import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const MainSectionContent = styled(Box)<{ bg: string }>`
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: transparent;
  width: 100%;
  flex-basis: 50%;
  padding: ${theme.spacing.medium}px ${theme.spacing.medium}px 0;
  overflow-y: auto;

  .loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: ${theme.spacing.medium}px;
  }

  .main-section-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: ${theme.spacing.small}px;

    .main-section-header__left {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.small}px;
      max-width: 707px;

      .title {
        color: ${theme.colors.white};
        font-size: ${theme.typography.fontSize.xxxLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: normal;
      }

      .description {
        color: ${theme.colors.white};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
        white-space: pre-line;
      }

      .details-wrapper {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.medium}px;

        .details-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10.5px;

          &.second {
            text-transform: capitalize;
          }

          .details-item-title {
            color: ${theme.colors.white};
            text-align: center;
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.thin};
            line-height: normal;
            white-space: nowrap;
          }

          .details-item-text {
            color: ${theme.colors.white};
            text-align: center;
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: normal;
            height: 20px;

            &.no-bold {
              font-weight: ${theme.typography.fontWeight.normal};
            }
          }
        }

        .section-divider {
          width: 1px;
          height: 51px;
          background: ${theme.colors.borderLow};
          margin: 0;
        }
      }
    }

    .main-section-header__right {
      padding: 0 ${theme.spacing.xSmall}px;

      .main-section-header__right-wrapper {
        a {
          padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
          border: 1px solid ${theme.colors.controlBorder};
          background: ${theme.colors.textPrimary};
          border-radius: ${theme.attributes.borderRadius.large};
          color: ${theme.baseColors.primary[20]};
          font-size: ${theme.typography.fontSize.xLarge};
          font-weight: ${theme.typography.fontWeight.thin};
          gap: ${theme.spacing.xSmall}px;
          text-decoration: none;
          display: flex;
          align-items: center;
          white-space: nowrap;

          &:hover {
            opacity: 0.9;
          }

          &:visited {
            color: ${theme.baseColors.primary[20]} !important;

            svg {
              path {
                fill: ${theme.baseColors.primary[20]} !important;
              }
            }
          }
        }
      }
    }
  }

  .MuiPaper-root.MuiAccordion-root {
    background: transparent;
    box-shadow: none !important;
    outline: 0 !important;
    margin-top: ${theme.spacing.xLarge}px;

    &::before {
      background: transparent;
    }

    &.Mui-expanded {
      margin: ${theme.spacing.xLarge}px 0 0;
    }
  }

  .MuiAccordion-root {
    background: transparent;

    .MuiAccordionSummary-root {
      background: transparent;
      border-bottom: 1px solid ${theme.colors.borderLow};
      padding: 0;
      min-height: unset;

      .MuiAccordionSummary-content {
        margin: 0;

        .accordion-title {
          color: ${theme.colors.white};
          text-align: center;
          font-size: ${theme.typography.fontSize.header};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: normal;
          margin-bottom: ${theme.spacing.small}px;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        color: ${theme.colors.white};
      }
    }

    .MuiCollapse-root {
      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          .MuiAccordionDetails-root {
            padding: 0;
            margin-top: ${theme.spacing.lg}px;
          }
        }
      }
    }
  }
`

export const ContentInner = styled(Box)`
  padding: ${theme.spacing.xLarge}px 0 0 0;

  .performance-text {
    margin-bottom: 12.5px;
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .main-performance-text {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: ${theme.spacing.large}px;
  }
`

export const InsightsImageWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.surfaceContainerBackground};
  padding: ${theme.spacing.xLarge}px 0;
`
