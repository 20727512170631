import { Box, Menu } from '@mui/material'
import styled from '@emotion/styled'
import theme from 'theme'

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;
  position: absolute;
  top: -${theme.spacing.xSmall}px;
  right: 0;

  .count-text {
    color: ${theme.baseColors.secondary[80]};
    text-align: center;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    padding: 0 ${theme.spacing.xSmall}px;
  }

  .sort-icon-button {
    &.sort-asc {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const MarkMenu = styled(Menu)`
  .sort-menu-title {
    color: ${theme.colors.textSecondaryLow};
    font-size: ${theme.typography.fontSize.small};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.thick};
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 9px;
  }
`
