import { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'

/* Utils */
import { Tenant } from '../../../../models'
import { breadcrumbs } from '../../../../lib/utils'

/* Components */
import { CompanyInfo, DashboardLogoWrapper } from '../dashboard.styles'
import HeaderComponent from '../../../components/header/HeaderComponent'

interface Props {
  accountSettings: Tenant | null
}

const DashboardHeader: FC<Props> = ({ accountSettings }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (accountSettings && Object.keys(accountSettings).length) {
      setLoading(false)
    }
  }, [accountSettings])

  return (
    <Box className="dashboard-header-wrapper">
      <HeaderComponent breadcrumbs={breadcrumbs.dashboard} type={'dashboard'}>
        <CompanyInfo>
          <DashboardLogoWrapper>
            {loading ? (
              ''
            ) : (
              <img
                src={`${
                  accountSettings?.logo
                    ? `${process.env.REACT_APP_LOGO_HOST}${accountSettings.logo}`
                    : '/dashboard-logo.svg'
                }`}
                alt="Dashboard Logo"
              />
            )}
          </DashboardLogoWrapper>
          <Typography className="company-name">{accountSettings?.name}</Typography>
        </CompanyInfo>
      </HeaderComponent>
    </Box>
  )
}

export default DashboardHeader
