import theme from '../../../../theme'

const AuditAddNewUserIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 7.25V5H13.5V7.25H11.25V8.75H13.5V11H15V8.75H17.25V7.25H15ZM6.75 9.5C8.4075 9.5 9.75 8.1575 9.75 6.5C9.75 4.8425 8.4075 3.5 6.75 3.5C5.0925 3.5 3.75 4.8425 3.75 6.5C3.75 8.1575 5.0925 9.5 6.75 9.5ZM6.75 5C7.575 5 8.25 5.675 8.25 6.5C8.25 7.325 7.575 8 6.75 8C5.925 8 5.25 7.325 5.25 6.5C5.25 5.675 5.925 5 6.75 5ZM11.5425 11.42C10.2825 10.775 8.6475 10.25 6.75 10.25C4.8525 10.25 3.2175 10.775 1.9575 11.42C1.2075 11.8025 0.75 12.575 0.75 13.415V15.5H12.75V13.415C12.75 12.575 12.2925 11.8025 11.5425 11.42ZM11.25 14H2.25V13.415C2.25 13.13 2.4 12.875 2.64 12.755C3.5325 12.2975 4.9725 11.75 6.75 11.75C8.5275 11.75 9.9675 12.2975 10.86 12.755C11.1 12.875 11.25 13.13 11.25 13.415V14Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default AuditAddNewUserIcon
