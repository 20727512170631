import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const SearchBarWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: ${theme.spacing.small}px;

  .search-icon {
    position: absolute;
    left: 35px;
    top: 26px;
    z-index: 1;
  }

  .clear-btn {
    position: absolute;
    right: ${theme.spacing.lg}px;
  }

  .search-bar {
    width: 100%;
    border-radius: 28px;
    height: 40px;
  }

  .MuiOutlinedInput-input {
    border: 1px solid ${theme.colors.controlBorder};
    border-radius: 28px;
    background: ${theme.colors.surfaceItemBackground};
    height: 40px;
    padding: 0 50px;
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;

    &::placeholder {
      color: ${theme.colors.neutral};
      opacity: 1;
    }

    &:focus {
      border-color: ${theme.colors.textPrimary};
    }
  }

  fieldset {
    border: none;
  }
`
