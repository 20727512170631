import { FC } from 'react'
import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const Wrapper = styled(Box)`
  border-radius: 18px;
  background: #debdd9;
  box-shadow: ${theme.attributes.boxShadow.quaternary};
  display: flex;
  padding: 2px ${theme.spacing.xSmall}px;
  align-items: center;
  gap: 3px;
  min-width: 61px;
  height: 20px;
  text-transform: uppercase;
  color: #3f293e;
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.medium};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
`

interface Props {
  text: string
  sx?: Record<string, unknown>
}

const CpiChip: FC<Props> = ({ text, sx }) => <Wrapper sx={sx}>{text}</Wrapper>

export default CpiChip
