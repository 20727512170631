import { Dispatch, FC, SetStateAction } from 'react'
import { Box, Typography, Divider } from '@mui/material'
import theme from 'theme'

/* Utils */
import { formatNumberCommas } from 'lib/utils'
import { IPreviewData, TableHeaders } from 'models'
import { defaultTableHeaders } from './PreviewData'

/* Components */
import TableSearch from './TableSearch'
import ColumnsSelectField from 'ui/pages/coverage-analyzer/components/ColumnsSelectField'
import { TableToolbarWrapper } from 'ui/pages/coverage-analyzer/components/TableToolbar.styles'

interface Props {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  setColumns: Dispatch<SetStateAction<TableHeaders[]>>
  columns: TableHeaders[]
  previewData: IPreviewData
}

const TableToolbar: FC<Props> = ({ search, setSearch, previewData, setColumns, columns }) => {
  const handleChange = (e: any, index: number) => {
    const temp = [...columns]
    temp[index].show = e.target.checked
    setColumns(temp)
  }

  const resetColumns = () => {
    const temp = [...defaultTableHeaders]
    temp.map((item) => {
      item.show = true
      return item
    })
    setColumns(temp)
  }

  return (
    <TableToolbarWrapper className="table-toolbar-wrapper">
      <Box className="left-side">
        <TableSearch search={search} setSearch={setSearch} />
      </Box>
      <Box className="right-side">
        <Divider
          orientation="vertical"
          flexItem
          sx={{ borderColor: theme.colors.borderLow, mx: `${theme.spacing.xSmall}px` }}
        />

        <ColumnsSelectField columns={columns} reset={resetColumns} handleChange={handleChange} />

        <Typography className="count">{formatNumberCommas(String(previewData.issues.length))} items</Typography>
      </Box>
    </TableToolbarWrapper>
  )
}

export default TableToolbar
