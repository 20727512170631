import { Box, Breadcrumbs, Button, TableContainer as MuiTableContainer, TableRow } from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
  margin-bottom: 146px;
`

export const BreadcrumbsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 70%;
  padding-left: ${theme.spacing.xSmall}px;

  .header-actions {
    display: flex;
    align-items: center;

    .favorite-icon {
      margin-right: 34px;

      .MuiButtonBase-root {
        &:hover,
        &:focus,
        &:active {
          background: rgba(202, 196, 208, 0.08);
        }
      }
    }
  }
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;

    &.category-breadcrumb {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      display: flex;
      padding: 2px ${theme.spacing.xSmall}px;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      border-radius: ${theme.attributes.borderRadius.mediumSmall};
      background: #b15c0f;
      box-shadow: ${theme.attributes.boxShadow.quaternary};
      text-transform: capitalize;
    }
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: ${theme.colors.textPrimary};
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`

export const AuditLogContainer = styled(Box)`
  min-height: calc(100vh - 90px);
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const TableContainer = styled(MuiTableContainer)<{ showborder: string }>`
  border-radius: 0;

  &::-webkit-scrollbar {
    position: sticky;
    top: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  &::-webkit-scrollbar-thumb {
    height: 8px;
    border-radius: ${theme.attributes.borderRadius.mediumSmall};
    background-color: #fbd8f5;
  }

  ${({ showborder }) =>
    showborder === 'true' &&
    `
    border-radius: 0 0 ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary};
  `};

  .MuiTable-root {
    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover {
          border-bottom: 1px solid ${theme.colors.controlBorder};
        }
      }
    }
  }

  .head-title {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .timestamp {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
    white-space: nowrap;
  }

  .name-wrapper {
    white-space: nowrap;
  }

  .name {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
    white-space: nowrap;
  }

  .email {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-left: ${theme.spacing.small}px;
    white-space: nowrap;
  }

  .role {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .description {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const TableActionsWrapper = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-height: 100%;
  width: 100%;
`

export const TableHeader = styled(Box)<{ showborder?: string }>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.small}px;
  width: 100%;

  ${({ showborder }) =>
    showborder &&
    showborder === 'false' &&
    `

    border-radius: 0 0 ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary};
  `};

  .header-actions {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.medium}px;

    .password-reset {
      margin-left: 42px;
      padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px ${theme.spacing.small}px;
      gap: ${theme.spacing.xSmall}px;
      background: ${theme.colors.primaryLight};
      border-radius: ${theme.attributes.borderRadius.large};
      height: 40px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fbd8f5;
      text-transform: none;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #f8d8f5;
        }
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.1);
        color: ${theme.colors.white};

        svg {
          path {
            fill: ${theme.colors.white};
          }
        }
      }
    }

    .delete-user {
      margin-left: ${theme.spacing.small}px;
      background: #93000a;
      border-radius: ${theme.attributes.borderRadius.large};
      height: 40px;
      padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px ${theme.spacing.small}px;
      gap: ${theme.spacing.xSmall}px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #ffb4ab;
      text-transform: none;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #ffb4ab;
        }
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.1);
        color: ${theme.colors.white};

        svg {
          path {
            fill: ${theme.colors.white};
          }
        }
      }
    }
  }

  .clear-filters-wrapper {
    display: flex;
    align-items: center;
    height: 56px;
  }

  .footer-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing.xSmall}px 0;
    gap: 38px;

    .per-page-wrapper {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.small}px;

      .per-page-text {
        color: ${theme.baseColors.greyscale[80]};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    .page-counter {
      display: flex;
      align-items: center;

      .MuiTypography-root {
        color: ${theme.baseColors.greyscale[80]};
        text-align: right;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    .actions-wrapper {
      display: flex;
      align-items: center;

      .icon-container {
        display: flex;
        align-items: center;
        padding: ${theme.spacing.xSmall}px;
        background: transparent;
        box-shadow: none;
        border: 0;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          cursor: default;

          svg {
            opacity: 0.38;
          }
        }

        &.left svg {
          transform: rotate(180deg);
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`

export const EmptyRow = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column wrap;
  background: ${theme.colors.backgroundMedium};
  border-bottom: 1px solid #342f32;
  width: 100%;
  min-height: calc(100vh - 306px);
  position: relative;
  padding: 55px 52px;
  border-radius: 0;
  z-index: 1;
  height: 646px;

  .text {
    z-index: 1;
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.huge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 52px;
    margin-bottom: ${theme.spacing.medium}px;
  }

  .icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
`

export const ClearFilters = styled(Button)`
  background: transparent;
  border-radius: ${theme.attributes.borderRadius.mediumSmall};
  gap: ${theme.spacing.xSmall}px;
  min-width: 78px;
  height: 32px;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.xLarge};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.thin};
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${theme.colors.textPrimary};
  text-transform: none;

  &.Mui-disabled {
    color: #debdd9;
    opacity: 0.38;
  }
`

export const LogAction = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.mediumSmall};
  border: 1px solid ${theme.colors.controlBorder};
  display: flex;
  padding: ${theme.spacing.xsm}px ${theme.spacing.small}px ${theme.spacing.xsm}px ${theme.spacing.xSmall}px;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;
  align-self: stretch;
  width: fit-content;

  .MuiTypography-root {
    color: #fbd8f5;
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    white-space: nowrap;
    margin-left: ${theme.spacing.xSmall}px;
  }
`

export const TableBodyRow = muiStyled(TableRow)(() => ({
  background: theme.colors.backgroundMedium,
  padding: `${theme.spacing.xSmall}px 0 ${theme.spacing.xSmall}px ${theme.spacing.small}px`,
  borderBottom: `1px solid ${theme.colors.borderLow}`,

  '&:last-child td, &:last-child th': {
    border: 0
  },

  '.MuiTableCell-root': {
    border: 0,
    paddingTop: 0,
    paddingBottom: 0,

    '&.log-action': {
      paddingTop: `${theme.spacing.xSmall}px`,
      paddingBottom: `${theme.spacing.xSmall}px`
    }
  },

  '&:hover': {
    background: theme.colors.primaryLight
  }
}))

export const TableHeadRow = muiStyled(TableRow)({
  background: theme.colors.backgroundMedium,
  borderBottom: `1px solid ${theme.colors.borderLow}`,
  height: '55px',

  '.MuiTableCell-root': {
    border: 0,
    paddingTop: `${theme.spacing.xSmall}px`,
    paddingBottom: `${theme.spacing.xSmall}px`
  }
})

export const DateRangePickerContainer = styled(Box)`
  position: relative;

  .MuiInputBase-input {
    color: ${theme.baseColors.greyscale[80]};
  }

  .MuiFormControl-root {
    margin-left: ${theme.spacing.xSmall}px !important;
    width: 152px;
  }

  .calendar-container {
    background: ${theme.colors.backgroundMedium};
    box-shadow: ${theme.attributes.boxShadow.primary};
    border-bottom-left-radius: ${theme.attributes.borderRadius.small};
    border-bottom-right-radius: ${theme.attributes.borderRadius.small};
    z-index: 1000000000;
    top: 80px;
    position: absolute;
  }

  .calendar-header {
    background: ${theme.colors.backgroundMedium};
    box-shadow: ${theme.attributes.boxShadow.secondary};
    border-radius: ${theme.attributes.borderRadius.small} ${theme.attributes.borderRadius.small} 0 0;

    .calendar-header__inner {
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 679px;
      padding: ${theme.spacing.small}px ${theme.spacing.medium}px;

      fieldset {
        border: 1px solid ${theme.colors.controlBorder};
        border-radius: ${theme.attributes.borderRadius.small};
      }

      input {
        font-family: ${theme.typography.fontFamily.primary};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.xLarge};
        line-height: 24px;
        letter-spacing: 0.5px;
        color: ${theme.baseColors.greyscale[80]};
        -webkit-text-fill-color: unset;
      }

      label {
        font-family: ${theme.typography.fontFamily.primary};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.medium};
        line-height: 23px;
        letter-spacing: 0.4px;
        color: ${theme.baseColors.greyscale[80]};
      }
    }
  }

  .calendar-content {
    display: flex;
    align-items: flex-start;

    .MuiPickerStaticWrapper-content {
      width: 660px;
      border-radius: unset;
      background: transparent;

      .MuiDateRangePickerViewDesktop-container {
        width: 330px;

        /* Month Selector */
        .MuiPickersArrowSwitcher-root {
          button svg {
            fill: ${theme.baseColors.greyscale[80]};
          }

          .MuiTypography-root {
            font-family: ${theme.typography.fontFamily.primary};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            font-size: ${theme.typography.fontSize.large};
            line-height: 20px;
            letter-spacing: 0.1px;
            color: ${theme.baseColors.greyscale[80]};
          }
        }

        &.MuiDateRangePickerViewDesktop-container {
          border-right: 1px solid #444746;
        }

        .MuiDayPicker-weekContainer {
          .MuiDateRangePickerDay-root:first-of-type:not(.MuiDateRangePickerDay-rangeIntervalDayHighlightStart, .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd),
          .MuiDateRangePickerDay-root:last-of-type {
            border-radius: 0 !important;
          }
        }
      }
    }

    .MuiDateRangePickerDay-root {
      .MuiButtonBase-root.MuiPickersDay-root {
        font-family: ${theme.typography.fontFamily.primary};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.large};
        line-height: 20px;
        letter-spacing: 0.25px;
        color: ${theme.colors.neutral};

        &.MuiPickersDay-dayOutsideMonth {
          color: ${theme.colors.borderLow};
        }
      }

      &.MuiDateRangePickerDay-rangeIntervalDayHighlight {
        background: ${theme.colors.primaryLight};

        .MuiButtonBase-root.MuiPickersDay-root {
          color: ${theme.baseColors.greyscale[80]};
        }

        &.MuiDateRangePickerDay-rangeIntervalDayHighlightStart,
        &.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
          .MuiDateRangePickerDay-rangeIntervalPreview {
            .MuiButtonBase-root.MuiPickersDay-root {
              background: ${theme.colors.textPrimary};
              color: ${theme.baseColors.primary[20]};

              &.MuiPickersDay-today {
                border: 1px solid ${theme.colors.textPrimary};
                color: ${theme.colors.textPrimary};
              }
            }
          }
        }
      }

      .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
        border: 1px solid ${theme.colors.textPrimary};
      }
    }

    .calendar-picker-wrapper {
      width: 140px;
    }

    .range-selector-wrapper {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      margin-top: 5px;

      & > .MuiButtonBase-root {
        padding: ${theme.spacing.half}px ${theme.spacing.medium}px ${theme.spacing.half}px ${theme.spacing.small}px;
        width: 100%;
        height: 48px;
        background: transparent;
        font-family: ${theme.typography.fontFamily.primary};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.xLarge};
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: none;
        justify-content: flex-start;
        color: #e6e1e5;
      }
    }
  }

  .calendar-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 9px ${theme.spacing.md}px 11px 0;
    border-top: 1px solid #444746;

    & > .MuiButtonBase-root {
      min-width: 69px;
      min-height: 40px;
      border-radius: ${theme.attributes.borderRadius.large};
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: ${theme.spacing.xSmall}px;
      font-family: ${theme.typography.fontFamily.primary};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.textPrimary};
      text-transform: none;

      &:first-of-type {
        margin-right: ${theme.spacing.xSmall}px;
      }

      &:nth-of-type(2) {
        width: 45px;
        min-width: unset;
      }
    }
  }

  .MuiMultiInputDateRangeField-separator {
    display: none;
  }

  .MuiFormControl-root {
    height: 40px;

    .MuiInputLabel-root {
      color: ${theme.colors.textPrimary};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      top: -${theme.spacing.xsm}px;
    }

    .MuiOutlinedInput-root {
      height: 40px;
      border-radius: ${theme.attributes.borderRadius.large};
      border: 1px solid ${theme.colors.controlBorder};
      background: transparent;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};

      .MuiInputBase-input {
        cursor: pointer;
      }

      &.Mui-focused {
        background: rgba(208, 188, 255, 0.16);
        color: ${theme.colors.textPrimary};
        border: 1px solid ${theme.colors.textPrimary};
      }

      input {
        height: 40px;
      }
    }
  }
`
