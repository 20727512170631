import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'
import { getCpiCategoryColors } from '../../../../../lib/utils'
import { NistFunction } from '../../../../../models'

export const CategoryWeightItemContainer = styled(Box)<{ selected?: boolean; invalidcpisweight: string }>`
  width: 100%;
  height: 72px;
  min-height: 72px;
  padding: ${theme.spacing.md}px ${theme.spacing.medium}px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.surfaceItemBackground};
  gap: ${theme.spacing.small}px;
  display: flex;
  align-items: center;
  justify-content: stretch;

  ${({ invalidcpisweight }) =>
    invalidcpisweight === 'true' &&
    `
      border: 1px solid ${theme.baseColors.info[50]};
  `}

  &:hover {
    cursor: pointer;
    background: ${theme.baseColors.surface[50]};
  }

  ${({ selected }) =>
    selected &&
    `
      background: ${theme.colors.primaryLight} !important;
    `};

  .MuiOutlinedInput-root {
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.controlBorder};
    letter-spacing: 0.5px;
    height: 48px;
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    width: 100%;
    outline: 0;

    fieldset {
      border: 0;
    }

    &:hover {
      border-color: ${theme.colors.neutral};
    }

    &:last-of-type {
      width: 81px;
      min-width: 81px;
    }

    &.Mui-focused {
      border-color: ${theme.colors.textPrimary};
    }

    input {
      padding-left: 22px;
    }
  }

  .length3 input {
    padding-left: ${theme.spacing.small}px;
  }

  .length1 input {
    padding-left: ${theme.spacing.large}px;
  }

  .percentage {
    .MuiTypography-root {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }
  }

  .MuiFormControl-root {
    width: 100%;

    &:last-of-type {
      width: 81px;
      min-width: 81px;
    }
  }

  .Mui-disabled {
    color: ${theme.baseColors.greyscale[80]};
    -webkit-text-fill-color: ${theme.baseColors.greyscale[80]};
    cursor: not-allowed;
  }

  .Mui-focused {
    border: 1px solid ${theme.colors.textPrimary};

    input {
      width: 100%;
    }
  }

  .close-button {
    color: ${theme.colors.textPrimary};

    &:hover {
      background: ${theme.colors.surfaceItemBackgroundLow};
    }

    &.is-disabled {
      color: ${theme.baseColors.greyscale[50]};

      &:hover {
        cursor: default;
        background: transparent;
      }
    }
  }

  .category-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    flex-grow: 1;
  }

  .no-role-weights-wrapper {
    padding: 0 ${theme.spacing.medium}px;
    width: 81px;
    min-width: 81px;

    .category-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      text-align: center;
      flex-grow: 1;
    }
  }
`

export const CategoryCircle = styled(Box)<{ bgcolor: string }>`
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: ${theme.attributes.borderRadius.circle};
  background: ${({ bgcolor }) => `${bgcolor}`};
`

export const OnyxiaCategoryCircle = styled(Box)<{ categoryid: string }>`
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: ${theme.attributes.borderRadius.circle};

  ${({ categoryid }) => {
    const colors = getCpiCategoryColors(categoryid)

    return `
      background: ${colors.background};
    `
  }};
`

export const NistCategoryCircle = styled(Box)<{ fn: NistFunction | '' }>`
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: ${theme.attributes.borderRadius.circle};

  ${({ fn }) =>
    fn === NistFunction.Identify &&
    `
      background: #8E9AFF;
    `}

  ${({ fn }) =>
    fn === NistFunction.Protect &&
    `
      background: #C77EDA;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Detect &&
    `
      background: #FFDE6A;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Respond &&
    `
      background: #FF7F6B;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Recover &&
    `
      background: #99FDC0;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Govern &&
    `
      background: #FF9E47;
    `}
`
