import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  width: 500px;

  .title {
    color: ${theme.colors.textPrimary};
    text-align: center;
    text-shadow: ${theme.attributes.boxShadow.quaternary};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.header};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: normal;
    margin-bottom: ${theme.spacing.small}px;
  }

  .description {
    max-width: 537px;
    color: ${theme.colors.white};
    text-align: center;
    text-shadow: ${theme.attributes.boxShadow.quaternary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }

  .action-wrapper {
    margin-top: ${theme.spacing.medium}px;
    display: flex;
    justify-content: center;
  }
`

export const EmptyLogo = styled(Box)`
  position: fixed;
  bottom: -5px;
  right: 0;
  z-index: 1;

  svg {
    z-index: 1;

    path {
      fill: ${theme.colors.borderLow};
    }
  }
`
