import { Autocomplete, Paper } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const StyledAutocomplete = styled(Autocomplete)`
  max-width: 300px;
  height: 40px;
  flex-grow: 1;
  background: transparent;
  border: 1px solid ${theme.colors.controlBorder};
  border-radius: ${theme.attributes.borderRadius.large};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${theme.baseColors.greyscale[80]};

  &.Mui-focused {
    border: 1px solid ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0 !important;
    border-bottom: none !important;

    fieldset {
      border: none;
      border-bottom: none !important;
    }
  }

  .MuiOutlinedInput-root {
    padding: 0 ${theme.spacing.small}px;
    height: 40px;
    color: #e3e3e3;

    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        padding: ${theme.spacing.xsm}px;

        &:hover {
          background: rgba(202, 196, 208, 0.08);
        }

        svg {
          path {
            fill: ${theme.baseColors.greyscale[80]};
          }
        }
      }
    }

    .MuiOutlinedInput-input {
      padding-left: ${theme.spacing.sm}px;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;

      &::placeholder {
        opacity: 1;
        color: ${theme.baseColors.greyscale[80]};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    &::placeholder {
      opacity: 1;
    }

    fieldset {
      border: none;
    }
  }
`

export const PaperComponent = styled(Paper)`
  width: 268px;
  border-radius: 0 0 ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} !important;
  background: ${theme.colors.backgroundMedium};
  padding: 0;
  border: 1px solid ${theme.colors.textPrimary};
  border-top: none !important;
  margin-top: -1px;
  margin-left: -1px;

  ul {
    li {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.1px;

      svg {
        margin-right: ${theme.spacing.xSmall}px;
      }
    }
  }
`
