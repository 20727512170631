import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Form, Formik } from 'formik'

/* Utils */
import { accountSettingsSelector } from '../../../../../store/user/userSlice'
import { useAppSelector } from '../../../../../store/hooks'
import { Tenant } from '../../../../../models'
import { timeZoneList } from '../account-managment.constants'

/* Components */
import { ThemeButton } from '../../../../components/buttons'
import ControlledSelectBox from '../../../../components/form-components/ControlledSelectBox'
import ControlledTextField from '../../../../components/form-components/ControlledTextField'
import { AccountWrapper, Content, FormContent, TitleWrapper } from './AccountInformation.styles'

interface IIndustries {
  label: string
  value: string
}

interface Props {
  isEdit: boolean
  form: Tenant
  industries: IIndustries[]
  updateData: (value: string, name: string) => void
  onSubmit: (values: Tenant) => void
}

const AccountInformation: FC<Props> = ({ isEdit, form, industries, updateData, onSubmit }) => {
  const account = useAppSelector(accountSettingsSelector)
  const timezoneOptions =
    timeZoneList.length > 0
      ? timeZoneList.map((item) => ({
          label: item.timezone + ' ' + item.name.split(' ')[0],
          value: item.name
        }))
      : []

  const changeHandler = (value: string, name: string) => {
    updateData(value, name)
  }

  return (
    <AccountWrapper>
      <TitleWrapper>
        <Typography className="title">Company Info</Typography>
      </TitleWrapper>

      {!isEdit ? (
        <Content>
          <Box className="info-block">
            <Typography className="left-side">Logo</Typography>
            <Typography className="right-side">
              <img
                src={`${account?.logo ? `${process.env.REACT_APP_LOGO_HOST}${account.logo}` : '/dashboard-logo.svg'}`}
                alt="company logo"
              />
            </Typography>
          </Box>
          <Box className="info-block">
            <Typography className="left-side">Company Name</Typography>
            <Typography className="right-side">{account?.name}</Typography>
          </Box>
          <Box className="info-block">
            <Typography className="left-side">Industry</Typography>
            <Typography className="right-side">{account?.industry}</Typography>
          </Box>
          <Box className="info-block">
            <Typography className="left-side">Timezone</Typography>
            <Typography className="right-side">{account?.timeZone}</Typography>
          </Box>
        </Content>
      ) : (
        <FormContent>
          <Formik initialValues={form} onSubmit={onSubmit} validateOnMount>
            {({ isSubmitting, isValid }) => (
              <Form autoComplete="off">
                <Box className="image-upload-wrapper">
                  <img
                    src={`${
                      account?.logo ? `${process.env.REACT_APP_LOGO_HOST}${account.logo}` : '/dashboard-logo.svg'
                    }`}
                    alt="company logo"
                  />
                </Box>

                <ControlledTextField name="name" label="Company Name" className="account" />

                <ControlledSelectBox
                  options={industries || []}
                  name="industry"
                  placeholder="Select"
                  label="Industry"
                  value={form.industry}
                  onChange={changeHandler}
                />
                <ControlledSelectBox
                  options={timezoneOptions}
                  name="timeZone"
                  placeholder="Select"
                  label="Timezone"
                  value={form.timeZone}
                  onChange={changeHandler}
                />

                <ThemeButton type="submit" disabled={!isValid || isSubmitting}>
                  Save
                </ThemeButton>
              </Form>
            )}
          </Formik>
        </FormContent>
      )}
    </AccountWrapper>
  )
}

export default AccountInformation
