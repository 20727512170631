import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const CategoryHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.overallScoreWrapper,
    color: theme.colors.neutral,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.medium,
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '16px'
  },
  '.tooltip-list': {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    marginBottom: '2px',

    '.tooltip-header': {
      fontFamily: theme.typography.fontFamily.primary,
      fontSize: `${theme.typography.fontSize.large} !important`,
      fontWeight: theme.typography.fontWeight.bold,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: theme.colors.white
    }
  },
  '.tooltip-desc': {
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: `${theme.typography.fontSize.medium} !important`,
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: theme.colors.white,
    maxWidth: '235px'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing.xSmall}px`,
    marginBottom: `${theme.spacing.xSmall}px`,
    marginTop: `${theme.spacing.xSmall}px`,

    '.tooltip-text': {
      fontFamily: theme.typography.fontFamily.primary,
      fontSize: `${theme.typography.fontSize.mediumSmall} !important`,
      fontWeight: theme.typography.fontWeight.bold,
      lineHeight: '16px',
      color: theme.colors.neutral
    }
  }
})
