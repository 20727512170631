import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import theme from 'theme'

/* Utils */
import { AnalysisDataTable, IntegrationInstance, OperatingSystem } from '../../../models'
import useCoverageAnalyzerData from './useCoverageAnalyzerData'

/* Components */
import { Container, Wrapper } from './coverageAnalyzer.styles'
import CoverageLoading from './CoverageLoading'
import { breadcrumbs } from '../../../lib/utils'
import HeaderComponent from '../../components/header/HeaderComponent'
import ToolbarComponent from '../../components/header/ToolbarComponent'
import ContentWrapper from '../../components/header/ContentWrapper'
import ToolbarList from './components/ToolbarList'
import TabsHeader from '../../components/tabs-header'
import TabPanel from '../library/library-details/components/LibraryDetailsTabPanel'
import TrendsTab from './sections/trends-tab'
import Analysis from './components/Analysis'
import CoverageAnalyzerInitial from './CoverageAnalyzerInitial'
import { ToolbarWrapper } from './components/ToolbarList.styles'

const CoverageAnalyzer: FC = () => {
  const [tabValue, setTabValue] = useState(0)
  const [analyzed, setAnalyzed] = useState(false)
  const [search, setSearch] = useState('')
  const [showFilter, setShowFilter] = useState(true)
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [isTrendsFilterActive, setIsTrendsFilterActive] = useState(false)

  const {
    firstSource,
    secondSource,
    handleChangeSource,
    selectedOperatingSystem,
    setSelectedOperatingSystem,
    selectedSeenWithin,
    setSelectedSeenWithin,
    setSelectedTimePeriod,
    sourceChanged,
    selectedTimePeriod,
    chartData,
    fetchData,
    chartLoading,
    setChartLoading,
    instances,
    optionsLoading,
    analysisData,
    fetchAnalysisData,
    loadingAnalysisData,
    setLoadingAnalysisData,
    selectedInstances,
    pagination,
    setPagination,
    tableFilters,
    setTableFilters,
    firstInstanceAssets,
    secondInstanceAssets,
    supportedIntegrations,
    numOfActiveAssets,
    initialLoad,
    fetchTotalAssets,
    setFirstSource,
    setSecondSource,
    lastActiveSeenWithin,
    setLastActiveSeenWithin,
    setSourceChanged,
    selectedOsTrends,
    setSelectedOsTrends,
    exporting,
    handleExport,
    setActiveSeenWithin,
    activeSeenWithin
  } = useCoverageAnalyzerData()

  const getInstanceName = useCallback((instance: IntegrationInstance | undefined) => {
    if (!instance) return ''

    const { integration_name: integrationName, id } = instance
    const idPart = id.split('-')[0]

    return `${integrationName}_${idPart}`
  }, [])

  const firstInstance = useMemo(
    () =>
      instances && selectedInstances.length > 0 ? instances.find((n) => n.id === selectedInstances[0].id) : undefined,
    [instances, selectedInstances]
  )
  const secondInstance = useMemo(
    () =>
      instances && selectedInstances.length > 0 ? instances.find((n) => n.id === selectedInstances[1].id) : undefined,
    [instances, selectedInstances]
  )
  const firstInstanceName = useMemo(() => getInstanceName(firstInstance), [firstInstance, instances, selectedInstances])
  const secondInstanceName = useMemo(
    () => getInstanceName(secondInstance),
    [secondInstance, instances, selectedInstances]
  )

  const data: AnalysisDataTable[] = analysisData.map((n) => {
    if (n.covered_by === 'both') {
      const firstSourceData = n[firstInstanceName]
      const secondSourceData = n[secondInstanceName]
      const sameHostName = firstSourceData?.host_name === secondSourceData?.host_name
      const sameMacAddress = firstSourceData?.mac_address === secondSourceData?.mac_address
      const sameOperatingSystem = firstSourceData?.operating_system === secondSourceData?.operating_system
      const sameSerialNumber = firstSourceData?.serial_number === secondSourceData?.serial_number
      const sameAssetId = firstSourceData?.asset_id === secondSourceData?.asset_id

      return {
        coverage: n.covered_by,
        matchedBy: n.matched_by,
        assetId: firstSourceData.asset_id,
        sameAssetId,
        secondAssetId: secondSourceData?.asset_id,
        hostName: firstSourceData?.host_name,
        serialNumber: firstSourceData?.serial_number,
        macAddress: firstSourceData?.mac_address,
        lastSeen: firstSourceData?.last_seen,
        operatingSystem: firstSourceData?.operating_system,
        sameHostName,
        secondHostName: secondSourceData?.host_name,
        sameMacAddress,
        secondMacAddress: secondSourceData?.mac_address,
        sameOperatingSystem,
        secondOperatingSystem: secondSourceData?.operating_system,
        sameSerialNumber,
        secondSerialNumber: secondSourceData?.serial_number,
        secondLastSeen: secondSourceData?.last_seen
      }
    }

    return {
      coverage: n.covered_by,
      matchedBy: n.matched_by,
      assetId: n.asset_id,
      hostName: n.host_name,
      serialNumber: n.serial_number,
      macAddress: n.mac_address,
      lastSeen: n.last_seen,
      operatingSystem: n.operating_system
    }
  })
  const [rows, setRows] = useState<AnalysisDataTable[]>(data)

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    if (analysisData.length > 0 || chartData) {
      setTabValue(newValue)
    }
  }

  useEffect(() => {
    setRows(
      analysisData.map((n) => {
        if (n.covered_by === 'both') {
          const firstSourceData = n[firstInstanceName]
          const secondSourceData = n[secondInstanceName]
          const sameHostName = firstSourceData?.host_name === secondSourceData?.host_name
          const sameMacAddress = firstSourceData?.mac_address === secondSourceData?.mac_address
          const sameOperatingSystem = firstSourceData?.operating_system === secondSourceData?.operating_system
          const sameSerialNumber = firstSourceData?.serial_number === secondSourceData?.serial_number
          const sameAssetId = firstSourceData?.asset_id === secondSourceData?.asset_id

          return {
            coverage: n.covered_by,
            matchedBy: n.matched_by,
            assetId: firstSourceData?.asset_id,
            sameAssetId,
            hostName: firstSourceData?.host_name,
            serialNumber: firstSourceData?.serial_number,
            macAddress: firstSourceData?.mac_address,
            lastSeen: firstSourceData?.last_seen,
            operatingSystem: firstSourceData?.operating_system,
            secondAssetId: secondSourceData?.asset_id,
            sameHostName,
            secondHostName: secondSourceData?.host_name,
            sameMacAddress,
            secondMacAddress: secondSourceData?.mac_address,
            sameOperatingSystem,
            secondOperatingSystem: secondSourceData?.operating_system,
            sameSerialNumber,
            secondSerialNumber: secondSourceData?.serial_number,
            secondLastSeen: secondSourceData?.last_seen
          }
        }

        return {
          coverage: n.covered_by,
          matchedBy: n.matched_by,
          assetId: n.asset_id,
          hostName: n.host_name,
          serialNumber: n.serial_number,
          macAddress: n.mac_address,
          lastSeen: n.last_seen,
          operatingSystem: n.operating_system
        }
      })
    )
    setLoadingAnalysisData(false)
  }, [analysisData])

  const handleCloseBackdrop = () => {
    setChartLoading(false)
  }

  const handleAnalyzed = () => {
    setLoadingAnalysisData(true)

    setTimeout(() => {
      setAnalyzed(true)
    }, 150)
  }

  const handleSearch = async ({
    integrationIds,
    searchTerm,
    filters,
    operatingSystem
  }: {
    integrationIds: string[]
    searchTerm: string
    filters: boolean[]
    operatingSystem: OperatingSystem
  }) => {
    await fetchAnalysisData({ integrationIds, searchTerm, filters, operatingSystem })
  }

  const debouncedSearch = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    setLoadingAnalysisData(true)
    debouncedSearch({
      integrationIds: [firstSource, secondSource],
      searchTerm: search,
      filters: tableFilters,
      operatingSystem: selectedOperatingSystem
    })
  }, [search])

  if (!analyzed) {
    return (
      <Container className="aca-container-initial">
        <HeaderComponent breadcrumbs={breadcrumbs.ACA} noBg={true} borderBottom={true} />

        <CoverageAnalyzerInitial
          selectedTimePeriod={selectedTimePeriod}
          selectedOsTrends={selectedOsTrends}
          firstSource={firstSource}
          secondSource={secondSource}
          handleChangeSource={handleChangeSource}
          selectedSeenWithin={selectedSeenWithin}
          setSelectedSeenWithin={setSelectedSeenWithin}
          fetchData={fetchData}
          instances={instances}
          optionsLoading={optionsLoading}
          fetchAnalysisData={fetchAnalysisData}
          handleAnalyzed={handleAnalyzed}
          supportedIntegrations={supportedIntegrations}
          lastActiveSeenWithin={lastActiveSeenWithin}
          setLastActiveSeenWithin={setLastActiveSeenWithin}
          setActiveSeenWithin={setActiveSeenWithin}
        />
      </Container>
    )
  }
  return (
    <Container className="aca-container">
      {chartLoading && tabValue === 1 ? <CoverageLoading open={chartLoading} handleClose={handleCloseBackdrop} /> : ''}

      <HeaderComponent
        breadcrumbs={breadcrumbs.ACA}
        noBg={true}
        sx={{ borderBottom: `1px solid ${theme.colors.borderLow} !important` }}
      />

      <ToolbarComponent>
        <ToolbarWrapper className="controls-toolbar-wrapper-main">
          <ToolbarList
            firstSource={firstSource}
            setActiveSeenWithin={setActiveSeenWithin}
            secondSource={secondSource}
            setChartLoading={setChartLoading}
            handleChangeSource={handleChangeSource}
            selectedSeenWithin={selectedSeenWithin}
            setSelectedSeenWithin={setSelectedSeenWithin}
            fetchData={fetchData}
            setIsFilterActive={setIsFilterActive}
            setSelectedOperatingSystem={setSelectedOperatingSystem}
            instances={instances}
            optionsLoading={optionsLoading}
            fetchAnalysisData={fetchAnalysisData}
            handleAnalyzed={handleAnalyzed}
            mainToolbarList
            setTableFilters={setTableFilters}
            firstInstanceAssets={firstInstanceAssets}
            secondInstanceAssets={secondInstanceAssets}
            supportedIntegrations={supportedIntegrations}
            numOfActiveAssets={numOfActiveAssets}
            loadingAnalysisData={loadingAnalysisData}
            sourceChanged={sourceChanged}
            lastActiveSeenWithin={lastActiveSeenWithin}
            setLastActiveSeenWithin={setLastActiveSeenWithin}
            setSourceChanged={setSourceChanged}
            analysisData={analysisData}
            selectedInstances={selectedInstances}
            setIsTrendsFilterActive={setIsTrendsFilterActive}
            setSelectedOsTrends={setSelectedOsTrends}
            setSelectedTimePeriod={setSelectedTimePeriod}
            selectedTimePeriod={selectedTimePeriod}
            selectedOsTrends={selectedOsTrends}
            fieldWidth="300"
            setSearch={setSearch}
          />
        </ToolbarWrapper>
      </ToolbarComponent>

      <TabsHeader
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        title="Asset Coverage Analyzer"
        tabLabels={['Assets', 'Coverage Trends']}
        loading={false}
        sx={{ marginTop: '24px !important' }}
      />

      <ContentWrapper
        line={2}
        marginTop={24}
        normalHeight={
          (!analysisData.length && !chartData) || tabValue === 1 ? 'false' : loadingAnalysisData ? 'true' : 'false'
        }
        paddingBottom={0}
        scrollHeight={analysisData.length > 0 || chartData ? 250 : 200}
      >
        <Wrapper>
          <TabPanel value={tabValue} index={0}>
            <Analysis
              rows={rows}
              setRows={setRows}
              handleExport={handleExport}
              exporting={exporting}
              firstSourceInField={firstSource}
              secondSourceInField={secondSource}
              selectedSeenWithin={selectedSeenWithin}
              selectedOperatingSystem={selectedOperatingSystem}
              setSelectedOperatingSystem={setSelectedOperatingSystem}
              fetchAnalysisData={fetchAnalysisData}
              loadingAnalysisData={loadingAnalysisData}
              pagination={pagination}
              setPagination={setPagination}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
              setLoadingAnalysisData={setLoadingAnalysisData}
              initialLoad={initialLoad}
              search={search}
              setSearch={setSearch}
              fetchTotalAssets={fetchTotalAssets}
              firstInstance={firstInstance}
              secondInstance={secondInstance}
              firstInstanceName={firstInstanceName}
              secondInstanceName={secondInstanceName}
              showFilter={showFilter}
              isFilterActive={isFilterActive}
              setIsFilterActive={setIsFilterActive}
              setShowFilter={setShowFilter}
              setFirstSource={setFirstSource}
              setSecondSource={setSecondSource}
              selectedInstances={selectedInstances}
              activeSeenWithin={activeSeenWithin}
              setSelectedSeenWithin={setSelectedSeenWithin}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <TrendsTab
              firstSource={firstSource}
              secondSource={secondSource}
              selectedOperatingSystem={selectedOsTrends}
              setSelectedOperatingSystem={setSelectedOsTrends}
              chartData={chartData}
              selectedTimePeriod={selectedTimePeriod}
              setSelectedTimePeriod={setSelectedTimePeriod}
              fetchData={fetchData}
              instances={instances}
              selectedInstances={selectedInstances}
              isTrendsFilterActive={isTrendsFilterActive}
              setIsTrendsFilterActive={setIsTrendsFilterActive}
              chartLoading={chartLoading}
            />
          </TabPanel>
        </Wrapper>
      </ContentWrapper>
    </Container>
  )
}

export default CoverageAnalyzer
