import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;

  .data-source-image {
    border-radius: ${theme.attributes.borderRadius.circle};

    &:hover {
      cursor: pointer;
    }
  }

  .sources-count-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`
