import { FC } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import theme from 'theme'

/* Components */
import { Wrapper } from './detailsCard.styles'
import { SSMCardSkeleton, SSMCardSkeletonWrapper } from '../../../../security-stack-map/components/ssmCard.styles'

interface Props {
  icon: JSX.Element
  text: string
  value: number
  handleClick: (text: string) => void
  isLoading?: boolean
}

const DetailsCard: FC<Props> = ({ icon, handleClick, text, value, isLoading }) => (
  <Wrapper
    onClick={() => handleClick(text)}
    isloading={isLoading ? 'true' : 'false'}
    sx={{
      '@media (max-width: 1463px)': {
        padding: isLoading ? '0 !important' : ''
      }
    }}
  >
    {isLoading ? (
      <SSMCardSkeletonWrapper
        className="card-skeleton-wrapper"
        sx={{
          width: '100% !important',
          height: '124px !important',

          '@media (max-width: 1463px)': {
            width: '100% !important',
            height: '56px !important',

            '& > div:last-of-type': {
              display: 'none'
            }
          }
        }}
      >
        <SSMCardSkeleton
          className="card-skeleton"
          sx={{
            '@media (max-width: 1463px)': {
              width: '100% !important'
            }
          }}
        >
          <Skeleton
            variant="rectangular"
            component="div"
            animation="wave"
            width="100%"
            height="54px"
            className="card-skeleton-inner"
            sx={{
              '@media (max-width: 1463px)': {
                width: '100% !important',
                height: '56px !important',
                borderRadius: theme.attributes.borderRadius.secondary
              }
            }}
          />
        </SSMCardSkeleton>
        <SSMCardSkeleton>
          <Skeleton
            variant="rectangular"
            component="div"
            animation="wave"
            width="100%"
            height="54px"
            className="card-skeleton-inner"
            sx={{
              '@media (max-width: 1463px)': {
                width: '100% !important',
                height: '56px !important',
                borderRadius: theme.attributes.borderRadius.secondary
              }
            }}
          />
        </SSMCardSkeleton>
      </SSMCardSkeletonWrapper>
    ) : (
      <>
        <Box className="details-card-header">
          {icon}
          <Typography>{value}</Typography>
        </Box>
        <Box className="details-card-content">
          <Typography>{text}</Typography>
        </Box>
      </>
    )}
  </Wrapper>
)

export default DetailsCard
