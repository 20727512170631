import { Box, Popper as MuiPopper } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Popper = styled(MuiPopper)`
  margin-top: ${theme.spacing.xSmall}px !important;
  z-index: 10000;
  overflow-x: hidden;
`

export const PopperInner = styled(Box)`
  border: 1px solid ${theme.colors.borderLow};
  box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
  padding: ${theme.spacing.small}px;
  gap: ${theme.spacing.medium}px;
  width: 465px;
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.surfaceContainerBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: ${theme.spacing.md}px;

  @media screen and (max-height: 800px) {
    height: 100%;
    max-height: 400px;
  }

  .popper-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 0 ${theme.spacing.xSmall}px ${theme.spacing.xSmall}px;
  }

  .popper-search-container {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xSmall}px;
    margin-left: -2px;
    width: calc(100% + 2px);

    .header-text {
      overflow: hidden;
      color: ${theme.colors.white};
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      margin-bottom: -${theme.spacing.half}px;
    }
  }

  .checkbox-form-control {
    margin: ${theme.spacing.xSmall}px 0 0 0;
    gap: ${theme.spacing.small}px;

    .MuiFormLabel-root {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }

    .MuiFormGroup-root {
      margin-top: ${theme.spacing.xSmall}px;

      .MuiFormControlLabel-root {
        .MuiTypography-root {
          color: ${theme.colors.white};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
        }
      }
    }
  }

  .popper-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid ${theme.colors.borderLow};
    padding-top: ${theme.spacing.small}px;

    .footer-actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${theme.spacing.small}px;
    }
  }
`
