import theme from 'theme'

const ZoomInSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      d="M12.3163 10.6913H11.7238L11.5138 10.4888C12.2488 9.63375 12.6913 8.52375 12.6913 7.31625C12.6913 4.62375 10.5088 2.44125 7.81625 2.44125C5.12375 2.44125 2.94125 4.62375 2.94125 7.31625C2.94125 10.0088 5.12375 12.1913 7.81625 12.1913C9.02375 12.1913 10.1338 11.7488 10.9888 11.0138L11.1913 11.2238V11.8163L14.9413 15.5588L16.0588 14.4413L12.3163 10.6913ZM7.81625 10.6913C5.94875 10.6913 4.44125 9.18375 4.44125 7.31625C4.44125 5.44875 5.94875 3.94125 7.81625 3.94125C9.68375 3.94125 11.1913 5.44875 11.1913 7.31625C11.1913 9.18375 9.68375 10.6913 7.81625 10.6913ZM8.19125 5.44125H7.44125V6.94125H5.94125V7.69125H7.44125V9.19125H8.19125V7.69125H9.69125V6.94125H8.19125V5.44125Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default ZoomInSvg
