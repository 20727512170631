import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const SearchSelect = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.small};
  border: 1px solid ${theme.colors.controlBorder};
  height: 40px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: ${theme.spacing.xSmall}px;

  &:hover {
    cursor: text;
  }

  .svg-dropdown {
    width: 24px;
    height: 24px;

    path {
      fill: ${theme.colors.white};
    }

    position: absolute;
    top: ${theme.spacing.xSmall}px;
    right: ${theme.spacing.xSmall}px;
  }

  .MuiTypography-root {
    overflow: hidden;
    color: ${theme.colors.white};
    text-overflow: ellipsis;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`
