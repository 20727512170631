import * as d3 from 'd3'
import theme from 'theme'

export const styledXAxisGroup = (xAxisGroup: d3.Selection<SVGGElement, unknown, null, undefined>) => {
  xAxisGroup
    .selectAll('text')
    .attr('color', theme.colors.white)
    .attr('font-size', theme.typography.fontSize.large)
    .attr('font-weight', `${theme.typography.fontWeight.normal}`)
    .attr('letter-spacing', '0.25px')
    .attr('line-height', '20px')
    .attr('font-family', theme.typography.fontFamily.primary)
}
