import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const BenchmarkingContainer = styled(Box)<{ width: number; isempty: string }>`
  display: flex;
  flex-flow: column wrap;
  box-sizing: border-box;
  width: 100%;
  flex-basis: 33.33%;

  .wrapper {
    border-radius: ${theme.attributes.borderRadius.quaternary};
    background: ${theme.colors.backgroundDarker};
    width: 100%;
    padding: ${theme.spacing.medium}px;
    overflow-y: auto;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      width: 100%;

      .header-title {
        display: flex;
        align-items: center;
        margin-right: ${theme.spacing.half}px;

        svg {
          width: 16px;
          height: 16px;
        }

        .title {
          color: ${theme.colors.neutral};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 24px;
          letter-spacing: 0.1px;
          margin-left: ${theme.spacing.xSmall}px;
        }
      }

      .select-wrapper {
        .MuiFormControl-root {
          margin-bottom: 0;

          .MuiOutlinedInput-root {
            outline: 0 !important;
            background: transparent !important;
            border-radius: ${theme.attributes.borderRadius.secondary} !important;
            border: 1px solid ${theme.colors.controlBorder} !important;
            color: ${theme.baseColors.greyscale[80]} !important;
          }
        }
      }
    }

    .card-container {
      gap: ${theme.spacing.small}px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
      width: 100%;
    }
  }
`

export const EmptyBenchmarkingContainer = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  gap: ${theme.spacing.xSmall}px;
  box-sizing: border-box;
  width: ${({ width }) => `${width}px`};
  opacity: 0.38;

  .wrapper {
    border-radius: ${theme.attributes.borderRadius.quaternary};
    background: ${theme.colors.backgroundDarker};
    box-shadow: ${theme.attributes.boxShadow.feedSection};
    width: 100%;
    padding: ${theme.spacing.small}px;
    overflow-y: scroll;
    height: 400px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ::-webkit-scrollbar {
      display: none;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${theme.spacing.sm}px;
      width: 100%;

      .header-title {
        display: flex;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
        }

        .title {
          color: ${theme.colors.neutral};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 24px;
          letter-spacing: 0.1px;
          margin-left: ${theme.spacing.xSmall}px;
        }
      }

      .select-wrapper {
        .MuiFormControl-root {
          margin-bottom: 0;

          .MuiOutlinedInput-root {
            outline: 0 !important;
            background: transparent !important;
            border-radius: ${theme.attributes.borderRadius.secondary} !important;
            border: 1px solid ${theme.colors.controlBorder} !important;
            color: ${theme.baseColors.greyscale[80]} !important;
          }
        }
      }
    }

    .card-container {
      gap: ${theme.spacing.small}px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
      width: 100%;
    }
  }
`
