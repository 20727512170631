import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const PotentialSourcesTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.small}px ${theme.spacing.small}px ${theme.spacing.xSmall}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.chipCircle,
    maxHeight: '258px',
    overflowY: 'auto',

    '&::-webkit-scrollbar-track': {
      background: theme.baseColors.surface[50],
      borderRadius: theme.attributes.borderRadius.secondary
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.textPrimary,
      height: '4px',
      width: '4px',
      border: '4px solid transparent',
      backgroundClip: 'content-box'
    }
  },
  '.tooltip-list': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  '.tooltip-header': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.xLarge,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.bold,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    marginBottom: `${theme.spacing.xSmall}px`
  },
  '.tooltip-wrapper': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: `${theme.spacing.xSmall}px`,

    img: {
      marginRight: `${theme.spacing.xSmall}px`,
      minWidth: '24px'
    }
  },
  '.tooltip-title': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.xLarge,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line'
  },
  '.tooltip-category': {
    textTransform: 'capitalize',
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.medium,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
})
