import { Box, Popper as MuiPopper } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Popper = styled(MuiPopper)`
  position: absolute !important;
  top: 46px !important;
  z-index: 100000;
`

export const SearchVendorsContainer = styled(Box)<{ height?: string }>`
  border-radius: ${theme.attributes.borderRadius.secondary};
  border: 1px solid ${theme.colors.borderLow};
  background: ${theme.colors.surfaceItemBackground};
  box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
  width: 300px;
  height: 100%;
  padding: ${theme.spacing.small}px;
  overflow-y: hidden;
`

export const AutocompleteContainer = styled(Box)`
  position: relative;
  width: 100%;
`

export const SearchVendorsInputWrapper = styled(Box)<{ hasinputvalue?: string }>`
  width: 100%;

  & .MuiFormControl-root {
    width: 100%;
    border-radius: ${theme.attributes.borderRadius.small};
    border: 0;
    outline: 0;
    height: 40px;

    & .MuiInputLabel-root {
      color: ${theme.baseColors.greyscale[80]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;

      &.MuiInputLabel-shrink {
        font-family: ${theme.typography.fontFamily.primary};

        &.Mui-focused {
          color: ${theme.colors.textPrimary};
          font-size: ${theme.typography.fontSize.medium};
          letter-spacing: 0.4px;
        }
      }
    }

    & .MuiOutlinedInput-root {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.5px;
      height: 40px;
      padding: 0 ${theme.spacing.small}px;

      .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
        &:hover {
          cursor: ${({ hasinputvalue }) => (hasinputvalue && hasinputvalue === 'false' ? 'default' : 'pointer')};

          svg {
            path {
              fill: ${({ hasinputvalue }) =>
                hasinputvalue && hasinputvalue === 'false' ? theme.colors.textSecondaryLow : theme.colors.white};
            }
          }
        }
      }

      input {
        padding-left: 0;
        margin-left: -2px;
      }

      fieldset {
        border: 1px solid ${theme.colors.controlBorder};

        span {
          font-size: 8.5px;
        }
      }

      &:hover fieldset {
        border-color: ${theme.colors.controlBorder};
      }

      &.Mui-focused {
        border: none;

        & fieldset {
          border: 2px solid ${theme.colors.textPrimary};
        }
      }
    }

    & .search-vendors-loading-indicator {
      color: ${theme.colors.textPrimary};
    }
  }
`

export const SearchVendorsResultsContainer = styled('div')`
  border-radius: ${theme.attributes.borderRadius.secondary};
  z-index: 1;
  margin-top: ${theme.spacing.xSmall}px;

  .search-vendors-results-text {
    color: ${theme.colors.textSecondaryLow};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.small};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.thick};
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: ${theme.spacing.small}px 0 ${theme.spacing.xSmall}px;
  }

  .search-vendors-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 200px;

    .search-vendors-loading-progress {
      color: ${theme.colors.textPrimary};
    }
  }

  .search-vendors-empty-wrapper {
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.medium}px;

    .search-vendors-empty-title {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.extraThick};
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .search-vendors-empty-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`

export const ResultsList = styled('ul')<{ hideborder?: string; items: number }>`
  font-family: ${theme.typography.fontFamily.primary};
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: ${theme.attributes.borderRadius.secondary};
  height: ${({ items }) => (items && items <= 5 ? `${40 * items}px` : !items ? 0 : '200px')};
  max-height: ${({ items }) => (items && items <= 5 ? `${40 * items}px` : !items ? 0 : '200px')};
  overflow-y: auto;
  height: auto;

  .no-options-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: ${theme.spacing.xSmall}px;

    .no-optons-text {
      color: ${theme.colors.white};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }

  ${({ hideborder }) =>
    hideborder &&
    hideborder === 'true' &&
    `
      border-radius: 0 0 ${theme.attributes.borderRadius.secondary} ${theme.attributes.borderRadius.secondary};
  `};

  ${({ items }) =>
    items > 5 &&
    `
    overflow-y: scroll;
  `};

  ::-webkit-scrollbar {
    width: 6px !important;
    background: ${theme.colors.controlBorder};
  }

  ::-webkit-scrollbar-track {
    width: 6px !important;
    background: ${theme.colors.controlBorder};
    border-radius: ${theme.attributes.borderRadius.small};
  }

  ::-webkit-scrollbar-thumb {
    width: 6px !important;
    background: ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.secondary};
  }
`

export const NoResultsList = styled('ul')<{ hideborder?: string; length: number }>`
  box-sizing: border-box;
  margin-top: 0;
  padding: 0;

  .search-vendors-empty-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large} !important;
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;
    letter-spacing: 0.25px;

    .here-text {
      color: ${theme.colors.textPrimary};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .first {
    margin-bottom: ${theme.spacing.xSmall}px;
    margin-top: ${theme.spacing.small}px;
  }
`

export const ResultListItem = styled('li')<{ iscustomvendor?: string }>`
  cursor: default;
  border-radius: ${theme.attributes.borderRadius.small};

  .list-item-wrapper {
    display: flex;
    align-items: center;
    padding: ${theme.spacing.small}px;
    cursor: default;
    list-style: none;
    background: transparent;
    height: 40px;
    border-radius: ${theme.attributes.borderRadius.small};
    gap: ${theme.spacing.small}px;
  }

  .option-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 274px;

    &.text-disabled {
      color: ${theme.baseColors.greyscale[50]};
    }
  }

  &:hover {
    cursor: pointer;
    background: ${theme.colors.primaryLight};
  }

  ${({ iscustomvendor }) =>
    iscustomvendor &&
    iscustomvendor === 'true' &&
    `    
      &:hover {
        cursor: pointer;
        background: rgba(208, 188, 255, 0.08) !important;  
  
        &:last-of-type {
          box-shadow: none;
          background: transparent;
        }
      }
    
      &:last-of-type {
        background: transparent;
        
        &:hover {
          background: rgba(208, 188, 255, 0.08) !important;
        }
      }
    `}

  img {
    min-width: 24px !important;
    width: 24px !important;
    height: 24px !important;
    object-fit: cover !important;
  }
`

export const NoIntegrationLogo = styled(Box)`
  border: 1px solid #342f32;
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  background: ${theme.baseColors.greyscale[80]};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1280px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1600px) and (max-height: 1079px) {
    width: 22px;
    height: 22px;
  }

  .no-integration-logo-letter {
    color: #342f32;
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: normal;
  }
`
