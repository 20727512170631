import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)<{
  containerWidth: number
  isLastStripe: boolean
  short: string
  xPosition?: number
  yPosition?: number
}>`
  display: flex;
  position: absolute;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  padding: ${theme.spacing.xSmall}px;
  gap: ${theme.spacing.small}px;
  width: 258px;
  left: 20px;

  ${({ short }) =>
    short &&
    short === 'true' &&
    `
      width: 168px;
    `};

  ${({ xPosition, isLastStripe, containerWidth, short }) =>
    xPosition && xPosition < 150
      ? `
        left: calc(${xPosition}px - 80px);
      `
      : isLastStripe && short && short === 'true'
      ? `
        left: calc(${containerWidth}px - 185px);
      `
      : isLastStripe
      ? `
        left: calc(${containerWidth}px - 275px);
      `
      : xPosition && xPosition >= 0
      ? `
      left: calc(${xPosition}px - ${short && short === 'true' ? 90 : 129}px);
    `
      : `
      display: none;
    `}

  ${({ yPosition }) =>
    yPosition &&
    yPosition === 4 &&
    `
      top: 9px;
    `}
  
  ${({ yPosition }) =>
    yPosition &&
    yPosition === 3 &&
    `
      top: calc(101.4px + 20px);
    `}
  
  ${({ yPosition }) =>
    yPosition &&
    yPosition === 2 &&
    `
      top: calc(101.4 * 2px + 25px);
    `}
  
  ${({ yPosition }) =>
    yPosition &&
    yPosition === 1 &&
    `
      top: calc(101.4 * 3px + 30px);
    `}
  
  p {
    margin: 0;
  }

  .label-card__content {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xSmall}px;
    flex-grow: 1;

    .label-card__header {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;

      svg {
        min-width: 18px;
      }

      .label-card__header-text {
        text-align: center;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    .label-card__text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 16px;
      letter-spacing: 0.5px;
      word-break: break-word;
      /* Truncate 2 lines of text */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .label-card__subtext {
      color: ${theme.colors.controlBorder};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .label-card__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;

    .action-icon-button {
      border: 0.7px solid ${theme.colors.controlBorder};
    }
  }
`
