import { Box, DialogActions, DialogContent } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DeleteUsersContainer = styled(Box)``

export const Header = styled(Box)`
  padding: 8px 16px 16px;
  border-bottom: 1px solid #342f32;

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: 36px;
    line-height: 44px;
    color: ${theme.colors.neutral};
  }
`

export const Content = styled(DialogContent)`
  padding: ${theme.spacing.medium}px 58px 48px;

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.header};
    color: ${theme.colors.neutral};
    margin-bottom: ${theme.spacing.medium}px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiFormControl-root {
      margin-bottom: ${theme.spacing.medium}px;
      width: 240px;

      label {
        color: ${theme.baseColors.greyscale[80]};

        .MuiInputLabel-asterisk {
          color: #fe455d;
        }
      }

      .MuiOutlinedInput-root {
        border-radius: ${theme.attributes.borderRadius.small};
        color: ${theme.baseColors.greyscale[80]};
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.xLarge};
        line-height: 24px;
        letter-spacing: 0.5px;

        fieldset {
          border: 1px solid ${theme.colors.controlBorder} !important;
        }
      }
    }
  }
`

export const Actions = styled(DialogActions)`
  background: ${theme.colors.backgroundDarker};
  box-shadow: ${theme.attributes.boxShadow.toolbar};
  border-radius: 0 0 16px 16px;
  padding: 16px 36px;

  .MuiButtonBase-root {
    &:last-of-type {
      margin-left: 24px;
      background: #93000a;
      border: none;
      color: #ffb4ab;

      svg {
        path {
          fill: #ffb4ab;
        }
      }
    }
  }
`
