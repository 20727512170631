import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const IntegrationHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: `${theme.spacing.sm}px`,
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.chipCircle
  },
  '.tooltip-text': {
    color: theme.colors.neutral,
    fontSize: theme.typography.fontSize.large,
    fontFamily: theme.typography.fontFamily.primary,
    lineHeight: '20px',
    letterSpacing: '0.25px'
  }
})
