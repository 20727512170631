import { FC, Dispatch,SetStateAction } from 'react'
import { Box } from '@mui/material'

/* Utils */
import {
  IChartData,
  IFramework,
  IntegrationInstance
} from '../../../../models'

/* Components */
import PerformanceScore from './PerformanceScore'
import InsightsWidget from './InsightsWidget'
import OverallPerformanceScore from './OverallPerformanceScore/OverallPerformanceScore'

interface Props {
  performanceScore: number
  cpisActivated: number
  chartLoading: boolean
  initialLoad: boolean
  chartData: IChartData[]
  noCategories: boolean
  configuredIntegrations: IntegrationInstance[]
  activeUsers: number
  availableCpis: string[]
  handleCategoryClick: (category: string) => void
  lastUpdatePerformance: string
  unreadInsightsCount: number
  insightsCountLoading: boolean
  insightsData: any
  setHoveredCard: Dispatch<SetStateAction<string>>
  customFramework: IFramework | null
}

const DashboardHero: FC<Props> = ({
  performanceScore,
  chartLoading,
  initialLoad,
  chartData,
  noCategories,
  // cpisActivated,
  // configuredIntegrations,
  // activeUsers,
  // availableCpis,
  handleCategoryClick,
  lastUpdatePerformance,
  unreadInsightsCount,
  insightsCountLoading,
  insightsData,
  setHoveredCard,
  customFramework
}) => {
  return (
    <>
      <Box className="performance-score-section">
        <OverallPerformanceScore performanceScore={performanceScore} />
        <PerformanceScore
          chartLoading={chartLoading}
          initialLoad={initialLoad}
          chartData={chartData}
          isEmpty={false}
          handleCategoryClick={handleCategoryClick}
          noCategories={noCategories}
          lastUpdatePerformance={lastUpdatePerformance}
          setHoveredCard={setHoveredCard}
          customFramework={customFramework}
        />
      </Box>
      <InsightsWidget
        unreadInsightsCount={unreadInsightsCount}
        insightsCountLoading={insightsCountLoading}
        insightsData={insightsData}
      />
    </>
  )
}

export default DashboardHero
