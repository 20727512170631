/* eslint camelcase: 0 */
import { Box, FormControl as MuiFormControl, Select as MuiSelect } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 0

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`

export const TextWrapper = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.small} 0 0 ${theme.attributes.borderRadius.small};
  border-top: 1px solid ${theme.colors.controlBorder};
  border-bottom: 1px solid ${theme.colors.controlBorder};
  border-left: 1px solid ${theme.colors.controlBorder};
  background: ${theme.colors.surfaceItemBackgroundLow};
  padding: 9.5px ${theme.spacing.small}px;
  height: 40px;

  .MuiTypography-root {
    color: ${theme.colors.white};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    white-space: nowrap;
  }
`

export const FormControl = styled(MuiFormControl)<{
  isselected?: string
  is_opened: string
  is_disabled: string
  nolabel?: string
}>`
  width: 100%;
  background: transparent;
  outline: none;
  min-width: 200px;
  height: 40px;
  border-radius: ${({ nolabel }) =>
    nolabel && nolabel === 'true'
      ? theme.attributes.borderRadius.small
      : `0px ${theme.attributes.borderRadius.small} ${theme.attributes.borderRadius.small} 0px`};
  border: 1px solid ${theme.colors.controlBorder};

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${theme.colors.white};
    text-align: left;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    letter-spacing: 0.1px;
    height: 40px;
  }

  &:hover {
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: ${theme.colors.textPrimary};
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: ${theme.spacing.small}px;

    path {
      fill: ${theme.colors.white};
    }
  }

  ${({ is_disabled }) =>
    is_disabled &&
    is_disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      
      &:hover {
        border-radius: ${theme.attributes.borderRadius.large};
        background: transparent;
    
        .MuiOutlinedInput-root {
          color: ${theme.colors.textPrimary};
        }
      }
      
       .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};

  ${({ is_opened }) =>
    is_opened &&
    is_opened === 'true' &&
    `
      border: 1px solid ${theme.colors.textPrimary};
      background: rgba(208, 188, 255, 0.16);
      
      .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};
`

export const Select = styled(MuiSelect)<{ isselected?: string; noborder?: string }>`
  min-width: 200px;
  height: 40px;
  background: transparent;
  border-radius: 0 ${theme.attributes.borderRadius.small} ${theme.attributes.borderRadius.small} 0;
  border: 1px solid ${theme.colors.controlBorder};
  gap: ${theme.spacing.xSmall}px;
    
  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};
    
  color: ${theme.colors.white};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: ${theme.baseColors.secondary[40]};
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    color: ${theme.baseColors.greyscale[80]};
  }
  
  .input-value-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing.xSmall}px;

    .input-value-count {
      color: ${theme.colors.textSecondaryLow};
      text-align: right;
      text-overflow: ellipsis;
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      margin-right: ${theme.spacing.xSmall}px;
    }
`

export const MenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.surfaceItemBackground,
      border: `1px solid ${theme.colors.borderLow}`,
      borderRadius: theme.attributes.borderRadius.small,
      padding: `${theme.spacing.xSmall}px`,
      minWidth: '167px',
      width: '167px',

      '.MuiList-root': {
        paddingTop: '0',
        paddingBottom: '0'
      },

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: '167px',
        height: '40px',
        padding: `${theme.spacing.xSmall}px`,
        borderRadius: theme.attributes.borderRadius.small,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.08)'
        },

        '.MuiListItemText-root .MuiTypography-root': {
          color: theme.colors.neutral,
          fontFamily: theme.typography.fontFamily.primary,
          fontSize: theme.typography.fontSize.xLarge,
          fontStyle: 'normal',
          fontWeight: theme.typography.fontWeight.normal,
          lineHeight: '24px',
          letterSpacing: '0.5px'
        }
      },

      '.Mui-selected': {
        'background-color': 'rgba(208, 188, 255, 0.16) !important'
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: theme.attributes.borderRadius.secondary
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
