import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import TextTruncate from 'react-text-truncate'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import axios from '../../../../../lib/axios'
import theme from 'theme'

/* Utils */
import { IntelInsight } from '../../../../../models'

/* Components */
import { IntelFeedBackground, IntelFeedCardWrapper } from '../../dashboard.styles'
import { LibraryDetailsInfoTooltip } from '../../../library/library-details/components/LibraryDetailsTooltip'

dayjs.extend(relativeTime)

interface Props {
  intelInsight: IntelInsight
  callback: () => void
  setDeleting: Dispatch<SetStateAction<boolean>>
  missingImageSourcesMap: Map<string, string>
}

const IntelFeedCard: FC<Props> = ({ intelInsight, callback, setDeleting, missingImageSourcesMap }) => {
  const [saved, setSaved] = useState(intelInsight.bookmarked)
  const [infoTooltipOpen, setInfoTooltipOpen] = useState(false)

  const handleSave = async () => {
    setSaved(!saved)
    setDeleting(true)

    try {
      const payload = {
        target: {
          iid: intelInsight.id
        }
      }

      if (saved) {
        await axios.delete('/api/v1/bookmarks', {
          data: {
            ...payload
          }
        })
      } else {
        await axios.post('/api/v1/bookmarks', payload)
      }
      callback()
    } catch (e) {
      console.error(e)
    } finally {
      setDeleting(false)
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(intelInsight.url).catch((e) => e)
    setInfoTooltipOpen(true)
    setTimeout(() => {
      setInfoTooltipOpen(false)
    }, 3000)
  }

  const handleFullReport = () => {
    window.open(intelInsight.url, '_blank')
  }

  const getDefaultSourceCardImage = (intelInsight: IntelInsight) => {
    const { source_icon: sourceIconPath } = intelInsight
    const lastSlashIndex = sourceIconPath.lastIndexOf('/')
    const untilLastSlashSlice = `${sourceIconPath.slice(0, lastSlashIndex)}`

    if (sourceIconPath.endsWith('news_filler_logo.png')) {
      return `${untilLastSlashSlice}/default_images/${getMissingSourceImages(intelInsight)}`
    }

    return `${untilLastSlashSlice}/default_images${sourceIconPath.slice(lastSlashIndex)}`
  }

  /*
   * This is a temporary workaround until we can merge new changes to the insights-api repo.
   */
  const getMissingSourceImages = (intelInsight: IntelInsight) => {
    return `${missingImageSourcesMap.get(intelInsight.source_name)}.png` || 'news_filler_logo.png'
  }

  const defaultSourceCardImage = getDefaultSourceCardImage(intelInsight)

  return (
    <IntelFeedCardWrapper read={false}>
      <Box className="header-container">
        <IntelFeedBackground
          url={intelInsight.image ? intelInsight.image.url : defaultSourceCardImage}
          fallBackUrl={defaultSourceCardImage}
        />
        <Typography className="title">{intelInsight.title}</Typography>
      </Box>

      <Box className="expanded-text">
        <TextTruncate line={3} element="span" truncateText="…" text={intelInsight.summary} textTruncateChild={null} />
      </Box>

      <Typography className="expanded-subtext">
        {dayjs(intelInsight.timestamp).fromNow()} &middot; {intelInsight.source_name}
      </Typography>

      <Box className="expanded-actions">
        <Button className="report-button" onClick={handleFullReport}>
          <ExitToAppIcon /> Full Report
        </Button>

        <Box className="expanded-icons">
          <IconButton onClick={handleSave}>{saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}</IconButton>
          <LibraryDetailsInfoTooltip
            PopperProps={{
              disablePortal: true,
              sx: {
                marginTop: `-${theme.spacing.sm}px !important`
              }
            }}
            open={infoTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={() => setInfoTooltipOpen(false)}
            arrow
            title={
              <>
                <Box className="tooltip-wrapper">
                  <Typography className="tooltip-text">Article url copied to clipboard</Typography>
                </Box>
              </>
            }
          >
            <IconButton className="tooltip-icon" onClick={handleCopy}>
              <ContentCopyIcon />
            </IconButton>
          </LibraryDetailsInfoTooltip>
        </Box>
      </Box>
    </IntelFeedCardWrapper>
  )
}

export default IntelFeedCard
