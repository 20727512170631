import { Filters } from '../ui/pages/onyxai-insights/OnyxAIInsights'
import { useQuery } from 'react-query'
import axios from '../lib/axios'
import dayjs from 'dayjs'
import { dateFormat } from '../lib/utils'
import { LabelOption } from '../ui/pages/library/components/LibrarySelectLabelField'

export function useSearchInsights({
  value,
  filters,
  reset,
  enabled,
  dataSourceOptions,
  cpiOptions,
  checkReadStatus,
  customTimePeriodValue,
  lastChanged
}: {
  value: string
  filters: Filters
  reset: boolean
  keepPreviousData?: boolean
  enabled?: boolean
  staleTime?: any
  changeReadStatus: (integrationIds: string[], read: boolean) => Promise<void>
  initialLoad?: boolean
  checkReadStatus: (integrationIds: string[]) => Promise<void>
  dataSourceOptions: LabelOption[]
  cpiOptions: LabelOption[]
  customTimePeriodValue: string
  lastChanged: string
}) {
  const url = '/api/v3/insights/search?search-new'
  const { time, subType, dataSources, cpis, severity, sortDirection, sortBy } = filters
  const newPayload: any = {
    term: value
  }

  if (!reset) {
    if (time.length) {
      if (time === 'Today') {
        newPayload.date_from = dayjs().format(dateFormat.customLabel)
        newPayload.date_to = dayjs().format(dateFormat.customLabel)
      }
      if (time === 'Last Month') {
        const today = dayjs().format('YYYY-MM-DD')
        const past30 = dayjs(today).subtract(30, 'days')
        newPayload.date_from = dayjs(past30).format('YYYY-MM-DD')
        newPayload.date_to = today
        // const thisStartOfMonth = startOfMonth(new Date())
        // newPayload.date_from = dayjs(thisStartOfMonth).format(dateFormat.customLabel)
        // newPayload.date_to = dayjs().format(dateFormat.customLabel)
      }
      // if (time === 'From Last Session') {
      //   const today = dayjs().format('YYYY-MM-DD')
      //   const past30 = dayjs(today).subtract(30, 'days')
      //   const thirtyDaysAgo = dayjs(past30).format('YYYY-MM-DD')
      //   newPayload.date_from = thirtyDaysAgo
      //   newPayload.date_to = today
      // }
      if (time === 'Custom') {
        const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const parts = customTimePeriodValue.split('-')
        const sub1 = parts[0].split(" '")
        const sub2 = parts[1].split(" '")
        const startMonth = String(shortMonths.findIndex((n) => n === sub1[0]) + 1).padStart(2, '0')
        const startYear = sub1.length > 1 ? `20${sub1[1]}` : `20${sub2[1]}`
        const endMonth = String(shortMonths.findIndex((n) => n === sub2[0]) + 1).padStart(2, '0')
        const endYear = `20${sub2[1]}`
        const daysInEndMonth = dayjs(`${endYear}-${endMonth}-01`).daysInMonth()
        newPayload.date_from = dayjs(`${startYear}-${startMonth}-01`).format(dateFormat.customLabel)
        newPayload.date_to = dayjs(`${endYear}-${endMonth}-${daysInEndMonth}`).format(dateFormat.customLabel)
      }
    }
    if (subType.length) {
      newPayload.sub_types = subType
      if (subType.length === 1 && subType[0] === 'all') {
        delete newPayload.sub_types
      }
    }
    if (dataSources.length) {
      const integrationIds = dataSourceOptions.filter((n) => dataSources.includes(n.label))
      newPayload.instance_ids = integrationIds.map((c) => c.id)
    }
    if (cpis.length) {
      newPayload.cpis = cpiOptions.filter((c) => cpis.includes(c.label)).map((n) => n.name)
    }
    if (severity.length) {
      newPayload.sections = severity.map((c) => c.toLowerCase())

      if (severity.length === 1 && severity[0] === 'all') {
        delete newPayload.sections
      }
      // if (!severity.includes('All')) {
      //   newPayload.sections = [severity.toLowerCase().replace(' severities', '')]
      // } else {
      //   delete newPayload.sections
      // }
    }
    if (sortDirection) {
      newPayload.sort_direction = sortDirection
    }
    if (sortBy) {
      newPayload.sort_by = sortBy
    }
  }

  const queryKey = ['insights', lastChanged, { ...newPayload }]
  const { data, isFetching } = useQuery(queryKey, () => axios.post(url, newPayload), {
    keepPreviousData: false,
    initialData: undefined,
    enabled,
    staleTime: Infinity
  })
  const ids = (data as any)?.data.data.map((n: any) => n.id) || []

  const {
    data: withReadStatus,
    refetch,
    isFetching: isFetching2
  } = useQuery(
    [data && 'ids', ids, lastChanged],
    async () => {
      if (!ids.length) return
      const response: any = await checkReadStatus(ids)

      return (data as any)?.data.data.map((n: any) => ({ ...n, read: response.readIds.includes(n.id) }))
    },
    {
      keepPreviousData: false,
      initialData: undefined,
      enabled: data && (data as any)?.data.data.length > 0,
      staleTime: Infinity
    }
  )

  return {
    isFetching: isFetching,
    isFetching2: isFetching2,
    loading: isFetching || isFetching2,
    data: withReadStatus,
    refetch
  }
}
