import styled from '@emotion/styled/macro'
import { Box, Tabs } from '@mui/material'
import theme from 'theme'

export const LabelsShowModeWrapper = styled(Box)`
  margin-top: ${theme.spacing.xSmall}px;
`

export const TabPanelWrapper = styled(Box)`
  border-top: 1px solid rgba(222, 189, 217, 0.25);
  padding-top: ${theme.spacing.small}px;

  .filters-wrapper {
    padding-bottom: ${theme.spacing.small}px;
    border-bottom: 2px solid rgba(222, 189, 217, 0.5);

    .filters-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: ${theme.spacing.medium}px;
      padding-left: ${theme.spacing.medium}px;

      .dates {
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    .filter-button {
      border-radius: 70px;
      border: 0.7px solid ${theme.colors.controlBorder};
      background: rgba(153, 141, 150, 0.15);
    }
  }

  .info {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .content {
    padding-top: ${theme.spacing.small}px;
    padding-right: ${theme.spacing.medium}px;
    padding-left: ${theme.spacing.medium}px;
    overflow-y: scroll;
    height: calc(100vh - 234px);

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const DateLabelPanel = styled(Box)`
  padding-top: ${theme.spacing.small}px;

  .date {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: ${theme.spacing.small}px;
  }
`

export const LabelsTabs = styled(Tabs)<{ isdetails?: string }>`
  background: transparent;
  height: 24px;
  min-height: 24px;

  .MuiTabs-scroller {
    height: 24px;

    .MuiTabs-flexContainer {
      height: 24px;
    }
  }

  .MuiButtonBase-root {
    border-radius: 0;
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xLarge};
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: #debdd9;
    border: none;
    text-transform: none;
    padding: 0;
    width: 94px;
    height: 24px;
    min-height: 24px;

    &.Mui-selected {
      color: ${theme.colors.textPrimary};
      border-bottom: 2px solid ${theme.colors.textPrimary};
    }
  }

  ${({ isdetails }) =>
    isdetails &&
    isdetails === 'true' &&
    `
    height: 30px;
    min-height: 30px;
    
    .MuiTabs-scroller {
      height: 30px;
      
      .MuiTabs-flexContainer {
        height: 30px;
         width: 100%;
         justify-content: space-between;
      }
    }
    
    .MuiButtonBase-root {
      height: 30px;
      white-space: nowrap;
      width: 50%;
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  `};
`
