import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row !important;
  gap: ${theme.spacing.xSmall}px !important;
  flex-basis: 100%;

  .section {
    flex-basis: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xSmall}px;

    .header {
      width: fit-content;
      color: ${theme.baseColors.secondary[80]};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }

    .list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.xSmall}px;
      flex-basis: 50%;

      .separator {
        background: ${theme.colors.textSecondaryLow};
        height: 1px;
        width: 100%;
      }

      .list-item-onyxia {
        border-radius: ${theme.attributes.borderRadius.small};
        background: ${theme.baseColors.surface[50]};
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 ${theme.spacing.xSmall}px;

        .list-item-text {
          overflow: hidden;
          color: ${theme.colors.white};
          text-overflow: ellipsis;
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
        }
      }
    }
  }
`
