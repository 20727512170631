import { Box, Breadcrumbs } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const HeaderContainer = styled(Box)`
  position: relative;
  z-index: 100;
  border-bottom: 1px solid ${theme.colors.borderLow};
  margin-left: ${theme.spacing.medium}px;
  margin-right: ${theme.spacing.medium}px;
  margin-top: 50px;

  .breadcrumbs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 100%;
    padding: ${theme.spacing.small}px 0 0 ${theme.spacing.medium}px;

    .header-actions {
      display: flex;
      align-items: center;

      .favorite-icon {
        margin-right: 34px;

        .MuiButtonBase-root {
          &:hover,
          &:focus,
          &:active {
            background: rgba(202, 196, 208, 0.08);
          }
        }
      }
    }
  }

  .title-wrapper {
    padding-left: ${theme.spacing.medium}px;

    &.title-wrapper {
      padding: 0 0 ${theme.spacing.medium}px ${theme.spacing.medium}px;

      .header-title {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xxxxLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 36px;
        text-transform: capitalize;
        margin-top: 18px;
        margin-bottom: ${theme.spacing.xSmall}px;
      }
    }
  }
`

export const HeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/category-page-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: ${theme.attributes.boxShadow.quaternary};
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  mix-blend-mode: luminosity;
  border-radius: ${theme.attributes.borderRadius.secondary} ${theme.attributes.borderRadius.secondary} 0 0;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: ${theme.colors.textPrimary};
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`
