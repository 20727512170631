import { FC } from 'react'
import { Box, TableBody, TableCell, TableHead, TableRow, Skeleton } from '@mui/material'
import { TableContainerW, TableWrapper } from './table-components/DataTable.styles'
import { defaultTableHeaders, TableHeaders } from '../../../../models'

interface Props {
  height?: string
  columns?: TableHeaders[]
}

const LoadingSkeleton: FC<Props> = ({ height, columns }) => {
  const rows = Array(height && !isNaN(parseInt(height)) ? Math.round(parseInt(height) / 47) : 25).fill(true)
  const cells = Array.isArray(columns) ? columns : Array(7).fill(true)

  return (
    <Box>
      <TableContainerW height={height || '100%'} hideoverflow="true">
        <TableWrapper
          sx={{ minWidth: 650 }}
          stickyHeader
          aria-label="sticky table loading"
          style={{ tableLayout: 'fixed' }}
        >
          <TableHead>
            {columns && columns.length > 0 ? (
              <TableRow>
                {columns.map((header) => (
                  <TableCell key={header.name}>{header.name}</TableCell>
                ))}
              </TableRow>
            ) : (
              <TableRow>
                {defaultTableHeaders.map((header) => (
                  <TableCell key={header.name}>{header.name}</TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {cells.map((cell, idx) => (
                  <TableCell key={idx} className="loading-cell-wrapper">
                    <Skeleton animation="wave" variant="rectangular" width="100%" height="20px" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </TableWrapper>
      </TableContainerW>
    </Box>
  )
}

export default LoadingSkeleton
