import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const IconTooltipDisabled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: '197px',
    minHeight: '33px',
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    boxShadow: theme.attributes.boxShadow.overallScoreWrapper,
    background: theme.colors.surfaceItemBackgroundLow
  },
  '.tooltip-wrapper.is-disabled-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '.arrow-right': {
      position: 'absolute',
      top: '2px',
      right: '-32px'
    },

    '.is-disabled-text': {
      color: theme.colors.white,
      fontFamily: theme.typography.fontFamily.primary,
      fontSize: theme.typography.fontSize.large,
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeight.normal,
      lineHeight: 'normal'
    }
  },
  '.tooltip-text': {
    color: theme.colors.white,
    fontSize: theme.typography.fontSize.mediumSmall,
    fontFamily: theme.typography.fontFamily.primary,
    lineHeight: '16px',
    fontWeight: theme.typography.fontWeight.bold,
    letterSpacing: '0.5px'
  },
  '.tooltip-subtext': {
    color: theme.colors.white,
    fontSize: theme.typography.fontSize.medium,
    fontFamily: theme.typography.fontFamily.primary,
    lineHeight: '16px',
    fontWeight: theme.typography.fontWeight.normal,
    letterSpacing: '0.4px'
  },
  '.tooltip-cpi-text': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    fontWeight: theme.typography.fontWeight.bold,
    lineHeight: '20px',
    letterSpacing: '0.1px'
  },
  '.tooltip-category': {
    textTransform: 'capitalize',
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.medium,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
})
