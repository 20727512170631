import theme from 'theme'

const LibraryCardLoading = () => {
  return (
    <svg width="920" height="176" viewBox="0 0 920 176" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="920" height="176" rx="16" fill={theme.colors.primaryDark} />
      <rect width="920" height="176" rx="16" fill={theme.colors.textPrimary} fillOpacity="0.08" />
      <mask
        id="mask0_10_8636"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="920"
        height="176"
      >
        <rect width="920" height="176" rx="16" fill="url(#paint0_linear_10_8636)" />
      </mask>
      <g mask="url(#mask0_10_8636)">
        <rect x="20" y="44" width="562" height="31" rx="15.5" fill={theme.colors.primaryLight} />
        <rect x="729" y="76" width="82" height="41" rx="20.5" fill={theme.colors.primaryLight} />
        <rect x="819" y="76" width="41" height="41" rx="20.5" fill={theme.colors.primaryLight} />
        <rect x="20" y="84" width="504" height="19" rx="9.5" fill={theme.colors.primaryLight} />
        <rect x="20" y="135" width="108" height="19" rx="9.5" fill={theme.colors.primaryLight} />
        <rect x="20" y="21" width="108" height="19" rx="9.5" fill={theme.colors.primaryLight} />
        <rect x="136" y="135" width="108" height="19" rx="9.5" fill={theme.colors.primaryLight} />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10_8636"
          x1="920"
          y1="87.9999"
          x2="-4.96107e-06"
          y2="87.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primaryLight} stopOpacity="0.290323" />
          <stop offset="0.515625" stopColor={theme.colors.primaryLight} />
          <stop offset="1" stopColor={theme.colors.primaryLight} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LibraryCardLoading
