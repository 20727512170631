import React, { Dispatch, FC, SetStateAction } from 'react'
import { SearchBarWrapper } from './tableSearch.styles'
import { Box, IconButton, TextField } from '@mui/material'
import SearchWhiteIcon from '../../../../../components/svg/SearchWhiteIcon'
import ClearSvg from '../../../../../components/svg/ClearSvg'
import theme from 'theme'

interface Props {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}

const TableSearch: FC<Props> = ({ search, setSearch }) => {
  const inputHandler = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    setSearch(e.target.value)
  }

  return (
    <SearchBarWrapper>
      <Box className="search-icon">
        <SearchWhiteIcon />
      </Box>
      <TextField
        className="search-bar"
        value={search}
        placeholder={'Search...'}
        onInput={(e) => inputHandler(e)}
        onKeyDown={(e) => e.stopPropagation()}
        autoFocus={true}
      />
      {search && (
        <IconButton className="clear-btn" onClick={() => setSearch('')}>
          <ClearSvg color={theme.colors.white} />
        </IconButton>
      )}
    </SearchBarWrapper>
  )
}

export default TableSearch
