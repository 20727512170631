export const baseUnit = 8

export const iconSize = {
  xSmall: baseUnit, // 8
  small: baseUnit * 2, // 16
  medium: baseUnit * 3, // 24
  large: baseUnit * 4, // 32
  xLarge: baseUnit * 5 // 40
}

/*
 * padding & margin
 * */
export const spacing = {
  half: baseUnit / 2,
  xsm: 6,
  sm: 10,
  md: 12,
  lg: 20,
  xSmall: baseUnit, // 8
  small: baseUnit * 2, // 16
  medium: baseUnit * 3, // 24
  large: baseUnit * 4, // 32
  xLarge: baseUnit * 5 // 40
}
