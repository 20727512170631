import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const IconTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    wordWrap: 'wrap',
    textAlign: 'center',
    gap: `${theme.spacing.sm}px`,
    minWidth: '85px',
    minHeight: '50px',
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.surfaceItemBackgroundLow,
    boxShadow: theme.attributes.boxShadow.overallScoreWrapper,
    position: 'relative'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column wrap',

    '.arrow-right': {
      position: 'absolute',
      top: '17.5px',
      right: '-13px'
    }
  },
  '.tooltip-text': {
    color: theme.colors.white,
    fontSize: theme.typography.fontSize.mediumSmall,
    fontFamily: theme.typography.fontFamily.primary,
    lineHeight: '16px',
    fontWeight: theme.typography.fontWeight.bold,
    letterSpacing: '0.5px'
  },
  '.tooltip-subtext': {
    color: theme.colors.white,
    fontSize: theme.typography.fontSize.medium,
    fontFamily: theme.typography.fontFamily.primary,
    lineHeight: '16px',
    fontWeight: theme.typography.fontWeight.normal,
    letterSpacing: '0.4px'
  },
  '.tooltip-cpi-text': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    fontWeight: theme.typography.fontWeight.bold,
    lineHeight: '20px',
    letterSpacing: '0.1px'
  },
  '.tooltip-category': {
    textTransform: 'capitalize',
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.medium,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
})
