/* eslint camelcase: 0 */
import { Box, Breadcrumbs } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const HeaderContainer = styled(Box)`
  justify-content: flex-end;
  display: flex;
  width: 100%;

  .header-content-container {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;

    .title-wrapper {
      .header-title {
        color: ${theme.colors.neutral};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xxxxLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 36px;
        text-transform: capitalize;
      }
    }

    .search-container {
      width: 300px;
    }

    .date-select-container {
      position: relative;
      display: flex;
      align-items: center;
      gap: ${theme.spacing.medium}px;

      .filter-icon-block {
        position: relative;
        display: flex;
        border-radius: ${theme.attributes.borderRadius.circle};
        background: transparent;

        .active-circle {
          position: absolute;
          top: -3px;
          right: -5px;
          background: ${theme.colors.primaryDark};
          border-radius: ${theme.attributes.borderRadius.circle};
          display: flex;
          align-items: center;
          justify-content: center;
          width: 14px;
          height: 14px;

          .circle {
            width: 8px;
            height: 8px;
            background: ${theme.baseColors.info[30]};
            border-radius: ${theme.attributes.borderRadius.circle};
          }
        }
      }

      .date-range-picker-container {
        position: relative;
        display: flex;
        align-items: center;
        gap: ${theme.spacing.xSmall}px;

        .MuiFormControl-root {
          min-width: 113px;
          height: 40px;
          border-radius: ${theme.attributes.borderRadius.large};

          .MuiOutlinedInput-root {
            min-width: 113px;
            height: 40px;
            border-radius: ${theme.attributes.borderRadius.large};
            color: ${theme.baseColors.greyscale[80]};
            text-align: left;
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.large};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            line-height: 20px;
            letter-spacing: 0.1px;
          }
        }
      }
    }
  }
`

export const DateRangePickerContainer = styled(Box)`
  display: flex;
  position: relative;
`

export const HeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/category-page-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: ${theme.attributes.boxShadow.quaternary};
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  mix-blend-mode: luminosity;
  border-radius: ${theme.attributes.borderRadius.mediumSmall} ${theme.attributes.borderRadius.mediumSmall} 0 0;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.large};
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: #debdd9;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`
