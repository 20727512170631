import { FC, SVGProps } from 'react'
import theme from 'theme'

const BenchmarkingIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.2 21V7.59597C14.22 7.23598 15.036 6.41998 15.396 5.39998H19.2L15.864 13.188C15.66 13.656 15.588 14.196 15.732 14.688C16.2 16.248 17.844 17.4 19.8 17.4C21.756 17.4 23.412 16.248 23.868 14.688C24.012 14.196 23.94 13.656 23.736 13.188L20.4 5.39998H21.6C22.26 5.39998 22.8 4.85998 22.8 4.19998C22.8 3.53998 22.26 2.99998 21.6 2.99998H15.396C14.904 1.59598 13.572 0.599976 12 0.599976C10.428 0.599976 9.096 1.59598 8.604 2.99998H2.4C1.74 2.99998 1.2 3.53998 1.2 4.19998C1.2 4.85998 1.74 5.39998 2.4 5.39998H3.6L0.264 13.188C0.06 13.656 -0.0120001 14.196 0.132 14.688C0.588 16.248 2.244 17.4 4.2 17.4C6.156 17.4 7.812 16.248 8.268 14.688C8.412 14.196 8.34 13.656 8.136 13.188L4.8 5.39998H8.604C8.964 6.41998 9.78 7.23598 10.8 7.59597V21H13.2ZM10.8 21H1.2C0.54 21 0 21.54 0 22.2C0 22.86 0.54 23.4 1.2 23.4H22.8C23.46 23.4 24 22.86 24 22.2C24 21.54 23.46 21 22.8 21H13.2H10.8ZM22.044 13.8H17.556L19.8 8.56798L22.044 13.8ZM6.444 13.8H1.956L4.2 8.56798L6.444 13.8ZM12 5.39998C11.34 5.39998 10.8 4.85998 10.8 4.19998C10.8 3.53998 11.34 2.99998 12 2.99998C12.66 2.99998 13.2 3.53998 13.2 4.19998C13.2 4.85998 12.66 5.39998 12 5.39998Z"
      fill={theme.colors.white}
    />
  </svg>
)

export default BenchmarkingIcon
