import { FC } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { ToastVariant } from '../../../store/toasts/toastSlice'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const Wrapper = styled(Box)<{ variant: ToastVariant }>`
  min-width: 451px;
  max-width: 451px;
  width: fit-content;
  min-height: 76px;
  position: fixed;
  right: ${theme.spacing.xLarge}px;
  background: #6dd58c;
  background: ${({ variant }) =>
    variant === ToastVariant.success
      ? theme.baseColors.info[20]
      : variant === ToastVariant.warning
      ? '#7FCFFF'
      : variant === ToastVariant.neutral
      ? theme.colors.borderLow
      : theme.baseColors.info[50]};
  display: flex;
  align-items: center;
  z-index: 10001;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  bottom: -30px;
  animation: slidein 0.5s forwards;
  border-radius: ${theme.attributes.borderRadius.small};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);

  @keyframes slidein {
    from {
      bottom: -30px;
      opacity: 0;
    }
    to {
      bottom: ${theme.spacing.xLarge}px;
      opacity: 1;
    }
  }

  .close-button {
    margin-right: ${theme.spacing.small}px;
  }

  .svg-wrapper {
    padding: ${theme.spacing.small}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column wrap;
    text-align: left;
    flex-grow: 1;
    gap: ${theme.spacing.xSmall}px;
    margin-right: ${theme.spacing.small}px;
    padding: ${theme.spacing.xSmall}px;

    .text {
      color: ${theme.baseColors.greyscale[10]};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;
    }

    .neutral-text {
      color: ${theme.colors.neutral};
    }

    .description {
      color: ${theme.baseColors.greyscale[10]};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      white-space: pre-line;
      max-width: 451px;
    }

    .neutral-description {
      color: ${theme.colors.neutral};
    }
  }
`

interface Props {
  variant: ToastVariant
  text: string
  description: string
  handleClose: () => void
  sx?: Record<string, unknown>
}

const MainLayoutToast: FC<Props> = ({ variant, text, description, handleClose, sx }) => (
  <Wrapper variant={variant} sx={sx}>
    {variant === ToastVariant.error ? (
      <Box className="svg-wrapper">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill={theme.baseColors.greyscale[10]} />
          <path
            d="M28 13.6114L26.3886 12L20 18.3886L13.6114 12L12 13.6114L18.3886 20L12 26.3886L13.6114 28L20 21.6114L26.3886 28L28 26.3886L21.6114 20L28 13.6114Z"
            fill={theme.baseColors.info[50]}
          />
        </svg>
      </Box>
    ) : variant === ToastVariant.warning ? (
      <Box className="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect x="6" y="6" width="12" height="12" fill={theme.baseColors.primary[10]} />
        </svg>
      </Box>
    ) : variant === ToastVariant.neutral ? (
      <Box className="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.37 5.51C9.19 6.15 9.1 6.82 9.1 7.5C9.1 11.58 12.42 14.9 16.5 14.9C17.18 14.9 17.85 14.81 18.49 14.63C17.45 17.19 14.93 19 12 19C8.14 19 5 15.86 5 12C5 9.07 6.81 6.55 9.37 5.51ZM12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 11.54 20.96 11.08 20.9 10.64C19.92 12.01 18.32 12.9 16.5 12.9C13.52 12.9 11.1 10.48 11.1 7.5C11.1 5.69 11.99 4.08 13.36 3.1C12.92 3.04 12.46 3 12 3Z"
            fill={theme.colors.neutral}
          />
        </svg>
      </Box>
    ) : (
      <Box className="svg-wrapper">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill={theme.baseColors.greyscale[10]} />
          <path
            d="M16.3559 24.0296L11.6146 19.2882L10 20.8914L16.3559 27.2473L30 13.6032L28.3968 12L16.3559 24.0296Z"
            fill={theme.baseColors.info[20]}
          />
        </svg>
      </Box>
    )}

    <Box className="wrapper">
      <Typography className={`${variant === ToastVariant.neutral ? 'neutral-text' : ''} text`}>{text}</Typography>
      {description && (
        <Typography className={`${variant === ToastVariant.neutral ? 'neutral-description' : ''} description`}>
          {description}
        </Typography>
      )}
    </Box>
    <IconButton className="close-button" onClick={handleClose}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 5.61143L18.3886 4L12 10.3886L5.61143 4L4 5.61143L10.3886 12L4 18.3886L5.61143 20L12 13.6114L18.3886 20L20 18.3886L13.6114 12L20 5.61143Z"
          fill={theme.colors.textSecondaryLow}
        />
      </svg>
    </IconButton>
  </Wrapper>
)

export default MainLayoutToast
