import { ButtonGroup, Popper } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const StyledSplitButton = styled(ButtonGroup)<{ disabled: boolean }>`
  box-shadow: none;
  background: transparent;
  height: 40px;
  z-index: 1;
  box-sizing: border-box;

  ${({ disabled }) =>
    disabled &&
    `
      background: ${theme.baseColors.surface[50]};
      color: ${theme.baseColors.greyscale[50]};
      border: none;
      border-radius: ${theme.attributes.borderRadius.large};
  `};

  .main-button {
    height: 40px;
    gap: ${theme.spacing.xSmall}px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
    background: transparent;
    text-transform: none;
    color: ${theme.colors.textPrimary};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.thin};
    line-height: normal;
    border-radius: ${theme.attributes.borderRadius.large};
    border: 1px solid ${theme.colors.controlBorder} !important;
    z-index: 2;
    box-sizing: border-box;

    &:hover {
      border-color: ${theme.colors.controlBorder};
      background: ${theme.baseColors.secondary[30]};
      color: ${theme.baseColors.primary[70]};
    }

    &:active,
    &:focus {
      border: 1px solid ${theme.colors.controlBorder};
      background: ${theme.baseColors.primary[20]};
      color: ${theme.colors.white};
    }

    ${({ disabled }) =>
      disabled &&
      `
          background: ${theme.baseColors.surface[50]};
          color: ${theme.baseColors.greyscale[50]};
          border: none;
      `};
  }

  .dropdown-button {
    padding: ${theme.spacing.xSmall}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.attributes.borderRadius.large};
    color: ${theme.colors.textPrimary};
    background: transparent;
    border: 1px solid ${theme.colors.controlBorder} !important;
    border-left: none !important;

    &:hover {
      border-color: ${theme.colors.controlBorder};
      background: ${theme.baseColors.secondary[30]};
      color: ${theme.baseColors.primary[70]};
    }

    &:active,
    &:focus {
      border: 1px solid ${theme.colors.controlBorder};
      background: ${theme.baseColors.primary[20]};
      color: ${theme.colors.white};
    }

    &[aria-expanded='true'] {
      background: rgba(89, 0, 96, 1);

      &:hover {
        color: ${theme.colors.textPrimary};
      }
    }

    ${({ disabled }) =>
      disabled &&
      `
          background: ${theme.baseColors.surface[50]};
          color: ${theme.baseColors.greyscale[50]};
          border: none;
      `};
  }
`

export const StyledPopper = styled(Popper)`
  .MuiPaper-root {
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.borderLow};
    background: ${theme.colors.surfaceItemBackground};
    box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
    width: 250px;

    .MuiList-root {
      padding: ${theme.spacing.xSmall}px;

      .MuiMenuItem-root {
        height: 40px;
        border-radius: ${theme.attributes.borderRadius.small};

        &.Mui-selected {
          background: ${theme.colors.primaryLight};
        }

        &:hover {
          background: ${theme.colors.primaryLight};
        }
      }
    }
  }
`
