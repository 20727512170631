import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const IntegrationCardContainer = styled(Box)<{ expanded: string; hasFailed: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.small}px;
  width: 100%;
  position: relative;

  ${({ expanded }) =>
    expanded === 'true' &&
    `
      border: 2px solid rgba(255, 169, 252, 0.89);
      border-radius: 22px;
      margin-top: ${theme.spacing.small}px;
    `};

  .MuiAccordion-root {
    background: transparent;
    border-radius: ${theme.attributes.borderRadius.tertiary};
    width: 100%;

    .MuiAccordionSummary-root {
      background: ${theme.colors.backgroundMedium};
      border-radius: ${theme.attributes.borderRadius.tertiary};
      height: 80px;

      ${({ hasFailed }) =>
        hasFailed &&
        `
          height: 116px;
      `};

      .MuiAccordionSummary-content {
        margin: ${theme.spacing.md}px 0;
      }
    }

    .MuiCollapse-root {
      padding-top: ${theme.spacing.small}px;
      background: transparent;

      .MuiCollapse-wrapper {
        .MuiAccordionDetails-root {
          background: ${theme.colors.backgroundMedium};
          border-radius: ${theme.attributes.borderRadius.tertiary};

          &:not(:last-of-type) {
            margin-bottom: ${theme.spacing.small}px;
          }
        }
      }

      ${({ expanded }) =>
        expanded === 'false' &&
        `
          visibility: hidden;
          padding: 0;
      `};
    }
  }
`

export const IntegrationCardWrapper = styled(Box)<{ expanded: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;

  .description {
    .description-jira-wrapper {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
    }

    .header {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.xLarge};
      line-height: 24px;
      letter-spacing: 0.1px;
      color: ${theme.colors.neutral};
    }

    .subheader {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.normal};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.25px;
      color: ${theme.baseColors.greyscale[80]};
    }

    .subheader-empty {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.normal};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.25px;
      color: ${theme.baseColors.greyscale[80]};
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .instance-button {
      height: 40px;
      border: 1px solid ${theme.colors.controlBorder};
      border-radius: ${theme.attributes.borderRadius.large};
      padding: 0 14px 0 ${theme.spacing.medium}px;
      gap: ${theme.spacing.xSmall}px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: ${theme.colors.textPrimary};
      text-transform: none;

      &.Mui-disabled {
        color: ${theme.colors.neutral};
        opacity: 0.38;
      }

      svg {
        transition: transform 0.3s;

        ${({ expanded }) =>
          expanded === 'true' &&
          `
          transform: rotate(180deg);
      `};
      }
    }

    .icon-button {
      padding: 0;
      margin-left: ${theme.spacing.lg}px;
    }
  }
`

export const WarningMessageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;
  padding: ${theme.spacing.half}px ${theme.spacing.xSmall}px;
  width: fit-content;
  margin-top: ${theme.spacing.xSmall}px;

  border-radius: ${theme.attributes.borderRadius.mediumSmall};
  border: 1px solid ${theme.colors.textPrimary};
  background: rgba(255, 138, 128, 0.2);

  .warning-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const InstanceCardContainer = styled(Box)<{ isSelected: string; hasFailed: boolean }>`
  background: ${theme.colors.backgroundDark};
  border-radius: ${theme.attributes.borderRadius.tertiary};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.small}px;
  width: 100%;
  margin-top: ${theme.spacing.large}px;
  max-height: 80px;
  height: 80px;

  ${({ hasFailed }) =>
    hasFailed &&
    `
      height: 116px;
      max-height: unset;
  `};

  &:first-of-type {
    margin-top: 0;
  }

  ${({ isSelected }) =>
    isSelected === 'true' &&
    `
    border: 1px solid ${theme.colors.textPrimary};
  `};

  .instance-card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 80px;

    .description {
      .header {
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.bold};
        font-size: ${theme.typography.fontSize.xLarge};
        line-height: 24px;
        letter-spacing: 0.1px;
        color: ${theme.colors.neutral};
        text-transform: capitalize;
      }

      .subheader {
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.large};
        line-height: 20px;
        letter-spacing: 0.25px;
        color: ${theme.baseColors.greyscale[80]};
      }

      .subheader-empty {
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.large};
        line-height: 20px;
        letter-spacing: 0.25px;
        color: ${theme.baseColors.greyscale[80]};
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .instance-button {
        height: 40px;
        border: 1px solid ${theme.colors.controlBorder};
        border-radius: ${theme.attributes.borderRadius.large};
        padding: 0 14px 0 ${theme.spacing.medium}px;
        gap: ${theme.spacing.xSmall}px;
        font-family: Quicksand sans-serif;
        font-weight: ${theme.typography.fontWeight.bold};
        font-size: ${theme.typography.fontSize.large};
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: ${theme.colors.textPrimary};
        text-transform: none;

        svg {
          transition: transform 0.3s;
        }
      }

      .icon-button {
        padding: 0;
        margin-left: ${theme.spacing.lg}px;
      }
    }
  }
`

export const NewIntegrationMarkerContainer = styled(Box)<{ expanded: string }>`
  position: absolute;
  top: ${({ expanded }) => (expanded === 'true' ? `${theme.spacing.medium}px` : `${theme.spacing.xSmall}px`)};
  left: ${theme.spacing.half}px;
  z-index: 100;
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.mediumSmall};
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  padding: ${theme.spacing.xsm}px ${theme.spacing.small}px ${theme.spacing.xsm}px ${theme.spacing.xSmall}px;
  gap: ${theme.spacing.xSmall}px;
  width: 73px;
  height: 18px;
  background: ${theme.baseColors.info[10]};
  border-radius: ${theme.attributes.borderRadius.normal};
`
