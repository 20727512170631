import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  width: 100%;
  padding: ${theme.spacing.small}px 26px ${theme.spacing.medium}px 26px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  border: 1px solid ${theme.colors.controlBorder};
  background: ${theme.colors.backgroundDarker};
  box-shadow: ${theme.attributes.boxShadow.quaternary};

  .title {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.header};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    margin-bottom: ${theme.spacing.small}px;
  }
`

export const Content = styled(Box)`
  .title {
    color: ${theme.colors.white};
    text-align: center;
    text-shadow: ${theme.attributes.boxShadow.quaternary};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.header};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: normal;
    margin-bottom: ${theme.spacing.small}px;
  }

  .description {
    max-width: 532px;
    color: ${theme.colors.white};
    text-align: center;
    text-shadow: ${theme.attributes.boxShadow.quaternary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    margin: 0 auto;
  }

  .action-wrapper {
    margin-top: ${theme.spacing.medium}px;
    display: flex;
    justify-content: center;
  }
`
