import theme from 'theme'

const ManualSetupIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2662 14.0015L12.2912 10.0265H11.5487L9.64367 11.9315V12.674L13.6187 16.649C13.9112 16.9415 14.3837 16.9415 14.6762 16.649L16.2662 15.059C16.5587 14.774 16.5587 14.294 16.2662 14.0015ZM14.1437 15.0665L10.9637 11.8865L11.4962 11.354L14.6762 14.534L14.1437 15.0665Z"
      fill={theme.baseColors.primary[20]}
    />
    <path
      d="M13.0187 8.01652L14.0762 6.95902L15.6662 8.54902C16.5437 7.67152 16.5437 6.24652 15.6662 5.36902L13.0112 2.71402L11.9537 3.77152V1.65652L11.4287 1.12402L8.77367 3.77902L9.30617 4.31152H11.4287L10.3712 5.36902L11.1662 6.16402L8.99867 8.33152L5.90117 5.23402V4.16902L3.63617 1.90402L1.51367 4.02652L3.78617 6.29902H4.84367L7.94117 9.39652L7.30367 10.034H5.71367L1.73867 14.009C1.44617 14.3015 1.44617 14.774 1.73867 15.0665L3.32867 16.6565C3.62117 16.949 4.09367 16.949 4.38617 16.6565L8.36117 12.6815V11.0915L12.2237 7.22902L13.0187 8.01652ZM7.03367 11.879L3.85367 15.059L3.32117 14.5265L6.50117 11.3465L7.03367 11.879Z"
      fill={theme.baseColors.primary[20]}
    />
  </svg>
)

export default ManualSetupIcon
