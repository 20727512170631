import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  padding: ${theme.spacing.medium}px ${theme.spacing.large}px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.spacing.small}px;

  @media (min-width: 1440px) {
    gap: ${theme.spacing.small}px;
  }
`
