import { FC, MouseEvent, useState } from 'react'
import { FormGroup, FormControlLabel, Checkbox, SelectProps, IconButton, Box } from '@mui/material'
import ColumnIcon from '../../../components/svg/coverage-analyzer/ColumnIcon'
import { MenuWrapper, Wrapper } from './ColumnsSelectField.styles'
import { InstanceCheckedIcon, InstanceUncheckedIcon } from '../../../components/svg'
import { ThemeButton } from '../../../components/buttons'
import { TableHeaders } from '../../../../models'
import theme from 'theme'

interface Props extends SelectProps {
  handleChange: any
  columns: TableHeaders[]
  reset: () => void
}

const ColumnsSelectField: FC<Props> = ({ columns, handleChange, reset }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Wrapper className="select-columns-wrapper">
      <IconButton
        sx={{
          border: 0,
          background: open ? theme.baseColors.primary[20] : 'transparent',

          svg: {
            path: {
              fill: !open ? `${theme.colors.textPrimary} !important` : `${theme.colors.white} !important`
            }
          },

          '&:hover': {
            background: open ? theme.baseColors.primary[20] : theme.baseColors.primary[70],
            border: 0,

            svg: {
              path: {
                fill: open ? `${theme.colors.white} !important` : `${theme.colors.textPrimary} !important`
              }
            }
          }
        }}
        id="select-columns-button"
        aria-controls={open ? 'select-columns-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ColumnIcon />
      </IconButton>
      <MenuWrapper
        id="select-columns-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'select-columns-button' }}
      >
        <FormGroup>
          {columns.map((column, index) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    checked={column.show}
                    icon={<InstanceUncheckedIcon />}
                    checkedIcon={<InstanceCheckedIcon />}
                    onChange={(e) => handleChange(e, index)}
                  />
                }
                label={column.name}
              />
            )
          })}
        </FormGroup>

        <Box className="action">
          <ThemeButton themevariant="secondary" onClick={() => reset()}>
            Reset
          </ThemeButton>
        </Box>
      </MenuWrapper>
    </Wrapper>
  )
}

export default ColumnsSelectField
