import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useAuth } from '@frontegg/react'
import axios from '../../../lib/axios'

/* Utils */
import {
  Benchmark,
  CategoryActivityItem,
  DataStatus,
  FrameworkCategoryOption,
  IChartData,
  InsightSubType,
  IntegrationInstance,
  IntelInsight,
  PerformanceScoreData,
  PerformanceStatus
} from '../../../models'
import { openToast, ToastVariant } from '../../../store/toasts/toastSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { accountSettingsSelector } from '../../../store/user/userSlice'
import { defaultLibraryCategoriesNIST, defaultLibraryCategoriesOnyxia } from '../library/library.constants'
import { formatPerformanceData, sidePanelWidth } from './utils'
import { useFramework } from '../../../hooks/useFramework'
import { categoryToUrl } from '../library/utils'

/* Components */
import { DashboardContainer, DashboardWrapper, Main, MainScrollSection } from './dashboard.styles'
import DemoAccountPrompt from './components/DemoAccountPrompt'
import DashboardHeader from './components/DashboardHeader'
import HeroSection from './sections/hero/HeroSection'
import FeedSection from './sections/feed/FeedSection'
import DashboardLoading from './components/DashboardLoading/DashboardLoading'
import CategoryActivity from './sections/category-activity'
import Benchmarking from './components/Benchmarking'
import NoDataBanner from './components/NoDataBanner'
import { breadcrumbs, dateFormat } from '../../../lib/utils'
import ContentWrapper from '../../components/header/ContentWrapper'
import HeaderComponent from '../../components/header/HeaderComponent'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: function (number: number) {
      return `${number}s`
    },
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
})

const Dashboard: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { user } = useAuth()
  const accountSettings = useAppSelector(accountSettingsSelector)
  const secondBreakpoint = useMediaQuery('(min-width:1440px)')
  const [internalFeed, setInternalFeed] = useState<IntelInsight[]>([])
  const [bookmarkFeed, setBookmarkFeed] = useState<IntelInsight[]>([])
  const [intelFeedTabValue, setIntelFeedTabValue] = useState(0)
  const [performanceScore, setPerformanceScore] = useState(0)
  const [activeCpisNum, setActiveCpisNum] = useState(0)
  const [deleting, setDeleting] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [feedLoading, setFeedLoading] = useState(true)
  const [chartData, setChartData] = useState<IChartData[]>([])
  const [chartLoading, setChartLoading] = useState(true)
  const [benchmark, setBenchmark] = useState<Benchmark | null>(null)
  const [isUserDefault, setIsUserDefault] = useState(false)
  const [configuredIntegrations, setConfiguredIntegrations] = useState<IntegrationInstance[]>([])
  const [activeUsers, setActiveUsers] = useState(0)
  let storageFramework = ''
  if (typeof window !== 'undefined') {
    const framework = localStorage.getItem('framework')
    storageFramework = framework || ''
  }
  const [categoryOptions, setCategoryOptions] = useState<FrameworkCategoryOption[]>(
    storageFramework === 'nist' ? defaultLibraryCategoriesNIST : defaultLibraryCategoriesOnyxia
  )
  const [feedHeight, setFeedHeight] = useState(0)
  const [nextPage, setNextPage] = useState('')
  const [loading, setLoading] = useState(false)
  const [categoryActivityData, setCategoryActivityData] = useState<CategoryActivityItem[]>([])
  const [performanceScoreData, setPerformanceScoreData] = useState<PerformanceScoreData | null>(null)
  const [availableCpis, setAvailableCpis] = useState<string[]>([])
  const [lastUpdatePerformance, setLastUpdatePerformance] = useState('')
  const [unreadInsightsCount, setUnreadInsightsCount] = useState(0)
  const [insightsCountLoading, setInsightsCountLoading] = useState(true)
  const [hoveredCard, setHoveredCard] = useState('')
  const [insightsData, setInsightsData] = useState({
    performanceDegradation: 0,
    suggestedOptimization: 0,
    performanceImprovement: 0
  })
  const { framework, customFramework } = useFramework()

  const fetchConfiguredIntegrations = useCallback(async () => {
    const res = await axios.get('/api/v3/integrations/configured')

    if (res.status === 200) {
      setConfiguredIntegrations(res.data.instances)

      return res.data.instances
    }
  }, [])

  const fetchUsers = useCallback(async () => {
    const res = await axios.get('/api/v1/identity/users')

    if (res.status === 200) {
      const { users } = res.data
      const activeUsers = users.map((user: any) => user.verified)
      setActiveUsers(activeUsers.length)
    }
  }, [])

  const fetchBenchmarkData = useCallback(async () => {
    const res = await axios.get('/api/v3/benchmarks/cpi_industry_scores')

    if (res.status === 200) {
      const { benchmark }: { benchmark: Benchmark } = res.data
      setBenchmark(benchmark ?? null)
    }
  }, [])

  const fetchInsightsData = async () => {
    setInsightsCountLoading(true)

    try {
      const today = dayjs().format('YYYY-MM-DD')
      const past30 = dayjs(today).subtract(30, 'days')
      const thirtyDaysAgo = dayjs(past30).format('YYYY-MM-DD')

      const res = await axios.post('/api/v3/insights/search', {
        sort_by: 'insight_date',
        sort_direction: 'DESC',
        term: '',
        date_from: thirtyDaysAgo,
        date_to: today
      })

      if (res.status.toString().includes('20')) {
        const data = res.data.data

        if (data.length > 0) {
          const ids: string[] = []
          let performanceDegradation = 0
          let suggestedOptimization = 0
          let performanceImprovement = 0
          data.forEach((item: any) => {
            ids.push(item.id)

            if (item.sub_type === InsightSubType.CrossedIntoGreen || item.sub_type === InsightSubType.Improvement) {
              performanceImprovement += 1
            }
            if (item.sub_type === InsightSubType.Reduction || item.sub_type === InsightSubType.CrossedIntoRed) {
              performanceDegradation += 1
            }
            if (item.sub_type === InsightSubType.CpiSlaOptimization) {
              suggestedOptimization += 1
            }
          })

          setInsightsData({
            performanceDegradation,
            suggestedOptimization,
            performanceImprovement
          })

          const readStatusResp = await axios.post('/api/v3/insights/check-read-status', {
            ids
          })

          if (readStatusResp.status.toString().includes('20')) {
            const result = readStatusResp.data.data
            setUnreadInsightsCount(result.unreadIds.length)
          }
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setInsightsCountLoading(false)
    }
  }

  const fetchActiveCpis = async () => {
    try {
      const res = await axios.get('/api/v3/cpis/search?sortBy=desc&status=active')

      if (res.status.toString().includes('20')) {
        setActiveCpisNum(res.data.data.cpis.length)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchCpis = async () => {
    try {
      const allCpisRes = await axios.get('/api/v3/cpis')
      const allCpis: any[] = allCpisRes.data.data.cpis
      const cpisWithoutConfig = allCpis.filter((n: any) => !n.configuration)

      const supportedIntegrationsForCpisWithoutConfig: string[] = []
      cpisWithoutConfig.forEach((cpi) => {
        if (cpi.supported_integrations.length > 0) {
          cpi.supported_integrations.forEach((instance: { name: string }) => {
            if (!supportedIntegrationsForCpisWithoutConfig.includes(instance.name)) {
              supportedIntegrationsForCpisWithoutConfig.push(instance.name)
            }
          })
        }
      })

      const configuredIntegrationNames = configuredIntegrations.map((n) => n.integration_name)

      const availableCpis: string[] = []
      configuredIntegrationNames.forEach((integration) => {
        if (supportedIntegrationsForCpisWithoutConfig.includes(integration) && !availableCpis.includes(integration)) {
          availableCpis.push(integration)
        }
      })
      const listOfAvailableCpis: string[] = []
      cpisWithoutConfig.forEach((item) => {
        item.supported_integrations.forEach((item1: { name: string }) => {
          if (availableCpis.includes(item1.name)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!listOfAvailableCpis.includes(item.name)) {
              listOfAvailableCpis.push(item.name)
            }
          }
        })
      })

      /* Are CPIs that we can activate because they have active data sources but for the moment these CPIs are inactive */
      setAvailableCpis(listOfAvailableCpis)
    } catch (err) {
      console.error(err)
    }
  }

  const fetchPerformanceData = async () => {
    try {
      const res = await axios.get('/api/v3/cpis/performance_score')
      if (res.status === 200) {
        const {
          data: { data }
        } = res
        const d: PerformanceScoreData = data
        setLastUpdatePerformance(dayjs(d.date).format(dateFormat.lastUpdate))
        const formattedData = formatPerformanceData(d.categories)
        setChartData(formattedData)
        setPerformanceScoreData(data)
        setPerformanceScore(d.score)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setChartLoading(false)
    }
  }

  const fetchCategories = useCallback(async () => {
    try {
      const res = await axios.get('/api/v1/frameworks/categories')

      if (res.status === 200) {
        const { categories } = res.data.data
        setCategoryOptions(categories)
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  const fetchInternalFeed = useCallback(async () => {
    const res = await axios.get('/api/v1/insights/all')

    if (res.status === 200) {
      setNextPage(res.data.data.paging?.cursor || '')
      setInternalFeed(res.data.data.list)
    }
  }, [])

  const fetchBookmarkFeed = useCallback(async () => {
    const res = await axios.get('/api/v1/bookmarks')

    if (res.status === 200) {
      setBookmarkFeed(res.data.data.list)
    }
  }, [])

  const fetchFeed = useCallback(async () => {
    setFeedLoading(true)
    try {
      await Promise.all([fetchInternalFeed(), fetchBookmarkFeed()])
    } catch (e) {
      console.error(e)
    } finally {
      setFeedLoading(false)
    }
  }, [fetchInternalFeed, fetchBookmarkFeed])

  const fetchCategoryActivity = useCallback(async () => {
    try {
      const res = await axios.get<{ data: CategoryActivityItem[] }>('/api/v3/cpis/category_activity')

      if (res.status === 200) {
        const {
          data: { data }
        } = res
        setCategoryActivityData(data)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    fetchFeed().catch((e) => e)
    fetchCategoryActivity().catch((e) => e)
    fetchInsightsData().catch((e) => e)
  }, [])

  const fetchAllData = async () => {
    try {
      try {
        await fetchUsers()
      } catch (err) {
        console.error(err)
      }

      const res = await Promise.all([
        fetchConfiguredIntegrations(),
        fetchBenchmarkData(),
        fetchCategories(),
        fetchActiveCpis(),
        fetchCpis()
      ])

      const configuredIntegrations = res[0]
      if (configuredIntegrations && configuredIntegrations.length > 0) {
        await fetchPerformanceData()
      }
    } catch (err) {
      console.error(err)
    } finally {
      setTimeout(() => {
        convertHeight().catch((e) => e)
      }, 200)
      setInitialLoad(true)
    }
  }

  useEffect(() => {
    if (user) {
      const roles = user.roles
      const demo = roles.find((role) => role.key === 'default')
      setIsUserDefault(!!demo)

      if (!demo) {
        fetchAllData().catch((e) => e)
      }
    }
  }, [user])

  useEffect(() => {
    let interval: number

    if (performanceScoreData) {
      const categories = performanceScoreData.categories

      if (categories.length > 0) {
        let notReadyStatus = false

        categories.forEach((category) => {
          if (category.status !== PerformanceStatus.active) return
          const cpiIsNotReady = category.details.some((n) =>
            [DataStatus.Initializing, DataStatus.Recalculating].includes(n.status)
          )
          if (cpiIsNotReady) {
            notReadyStatus = true
          }
        })

        if (notReadyStatus) {
          interval = 1000 * 10 // 10 seconds
        } else {
          interval = 1000 * 60 * 5 // 5 minutes
        }

        const refetchData = setInterval(() => {
          fetchPerformanceData().catch((e) => e)
        }, interval)

        return () => {
          clearInterval(refetchData)
        }
      }
    }
  }, [performanceScoreData])

  useEffect(() => {
    if (internalFeed.length === 16) {
      setTimeout(() => {
        convertHeight().catch((e) => e)
      }, 300)
    }
  }, [internalFeed])

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setIntelFeedTabValue(newValue)
    if (newValue === 0) fetchFeed().catch((e) => e)
  }

  const intelFeedPaging = async () => {
    if (!nextPage) return

    try {
      setLoading(true)
      const res = await axios.get(`/api/v1/insights/all?next=${nextPage}`)
      if (res.status === 200) {
        setNextPage(res.data.data.paging?.cursor || '')
        const fullList = [...internalFeed, ...res.data.data.list]
        setInternalFeed(fullList)
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const convertHeight = async () => {
    let height = 747
    let offset = 140 + 367 + 32
    if (!secondBreakpoint) {
      height -= 367 + 32
    }
    if (secondBreakpoint) {
      offset = 140
      height = 747
    }

    const mainSectionScroll = document.getElementById('main-scroll-section')
    if (mainSectionScroll) {
      height = mainSectionScroll.clientHeight - offset
    }

    setFeedHeight(height)
    if (height > 1500) await intelFeedPaging()
  }

  const scrollHandler = async (e: any) => {
    if (loading) return

    const offset = 1500
    const bottom = e.target.scrollWidth - e.target.scrollLeft - offset < e.target.clientWidth
    if (bottom) await intelFeedPaging()
  }

  const handleCategoryClick = (category: string) => {
    navigate('/library', { state: { navigatedCategory: category } })
  }

  const handleCpiRedirect = (category: CategoryActivityItem) => {
    const fw = framework === 'onyxia' ? 'onyxia' : framework === 'nist' ? 'nist' : 'custom'

    if (fw === 'custom' && customFramework) {
      const url = categoryToUrl(category.name)
      navigate('/library', { state: { navigatedCategory: url } })
    } else {
      navigate('/library', { state: { navigatedCategory: category.id } })
    }
  }

  const requestQuote = () => {
    dispatch(
      openToast({
        variant: ToastVariant.success,
        props: {
          text: 'Quote Request Sent',
          description: 'The Onyxia team will contact you shortly, thank you for your interest!'
        }
      })
    )
  }

  if (!initialLoad) {
    return <DashboardLoading feedHeight={feedHeight} />
  }
  return (
    <>
      <DashboardContainer sx={{ height: initialLoad ? '100vh' : '' }}>
        {initialLoad && activeCpisNum > 0 ? (
          <DashboardHeader accountSettings={accountSettings} />
        ) : (
          <HeaderComponent breadcrumbs={breadcrumbs.dashboard} type="dashboard">
            <NoDataBanner />
          </HeaderComponent>
        )}

        <ContentWrapper line={1} paddingBottom={1} normalHeight="true">
          <Main>
            <DashboardWrapper id="main-section">
              <HeroSection
                cpisActivated={activeCpisNum}
                chartLoading={chartLoading}
                initialLoad={initialLoad}
                chartData={chartData}
                performanceScore={performanceScore}
                noCategories={!performanceScoreData?.categories.length}
                configuredIntegrations={configuredIntegrations}
                activeUsers={activeUsers}
                availableCpis={availableCpis}
                handleCategoryClick={handleCategoryClick}
                lastUpdatePerformance={lastUpdatePerformance}
                unreadInsightsCount={unreadInsightsCount}
                insightsCountLoading={insightsCountLoading}
                insightsData={insightsData}
                setHoveredCard={setHoveredCard}
                customFramework={customFramework}
              />

              <Box className="main-content-container">
                <Box className="main-scroll-wrapper">
                  <MainScrollSection id="main-scroll-section" className="weekly-category-score-trend-wrapper">
                    {isUserDefault && !chartLoading && !chartData.length && !performanceScoreData?.categories.length ? (
                      <DemoAccountPrompt requestQuote={requestQuote} />
                    ) : (
                      <Box sx={{ height: '100%' }}>
                        <CategoryActivity
                          initialLoad={initialLoad}
                          categories={categoryActivityData}
                          handleCpiRedirect={handleCpiRedirect}
                          categoryOptions={categoryOptions.map((n) => n.title)}
                          hoveredCard={hoveredCard}
                        />
                      </Box>
                    )}
                  </MainScrollSection>
                </Box>

                <Benchmarking
                  framework={framework}
                  customFramework={customFramework}
                  width={sidePanelWidth}
                  benchmark={benchmark}
                  cpiTitles={
                    performanceScoreData &&
                    performanceScoreData.categories.length > 0 &&
                    performanceScoreData.categories[0].details &&
                    performanceScoreData.categories[0].details.length > 0
                      ? performanceScoreData?.categories[0].details.map((cpi) => ({
                          displayName: cpi.cpiDisplayName,
                          name: cpi.cpiName,
                          title: cpi.cpiTitle,
                          category: performanceScoreData?.categories[0].name
                        }))
                      : []
                  }
                  isEmpty={false}
                />
              </Box>

              <FeedSection
                feedHeight={feedHeight}
                intelFeedTabValue={intelFeedTabValue}
                handleTabChange={handleTabChange}
                scrollHandler={scrollHandler}
                feedLoading={feedLoading}
                internalFeed={internalFeed}
                fetchBookmarkFeed={fetchBookmarkFeed}
                setDeleting={setDeleting}
                bookmarkFeed={bookmarkFeed}
                deleting={deleting}
                loading={loading}
              />
            </DashboardWrapper>
          </Main>
        </ContentWrapper>
      </DashboardContainer>
    </>
  )
}

export default Dashboard
