import { Dispatch, FC, SetStateAction } from 'react'
import { Box, SelectChangeEvent, Typography, useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'
import theme from 'theme'

/* Utils */
import {
  ChartData,
  ConfiguredCPI,
  CPIManifest,
  CPISeverity,
  DataStatus,
  IntegrationInstance,
  PredictionChartData,
  PredictionDataExtended
} from '../../../../../../../models'
import { formatPerformanceUnit, getUtcDateAndTime } from '../../../../utils'
import { dateFormat } from '../../../../../../../lib/utils'
import { getDataSourceAllOption } from '../../../../library.constants'

/* Components */
import { FindingsIcon, LegendPredictedPerformanceIcon } from '../../../../../../components/svg'
import { ChipsContainer } from '../../../../components/library-components.styles'
import LibraryDataSourceSelect from '../../../../components/LibraryDataSourceSelect'
import { PredictionDescriptionWrapper } from '../trendsTab.styles'
import LegendPredictedFindingIcon from '../../../../../../components/svg/library/LegendPredictedFindingIcon'

interface Props {
  manifestData: CPIManifest
  chartData: PredictionChartData | null
  setSelectedDataSourceIds: Dispatch<SetStateAction<string[]>>
  setDataSourceValue: Dispatch<SetStateAction<string>>
  selectedSeverity: CPISeverity
  userConfiguredIntegrations: IntegrationInstance[]
  cpiConfiguration: ConfiguredCPI | null
  cpiSupportedAndUserConfiguredList: IntegrationInstance[]
  dataSourceOptions: IntegrationInstance[]
  dataSourceValue: string
  zone: string
  renderSeverities: () => JSX.Element | null
  refetchPredictionData: ({
    thisCpiName,
    severity,
    selectedSources
  }: {
    thisCpiName: string
    severity?: CPISeverity
    selectedSources?: string[]
  }) => Promise<ChartData | null>
  lastValuePrediction: PredictionDataExtended | null
  activeSources: IntegrationInstance[]
}

const PredictionChartDescription: FC<Props> = ({
  manifestData,
  chartData,
  selectedSeverity,
  setSelectedDataSourceIds,
  setDataSourceValue,
  dataSourceOptions,
  dataSourceValue,
  zone,
  renderSeverities,
  refetchPredictionData,
  lastValuePrediction,
  activeSources
}) => {
  const thirdBreakpoint = useMediaQuery('(min-width:1920px)')

  const handleChangeDataSource = async (event: SelectChangeEvent<string>) => {
    const {
      target: { value }
    } = event

    // eslint-disable-next-line array-callback-return
    const findItemsIds = dataSourceOptions.map((item) => {
      if (item.name === value) return item.id
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const filtersArr: string[] = findItemsIds.filter(Boolean)
    const selectedSources = value.includes('All') ? [] : filtersArr
    setSelectedDataSourceIds(selectedSources)

    const findName = activeSources.find((n) => n.id === selectedSources[0])
    setDataSourceValue(
      selectedSources.length === 1 && findName ? findName.integration_name : `All (${dataSourceOptions.length})`
    )

    await refetchPredictionData({ thisCpiName: manifestData.name || '', selectedSources })
  }

  const getRecentValueDate = () => {
    if (!chartData) return dayjs().format(dateFormat.auditLogDefault)
    const date = getUtcDateAndTime(chartData.recentDate).labelFormatDate

    return dayjs(date).format('MMM DD YYYY')
  }

  const allOption = getDataSourceAllOption(dataSourceOptions.length)
  const allOptionText = `All (${dataSourceOptions.length})`

  return (
    <PredictionDescriptionWrapper status={chartData?.status} zone={zone}>
      <Box className="section">
        <Typography className="recent-performance-value">
          {chartData?.recentValue || 0}
          <span>{formatPerformanceUnit(manifestData?.ui.trend_chart.user_input.visualization_param.unit)}</span>
        </Typography>
        <Typography className="recent-performance-text">Most Recent Performance</Typography>
        <Typography className="updated-at">
          {selectedSeverity} Severities, {getRecentValueDate()}
        </Typography>
      </Box>

      <Box className="section section-prediction">
        <Typography className="predicted-performance-value">
          {Math.round(lastValuePrediction?.yValue || 0)}
          <span>%</span>
        </Typography>
        <Typography className="recent-performance-text">Predicted Performance</Typography>
        <Typography className="updated-at">
          {selectedSeverity} Severities, {dayjs(lastValuePrediction?.xValue || '').format('MMM DD YYYY')}
        </Typography>
      </Box>

      <Box className="section section-legend">
        {thirdBreakpoint && <Typography className="legend-title">Key:</Typography>}
        <Box className="legend-wrapper">
          <Box className="legend-item">
            {chartData?.zoneColor === 'green' ? (
              <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1_4256" fill="white">
                  <path d="M0 0.298828H16V3.29883H0V0.298828Z" />
                </mask>
                <path
                  d="M0 3.29883H16V-2.70117H0V3.29883Z"
                  fill={theme.baseColors.info[20]}
                  mask="url(#path-1-inside-1_1_4256)"
                />
              </svg>
            ) : (
              <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1_4256" fill="white">
                  <path d="M0 0.298828H16V3.29883H0V0.298828Z" />
                </mask>
                <path
                  d="M0 3.29883H16V-2.70117H0V3.29883Z"
                  fill={theme.baseColors.info[50]}
                  mask="url(#path-1-inside-1_1_4256)"
                />
              </svg>
            )}
            <Typography>Performance</Typography>
          </Box>
          <Box className="legend-item">
            <LegendPredictedPerformanceIcon />
            <Typography>Predicted Performance</Typography>
          </Box>
          <Box className="legend-item">
            <FindingsIcon />
            <Typography>Current Finding</Typography>
          </Box>
          <Box className="legend-item">
            <LegendPredictedFindingIcon />
            <Typography>Predicted Finding</Typography>
          </Box>
        </Box>
      </Box>

      <Box className="section section-controls">
        {renderSeverities() !== null && (
          <ChipsContainer
            className="chips-container severity"
            sx={{
              gap: `${theme.spacing.xSmall}px`,

              '.chips-title': {
                color: `${theme.colors.white} !important`,
                fontFamily: `${theme.typography.fontFamily.primary} !important`,
                fontSize: `${theme.typography.fontSize.large} !important`,
                fontStyle: 'normal',
                fontWeight: `${theme.typography.fontWeight.bold} !important`,
                lineHeight: '20px !important',
                letterSpacing: '0.1px !important'
              }
            }}
          >
            {renderSeverities()}
          </ChipsContainer>
        )}

        <Box className="data-source-wrapper">
          <Typography className="title">Data Source(s):</Typography>
          <Box className="data-source-select-wrapper">
            <LibraryDataSourceSelect
              customClass="trends-source"
              label=""
              dataSourceOptions={[allOption, ...dataSourceOptions]}
              placeholder="Data Source"
              value={dataSourceValue}
              manifestData={manifestData}
              handleChange={handleChangeDataSource}
              disabled={chartData?.status !== DataStatus.Ready}
              allOptionText={allOptionText}
              raise
            />
          </Box>
        </Box>
      </Box>
    </PredictionDescriptionWrapper>
  )
}

export default PredictionChartDescription
