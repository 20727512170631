import { Box, DialogActions, DialogContent } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)``

export const Header = styled(Box)`
  padding: 8px 16px 16px;
  border-bottom: 1px solid #342f32;

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: 36px;
    line-height: 44px;
    color: ${theme.colors.neutral};
  }
`

export const Content = styled(DialogContent)`
  box-shadow: ${theme.attributes.boxShadow.chipCircle};
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.backgroundMedium};
  padding: ${theme.spacing.medium}px 24px 16px 24px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 32px;
    color: ${theme.colors.neutral};
    margin-bottom: ${theme.spacing.md}px;
  }

  .subtitle {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: ${theme.spacing.medium}px;
  }

  .framework {
    border-radius: ${theme.attributes.borderRadius.quaternary};
    background: ${theme.colors.backgroundMedium};
    padding-left: ${theme.spacing.md}px;
    padding-right: ${theme.spacing.small}px;
    padding-top: ${theme.spacing.md}px;
    padding-bottom: ${theme.spacing.md}px;
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
    margin-bottom: 38px;

    .text {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: ${theme.spacing.small}px;
    padding-left: ${theme.spacing.small}px;
    margin-bottom: ${theme.spacing.xSmall}px;

    .name {
      color: ${theme.baseColors.greyscale[80]};
      font-family: ${theme.typography.fontFamily.primary};
      ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .value {
      width: 175px;
      color: ${theme.baseColors.greyscale[80]};
      font-family: ${theme.typography.fontFamily.primary};
      ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .content-category {
    margin-bottom: 38px;

    .content-item {
      border-radius: ${theme.attributes.borderRadius.quaternary};
      background: ${theme.colors.backgroundMedium};
      padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${theme.spacing.half}px;

      .name {
        color: ${theme.colors.neutral};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      .value {
        width: 175px;
        color: ${theme.colors.neutral};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
  }

  .content-cpi {
    .content-item {
      border-radius: ${theme.attributes.borderRadius.quaternary};
      background: ${theme.colors.backgroundMedium};
      padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${theme.spacing.half}px;

      .cpi-block {
        display: flex;
        align-items: baseline;
        gap: ${theme.spacing.small}px;

        .cpi {
          border-radius: 18px;
          background: #debdd9;
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.medium};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #3f293e;
          padding: 2px ${theme.spacing.xSmall}px;
        }

        .name {
          color: ${theme.colors.neutral};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 24px;
          letter-spacing: 0.1px;
          max-width: 310px;
        }
      }

      .value {
        width: 175px;
        color: ${theme.colors.neutral};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
  }
`

export const Actions = styled(DialogActions)`
  margin-top: ${theme.spacing.medium}px;
`
