import { ReactNode, SyntheticEvent } from 'react'
import { Tabs as MuiTabs, Tab, Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const Tabs = styled(MuiTabs)`
  border-bottom: 0;
  height: 40px !important;
  min-height: 40px !important;

  .MuiTabs-scroller {
    border-bottom: 0;
    display: flex;
    align-items: center;
    height: 40px;

    .MuiTabs-indicator {
      display: none;
    }
  }

  .MuiTabs-flexContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0;

    .MuiButtonBase-root {
      border-radius: ${theme.attributes.borderRadius.large} 0 0 ${theme.attributes.borderRadius.large};
      border: 1px solid ${theme.colors.controlBorder};
      text-align: center;
      font-size: ${theme.typography.fontSize.xLarge};
      color: ${theme.colors.textPrimary};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;
      text-transform: none;
      width: 142.5px;
      height: 40px;
      min-height: 40px;
      padding: 0;

      &:nth-of-type(2) {
        border-radius: 0 ${theme.attributes.borderRadius.large} ${theme.attributes.borderRadius.large} 0;
      }

      &.Mui-selected {
        background: ${theme.baseColors.primary[20]};
        color: ${theme.colors.white};
      }
    }
  }
`

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

export function JiraDropdownTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`jira-dropdown-tabpanel-${index}`}
      aria-labelledby={`jira-dropdown-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-panel-list-wrapper" sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

interface TabsProps {
  value: number
  handleChange: (event: SyntheticEvent, newValue: number) => void
}

export default function JiraDropdownTabs({ value, handleChange }: TabsProps) {
  return (
    <Tabs value={value} onChange={handleChange} aria-label="jira dropdown tabs">
      <Tab label="Default" {...a11yProps(0)} />
      <Tab label="Custom" {...a11yProps(1)} />
    </Tabs>
  )
}
