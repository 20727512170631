import { FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.backgroundDark,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.small,
      minWidth: '130px',

      '.MuiMenuItem-root': {
        height: '32px',
        paddingLeft: `${theme.spacing.small}px`,
        paddingRight: `${theme.spacing.small}px`,
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          paddingRight: '19px',
          paddingLeft: 0,

          svg: {
            path: {
              fill: theme.baseColors.greyscale[80]
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontSize: theme.typography.fontSize.large,
          lineHeight: '20px',
          letterSpacing: '0.25px',
          color: theme.baseColors.greyscale[80]
        },

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: theme.attributes.boxShadow.chipCircle,
            margin: '-4.5px',
            borderRadius: theme.attributes.borderRadius.circle,

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const,
  getContentAnchorEl: null
}

export const BenchmarkingSelect = styled(Select)<{ isselected?: string; noborder?: string }>`
  min-width: 113px;
  height: 32px;
  gap: ${theme.spacing.xSmall}px;
  color: ${theme.colors.neutral};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.mediumSmall};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 16px;
  letter-spacing: 0.5px;
  border-radius: ${theme.attributes.borderRadius.mediumSmall};
  border: 1px solid ${theme.colors.textPrimary};
  background: ${theme.colors.primaryDarker};

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: rgba(208, 188, 255, 0.16);
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
    background: ${theme.colors.primaryDarker};
  }

  &.Mui-disabled {
    .MuiSelect-outlined {
      -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    }
  }

  svg {
    path {
      fill: ${theme.baseColors.greyscale[80]};
    }
  }
`

export const BenchmarkingFormControl = styled(FormControl)`
  border-radius: ${theme.attributes.borderRadius.mediumSmall};
  border: none;
  outline: none;
  min-width: 113px;
  height: 32px;
  margin-bottom: ${theme.spacing.medium}px;

  .MuiSelect-icon {
    top: calc(50% - 0.7em);
  }
`
