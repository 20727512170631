import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: ${theme.colors.primaryDarker};
`

export const Breadcrumbs = styled(MuiBreadcrumbs)`
  .MuiBreadcrumbs-li {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 36px;
    text-transform: capitalize;
  }

  .MuiBreadcrumbs-separator {
    color: ${theme.colors.textPrimary};
    margin-left: ${theme.spacing.sm}px;
    margin-right: ${theme.spacing.sm}px;
  }
`

export const Content = styled(Box)`
  display: flex;
  padding: ${theme.spacing.medium}px;
  width: 100%;
  flex-basis: 100%;
  gap: ${theme.spacing.medium}px;
`

export const SectionDivider = styled(Box)<{ height: string }>`
  background: ${theme.colors.borderLow};
  width: 1px;
  height: ${({ height }) => height};
  align-self: flex-end;
  margin-left: ${theme.spacing.sm}px;
  margin-top: 50px;
`

export const paperPropsSx = {
  sx: {
    background: theme.colors.surfaceItemBackground,
    border: `1px solid ${theme.colors.borderLow}`,
    borderRadius: theme.attributes.borderRadius.small,
    padding: `${theme.spacing.xSmall}px`,
    maxHeight: 40 * 4.5,
    marginTop: `${theme.spacing.half}px`,
    width: '225px',
    boxSizing: 'border-box',
    height: '56px',
    overflowY: 'hidden',

    '.MuiList-root': {
      padding: 0,

      '.MuiMenuItem-root': {
        height: '40px',
        background: 'transparent',

        '&:hover': {
          background: theme.colors.primaryLight
        },

        '&.disabled-menu': {
          color: theme.colors.textSecondaryLow,

          '&:hover': {
            cursor: 'default',
            background: 'transparent'
          }
        },

        borderRadius: theme.attributes.borderRadius.small,
        overflow: 'hidden',
        color: theme.colors.white,
        textOverflow: 'ellipsis',
        fontFamily: theme.typography.fontFamily.primary,
        fontSize: theme.typography.fontSize.xLarge,
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeight.normal,
        lineHeight: 'normal'
      }
    }
  }
}
