import theme from '../../../../theme'

const AuditAccessPermissionChangeIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 2C4.86 2 1.5 5.36 1.5 9.5C1.5 13.64 4.86 17 9 17C13.14 17 16.5 13.64 16.5 9.5C16.5 5.36 13.14 2 9 2ZM9 15.5C5.6925 15.5 3 12.8075 3 9.5C3 6.1925 5.6925 3.5 9 3.5C12.3075 3.5 15 6.1925 15 9.5C15 12.8075 12.3075 15.5 9 15.5ZM12.1275 11.57L11.3025 10.745C11.835 9.7475 11.7 8.4875 10.86 7.6475C10.3425 7.13 9.675 6.875 9 6.875C8.9775 6.875 8.955 6.8825 8.9325 6.8825L9.75 7.7L8.955 8.495L6.8325 6.3725L8.955 4.25L9.75 5.045L9.03 5.765C9.9825 5.7725 10.9275 6.125 11.655 6.845C12.93 8.1275 13.0875 10.115 12.1275 11.57ZM11.1675 12.6275L9.045 14.75L8.25 13.955L8.9625 13.2425C8.0175 13.235 7.0725 12.8675 6.3525 12.1475C5.07 10.865 4.9125 8.885 5.8725 7.43L6.6975 8.255C6.165 9.2525 6.3 10.5125 7.14 11.3525C7.665 11.8775 8.3625 12.1325 9.06 12.11L8.25 11.3L9.045 10.505L11.1675 12.6275Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default AuditAccessPermissionChangeIcon
