import { FC } from 'react'
import { Box, Fade, IconButton, Snackbar, Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import styled from '@emotion/styled/macro'
import { useAppDispatch } from '../../../store/hooks'
import { closeNotification } from '../../../store/notifications/notificationSlice'
import { ToastVariant } from '../../../store/toasts/toastSlice'
import theme from 'theme'

const Wrapper = styled(Box)<{ variant: 'success' | 'error' | 'warning' | 'neutral' }>`
  width: 645px;
  min-height: 72px;
  position: fixed;
  right: ${theme.spacing.xLarge}px;
  bottom: -30px;
  background: #6dd58c;
  box-shadow: ${theme.attributes.boxShadow.toolbar};
  background: ${({ variant }) =>
    variant === ToastVariant.success
      ? theme.baseColors.info[20]
      : variant === ToastVariant.warning
      ? '#7FCFFF'
      : variant === ToastVariant.neutral
      ? theme.colors.borderLow
      : '#FDAEA3'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.small}px;
  padding: 13px ${theme.spacing.small}px;
  z-index: 10001;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  animation: slidein 0.5s forwards;

  @keyframes slidein {
    from {
      bottom: -30px;
      opacity: 0;
    }
    to {
      bottom: ${theme.spacing.xLarge}px;
      opacity: 1;
    }
  }

  .message {
    color: ${theme.baseColors.primary[10]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;

    .left-side-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: ${theme.spacing.half}px;

      .title {
        color: ${theme.baseColors.primary[10]};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      .message {
        color: ${theme.baseColors.primary[10]};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
`

const vertical = 'top'
const horizontal = 'center'

interface Props {
  variant: 'success' | 'error' | 'warning'
  message: string
  open: boolean
  autoHideDelay?: number
  title?: string
  sx?: Record<string, unknown>
}

const Notification: FC<Props> = ({ variant, message, title, sx, open, autoHideDelay }) => {
  const dispatch = useAppDispatch()
  const handleClose = () => dispatch(closeNotification())

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      message={message}
      autoHideDuration={autoHideDelay}
      TransitionComponent={Fade}
      key={vertical + horizontal}
      sx={{
        bottom: '0 !important',
        width: '100%',
        zIndex: 100000
      }}
    >
      <Wrapper variant={variant} sx={sx}>
        <Box className="left-side">
          {variant === 'success' && (
            <>
              <Box sx={{ width: '24px !important', height: '24px !important' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
                    fill={theme.baseColors.primary[10]}
                  />
                </svg>
              </Box>

              <Box className="left-side-content">
                {title && (
                  <Typography variant="body1" className="title">
                    {title ?? 'Success: '}
                  </Typography>
                )}
                <Typography variant="body1" className="message">
                  {message ?? 'The test worked as expected.'}
                </Typography>
              </Box>
            </>
          )}

          {variant === 'error' && (
            <>
              <Box sx={{ width: '24px !important', height: '24px !important' }}>
                <Warning
                  sx={{ color: '#E85541', fontSize: theme.typography.fontSize.xLarge, mx: `${theme.spacing.half}px` }}
                />
              </Box>

              <Box className="left-side-content">
                {title && (
                  <Typography variant="body1" className="title">
                    {title ?? 'Test Failed: '}
                  </Typography>
                )}
                <Typography variant="body1" className="message">
                  {message ?? 'Something went wrong, please make sure inputs are accurate.'}
                </Typography>
              </Box>
            </>
          )}

          {variant === 'warning' && (
            <>
              <Box sx={{ width: '24px !important', height: '24px !important' }}>
                <InfoIcon
                  sx={{
                    color: theme.colors.purpleDark,
                    fontSize: theme.typography.fontSize.xLarge,
                    mx: `${theme.spacing.half}px`,
                    width: '24px !important',
                    height: '24px !important'
                  }}
                />
              </Box>

              <Box className="left-side-content">
                {title && (
                  <Typography variant="body1" className="title">
                    {title ?? 'Update Discarded:'}
                  </Typography>
                )}
                <Typography variant="body1" className="message">
                  {message ?? 'Changes were not saved.'}
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <IconButton className="close-button" onClick={handleClose}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill={theme.baseColors.primary[10]}
            />
          </svg>
        </IconButton>
      </Wrapper>
    </Snackbar>
  )
}

export default Notification
