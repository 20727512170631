import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { hasPermission, Permission } from '../lib/utils'
import { User } from '@frontegg/redux-store'

interface Props {
  user: User | null | undefined
  children: ReactElement
  permission: Permission | 'all'
  deniedRoles?: string[]
  redirectTo?: string
}

const ProtectedRoute = ({ user, children, permission, deniedRoles, redirectTo }: Props): ReactElement => {
  if (permission === 'all') return children
  if (!hasPermission(permission, user) || (user && deniedRoles && deniedRoles.includes(user?.roles[0].key))) {
    return <Navigate to={redirectTo ?? '/'} replace />
  }
  return children
}

export default ProtectedRoute
