import theme from 'theme'

export const dataSourceErrorBackground = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className="error-bg">
    <circle cx="7" cy="7" r="7" fill={theme.baseColors.info[50]} />
  </svg>
)

export const dataSourceErrorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    className="error-content"
  >
    <g clipPath="url(#clip0_62_461)">
      <path
        d="M5 0.833313C2.7 0.833313 0.833328 2.69998 0.833328 4.99998C0.833328 7.29998 2.7 9.16665 5 9.16665C7.3 9.16665 9.16666 7.29998 9.16666 4.99998C9.16666 2.69998 7.3 0.833313 5 0.833313ZM5.41666 7.08331H4.58333V6.24998H5.41666V7.08331ZM5.41666 5.41665H4.58333V2.91665H5.41666V5.41665Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_62_461">
        <rect width="10" height="10" fill={theme.colors.white} />
      </clipPath>
    </defs>
  </svg>
)
