import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  position: relative;

  @media (max-width: 1440px) {
    margin-top: ${theme.spacing.small}px;
  }

  .chips-title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing.xSmall}px;
    white-space: nowrap;
  }

  .MuiChip-root {
    background: transparent;
    border-radius: ${theme.attributes.borderRadius.secondary};
    border: 1px solid ${theme.colors.controlBorder};
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${theme.colors.white};
    text-transform: capitalize;

    &.selected {
      color: ${theme.baseColors.primary[20]};
      border: none;
      background: ${theme.colors.textPrimary};
    }

    &:hover {
      background: ${theme.colors.textPrimary} !important;
      color: ${theme.baseColors.primary[20]} !important;
      opacity: 0.9 !important;
    }
  }

  .month-picker-container {
    position: absolute;
    top: 225px;
    left: 457px;
    z-index: 10000;
    width: 428px;
    height: 209px;
    display: none;
    border-radius: ${theme.attributes.borderRadius.secondary};
    background: ${theme.colors.primaryLight};
    transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    @media (max-width: 1279px) {
      top: 267px;
      left: 37px;
    }

    &.isopen {
      padding: 0;

      > div:first-of-type {
        padding: ${theme.spacing.small}px ${theme.spacing.md}px ${theme.spacing.half}px;
        justify-content: center;
        gap: 170px;

        & [class*='mx-4'] {
          &:before {
            width: 1px;
            height: 44px;
            background-color: #614964;
            position: absolute;
            left: 211.5px;
            top: 0;
            content: '';
            display: block;
          }
        }

        > div:first-of-type,
        > div:last-of-type {
          display: none;
        }
      }

      .months-wrapper {
        &:before {
          width: 1px;
          height: 44px;
          background-color: #614964;
          position: absolute;
          left: 211.5px;
          top: 165px;
          content: '';
          display: block;
        }

        > div:first-of-type {
          gap: ${theme.spacing.xSmall}px 0;
          padding: ${theme.spacing.half}px ${theme.spacing.medium}px 0 0;

          > div {
            width: 40px;
            height: 40px;
          }
        }

        > div:nth-of-type(2) {
          gap: ${theme.spacing.xSmall}px 0;
          padding: ${theme.spacing.half}px ${theme.spacing.xSmall}px 0 ${theme.spacing.lg}px;
        }
      }
    }

    p {
      color: ${theme.baseColors.greyscale[80]};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    & [class*='gap-4'] {
      > div:first-of-type {
        &:before {
          width: 1px;
          height: 120px;
          background-color: #614964;
          position: absolute;
          left: 212px;
          top: 44px;
          content: '';
          display: block;
        }
      }
    }

    .month-picker-month {
      color: #fbd8f5;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;

      &[class*='variant-start'],
      &[class*='variant-end'] {
        background: ${theme.colors.textPrimary};
        color: ${theme.baseColors.primary[20]};
      }

      &[class*='variant-selected'] {
        background: #614964;
        color: #fbd8f5;
      }

      &[class*='variant-selected'] {
        color: #fbd8f5;
      }

      &[class*='variant-default'] {
        &:hover {
          background: transparent;
        }
      }
    }

    &.isopen {
      display: flex;
    }
  }
`
