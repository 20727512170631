import theme from 'theme'

const IntegrationWarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M0.833496 18.4167H19.1668L10.0002 2.58337L0.833496 18.4167ZM10.8335 15.9167H9.16683V14.25H10.8335V15.9167ZM10.8335 12.5834H9.16683V9.25004H10.8335V12.5834Z"
      fill={theme.baseColors.info[50]}
    />
  </svg>
)

export default IntegrationWarningIcon
