import { FC } from 'react'
import { Box, ClickAwayListener, InputAdornment, Skeleton, Checkbox, TextField, Typography } from '@mui/material'
import theme from 'theme'

/* Utils */
import { JiraUser } from 'models'

/* Components */
import { IconTooltip } from '../../../../security-stack-map/components/IconTooltip'
import { SearchSelect } from './searchSelectDropdown.styles'
import { SSMCardSkeleton, SSMCardSkeletonWrapper } from 'ui/pages/security-stack-map/components/ssmCard.styles'
import {
  AutocompleteContainer,
  Popper,
  NoIntegrationLogo,
  ResultListItem,
  ResultsList,
  SearchVendorsContainer,
  SearchVendorsInputWrapper,
  SearchVendorsResultsContainer
} from 'ui/pages/security-stack-map/components/searchVendors.styles'
import { InstanceCheckedIcon, InstanceUncheckedIcon } from 'ui/components/svg'

interface Props {
  addProductOpen: any
  addButtonRef: any
  handleCloseAdd: any
  initialSearched: any
  loading: any
  vendors: JiraUser[]
  getRootProps: any
  focused: any
  inputValue: any
  getInputProps: any
  setVendors: any
  setLoading: any
  debouncedSearch: any
  handleClearSearch: any
  emptyUser: any
  setInputValue: any
  getListboxProps: any
  handleListKeyDown: any
  getOptionProps: any
  getIntegrationLogo: any
  handleAddVendor: (newValue: JiraUser) => void
  handleToggleAdd: () => void
  field: string
  value: JiraUser[]
  minWidth?: string
  maxWidth?: string
  selectedOptions: JiraUser[]
}

const SearchSelectDropdown: FC<Props> = ({
  addProductOpen,
  addButtonRef,
  handleCloseAdd,
  initialSearched,
  loading,
  vendors,
  getRootProps,
  focused,
  inputValue,
  getInputProps,
  setVendors,
  setLoading,
  debouncedSearch,
  handleClearSearch,
  emptyUser,
  setInputValue,
  getListboxProps,
  handleListKeyDown,
  getOptionProps,
  getIntegrationLogo,
  handleAddVendor,
  handleToggleAdd,
  field,
  minWidth,
  maxWidth,
  value,
  selectedOptions
}) => {
  const list = inputValue
    ? [
        ...selectedOptions,
        ...vendors.filter((c) => !selectedOptions.find((n: any) => n.emailAddress === c.emailAddress))
      ]
    : selectedOptions

  return (
    <>
      <SearchSelect
        ref={addButtonRef}
        className="add-button"
        onClick={handleToggleAdd}
        id="composition-button"
        aria-controls={addProductOpen ? 'composition-menu' : undefined}
        aria-expanded={addProductOpen ? 'true' : undefined}
        aria-haspopup="true"
        sx={{
          minWidth: minWidth || '121px',
          borderColor: addProductOpen ? theme.colors.textPrimary : theme.colors.controlBorder,
          svg: {
            transform: addProductOpen ? 'rotate(-180deg)' : 'rotate(0deg)'
          },
          maxWidth,
          paddingRight: '28px'
        }}
      >
        <Typography>
          {!value.length ? '' : value.length === 1 ? value[0].emailAddress || '' : `${value.length} Selected`}
        </Typography>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown"
        >
          <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill={theme.colors.textPrimary}></path>
        </svg>
      </SearchSelect>

      <Popper
        open={addProductOpen}
        anchorEl={addButtonRef.current}
        transition
        disablePortal
        sx={{
          left: typeof window !== 'undefined' && window.innerWidth > 1760 ? '0 !important' : 'unset !important',
          right: typeof window !== 'undefined' && window.innerWidth > 1760 ? 'unset !important' : '0 !important'
        }}
      >
        <ClickAwayListener onClickAway={handleCloseAdd}>
          <SearchVendorsContainer
            height={
              (initialSearched && !loading && vendors.length === 1) ||
              (initialSearched && !loading && inputValue === '' && vendors.length > 1)
                ? '228px'
                : '311px'
            }
          >
            <AutocompleteContainer>
              <SearchVendorsInputWrapper
                {...getRootProps()}
                className={focused ? 'focused' : ''}
                hasinputvalue={inputValue.length > 0 ? 'true' : 'false'}
              >
                <TextField
                  variant="outlined"
                  color="primary"
                  size="medium"
                  label=""
                  inputProps={{ ...getInputProps() }}
                  InputProps={{
                    onChange: (e) => {
                      setLoading(true)
                      debouncedSearch(e.target.value, field)
                    },
                    onKeyDown: (e: any) => {
                      if (e.keyCode === 8) {
                        if (inputValue.length <= 1) {
                          setVendors([emptyUser])
                        }
                        setInputValue(e.target.value)
                      }
                    },
                    onKeyUp: (e: any) => {
                      if (e.keyCode === 8) {
                        if (inputValue.length >= 2) {
                          setLoading(true)
                          debouncedSearch(inputValue, field)
                        }
                      }
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M16.2939 14.5786H15.3905L15.0703 14.2699C16.191 12.9663 16.8656 11.2739 16.8656 9.43282C16.8656 5.32762 13.538 2 9.43282 2C5.32762 2 2 5.32762 2 9.43282C2 13.538 5.32762 16.8656 9.43282 16.8656C11.2739 16.8656 12.9663 16.191 14.2699 15.0703L14.5786 15.3905V16.2939L20.2962 22L22 20.2962L16.2939 14.5786ZM9.43282 14.5786C6.58548 14.5786 4.28702 12.2802 4.28702 9.43282C4.28702 6.58548 6.58548 4.28702 9.43282 4.28702C12.2802 4.28702 14.5786 6.58548 14.5786 9.43282C14.5786 12.2802 12.2802 14.5786 9.43282 14.5786Z"
                            fill={theme.colors.white}
                          />
                        </svg>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" onClick={handleClearSearch}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M20 5.61143L18.3886 4L12 10.3886L5.61143 4L4 5.61143L10.3886 12L4 18.3886L5.61143 20L12 13.6114L18.3886 20L20 18.3886L13.6114 12L20 5.61143Z"
                            fill={theme.colors.textSecondaryLow}
                          />
                        </svg>
                      </InputAdornment>
                    )
                  }}
                />
              </SearchVendorsInputWrapper>

              <SearchVendorsResultsContainer>
                <Typography className="search-vendors-results-text">
                  Results ({list.filter((c) => !!c.emailAddress).length})
                </Typography>
                {focused && loading && (
                  <SSMCardSkeletonWrapper sx={{ gap: `${theme.spacing.xSmall}px !important` }}>
                    <SSMCardSkeleton sx={{ height: '40px !important' }}>
                      <Skeleton
                        variant="rectangular"
                        component="div"
                        animation="wave"
                        width="100%"
                        height="100%"
                        className="card-skeleton-inner"
                      />
                    </SSMCardSkeleton>
                  </SSMCardSkeletonWrapper>
                )}
                <ResultsList {...getListboxProps()} items={list.length} onKeyDown={handleListKeyDown}>
                  {(!list.length || (list.length === 1 && !list[0].emailAddress)) && !loading ? (
                    <Box className="no-options-wrapper">
                      <Typography className="no-options-text">No results found</Typography>
                    </Box>
                  ) : (
                    list.map((option, index) => {
                      if (!option.emailAddress) return null
                      return (
                        <ResultListItem
                          {...getOptionProps({ option, index })}
                          key={index}
                          onClick={() => {
                            handleAddVendor(option)
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <IconTooltip
                              placement="left-start"
                              PopperProps={{
                                sx: {
                                  marginTop: `-${theme.spacing.xSmall}px !important`,
                                  marginRight: '7px !important'
                                }
                              }}
                              title={
                                <>
                                  <Box className="tooltip-wrapper">
                                    <svg
                                      width="12"
                                      height="14"
                                      viewBox="0 0 12 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="arrow-right"
                                    >
                                      <path d="M12 7.0718L0 0.143593V14L12 7.0718Z" fill={theme.colors.controlBorder} />
                                    </svg>

                                    <Typography className="tooltip-text">{option.emailAddress}</Typography>
                                  </Box>
                                </>
                              }
                            >
                              <Box className="list-item-wrapper">
                                <Checkbox
                                  icon={<InstanceUncheckedIcon />}
                                  checkedIcon={<InstanceCheckedIcon />}
                                  style={{ marginRight: 8 }}
                                  checked={!!value.find((c) => c.emailAddress === option.emailAddress)}
                                />

                                {option.avatarUrls['48x48'] ? (
                                  <img
                                    src={getIntegrationLogo(option.avatarUrls['48x48'])}
                                    width="24px"
                                    height="24px"
                                    alt={option.emailAddress}
                                  />
                                ) : (
                                  <NoIntegrationLogo>
                                    <Typography className="no-integration-logo-letter">
                                      {option.emailAddress[0]}
                                    </Typography>
                                  </NoIntegrationLogo>
                                )}

                                <Typography className="option-text">{option?.emailAddress || ''}</Typography>
                              </Box>
                            </IconTooltip>
                          </div>
                        </ResultListItem>
                      )
                    })
                  )}
                </ResultsList>
              </SearchVendorsResultsContainer>
            </AutocompleteContainer>
          </SearchVendorsContainer>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default SearchSelectDropdown
