import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const ConnectionDetailsTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.controlBorder
  },
  [`& .${tooltipClasses.tooltip}`]: {
    position: 'relative',
    fontFamily: theme.typography.fontFamily.primary,
    padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    borderRadius: `${theme.attributes.borderRadius.secondary}`,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.surfaceItemBackgroundLow,
    color: theme.colors.white,
    fontSize: theme.typography.fontSize.large,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: 'normal'
  }
})
