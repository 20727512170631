import { Box, Tabs as MuiTabs } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Tabs = styled(MuiTabs)<{ isdetails?: string }>`
  background: transparent;
  height: auto;

  .MuiTabs-scroller {
    height: 48px !important;
    padding-left: ${theme.spacing.medium}px;

    .MuiTabs-flexContainer {
      height: 48px !important;
    }
  }

  .MuiButtonBase-root {
    border-radius: 0;
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xLarge};
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: #debdd9;
    border: none;
    text-transform: none;
    padding: 0;
    width: fit-content;
    min-width: unset;
    height: 48px;
    min-height: 48px;
    margin-right: ${theme.spacing.xLarge}px;

    &.Mui-selected {
      color: ${theme.colors.textPrimary};
      border-bottom: 2px solid ${theme.colors.textPrimary};
    }

    &.Mui-disabled {
      color: #debdd9;
    }
  }

  ${({ isdetails }) =>
    isdetails &&
    isdetails === 'true' &&
    `
    height: 48px;
    min-height: 48px;
    
    .MuiTabs-scroller {
      height: 48px;
      
      .MuiTabs-flexContainer {
        height: 48px;
      }
    }
    
    .MuiButtonBase-root {
      height: 48px;
      white-space: nowrap;
      width: fit-content;
    }
  `};
`

export const DrawerTabPanelWrapper = styled(Box)`
  height: calc(100vh - 324px);
`
