import { FC } from 'react'
import { Box, DialogContent, DialogActions, Typography, IconButton, DialogTitle } from '@mui/material'
import theme from 'theme'

/* Utils */
import { SSMAssetType, SSMNistPillar, SSMResponseProduct } from '../../../../models'

/* Components */
import { Dialog, List } from './confirmRemovalDialog.styles'
import { ThemeButton } from '../../../components/buttons'

export interface Props {
  open: boolean
  onClose: () => void
  displayName: string
  numOfTiles: number
  handleClick: (
    asset: SSMResponseProduct,
    thisNist: SSMNistPillar,
    thisAsset: SSMAssetType,
    assets: SSMResponseProduct[]
  ) => void
  asset: any
  thisNist: any
  thisAsset: any
  assets: any
  removing: boolean
}

const ConfirmRemovalDialog: FC<Props> = ({
  onClose,
  open,
  displayName,
  numOfTiles,
  handleClick,
  asset,
  thisNist,
  thisAsset,
  assets,
  removing
}) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <Box className="header">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
              d="M1.3335 28.6667H30.6668L16.0002 3.33337L1.3335 28.6667ZM17.3335 24.6667H14.6668V22H17.3335V24.6667ZM17.3335 19.3334H14.6668V14H17.3335V19.3334Z"
              fill={theme.colors.textPrimary}
            />
          </svg>
          <Typography className="header-title">Warning</Typography>
        </Box>
        <IconButton size="medium" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M20 5.61143L18.3886 4L12 10.3886L5.61143 4L4 5.61143L10.3886 12L4 18.3886L5.61143 20L12 13.6114L18.3886 20L20 18.3886L13.6114 12L20 5.61143Z"
              fill={theme.colors.textPrimary}
            />
          </svg>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className="content-title">
          Are you sure you want to remove <span>{displayName}</span> from all Security Stack Map cells?
        </Typography>
        <List>
          <li>
            {displayName} will be removed from {numOfTiles} cells, and all cost configurations will be lost.
          </li>
        </List>
      </DialogContent>
      <DialogActions>
        <ThemeButton themevariant="secondary" onClick={onClose} disabled={removing}>
          Cancel
        </ThemeButton>
        <ThemeButton
          themevariant="primary"
          onClick={() => handleClick(asset, thisNist, thisAsset, assets)}
          sx={{ width: '56px !important', padding: '0 !important' }}
          disabled={removing}
        >
          OK
        </ThemeButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmRemovalDialog
