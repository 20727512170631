import * as d3 from 'd3'
import theme from 'theme'

/*
 * Hover Data Point Circles
 * */
export const appendHoverPointCircles = (
  svg:
    | d3.Selection<SVGSVGElement | null, unknown, null, undefined>
    | d3.Selection<SVGGElement, unknown, null, undefined>
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hoverCircleInner = svg.selectAll('.hover-circle-inner')
  if (!hoverCircleInner.empty()) hoverCircleInner.remove()
  svg
    .append('circle')
    .attr('class', 'hover-circle-inner')
    .attr('cx', 100)
    .attr('cy', 200)
    .attr('r', 7)
    .attr('fill', theme.colors.white)
    .attr('stroke', '#000')
    .attr('stroke-width', 2)
    .attr('stroke-opacity', 1)
    .attr('display', 'none')

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hoverCircleOuter = svg.selectAll('.hover-circle-outer')
  if (!hoverCircleOuter.empty()) hoverCircleOuter.remove()
  svg
    .append('circle')
    .attr('class', 'hover-circle-outer')
    .attr('cx', 100)
    .attr('cy', 200)
    .attr('r', 7)
    .attr('fill', 'none')
    .attr('stroke', theme.colors.white)
    .attr('stroke-width', 15)
    .attr('stroke-opacity', 0.5)
    .attr('display', 'none')
}

interface Props {
  svg:
    | d3.Selection<SVGSVGElement | null, unknown, null, undefined>
    | d3.Selection<SVGGElement, unknown, null, undefined>
  tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
  height: number
  chartHeightStart: number
}

/*
 * Hover Data Point Line
 * */
export const appendHoverPointLine = ({ svg, tooltip, height, chartHeightStart }: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const existingline = svg.selectAll('.cursor-line')
  if (!existingline.empty()) existingline.remove()
  const cursorLine = svg
    .append('line')
    .attr('class', 'cursor-line')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', chartHeightStart)
    .attr('y2', height)
    .attr('stroke', theme.colors.white)
    .attr('stroke-width', 1)
    .style('display', 'none')

  svg.on('mouseout', () => {
    cursorLine.style('display', 'none')
    tooltip.style('display', 'none')
    const circleOuter = svg.select('.hover-circle-outer')
    const circleInner = svg.select('.hover-circle-inner')
    svg.select('.add-event-button').style('display', 'none')
    circleOuter.attr('display', 'none')
    circleInner.attr('display', 'none')
  })
}
