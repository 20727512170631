import styled from '@emotion/styled'
import { Table, TableContainer } from '@mui/material'
import theme from 'theme'

export const TableContainerW = styled(TableContainer)<{ height?: string; hideoverflow?: string }>`
  background: ${theme.colors.surfaceContainerBackground};
  max-height: calc(100vh - 268px);
  width: 100%;
  overflow-x: auto;

  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}

  ${({ hideoverflow }) =>
    hideoverflow &&
    hideoverflow === 'true' &&
    `
    overflow: hidden !important;
  `}

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.quaternary};
  }
`

export const TableWrapper = styled(Table)`
  thead th {
    color: ${theme.baseColors.secondary[80]};
    text-overflow: ellipsis;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    padding: 9px ${theme.spacing.sm}px;
    border-top: 1px solid ${theme.colors.borderLow};
    border-left: 1px solid ${theme.colors.borderLow};
    border-bottom: 1px solid ${theme.colors.borderLow};
    background: ${theme.colors.surfaceContainerBackground};
    white-space: nowrap;
    cursor: pointer;

    &:last-child {
      border-right: 1px solid ${theme.colors.borderLow};
    }

    .row-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: ${theme.spacing.small}px;

      .row-header-text {
        overflow: hidden;
        color: ${theme.baseColors.secondary[80]};
        text-overflow: ellipsis;
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      }

      .icon-wrapper {
        display: flex;
        cursor: pointer;
      }

      .desc-icon {
        transform: rotate(180deg);
      }
    }
  }

  .no-padding {
    padding-right: 2px;
  }

  tbody tr {
    &:hover {
      background-color: ${theme.colors.surfaceItemBackground};
      border-bottom-color: ${theme.colors.borderLow};
    }
  }

  tbody td {
    padding: 11px ${theme.spacing.xSmall}px;
    border-left: 1px solid ${theme.colors.borderLow};
    border-bottom: 1px solid ${theme.colors.borderLow};
    color: ${theme.colors.white};
    text-overflow: ellipsis;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    height: 60px;

    &.loading-cell-wrapper {
      .MuiSkeleton-root {
        border-radius: ${theme.attributes.borderRadius.large};

        &:after {
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 15.32%,
            rgba(255, 255, 255, 0.35) 49.89%,
            rgba(255, 255, 255, 0) 83.78%
          );
        }
      }

      &:after {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 15.32%,
          rgba(255, 255, 255, 0.35) 49.89%,
          rgba(255, 255, 255, 0) 83.78%
        );
      }
    }

    .coverage-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ${theme.spacing.small}px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    &:last-child {
      border-right: 1px solid ${theme.colors.borderLow};
    }

    .ellipsis {
      @media (max-width: 1440px) {
        width: 90%;
      }
    }

    .cell-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: ${theme.spacing.md}px;

      .count {
        cursor: pointer;
        color: ${theme.baseColors.secondary[80]};

        &:hover {
          color: ${theme.colors.textPrimary};
        }
      }
    }
  }
`
