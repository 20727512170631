import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  width: 539px;
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.lg}px;

    .title {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 28px;
    }
  }
`

export const Content = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.mediumSmall};
  border: 1px solid ${theme.colors.controlBorder};
  background: linear-gradient(0deg, #292128 0%, #292128 100%);
  padding: ${theme.spacing.medium}px ${theme.spacing.medium}px ${theme.spacing.lg}px ${theme.spacing.medium}px;
  margin-bottom: ${theme.spacing.lg}px;

  &:last-child {
    margin-bottom: 0;
  }

  .MuiFormControl-root {
    width: 100%;
    border: 1px solid ${theme.colors.controlBorder};
    border-radius: ${theme.attributes.borderRadius.small};
    height: 52px;
  }

  label {
    background: linear-gradient(0deg, #292128 0%, #292128 100%);
    padding-left: ${theme.spacing.xsm}px;
    padding-right: ${theme.spacing.xsm}px;
  }

  label.Mui-focused {
    color: ${theme.colors.neutral};
    background: linear-gradient(0deg, #292128 0%, #292128 100%);
  }

  input {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .MuiOutlinedInput-root.Mui-disabled {
    height: 52px;
  }

  .Mui-disabled {
    color: ${theme.colors.neutral} !important;
    -webkit-text-fill-color: ${theme.colors.neutral} !important;
  }

  .actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .status-block {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;

      .status {
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    button {
      height: 40px;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 14px;

      .delete-btn {
        color: #ffb4ab !important;

        svg {
          path {
            fill: #ffb4ab !important;
          }
        }
      }
    }
  }
`

export const EmptyState = styled(Box)`
  .empty-title {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.huge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 52px;
    margin-bottom: ${theme.spacing.small}px;
  }

  .empty-desc {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 28px;
    margin-bottom: 34px;
  }

  .empty-img {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`
