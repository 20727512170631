import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  form {
    overflow: visible;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xSmall}px;

    .data-filtering-group {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      flex-basis: 100%;
      width: 100%;

      .delete-button {
        align-self: flex-end;

        &:hover {
          background: ${theme.baseColors.secondary[30]};
        }

        &.Mui-disabled {
          background: ${theme.baseColors.surface[50]};

          svg {
            path {
              fill: ${theme.baseColors.greyscale[50]};
            }
          }
        }
      }

      .MuiInputLabel-root {
        color: ${theme.colors.white};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      }

      .MuiOutlinedInput-root {
        border-radius: ${theme.attributes.borderRadius.small};
        height: 40px;
        padding: 0 ${theme.spacing.xSmall}px;

        &.MuiInputBase-multiline {
          height: 96px;
        }

        .MuiOutlinedInput-input {
          color: ${theme.colors.white};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0;

          &.MuiInputBase-inputMultiline {
            margin-top: ${theme.spacing.xSmall}px;
          }
        }

        fieldset {
          border-radius: ${theme.attributes.borderRadius.small};
          border: 1px solid ${theme.colors.controlBorder};
          padding: 0 ${theme.spacing.xSmall}px;
        }
      }

      .MuiInputAdornment-root {
        svg {
          path {
            fill: #debdd9;
          }
        }
      }
    }
  }
`
