import theme from '../../../../theme'

const AuditEditUserDetailsIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.2002 6.875H10.4502V8.375H2.2002V6.875ZM2.2002 5.375H10.4502V3.875H2.2002V5.375ZM2.2002 11.375H7.4502V9.875H2.2002V11.375ZM13.4577 9.0275L13.9902 8.495C14.2827 8.2025 14.7552 8.2025 15.0477 8.495L15.5802 9.0275C15.8727 9.32 15.8727 9.7925 15.5802 10.085L15.0477 10.6175L13.4577 9.0275ZM12.9252 9.56L8.9502 13.535V15.125H10.5402L14.5152 11.15L12.9252 9.56Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default AuditEditUserDetailsIcon
