import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './performanceScoreChart.styles.css'
import * as d3 from 'd3'
import { PieArcDatum } from 'd3'
import { Typography } from '@mui/material'
import theme from 'theme'

/* Utils */
import { IChartData, PerformanceStatus } from '../../../../../models'

/* Components */
import { PizzaChartWrapper, SetupWrapper } from './performanceScoreChart.styles'
import { RightIconButton } from '../../../../components/svg'
import { ThemeButton } from '../../../../components/buttons'
import PerformanceScoreTooltip, { PerformanceScoreTooltipProps } from './PerformanceScoreTooltip'
// import OverperformanceIconTooltip from './OverperformanceIconTooltip'

const numOfInnerCircles = 5

const sliceIsDisabled = (value: number) => {
  return value === 100
}

// const sliceIsActive = (value: number) => {
//   return value < 150 && value > 0
// }

const sliceIsInactive = (value: number) => {
  return value === 0
}

interface Props {
  data: IChartData[]
  noCategory: boolean
  tooltip: PerformanceScoreTooltipProps
  setTooltip: Dispatch<SetStateAction<PerformanceScoreTooltipProps>>
  overperformanceIconTooltip: PerformanceScoreTooltipProps
  setOverperformanceIconTooltip: Dispatch<SetStateAction<PerformanceScoreTooltipProps>>
  handleCategoryClick: (category: string) => void
  setHoveredCard: Dispatch<SetStateAction<string>>
}

const PerformanceScoreChart: FC<Props> = ({
  data,
  noCategory,
  tooltip,
  setTooltip,
  // overperformanceIconTooltip,
  setOverperformanceIconTooltip,
  handleCategoryClick,
  setHoveredCard
}) => {
  const navigate = useNavigate()
  const width = 223
  const height = 242

  const margin = { left: 25, right: 25, top: 10, bottom: 10 }
  const radius = (Math.min(width, height) - (margin.left + margin.right)) / 2
  /* Radius divided by number of circles */
  const radiusConst = radius / numOfInnerCircles

  useEffect(() => {
    const svg = d3
      .select('#chart')
      .attr('class', 'performance-score-chart-svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2 + 2},${height / 2})`)

    svg
      .append('filter')
      .attr('id', 'blurMe')
      .append('feGaussianBlur')
      .attr('in', 'SourceGraphic')
      .attr('stdDeviation', '2')

    const appendDefs = () => {
      const defs = svg.append('defs')

      const backgroundGradient = defs
        .append('linearGradient')
        .attr('id', 'backgroundGradient')
        .attr('x1', '0%')
        .attr('x2', '100%')
        .attr('y1', '0%')
        .attr('y2', '100%')
      backgroundGradient
        .append('stop')
        .attr('class', 'start')
        .attr('offset', '7%')
        .attr('stop-color', theme.colors.textPrimary)
        .attr('stop-opacity', 1)
      backgroundGradient
        .append('stop')
        .attr('class', 'end')
        .attr('offset', '93%')
        .attr('stop-color', theme.colors.textPrimary)
        .attr('stop-opacity', 1)

      const pinkGradient = defs
        .append('radialGradient')
        .attr('id', 'pinkGradient')
        .attr('x1', '0%')
        .attr('x2', '100%')
        .attr('y1', '0%')
        .attr('y2', '100%')
        .attr('cx', '0')
        .attr('cy', '0')
        .attr('r', '1')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('gradientTransform', `translate(0 0) rotate(90) scale(${width * 0.46})`)
      pinkGradient.append('stop').attr('offset', '0%').attr('stop-color', '#fda0fd').attr('stop-opacity', 0.98)
      pinkGradient.append('stop').attr('offset', '90%').attr('stop-color', '#ab7ddb').attr('stop-opacity', 0.4)

      const blueGradient = defs
        .append('radialGradient')
        .attr('id', 'blueGradient')
        .attr('cx', '0')
        .attr('cy', '0')
        .attr('r', '1')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('gradientTransform', `translate(0 0) rotate(90) scale(${width * 0.46})`)
      blueGradient.append('stop').attr('stop-color', '#3553f8').attr('stop-opacity', 0.6)
      blueGradient.append('stop').attr('offset', '100%').attr('stop-color', '#18246d').attr('stop-opacity', 0.5)
    }
    appendDefs()

    const arcBlue = d3.arc<PieArcDatum<IChartData>>().innerRadius(0).outerRadius(radius)
    const arcPink = (d: d3.PieArcDatum<any>) => {
      const originalData = d.data
      const { score } = originalData
      // Calculate the radius based on whether the value is over 100%
      const computedRadius = score > 100 ? radius * 1.039 : radius * (d.data.score / 100)
      // const computedRadius = score > 0 ? radius * (score > 100 ? 1.05 : score / 100) : 0.01
      const arcGenerator = d3.arc().innerRadius(0).outerRadius(computedRadius)

      return arcGenerator(d as unknown as d3.DefaultArcObject)
    }
    const pie = d3.pie<IChartData>().value(() => 100) // keep all slice with the same ratio
    const arcs = svg.selectAll('arc').data(pie(data)).enter().append('g').attr('class', 'arc')
    const arcsPink = svg.selectAll('arc').data(pie(data)).enter().append('g').attr('class', 'arc')

    arcs
      .append('path')
      .attr('d', arcBlue)
      .attr('stroke-width', ({ data: { score } }) => {
        if (score === 0) return 0
        else if (score <= 1) return 1.2
        else return 1.2
      })
      .attr('stroke', ({ data: { score, status } }) => {
        if (score === 0 || status === PerformanceStatus.unavailable) return 'transparent'
        else return '#2554f5'
      })
      .attr('class', 'chunk')
      .attr('opacity', 0.6)
      .attr('fill', ({ data: { score, status } }) => {
        if (score === 0 || status === PerformanceStatus.unavailable) return '#3d3042'
        else return 'url(#blueGradient)'
      })
      .attr('filter', (d: any) => (d.data.score === 0 ? 'url(#blurMe)' : ''))
      .on('mouseenter', (event, { data }) => {
        const x = event.pageX
        const y = event.pageY

        if (data.emptySlice) {
          setTooltip({ x: 0, y: 0, content: null })
        } else if (data.status === PerformanceStatus.unavailable) {
          setTooltip({ x, y, content: { ...data, score: 0 } })
          setHoveredCard(data.category)
        } else if (!sliceIsDisabled(data.score)) {
          setTooltip({ x, y, content: data })
          setHoveredCard(data.category)
        }
      })
      .on('mouseleave', () => {
        setTooltip({ x: 0, y: 0, content: null })
        setHoveredCard('')
      })
      .on('click', (e, { data: { score, category } }) => {
        if (sliceIsInactive(score)) {
          handleCategoryClick(category)
        }
      })

    arcsPink
      .append('path')
      .attr('class', 'pinkgradient')
      .attr('d', arcPink)
      .attr('stroke-width', '1')
      .attr('stroke', ({ data: { score, status } }) =>
        status === PerformanceStatus.unavailable ? 'transparent' : score < 100 ? '#fda0fd' : '#fda0fd'
      )
      .attr('fill', ({ data: { status } }) => {
        if (status === PerformanceStatus.unavailable) return '#3d3042'
        else return 'url(#pinkGradient)'
      })
      .attr('filter', (d: any) => (d.data.value === 0 ? 'url(#blurMe)' : ''))
      .on('mouseenter', (event, { data }) => {
        if (data.emptySlice) return

        const x = event.pageX
        const y = event.pageY

        if (data.status === PerformanceStatus.unavailable) {
          setTooltip({ x, y, content: { ...data, score: 0 } })
        } else if (!sliceIsDisabled(data.score)) {
          setTooltip({ x, y, content: data })
        }

        setHoveredCard(data.category)
      })
      .on('mouseleave', () => {
        setTooltip({ x: 0, y: 0, content: null })
        setHoveredCard('')
      })
      .on('click', (e, { data: { score, category } }) => {
        if (sliceIsInactive(score)) {
          handleCategoryClick(category)
        }
      })

    const pad = 0.04
    const radiusPoint1 = 25
    const radiusPoint2 = 15

    arcs
      .append('path')
      .attr('d', (d: any) => {
        const pos = arcBlue.centroid(d)
        const midAngle = Math.atan2(pos[1], pos[0])

        const x0 = Math.cos(midAngle) * (radius + radiusPoint1)
        const y0 = Math.sin(midAngle) * (radius + radiusPoint1)

        const x1 = Math.cos(midAngle - pad) * (radius + radiusPoint2)
        const y1 = Math.sin(midAngle - pad) * (radius + radiusPoint2)

        const x2 = Math.cos(midAngle + pad) * (radius + radiusPoint2)
        const y2 = Math.sin(midAngle + pad) * (radius + radiusPoint2)
        return `M${x0},${y0} ${x1},${y1} ${x2},${y2}Z`
      })
      .attr('stroke', 'white')
      .attr('stroke-width', 6)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('fill', theme.baseColors.info[40])
      .attr('display', (d: any) => {
        return d.data && d.data.score > 100 ? 'block' : 'none'
      })

    const pad2 = 0.033
    const radiusPoint12 = 23.5
    const radiusPoint22 = 15.9
    arcs
      .append('path')
      .attr('d', (d: any) => {
        const pos = arcBlue.centroid(d)
        const midAngle = Math.atan2(pos[1], pos[0])

        // Calculate initial point
        const x0 = Math.cos(midAngle) * (radius + radiusPoint12)
        const y0 = Math.sin(midAngle) * (radius + radiusPoint12)

        // Calculate points for corners
        const x1 = Math.cos(midAngle - pad2) * (radius + radiusPoint22)
        const y1 = Math.sin(midAngle - pad2) * (radius + radiusPoint22)

        const x2 = Math.cos(midAngle + pad2) * (radius + radiusPoint22)
        const y2 = Math.sin(midAngle + pad2) * (radius + radiusPoint22)

        return `M${x0},${y0} ${x1},${y1} ${x2},${y2}Z`
      })
      .attr('stroke', theme.colors.textPrimary)
      .attr('stroke-width', 6)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('fill', theme.baseColors.info[40])
      .attr('class', 'triangle')
      .attr('display', (d: any) => {
        return d.data && d.data.score > 100 ? 'block' : 'none'
      })
      .on('mouseenter', (event, { data }) => {
        const x = event.pageX
        const y = event.pageY
        setOverperformanceIconTooltip({ x, y, content: data })
        setHoveredCard(data.category)
      })
      .on('mouseleave', () => {
        setOverperformanceIconTooltip({ x: 0, y: 0, content: null })
        setHoveredCard('')
      })

    /* Blue Slices */
    const blueSlices = svg.selectAll('.slice-blue')
    // Move the lines to the bottom by appending each line to the SVG again
    blueSlices.each(function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      svg.node().appendChild(this)
    })

    /* Pink Slices */
    const pinkSlices = svg.selectAll('.slice-pink')
    // Move the lines to the bottom by appending each line to the SVG again
    pinkSlices.each(function (d) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      svg.node().appendChild(this)

      Array.from(Array(numOfInnerCircles).keys()).forEach((e) => {
        const n = e + 1
        /* Dots inside blue area */
        svg
          .append('path')
          .attr('stroke', '#efefef')
          .attr('fill', 'none')
          .attr('stroke-dasharray', '1,3')
          .attr('stroke-linecap', 'round')
          .attr(
            'd',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            d3
              .arc()
              .outerRadius(n * radiusConst)
              .innerRadius(n * radiusConst)
              .startAngle((d as any).endAngle)
              .endAngle((d as any).startAngle)
          )

        // outer blue line
        svg
          .append('path')
          .attr('stroke', () => {
            if ((d as any).data.score === 0) return theme.colors.white
            else if ((d as any).data.score < 100) return '#2554f5'
            else return 'none'
          })
          .attr('stroke-width', (d as any).data.score === 0 ? 0.2 : 0.75)
          .attr('fill', 'none')
          .attr(
            'd',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            d3
              .arc()
              .outerRadius(radius)
              .innerRadius(0)
              .startAngle((d as any).endAngle)
              .endAngle((d as any).startAngle)
          )
      })
    })

    const chunks = svg.selectAll('.chunk')

    // Move the lines to the bottom by appending each line to the SVG again
    chunks.each(function (d) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      svg.node().appendChild(this)
      Array.from(Array(numOfInnerCircles).keys()).forEach((e) => {
        const n = e + 1
        // dotted line
        svg
          .append('path')
          .attr('stroke', () => {
            // eslint-disable-next-line
            // @ts-ignore
            return d.data.score === 0 ? '#403e41' : '#efefef'
          })
          .attr('stroke-width', () => {
            // eslint-disable-next-line
            // @ts-ignore
            return d.data.score === 0 ? 2 : 1
          })
          .attr('fill', 'url(#blurMe)')
          .attr('stroke-dasharray', `1,3`)
          .attr('stroke-linecap', 'round')
          .attr(
            'd',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            d3
              .arc()
              .outerRadius(n * radiusConst)
              .innerRadius(n * radiusConst)
              .startAngle((d as any).endAngle)
              .endAngle((d as any).startAngle)
          )

        // blue line
        svg
          .append('path')
          .attr('stroke', () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (d.data.score === 0) return 'transparent'
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            else if (d.data.score < 100) return '#2554f5'
            else return 'none'
          })
          // eslint-disable-next-line
          .attr('stroke-width', (d as any).data.value == 0 ? 0.2 : 0.75)
          .attr('fill', 'none')
          .attr(
            'd',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            d3
              .arc()
              .outerRadius(radius)
              .innerRadius(0)
              .startAngle((d as any).endAngle)
              .endAngle((d as any).startAngle)
          )
      })
    })

    chunks.raise()
    svg.selectAll('.circle').lower()
    arcsPink.raise()
  }, [])

  return (
    <PizzaChartWrapper>
      {noCategory || data.every((n) => n.score === 100) ? (
        <>
          <SetupWrapper>
            <Typography className="setup-title">Activate a CPI to Begin</Typography>
            <ThemeButton onClick={() => navigate('/library')}>
              <>
                <RightIconButton />
                Explore CPIs
              </>
            </ThemeButton>
          </SetupWrapper>

          <img src="/blur-pizza.png" alt="Auth0 logo" />
        </>
      ) : null}

      <svg id="chart" />
      {tooltip.content && <PerformanceScoreTooltip x={tooltip.x} y={tooltip.y} content={tooltip.content} />}
      {/*      {overperformanceIconTooltip.content && (
        <OverperformanceIconTooltip
          x={overperformanceIconTooltip.x}
          y={overperformanceIconTooltip.y}
          content={overperformanceIconTooltip.content}
        />
      )} */}
    </PizzaChartWrapper>
  )
}

export default PerformanceScoreChart
