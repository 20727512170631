import StubPerformanceChart from './Stubs/StubPerformanceChart'
import { Box, Typography, useMediaQuery } from '@mui/material'

/* Components */
import { Wrapper } from './stubPerformanceScore.styles'
import StubPerformanceCategories from './Stubs/StubPerformanceCategories'
import StubCategoriesSectionMobile from './Stubs/StubCategoriesSectionMobile'

const StubPerformanceScore = () => {
  const secondBreakpoint = useMediaQuery('(min-width:1920px)')

  return (
    <Wrapper>
      <Box className="title-wrapper">
        <img src="/title-vector.svg" alt="title-vector" />
        <Typography className="chart-title">Category Performance Score</Typography>
      </Box>
      <Box className="inner">
        <Box className="chart">
          <StubPerformanceChart secondBreakpoint={secondBreakpoint} />
        </Box>
        <Box className="categories">
          {secondBreakpoint ? <StubPerformanceCategories /> : <StubCategoriesSectionMobile />}
        </Box>
      </Box>
    </Wrapper>
  )
}

export default StubPerformanceScore
