import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import theme from 'theme'

/* Utils */
import { CPITimePeriod, HistoryPerformanceData } from '../../../../../../../models'

/* Components */
import { CategoryPerformanceScoreWrapper } from '../../../categoryPage.styles'
import HistoryPerformanceChart from './HistoryPerformanceChart'
import { MostRecentFindingIcon, OverPerformIcon } from '../../../../../../components/svg'

interface Props {
  data: HistoryPerformanceData[]
  selectedTimePeriod: CPITimePeriod
  numberOfStripes: 7 | 4 | 3 | 6 | 12
  issolo?: string
  performanceValue: number
  lastUpdate: string
}

const PerformanceScoreHistory: FC<Props> = ({ issolo, data, selectedTimePeriod, numberOfStripes, lastUpdate }) => {
  const lastValue = data.length > 0 ? data[data.length - 1].yValue : 0
  const remainder = +(lastValue - 100)

  return (
    <CategoryPerformanceScoreWrapper issolo={issolo}>
      <Box className="header-block">
        <Typography className="title">Performance Score</Typography>
        <Typography className="date">Last Update: {lastUpdate}</Typography>
      </Box>
      <Box className="chips-wrapper" sx={{ marginBottom: '18px !important' }}>
        <Box className="chip">
          <Box className="chip-circle less" sx={{ background: `${theme.colors.textPrimary} !important` }} />{' '}
          <Typography className="chip-sla">Performance</Typography>
        </Box>
        <Box className="chip">
          <Box className="chip-circle same" sx={{ background: `${theme.baseColors.info[10]} !important` }} />{' '}
          <Typography className="chip-sla">SLA</Typography>
        </Box>
        <Box className="chip">
          <MostRecentFindingIcon /> <Typography className="chip-sla">Most Recent Finding</Typography>
        </Box>
      </Box>
      {remainder > 0 && (
        <Box className="over-perform">
          <OverPerformIcon />
          <Typography className="value">
            <b>{remainder}%</b> Over Performing
          </Typography>
        </Box>
      )}

      <Box className="performance-score-container">
        <Box className="performance-score-chart-container">
          {data.length > 0 && (
            <HistoryPerformanceChart
              data={data}
              selectedTimePeriod={selectedTimePeriod}
              numberOfStripes={numberOfStripes}
            />
          )}
        </Box>
      </Box>
    </CategoryPerformanceScoreWrapper>
  )
}

export default PerformanceScoreHistory
