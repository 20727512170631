export const baseColors = {
  primary: {
    10: '#37003b',
    20: '#590060',
    30: '#781a7e',
    40: '#943798',
    50: '#b051b3',
    60: '#ce6bcf',
    70: '#ec85ec',
    80: '#ffa9fc',
    90: '#ffd6f9',
    95: '#ffebf9',
    100: '#fffbff'
  },
  secondary: {
    10: '#261625',
    20: '#3c2b3b',
    30: '#544152',
    40: '#6c586a',
    50: '#867083',
    60: '#a18a9d',
    70: '#bca4b8',
    80: '#d9bfd4',
    90: '#f6dbf0',
    95: '#ffebf9',
    100: '#fffbff'
  },
  greyscale: {
    0: '#000000',
    10: '#211920',
    20: '#362e35',
    30: '#4d444d',
    40: '#665b63',
    50: '#7f747c',
    60: '#998d96',
    70: '#b5a8b0',
    80: '#d0c3cc',
    90: '#eddee8',
    95: '#fcedf6',
    99: '#fffbff',
    100: '#ffffff'
  },
  info: {
    10: '#3754fc',
    20: '#aed581',
    30: '#ffe082',
    40: '#f09543',
    50: '#ff8a80',
    60: '#aed58133'
  },
  surface: {
    10: '#1e1a1d',
    20: '#292128',
    30: '#362e35',
    40: '#372a36',
    50: '#483646',
    60: '#573f56'
  },
  brand: {
    10: '#2b0645',
    20: '#ea8e3b',
    30: '#421682',
    40: '#8f9cf8',
    50: '#ffffff'
  }
}
