import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import axios from '../../../../lib/axios'
import debounce from 'lodash.debounce'
import { useAuth } from '@frontegg/react'
import theme from 'theme'

/* Utils */
import { User } from '../../../../models'
import { breadcrumbs, hasPermission, Permission } from '../../../../lib/utils'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { modalPropsSelector, ModalType, openModal } from '../../../../store/modals/modalSlice'
import { closeToast, openToast, toastPropsSelector, ToastVariant } from '../../../../store/toasts/toastSlice'

/* Components */
import {
  EmptyRow,
  Row,
  TableBody,
  TableHeader,
  UserManagementContainer,
  UserManagementWrapper
} from './user-management.styles'
import { ThemeButton } from '../../../components/buttons'
import {
  CheckboxChecked,
  CheckboxIndeterminate,
  CheckboxUnchecked,
  DeleteIcon,
  PaginationArrowRight,
  PlusIcon
} from '../../../components/svg'
import SearchUsers from './components/SearchUsers'
import HeaderComponent from '../../../components/header/HeaderComponent'
import ToolbarComponent from '../../../components/header/ToolbarComponent'
import ContentWrapper from '../../../components/header/ContentWrapper'
import { PermissionTooltip } from 'ui/components/tooltip'

const PAGINATION_LIMIT = 50

const UserManagement: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const toastProps = useAppSelector(toastPropsSelector)
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(PAGINATION_LIMIT)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [hasNext, setHasNext] = useState(users.length >= 20)
  const [selected, setSelected] = useState<User[]>([])
  const [searchedOptions, setSearchedOptions] = useState<string[]>(
    JSON.parse(localStorage.getItem('USERS_SEARCH_TERMS') || '[]')
  )
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()
  const userRole = useMemo(() => user?.roles[0].name || '', [user])

  const fetchUsers = async () => {
    const res = await axios.get('/api/v1/identity/users')

    const { limit, total, users } = res.data
    setTotal(total)
    setLimit(limit)
    setHasNext(total > limit)
    setHasPrevious(false)
    setUsers(users)
    setAllUsers(users)
  }

  useEffect(() => {
    fetchUsers().catch((e) => e)
  }, [modalProps.props, toastProps.props])

  const handleInviteUser = async () => {
    dispatch(openModal({ type: ModalType.inviteUser }))
  }

  const handleCheckAll = () => {
    if (selected.length === users.length) {
      setSelected([])
    } else {
      setSelected(users)
    }
  }

  const handlePrevious = () => {
    if (page > 0) {
      setPage((page) => page - 1)
    }
    setPage(0)
  }

  const handleNext = async () => {
    const nextPage = page + 1
    setPage(nextPage)
    const url = `/api/v1/identity/users?page=${page}&limit=${limit}`
    const res = await axios.get(url)

    if (res.status === 200) {
      const { limit, total, users } = res.data
      setLimit(limit)
      setTotal(total)
      setUsers(users)
      setHasNext(total > limit)
      setHasPrevious(nextPage > 0)
    }
  }

  const handleCheckboxChange = (e: boolean, n: User) => {
    let c = [...selected]
    if (e) {
      c.push(n)
    } else {
      c = c.filter((k) => k.name !== n.name)
    }
    setSelected(c)
  }

  const deleteUserCallback = () => {
    setSelected([])
  }

  const handleDeleteUsers = () => {
    dispatch(openModal({ type: ModalType.deleteUsers, props: { users: selected, callback: deleteUserCallback } }))
  }

  const handleSearch = async (event: SyntheticEvent, value: string) => {
    setLoading(true)
    let url = `/api/v1/identity/users?page=${page}&limit=${limit}&search=${value}`
    if (value.includes('verified')) {
      url += '&verified=true'
    }

    /* Fallback */
    const filtered = allUsers.filter((n) => {
      if (n.email.toLowerCase().includes(value.toLowerCase())) {
        return n
      }
      if (n.name.toLowerCase().includes(value.toLowerCase())) {
        return n
      }
      if (n.role && n.role.toLowerCase().includes(value.toLowerCase())) {
        return n
      }
      if (n.verified && value.toLowerCase().includes('status')) {
        return n
      }
      if (!n.verified && (value.toLowerCase().includes('pending') || value.toLowerCase().includes('approval'))) {
        return n
      }
      return null
    })

    try {
      const res = await axios.get(url)

      const newSearched = [...searchedOptions]

      if (res.status === 200) {
        const { limit, total, users } = res.data
        setLimit(limit)
        setTotal(total)
        setUsers(users)
        setHasNext(total > limit)
        setHasPrevious(page > 0)

        if (!newSearched.includes(value)) {
          newSearched.push(value)
          setSearchedOptions(newSearched)
          localStorage.setItem('USERS_SEARCH_TERMS', JSON.stringify(newSearched))
          setLoading(false)
          return
        } else {
          localStorage.setItem('USERS_SEARCH_TERMS', JSON.stringify(newSearched))
          setSearchedOptions(newSearched)
        }
      } else {
        if (!newSearched.includes(value)) {
          newSearched.push(value)
          setSearchedOptions(newSearched)
          localStorage.setItem('USERS_SEARCH_TERMS', JSON.stringify(newSearched))
          setLoading(false)
          return
        } else {
          localStorage.setItem('USERS_SEARCH_TERMS', JSON.stringify(newSearched))
          setSearchedOptions(newSearched)
        }

        if (value === '') {
          setUsers(allUsers)
        } else {
          setUsers(filtered)
        }
      }
    } catch (e) {
      console.error(e)
      setUsers(filtered)
    } finally {
      setLoading(false)
    }
  }

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 500), [allUsers])

  const handlePasswordReset = async () => {
    if (
      !hasPermission(Permission.UsersSelfPasswordUpdate, user) ||
      !hasPermission(Permission.UsersPasswordUpdate, user)
    ) {
      return
    }
    if (selected.length > 0) {
      try {
        const res = await axios.post('/api/v1/identity/users/password', {
          users: selected.map((n) => n.id)
        })

        if (res.status === 200) {
          dispatch(
            openToast({
              variant: ToastVariant.success,
              props: {
                text: `Reset Password Link${selected.length > 1 ? 's' : ''} Sent`,
                description: `Password reset link${selected.length > 1 ? 's' : ''} sent to ${selected.length} user${
                  selected.length > 1 ? 's' : ''
                }`
              }
            })
          )
        }
      } catch (e) {
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Failed sending link',
              description:
                (e as any).response?.data.message ?? 'There was a problem while sending your password reset link.'
            }
          })
        )
      } finally {
        setTimeout(() => {
          dispatch(closeToast())
        }, 5000)
      }
    }
  }

  const handleOpenProfile = (rowUser: User) => {
    if (user && user.email === rowUser.email) {
      const loggedInUser: User = {
        created_at: (user as any).createdAt || '',
        email: user.email,
        id: user.id,
        name: user.name || '',
        phone_number: Number(user.phoneNumber) ?? null,
        profile_picture_url: user.profilePictureUrl || '',
        tenant_id: user.tenantId || '',
        verified: user.verified || false,
        role: user.roles.length > 0 ? user.roles[0].name : 'Demo'
      }
      dispatch(openModal({ type: ModalType.userProfile, props: { user: loggedInUser } }))
    } else {
      dispatch(openModal({ type: ModalType.userProfile, props: { user: rowUser } }))
    }
  }

  return (
    <>
      <HeaderComponent breadcrumbs={breadcrumbs.userManagement} />
      <ToolbarComponent>
        <UserManagementWrapper>
          <SearchUsers options={searchedOptions} onInputChange={debouncedHandleSearch} loading={loading} />
          <ThemeButton
            themevariant="primary"
            font="big"
            onClick={handleInviteUser}
            disabled={!hasPermission(Permission.UsersInvite, user)}
          >
            <>
              <PlusIcon />
              Add User
            </>
          </ThemeButton>
        </UserManagementWrapper>
      </ToolbarComponent>

      <ContentWrapper line={2} paddingBottom={80}>
        <UserManagementContainer>
          <Grid spacing={3} container sx={{ width: '100%', height: '100%', pb: `${theme.spacing.xLarge}px` }}>
            <Grid item xs={12} sx={{ height: '100%' }}>
              <TableBody>
                <TableHeader>
                  <Box className="header-actions">
                    <IconButton onClick={handleCheckAll}>
                      {selected.length > 0 && selected.length < users.length ? (
                        <CheckboxIndeterminate />
                      ) : selected.length > 0 && selected.length === users.length ? (
                        <CheckboxChecked />
                      ) : (
                        <CheckboxUnchecked />
                      )}
                    </IconButton>
                    {selected.length > 0 && (
                      <Button
                        className="password-reset"
                        onClick={handlePasswordReset}
                        disabled={!hasPermission(Permission.UsersPasswordUpdate, user)}
                      >
                        <SendIcon />
                        Send Password Reset Link
                      </Button>
                    )}
                    {selected.length > 0 && (
                      <>
                        {['Admin', 'Viewer', 'Demo'].includes(userRole) ? (
                          <PermissionTooltip
                            PopperProps={{
                              sx: {
                                marginTop: `-${theme.spacing.sm}px !important`
                              }
                            }}
                            title={
                              <>
                                <Box className="tooltip-wrapper-permissions">
                                  <Typography className="tooltip-text">Insufficient permissions</Typography>
                                </Box>
                              </>
                            }
                          >
                            <Button className="delete-user disabled" disableTouchRipple>
                              <DeleteIcon />
                              Delete {selected.length > 1 ? 'Users' : 'User'}
                            </Button>
                          </PermissionTooltip>
                        ) : (
                          <Button
                            className="delete-user"
                            onClick={handleDeleteUsers}
                            disabled={!hasPermission(Permission.SubscriptionDelete, user)}
                          >
                            <DeleteIcon />
                            Delete {selected.length > 1 ? 'Users' : 'User'}
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                  <Box className="pagination-actions">
                    <Typography className="pagination-text">
                      {page + 1}-{total <= users.length ? total : limit} of {limit}
                    </Typography>
                    <IconButton onClick={handlePrevious} disabled={!hasPrevious} className="arrow-button">
                      <PaginationArrowRight style={{ transform: 'rotate(180deg)' }} />
                    </IconButton>
                    <IconButton onClick={handleNext} disabled={!hasNext} className="arrow-button">
                      <PaginationArrowRight />
                    </IconButton>
                  </Box>
                </TableHeader>
                {users.length > 0 && (
                  <Box>
                    {users.map((n: User) => (
                      <Row key={n.name} userrole={n.role ? n.role.toLowerCase() : ''}>
                        {selected.findIndex((e) => n.email === e.email) > -1 ? (
                          <IconButton onClick={() => handleCheckboxChange(false, n)}>
                            <CheckboxChecked />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleCheckboxChange(true, n)}>
                            <CheckboxUnchecked />
                          </IconButton>
                        )}
                        <Typography className="name" onClick={() => handleOpenProfile(n)}>
                          {n.name}
                        </Typography>
                        <Typography className="email" onClick={() => handleOpenProfile(n)} data-private>
                          {n.email}
                        </Typography>
                        <Box
                          className="actions-wrapper"
                          onClick={() => handleOpenProfile(n)}
                          sx={!n.role ? { display: 'flex', justifyContent: 'flex-end' } : null}
                        >
                          {n.role && (
                            <Box className="permissions-container">
                              <Box className="permissions-title">{n.role === 'cpi_manager' ? 'Manager' : n.role}</Box>
                            </Box>
                          )}
                          <Box
                            className="status-container"
                            onClick={() => handleOpenProfile(n)}
                            sx={!n.role ? { width: 'fit-content !important', marginRight: '60px !important' } : null}
                          >
                            <Box className="user-status">
                              {!n.role ? 'Pending Approval' : n.verified ? 'Active' : 'Pending Approval'}
                            </Box>
                          </Box>
                        </Box>
                      </Row>
                    ))}
                  </Box>
                )}
                {allUsers.length > 0 && !users.length && (
                  <EmptyRow>
                    <Typography className="title">No results were found.</Typography>
                    <Typography className="text">Try a new search to get started.</Typography>
                  </EmptyRow>
                )}
              </TableBody>
            </Grid>
          </Grid>
        </UserManagementContainer>
      </ContentWrapper>
    </>
  )
}

export default UserManagement
