import { Dispatch, FC, SetStateAction, useState, useRef } from 'react'
import { Box, SelectChangeEvent, Typography } from '@mui/material'
import theme from 'theme'

/* Utils */
import {
  IntegrationInstance,
  LastSeenWithin,
  OperatingSystem,
  TableHeaders,
  defaultTableHeaders,
  AnalysisDataTable,
  AcaLastSeen
} from '../../../../models'
import { Pagination } from '../useCoverageAnalyzerData'

/* Components */
import { Container, ContentWrapper } from './analysis.styles'
import FilterComponent from './FilterComponent'
import MiscControlSelectField from '../sections/trends-tab/components/controls-components/MiscControlSelectField'
import {
  MiscControlWrapper,
  CoverageWrapper,
  CoverageItem
} from '../sections/trends-tab/components/analysisChartDescription.styles'
import OnlyA from '../../../components/svg/coverage-analyzer/OnlyA'
import OnlyB from '../../../components/svg/coverage-analyzer/OnlyB'
import Both from '../../../components/svg/coverage-analyzer/Both'
import NotOne from '../../../components/svg/coverage-analyzer/NotOne'
import RowDataDrawer from './settings/RowDataDrawer'
import TableContent from './table-components/TableContent'

const coveredBy = [
  { label: 'Only A', img: <OnlyA /> },
  { label: 'Only B', img: <OnlyB /> },
  { label: 'A and B', img: <Both /> },
  { label: 'Only A or Only B', img: <NotOne /> }
]

interface Props {
  loadingAnalysisData: boolean
  selectedOperatingSystem: OperatingSystem
  setSelectedOperatingSystem: Dispatch<SetStateAction<OperatingSystem>>
  fetchAnalysisData: ({
    integrationIds,
    operatingSystem,
    lastSeenWithin,
    searchTerm,
    filters,
    sortBy,
    direction
  }: {
    integrationIds: string[]
    operatingSystem?: OperatingSystem
    lastSeenWithin?: LastSeenWithin
    searchTerm?: string
    filters?: boolean[]
    sortBy?: string
    direction?: string
  }) => void
  pagination: Pagination
  setPagination: Dispatch<SetStateAction<Pagination>>
  tableFilters: boolean[]
  setTableFilters: Dispatch<SetStateAction<boolean[]>>
  setLoadingAnalysisData: Dispatch<SetStateAction<boolean>>
  initialLoad: boolean
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  fetchTotalAssets: ({
    integrationIds,
    lastSeenWithin
  }: {
    integrationIds: string[]
    lastSeenWithin: AcaLastSeen
  }) => Promise<void>
  rows: AnalysisDataTable[]
  setRows: Dispatch<SetStateAction<AnalysisDataTable[]>>
  firstInstance: IntegrationInstance | undefined
  secondInstance: IntegrationInstance | undefined
  firstInstanceName: string
  secondInstanceName: string
  showFilter: boolean
  isFilterActive: boolean
  setIsFilterActive: Dispatch<SetStateAction<boolean>>
  setShowFilter: Dispatch<SetStateAction<boolean>>
  firstSourceInField: string
  secondSourceInField: string
  setFirstSource: Dispatch<SetStateAction<string>>
  setSecondSource: Dispatch<SetStateAction<string>>
  selectedInstances: IntegrationInstance[]
  exporting: boolean
  handleExport: ({
    integrationIds,
    operatingSystem,
    lastSeenWithin,
    searchTerm,
    filters,
    sortBy,
    direction
  }: {
    integrationIds?: string[]
    operatingSystem?: OperatingSystem
    lastSeenWithin?: LastSeenWithin
    searchTerm?: string
    filters?: boolean[]
    sortBy?: string
    direction?: string
  }) => void
  selectedSeenWithin: LastSeenWithin
  setSelectedSeenWithin: Dispatch<SetStateAction<LastSeenWithin>>
  activeSeenWithin: LastSeenWithin
}

const Analysis: FC<Props> = ({
  selectedOperatingSystem,
  setSelectedOperatingSystem,
  initialLoad,
  loadingAnalysisData,
  fetchAnalysisData,
  pagination,
  selectedSeenWithin,
  setPagination,
  tableFilters,
  setTableFilters,
  setLoadingAnalysisData,
  search,
  setSearch,
  fetchTotalAssets,
  rows,
  setRows,
  firstInstance,
  secondInstance,
  firstInstanceName,
  secondInstanceName,
  showFilter,
  isFilterActive,
  setIsFilterActive,
  setShowFilter,
  firstSourceInField,
  secondSourceInField,
  setFirstSource,
  setSecondSource,
  selectedInstances,
  exporting,
  handleExport,
  activeSeenWithin,
  setSelectedSeenWithin
}) => {
  const contentWrapperRef = useRef<HTMLDivElement | null>(null)
  const [columns, setColumns] = useState<TableHeaders[]>([...defaultTableHeaders])
  const [openRowDrawer, setOpenRowDrawer] = useState(false)
  const [activeRowItem, setActiveRowItem] = useState<AnalysisDataTable | null>(null)

  const resetFilter = () => {
    setTableFilters([false, false, false, false])

    setSelectedOperatingSystem(OperatingSystem.All)
    setIsFilterActive(false)
    fetchAnalysisData({
      searchTerm: search,
      filters: [false, false, false, false],
      integrationIds: selectedInstances.map((n) => n.id),
      operatingSystem: OperatingSystem.All
    })
  }

  const handleChangeCoverage = async (index: number) => {
    setLoadingAnalysisData(true)
    const temp = [...tableFilters.map((n, idx) => (index === idx ? n : false))]
    temp[index] = !temp[index]
    setTableFilters(temp)

    const firstId = selectedInstances[0].id
    const secondId = selectedInstances[1].id
    if (firstId !== firstSourceInField || secondId !== secondSourceInField) {
      setFirstSource(selectedInstances[0].id)
      setSecondSource(selectedInstances[1].id)
    }

    if (selectedSeenWithin !== activeSeenWithin) {
      setSelectedSeenWithin(activeSeenWithin)
    }
    const isOneTrue = temp.find((item) => item)
    if (isOneTrue) setIsFilterActive(isOneTrue)
    else if (!isOneTrue && selectedOperatingSystem === 'All') setIsFilterActive(false)

    await handleSubmit(temp)
  }

  const handleChangeOperatingSystem = async (event: SelectChangeEvent) => {
    const operatingSystem = event.target.value as OperatingSystem
    setSelectedOperatingSystem(operatingSystem)
    setIsFilterActive(true)

    const firstId = selectedInstances[0].id
    const secondId = selectedInstances[1].id
    if (firstId !== firstSourceInField || secondId !== secondSourceInField) {
      setFirstSource(selectedInstances[0].id)
      setSecondSource(selectedInstances[1].id)
    }

    await fetchAnalysisData({
      integrationIds: selectedInstances.map((n) => n.id),
      operatingSystem,
      filters: tableFilters,
      searchTerm: search
    })
  }

  const handleSubmit = async (filters: boolean[]) => {
    if (!firstInstance || !secondInstance) return

    await fetchAnalysisData({
      integrationIds: selectedInstances.map((n) => n.id),
      filters,
      searchTerm: search
    })
  }

  return (
    <Container>
      <ContentWrapper showFilter={showFilter} ref={contentWrapperRef} className="table-content-wrapper">
        <TableContent
          ref={contentWrapperRef}
          search={search}
          setSearch={setSearch}
          showFilter={showFilter}
          isFilterActive={isFilterActive}
          setShowFilter={setShowFilter}
          columns={columns}
          setColumns={setColumns}
          loadingAnalysisData={loadingAnalysisData}
          firstInstanceName={firstInstanceName}
          secondInstanceName={secondInstanceName}
          initialLoad={initialLoad}
          setOpenRowDrawer={setOpenRowDrawer}
          setActiveRowItem={setActiveRowItem}
          firstInstance={firstInstance}
          secondInstance={secondInstance}
          pagination={pagination}
          setPagination={setPagination}
          selectedSeenWithin={selectedSeenWithin}
          selectedOperatingSystem={selectedOperatingSystem}
          fetchAnalysisData={fetchAnalysisData}
          tableFilters={tableFilters}
          fetchTotalAssets={fetchTotalAssets}
          setLoadingAnalysisData={setLoadingAnalysisData}
          rows={rows}
          setRows={setRows}
          firstSourceInField={firstSourceInField}
          secondSourceInField={secondSourceInField}
          setFirstSource={setFirstSource}
          setSecondSource={setSecondSource}
          selectedInstances={selectedInstances}
          exporting={exporting}
          handleExport={handleExport}
        />
      </ContentWrapper>

      {showFilter && (
        <Box
          sx={{
            width: '1px',
            background: theme.colors.borderLow,
            marginLeft: `${theme.spacing.medium}px`,
            marginRight: `-${theme.spacing.medium}px`,
            height: 'calc(100vh - 345px)'
          }}
        />
      )}

      <FilterComponent showFilter={showFilter} setShowFilter={setShowFilter} resetFilter={resetFilter}>
        <>
          <MiscControlWrapper>
            <Typography className="misc-control-title" sx={{ marginBottom: '0 !important' }}>
              Covered by
            </Typography>

            <CoverageWrapper>
              {coveredBy.map((item, index) => (
                <CoverageItem
                  isActive={tableFilters[index]}
                  key={item.label}
                  onClick={() => handleChangeCoverage(index)}
                  disabled={loadingAnalysisData ? 'true' : 'false'}
                >
                  {item.img}
                  <Typography className="name">{item.label}</Typography>
                </CoverageItem>
              ))}
            </CoverageWrapper>
          </MiscControlWrapper>

          <MiscControlWrapper>
            <Typography
              className="misc-control-title"
              sx={{
                marginBottom: `${theme.spacing.xSmall}px !important`,
                fontWeight: `${theme.typography.fontWeight.normal} !important`
              }}
            >
              Operating System
            </Typography>
            <MiscControlSelectField
              options={Object.values(OperatingSystem)}
              label=""
              placeholder=""
              noLabel={true}
              value={selectedOperatingSystem}
              handleChange={handleChangeOperatingSystem}
              disabled={(!firstSourceInField && !secondSourceInField) || loadingAnalysisData}
            />
          </MiscControlWrapper>
        </>
      </FilterComponent>

      <RowDataDrawer
        drawerOpen={openRowDrawer}
        activeRowItem={activeRowItem}
        setDrawerOpen={setOpenRowDrawer}
        firstInstance={firstInstance}
        secondInstance={secondInstance}
      />
    </Container>
  )
}

export default Analysis
