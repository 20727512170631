import theme from '../../../../theme'

const AuditDeleteUserIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.125 6.5C10.125 4.8425 8.7825 3.5 7.125 3.5C5.4675 3.5 4.125 4.8425 4.125 6.5C4.125 8.1575 5.4675 9.5 7.125 9.5C8.7825 9.5 10.125 8.1575 10.125 6.5ZM8.625 6.5C8.625 7.325 7.95 8 7.125 8C6.3 8 5.625 7.325 5.625 6.5C5.625 5.675 6.3 5 7.125 5C7.95 5 8.625 5.675 8.625 6.5Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M1.125 14V15.5H13.125V14C13.125 12.005 9.1275 11 7.125 11C5.1225 11 1.125 12.005 1.125 14ZM2.625 14C2.775 13.4675 5.1 12.5 7.125 12.5C9.1425 12.5 11.4525 13.46 11.625 14H2.625Z"
      fill={theme.colors.textPrimary}
    />
    <path d="M16.875 8H12.375V9.5H16.875V8Z" fill={theme.colors.textPrimary} />
  </svg>
)

export default AuditDeleteUserIcon
