import { FC } from 'react'
import { Box, Button } from '@mui/material'
import theme from 'theme'

interface Props {
  handleCancel: () => void
  handleBack: () => void
  activeStep: number
  isSubmitting: boolean
  isValid: boolean
  hasSelectedProjects: boolean
}

const JiraIntegrationActions: FC<Props> = ({
  handleCancel,
  handleBack,
  activeStep,
  isSubmitting,
  isValid,
  hasSelectedProjects
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }}
  >
    <Button
      onClick={handleCancel}
      sx={{
        color: theme.colors.rawSienneCore,
        border: `1px solid ${theme.colors.rawSienneCore}`,
        borderRadius: theme.attributes.borderRadius.small,
        textTransform: 'capitalize',
        fontSize: theme.typography.fontSize.xLarge,
        lineHeight: '24px',
        fontWeight: 700,
        padding: `${theme.spacing.xsm}px ${theme.spacing.small}px`
      }}
    >
      Cancel
    </Button>
    <>
      <Button
        onClick={handleBack}
        disabled={activeStep === 0}
        sx={{
          color: theme.colors.purpleLight,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: '24px',
          fontWeight: 700,
          background: 'rgba(0, 0, 0, 0.06)',
          borderRadius: theme.attributes.borderRadius.small,
          textTransform: 'capitalize',
          padding: `${theme.spacing.xsm}px ${theme.spacing.small}px`,
          margin: `0 ${theme.spacing.small}px 0 33px`
        }}
      >
        Back
      </Button>
      <Button
        type="submit"
        sx={{
          color: theme.colors.purpleLight,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: '24px',
          fontWeight: 700,
          background: 'rgba(0, 0, 0, 0.06)',
          borderRadius: theme.attributes.borderRadius.small,
          textTransform: 'capitalize',
          padding: `${theme.spacing.xsm}px ${theme.spacing.small}px`
        }}
        disabled={activeStep === 1 ? !hasSelectedProjects : !isValid || isSubmitting}
      >
        {activeStep < 2 ? 'Next' : 'Finish'}
      </Button>
    </>
  </Box>
)

export default JiraIntegrationActions
