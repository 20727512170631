import { Dispatch, FC, SetStateAction } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import { Wrapper } from './CalendarDatePicker.styles'
import { DateRangeI } from '../../../../../../../../models'
import theme from 'theme'

interface Props {
  editDate: string | null
  dateRange: DateRangeI
  setForm: Dispatch<SetStateAction<any>>
  format?: string
}

const CalendarDatePicker: FC<Props> = ({ dateRange, editDate, setForm, format }) => {
  const today = new Date().getTime()
  const todayFormatted = editDate || today

  const handleDateChange = (date: any) => {
    setForm((prevState: any) => ({
      ...prevState,
      date: date
    }))
  }

  return (
    <Wrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Date"
          maxDate={dayjs(dateRange.maxDate)}
          minDate={dayjs(dateRange.minDate)}
          defaultValue={dayjs(todayFormatted)}
          format={format}
          onAccept={(date) => {
            handleDateChange(date)
          }}
          slotProps={{
            popper: {
              sx: {
                '.MuiPaper-root.MuiPickersPopper-paper': {
                  borderRadius: theme.attributes.borderRadius.quaternary,
                  background: theme.colors.primaryLight,

                  '.MuiPickersArrowSwitcher-root': {
                    '.MuiButtonBase-root': {
                      color: '#CAC4D0'
                    }
                  },

                  '.MuiPickersCalendarHeader-label': {
                    color: '#CAC4D0',
                    fontFamily: theme.typography.fontFamily.primary,
                    fontSize: theme.typography.fontSize.large,
                    fontStyle: 'normal',
                    fontWeight: theme.typography.fontWeight.bold,
                    lineHeight: '20px',
                    letterSpacing: '0.1px'
                  },

                  '.MuiDayCalendar-header': {
                    '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel': {
                      color: '#E6E0E9',
                      textAlign: 'center',
                      fontFamily: theme.typography.fontFamily.primary,
                      fontSize: theme.typography.fontSize.xLarge,
                      fontStyle: 'normal',
                      fontWeight: theme.typography.fontWeight.bold,
                      lineHeight: '24px',
                      letterSpacing: '0.1px'
                    }
                  },

                  '.MuiButtonBase-root.MuiPickersDay-root': {
                    color: '#E6E0E9',
                    textAlign: 'center',
                    fontFamily: theme.typography.fontFamily.primary,
                    fontSize: theme.typography.fontSize.xLarge,
                    fontStyle: 'normal',
                    fontWeight: theme.typography.fontWeight.bold,
                    lineHeight: '24px',
                    letterSpacing: '0.1px',

                    '&.Mui-selected': {
                      background: theme.colors.textPrimary,
                      color: '#66006E'
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        background: theme.colors.textSecondaryLow,
                        borderColor: theme.colors.textSecondaryLow
                      }
                    },
                    '&.MuiPickersDay-today': {
                      background: 'transparent',
                      border: `1px solid ${theme.colors.textPrimary}`,
                      color: theme.colors.textPrimary
                    },
                    '&.Mui-disabled': {
                      color: theme.colors.controlBorder
                    }
                  },

                  '.MuiButton-root': {
                    color: theme.colors.textPrimary,
                    fontFamily: theme.typography.fontFamily.primary,
                    fontSize: theme.typography.fontSize.large,
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    margin: '0'
                  }
                }
              }
            },
            actionBar: {
              actions: ['cancel', 'accept']
            }
          }}
        />
      </LocalizationProvider>
    </Wrapper>
  )
}

export default CalendarDatePicker
