import React, { FC } from 'react'
import { FormControl, Select } from './reportsTimePeriodSelectField.styles'
import { SelectDropdownIcon } from '../../../../components/svg'
import { IconButton, InputAdornment, OutlinedInput, SelectProps } from '@mui/material'
import theme from 'theme'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

interface Props extends SelectProps {
  value?: any
  minWidth?: string
  disabled: boolean
  anchorEl: any
  setAnchorEl: any
  setPickerOpen: any
  pickerOpen: boolean
}

const ReportsMonthPickerSelectField: FC<Props> = ({
  pickerOpen,
  placeholder,
  setPickerOpen,
  minWidth,
  value,
  disabled,
  anchorEl,
  setAnchorEl,
  ...rest
}) => {
  const handleClick = (event: any) => {
    if (disabled) return
    if (pickerOpen) {
      setAnchorEl(null)
      setPickerOpen(false)
    } else {
      setPickerOpen(true)
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <FormControl sx={{ minWidth }} is_opened={anchorEl ? 'true' : 'false'} is_disabled={disabled ? 'true' : 'false'}>
      <Select
        labelId="reports-customize-select-label"
        id="reports-customize-select"
        displayEmpty
        MenuProps={{
          style: { display: 'none' },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => {
            setAnchorEl(null)
            setPickerOpen(false)
          }
        }}
        sx={{
          minWidth,
          '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          const z = value.slice(0, 4)
          const n = value.slice(5)
          const c = parseInt(n, 10)
          const x = months[c - 1]
          const formatted = `${x} ${z}`

          return !value ? 'Select' : formatted && (formatted as string).length > 0 ? formatted : 'Select'
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        placeholder="Select"
        value={value}
        onChange={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        label="Select"
        input={
          <OutlinedInput
            onMouseDown={handleClick}
            value={value}
            startAdornment={
              <InputAdornment position="start">
                <IconButton edge="start">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM9 14H7V12H9V14ZM13 14H11V12H13V14ZM17 14H15V12H17V14ZM9 18H7V16H9V18ZM13 18H11V16H13V18ZM17 18H15V16H17V18Z"
                      fill={theme.baseColors.greyscale[80]}
                    />
                  </svg>
                </IconButton>
              </InputAdornment>
            }
          />
        }
      />
    </FormControl>
  )
}

export default ReportsMonthPickerSelectField
