import theme from 'theme'

const DeleteSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6841 2.25V3H15.4341V4.5H14.6841V14.25C14.6841 15.075 14.0091 15.75 13.1841 15.75H5.68408C4.85908 15.75 4.18408 15.075 4.18408 14.25V4.5H3.43408V3H7.18408V2.25H11.6841ZM5.68408 14.25H13.1841V4.5H5.68408V14.25ZM7.18408 6H8.68408V12.75H7.18408V6ZM11.6841 6H10.1841V12.75H11.6841V6Z"
        fill={theme.colors.textPrimary}
      />
    </svg>
  )
}

export default DeleteSvg
