import theme from 'theme'

const PinLabelIcon = () => (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke={theme.colors.controlBorder} />
    <path
      d="M25.13 13.84C24.77 13.33 24.17 13 23.5 13L12.5 13.01C11.4 13.01 10.5 13.9 10.5 15V25C10.5 26.1 11.4 26.99 12.5 26.99L23.5 27C24.17 27 24.77 26.67 25.13 26.16L29.5 20L25.13 13.84ZM23.5 25H12.5V15H23.5L27.05 20L23.5 25Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default PinLabelIcon
