import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import theme from 'theme'

/* Utils */
import { CustomLabelCountI, CustomLabelI, SystemEventName } from '../../../../../../../../models'
import { dateFormat } from '../../../../../../../../lib/utils'
import { CUSTOM_LABEL_ID, getSystemEventValue } from '../../../../../utils'

/* Components */
import { Wrapper } from './CustomLabel.style'
import YellowLabelSvg from '../../../../../../../components/svg/labels/YellowLabelSvg'
import { LibraryDetailsHoverTooltip } from '../../../../components/LibraryDetailsTooltip'
import { EditIcon } from '../../../../../../../components/svg'
import PinSvg from '../../../../../../../components/svg/labels/PinSvg'

interface Props {
  setLoading: Dispatch<SetStateAction<boolean>>
  customLabel: CustomLabelI
  customLabelsCount: CustomLabelCountI
  updateEditLabel: (label: any) => void
  updateMode: (newMode: string) => void
  updateLabels: () => void
  unpinLabel: (labelId: string) => Promise<void>
  pinLabel: (labelId: string) => Promise<void>
  pinningLabel: boolean
  setPinningLabel: Dispatch<SetStateAction<boolean>>
}

const CustomLabel: FC<Props> = ({
  customLabel,
  customLabelsCount,
  updateMode,
  updateEditLabel,
  updateLabels,
  // setLoading,
  unpinLabel,
  pinLabel,
  pinningLabel,
  setPinningLabel
}) => {
  const isPined = useMemo(() => {
    return !!customLabelsCount?.pinned_labels?.find((item) => item.id === customLabel.id)
  }, [customLabelsCount])

  const openEdit = () => {
    updateMode('edit')
    updateEditLabel(customLabel)
  }

  const togglePin = async () => {
    try {
      setPinningLabel(true)

      if (isPined) {
        await unpinLabel(customLabel.id)
      } else {
        await pinLabel(customLabel.id)
      }

      await updateLabels()
    } catch (e) {
      console.error(e)
    } finally {
      setPinningLabel(false)
    }
  }

  if (!customLabel.data) return <Box></Box>
  return (
    <Wrapper>
      <Box>
        <Box className="header">
          <YellowLabelSvg />
          <Typography className="title">
            {customLabel.action === CUSTOM_LABEL_ID ? customLabel.data.title : getSystemEventValue(customLabel.action)}
          </Typography>
        </Box>

        <Typography className="description">
          {customLabel.action === SystemEventName.CpiCustom ? customLabel.data.description : customLabel.description}
        </Typography>
        <Typography className="date">
          {dayjs(customLabel.data.date || customLabel.created_at).format(dateFormat.short)}
        </Typography>
      </Box>
      <Box className="actions-wrapper">
        {pinningLabel ? (
          <Box
            sx={{
              background: isPined ? theme.colors.textPrimary : 'transparent',
              border: `1px solid ${theme.colors.controlBorder}`,
              borderRadius: theme.attributes.borderRadius.circle,
              width: '32px',
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress size={16} color="secondary" />
          </Box>
        ) : (
          <LibraryDetailsHoverTooltip
            title={
              isPined
                ? 'Unpin Label from Chart'
                : customLabelsCount?.pinned_labels.length >= 4
                ? '4 Labels max'
                : 'Pin Label to Chart'
            }
            arrow
            placement="bottom-end"
          >
            <IconButton
              className={`pined-button ${
                isPined ? 'is-pined' : customLabelsCount?.pinned_labels.length >= 4 ? 'is-disabled' : ''
              }`}
              onClick={() => togglePin()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px !important',
                height: '32px !important',
                padding: '5px !important',
                overflow: 'hidden !important'
              }}
            >
              <PinSvg isPined={isPined ? true : customLabelsCount?.pinned_labels.length >= 4 ? null : false} />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        )}

        {/*        {isPined ? (
          <LibraryDetailsHoverTooltip title={'Unpin Label from Chart'} arrow placement="bottom-end">
            <IconButton className={`is-pined pined-button`} onClick={() => togglePin()}>
              <PinSvg isPined={true} />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        ) : customLabelsCount?.pinned_labels.length >= 4 ? (
          <LibraryDetailsHoverTooltip title={'4 Labels max'} arrow placement="bottom-end">
            <IconButton className={`is-disabled pined-button`}>
              <PinSvg isPined={null} />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        ) : (
          <LibraryDetailsHoverTooltip title={'Pin Label to Chart'} arrow placement="bottom-end">
            <IconButton className={`pined-button`} onClick={() => togglePin()}>
              <PinSvg isPined={false} />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        )} */}

        {customLabel.action === SystemEventName.CpiCustom ? (
          <LibraryDetailsHoverTooltip title="Edit Label" arrow placement="bottom-end">
            <IconButton className="edit-button" onClick={() => openEdit()}>
              <EditIcon />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        ) : (
          ''
        )}
      </Box>
    </Wrapper>
  )
}

export default CustomLabel
