import theme from 'theme'

const CategoryActivityIcon = () => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.33333 0L10.86 1.52667L7.60667 4.78L4.94 2.11333L0 7.06L0.94 8L4.94 4L7.60667 6.66667L11.8067 2.47333L13.3333 4V0H9.33333Z"
      fill={theme.baseColors.greyscale[80]}
    />
  </svg>
)

export default CategoryActivityIcon
