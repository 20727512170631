import theme from 'theme'

const EmptyPizza = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
      <g filter="url(#filter0_f_230_8552)">
        <mask id="path-1-inside-1_230_8552" fill="white">
          <path d="M197 100.5C197 153.795 153.795 197 100.5 197C47.2045 197 4 153.795 4 100.5C4 47.2045 47.2045 4 100.5 4C153.795 4 197 47.2045 197 100.5ZM81.2 100.5C81.2 111.159 89.8409 119.8 100.5 119.8C111.159 119.8 119.8 111.159 119.8 100.5C119.8 89.8409 111.159 81.2 100.5 81.2C89.8409 81.2 81.2 89.8409 81.2 100.5Z" />
        </mask>
        <path
          d="M197 100.5C197 153.795 153.795 197 100.5 197C47.2045 197 4 153.795 4 100.5C4 47.2045 47.2045 4 100.5 4C153.795 4 197 47.2045 197 100.5ZM81.2 100.5C81.2 111.159 89.8409 119.8 100.5 119.8C111.159 119.8 119.8 111.159 119.8 100.5C119.8 89.8409 111.159 81.2 100.5 81.2C89.8409 81.2 81.2 89.8409 81.2 100.5Z"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          strokeDasharray="2 2"
          mask="url(#path-1-inside-1_230_8552)"
        />
        <mask id="path-2-inside-2_230_8552" fill="white">
          <path d="M197 100.5C197 153.795 153.795 197 100.5 197C47.2045 197 4 153.795 4 100.5C4 47.2045 47.2045 4 100.5 4C153.795 4 197 47.2045 197 100.5ZM61.9 100.5C61.9 121.818 79.1818 139.1 100.5 139.1C121.818 139.1 139.1 121.818 139.1 100.5C139.1 79.1818 121.818 61.9 100.5 61.9C79.1818 61.9 61.9 79.1818 61.9 100.5Z" />
        </mask>
        <path
          d="M197 100.5C197 153.795 153.795 197 100.5 197C47.2045 197 4 153.795 4 100.5C4 47.2045 47.2045 4 100.5 4C153.795 4 197 47.2045 197 100.5ZM61.9 100.5C61.9 121.818 79.1818 139.1 100.5 139.1C121.818 139.1 139.1 121.818 139.1 100.5C139.1 79.1818 121.818 61.9 100.5 61.9C79.1818 61.9 61.9 79.1818 61.9 100.5Z"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          strokeDasharray="2 2"
          mask="url(#path-2-inside-2_230_8552)"
        />
        <mask id="path-3-inside-3_230_8552" fill="white">
          <path d="M197 100.5C197 153.795 153.795 197 100.5 197C47.2045 197 4 153.795 4 100.5C4 47.2045 47.2045 4 100.5 4C153.795 4 197 47.2045 197 100.5ZM42.6 100.5C42.6 132.477 68.5227 158.4 100.5 158.4C132.477 158.4 158.4 132.477 158.4 100.5C158.4 68.5227 132.477 42.6 100.5 42.6C68.5227 42.6 42.6 68.5227 42.6 100.5Z" />
        </mask>
        <path
          d="M197 100.5C197 153.795 153.795 197 100.5 197C47.2045 197 4 153.795 4 100.5C4 47.2045 47.2045 4 100.5 4C153.795 4 197 47.2045 197 100.5ZM42.6 100.5C42.6 132.477 68.5227 158.4 100.5 158.4C132.477 158.4 158.4 132.477 158.4 100.5C158.4 68.5227 132.477 42.6 100.5 42.6C68.5227 42.6 42.6 68.5227 42.6 100.5Z"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          strokeDasharray="2 2"
          mask="url(#path-3-inside-3_230_8552)"
        />
        <mask id="path-4-inside-4_230_8552" fill="white">
          <path d="M197 100.5C197 153.795 153.795 197 100.5 197C47.2045 197 4 153.795 4 100.5C4 47.2045 47.2045 4 100.5 4C153.795 4 197 47.2045 197 100.5ZM23.3 100.5C23.3 143.136 57.8636 177.7 100.5 177.7C143.136 177.7 177.7 143.136 177.7 100.5C177.7 57.8636 143.136 23.3 100.5 23.3C57.8636 23.3 23.3 57.8636 23.3 100.5Z" />
        </mask>
        <path
          d="M197 100.5C197 153.795 153.795 197 100.5 197C47.2045 197 4 153.795 4 100.5C4 47.2045 47.2045 4 100.5 4C153.795 4 197 47.2045 197 100.5ZM23.3 100.5C23.3 143.136 57.8636 177.7 100.5 177.7C143.136 177.7 177.7 143.136 177.7 100.5C177.7 57.8636 143.136 23.3 100.5 23.3C57.8636 23.3 23.3 57.8636 23.3 100.5Z"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          strokeDasharray="2 2"
          mask="url(#path-4-inside-4_230_8552)"
        />
        <mask id="path-5-inside-5_230_8552" fill="white">
          <path d="M100.5 4C117.474 4 134.147 8.47696 148.837 16.9791C163.528 25.4813 175.716 37.7079 184.172 52.4252L100.5 100.5V4Z" />
        </mask>
        <path
          d="M100.5 4C117.474 4 134.147 8.47696 148.837 16.9791C163.528 25.4813 175.716 37.7079 184.172 52.4252L100.5 100.5V4Z"
          fill={theme.colors.borderLow}
          fillOpacity="0.02"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          mask="url(#path-5-inside-5_230_8552)"
        />
        <mask id="path-6-inside-6_230_8552" fill="white">
          <path d="M184.071 52.25C192.558 66.9496 197.018 83.6275 197 100.601C196.982 117.575 192.488 134.243 183.97 148.925L100.5 100.5L184.071 52.25Z" />
        </mask>
        <path
          d="M184.071 52.25C192.558 66.9496 197.018 83.6275 197 100.601C196.982 117.575 192.488 134.243 183.97 148.925L100.5 100.5L184.071 52.25Z"
          fill={theme.colors.borderLow}
          fillOpacity="0.02"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          mask="url(#path-6-inside-6_230_8552)"
        />
        <path
          d="M100.997 101.365L183.386 148.932C174.954 163.363 162.9 175.344 148.412 183.689C133.924 192.033 117.511 196.448 100.798 196.5L100.997 101.365Z"
          fill={theme.colors.borderLow}
          fillOpacity="0.02"
          stroke={theme.colors.borderLow}
        />
        <mask id="path-8-inside-7_230_8552" fill="white">
          <path d="M100.5 197C83.5264 197 66.8531 192.523 52.1625 184.021C37.4719 175.519 25.2836 163.292 16.8277 148.575L100.5 100.5L100.5 197Z" />
        </mask>
        <path
          d="M100.5 197C83.5264 197 66.8531 192.523 52.1625 184.021C37.4719 175.519 25.2836 163.292 16.8277 148.575L100.5 100.5L100.5 197Z"
          fill={theme.colors.borderLow}
          fillOpacity="0.02"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          mask="url(#path-8-inside-7_230_8552)"
        />
        <mask id="path-9-inside-8_230_8552" fill="white">
          <path d="M16.9286 148.75C8.44177 134.05 3.98228 117.373 4.00005 100.399C4.01783 83.4254 8.51224 66.7568 17.0298 52.0751L100.5 100.5L16.9286 148.75Z" />
        </mask>
        <path
          d="M16.9286 148.75C8.44177 134.05 3.98228 117.373 4.00005 100.399C4.01783 83.4254 8.51224 66.7568 17.0298 52.0751L100.5 100.5L16.9286 148.75Z"
          fill={theme.colors.borderLow}
          fillOpacity="0.02"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          mask="url(#path-9-inside-8_230_8552)"
        />
        <mask id="path-10-inside-9_230_8552" fill="white">
          <path d="M17.0813 51.9864C25.6145 37.3137 37.8667 25.1513 52.6018 16.7264C67.3369 8.30151 84.0335 3.91219 101.007 4.00133L100.5 100.5L17.0813 51.9864Z" />
        </mask>
        <path
          d="M17.0813 51.9864C25.6145 37.3137 37.8667 25.1513 52.6018 16.7264C67.3369 8.30151 84.0335 3.91219 101.007 4.00133L100.5 100.5L17.0813 51.9864Z"
          fill={theme.colors.borderLow}
          fillOpacity="0.02"
          stroke={theme.colors.borderLow}
          strokeWidth="2"
          mask="url(#path-10-inside-9_230_8552)"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_230_8552"
          x="0"
          y="0"
          width="200"
          height="200"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_230_8552" />
        </filter>
      </defs>
    </svg>
  )
}

export default EmptyPizza
