import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'
import { CPIColor } from 'lib/utils'

const Container = styled(Box)<{ containerType: 'info' | 'error' }>`
  border-radius: ${theme.attributes.borderRadius.secondary};
  border: 1px solid ${theme.colors.primaryLight};
  background: ${theme.colors.primaryLight};
  box-shadow: ${theme.attributes.boxShadow.toolbar};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.small}px;
  padding: ${theme.spacing.small}px;
  height: 56px;
  width: 255px;
  min-width: 255px;

  img {
    min-width: 24px;
    min-height: 24px;
  }

  ${({ containerType }) =>
    containerType === 'error' &&
    `
      border: 1px solid ${theme.colors.primaryLight};
      background: ${CPIColor.Problematic};
    `};

  .total-info-text {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    white-space: nowrap;
  }
`

interface Props {
  type: 'info' | 'error'
}

const TotalMustEqual: FC<Props> = ({ type = 'info' }) => (
  <Container className="total-info-wrapper" containerType={type}>
    <img
      src={type === 'info' ? '/info-icon.svg' : '/error-icon.svg'}
      alt={type === 'info' ? 'info' : 'error'}
      width="24px"
      height="24px"
    />
    <Typography className="total-info-text">Total must equal 100%.</Typography>
  </Container>
)

export default TotalMustEqual
