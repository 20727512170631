import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const BenchmarkCardTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: `${theme.spacing.xSmall}px`,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.chipCircle,
    width: '150px'
  },
  '.tooltip-wrapper-permissions': {
    display: 'flex',
    alignItems: 'center',
    width: '50px'
  },
  '.tooltip-title': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.xxxLarge,
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '32px',
    marginBottom: '3px'
  },
  '.tooltip-text': {
    color: theme.colors.white,
    fontSize: theme.typography.fontSize.medium,
    fontFamily: theme.typography.fontFamily.primary,
    lineHeight: '16px',
    fontWeight: theme.typography.fontWeight.normal,
    letterSpacing: '0.4px'
  },
  '.tooltip-cpi-text': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    fontWeight: theme.typography.fontWeight.bold,
    lineHeight: '20px',
    letterSpacing: '0.1px'
  },
  '.tooltip-category': {
    textTransform: 'capitalize',
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.medium,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
})
