import { Dispatch, SetStateAction, useState, forwardRef, Fragment } from 'react'
import { Box, Typography, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import theme from 'theme'

/* Utils */
import { PreviewDataIssue, TableHeaders } from 'models'
import { camelToSnake } from 'lib/utils'

/* Components */
import { TableContainerW, TableWrapper } from './dataTable.styles'
import TableColumnArrow from '../../../../../components/svg/coverage-analyzer/TableColumnArrow'
import LoadingRows from '../../../../coverage-analyzer/components/LoadingRows'
import { DataTableTooltip } from './DataTableTooltip'

type Ref = HTMLDivElement | null

interface Props {
  columns: TableHeaders[]
  setColumns: Dispatch<SetStateAction<TableHeaders[]>>
  handleSort: ({ column, direction }: { column: string; direction: string }) => Promise<void>
  rows: PreviewDataIssue[]
  search: string
}

const DataTable = forwardRef<Ref, Props>(({ columns, setColumns, handleSort, rows, search }, ref) => {
  const [loading] = useState(false)

  const canShow = (key: string) => {
    const findItem = columns.find((column) => column.dataKey === key)

    return findItem?.show
  }

  const sortData = async (index: number) => {
    const temp = [...columns.map((c) => ({ ...c, sort: false }))]

    const key = temp[index].dataKey
    if (key === 'matchedBy') return

    temp[index].sortType = temp[index].sortType === 'asc' ? 'desc' : 'asc'
    temp[index].sort = true
    const tempName = camelToSnake(temp[index].dataKey)
    const column =
      tempName === 'original_serial_number' ? 'serial_number' : tempName === 'last_address' ? 'last_seen' : tempName
    const direction = temp[index].sortType.toUpperCase()

    setColumns(temp)

    await handleSort({ column, direction })
  }

  const highlightMatch = (text: string) => {
    if (!search) return text

    const regex = new RegExp(`(${search})`, 'gi')
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ color: theme.colors.textPrimary }}>
          {part}
        </span>
      ) : (
        <Fragment key={index}>{part}</Fragment>
      )
    )
  }

  return (
    <Box>
      <TableContainerW ref={ref}>
        <TableWrapper sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {columns
                .filter((item) => item.show)
                .map((column, index) => {
                  return (
                    <TableCell key={index} className={column.sort ? 'no-padding' : ''} onClick={() => sortData(index)}>
                      <Box className="row-wrapper header-cell-inner">
                        <Typography className="row-header-text">{column.name}</Typography>

                        {column.sort && (
                          <Box
                            className={`${column.sortType === 'desc' ? '' : 'desc-icon'} icon-wrapper`}
                            onClick={() => sortData(index)}
                          >
                            <TableColumnArrow />
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  )
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              if (loading && index > rows.length - 5) {
                return <LoadingRows height="50px !important" />
              }
              return (
                <TableRow key={index}>
                  {canShow('type') && (
                    <TableCell>
                      <DataTableTooltip
                        title={
                          <>
                            <Box className="tooltip-list">
                              <Typography className="tooltip-header">{row.type}</Typography>
                            </Box>
                          </>
                        }
                      >
                        <img src={row.iconUrl} alt="" width="24px" height="24px" />
                      </DataTableTooltip>
                    </TableCell>
                  )}

                  {canShow('key') && (
                    <TableCell>
                      <Box className="cell-wrapper">
                        <DataTableTooltip
                          title={
                            <>
                              <Box className="tooltip-list">
                                <Typography className="tooltip-header">{row.key}</Typography>
                              </Box>
                            </>
                          }
                        >
                          <Typography className="ellipsis">{highlightMatch(row.key || '')}</Typography>
                        </DataTableTooltip>
                      </Box>
                    </TableCell>
                  )}

                  {canShow('summary') && (
                    <TableCell>
                      <Box className="cell-wrapper">
                        <DataTableTooltip
                          title={
                            <>
                              <Box className="tooltip-list">
                                <Typography className="tooltip-header">{row.summary}</Typography>
                              </Box>
                            </>
                          }
                        >
                          <Typography className="ellipsis">{highlightMatch(row.summary || '')}</Typography>
                        </DataTableTooltip>
                      </Box>
                    </TableCell>
                  )}

                  {canShow('priority') && (
                    <TableCell>
                      <Box className="cell-wrapper">
                        <DataTableTooltip
                          title={
                            <>
                              <Box className="tooltip-list">
                                <Typography className="tooltip-header">{row.priority}</Typography>
                              </Box>
                            </>
                          }
                        >
                          <Typography className="ellipsis">{highlightMatch(row.priority || '')}</Typography>
                        </DataTableTooltip>
                      </Box>
                    </TableCell>
                  )}

                  {canShow('status') && (
                    <TableCell>
                      <Box className="cell-wrapper">
                        <DataTableTooltip
                          title={
                            <>
                              <Box className="tooltip-list">
                                <Typography className="tooltip-header">{row.status}</Typography>
                              </Box>
                            </>
                          }
                        >
                          <Typography className="ellipsis">{highlightMatch(row.status || '')}</Typography>
                        </DataTableTooltip>
                      </Box>
                    </TableCell>
                  )}

                  {canShow('assignee') && (
                    <TableCell>
                      <Box className="cell-wrapper">
                        <DataTableTooltip
                          title={
                            <>
                              <Box className="tooltip-list">
                                <Typography className="tooltip-header">{row.assignee}</Typography>
                              </Box>
                            </>
                          }
                        >
                          <Typography className="ellipsis">{highlightMatch(row.assignee || '')}</Typography>
                        </DataTableTooltip>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </TableWrapper>
      </TableContainerW>
    </Box>
  )
})

export default DataTable
