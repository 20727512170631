import theme from 'theme'

const StepperActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.25 3V21C17.22 21 21.25 16.97 21.25 12C21.25 7.03 17.22 3 12.25 3Z" fill={theme.colors.textPrimary} />
    <path
      d="M6.25 15C6.80228 15 7.25 14.5523 7.25 14C7.25 13.4477 6.80228 13 6.25 13C5.69772 13 5.25 13.4477 5.25 14C5.25 14.5523 5.69772 15 6.25 15Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M6.25 19C6.80228 19 7.25 18.5523 7.25 18C7.25 17.4477 6.80228 17 6.25 17C5.69772 17 5.25 17.4477 5.25 18C5.25 18.5523 5.69772 19 6.25 19Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M6.25 11C6.80228 11 7.25 10.5523 7.25 10C7.25 9.44772 6.80228 9 6.25 9C5.69772 9 5.25 9.44772 5.25 10C5.25 10.5523 5.69772 11 6.25 11Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M3.25 10.5C3.52614 10.5 3.75 10.2761 3.75 10C3.75 9.72386 3.52614 9.5 3.25 9.5C2.97386 9.5 2.75 9.72386 2.75 10C2.75 10.2761 2.97386 10.5 3.25 10.5Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M6.25 7C6.80228 7 7.25 6.55228 7.25 6C7.25 5.44772 6.80228 5 6.25 5C5.69772 5 5.25 5.44772 5.25 6C5.25 6.55228 5.69772 7 6.25 7Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M3.25 14.5C3.52614 14.5 3.75 14.2761 3.75 14C3.75 13.7239 3.52614 13.5 3.25 13.5C2.97386 13.5 2.75 13.7239 2.75 14C2.75 14.2761 2.97386 14.5 3.25 14.5Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M10.25 21.5C10.5261 21.5 10.75 21.2761 10.75 21C10.75 20.7239 10.5261 20.5 10.25 20.5C9.97386 20.5 9.75 20.7239 9.75 21C9.75 21.2761 9.97386 21.5 10.25 21.5Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M10.25 3.5C10.5261 3.5 10.75 3.27614 10.75 3C10.75 2.72386 10.5261 2.5 10.25 2.5C9.97386 2.5 9.75 2.72386 9.75 3C9.75 3.27614 9.97386 3.5 10.25 3.5Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M10.25 7C10.8023 7 11.25 6.55228 11.25 6C11.25 5.44772 10.8023 5 10.25 5C9.69772 5 9.25 5.44772 9.25 6C9.25 6.55228 9.69772 7 10.25 7Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M10.25 15.5C11.0784 15.5 11.75 14.8284 11.75 14C11.75 13.1716 11.0784 12.5 10.25 12.5C9.42157 12.5 8.75 13.1716 8.75 14C8.75 14.8284 9.42157 15.5 10.25 15.5Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M10.25 11.5C11.0784 11.5 11.75 10.8284 11.75 10C11.75 9.17157 11.0784 8.5 10.25 8.5C9.42157 8.5 8.75 9.17157 8.75 10C8.75 10.8284 9.42157 11.5 10.25 11.5Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M10.25 19C10.8023 19 11.25 18.5523 11.25 18C11.25 17.4477 10.8023 17 10.25 17C9.69772 17 9.25 17.4477 9.25 18C9.25 18.5523 9.69772 19 10.25 19Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default StepperActiveIcon
