import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  border-top: 1px solid rgba(222, 189, 217, 0.25);
  padding-top: ${theme.spacing.small}px;
  padding-right: ${theme.spacing.medium}px;
  padding-left: ${theme.spacing.medium}px;

  .line {
    background: rgba(222, 189, 217, 0.5);
    opacity: 0.5;
    margin-bottom: ${theme.spacing.medium}px;
    margin-top: ${theme.spacing.medium}px;
    width: 100%;
    display: block;
    height: 2px;
  }

  .line-actions {
    flex-direction: column !important;
    gap: ${theme.spacing.md}px;

    > div {
      width: 100%;
    }
  }

  .sub-title {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: ${theme.spacing.small}px;
  }

  .help {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${theme.colors.controlBorder};
    margin-bottom: ${theme.spacing.xSmall}px;
    display: flex;
    justify-content: flex-end;
    margin-right: ${theme.spacing.xSmall}px;
  }

  .save-button {
    width: 100%;
    color: ${theme.colors.textPrimary} !important;
  }

  .disabled-btn {
    color: ${theme.colors.neutral} !important;
  }

  .edit-button {
    padding-left: ${theme.spacing.xsm}px;
    padding-right: ${theme.spacing.xsm}px;
    white-space: nowrap;
  }

  .text-danger {
    color: ${theme.baseColors.info[50]};
  }
`

export const StyledInputWrapper = styled(Box)`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-radius: ${theme.attributes.borderRadius.quaternary};
  }

  .MuiFormControl-root {
    width: 100%;

    fieldset {
      border: 0;
    }
  }

  .MuiOutlinedInput-input {
    padding: ${theme.spacing.sm}px ${theme.spacing.small}px !important;
    color: ${theme.colors.white};
    font-weight: ${theme.typography.fontWeight.normal};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.25px;
    border-radius: ${theme.attributes.borderRadius.quaternary};
    border: 1px solid ${theme.colors.controlBorder};
    background: ${theme.colors.backgroundLight};
    box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
    width: 100%;

    &::placeholder {
      opacity: 1;
      color: ${theme.colors.white};
      font-weight: ${theme.typography.fontWeight.normal};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .MuiAutocomplete-input {
      padding: 7.5px 0;
    }
  }

  .MuiTextField-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
`
