import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs, FormControl, InputAdornment, MenuItem as MuiMenuItem, Select } from '@mui/material'
import { getCpiCategoryColors } from '../../../../lib/utils'
import theme from 'theme'

const ITEM_HEIGHT = 42
const ITEM_PADDING_TOP = 8

export const NavigationSelect = styled(Select)<{ isselected?: string; noborder?: string; isCustom?: boolean }>`
  min-width: 113px;
  height: 42px;
  background: transparent;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};
  gap: ${theme.spacing.xSmall}px;

  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: ${theme.colors.white};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: ${theme.baseColors.secondary[40]};
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    path {
      fill: ${({ isCustom }) => (isCustom ? theme.colors.textPrimary : theme.baseColors.greyscale[80])};
    }
  }
`

export const NavigationFormControl = styled(FormControl)<{ isselected?: string; isCustom?: boolean }>`
  width: 200px;
  margin-top: 0;
  margin-right: 28px;
  border-radius: ${({ isCustom }) => (isCustom ? theme.attributes.borderRadius.large : '40px')};
  border: 1px solid ${theme.colors.controlBorder};
  background: transparent;
  outline: none;
  min-width: 113px;
  height: ${({ isCustom }) => (isCustom ? '40px' : '42px')};

  &:hover {
    border: 1px solid ${theme.colors.textPrimary};
    background: rgba(208, 188, 255, 0.16);
  }

  ${({ isCustom }) =>
    isCustom &&
    `
    justify-content: center;
  `};

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
  }

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    border: 1px solid ${theme.colors.textPrimary};
    background: rgba(208, 188, 255, 0.16);
  `};
`

export const MenuItem = styled(MuiMenuItem)`
  height: 40px;
  padding-left: ${theme.spacing.small}px;
  padding-right: ${theme.spacing.small}px;

  img {
    margin-right: ${theme.spacing.xSmall}px;
  }

  &:hover {
    background: rgba(208, 188, 255, 0.16) !important;
  }

  .MuiCheckbox-root {
    padding-right: 19px;
    padding-left: 0;

    svg {
      path {
        fill: ${theme.baseColors.greyscale[80]};
      }
    }
  }

  .MuiListItemText-root .MuiTypography-root {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${theme.baseColors.greyscale[80]};
  }

  display: flex;
  align-items: center;
  width: 100%;

  .image-wrapper {
    display: flex;

    > img {
      width: 18px;
      height: 18px;
      box-shadow: ${theme.attributes.boxShadow.chipCircle};
      margin: -4.5px;
      border-radius: ${theme.attributes.borderRadius.circle};

      &:nth-of-type(1) {
        z-index: 10;
      }

      &:nth-of-type(2) {
        z-index: 9;
      }

      &:nth-of-type(3) {
        z-index: 8;
      }

      &:nth-of-type(4) {
        z-index: 7;
      }

      &:nth-of-type(5) {
        z-index: 6;
      }

      &:nth-of-type(6) {
        z-index: 5;
      }

      &:nth-of-type(7) {
        z-index: 4;
      }
    }
  }

  .text-wrapper {
    margin-left: 13px;
  }
`

export const CategoryMenuProps = {
  sx: {
    zIndex: 1500
  },
  PaperProps: {
    sx: {
      background: theme.colors.primaryLight,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.small,
      width: '480px !important',
      maxHeight: '351px !important',
      marginTop: '3px',
      zIndex: '10000000',
      overflow: 'hidden',

      '.MuiList-root': {
        paddingTop: '0'
      },

      '.MuiMenuItem-root': {
        height: '40px',
        paddingLeft: `${theme.spacing.small}px`,
        paddingRight: `${theme.spacing.small}px`,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          paddingRight: '19px',
          paddingLeft: 0,

          svg: {
            path: {
              fill: theme.baseColors.greyscale[80]
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.bold,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: theme.colors.white,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar-track': {
        background: theme.colors.primaryLight
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#FBD8F5',
        height: '4px',
        width: '4px',
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'center' as const
  },
  variant: 'menu' as const
}

export const DataSourceInputAdornment = styled(InputAdornment)`
  .adornment-wrapper {
    display: flex;
    gap: ${theme.spacing.xSmall}px;

    img {
      width: 18px;
      height: 18px;
      box-shadow: ${theme.attributes.boxShadow.chipCircle};
      border-radius: ${theme.attributes.borderRadius.circle};

      &:nth-of-type(1) {
        z-index: 10;
      }

      &:nth-of-type(2) {
        z-index: 9;
      }

      &:nth-of-type(3) {
        z-index: 8;
      }

      &:nth-of-type(4) {
        z-index: 7;
      }

      &:nth-of-type(5) {
        z-index: 6;
      }

      &:nth-of-type(6) {
        z-index: 5;
      }

      &:nth-of-type(7) {
        z-index: 4;
      }
    }
  }
`

export const LibraryCardWrapper = styled(Box)`
  background: ${theme.colors.backgroundLight};
  border-radius: ${theme.attributes.borderRadius.quaternary};
  padding: ${theme.spacing.small}px;
  margin-bottom: ${theme.spacing.medium}px;
  width: 880px;
  position: relative;
  cursor: pointer;

  &:hover {
    background: #342f32;
  }

  .cpi-new {
    border-radius: 18px;
    background: ${theme.baseColors.info[10]};
    box-shadow: ${theme.attributes.boxShadow.quaternary};
    display: flex;
    padding: 2px ${theme.spacing.xSmall}px;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
    margin-left: ${theme.spacing.xSmall}px;

    > .MuiTypography-root {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .favorite-icon {
    position: absolute;
    top: 5px;
    right: ${theme.spacing.small}px;

    .MuiButtonBase-root {
      &:hover,
      &:focus,
      &:active {
        background: rgba(202, 196, 208, 0.08);
      }
    }
  }

  @media (min-width: 1300px) {
    width: 920px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    margin: ${theme.spacing.lg}px 0 ${theme.spacing.xSmall}px;

    .title {
      color: ${theme.colors.white};
      text-align: start;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxLarge};
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 28px;
    }
  }

  .cpi-name {
    display: inline-block;
    padding: 2px ${theme.spacing.xSmall}px;
    align-items: center;
    gap: 3px;
    border-radius: 18px;
    background: #debdd9;
    box-shadow: ${theme.attributes.boxShadow.quaternary};

    > .MuiTypography-root {
      color: #3f293e;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const LibraryFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme.spacing.medium}px;

  .left-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: ${theme.spacing.small}px;

    .data-sources {
      display: flex;
      align-items: center;
      border-radius: ${theme.attributes.borderRadius.secondary};
      border: 1px solid ${theme.colors.borderLow};
      padding: 7px ${theme.spacing.xSmall}px;

      > .MuiTypography-root {
        color: ${theme.colors.neutral};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.medium};
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 16px;
        letter-spacing: 0.4px;
      }

      .data-sources-wrapper {
        display: flex;
        align-items: center;
      }
    }

    .framework-panel {
      border-radius: ${theme.attributes.borderRadius.secondary};
      border: 1px solid ${theme.colors.borderLow};
      padding: 7px ${theme.spacing.xSmall}px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .cpi-active-button {
      width: fit-content;
      height: 32px;
      box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
      border-radius: ${theme.attributes.borderRadius.secondary};
      padding: ${theme.spacing.xsm}px ${theme.spacing.small}px ${theme.spacing.xsm}px ${theme.spacing.small}px;
      gap: ${theme.spacing.xSmall}px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};
    }

    .cpi-active {
      background: ${theme.baseColors.info[10]};
    }

    .cpi-inactive {
      background: ${theme.colors.borderLow};
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .MuiButtonBase-root {
      margin-left: ${theme.spacing.small}px;
    }
  }
`

export const LibraryFooterCategory = styled(Box)<{ category: string }>`
  display: flex;
  height: 30px;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.secondary};
  box-shadow: ${theme.attributes.boxShadow.quaternary};
  padding: ${theme.spacing.xsm}px ${theme.spacing.small}px;

  > .MuiTypography-root {
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  ${({ category }) => {
    const colors = getCpiCategoryColors(category)
    return `
  background: ${colors.background};
  > .MuiTypography-root {
    color: ${colors.color};
  `
  }};
`

export const LibraryFooterLogo = styled(Box)<{ logo: string }>`
  border: 1px solid #342f32;
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  width: 24px;
  height: 24px;
  border-radius: ${theme.attributes.borderRadius.normal};
  background: ${({ logo }) => `url('${logo}') no-repeat center`};
  background-size: cover;
  margin-left: ${theme.spacing.half}px;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: ${theme.typography.fontFamily.primary};
    text-decoration: none;
    color: ${theme.colors.textPrimary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;

    &.breadcrumb-links {
      color: ${theme.colors.white};
    }

    &.category-breadcrumb {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      display: flex;
      padding: 2px ${theme.spacing.xSmall}px;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      border-radius: ${theme.attributes.borderRadius.secondary};
      background: #b15c0f;
      box-shadow: ${theme.attributes.boxShadow.quaternary};
      text-transform: capitalize;
    }
  }

  .MuiLink-root {
    color: ${theme.colors.white};
  }

  .current-breadcrumb {
    color: ${theme.colors.textPrimary};
  }

  .MuiBreadcrumbs-separator {
    color: ${theme.colors.white};
    margin-left: ${theme.spacing.small}px;
    margin-right: ${theme.spacing.small}px;
  }
`

export const ChipsContainer = styled(Box)`
  display: flex;
  flex-flow: column wrap;

  @media (max-width: 1440px) {
    margin-top: ${theme.spacing.small}px;
  }

  .chips-title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing.xSmall}px;
    white-space: nowrap;
  }

  .MuiChip-root {
    background: transparent;
    border-radius: ${theme.attributes.borderRadius.secondary};
    border: 1px solid ${theme.colors.controlBorder};
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${theme.colors.white};
    text-transform: capitalize;

    &.selected {
      color: ${theme.baseColors.primary[20]};
      border: none;
      background: ${theme.colors.textPrimary};
    }

    &:hover {
      background: ${theme.colors.textPrimary} !important;
      color: ${theme.baseColors.primary[20]} !important;
      opacity: 0.9 !important;
    }
  }
`

export const IntegrationItem = styled(Box)<{ active?: boolean }>`
  position: relative;

  img {
    border: 1px solid ${theme.colors.controlBorder};
    border-radius: ${theme.attributes.borderRadius.circle};
    ${({ active }) =>
      active &&
      `
       border: 1px solid ${theme.baseColors.info[20]};
      `};
  }

  span {
    width: 8px;
    height: 8px;
    border-radius: ${theme.attributes.borderRadius.circle};
    display: block;
    background: ${theme.colors.controlBorder};
    position: absolute;
    right: -3px;
    top: ${theme.spacing.sm}px;
    ${({ active }) =>
      active &&
      `
        background: ${theme.baseColors.info[20]};
    `};
  }

  .circle-new {
    z-index: 10;
    top: ${theme.spacing.xsm}px;
    right: -7px;
  }
`

export const IntegrationTextItem = styled(Box)<{ active: boolean }>`
  .box-wrapper {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xsm}px;
    margin-left: ${theme.spacing.xSmall}px;

    .text {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium} !important;
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-left: 0 !important;
      color: ${theme.colors.controlBorder} !important;
      ${({ active }) =>
        active &&
        `
        color: ${theme.baseColors.info[20]} !important;
      `};
    }

    span {
      width: 8px;
      height: 8px;
      border-radius: ${theme.attributes.borderRadius.circle};
      display: block;
      background: ${theme.colors.controlBorder};
      ${({ active }) =>
        active &&
        `
        background: ${theme.baseColors.info[20]};
      `};
    }
  }
`
