import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const MiscControlWrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  margin-bottom: ${theme.spacing.medium}px;

  .misc-control-title {
    font-family: ${theme.typography.fontFamily.primary};
    letter-spacing: 0.1px;
    white-space: nowrap;
    margin-bottom: ${theme.spacing.half}px;
    display: inline-block;
    color: ${theme.colors.white};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.thin};
    line-height: normal;
  }

  .pointer {
    cursor: pointer;
  }

  .system-wrapper {
    display: flex;
    align-items: center;
  }
`

export const CoverageWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: ${theme.spacing.small}px;
  gap: ${theme.spacing.small}px;
`

export const CoverageItem = styled(Box)<{ isActive?: boolean; disabled?: string }>`
  border-radius: ${theme.attributes.borderRadius.secondary};
  border: ${({ isActive }) =>
    isActive ? `1px solid ${theme.baseColors.primary[20]}` : `1px solid ${theme.colors.controlBorder}`};
  background: ${({ isActive }) => (isActive ? theme.baseColors.primary[20] : 'transparent')};
  padding: ${theme.spacing.small}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${theme.spacing.small}px;
  width: 47%;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      color: ${theme.colors.textPrimary};
    `};

  .name {
    color: ${theme.colors.white};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`
