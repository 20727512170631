import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs, FormControl, MenuItem as MuiMenuItem, Select } from '@mui/material'
import theme from 'theme'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const LibrarySelect = styled(Select)<{ isselected?: string; noborder?: string; iscustom?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  gap: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};
  position: relative;

  ${({ noborder }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: ${theme.colors.white};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: rgba(234, 221, 255, 0.08);
    
    .MuiOutlinedInput-root {
      .MuiSelect-select {
        color: ${theme.colors.textPrimary} !important;
      }
    }    
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    min-width: 122px;
    height: 40px;
  `};

  .MuiOutlinedInput-root {
    ${({ iscustom }) =>
      iscustom &&
      iscustom === 'true' &&
      `
      height: 40px;
  `};
  }

  svg.svg-dropdown {
    top: calc(50% - 0.7em);
    right: ${theme.spacing.xsm}px;
    width: 24px;
    height: 24px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: ${theme.colors.white} !important;
    }
  }
`

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isSort }) =>
    !isSort &&
    `
   gap: ${theme.spacing.xSmall}px;
  `};

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    color: ${theme.colors.white};
    white-space: nowrap;
  }
`

export const LibraryFormControl = styled(FormControl)<{
  noborder?: string
  isselected?: string
  iscustom?: string
  isSort?: boolean
}>`
  outline: none;
  min-width: 121px !important;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.small};
  display: flex;
  position: relative;
  max-width: unset !important;
  width: 100%;

  ${({ noborder }) =>
    noborder !== 'true' &&
    `
    border: 1px solid ${theme.colors.controlBorder};
  `};

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    overflow: hidden;
    color: ${theme.colors.white};
    text-overflow: ellipsis;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;

    .MuiOutlinedInput-input {
      &.Mui-disabled {
        -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
      }

      @media (max-width: 1170px) {
        display: none;
      }
    }
  }

  &:hover {
    border-radius: ${theme.attributes.borderRadius.small};
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: ${theme.colors.textPrimary};
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: ${theme.spacing.small}px;

    path {
      fill: ${theme.colors.textPrimary};
    }
  }

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const MenuItem = styled(MuiMenuItem)`
  height: 40px;
  padding-left: ${theme.spacing.small}px;
  padding-right: ${theme.spacing.small}px;

  img {
    margin-right: ${theme.spacing.xSmall}px;
  }

  &:hover {
    background: rgba(208, 188, 255, 0.16) !important;
  }

  .MuiCheckbox-root {
    padding-right: 19px;
    padding-left: 0;
  }

  .MuiListItemText-root .MuiTypography-root {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${theme.baseColors.greyscale[80]};
  }

  display: flex;
  align-items: center;
  width: 100%;

  .image-wrapper {
    display: flex;

    > img {
      width: 18px;
      height: 18px;
      box-shadow: ${theme.attributes.boxShadow.chipCircle};
      margin: -4.5px;
      border-radius: ${theme.attributes.borderRadius.circle};

      &:nth-of-type(1) {
        z-index: 10;
      }

      &:nth-of-type(2) {
        z-index: 9;
      }

      &:nth-of-type(3) {
        z-index: 8;
      }

      &:nth-of-type(4) {
        z-index: 7;
      }

      &:nth-of-type(5) {
        z-index: 6;
      }

      &:nth-of-type(6) {
        z-index: 5;
      }

      &:nth-of-type(7) {
        z-index: 4;
      }
    }
  }

  .text-wrapper {
    margin-left: 13px;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      minWidth: '323px !important',
      maxHeight: '348px !important',
      overflowY: 'hidden',
      borderRadius: theme.attributes.borderRadius.small,
      border: `1px solid ${theme.colors.borderLow}`,
      background: theme.colors.surfaceItemBackground,
      marginTop: `${theme.spacing.half}px`,

      '.MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,

        '.jira-dropdown-tabs-wrapper': {
          width: '100%',
          padding: `${theme.spacing.xSmall}px`,
          overflowY: 'hidden',

          '.divider': {
            background: theme.colors.borderLow,
            width: '100%',
            height: '1px',
            margin: `${theme.spacing.md}px 0`
          },

          '.list-wrapper': {
            maxHeight: '198px',
            overflowY: 'auto',

            '&::-webkit-scrollbar-track': {
              background: theme.colors.borderLow,
              borderRadius: theme.attributes.borderRadius.mediumSmall
            },

            '&::-webkit-scrollbar-thumb': {
              background: theme.colors.textPrimary,
              height: '4px',
              width: '4px',
              border: '4px solid transparent',
              backgroundClip: 'content-box'
            }
          },

          '.total-results-text': {
            color: theme.colors.textSecondaryLow,
            fontSize: theme.typography.fontSize.small,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeight.thick,
            lineHeight: 'normal',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            padding: `${theme.spacing.xSmall}px 0`
          },

          '.apply-button': {
            width: '100%'
          }
        },

        '.MuiMenuItem-root': {
          height: '48px',
          paddingLeft: `${theme.spacing.small}px`,
          paddingRight: `${theme.spacing.small}px`,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: `${theme.spacing.xSmall}px`,

          img: {
            marginRight: `${theme.spacing.xSmall}px`
          },

          '&:hover': {
            background: 'rgba(208, 188, 255, 0.16) !important'
          },

          '.MuiCheckbox-root': {
            padding: `${theme.spacing.xSmall}px`,

            '&:hover': {
              background: 'rgba(202, 196, 208, 0.12)'
            }
          },

          '.MuiListItemText-root .MuiTypography-root': {
            fontFamily: theme.typography.fontFamily.primary,
            fontWeight: theme.typography.fontWeight.bold,
            fontSize: theme.typography.fontSize.xLarge,
            lineHeight: '24px',
            letterSpacing: '0.1px',
            color: theme.colors.neutral,
            paddingLeft: '19px'
          },

          '.image-wrapper': {
            display: 'flex',

            '> img': {
              width: '18px',
              height: '18px',
              boxShadow: theme.attributes.boxShadow.chipCircle,
              margin: '-4.5px',
              borderRadius: theme.attributes.borderRadius.circle,

              '&:nth-of-type(1)': {
                zIndex: 10
              },
              '&:nth-of-type(2)': {
                zIndex: 9
              },
              '&:nth-of-type(3)': {
                zIndex: 8
              },
              '&:nth-of-type(4)': {
                zIndex: 7
              },
              '&:nth-of-type(5)': {
                zIndex: 6
              },
              '&:nth-of-type(6)': {
                zIndex: 5
              },
              '&:nth-of-type(7)': {
                zIndex: 4
              }
            }
          },

          '.textWrapper': {
            marginLeft: '13px'
          }
        }
      },

      '&::-webkit-scrollbar-track': {
        background: theme.baseColors.surface[50]
      },

      '&::-webkit-scrollbar-thumb': {
        background: `${theme.colors.textPrimary}`,
        height: '4px',
        width: '4px',
        border: '4px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;

    &.category-breadcrumb {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      display: flex;
      padding: 2px ${theme.spacing.xSmall}px;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      border-radius: ${theme.attributes.borderRadius.mediumSmall};
      background: #b15c0f;
      box-shadow: ${theme.attributes.boxShadow.quaternary};
      text-transform: capitalize;
    }
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: ${theme.colors.textPrimary};
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
    margin-left: ${theme.spacing.xSmall}px;
    margin-right: ${theme.spacing.xSmall}px;
  }
`
