import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const AccountWrapper = styled(Box)`
  width: 555px;
  margin: 0 auto ${theme.spacing.large}px;
  border-radius: ${theme.attributes.borderRadius.secondary};
  border: 1px solid ${theme.colors.controlBorder};
  background: ${theme.colors.backgroundDarker};
  padding: ${theme.spacing.medium}px ${theme.spacing.large}px;
`

export const TitleWrapper = styled(Box)`
  margin-bottom: ${theme.spacing.medium}px;

  .title {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 28px;
  }
`

export const Content = styled(Box)`
  .info-block {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.lg}px;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid ${theme.colors.borderLow};

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 9px;
    }
  }

  .left-side {
    width: 40%;
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .right-side {
    width: 60%;
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;

    img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 100%;
    }

    &.capitalize-first {
      text-transform: capitalize;
    }
  }
`

export const FormContent = styled(Box)`
  .MuiTextField-root {
    width: 100%;
  }

  .MuiInputLabel-root {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    letter-spacing: 0.4px;
    color: ${theme.baseColors.greyscale[80]};
    width: 100%;
  }

  .MuiOutlinedInput-root {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xLarge};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.baseColors.greyscale[80]};
    margin-bottom: ${theme.spacing.medium}px;

    &:hover {
      border: transparent;
    }

    fieldset {
      border: 1px solid ${theme.colors.controlBorder};
      border-radius: ${theme.attributes.borderRadius.small};
    }
  }

  .MuiInputAdornment-root {
    svg {
      path {
        fill: #debdd9;
      }
    }
  }

  .text-area {
    margin-bottom: 51px;
  }

  .image-upload-wrapper {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.lg}px;
    margin-bottom: 27px;

    img {
      min-width: 40px;
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 100%;
    }

    .img-upload {
      display: flex;

      .inputfile {
        /* visibility: hidden etc. wont work */
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .inputfile:focus + label {
        /* keyboard navigation */
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
      }

      .inputfile + label * {
        pointer-events: none;
      }

      label {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.xSmall}px;
        cursor: pointer;
        padding: ${theme.spacing.sm}px ${theme.spacing.small}px ${theme.spacing.sm}px ${theme.spacing.md}px;

        .label-text {
          color: ${theme.colors.textPrimary};
          text-align: center;
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
    }
  }
`
