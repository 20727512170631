import { styled } from '@mui/material/styles'
import { AutocompleteGetTagProps } from '@mui/base/useAutocomplete'
import CloseIcon from '@mui/icons-material/Close'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import emotionStyled from '@emotion/styled'
import theme from 'theme'

export const Root = styled('div')(
  ({ theme: muiTheme }) => `
  color: ${muiTheme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
  ${theme.typography.fontSize.large};
`
)

export const InputWrapper = styled('div')(
  () => `
  width: 100%;
  border-radius: ${theme.attributes.borderRadius.small};
  border: 1px solid ${theme.colors.controlBorder};
  background-color: transparent;
  padding: ${theme.spacing.xSmall}px;
  gap: ${theme.spacing.sm}px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.colors.controlBorder};
  }

  &.focused {
    border-color: ${theme.colors.controlBorder};
  }

  & .MuiFormControl-root.MuiTextField-root {
    background-color: transparent;
    box-sizing: border-box;
    padding: 0;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    height: 100%;
    border: 0;    
    overflow: hidden;
    color: ${theme.baseColors.secondary[80]};
    text-overflow: ellipsis;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    
    .MuiOutlinedInput-root {
      padding-left: 0;
      overflow: hidden;
      padding-right: 0;
      
      & input {
        color: ${theme.baseColors.secondary[80]};
        text-overflow: ellipsis;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      
          &::placeholder {
            color: ${theme.baseColors.secondary[80]};
            opacity: 1;
            text-overflow: ellipsis;
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: normal;
          }
        }
      
      & > svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        
        path {
          fill: ${theme.colors.white};
        }
      }
    }
    
    & fieldset {
      border: 0;
      outline: 0;
    }
  }
  
  & input {
    background-color: transparent;
    height: 30px !important;
    box-sizing: border-box;
    padding: ${theme.spacing.xsm}px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    height: 100%;    
    overflow: hidden;
    color: ${theme.baseColors.secondary[80]};
    text-overflow: ellipsis;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`
)

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string
  icon: string
}

const Tag = ({ label, onDelete, icon, ...other }: TagProps) => (
  <div {...other} style={{ height: '28px !important' }}>
    <img src={icon} alt="Data Source Logo" width="24px" height="24px" />
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
)

export const StyledTag = styled(Tag)<TagProps>(
  ({ theme: muiTheme }) => `
  height: 28px !important;
  max-height: 28px !important;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  border-radius: ${theme.attributes.borderRadius.small};
  border: 1px solid ${theme.colors.borderLow};
  background: ${theme.colors.primaryLight};
  gap: ${theme.spacing.xSmall}px;
  padding: 0 ${theme.spacing.xSmall}px;
  display: flex;
  align-items: center;

  &:focus {
    border-color: ${muiTheme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${muiTheme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  img {
    border-radius: ${theme.attributes.borderRadius.small};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.small};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.thick};
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  & svg {
    color: ${theme.colors.white};
    font-size: ${theme.typography.fontSize.medium} !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    cursor: pointer;
    padding: ${theme.spacing.half}px;
  }
`
)

export const Listbox = styled('ul')(
  () => `
  width: 427px;
  background: transparent;
  margin: ${theme.spacing.xSmall}px 0 0;
  padding: ${theme.spacing.xSmall}px;
  list-style: none;
  border-radius: ${theme.attributes.borderRadius.small};
  border: 1px solid ${theme.colors.borderLow};
  z-index: 1;

  & li {
  display: flex;
  height: 40px;
  align-items: center;
  align-self: stretch;
  border-radius: ${theme.attributes.borderRadius.small};
  background: ${theme.colors.surfaceItemBackground};

    & span {
      flex-grow: 1;
      padding: 0 ${theme.spacing.xSmall}px;
      overflow: hidden;
      color: ${theme.colors.white};
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.colors.surfaceItemBackground};

    & svg {
      display: none;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.colors.primaryLight};
    cursor: pointer;

    & svg {
      display: none;
    }
  }
`
)

export const ListWrapper = emotionStyled('div')`
  overflow: auto;
  max-height: 200px;
  padding-right: ${theme.spacing.xSmall}px;
  
  ::-webkit-scrollbar {
    width: 8px !important;
    background: ${theme.colors.borderLow};
  }

  ::-webkit-scrollbar-track {
    width: 6px !important;
    background: ${theme.colors.borderLow} !important;
    borderRadius: ${theme.attributes.borderRadius.quaternary} !important;
  }

  ::-webkit-scrollbar-thumb {
    width: 6px !important;
    background: ${theme.colors.textPrimary} !important;
    borderRadius: ${theme.attributes.borderRadius.quaternary} !important;
  }
`
