import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from 'theme'

export const ResetWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: ${theme.colors.surfaceItemBackground};
  width: 100%;
  padding-bottom: ${theme.spacing.xSmall}px;
`

export const ResetButton = styled(Button)`
  border-radius: ${theme.attributes.borderRadius.large};
  cursor: pointer;
  font-family: ${theme.typography.fontFamily.primary};
  text-transform: none;
  padding: ${theme.spacing.sm}px ${theme.spacing.small}px;
  width: 100%;
  height: 40px;
  background: transparent;
  border: 1px solid ${theme.colors.controlBorder};
  opacity: 1;
  color: ${theme.colors.textPrimary};
  text-align: center;
  font-size: ${theme.typography.fontSize.xLarge};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.thin};
  line-height: normal;

  &:hover {
    background: rgba(234, 221, 255, 0.08);
  }

  &.Mui-disabled {
    color: ${theme.baseColors.greyscale[50]};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-weight: ${theme.typography.fontWeight.thin};
    opacity: 1;
    border: 1px solid transparent;
    background: ${theme.baseColors.surface[50]};
  }
`
