import { Dispatch, FC, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import theme from 'theme'

/* Utils */
import { ManifestIntegration, IntegrationInstance } from '../../../../../../models'
import { IntegrationsAddIcon, IntegrationsCloseIcon, IntegrationsEditIcon } from '../../../../../components/svg'

/* Components */
import { IntegrationCardWrapper, WarningMessageContainer } from './integrationCard.styles'
import BetaIntegrationMarker from '../BetaIntegrationMarker'

interface Props {
  detailsOpen: boolean
  withInstance?: boolean
  instance?: IntegrationInstance
  integration: ManifestIntegration
  handleOpenDetails: () => void
  setTabValue: Dispatch<SetStateAction<number>>
  setSelectedInstance: Dispatch<SetStateAction<IntegrationInstance | null>>
  hasFailed?: boolean
  expanded: string
}

const IntegrationCardContent: FC<Props> = ({
  integration,
  withInstance,
  instance,
  detailsOpen,
  handleOpenDetails,
  setTabValue,
  setSelectedInstance,
  hasFailed,
  expanded
}) => {
  const navigate = useNavigate()

  const handleAdd = () => navigate(`/settings/integrations/${integration?.name}/add`)

  const handleEdit = () => {
    handleOpenDetails()
    setTabValue(1)
    setSelectedInstance(instance ?? null)
  }

  const handleClose = () => {
    handleOpenDetails()
    setSelectedInstance(null)
  }

  return (
    <IntegrationCardWrapper expanded={expanded}>
      <Box className="description">
        {integration.name === 'atlassian_jira' ? (
          <Box className="description-jira-wrapper">
            <Typography className="header">{instance ? instance.name : integration.display_name}</Typography>
            <BetaIntegrationMarker expanded={!!expanded} />
          </Box>
        ) : (
          <Typography className="header">{instance ? instance.name : integration.display_name}</Typography>
        )}

        <Typography className="subheader">{integration.description}</Typography>
        {hasFailed && (
          <WarningMessageContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M7.99967 1.33331C4.31967 1.33331 1.33301 4.31998 1.33301 7.99998C1.33301 11.68 4.31967 14.6666 7.99967 14.6666C11.6797 14.6666 14.6663 11.68 14.6663 7.99998C14.6663 4.31998 11.6797 1.33331 7.99967 1.33331ZM8.66634 11.3333H7.33301V9.99998H8.66634V11.3333ZM8.66634 8.66665H7.33301V4.66665H8.66634V8.66665Z"
                fill={theme.baseColors.info[50]}
              />
            </svg>
          </WarningMessageContainer>
        )}
      </Box>
      <Box className="actions">
        {withInstance && (
          <Button className="instance-button" disabled={integration.instances < 1}>
            {integration.instances} Instance
            {integration.instances !== 1 && 's'} <ExpandMoreOutlined />
          </Button>
        )}
        {withInstance ? (
          <IconButton className="icon-button" onClick={handleAdd}>
            <IntegrationsAddIcon />
          </IconButton>
        ) : (
          <>
            {detailsOpen ? (
              <IconButton className="icon-button-close" onClick={handleClose}>
                <IntegrationsCloseIcon />
              </IconButton>
            ) : (
              <IconButton className="icon-button" onClick={handleEdit}>
                <IntegrationsEditIcon />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </IntegrationCardWrapper>
  )
}

export default IntegrationCardContent
