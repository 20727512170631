import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)<{ backgroundColor: string; height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.small}px;
  position: relative;
  border: 1px solid ${theme.colors.borderLow};
  min-height: 140px;
  height: ${({ height }) => `${height}px`};
  ${({ backgroundColor }) => `
    background-color: ${backgroundColor};
  `};

  .MuiTypography-root {
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: normal;
  }

  .edit-icon-button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 0 0 ${theme.attributes.borderRadius.secondary};
    background: ${theme.colors.textPrimary};
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    padding: 0;
    opacity: 0;
  }

  &:hover {
    .edit-icon-button {
      opacity: 1;
    }
  }

  &:hover {
    border-color: ${theme.colors.controlBorder};

    &:not(.with-items) {
      background: ${theme.baseColors.surface[50]};
    }
  }
`
