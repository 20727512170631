import { Box, DialogActions, DialogContent } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)``

export const Header = styled(Box)`
  padding: 8px 16px 16px;
  border-bottom: 1px solid #342f32;

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: 36px;
    line-height: 44px;
    color: ${theme.colors.neutral};
  }
`

export const Content = styled(DialogContent)`
  box-shadow: ${theme.attributes.boxShadow.chipCircle};
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.backgroundMedium};
  padding: ${theme.spacing.medium}px 24px 16px 24px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 32px;
    color: ${theme.colors.neutral};
    margin-bottom: ${theme.spacing.md}px;
  }

  .text {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const Actions = styled(DialogActions)`
  margin-top: ${theme.spacing.medium}px;
`
