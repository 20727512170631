import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  background: ${theme.colors.primaryDarker};

  .sub-header-text {
    margin-top: ${theme.spacing.small}px;
    margin-left: ${theme.spacing.medium}px;
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.header};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`

export const Header = styled(Box)`
  height: 100px;
  padding: ${theme.spacing.medium}px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .header-title {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 36px;
    width: fit-content;
  }

  .header-actions {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;

    .divider {
      width: 1px;
      height: 56px;
      background: ${theme.colors.borderLow};
    }

    .edit-icon {
      margin-left: -${theme.spacing.xSmall}px;
      color: ${theme.colors.textPrimary};

      &:hover {
        background: ${theme.colors.surfaceItemBackgroundLow};
        cursor: pointer;
      }

      &[aria-expanded='true'] {
        background: ${theme.colors.surfaceItemBackgroundLow};
        cursor: pointer;
      }
    }
  }
`

export const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 ${theme.spacing.medium}px;
  margin-top: -100px;
`

export const Content = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: ${theme.spacing.medium}px;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`

export const Divider = styled(Box)`
  width: 1px;
  height: 272px;
  background: ${theme.colors.borderLow};

  @media (max-width: 1280px) {
    height: 1px;
    width: 400px;
  }
`
