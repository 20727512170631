import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.medium}px;
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.surfaceItemBackground};
  width: 420px;
  padding: ${theme.spacing.small}px;
  border: 1px solid ${theme.colors.borderLow};

  .close-popper-icon {
    padding: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    border: none;
    height: auto;

    svg {
      path {
        fill: ${theme.colors.white};
      }
    }

    .header-text {
      color: ${theme.colors.white};
      white-space: nowrap;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.header};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;
    }
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.small}px;

    .form-control {
      gap: 2px;
      width: 100%;

      .MuiOutlinedInput-root {
        height: 40px;
        overflow: hidden;
        color: ${theme.colors.white};
        text-overflow: ellipsis;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
        border-radius: ${theme.attributes.borderRadius.small};
        border: 1px solid ${theme.colors.controlBorder};
        padding-right: 0;

        & .MuiOutlinedInput-input {
          padding: 0 ${theme.spacing.small}px;
          overflow: hidden;
          color: ${theme.colors.white};
          text-overflow: ellipsis;
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;

          &::placeholder {
            overflow: hidden;
            color: ${theme.baseColors.secondary[80]};
            text-overflow: ellipsis;
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: normal;
            opacity: 1;
          }
        }

        & .end-adornment-close-icon {
          color: ${theme.colors.white};
          padding: ${theme.spacing.half}px;
          margin-right: ${theme.spacing.half}px;

          &:hover {
            background: rgba(255, 255, 255, 0.05);
          }
        }

        &.Mui-focused > fieldset {
          border-color: ${theme.colors.controlBorder};
          border-width: 1px;
        }

        &:hover {
          border-color: ${theme.colors.controlBorder};
        }
      }

      .MuiTypography-root {
        overflow: hidden;
        color: ${theme.colors.white};
        text-overflow: ellipsis;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;

        &.Mui-focused {
          color: ${theme.colors.white} !important;
        }
      }
    }
  }

  .divider-actions {
    background: ${theme.colors.borderLow};
    height: 1px;
    width: 100%;
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${theme.spacing.small}px;
  }
`
