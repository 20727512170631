import theme from 'theme'

const FilterSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M5.50281 4.80039H12.5028L8.99581 9.21039L5.50281 4.80039ZM3.57781 4.52739C4.99181 6.34039 7.60281 9.70039 7.60281 9.70039V13.9004C7.60281 14.2854 7.91781 14.6004 8.30281 14.6004H9.70281C10.0878 14.6004 10.4028 14.2854 10.4028 13.9004V9.70039C10.4028 9.70039 13.0068 6.34039 14.4208 4.52739C14.7778 4.06539 14.4488 3.40039 13.8678 3.40039H4.13081C3.54981 3.40039 3.22081 4.06539 3.57781 4.52739Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)
export default FilterSvg
