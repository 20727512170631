import { useCallback, useEffect, useState } from 'react'

/* Utils */
import axios from '../../../lib/axios'
import {
  AcaComparisonPayload,
  AcaCoverageFilter,
  AcaLastSeen,
  AcaOperatingSystem,
  AnalysisData,
  AnalyzerData,
  CPITimePeriod,
  IntegrationInstance,
  LastSeenWithin,
  ManifestIntegration,
  OperatingSystem
} from '../../../models'
import { downloadFile, getDownloadCsvProps } from './utils'

export interface Pagination {
  current_page: number
  next_page: number | null
  prev_page: number | null
  total_pages: number
  total_records: number
}

const useCoverageAnalyzerData = () => {
  const [firstSource, setFirstSource] = useState('')
  const [secondSource, setSecondSource] = useState('')
  const [selectedOperatingSystem, setSelectedOperatingSystem] = useState<OperatingSystem>(OperatingSystem.All)
  const [selectedOsTrends, setSelectedOsTrends] = useState<OperatingSystem>(OperatingSystem.All)
  const [selectedSeenWithin, setSelectedSeenWithin] = useState<LastSeenWithin>(LastSeenWithin.All)
  const [activeSeenWithin, setActiveSeenWithin] = useState<LastSeenWithin>(LastSeenWithin.All)
  const [lastActiveSeenWithin, setLastActiveSeenWithin] = useState<LastSeenWithin>(LastSeenWithin.All)
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<CPITimePeriod>(CPITimePeriod.ThreeMonths)
  const [chartData, setChartData] = useState<AnalyzerData | null>(null)
  const [chartLoading, setChartLoading] = useState(false)
  const [optionsLoading, setOptionsLoading] = useState(true)
  const [loadingAnalysisData, setLoadingAnalysisData] = useState(true)
  const [instances, setInstances] = useState<IntegrationInstance[]>([])
  const [supportedIntegrations, setSupportedIntegrations] = useState<ManifestIntegration[]>([])
  const [allAnalysisData, setAllAnalysisData] = useState<AnalysisData[]>([])
  const [analysisData, setAnalysisData] = useState<AnalysisData[]>([])
  const [selectedInstances, setSelectedInstances] = useState<IntegrationInstance[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 1,
    next_page: null,
    prev_page: null,
    total_pages: 1,
    total_records: 0
  })
  const [tableFilters, setTableFilters] = useState([false, false, false, false])
  const [firstInstanceAssets, setFirstInstanceAssets] = useState(0)
  const [secondInstanceAssets, setSecondInstanceAssets] = useState(0)
  const [numOfActiveAssets, setNumOfActiveAssets] = useState(0)
  const [sourceChanged, setSourceChanged] = useState(true)
  const [initialLoad, setInitialLoad] = useState(false)
  const [exporting, setExporting] = useState(false)

  const handleChangeSource = (source: 1 | 2, value: string) => {
    switch (source) {
      case 1:
        setFirstSource(value)
        break
      case 2:
        setSecondSource(value)
        break
    }

    let sourceChanged = true

    if (selectedInstances.length > 1) {
      const first = source === 1 ? value : firstSource
      const second = source === 2 ? value : secondSource
      if (first === selectedInstances[0].id && second === selectedInstances[1].id) {
        sourceChanged = false
      }
    }

    if (sourceChanged) {
      setSourceChanged(sourceChanged)
    } else {
      setSourceChanged(selectedSeenWithin !== lastActiveSeenWithin)
    }
  }

  const fetchInstances = async () => {
    const supportedRes = await axios.get('/api/v3/integrations/supported')

    const acaSupportedIntegrationNames: string[] = []
    if (supportedRes.status === 200) {
      const integrations: ManifestIntegration[] = supportedRes.data.integrations.filter(
        (n: ManifestIntegration) => n.is_supports_aca
      )
      integrations.forEach((integration) => acaSupportedIntegrationNames.push(integration.name))
      setSupportedIntegrations(integrations)
    }

    const res = await axios.get('/api/v3/integrations/configured')

    if (res.status === 200) {
      let instances: IntegrationInstance[] = res.data.instances
      if (acaSupportedIntegrationNames.length > 0) {
        instances = instances.filter((n) => acaSupportedIntegrationNames.includes(n.integration_name))
      }
      setInstances(instances)
    }
  }

  const fetchAll = async () => {
    setOptionsLoading(true)

    try {
      await fetchInstances()
    } catch (e) {
      console.error(e)
    } finally {
      setOptionsLoading(false)
    }
  }

  const fetchData = async ({
    timePeriod = selectedTimePeriod,
    seenWithin = selectedSeenWithin,
    operatingSystem = selectedOperatingSystem
  }: {
    timePeriod?: CPITimePeriod
    seenWithin?: LastSeenWithin
    operatingSystem?: OperatingSystem
  }) => {
    setChartLoading(true)

    const integrationIds = []
    if (firstSource) {
      integrationIds.push(firstSource)
    }
    if (secondSource) {
      integrationIds.push(secondSource)
    }

    const payload = {
      time_period: timePeriod.toLowerCase() === 'past year' ? '1y' : timePeriod.toLowerCase(),
      operating_system: operatingSystem.toLowerCase(),
      integration_ids: integrationIds,
      last_seen: seenWithin.toLowerCase()
    }

    try {
      const res = await axios.post('/api/v3/aca/data', payload)

      if (res.status.toString().includes('20') && res.data) {
        setChartData(res.data.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setChartLoading(false)
    }
  }

  const fetchTotalAssets = async ({
    integrationIds,
    lastSeenWithin
  }: {
    integrationIds: string[]
    lastSeenWithin: AcaLastSeen
  }) => {
    const payload = {
      integration_ids: integrationIds,
      last_seen: lastSeenWithin
    }
    const res = await axios.post('/api/v3/aca/total_comparable_assets', payload)

    if (res.status.toString().includes('20')) {
      const { data } = res.data
      const firstId = integrationIds[0].slice(0, 8)
      const secondId = integrationIds[1].slice(0, 8)
      for (const [key, value] of Object.entries(data)) {
        if (key.includes(firstId)) {
          setFirstInstanceAssets(value as number)
        }
        if (key.includes(secondId)) {
          setSecondInstanceAssets(value as number)
        }
        if (key === 'total_active') {
          setNumOfActiveAssets(value as number)
        }
      }
    }
  }

  const fetchAnalysisData = useCallback(
    async ({
      integrationIds,
      operatingSystem = selectedOperatingSystem,
      lastSeenWithin = activeSeenWithin,
      searchTerm = '',
      filters = tableFilters,
      sortBy = 'last_seen',
      direction = 'ASC'
    }: {
      integrationIds: string[]
      operatingSystem?: OperatingSystem
      lastSeenWithin?: LastSeenWithin
      searchTerm?: string
      filters?: boolean[]
      sortBy?: string
      direction?: string
    }) => {
      setLoadingAnalysisData(true)

      const instance1 = instances.find((n) => n.id === integrationIds[0])
      const instance2 = instances.find((n) => n.id === integrationIds[1])

      if (instance1 && instance2) {
        setSelectedInstances([instance1, instance2])
      }

      const payload: AcaComparisonPayload = {
        operating_system: operatingSystem.toLowerCase() as AcaOperatingSystem,
        integration_ids: integrationIds,
        last_seen: lastSeenWithin.toLowerCase() as AcaLastSeen,
        page_number: 1,
        sort_by: sortBy,
        sort_direction: direction,
        search_term: searchTerm
      }

      const coverageFilterIndex = filters.findIndex((c) => c)

      if (coverageFilterIndex > -1) {
        payload.coverage =
          coverageFilterIndex === 0
            ? AcaCoverageFilter.FirstNotSecond
            : coverageFilterIndex === 1
            ? AcaCoverageFilter.SecondNotFirst
            : coverageFilterIndex === 2
            ? AcaCoverageFilter.Intersection
            : AcaCoverageFilter.SymmetricDifference
      } else {
        delete payload.coverage
      }

      try {
        try {
          await fetchTotalAssets({ integrationIds, lastSeenWithin: lastSeenWithin.toLowerCase() as AcaLastSeen })
        } catch (err) {
          console.error(err)
          setLoadingAnalysisData(false)
        }

        const res = await axios.post('/api/v3/aca/comparison', payload)

        if (res.status.toString().includes('20')) {
          const { data, pagination } = res.data

          setAnalysisData(data)
          setAllAnalysisData(data)
          setPagination(pagination)
          setSourceChanged(true)
        }
      } catch (e) {
        console.error(e)
      } finally {
        // setLoadingAnalysisData(false)
        setInitialLoad(true)
        setSourceChanged(false)
      }
    },
    [selectedOperatingSystem, instances, tableFilters, selectedSeenWithin]
  )

  const handleExport = useCallback(
    async ({
      integrationIds = [selectedInstances[0].id, selectedInstances[1].id],
      operatingSystem = selectedOperatingSystem,
      lastSeenWithin = activeSeenWithin,
      searchTerm = '',
      filters = tableFilters,
      sortBy = 'last_seen',
      direction = 'ASC'
    }: {
      integrationIds?: string[]
      operatingSystem?: OperatingSystem
      lastSeenWithin?: LastSeenWithin
      searchTerm?: string
      filters?: boolean[]
      sortBy?: string
      direction?: string
    }) => {
      setExporting(true)

      const payload: AcaComparisonPayload = {
        operating_system: operatingSystem.toLowerCase() as AcaOperatingSystem,
        integration_ids: integrationIds,
        last_seen: lastSeenWithin.toLowerCase() as AcaLastSeen,
        page_number: 1,
        sort_by: sortBy,
        sort_direction: direction,
        search_term: searchTerm
      }

      const coverageFilterIndex = filters.findIndex((c) => c)

      if (coverageFilterIndex > -1) {
        payload.coverage =
          coverageFilterIndex === 0
            ? AcaCoverageFilter.FirstNotSecond
            : coverageFilterIndex === 1
            ? AcaCoverageFilter.SecondNotFirst
            : coverageFilterIndex === 2
            ? AcaCoverageFilter.Intersection
            : AcaCoverageFilter.SymmetricDifference
      } else {
        delete payload.coverage
      }

      try {
        const res = await axios.post('/api/v3/aca/comparison/csv', payload)

        if (res.status.toString().includes('20')) {
          const fileName = getDownloadCsvProps(selectedInstances)

          downloadFile(res.data, fileName)
        }
      } catch (err) {
        console.error(err)
      } finally {
        setExporting(false)
      }
    },
    [selectedOperatingSystem, selectedInstances, tableFilters, selectedSeenWithin]
  )

  const filterAnalysisData = async (filters: boolean[], os: OperatingSystem) => {
    if (!allAnalysisData || !firstSource || !secondSource) {
      setLoadingAnalysisData(false)
      return []
    }

    setLoadingAnalysisData(true)

    const coverageFilterIndex = filters.findIndex((c) => c)

    const payload: AcaComparisonPayload = {
      operating_system: selectedOperatingSystem.toLowerCase() as AcaOperatingSystem,
      integration_ids: [firstSource, secondSource],
      last_seen: selectedSeenWithin.toLowerCase() as AcaLastSeen,
      page_number: 1,
      sort_by: 'last_seen'
    }
    if (coverageFilterIndex > -1) {
      payload.coverage =
        coverageFilterIndex === 0
          ? AcaCoverageFilter.FirstNotSecond
          : coverageFilterIndex === 1
          ? AcaCoverageFilter.SecondNotFirst
          : coverageFilterIndex === 2
          ? AcaCoverageFilter.Intersection
          : AcaCoverageFilter.SymmetricDifference
    }
    if (os) {
      payload.operating_system = os.toLowerCase() as AcaOperatingSystem
    }

    try {
      const res = await axios.post('/api/v3/aca/comparison', payload)

      if (res.status.toString().includes('20')) {
        setAnalysisData(res.data.data)
        setAllAnalysisData(res.data.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingAnalysisData(false)
    }
  }

  useEffect(() => {
    fetchAll().catch((e) => e)
  }, [])

  return {
    fetchTotalAssets,
    exporting,
    setActiveSeenWithin,
    handleExport,
    activeSeenWithin,
    selectedOsTrends,
    setSelectedOsTrends,
    selectedInstances,
    initialLoad,
    sourceChanged,
    pagination,
    setSourceChanged,
    setPagination,
    tableFilters,
    setTableFilters,
    firstSource,
    secondSource,
    setFirstSource,
    setSecondSource,
    handleChangeSource,
    selectedOperatingSystem,
    setSelectedOperatingSystem,
    selectedSeenWithin,
    setSelectedSeenWithin,
    selectedTimePeriod,
    setSelectedTimePeriod,
    fetchData,
    chartData,
    setChartData,
    chartLoading,
    setChartLoading,
    instances,
    supportedIntegrations,
    optionsLoading,
    analysisData,
    fetchAnalysisData,
    filterAnalysisData,
    loadingAnalysisData,
    setLoadingAnalysisData,
    firstInstanceAssets,
    secondInstanceAssets,
    numOfActiveAssets,
    lastActiveSeenWithin,
    setLastActiveSeenWithin
  }
}

export default useCoverageAnalyzerData
