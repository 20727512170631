import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)<{ type?: string; noBg?: boolean; borderbottom?: string }>`
  border-bottom: ${({ noBg, borderbottom }) =>
    borderbottom && borderbottom === 'true'
      ? `1px solid ${theme.colors.borderLow}`
      : noBg
      ? 'none'
      : `1px solid ${theme.colors.borderLow}`};
  background: transparent;
  height: 100px;
  padding-left: ${theme.spacing.medium}px;
  padding-right: ${theme.spacing.medium}px;
  position: static;
  top: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;

  ${({ type }) =>
    type === 'dashboard' &&
    `
    left: 72px
  `};
`
export const HeaderBreadcrumbsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;

  .link-block {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
  }

  .text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxLarge};
    font-weight: ${theme.typography.fontWeight.bold};
    color: ${theme.colors.white};
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
  }

  .link {
    color: ${theme.baseColors.greyscale[60]};
    text-decoration: none;

    &:hover {
      color: ${theme.colors.white};
    }
  }
`
