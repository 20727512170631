import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useAuth } from '@frontegg/react'

/* Utils */
import axios from '../../../../../lib/axios'
import { useAppDispatch } from '../../../../../store/hooks'
import { AllCPIsReportType, hasAnyRole, Role } from '../../../../../lib/utils'
import { closeToast, openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'
import { openInNewTab } from '../../reports.utils'
// import { useFramework } from '../../../../../hooks/useFramework'

/* Components */
import { Wrapper } from './createReportTab.styles'
import ReportCard from './components/ReportCard'

const CreateReportTab: FC = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const [generatingFullReport, setGeneratingFullReport] = useState(false)
  const [generatingSSMReport, setGeneratingSSMReport] = useState(false)
  const [generatingExeReport, setGeneratingExeReport] = useState(false)
  const closeNotification = useRef<any>(null)
  const [fullReportCode, setFullReportCode] = useState('')
  const [ssmReportCode, setSsmReportCode] = useState('')
  const [exeReportUrl, setExeReportUrl] = useState('')
  // const { framework, customFramework } = useFramework()

  const fullReportQuery = useQuery(
    ['fullReportCode', { fullReportCode }],
    () => axios.get(`/api/v1/reports/download_url/${fullReportCode}`),
    {
      refetchInterval: 5000,
      enabled: !!fullReportCode
    }
  )

  const ssmReportQuery = useQuery(
    ['ssmReportCode', { ssmReportCode }],
    () => axios.get(`/api/v1/reports/download_url/${ssmReportCode}`),
    {
      refetchInterval: 5000,
      enabled: !!ssmReportCode
    }
  )

  useEffect(() => {
    if (fullReportQuery.error && (fullReportQuery.error as any).response?.status === 418) {
      setGeneratingFullReport(false)
      setFullReportCode('')
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: (fullReportQuery.error as any).response?.data.error || 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }, [fullReportQuery.error])

  useEffect(() => {
    if (fullReportQuery.data && !fullReportQuery.error && !fullReportQuery.isFetching) {
      const downloadUrl = fullReportQuery.data.data.reportDownloadUrl.url
      openInNewTab(`${downloadUrl}`)
      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: `Full Report PDF Successfully Downloaded`,
            description: ''
          }
        })
      )

      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setFullReportCode('')
    }
  }, [fullReportQuery.data, fullReportQuery.error, fullReportQuery.isFetching])

  useEffect(() => {
    if (ssmReportQuery.error && (ssmReportQuery.error as any).response?.status === 418) {
      setGeneratingSSMReport(false)
      setSsmReportCode('')
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: (ssmReportQuery.error as any).response?.data.error || 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }, [ssmReportQuery.error])

  useEffect(() => {
    if (ssmReportQuery.data && !ssmReportQuery.error && !ssmReportQuery.isFetching) {
      const downloadUrl = ssmReportQuery.data.data.reportDownloadUrl.url
      openInNewTab(`${downloadUrl}`)
      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: `SSM Report PDF Successfully Downloaded`,
            description: ''
          }
        })
      )
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setSsmReportCode('')
    }
  }, [ssmReportQuery.data, ssmReportQuery.error, ssmReportQuery.isFetching])

  useEffect(() => {
    if (exeReportUrl) {
      openInNewTab(exeReportUrl)
      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: `Executive Presentation Successfully Downloaded`,
            description: ''
          }
        })
      )

      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setExeReportUrl('')
    }
  }, [exeReportUrl])

  const generateFullReport = async () => {
    setGeneratingFullReport(true)

    try {
      const payload = {
        type: AllCPIsReportType.GENERAL
      }

      const res = await axios.post(`/api/v1/reports/all_cpis`, payload)

      if (res.status.toString().startsWith('2')) {
        const { code } = res.data
        setFullReportCode(code)
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
    } finally {
      setGeneratingFullReport(false)
    }
  }

  const generateSSMReport = async () => {
    setGeneratingSSMReport(true)

    try {
      const res = await axios.post(`/api/v1/reports/ssm`)

      if (res.status.toString().startsWith('2')) {
        const { code } = res.data
        setSsmReportCode(code)
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
    } finally {
      setGeneratingSSMReport(false)
    }
  }

  const generateExecutiveReport = async () => {
    setGeneratingExeReport(true)

    try {
      const res = await axios.post(`/api/v1/reports/executive-report`)

      if (res.status.toString().startsWith('2')) {
        const { downloadURL } = res.data
        setExeReportUrl(downloadURL)
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
    } finally {
      setGeneratingExeReport(false)
    }
  }

  const getDisabled = () => {
    const allowedRoles = [Role.Owner, Role.Admin, Role.Demo]

    const hasRole = hasAnyRole(allowedRoles, user)

    return !hasRole
  }

  const items = [
    {
      title: 'Generate a Full Report',
      text: 'Generate a comprehensive collection of data trends across active CPIs.',
      backgroundUrl: '/reports-management/generate-report-icon.png',
      buttonText: 'Download PDF',
      onClick: () => {
        generateFullReport().catch((e) => e)
      },
      comingSoon: false,
      disabled: getDisabled(),
      loading: generatingFullReport || fullReportQuery.isFetching
    },
    {
      title: 'Security Stack Report',
      text: 'Detailed summary of your security stack coverage and budget allocation.',
      backgroundUrl: '/reports-management/generate-ssm-icon.png',
      buttonText: 'Download PDF',
      onClick: () => {
        generateSSMReport().catch((e) => e)
      },
      comingSoon: false,
      isNew: false,
      disabled: getDisabled(),
      loading: generatingSSMReport || ssmReportQuery.isFetching
    },
    {
      title: 'Create a CPI Report',
      text: 'Customize a focused report of an active CPI with your choice of data filters.',
      backgroundUrl: '/reports-management/create-cpi-report-icon.png',
      buttonText: 'Customize Report',
      onClick: () => {
        navigate('/reports/customize-cpi-report')
      },
      comingSoon: false,
      disabled: getDisabled(),
      loading: false
    },
    {
      title: 'Create a Quarterly Report',
      text: 'Customize a presentation-ready report to share with your board.',
      backgroundUrl: '/reports-management/create-quarterly-report-icon.png',
      buttonText: 'Customize Report',
      onClick: () => {
        navigate('/reports/customize-quarterly-report')
      },
      disabled: getDisabled(),
      comingSoon: false,
      isNew: false,
      loading: false
    },
    {
      title: 'Create a Monthly Report',
      text: 'Customize a time based report to track progress month over month.',
      backgroundUrl: '/reports-management/create-monthly-report-icon.png',
      buttonText: 'Customize Report',
      onClick: () => {
        navigate('/reports/customize-monthly-report')
      },
      disabled: getDisabled(),
      comingSoon: false,
      isNew: false,
      loading: false
    },
    {
      title: 'Executive Presentation',
      text: 'Customizable PPT presentation for board reporting containing key highlights from Onyxia Platform',
      backgroundUrl: '/reports-management/create-executive-report.png',
      buttonText: 'Executive Report',
      onClick: () => {
        generateExecutiveReport().catch((e) => e)
      },
      disabled: getDisabled(),
      comingSoon: false,
      isNew: true,
      loading: generatingExeReport
    }
  ]

  return (
    <Wrapper>
      {items.map((item, index) => (
        <ReportCard key={index} {...item} />
      ))}
    </Wrapper>
  )
}

export default CreateReportTab
