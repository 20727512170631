import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import * as d3 from 'd3'
import dayjs from 'dayjs'
import theme from 'theme'

/* Utils */
import { CPIFrequency, D3Position, Insight, InsightData } from 'models'
import {
  formatPerformanceUnit,
  formatTooltipDateInsights,
  getChartWidth,
  getRangeFromToFormatted,
  getSlaGroupProps
} from '../../../library/utils'
import { getYLine } from '../../../library/library-details/sections/trends-tab/chart-utils'
import { getTextWidth } from '../../../library/library-details/sections/trends-tab/chart-components-shared'
import { dateFormat } from '../../../../../lib/utils'

/* Components */
import InsightsChart from './InsightsChart'
import { ChartWrapper, Container, Header } from './insightsTrendsData.styles'
import { GreenZoneIcon, PredictionLineIcon, RedZoneIcon, SLAIcon, TrendLineIcon } from './icons'
import { appendDefs, raiseElements } from '../../../library/library-details/sections/trends-tab/chart-components'

const chartHeightStart = 0
const defaultAreas = [
  {
    color: 'green',
    range: '[0,$]'
  },
  {
    color: 'red',
    range: '($,+inf)'
  }
]

interface Props {
  insight: Insight
}

const InsightsTrendsData: FC<Props> = ({ insight }) => {
  const isSmall = useMediaQuery('(max-width:1200px)')
  const isLaptop = useMediaQuery('(max-width:1280px)')
  const isLargeScreen = useMediaQuery('(max-width:1570px)')
  const isExtraLargeScreen = useMediaQuery('(max-width:1770px)')

  const containerRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<SVGSVGElement | null>(null)

  const margin = { top: 20, right: 0, bottom: 40, left: 0 }
  const [width, setWidth] = useState(getChartWidth(isSmall, isLaptop, isLargeScreen, isExtraLargeScreen))
  const [height, setHeight] = useState(360 - margin.top - margin.bottom)

  const buildTrendsChart = useCallback(
    (data: InsightData, maxValueY: number) => {
      const svg = d3.select(svgRef.current)
      const tooltip = d3.select('.data-point-tooltip')
      const everything = svg.selectAll('*')
      everything.remove()

      const chartData = {
        sla: data.sla,
        values: data.trend_data
      } as any
      const healthUnit = data.cpi_ui_trend_chart_unit

      const nonPercentRange: [number, number] = [0, maxValueY * 2]
      const getYAxisDomain = (): [number, number] => {
        if (healthUnit === 'percent') {
          return [0, 100]
        }
        if (maxValueY === 0) {
          return [0, 30]
        }
        return nonPercentRange
      }
      const yAxisDomain = getYAxisDomain()

      const uniqueMonths: string[] = chartData.values.map((n: any) => n.start_date.slice(0, 10))

      const stripeWidth = width / uniqueMonths.length

      let pathStr = ``
      const positions: D3Position[] = []
      const { values } = chartData
      const lastValue = chartData.values[chartData.values.length - 1].start_date
      const lastValueDay = dayjs(lastValue).get('date')
      const daysInLastValue = dayjs(lastValue).daysInMonth()
      const lastStripeDivider = daysInLastValue / lastValueDay

      for (let i = 0; i < uniqueMonths.length; i++) {
        const stripeWidthStart = stripeWidth * i
        const stripeWidthEnd = stripeWidth * (i + 1)

        if (!values.length) {
          positions.push({
            x: 0,
            y: 0,
            value: 0
          })
          pathStr += `M0 ${height}`
          continue
        }

        const stripeMiddle = stripeWidth / 2 + stripeWidth * i

        const xScaleEach = d3
          .scaleBand()
          // .domain(values.map((d: any) => d.start_date))
          .domain(values.map((d: any) => dayjs(d.start_date).add(3, 'days').format('YYYY-MM-DD')))
          .range([
            stripeWidthStart,
            i === uniqueMonths.length - 1 && lastValueDay < daysInLastValue
              ? stripeWidthEnd - stripeWidth + stripeWidth / lastStripeDivider
              : stripeWidthEnd
          ])
        const yScaleEach = d3
          .scaleLinear()
          .domain(yAxisDomain)
          .nice()
          .range([height, chartHeightStart ?? 0])

        const startString = i === 0 ? 'M' : 'L'
        const d =
          typeof values[i].start_date !== 'string'
            ? dayjs(values[i].start_date).format('YYYY-MM-DD')
            : values[i].start_date.slice(0, 10) || dayjs(values[i].start_date).format('YYYY-MM-DD')
        const position = {
          x: (xScaleEach(values[i].start_date) || 0) + stripeMiddle,
          y: yScaleEach(values[i].value),
          value: values[i].value,
          monthIndex: i,
          date: dayjs(d).format('YYYY-MM-DD')
        }
        positions.push({
          ...position,
          x: Number((Math.round(position.x * 100) / 100).toFixed(2))
        })
        pathStr += `${startString}${position.x} ${position.y}`
      }

      const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([0, width]).padding(0)
      const yScale = d3.scaleLinear().domain(yAxisDomain).range([height, chartHeightStart]).nice()
      const d = {
        values: chartData.values,
        sla: data.sla
      } as any
      const yLine = getYLine(d, maxValueY)
      const healthBorder = Math.abs(yScale(yLine))

      appendDefs(svg)

      /* Data Points */
      const dataPoints = svg.selectAll('.hover-circle-inner')
      if (!dataPoints.empty()) dataPoints.remove()
      positions.forEach((position: any, i: number) => {
        svg
          .append('circle')
          .attr('class', 'hover-circle-inner')
          .attr('cx', position.x)
          .attr('cy', position.y)
          .attr('r', 4)
          .attr('fill', theme.colors.white)
          .attr('stroke-width', 0)
          .attr('stroke-opacity', 0)

        if (i === positions.length - 1) {
          svg
            .append('circle')
            .attr('class', 'hover-circle-inner')
            .attr('cx', position.x)
            .attr('cy', position.y)
            .attr('r', 4)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
        }
      })

      /*
       * Cartesian Grid Horizontal Line
       * */
      svg
        .selectAll('.cartesian-grid-horizontal')
        .data([0, 1, 2, 3, 4])
        .enter()
        .append('line')
        .attr('class', 'cartesian-grid-horizontal')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', (d) => (d === 0 ? chartHeightStart : d * (height / 4)))
        .attr('y2', (d) => (d === 0 ? chartHeightStart : d * (height / 4)))
        .attr('stroke', theme.colors.borderLow)
        .attr('stroke-width', '1px')

      /* Recent Performance Line */
      const existingRecentPerformanceLine = svg.select('.sla-line')
      if (!existingRecentPerformanceLine.empty()) existingRecentPerformanceLine.remove()
      svg
        .append('line')
        .attr('class', 'sla-line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', healthBorder < 2 ? healthBorder : healthBorder - 0.5)
        .attr('y2', healthBorder < 2 ? healthBorder : healthBorder - 0.5)
        .attr('stroke', theme.colors.white)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '6 6')

      const areas = data.cpi_ui_trend_chart_areas || defaultAreas
      const t1 = areas[0]
      let colorBottom = 'green'
      if (t1.range.includes('0')) {
        colorBottom = t1.color
      } else {
        colorBottom = 'red'
      }
      const colorGreen = '#27AE60'
      const colorRed = '#E74C3C'

      /*
       *  Filled Area
       * */
      const barBgRed = svg.select('.bar-bg-red')
      if (!barBgRed.empty()) barBgRed.remove()
      svg
        .selectAll('.bar-bg-red')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', colorBottom === 'green' ? colorRed : colorGreen)
        .attr('fill-opacity', '1')
        .attr('class', colorBottom === 'green' ? 'bar-bg-red' : 'bar-bg-green')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', healthBorder)

      const barBgGreen = svg.select('.bar-bg-green')
      if (!barBgGreen.empty()) barBgGreen.remove()
      svg
        .selectAll('.bar-bg-green')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', colorBottom === 'green' ? colorGreen : colorRed)
        .attr('fill-opacity', '1')
        .attr('class', colorBottom === 'green' ? 'bar-bg-green' : 'bar-bg-red')
        .attr('x', 0)
        .attr('y', healthBorder)
        .attr('width', width)
        .attr('height', height)

      /*
       *  Filled Area Stroke
       * */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const existingFilledAreaStroke = svg.selectAll('.filled-area-stroke')
      if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', theme.colors.white)
        .attr('d', `${pathStr}`)
        .attr('class', 'filled-area-stroke')
        .attr('stroke-width', 1)
        .attr('stroke-linejoin', 'bevel')

      /* Stripes */
      svg
        .selectAll('.bar')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', 'transparent')
        .attr('fill-opacity', '1')
        .attr('class', 'bar')
        .attr('x', (d) => xScaleGroup(d) || 0)
        .attr('y', 0)
        .attr('width', xScaleGroup.bandwidth())
        .attr('height', height + margin.top + margin.bottom)
        .attr('stroke', theme.colors.borderLow)
        .attr('stroke-width', 1)

      /* SLA */
      const existingSlaGroup = svg.select('g.sla')
      if (!existingSlaGroup.empty()) existingSlaGroup.remove()
      const slaGroup = svg.append('g').attr('class', 'sla')
      const text = `${data.sla}${formatPerformanceUnit(healthUnit, true)}`
      const textWidth = getTextWidth(text, '590 10px Quicksand')
      const slaRectWidth = textWidth + 16
      const slaRectHeight = 23
      const slaMarginLeft = 31
      const { slaYPosition } = getSlaGroupProps(height, healthBorder, [], slaRectWidth)

      if (healthBorder >= 29) {
        if (height - slaYPosition >= 36) {
          /* SLA Below 10 */
          slaGroup
            .append('rect')
            .attr('class', 'sla-tooltip-wrapper')
            .attr('x', slaMarginLeft + 8)
            .attr('y', healthBorder - slaRectHeight + 3)
            .attr('width', slaRectWidth)
            .attr('height', slaRectHeight)
            .attr('fill', theme.colors.white)
            .attr('opacity', 1)
            .attr('rx', 5)
            .attr('clip-path', 'inset(0px 0px 3px 0px)')
          slaGroup
            .append('text')
            .attr('class', 'sla-text')
            .attr('x', slaMarginLeft + 8 + (slaRectWidth - 18) / 2)
            .attr('y', healthBorder - slaRectHeight + 13)
            .attr('dy', '0.36em')
            .style('fill', theme.baseColors.greyscale[10])
            .style('font-size', theme.typography.fontSize.small)
            .style('font-family', theme.typography.fontFamily.primary)
            .style('font-weight', theme.typography.fontWeight.bold)
            .style('line-height', '20px')
            .style('letter-spacing', '1px')
            .style('text-transform', 'uppercase')
            .text(text)
        } else {
          /* Remaining SLA */
          slaGroup
            .append('rect')
            .attr('class', 'sla-tooltip-wrapper')
            .attr('x', slaMarginLeft + 8)
            .attr('y', slaYPosition - slaRectHeight / 2 - 8)
            .attr('width', slaRectWidth)
            .attr('height', slaRectHeight)
            .attr('fill', theme.colors.white)
            .attr('opacity', 1)
            .attr('rx', 5)
            .attr('clip-path', 'inset(0px 0px 3px 0px)')
          slaGroup
            .append('text')
            .attr('class', 'sla-text')
            .attr('x', slaMarginLeft + 8 + (slaRectWidth - 18) / 2)
            .attr('y', slaYPosition - 9)
            .attr('dy', '0.36em')
            .style('fill', theme.baseColors.greyscale[10])
            .style('font-size', theme.typography.fontSize.small)
            .style('font-family', theme.typography.fontFamily.primary)
            .style('font-weight', theme.typography.fontWeight.bold)
            .style('line-height', '20px')
            .style('letter-spacing', '1px')
            .style('text-transform', 'uppercase')
            .text(text)
        }
      } else {
        /* SLA Above 90 */
        slaGroup
          .append('rect')
          .attr('class', 'sla-tooltip-wrapper')
          .attr('x', slaMarginLeft + 8)
          .attr('y', healthBorder - 4)
          .attr('width', slaRectWidth)
          .attr('height', slaRectHeight)
          .attr('fill', theme.colors.white)
          .attr('opacity', 1)
          .attr('rx', 5)
          .attr('clip-path', 'inset(3px 0px 0px 0px)')
        slaGroup
          .append('text')
          .attr('class', 'sla-text')
          .attr('x', slaMarginLeft + 8 + (slaRectWidth - 18) / 2)
          .attr('y', healthBorder + 8)
          .attr('dy', '0.36em')
          .style('fill', theme.baseColors.greyscale[10])
          .style('font-size', theme.typography.fontSize.small)
          .style('font-family', theme.typography.fontFamily.primary)
          .style('font-weight', theme.typography.fontWeight.bold)
          .style('line-height', '20px')
          .style('letter-spacing', '1px')
          .style('text-transform', 'uppercase')
          .text(text)
      }

      /* X-Axis Container Background */
      svg
        .append('rect')
        .attr('fill', theme.colors.surfaceItemBackgroundLow)
        .attr('width', width)
        .attr('height', 60)
        .attr('y', height)
        .attr('class', 'x-axis-container')

      /*
       * X-Axis
       * */
      const xAxis = d3.axisBottom(xScaleGroup).tickFormat((date) => {
        const d = chartData.values.find(
          (n: any) => n.start_date.slice(0, 10) === dayjs(date).format(dateFormat.customLabel)
        )
        const dataStartsAt = chartData.values[0].start_date

        if (d) {
          const { rangeFromFormatted, rangeToFormatted } = getRangeFromToFormatted(
            CPIFrequency.Daily,
            dataStartsAt,
            (d as any).start_date,
            (d as any).end_date,
            false
          )
          const today = dayjs().format('YYYY-MM-DD')
          if (dayjs((d as any).end_date) >= dayjs(today)) return rangeFromFormatted

          if (!rangeToFormatted) return rangeFromFormatted
          return `${rangeFromFormatted}-${rangeToFormatted}`
        }

        return dayjs(date).format('MMM DD')
      })

      const existingXAxis = svg.selectAll('.x-axis')
      if (!existingXAxis.empty()) existingXAxis.remove()
      const xAxisGroup = svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('color', theme.colors.white)
        .attr('transform', `translate(0, ${height + 19})`)
        .call(xAxis)

      const windowWidth = window.innerWidth
      xAxisGroup
        .selectAll('text')
        .attr('color', theme.colors.white)
        .attr('font-size', windowWidth < 1280 ? '12px' : windowWidth < 1450 ? '14px' : '16px')
        .attr('font-weight', theme.typography.fontWeight.normal)
        .attr('line-height', 'normal')
        .attr('font-family', theme.typography.fontFamily.primary)

      xAxisGroup.selectAll('path').attr('display', 'none')
      const xAxisTick = svg.selectAll('.tick')
      xAxisTick.selectAll('line').attr('display', 'none')

      /*
       *  Y-Axis
       * */
      const yAxis = d3
        .axisRight(yScale)
        .ticks(4)
        .tickFormat((d, i) => {
          const unit = formatPerformanceUnit(healthUnit, true, true)

          return i === 0 ? '' : `${d}${unit}`
        })

      const existingYAxis = svg.selectAll('.y-axis')
      if (!existingYAxis.empty()) existingYAxis.remove()
      const yAxisGroup = svg.append('g').attr('class', 'y-axis').attr('color', 'none').call(yAxis)
      yAxisGroup
        .selectAll('text')
        .attr('dy', '18px')
        .attr('color', theme.colors.white)
        .attr('font-size', theme.typography.fontSize.small)
        .attr('font-weight', '590')
        .attr('letter-spacing', '1px')
        .attr('text-transform', 'uppercase')
        .attr('line-height', 'normal')
        .attr('font-family', theme.typography.fontFamily.primary)
      yAxisGroup.selectAll('path').attr('display', 'none')
      yAxisGroup.selectAll('line').attr('display', 'none')

      /* Last Data Point Tooltip */
      const unit = formatPerformanceUnit(healthUnit, true)
      const lastDataPointRectHeight = 54
      const nonPredictions = positions.filter((c: any) => !c.prediction)
      const lastDataPoint = nonPredictions[nonPredictions.length - 1]
      let tooltipTitle = dayjs(lastDataPoint.date).format('MMM DD, YYYY')
      if (data && data.trend_data) {
        const lastDataValue = data.trend_data[data.trend_data.length - 1]
        tooltipTitle = formatTooltipDateInsights(lastDataValue.start_date, lastDataValue.end_date)
      }
      const dataPointTooltipTextWidth = getTextWidth(tooltipTitle, '590 14px Quicksand')
      const lastDataPointRectWidth = dataPointTooltipTextWidth + 16
      const tooltipDescription = `${lastDataPoint.value}${unit}`

      const left = lastDataPoint.x - lastDataPointRectWidth / 2
      const maxLeft = width - lastDataPointRectWidth
      const thisLeft = left > maxLeft ? maxLeft : left
      const diff = maxLeft - left
      const nextLeft = diff < 0 ? thisLeft - diff : thisLeft

      tooltip.style('width', lastDataPointRectWidth)
      tooltip.style('height', lastDataPointRectHeight)
      tooltip.style('left', `${nextLeft - 1}px`)
      tooltip.style('top', `calc(${lastDataPoint.y}px - 70px)`)
      tooltip.html(`
        <div class="tooltip-wrapper">
          <p class="tooltip-title">${tooltipTitle}</p>
          <p class="tooltip-text">${tooltipDescription}</p>
        </div>
      `)
      tooltip.style('display', 'block')

      raiseElements(svg)
      svg.selectAll('g.tick').raise()
      svg.selectAll('.tick').raise()
      svg.selectAll('.y-axis').raise()
      svg.selectAll('.sla-tooltip-wrapper').raise()
      svg.selectAll('.sla-text').raise()
      svg.selectAll('.sla-line').raise()
    },
    [insight, margin, width, height]
  )

  const buildPredictionChart = useCallback(
    (data: InsightData, maxValueY: number) => {
      const svg = d3.select(svgRef.current)
      const tooltip = d3.select('.data-point-tooltip')
      const everything = svg.selectAll('*')
      everything.remove()

      const chartData = {
        sla: data.sla,
        values: data.trend_data
      } as any
      const healthUnit = data.cpi_ui_trend_chart_unit

      const getYAxisDomain = (): [number, number] => {
        const nonPercentRange: [number, number] = [0, maxValueY * 2]

        if (healthUnit === 'percent') {
          return [0, 100]
        }
        if (maxValueY === 0) {
          return [0, 30]
        }
        return nonPercentRange
      }
      const yAxisDomain = getYAxisDomain()

      const uniqueMonths: string[] = chartData.values.map((n: any) => n.start_date.slice(0, 10))

      const stripeWidth = width / uniqueMonths.length

      let pathStr = ``
      const positions: D3Position[] = []
      const { values } = chartData
      const lastValue = values[values.length - 1].start_date
      const lastValueDay = dayjs(lastValue).get('date')
      const daysInLastValue = dayjs(lastValue).daysInMonth()
      const lastStripeDivider = daysInLastValue / lastValueDay

      for (let i = 0; i < uniqueMonths.length; i++) {
        const stripeWidthStart = stripeWidth * i
        const stripeWidthEnd = stripeWidth * (i + 1)
        const test = []
        const arr = Array.from(Array(7).keys())
        const startDate = values[i].start_date
        const theseValues = values[i].values
        const dates = theseValues.map((c: any) => c.date)
        if (i === 0 && theseValues.length < 7) {
          for (let idx = 0; idx < arr.length; idx++) {
            const key = arr[idx]
            if (key === 0) {
              const d = values[i].start_date
              test.push({ date: d, value: 0, includes: dates.includes(d) })
            } else if (key === 6) {
              const d = theseValues[theseValues.length - 1].date
              test.push({
                date: d,
                value: theseValues[theseValues.length - 1].value,
                includes: dates.includes(d)
              })
            } else {
              const nextDate = dayjs(startDate).add(idx, 'days').format('YYYY-MM-DD')
              const exists = theseValues.find((c: any) => c.date === nextDate)
              test.push({
                date: nextDate,
                value: exists ? exists.value : 0,
                includes: dates.includes(nextDate)
              })
            }
          }
        }
        const thisValues = values[i].values
        if (!values.length) {
          positions.push({
            x: 0,
            y: 0,
            value: 0
          })
          pathStr += `M0 ${height}`
          continue
        }

        let stripeStart = stripeWidthStart
        if (i === 0) {
          const includedCount = test.filter((c: any) => c.includes)

          stripeStart = stripeWidth / includedCount.length
        }

        const xScaleEach = d3
          .scaleBand()
          .domain(thisValues.map((d: any) => d.date))
          .range([
            stripeStart,
            i === uniqueMonths.length - 1 && lastValueDay < daysInLastValue
              ? stripeWidthEnd - stripeWidth + stripeWidth / lastStripeDivider
              : stripeWidthEnd
          ])
        const yScaleEach = d3
          .scaleLinear()
          .domain(yAxisDomain)
          .nice()
          .range([height, chartHeightStart ?? 0])

        thisValues.forEach((each: any, index: number) => {
          // const startString = (i === 0 && index === 0)  || (i === 1 && index === 0) ? 'M' : 'L'
          const startString = i === 0 && index === 0 ? 'M' : 'L'
          const date = dayjs(each.date).format('YYYY-MM-DD')

          // if (i === 0 && each.value === 0) return
          const position = {
            // x: (xScaleEach(each.date) || 0) + stripeMiddle,
            x: xScaleEach(each.date) || 0,
            y: yScaleEach(each.value),
            value: each.value,
            monthIndex: i,
            date,
            // prediction: Boolean(values[i].prediction)
            prediction: each.prediction
          }
          positions.push({
            ...position,
            x: Number((Math.round(position.x * 100) / 100).toFixed(2))
          })
          pathStr += `${startString}${position.x} ${position.y}`
        })
      }

      const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([0, width]).padding(0)
      const yScale = d3.scaleLinear().domain(yAxisDomain).range([height, chartHeightStart]).nice()
      const d = {
        values: chartData.values,
        sla: data.sla
      } as any
      const yLine = getYLine(d, maxValueY)
      const healthBorder = yScale(yLine)

      appendDefs(svg)

      /* Data Points */
      const dataPoints = svg.selectAll('.hover-circle-inner')
      if (!dataPoints.empty()) dataPoints.remove()
      const allPredictions = positions.filter((c) => !(c as any).prediction)
      positions.forEach((position: any, i: number) => {
        if (!position.prediction) {
          svg
            .append('circle')
            .attr('class', 'hover-circle-inner')
            .attr('cx', position.x)
            .attr('cy', position.y)
            .attr('r', 4)
            .attr('fill', theme.colors.white)
            .attr('stroke-width', 0)
            .attr('stroke-opacity', 0)

          if (i === allPredictions.length - 1) {
            svg
              .append('circle')
              .attr('class', 'hover-circle-inner')
              .attr('cx', position.x)
              .attr('cy', position.y)
              .attr('r', 4)
              .attr('fill', 'none')
              .attr('stroke', 'black')
              .attr('stroke-width', 1)
          }
        }
      })

      /*
       * Cartesian Grid Horizontal Line
       * */
      svg
        .selectAll('.cartesian-grid-horizontal')
        .data([0, 1, 2, 3, 4])
        .enter()
        .append('line')
        .attr('class', 'cartesian-grid-horizontal')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', (d) => (d === 0 ? chartHeightStart : d * (height / 4)))
        .attr('y2', (d) => (d === 0 ? chartHeightStart : d * (height / 4)))
        .attr('stroke', theme.colors.borderLow)
        .attr('stroke-width', '1px')

      /* Recent Performance Line */
      const existingRecentPerformanceLine = svg.select('.sla-line')
      if (!existingRecentPerformanceLine.empty()) existingRecentPerformanceLine.remove()
      svg
        .append('line')
        .attr('class', 'sla-line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', healthBorder < 2 ? healthBorder : healthBorder - 0.5)
        .attr('y2', healthBorder < 2 ? healthBorder : healthBorder - 0.5)
        .attr('stroke', theme.colors.white)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '6 6')

      const areas = data.cpi_ui_trend_chart_areas || defaultAreas
      const t1 = areas[0]
      let colorBottom = 'green'
      if (t1.range.includes('0')) {
        colorBottom = t1.color
      } else {
        colorBottom = 'red'
      }
      const colorGreen = '#27AE60'
      const colorRed = '#E74C3C'

      /*
       *  Filled Area
       * */
      const barBgRed = svg.select('.bar-bg-red')
      if (!barBgRed.empty()) barBgRed.remove()
      svg
        .selectAll('.bar-bg-red')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', colorBottom === 'green' ? colorRed : colorGreen)
        .attr('fill-opacity', '1')
        .attr('class', colorBottom === 'green' ? 'bar-bg-red' : 'bar-bg-green')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', healthBorder)

      const barBgGreen = svg.select('.bar-bg-green')
      if (!barBgGreen.empty()) barBgGreen.remove()
      svg
        .selectAll('.bar-bg-green')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', colorBottom === 'green' ? colorGreen : colorRed)
        .attr('fill-opacity', '1')
        .attr('class', colorBottom === 'green' ? 'bar-bg-green' : 'bar-bg-red')
        .attr('x', 0)
        .attr('y', healthBorder)
        .attr('width', width)
        .attr('height', height)

      const test = pathStr.split(' ')
      const test2 = test.slice(0, 8)
      const test3 = test2.join(' ')
      const test4 = test.slice(9)
      const nextFirstPoint = test2[test2.length - 2]
      const nextPoint1 = nextFirstPoint.split('L')
      const nextPoint2 = nextPoint1[1]
      const nextPointLast = test2[test2.length - 1]
      const nextPointLast1 = nextPointLast.split('L')
      const nextPointLast2 = nextPointLast1[0]
      const nextTest4 = test4[0]
      const nextTest5 = nextTest4.split('L')
      const nextTest6 = nextTest5[1]
      const test5 = `M${nextPoint2} ${nextPointLast2}${test4.map((c, i) => (i === 0 ? `L${nextTest6}` : c)).join(' ')}`

      /*
       *  Filled Area Stroke
       * */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const existingFilledAreaStroke = svg.selectAll('.filled-area-stroke')
      if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', theme.colors.white)
        .attr('d', `${test3}`)
        .attr('class', 'filled-area-stroke')
        .attr('stroke-width', 1)
        .attr('stroke-linejoin', 'bevel')

      const existingFilledAreaStroke2 = svg.selectAll('.filled-area-stroke-2')
      if (!existingFilledAreaStroke2.empty()) existingFilledAreaStroke2.remove()
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', theme.baseColors.info[30])
        .attr('d', `${test5}`)
        .attr('class', 'filled-area-stroke-2')
        .attr('stroke-width', 1)
        .attr('stroke-linejoin', 'bevel')

      /* Stripes */
      svg
        .selectAll('.bar')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', 'transparent')
        .attr('fill-opacity', '1')
        .attr('class', 'bar')
        .attr('x', (d) => {
          return xScaleGroup(d) || 0
        })
        .attr('y', 0)
        .attr('width', xScaleGroup.bandwidth())
        .attr('height', height + margin.top + margin.bottom)
        .attr('stroke', theme.colors.borderLow)
        .attr('stroke-width', 1)

      /* SLA */
      const existingSlaGroup = svg.select('g.sla')
      if (!existingSlaGroup.empty()) existingSlaGroup.remove()
      const slaGroup = svg.append('g').attr('class', 'sla')
      const text = `${data.sla}${formatPerformanceUnit(healthUnit, true)}`
      const textWidth = getTextWidth(text, '590 10px Quicksand')
      const slaRectWidth = textWidth + 16
      const slaRectHeight = 23
      const slaMarginLeft = 31
      const { slaYPosition } = getSlaGroupProps(height, healthBorder, [], slaRectWidth)

      if (healthBorder >= 29) {
        if (height - slaYPosition >= 36) {
          /* SLA Below 10 */
          slaGroup
            .append('rect')
            .attr('class', 'sla-tooltip-wrapper')
            .attr('x', slaMarginLeft + 8)
            .attr('y', healthBorder - slaRectHeight + 3)
            .attr('width', slaRectWidth)
            .attr('height', slaRectHeight)
            .attr('fill', theme.colors.white)
            .attr('opacity', 1)
            .attr('rx', 5)
            .attr('clip-path', 'inset(0px 0px 3px 0px)')
          slaGroup
            .append('text')
            .attr('class', 'sla-text')
            .attr('x', slaMarginLeft + 8 + (slaRectWidth - 18) / 2)
            .attr('y', healthBorder - slaRectHeight + 13)
            .attr('dy', '0.36em')
            .style('fill', theme.baseColors.greyscale[10])
            .style('font-size', theme.typography.fontSize.small)
            .style('font-family', theme.typography.fontFamily.primary)
            .style('font-weight', theme.typography.fontWeight.bold)
            .style('line-height', '20px')
            .style('letter-spacing', '1px')
            .style('text-transform', 'uppercase')
            .text(text)
        } else {
          /* Remaining SLA */
          slaGroup
            .append('rect')
            .attr('class', 'sla-tooltip-wrapper')
            .attr('x', slaMarginLeft + 8)
            .attr('y', slaYPosition - slaRectHeight / 2 - 8)
            .attr('width', slaRectWidth)
            .attr('height', slaRectHeight)
            .attr('fill', theme.colors.white)
            .attr('opacity', 1)
            .attr('rx', 5)
            .attr('clip-path', 'inset(0px 0px 3px 0px)')
          slaGroup
            .append('text')
            .attr('class', 'sla-text')
            .attr('x', slaMarginLeft + 8 + (slaRectWidth - 18) / 2)
            .attr('y', slaYPosition - 9)
            .attr('dy', '0.36em')
            .style('fill', theme.baseColors.greyscale[10])
            .style('font-size', theme.typography.fontSize.small)
            .style('font-family', theme.typography.fontFamily.primary)
            .style('font-weight', theme.typography.fontWeight.bold)
            .style('line-height', '20px')
            .style('letter-spacing', '1px')
            .style('text-transform', 'uppercase')
            .text(text)
        }
      } else {
        /* SLA Above 90 */
        slaGroup
          .append('rect')
          .attr('class', 'sla-tooltip-wrapper')
          .attr('x', slaMarginLeft + 8)
          .attr('y', healthBorder - 4)
          .attr('width', slaRectWidth)
          .attr('height', slaRectHeight)
          .attr('fill', theme.colors.white)
          .attr('opacity', 1)
          .attr('rx', 5)
          .attr('clip-path', 'inset(3px 0px 0px 0px)')
        slaGroup
          .append('text')
          .attr('class', 'sla-text')
          .attr('x', slaMarginLeft + 8 + (slaRectWidth - 18) / 2)
          .attr('y', healthBorder + 8)
          .attr('dy', '0.36em')
          .style('fill', theme.baseColors.greyscale[10])
          .style('font-size', theme.typography.fontSize.small)
          .style('font-family', theme.typography.fontFamily.primary)
          .style('font-weight', theme.typography.fontWeight.bold)
          .style('line-height', '20px')
          .style('letter-spacing', '1px')
          .style('text-transform', 'uppercase')
          .text(text)
      }

      /* Last Data Point Tooltip */
      const unit = formatPerformanceUnit(healthUnit, true)
      const lastDataPointRectHeight = 54
      const nonPredictions = positions.filter((c: any) => !c.prediction)
      const lastDataPoint = nonPredictions[nonPredictions.length - 1]
      const tooltipTitle = dayjs(lastDataPoint.date).format('MMM DD, YYYY')
      const dataPointTooltipTextWidth = getTextWidth(tooltipTitle, '590 14px Quicksand')
      const lastDataPointRectWidth = dataPointTooltipTextWidth + 16
      const tooltipDescription = `${lastDataPoint.value}${unit}`

      const left = lastDataPoint.x - lastDataPointRectWidth / 2
      const maxLeft = width - lastDataPointRectWidth
      const thisLeft = left > maxLeft ? maxLeft : left
      const diff = maxLeft - left
      const nextLeft = diff < 0 ? thisLeft - diff : thisLeft

      tooltip.style('width', lastDataPointRectWidth)
      tooltip.style('height', lastDataPointRectHeight)
      tooltip.style('left', `${nextLeft - 1}px`)
      tooltip.style('top', `calc(${lastDataPoint.y}px - 70px)`)
      tooltip.html(`
        <div class="tooltip-wrapper">
          <p class="tooltip-title">${tooltipTitle}</p>
          <p class="tooltip-text">${tooltipDescription}</p>
        </div>
      `)
      tooltip.style('display', 'block')

      /* X-Axis Container Background */
      svg
        .append('rect')
        .attr('fill', theme.colors.surfaceItemBackgroundLow)
        .attr('width', width)
        .attr('height', 60)
        .attr('y', height)
        .attr('class', 'x-axis-container')

      /*
       * X-Axis
       * */
      const xAxis = d3.axisBottom(xScaleGroup).tickFormat((date) => {
        const d = chartData.values.find(
          (n: any) => n.start_date.slice(0, 10) === dayjs(date).format(dateFormat.customLabel)
        )
        const dataStartsAt = chartData.values[0].start_date

        if (d) {
          const { rangeFromFormatted, rangeToFormatted } = getRangeFromToFormatted(
            CPIFrequency.Daily,
            dataStartsAt,
            (d as any).start_date,
            (d as any).end_date,
            false
          )
          const today = dayjs().format('YYYY-MM-DD')
          if (dayjs((d as any).end_date) >= dayjs(today)) return rangeFromFormatted

          if (!rangeToFormatted) return rangeFromFormatted
          return `${rangeFromFormatted}-${rangeToFormatted}`
        }

        return dayjs(date).format('MMM DD')
      })

      const existingXAxis = svg.selectAll('.x-axis')
      if (!existingXAxis.empty()) existingXAxis.remove()
      const xAxisGroup = svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('color', theme.colors.white)
        .attr('transform', `translate(0, ${height + 19})`)
        .call(xAxis)

      const windowWidth = window.innerWidth
      xAxisGroup
        .selectAll('text')
        .attr('color', theme.colors.white)
        .attr('font-size', windowWidth < 1280 ? '12px' : windowWidth < 1450 ? '14px' : '16px')
        .attr('font-weight', theme.typography.fontWeight.normal)
        .attr('line-height', 'normal')
        .attr('font-family', theme.typography.fontFamily.primary)

      xAxisGroup.selectAll('path').attr('display', 'none')
      const xAxisTick = svg.selectAll('.tick')
      xAxisTick.selectAll('line').attr('display', 'none')

      /*
       *  Y-Axis
       * */
      const yAxis = d3
        .axisRight(yScale)
        .ticks(4)
        .tickFormat((d, i) => {
          const unit = formatPerformanceUnit(healthUnit, true, true)

          return i === 0 ? '' : `${d}${unit}`
        })

      const existingYAxis = svg.selectAll('.y-axis')
      if (!existingYAxis.empty()) existingYAxis.remove()
      const yAxisGroup = svg.append('g').attr('class', 'y-axis').attr('color', 'none').call(yAxis)
      yAxisGroup
        .selectAll('text')
        .attr('dy', '18px')
        .attr('color', theme.colors.white)
        .attr('font-size', theme.typography.fontSize.small)
        .attr('font-weight', '590')
        .attr('letter-spacing', '1px')
        .attr('text-transform', 'uppercase')
        .attr('line-height', 'normal')
        .attr('font-family', theme.typography.fontFamily.primary)
      yAxisGroup.selectAll('path').attr('display', 'none')
      yAxisGroup.selectAll('line').attr('display', 'none')

      raiseElements(svg)
      svg.selectAll('g.tick').raise()
      svg.selectAll('.tick').raise()
      svg.selectAll('.y-axis').raise()
      svg.selectAll('.sla-tooltip-wrapper').raise()
      svg.selectAll('.sla-text').raise()
      svg.selectAll('.sla-line').raise()
    },
    [insight, margin, width, height]
  )

  const buildTrendsChartOC = useCallback(
    (data: InsightData, maxValueY: number) => {
      const svg = d3.select(svgRef.current)
      const tooltip = d3.select('.data-point-tooltip')
      const everything = svg.selectAll('*')
      everything.remove()

      const chartData = {
        sla: data.sla,
        values: data.trend_data
      } as any
      const healthUnit = data.cpi_ui_trend_chart_unit
      const max = Math.max(...chartData.values.map((n: any) => Math.abs(n.value)))
      const yDomain = max === 0 ? [-100, 100] : [-max, max]

      const uniqueMonths: string[] = chartData.values.map((n: any) => n.start_date.slice(0, 10))

      const stripeWidth = width / uniqueMonths.length

      let pathStr = ``
      const positions: D3Position[] = []
      const { values } = chartData
      const lastValue = chartData.values[chartData.values.length - 1].start_date
      const lastValueDay = dayjs(lastValue).get('date')
      const daysInLastValue = dayjs(lastValue).daysInMonth()
      const lastStripeDivider = daysInLastValue / lastValueDay

      for (let i = 0; i < uniqueMonths.length; i++) {
        const stripeWidthStart = stripeWidth * i
        const stripeWidthEnd = stripeWidth * (i + 1)

        if (!values.length) {
          positions.push({
            x: 0,
            y: 0,
            value: 0
          })
          pathStr += `M0 ${height}`
          continue
        }

        const stripeMiddle = stripeWidth / 2 + stripeWidth * i

        const xScaleEach = d3
          .scaleBand()
          // .domain(values.map((d: any) => d.start_date))
          .domain(values.map((d: any) => dayjs(d.start_date).add(3, 'days').format('YYYY-MM-DD')))
          .range([
            stripeWidthStart,
            i === uniqueMonths.length - 1 && lastValueDay < daysInLastValue
              ? stripeWidthEnd - stripeWidth + stripeWidth / lastStripeDivider
              : stripeWidthEnd
          ])
        const yScaleEach = d3
          .scaleLinear()
          .domain(yDomain)
          .nice()
          .range([height, chartHeightStart ?? 0])

        const startString = i === 0 ? 'M' : 'L'
        const d =
          typeof values[i].start_date !== 'string'
            ? dayjs(values[i].start_date).format('YYYY-MM-DD')
            : values[i].start_date.slice(0, 10) || dayjs(values[i].start_date).format('YYYY-MM-DD')
        const position = {
          x: (xScaleEach(values[i].start_date) || 0) + stripeMiddle,
          y: yScaleEach(values[i].value),
          value: values[i].value,
          monthIndex: i,
          date: dayjs(d).format('YYYY-MM-DD')
        }
        positions.push({
          ...position,
          x: Number((Math.round(position.x * 100) / 100).toFixed(2))
        })
        pathStr += `${startString}${position.x} ${position.y}`
      }

      const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([0, width]).padding(0)
      const yScale = d3.scaleLinear().domain(yDomain).range([height, chartHeightStart]).nice()
      const d = {
        values: chartData.values,
        sla: data.sla
      } as any
      const yLine = getYLine(d, maxValueY)
      const healthBorder = yScale(yLine)

      appendDefs(svg)

      /* Data Points */
      const dataPoints = svg.selectAll('.hover-circle-inner')
      if (!dataPoints.empty()) dataPoints.remove()
      positions.forEach((position: any, i: number) => {
        svg
          .append('circle')
          .attr('class', 'hover-circle-inner')
          .attr('cx', position.x)
          .attr('cy', position.y)
          .attr('r', 4)
          .attr('fill', theme.colors.white)
          .attr('stroke-width', 0)
          .attr('stroke-opacity', 0)

        if (i === positions.length - 1) {
          svg
            .append('circle')
            .attr('class', 'hover-circle-inner')
            .attr('cx', position.x)
            .attr('cy', position.y)
            .attr('r', 4)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
        }
      })

      /*
       * Cartesian Grid Horizontal Line
       * */
      svg
        .selectAll('.cartesian-grid-horizontal')
        .data([0, 1, 2, 3, 4])
        .enter()
        .append('line')
        .attr('class', 'cartesian-grid-horizontal')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', (d) => (d === 0 ? chartHeightStart : d * (height / 4)))
        .attr('y2', (d) => (d === 0 ? chartHeightStart : d * (height / 4)))
        .attr('stroke', theme.colors.borderLow)
        .attr('stroke-width', '1px')

      /* Recent Performance Line */
      const existingRecentPerformanceLine = svg.select('.sla-line')
      if (!existingRecentPerformanceLine.empty()) existingRecentPerformanceLine.remove()
      svg
        .append('line')
        .attr('class', 'sla-line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', healthBorder < 2 ? healthBorder : healthBorder - 0.5)
        .attr('y2', healthBorder < 2 ? healthBorder : healthBorder - 0.5)
        .attr('stroke', theme.colors.white)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '6 6')

      const areas = data.cpi_ui_trend_chart_areas || defaultAreas
      const t1 = areas[0]
      let colorBottom = 'red'
      if (t1.range.includes('-inf,$')) {
        colorBottom = t1.color
      } else {
        colorBottom = areas[1].color
      }
      const colorGreen = '#27AE60'
      const colorRed = '#E74C3C'

      /*
       *  Filled Area
       * */
      const barBgRed = svg.select('.bar-bg-red')
      if (!barBgRed.empty()) barBgRed.remove()
      svg
        .selectAll('.bar-bg-red')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', colorBottom === 'green' ? colorRed : colorGreen)
        // .attr('fill', colorGreen)
        .attr('fill-opacity', '1')
        .attr('class', colorBottom === 'green' ? 'bar-bg-red' : 'bar-bg-green')
        // .attr('class', 'bar-bg-green')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', healthBorder)

      const barBgGreen = svg.select('.bar-bg-green')
      if (!barBgGreen.empty()) barBgGreen.remove()
      svg
        .selectAll('.bar-bg-green')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', colorBottom === 'green' ? colorGreen : colorRed)
        // .attr('fill', colorRed)
        .attr('fill-opacity', '1')
        .attr('class', colorBottom === 'green' ? 'bar-bg-green' : 'bar-bg-red')
        // .attr('class', 'bar-bg-red')
        .attr('x', 0)
        .attr('y', healthBorder)
        .attr('width', width)
        .attr('height', height)

      /*
       *  Filled Area Stroke
       * */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const existingFilledAreaStroke = svg.selectAll('.filled-area-stroke')
      if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', theme.colors.white)
        .attr('d', `${pathStr}`)
        .attr('class', 'filled-area-stroke')
        .attr('stroke-width', 1)
        .attr('stroke-linejoin', 'bevel')

      /* Stripes */
      svg
        .selectAll('.bar')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', 'transparent')
        .attr('fill-opacity', '1')
        .attr('class', 'bar')
        .attr('x', (d) => xScaleGroup(d) || 0)
        .attr('y', 0)
        .attr('width', xScaleGroup.bandwidth())
        .attr('height', height + margin.top + margin.bottom)
        .attr('stroke', theme.colors.borderLow)
        .attr('stroke-width', 1)

      /* SLA */
      const existingSlaGroup = svg.select('g.sla')
      if (!existingSlaGroup.empty()) existingSlaGroup.remove()
      const slaGroup = svg.append('g').attr('class', 'sla')
      const text = `${data.sla}${formatPerformanceUnit(healthUnit, true)}`
      const textWidth = getTextWidth(text, '590 10px Quicksand')
      const slaRectWidth = textWidth + 16
      const slaRectHeight = 23
      const slaMarginLeft = 31
      const { slaYPosition } = getSlaGroupProps(height, healthBorder, [], slaRectWidth)

      if (healthBorder >= 29) {
        if (height - slaYPosition >= 36) {
          /* SLA Below 10 */
          slaGroup
            .append('rect')
            .attr('class', 'sla-tooltip-wrapper')
            .attr('x', slaMarginLeft + 8)
            .attr('y', healthBorder - slaRectHeight + 3)
            .attr('width', slaRectWidth)
            .attr('height', slaRectHeight)
            .attr('fill', theme.colors.white)
            .attr('opacity', 1)
            .attr('rx', 5)
            .attr('clip-path', 'inset(0px 0px 3px 0px)')
          slaGroup
            .append('text')
            .attr('class', 'sla-text')
            .attr(
              'x',
              textWidth < 10
                ? 6 + slaMarginLeft + 8 + (slaRectWidth - 18) / 2
                : slaMarginLeft + 8 + (slaRectWidth - 18) / 2
            )
            .attr('y', healthBorder - slaRectHeight + 13)
            .attr('dy', '0.36em')
            .style('fill', theme.baseColors.greyscale[10])
            .style('font-size', theme.typography.fontSize.small)
            .style('font-family', theme.typography.fontFamily.primary)
            .style('font-weight', theme.typography.fontWeight.bold)
            .style('line-height', '20px')
            .style('letter-spacing', '1px')
            .style('text-transform', 'uppercase')
            .text(text)
        } else {
          /* Remaining SLA */
          slaGroup
            .append('rect')
            .attr('class', 'sla-tooltip-wrapper')
            .attr('x', slaMarginLeft + 8)
            .attr('y', slaYPosition - slaRectHeight / 2 - 8)
            .attr('width', slaRectWidth)
            .attr('height', slaRectHeight)
            .attr('fill', theme.colors.white)
            .attr('opacity', 1)
            .attr('rx', 5)
            .attr('clip-path', 'inset(0px 0px 3px 0px)')
          slaGroup
            .append('text')
            .attr('class', 'sla-text')
            .attr(
              'x',
              textWidth < 10
                ? 6 + slaMarginLeft + 8 + (slaRectWidth - 18) / 2
                : slaMarginLeft + 8 + (slaRectWidth - 18) / 2
            )
            .attr('y', slaYPosition - 9)
            .attr('dy', '0.36em')
            .style('fill', theme.baseColors.greyscale[10])
            .style('font-size', theme.typography.fontSize.small)
            .style('font-family', theme.typography.fontFamily.primary)
            .style('font-weight', theme.typography.fontWeight.bold)
            .style('line-height', '20px')
            .style('letter-spacing', '1px')
            .style('text-transform', 'uppercase')
            .text(text)
        }
      } else {
        /* SLA Above 90 */
        slaGroup
          .append('rect')
          .attr('class', 'sla-tooltip-wrapper')
          .attr('x', slaMarginLeft + 8)
          .attr('y', healthBorder - 4)
          .attr('width', slaRectWidth)
          .attr('height', slaRectHeight)
          .attr('fill', theme.colors.white)
          .attr('opacity', 1)
          .attr('rx', 5)
          .attr('clip-path', 'inset(3px 0px 0px 0px)')
        slaGroup
          .append('text')
          .attr('class', 'sla-text')
          .attr(
            'x',
            textWidth < 10
              ? 6 + slaMarginLeft + 8 + (slaRectWidth - 18) / 2
              : slaMarginLeft + 8 + (slaRectWidth - 18) / 2
          )
          .attr('y', healthBorder + 8)
          .attr('dy', '0.36em')
          .style('fill', theme.baseColors.greyscale[10])
          .style('font-size', theme.typography.fontSize.small)
          .style('font-family', theme.typography.fontFamily.primary)
          .style('font-weight', theme.typography.fontWeight.bold)
          .style('line-height', '20px')
          .style('letter-spacing', '1px')
          .style('text-transform', 'uppercase')
          .text(text)
      }

      /* X-Axis Container Background */
      svg
        .append('rect')
        .attr('fill', theme.colors.surfaceItemBackgroundLow)
        .attr('width', width)
        .attr('height', 60)
        .attr('y', height)
        .attr('class', 'x-axis-container')

      /*
       * X-Axis
       * */
      const xAxis = d3.axisBottom(xScaleGroup).tickFormat((date) => {
        const d = chartData.values.find(
          (n: any) => n.start_date.slice(0, 10) === dayjs(date).format(dateFormat.customLabel)
        )
        const dataStartsAt = chartData.values[0].start_date

        if (d) {
          const { rangeFromFormatted, rangeToFormatted } = getRangeFromToFormatted(
            CPIFrequency.Daily,
            dataStartsAt,
            (d as any).start_date,
            (d as any).end_date,
            false
          )
          const today = dayjs().format('YYYY-MM-DD')
          if (dayjs((d as any).end_date) >= dayjs(today)) return rangeFromFormatted

          if (!rangeToFormatted) return rangeFromFormatted
          return `${rangeFromFormatted}-${rangeToFormatted}`
        }

        return dayjs(date).format('MMM DD')
      })

      const existingXAxis = svg.selectAll('.x-axis')
      if (!existingXAxis.empty()) existingXAxis.remove()
      const xAxisGroup = svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('color', theme.colors.white)
        .attr('transform', `translate(0, ${height + 19})`)
        .call(xAxis)

      const windowWidth = window.innerWidth
      xAxisGroup
        .selectAll('text')
        .attr('color', theme.colors.white)
        .attr('font-size', windowWidth < 1280 ? '12px' : windowWidth < 1450 ? '14px' : '16px')
        .attr('font-weight', theme.typography.fontWeight.normal)
        .attr('line-height', 'normal')
        .attr('font-family', theme.typography.fontFamily.primary)

      xAxisGroup.selectAll('path').attr('display', 'none')
      const xAxisTick = svg.selectAll('.tick')
      xAxisTick.selectAll('line').attr('display', 'none')

      /*
       *  Y-Axis
       * */
      const yAxis = d3
        .axisRight(yScale)
        .ticks(5)
        .tickFormat((d, i) => {
          const unit = formatPerformanceUnit(healthUnit, true, true)

          return i === 0 ? '' : `${d}${unit}`
        })

      const existingYAxis = svg.selectAll('.y-axis')
      if (!existingYAxis.empty()) existingYAxis.remove()
      const yAxisGroup = svg.append('g').attr('class', 'y-axis').attr('color', 'none').call(yAxis)
      yAxisGroup
        .selectAll('text')
        .attr('dy', '18px')
        .attr('color', theme.colors.white)
        .attr('font-size', theme.typography.fontSize.small)
        .attr('font-weight', '590')
        .attr('letter-spacing', '1px')
        .attr('text-transform', 'uppercase')
        .attr('line-height', 'normal')
        .attr('font-family', theme.typography.fontFamily.primary)
      yAxisGroup.selectAll('path').attr('display', 'none')
      yAxisGroup.selectAll('line').attr('display', 'none')

      /* Last Data Point Tooltip */
      const unit = formatPerformanceUnit(healthUnit, true)
      const lastDataPointRectHeight = 54
      const nonPredictions = positions.filter((c: any) => !c.prediction)
      const lastDataPoint = nonPredictions[nonPredictions.length - 1]
      let tooltipTitle = dayjs(lastDataPoint.date).format('MMM DD, YYYY')
      if (data && data.trend_data) {
        const lastDataValue = data.trend_data[data.trend_data.length - 1]
        tooltipTitle = formatTooltipDateInsights(lastDataValue.start_date, lastDataValue.end_date)
      }
      const dataPointTooltipTextWidth = getTextWidth(tooltipTitle, '590 14px Quicksand')
      const lastDataPointRectWidth = dataPointTooltipTextWidth + 16
      const tooltipDescription = `${lastDataPoint.value}${unit}`

      const left = lastDataPoint.x - lastDataPointRectWidth / 2
      const maxLeft = width - lastDataPointRectWidth
      const thisLeft = left > maxLeft ? maxLeft : left
      const diff = maxLeft - left
      const nextLeft = diff < 0 ? thisLeft - diff : thisLeft

      tooltip.style('width', lastDataPointRectWidth)
      tooltip.style('height', lastDataPointRectHeight)
      tooltip.style('left', `${nextLeft - 1}px`)
      tooltip.style('top', `calc(${lastDataPoint.y}px - 70px)`)
      tooltip.html(`
        <div class="tooltip-wrapper">
          <p class="tooltip-title">${tooltipTitle}</p>
          <p class="tooltip-text">${tooltipDescription}</p>
        </div>
      `)
      tooltip.style('display', 'block')

      raiseElements(svg)
      svg.selectAll('g.tick').raise()
      svg.selectAll('.tick').raise()
      svg.selectAll('.y-axis').raise()
      svg.selectAll('.sla-tooltip-wrapper').raise()
      svg.selectAll('.sla-text').raise()
      svg.selectAll('.sla-line').raise()
    },
    [insight, margin, width, height]
  )

  useEffect(() => {
    if (insight.data) {
      const { data } = insight

      if (data.cpi_display_name === 'CPI-007') {
        const dataMaxValue = Math.max(...(data as any).trend_data.map((e: any) => (isNaN(e.value) ? 0 : e.value)))

        buildTrendsChartOC(data as any, dataMaxValue)
      } else {
        if (
          insight.type === 'cpi_trend_prediction' &&
          Array.isArray(data.trend_data) &&
          Array.isArray(data.prediction_trend_data)
        ) {
          const allData = [...data.trend_data, ...data.prediction_trend_data.map((c) => ({ ...c, prediction: true }))]
          const allValues = allData.map((c) => (Array.isArray(c.values) ? c.values.map((n) => n.value) : 0)).flat()
          const dataMaxValue = Math.max(...allValues.map((e: any) => (isNaN(e) ? 0 : e)))

          const t = allData
            .map((obj1, i, arr) => {
              const test = arr.filter((c: any) => c.start_date === obj1.start_date)
              if (test.length > 1) {
                return {
                  values: [
                    ...(test as any)[0].values.map((n: any) => ({ ...n, prediction: false })),
                    ...(test as any)[1].values.map((n: any) => ({ ...n, prediction: true }))
                  ],
                  end_date: test[0].end_date,
                  start_date: test[0].start_date
                }
              }

              return {
                ...test[0],
                values: (obj1 as any).values.map((c: any) => ({
                  ...c,
                  prediction: Boolean((obj1 as any).prediction)
                }))
              }
            })
            .filter((obj1, i, arr) => {
              const found = arr.findIndex((obj2) => (obj2 as any).start_date === (obj1 as any).start_date)

              return found === i
            })

          buildPredictionChart(
            {
              ...data,
              trend_data: t as any
            } as any,
            dataMaxValue
          )
        } else {
          const dataMaxValue = Math.max(...(data as any).trend_data.map((e: any) => (isNaN(e.value) ? 0 : e.value)))

          buildTrendsChart(data as any, dataMaxValue)
        }
      }
    }
  }, [insight, width])

  return (
    <Container className="insights-trends-data-container">
      <Header className="header">
        <Box className="header-item">
          <GreenZoneIcon />
          <Typography className="header-item-text">Green Zone</Typography>
        </Box>
        <Box className="header-item">
          <RedZoneIcon />
          <Typography className="header-item-text">Red Zone</Typography>
        </Box>
        <Box className="header-item">
          <SLAIcon />
          <Typography className="header-item-text">
            SLA (<span>{insight.data?.section || ''}</span>)
          </Typography>
        </Box>
        <Box className="header-item">
          <TrendLineIcon />
          <Typography className="header-item-text">Trend line</Typography>
        </Box>
        {insight.type === 'cpi_trend_prediction' && (
          <Box className="header-item">
            <PredictionLineIcon />
            <Typography className="header-item-text">Predictions line</Typography>
          </Box>
        )}
      </Header>
      <ChartWrapper>
        <Box className="chart-container">
          <div ref={containerRef} className="trends-d3-wrapper">
            <InsightsChart
              ref={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                containerRef,
                svgRef
              }}
              width={width}
              height={height}
              margin={margin}
              setWidth={setWidth}
              setHeight={setHeight}
            />
          </div>
        </Box>
      </ChartWrapper>
    </Container>
  )
}

export default InsightsTrendsData
