import { FC, useEffect, useRef, useState } from 'react'
import { Box, SelectChangeEvent, Typography, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import theme from 'theme'

/* Utils */
import axios from '../../../../lib/axios'
import {
  CPIFrequency,
  CPILibraryItem,
  CPIManifest,
  ConfiguredCPI,
  CPISeverity,
  CPITimePeriod
} from '../../../../models'
import { useAppDispatch } from '../../../../store/hooks'
import { defaultFrequencyValues } from '../../library/library.constants'
import { openInNewTab } from '../reports.utils'
import { getPayloadTimePeriod } from '../../library/utils'
import { extractSeverityValues } from '../../../../lib/utils'
import { closeToast, openToast, ToastVariant } from '../../../../store/toasts/toastSlice'

/* Components */
import { ActionsWrapper, CustomizerContainer, FormItemWrapper, FormWrapper, Wrapper } from '../reports.styles'
import TabsHeader from '../../../components/tabs-header'
import CustomizeReportStepper from './components/CustomizeReportStepper'
import ReportsNavigationSelectField from '../../../components/reports-navigation-select-field'
import { ThemeButton } from '../../../components/buttons'
import ReportsTimePeriodPicker from './components/ReportsTimePeriodPicker'
import ReportsSelectField from './components/ReportsSelectField'
import { ArrowBackIcon, ArrowForwardIcon, DownloadIcon } from '../../../components/svg'
// import { useFramework } from '../../../../hooks/useFramework'

const steps = ['Choose CPI', 'Set Preferences']
const defaultSeverityTitle = 'Set the severity of data to collect'
const defaultSeverityTitleKeyword = 'severity'
const defaultSeverityOptions = [
  'All Severities',
  CPISeverity.Critical,
  CPISeverity.High,
  CPISeverity.Medium,
  CPISeverity.Low
]
const timePeriodOptions = [
  CPITimePeriod.Week,
  CPITimePeriod.Month,
  CPITimePeriod.ThreeMonths,
  CPITimePeriod.SixMonths,
  CPITimePeriod.PastYear
]

const CustomizeReport: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const [activeCpis, setActiveCpis] = useState<CPILibraryItem[]>([])
  const [cpiValue, setCpiValue] = useState('')
  const closeNotification = useRef<any>(null)
  const [generatingCpiReport, setGeneratingCpiReport] = useState(false)
  const [customTimePeriodValue, setCustomTimePeriodValue] = useState<{
    dateFrom: string
    dateTo: string
  }>({
    dateFrom: '',
    dateTo: ''
  })
  const [cpiConfiguration, setCpiConfiguration] = useState<ConfiguredCPI | null>(null)
  const [manifestData, setManifestData] = useState<CPIManifest | null>(null)
  const [severityOptions, setSeverityOptions] = useState<string[]>(defaultSeverityOptions)
  const [nextLoading, setNextLoading] = useState(false)
  const [severityTitleKeyword, setSeverityTitleKeyword] = useState(defaultSeverityTitle)
  // const { framework, customFramework } = useFramework()

  const [code, setCode] = useState('')
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<CPITimePeriod | string>('')
  const [selectedFrequency, setSelectedFrequency] = useState<CPIFrequency | string>('')
  const [selectedSeverity, setSelectedSeverity] = useState<CPISeverity | string>('')
  const [hasNoSeverities, setHasNoSeverities] = useState(false)

  const { data, error, isFetching } = useQuery(
    ['code', { code }],
    () => axios.get(`/api/v1/reports/download_url/${code}`),
    {
      refetchInterval: 5000,
      enabled: !!code
    }
  )

  useEffect(() => {
    if (error && (error as any).response?.status === 418) {
      setGeneratingCpiReport(false)
      setCode('')
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: (error as any).response?.data.error || 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }, [error])

  useEffect(() => {
    if (data && !error && !isFetching) {
      const downloadUrl = data.data.reportDownloadUrl.url
      openInNewTab(`${downloadUrl}`)
      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: `${cpiValue} Report PDF Successfully Downloaded`,
            description: ''
          }
        })
      )
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setCode('')
      navigate('/reports')
    }
  }, [data, error, isFetching, code])

  const generateCpiReport = async () => {
    setGeneratingCpiReport(true)

    try {
      if (!cpiConfiguration || !manifestData) return
      const integrationIds = cpiConfiguration.integrations.map((n: any) => n.id)

      const payload: any = {
        cpi_name: manifestData.name,
        frequency: selectedFrequency,
        integration_ids: integrationIds,
        section: selectedSeverity.includes('All') ? 'all' : selectedSeverity,
        timePeriod: getPayloadTimePeriod(selectedTimePeriod as CPITimePeriod)
      }

      if (customTimePeriodValue.dateFrom && customTimePeriodValue.dateTo) {
        delete payload.timePeriod
        payload.dateFrom = customTimePeriodValue.dateFrom
        payload.dateTo = customTimePeriodValue.dateTo
      }

      const res = await axios.post(`/api/v1/reports/cpi`, payload)

      if (res.status.toString().startsWith('2')) {
        const { code } = res.data
        setCode(code)
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
    } finally {
      setGeneratingCpiReport(false)
    }
  }

  const fetchSelectedCpi = async () => {
    setNextLoading(true)

    let cpiName = ''
    const foundOption = activeCpis.length > 0 ? activeCpis.find((n) => n.title === cpiValue) : undefined
    if (foundOption) {
      cpiName = foundOption.name
    }

    try {
      const cpiConfigRes = await axios.get<{ data: ConfiguredCPI }>(`/api/v3/cpis/${cpiName}`)
      const manifestRes = await axios.get<{ data: CPIManifest | null }>(`/api/v3/cpis/${cpiName}/details`)
      const {
        data: { data: cpiConfigData }
      } = cpiConfigRes
      const { data: manifestData } = manifestRes.data
      setCpiConfiguration(cpiConfigData)
      setManifestData(manifestData)
      if (manifestData) {
        const visualizationParam = manifestData.ui.trend_chart.user_input.visualization_param
        const { identical_section_values: identicalSectionValues, sections } = visualizationParam
        if (identicalSectionValues && sections.length === 1) {
          setHasNoSeverities(true)
          setSelectedSeverity(sections[0].name)
        } else {
          setHasNoSeverities(false)
        }
      }

      const values = extractSeverityValues(cpiConfigData, manifestData)
      /* Severity values is an object that can include "SLA" in it, so
       * we want to exclude that here for the severity options */
      const severityOptions = Object.keys(values).filter(
        (n) => n !== 'sla' && n !== 'isNoSeveritiesCpi' && n !== 'days_until_inactive'
      )
      setSeverityOptions(severityOptions)

      const params = manifestData?.ui.trend_chart.user_input.visualization_param
      if (!params || params.fields_suffix !== 'Incidents') {
        let sectionDisplayName = params?.sections_singular_display_name?.toLowerCase() || defaultSeverityTitleKeyword
        if (sectionDisplayName === 'Severities') sectionDisplayName = defaultSeverityTitleKeyword
        setSeverityTitleKeyword(sectionDisplayName)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setNextLoading(false)
    }
  }

  const handleNext = async () => {
    if (activeStep === 0) {
      await fetchSelectedCpi()
      setActiveStep(1)
    } else {
      generateCpiReport().catch((e) => e)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setSelectedTimePeriod('')
    setSelectedSeverity('')
    setSelectedFrequency('')
  }

  const fetchListOfCPI = async () => {
    try {
      const allCpisRes = await axios.get('/api/v3/cpis')
      const activeList = allCpisRes.data.data.cpis.filter((item: CPILibraryItem) => item.configuration)
      setActiveCpis(activeList)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchListOfCPI().catch((e) => e)
  }, [])

  const handleChangeTimePeriod = (event: SelectChangeEvent | null) => {
    const period = event ? (event.target.value as CPITimePeriod) : CPITimePeriod.Custom
    setSelectedTimePeriod(period)
  }

  return (
    <CustomizerContainer>
      <TabsHeader
        tabValue={0}
        parents={['Reports']}
        handleTabChange={() => {
          //
        }}
        title="Customize a CPI Report"
        tabLabels={[]}
        hideIntegrationsTabs={true}
      />

      <Wrapper>
        <CustomizeReportStepper steps={steps} activeStep={activeStep} />

        <FormWrapper>
          {activeStep === 0 && (
            <FormItemWrapper>
              <Typography className="title">Choose your CPI:</Typography>
              <ReportsNavigationSelectField
                label=""
                options={activeCpis}
                placeholder="Active Cpi"
                value={cpiValue}
                handleChange={(newValue) => setCpiValue(newValue)}
                minWidth="160px !important"
                noborder="true"
                formControlSx={{
                  background: 'transparent !important',
                  border: `1px solid ${theme.colors.controlBorder} !important`,
                  borderRadius: `${theme.attributes.borderRadius.small} !important`,
                  height: '56px !important',

                  '.MuiOutlinedInput-root': {
                    height: '56px !important',

                    '> svg:first-of-type': {
                      top: `${theme.spacing.small}px`
                    }
                  }
                }}
              />
            </FormItemWrapper>
          )}
          {activeStep === 1 && (
            <>
              <FormItemWrapper>
                <Typography className="title">Set the date range</Typography>
                <ReportsTimePeriodPicker
                  timePeriodOptions={timePeriodOptions}
                  selectedTimePeriod={selectedTimePeriod}
                  setSelectedTimePeriod={setSelectedTimePeriod}
                  handleChangeTimePeriod={handleChangeTimePeriod}
                  setCustomTimePeriodValue={setCustomTimePeriodValue}
                />
              </FormItemWrapper>
              <FormItemWrapper>
                <Typography className="title">Set the data frequency</Typography>
                <ReportsSelectField
                  options={defaultFrequencyValues as string[]}
                  value={selectedFrequency}
                  handleChange={(e: any) => setSelectedFrequency(e.target.value)}
                />
              </FormItemWrapper>
              {!hasNoSeverities && (
                <FormItemWrapper>
                  <Typography className="title">{`Set the ${severityTitleKeyword} of data to collect`}</Typography>
                  <ReportsSelectField
                    options={severityOptions}
                    value={selectedSeverity}
                    handleChange={(e: any) => setSelectedSeverity(e.target.value)}
                  />
                </FormItemWrapper>
              )}
            </>
          )}
        </FormWrapper>

        <ActionsWrapper>
          <Box className="buttons-container">
            {activeStep > 0 && (
              <ThemeButton
                themevariant="secondary"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: `${theme.spacing.sm}px`, paddingLeft: `${theme.spacing.small}px !important` }}
              >
                <>
                  <ArrowBackIcon />
                  Back
                </>
              </ThemeButton>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            <ThemeButton
              onClick={handleNext}
              disabled={
                (activeStep === 0 && (!cpiValue || nextLoading)) ||
                (activeStep === 1 && (!selectedTimePeriod || !selectedFrequency || !selectedSeverity))
              }
            >
              <>
                {activeStep === steps.length - 1 ? (
                  <>
                    {generatingCpiReport || isFetching ? (
                      <CircularProgress size={18} color="secondary" />
                    ) : (
                      <DownloadIcon
                        fill={
                          !selectedTimePeriod || !selectedFrequency || !selectedSeverity
                            ? theme.baseColors.greyscale[80]
                            : theme.baseColors.primary[20]
                        }
                      />
                    )}
                  </>
                ) : (
                  <ArrowForwardIcon fill={!cpiValue ? theme.colors.neutral : theme.baseColors.primary[20]} />
                )}
                {activeStep === steps.length - 1 ? 'Download PDF' : 'Next'}
              </>
            </ThemeButton>
          </Box>
        </ActionsWrapper>
      </Wrapper>
    </CustomizerContainer>
  )
}

export default CustomizeReport
