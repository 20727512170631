import styled from '@emotion/styled'
import { Box } from '@mui/material'
import theme from 'theme'

export const SearchBarWrapper = styled(Box)<{ val?: string }>`
  display: flex;
  justify-content: center;
  cursor: text;

  .search-icon {
    position: absolute;
    left: ${theme.spacing.sm}px;
    top: 9px;
    z-index: 1;
    transition: width 0.3s ease-in-out;

    svg {
      transition: width 0.3s ease-in-out;

      path {
        fill: ${theme.colors.controlBorder};
      }
    }
  }

  .clear-btn {
    position: absolute;
    right: 0;
    top: 2px;
    transition: width 0.3s ease-in-out;

    svg {
      transition: width 0.3s ease-in-out;

      path {
        fill: ${theme.colors.white};
      }
    }
  }

  .search-bar {
    width: 100%;
    border-radius: 28px;
    height: 40px;
  }

  .MuiOutlinedInput-input {
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.textPrimary};
    background: ${theme.colors.surfaceItemBackground};
    height: 24px;
    padding: ${({ val }) =>
      typeof val !== 'undefined' && val.length > 0
        ? `${theme.spacing.xSmall}px ${theme.spacing.xLarge}px ${theme.spacing.xSmall}px ${theme.spacing.xSmall}px`
        : `${theme.spacing.xSmall}px ${theme.spacing.xLarge}px ${theme.spacing.xSmall}px ${theme.spacing.xLarge}px`};
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    transition: all 0.3s ease-in-out;

    &::placeholder {
      color: ${theme.colors.textSecondaryLow};
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }

    &:focus {
      border-color: ${theme.colors.textPrimary};
    }
  }

  fieldset {
    border: none;
  }
`
