import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const CategoryInfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: `${theme.spacing.small}px`,
    width: '316px',
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.surfaceItemBackgroundLow,
    margin: 0,
    marginLeft: `${theme.spacing.md}px`,
    height: '100%'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: `${theme.spacing.xSmall}px`,

    '&.weights-wrapper': {
      gap: `${theme.spacing.md}px`,

      '.weights-wrapper-header': {
        display: 'flex',
        alignCenter: 'center',
        gap: `${theme.spacing.xSmall}px`,

        '&.header-text-wrapper': {
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',

          '.tooltip-text.weights': {
            marginRight: `${theme.spacing.md}px`
          }
        },

        '.weights-warning-icon': {
          width: '24px',
          height: '24px',
          minWidth: '24px',
          minHeight: '24px',

          path: {
            fill: theme.baseColors.info[50]
          }
        }
      }
    },

    '.info-icon': {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',

      path: {
        fill: theme.baseColors.secondary[80]
      }
    }
  },
  '.tooltip-text': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: 'normal',

    '&.weights': {
      color: theme.baseColors.info[50],
      lineHeight: 'normal'
    },

    '&.weights-title': {
      marginTop: `${theme.spacing.half}px`
    }
  }
})
