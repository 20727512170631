import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const BenchmarkRangeTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: '200px',
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.overallScoreWrapper,
    padding: `${theme.spacing.small}px`
  },

  '.tooltip-value': {
    '.MuiTypography-root': {
      color: theme.colors.white,
      fontFamily: theme.typography.fontFamily.primary,
      fontWeight: theme.typography.fontWeight.normal,
      fontStyle: 'normal',
      textAlign: 'start',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap'
    },

    '.tooltip-title': {
      fontSize: theme.typography.fontSize.xxxLarge,
      lineHeight: '32px',
      marginBottom: '3px'
    },

    '&.tooltip-text': {
      fontSize: theme.typography.fontSize.medium,
      lineHeight: '16px',
      letterSpacing: '0.4px'
    }
  }
}))

export default BenchmarkRangeTooltip
