import { Button as MuiButton } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Button = styled(MuiButton)<{ buttontype?: string }>`
  background: ${theme.colors.rawSienneCore};
  box-shadow: ${theme.attributes.boxShadow.tertiary};
  border-radius: ${theme.attributes.borderRadius.secondary};
  font-weight: 700;
  font-size: ${theme.typography.fontSize.xLarge};
  line-height: 24px;
  color: ${theme.colors.purpleDark};
  text-transform: capitalize;
  padding: ${theme.spacing.xsm}px 36px;
  margin: 0 auto;

  :hover {
    color: ${theme.colors.white};

    svg {
      path {
        fill: ${theme.colors.white};
      }
    }
  }

  ${({ buttontype }) =>
    buttontype &&
    buttontype === 'delete' &&
    `
     background: #E85541;
     color: #F5F5F5;
  `};

  ${({ buttontype }) =>
    buttontype &&
    buttontype === 'red-button' &&
    `
     border-radius: ${theme.attributes.borderRadius.small};
     background: #FD7B69;
  `};
`
