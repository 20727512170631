import { FC } from 'react'
import { Box, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import theme from 'theme'

/* Utils */
import { Benchmark, BenchmarkRowData, BenchmarkScoreCategory } from '../../../../../../../models'

/* Components */
import {
  BenchmarkValueWrapper,
  CategoryPerformanceScoreWrapper,
  CPICell,
  CPITableContainer,
  FooterCell,
  SLACell
} from '../../../categoryPage.styles'
import BenchmarkingSelectField from '../../../../../dashboard/components/Benchmarking/components/BenchmarkingSelectField'
import { BenchmarkCardTooltip } from '../../../../../dashboard/components/Benchmarking/components/BenchmarkCardTooltip'
import { formatCPIDisplayNameForURL } from '../../../../../../../lib/utils'

const popperProps = {
  sx: {
    marginTop: `-${theme.spacing.sm}px !important`
  }
}

const minPercentageForShow = 11

const showPercentage = (percentage: number | null) => {
  if (!percentage) {
    return ''
  }
  if ((percentage.toString().length > 2 && percentage < minPercentageForShow) || percentage <= 6) {
    return ''
  }

  return `${percentage}%`
}

interface Props {
  containersMaxWidth: number
  benchmark: Benchmark | null
  benchmarkForCategory: BenchmarkScoreCategory | null
  selectedBenchmark: string
  handleCompareToChange: (e: SelectChangeEvent<string>) => void
  rows: BenchmarkRowData[]
  lastUpdatePerformance: string
}

const BenchmarkingScore: FC<Props> = ({
  containersMaxWidth,
  benchmark,
  benchmarkForCategory,
  selectedBenchmark,
  handleCompareToChange,
  rows,
  lastUpdatePerformance
}) => {
  const navigate = useNavigate()

  const toLink = (name: string) => {
    navigate(`/library/cpis/${formatCPIDisplayNameForURL(name)}`)
  }

  return (
    <CategoryPerformanceScoreWrapper sx={{ minWidth: containersMaxWidth }}>
      <Box className="header-block">
        <Typography className="title">Benchmarking Score</Typography>
        <Typography className="date">Last Update: {lastUpdatePerformance}</Typography>
      </Box>
      <Box className="chips-wrapper">
        <Box className="chip">
          <Box className="chip-circle less" /> <Typography className="chip-sla">Less Strict SLA</Typography>
        </Box>
        <Box className="chip">
          <Box className="chip-circle same" /> <Typography className="chip-sla">Same SLA</Typography>
        </Box>
        <Box className="chip">
          <Box className="chip-circle more" /> <Typography className="chip-sla">More Strict SLA</Typography>
        </Box>
      </Box>

      {benchmark && (
        <Box className="select-wrapper">
          <BenchmarkingSelectField
            options={benchmark.score.map((scoreItem) => scoreItem.industry_name)}
            value={selectedBenchmark}
            handleChange={handleCompareToChange}
            compareText="Compare to"
            disabled={!benchmarkForCategory || !benchmarkForCategory.summary}
          />
        </Box>
      )}

      {benchmarkForCategory && benchmarkForCategory.summary && (
        <BenchmarkValueWrapper
          one={benchmarkForCategory.summary.less ?? 0}
          two={benchmarkForCategory.summary.equal ?? 0}
          three={benchmarkForCategory.summary.more ?? 0}
        >
          <BenchmarkCardTooltip
            PopperProps={popperProps}
            title={
              <>
                <Box className="tooltip-wrapper">
                  <Typography className="tooltip-title">{benchmarkForCategory.summary.less}%</Typography>
                  <Typography className="tooltip-text">of companies have less strict SLAs</Typography>
                </Box>
              </>
            }
          >
            <Box className="one">{showPercentage(benchmarkForCategory.summary.less)}</Box>
          </BenchmarkCardTooltip>
          <BenchmarkCardTooltip
            PopperProps={popperProps}
            title={
              <>
                <Box className="tooltip-wrapper">
                  <Typography className="tooltip-title">{benchmarkForCategory.summary.equal}%</Typography>
                  <Typography className="tooltip-text">of companies have similar SLAs</Typography>
                </Box>
              </>
            }
          >
            <Box className="two">{showPercentage(benchmarkForCategory.summary.equal)}</Box>
          </BenchmarkCardTooltip>
          <BenchmarkCardTooltip
            PopperProps={popperProps}
            title={
              <>
                <Box className="tooltip-wrapper">
                  <Typography className="tooltip-title">{benchmarkForCategory.summary.more}%</Typography>
                  <Typography className="tooltip-text">of companies have more strict SLAs</Typography>
                </Box>
              </>
            }
          >
            <Box className="three">{showPercentage(benchmarkForCategory.summary.more)}</Box>
          </BenchmarkCardTooltip>
        </BenchmarkValueWrapper>
      )}
      {!benchmarkForCategory ||
        (!benchmarkForCategory.summary && (
          <Box className="no-category">
            <img src="/info-panel.svg" alt="info" />
            No benchmark data available for this CPI Category.
          </Box>
        ))}

      <Typography className="table-title">Calculation Details</Typography>
      <CPITableContainer>
        <Table aria-label="category performance score table">
          <TableHead>
            <TableRow>
              <TableCell>CPI</TableCell>
              <TableCell align="right">Weight</TableCell>
              <TableCell align="right">Less Strict SLA</TableCell>
              <TableCell align="right">Same SLA</TableCell>
              <TableCell align="right">More Strict SLA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) =>
              !row.summary ? (
                <TableRow key={row.name} sx={{ background: '#342F32 !important' }}>
                  <CPICell component="th" scope="row" sx={{ opacity: '0.38 !important' }}>
                    <Box className="cpi-cell-wrapper">
                      <Box className="cpi-chip" onClick={() => toLink(row.name)}>
                        {row.name}
                      </Box>
                      <Box className="cpi-title" onClick={() => toLink(row.name)}>
                        {row.cpiTitle}
                      </Box>
                    </Box>
                  </CPICell>
                  <TableCell align="right" sx={{ verticalAlign: 'baseline !important', opacity: '0.38 !important' }}>
                    {row.weight}
                  </TableCell>
                  <SLACell align="right" type="less" sx={{ opacity: '0.38 !important' }}>
                    <Typography component="span">--%</Typography>
                  </SLACell>
                  <SLACell align="right" type="same" sx={{ opacity: '0.38 !important' }}>
                    <Typography component="span">--%</Typography>
                  </SLACell>
                  <SLACell align="right" type="more" sx={{ opacity: '0.38 !important' }}>
                    <Typography component="span">--%</Typography>
                  </SLACell>
                </TableRow>
              ) : (
                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <CPICell component="th" scope="row">
                    <Box className="cpi-cell-wrapper">
                      <Box className="cpi-chip" onClick={() => toLink(row.name)}>
                        {row.name}
                      </Box>
                      <Box className="cpi-title" onClick={() => toLink(row.name)}>
                        {row.cpiTitle}
                      </Box>
                    </Box>
                  </CPICell>
                  <TableCell align="right" sx={{ verticalAlign: 'baseline !important' }}>
                    {row.weight}
                  </TableCell>
                  <SLACell align="right" type="less">
                    <Typography component="span">{row.summary.less}%</Typography>
                  </SLACell>
                  <SLACell align="right" type="same">
                    <Typography component="span">{row.summary.equal}%</Typography>
                  </SLACell>
                  <SLACell align="right" type="more">
                    <Typography component="span">{row.summary.more}%</Typography>
                  </SLACell>
                </TableRow>
              )
            )}
            <TableRow>
              <FooterCell align="right" colSpan={2} className="no-border">
                <Box className="last-tr-big">Resulting Scores:</Box>
              </FooterCell>
              <FooterCell align="right" className="no-border">
                <Box className="last-tr">
                  <Typography className="footer-sla less">{benchmarkForCategory?.summary?.less ?? 0}%</Typography>
                </Box>
              </FooterCell>
              <FooterCell align="right" className="no-border">
                <Box className="last-tr">
                  <Typography className="footer-sla same">{benchmarkForCategory?.summary?.equal ?? 0}%</Typography>
                </Box>
              </FooterCell>
              <FooterCell align="right" className="no-border">
                <Box className="last-tr">
                  <Typography className="footer-sla more">{benchmarkForCategory?.summary?.more ?? 0}%</Typography>
                </Box>
              </FooterCell>
            </TableRow>
          </TableBody>
        </Table>
      </CPITableContainer>
    </CategoryPerformanceScoreWrapper>
  )
}

export default BenchmarkingScore
