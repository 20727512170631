import { FC, MouseEvent } from 'react'
import { IconButton, MenuItem, Typography } from '@mui/material'
import theme from 'theme'

/* Components */
import { MarkMenu } from '../listToolbar.styles'

interface Props {
  markMenuOpen: boolean
  anchorEl: null | HTMLElement
  handleClick: (event: MouseEvent<HTMLElement>) => void
  handleClose: (option: string) => void
  markMenuOptions: string[]
  selectedOption: string
}

const SortMenu: FC<Props> = ({ markMenuOptions, markMenuOpen, selectedOption, handleClick, handleClose, anchorEl }) => {
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={markMenuOpen ? 'long-menu' : undefined}
        aria-expanded={markMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
        sx={{
          color: markMenuOpen ? theme.baseColors.secondary[80] : theme.colors.textPrimary,
          background: markMenuOpen ? theme.baseColors.primary[20] : '',
          '&:hover': {
            background: theme.baseColors.primary[70],

            svg: {
              path: {
                fill: theme.baseColors.primary[20]
              }
            }
          }
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M2 18.3333H8.66667V16.1111H2V18.3333ZM2 5V7.22222H22V5H2ZM2 12.7778H15.3333V10.5556H2V12.7778Z"
            fill={markMenuOpen ? theme.colors.white : theme.colors.textPrimary}
          />
        </svg>
      </IconButton>
      <MarkMenu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={markMenuOpen}
        onClose={() => handleClose('')}
        sx={{
          '.MuiPaper-root': {
            marginTop: '5.5px',
            borderRadius: theme.attributes.borderRadius.small,
            width: '200px',
            maxHeight: '167px',
            border: `1px solid ${theme.colors.borderLow}`,
            background: theme.colors.surfaceItemBackground,
            padding: 0,

            '.MuiList-root': {
              padding: `${theme.spacing.xSmall}px`,

              '.MuiMenuItem-root': {
                justifyContent: 'space-between',
                borderRadius: theme.attributes.borderRadius.small,
                height: '40px',
                overflow: 'hidden',
                color: theme.colors.white,
                textOverflow: 'ellipsis',
                fontSize: theme.typography.fontSize.xLarge,
                fontStyle: 'normal',
                fontWeight: theme.typography.fontWeight.normal,
                lineHeight: 'normal',
                padding: `0 ${theme.spacing.xSmall}px`,

                svg: {
                  transition: 'all .4s ease',

                  path: {
                    transition: 'all .4s ease'
                  }
                },

                '&:hover': {
                  background: theme.colors.primaryLight
                }
              }
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography className="sort-menu-title">Sort by</Typography>
        {markMenuOptions.map((option) => (
          <MenuItem key={option} selected={false} onClick={() => handleClose(option)}>
            {option}
            {selectedOption === option && (
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.35588 16.5296L3.61455 11.7882L2 13.3914L8.35588 19.7473L22 6.10318L20.3968 4.5L8.35588 16.5296Z"
                  fill={theme.colors.textPrimary}
                />
              </svg>
            )}
          </MenuItem>
        ))}
      </MarkMenu>
    </div>
  )
}

export default SortMenu
