import { FC } from 'react'
import { SSMCardSkeleton } from './insightsList.styles'
import { Skeleton } from '@mui/material'
import theme from 'theme'

const HeaderLoadingSkeleton: FC = () => (
  <SSMCardSkeleton>
    <Skeleton
      variant="rectangular"
      component="div"
      animation="wave"
      width="100%"
      height="100%"
      className="card-skeleton-inner"
      sx={{ width: '150px !important', height: '20px !important', borderRadius: theme.attributes.borderRadius.small }}
    />
  </SSMCardSkeleton>
)

export default HeaderLoadingSkeleton
