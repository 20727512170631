import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const SearchBarWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.xSmall}px ${theme.spacing.xSmall}px 0;
  position: sticky;
  top: 0;
  z-index: 1;

  .search-icon {
    position: absolute;
    left: ${theme.spacing.lg}px;
    top: 18px;
    z-index: 1;

    svg {
      path {
        fill: ${theme.colors.controlBorder};
      }
    }
  }

  .clear-btn {
    position: absolute;
    right: ${theme.spacing.lg}px;
  }

  .search-bar {
    width: 100%;
    height: 40px;
  }

  .MuiOutlinedInput-input {
    border: 1px solid ${theme.colors.controlBorder};
    border-radius: ${theme.attributes.borderRadius.small};
    background: ${theme.colors.surfaceItemBackground};
    height: 40px;
    padding: 0 ${theme.spacing.xLarge}px;
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;

    &::placeholder {
      color: ${theme.colors.textSecondaryLow};
      opacity: 1;
    }

    &:focus {
      border-color: ${theme.colors.textPrimary};
    }
  }

  fieldset {
    border: none;
  }
`
