import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DetailedComparisonWrapper = styled(Box)`
  padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundLight};
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  width: 100%;
  max-width: 756px;

  .detailed-comparison-title {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .detailed-comparison-description {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .no-category {
    border-radius: 2px;
    background: ${theme.colors.borderLow};
    box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${theme.colors.white};
    padding: ${theme.spacing.xsm}px 7px;
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
    margin-top: ${theme.spacing.small}px;
    margin-bottom: ${theme.spacing.medium}px;
  }
`

export const DetailedComparisonLegend = styled(Box)`
  margin: ${theme.spacing.medium}px 0 52px;
  gap: ${theme.spacing.medium}px;
  display: flex;
  align-items: center;

  .legend-item {
    display: flex;
    align-items: center;

    .legend-color {
      width: 16px;
      height: 16px;

      &.blue {
        border-radius: ${theme.attributes.borderRadius.small} 0 0 ${theme.attributes.borderRadius.small};
        border: 1px solid #8e9aff;
        background: linear-gradient(180deg, #8e9aff 0%, rgba(142, 154, 255, 0.4) 100%);
      }

      &.orange {
        border: 1px solid ${theme.baseColors.info[40]};
        background: linear-gradient(180deg, rgba(240, 149, 67, 0.94) 0%, rgba(240, 149, 67, 0.4) 100%);
      }

      &.purple {
        border-radius: 0 ${theme.attributes.borderRadius.small} ${theme.attributes.borderRadius.small} 0;
        border: 1px solid ${theme.baseColors.primary[50]};
        background: linear-gradient(180deg, ${theme.baseColors.primary[30]} 0%, rgba(120, 26, 126, 0.4) 100%);
      }
    }

    .legend-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      margin-left: ${theme.spacing.xSmall}px;
    }
  }
`

export const DetailedComparisonGroupsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg}px;
  margin-bottom: ${theme.spacing.large}px;
`

export const DetailedComparisonItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;

  .title-wrapper {
    flex-basis: 25%;
    margin-right: ${theme.spacing.medium}px;

    .industry-title {
      text-align: right;
      white-space: nowrap;
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
`

export const DetailedComparisonItemValues = styled(Box)<{
  one: string | number
  two: string | number
  three: string | number
}>`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: content-box;
  flex-basis: 75%;
  color: ${theme.colors.white};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.xLarge};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 24px;
  letter-spacing: 0.1px;

  &:hover {
    .one {
      opacity: 0.38;
    }

    .two {
      opacity: 0.38;
    }

    .three {
      opacity: 0.38;
    }
  }

  .one {
    width: ${({ one }) => `${one < 1 ? 0 : one}%`};
    border-radius: ${theme.attributes.borderRadius.quaternary} 0 0 ${theme.attributes.borderRadius.quaternary};
    border-top: 1px solid #8e9aff;
    border-bottom: 1px solid #8e9aff;
    border-left: 1px solid #8e9aff;
    background: linear-gradient(180deg, #8e9aff 0%, rgba(142, 154, 255, 0.4) 100%);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ one }) =>
      one < 1 &&
      `
      border: none;
    `};

    ${({ two, three }) =>
      two < 1 &&
      three < 1 &&
      `
      border: 16px;
    `};

    &:hover {
      border: 1px solid ${theme.colors.white};
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }

  .two {
    width: ${({ two }) => `${two < 1 ? 0 : two}%`};
    border-top: 1px solid ${theme.baseColors.info[40]};
    border-bottom: 1px solid ${theme.baseColors.info[40]};
    background: linear-gradient(180deg, rgba(240, 149, 67, 0.94) 0%, rgba(240, 149, 67, 0.4) 100%);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ one, two }) =>
      one < 1 &&
      two >= 1 &&
      `
      border-radius: ${theme.attributes.borderRadius.quaternary} 0 0 ${theme.attributes.borderRadius.quaternary};
    `};

    ${({ one, two }) =>
      one < 1 &&
      two < 1 &&
      `
      border: none;
    `};

    ${({ one, three }) =>
      one < 1 &&
      three < 1 &&
      `
      border-radius: ${theme.attributes.borderRadius.quaternary};
    `};

    &:hover {
      border: 1px solid ${theme.colors.white};
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }

  .three {
    width: ${({ three }) => `${three < 1 ? 0 : three}%`};
    border-radius: 0 ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0;
    border-top: 1px solid ${theme.baseColors.primary[50]};
    border-right: 1px solid ${theme.baseColors.primary[50]};
    border-bottom: 1px solid ${theme.baseColors.primary[50]};
    background: linear-gradient(180deg, ${theme.baseColors.primary[30]} 0%, rgba(120, 26, 126, 0.4) 100%);
    color: ${theme.colors.white};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ one, two }) =>
      one < 1 &&
      two < 1 &&
      `
      border-radius: ${theme.attributes.borderRadius.quaternary};
    `};

    ${({ three }) =>
      three < 1 &&
      `
      border: none;
    `};

    &:hover {
      border: 1px solid ${theme.colors.white};
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }
`
