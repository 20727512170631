import { Box, Drawer as MuiDrawer } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    background: #141218;
    box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
    min-width: 320px;
    padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
    border-left: 1px solid ${theme.colors.controlBorder};
    overflow: visible;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: ${theme.colors.borderLow};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.textPrimary};
      border-radius: ${theme.attributes.borderRadius.quaternary};
    }

    .wrapper-inner {
      width: 100%;
      margin-bottom: 100px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: ${theme.spacing.half}px;

        .close-icon {
          width: 40px;
          height: 40px;

          &:hover {
            background: rgba(202, 196, 208, 0.12);
          }

          svg {
            path {
              fill: ${theme.colors.white};
            }
          }
        }

        .title {
          font-family: ${theme.typography.fontFamily.primary};
          font-weight: ${theme.typography.fontWeight.normal};
          font-size: ${theme.typography.fontSize.xxLarge};
          line-height: 28px;
          color: ${theme.colors.white};
        }
      }

      .cpi-wrapper {
        display: inline-flex;
        border-radius: 18px;
        background: #debdd9;
        box-shadow: ${theme.attributes.boxShadow.quaternary};
        padding: 2px ${theme.spacing.xSmall}px;
        margin-bottom: ${theme.spacing.medium}px;

        .MuiTypography-root {
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.spacing.medium};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #3f293e;
        }
      }

      .content {
        .select-category-wrapper {
          display: flex;
          align-items: center;
          gap: ${theme.spacing.xSmall}px;
          flex-direction: column;
          margin-bottom: ${theme.spacing.medium}px;

          .select-category-header {
            .MuiTypography-root {
              color: ${theme.colors.white};
              font-family: ${theme.typography.fontFamily.primary};
              font-size: ${theme.typography.fontSize.xLarge};
              font-style: normal;
              font-weight: ${theme.typography.fontWeight.bold};
              line-height: 24px;
              letter-spacing: 0.1px;
            }
          }

          .select-category-container {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: ${theme.spacing.md}px;
            width: 100%;

            .select-category-text {
              min-width: 75px !important;
              overflow: hidden;
              color: ${theme.colors.white};
              text-overflow: ellipsis;
              text-align: left;
              font-family: ${theme.typography.fontFamily.primary};
              font-size: ${theme.typography.fontSize.xLarge};
              font-style: normal;
              font-weight: ${theme.typography.fontWeight.normal};
              line-height: normal;
            }
          }
        }

        .text {
          font-family: ${theme.typography.fontFamily.primary};
          font-weight: ${theme.typography.fontWeight.bold};
          font-size: ${theme.typography.fontSize.xLarge};
          line-height: 24px;
          letter-spacing: 0.1px;
          color: ${theme.colors.white};
        }

        .red-required {
          display: inline-block;
          color: #fe455d;
          font-family: ${theme.typography.fontFamily.primary};
          font-weight: ${theme.typography.fontWeight.bold};
          font-size: ${theme.typography.fontSize.xLarge};
          line-height: 24px;
          letter-spacing: 0.1px;
        }

        .text-sub {
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.spacing.medium};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: 16px;
          letter-spacing: 0.4px;
          color: ${theme.colors.white};
        }

        .also-available-wrapper {
          display: flex;
          align-items: center;
          margin: ${theme.spacing.xSmall}px 0 ${theme.spacing.small}px;

          .also-available-text {
            font-family: ${theme.typography.fontFamily.primary};
            font-weight: ${theme.typography.fontWeight.normal};
            font-size: ${theme.spacing.medium};
            line-height: 16px;
            letter-spacing: 0.4px;
            color: ${theme.colors.neutral};
          }

          .also-available-link {
            font-family: ${theme.typography.fontFamily.primary};
            font-weight: ${theme.typography.fontWeight.normal};
            font-size: ${theme.spacing.medium};
            line-height: 16px;
            letter-spacing: 0.4px;
            color: ${theme.colors.textPrimary};
            cursor: pointer;
            text-decoration: underline;
            text-transform: capitalize;
          }
        }

        .benchmarks-title-wrapper {
          display: flex;
          align-items: center;
          gap: ${theme.spacing.xSmall}px;

          .benchmarks-title {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            margin-top: ${theme.spacing.medium}px;

            .benchmark-header {
              color: ${theme.colors.white};
              font-family: ${theme.typography.fontFamily.primary};
              font-size: ${theme.typography.fontSize.xLarge};
              font-weight: ${theme.typography.fontWeight.bold};
              line-height: 24px;
              letter-spacing: 0.1px;

              span {
                color: #fe455d;
              }
            }

            .benchmark-subheader {
              color: ${theme.colors.white};
              font-family: ${theme.typography.fontFamily.primary};
              font-size: ${theme.spacing.medium};
              font-weight: ${theme.typography.fontWeight.normal};
              line-height: 16px;
              letter-spacing: 0.4px;
            }
          }

          .benchmarks-title-tooltip-wrapper {
            margin-top: ${theme.spacing.medium}px;
          }
        }

        .benchmarks-wrapper {
          margin-top: ${theme.spacing.xSmall}px;
          border-radius: ${theme.attributes.borderRadius.quaternary};
          filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));

          .benchmarks-header {
            border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
            margin-bottom: ${theme.spacing.xSmall}px;
            padding: ${theme.spacing.md}px ${theme.spacing.small}px;
            gap: ${theme.spacing.medium}px;
            height: 56px;
            align-items: center;

            .head-1 {
              font-family: ${theme.typography.fontFamily.primary};
              font-weight: ${theme.typography.fontWeight.bold};
              font-size: ${theme.spacing.medium};
              line-height: 16px;
              letter-spacing: 0.5px;
              color: ${theme.colors.white};
              word-wrap: break-word;
              overflow-wrap: break-word;
              max-width: 80px;
            }

            .head-2 {
              font-family: ${theme.typography.fontFamily.primary};
              font-weight: ${theme.typography.fontWeight.bold};
              font-size: ${theme.spacing.medium};
              line-height: 16px;
              letter-spacing: 0.5px;
              color: ${theme.colors.white};
            }
          }

          .benchmarks-body {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            border-radius: 0 0 ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary};

            .benchmarks-row {
              height: 56px;
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 42px;

              &:first-of-type {
                margin-top: ${theme.spacing.medium}px;
              }

              &:last-of-type {
                border-radius: ${theme.attributes.borderRadius.quaternary};
              }

              .info-tooltip {
                padding-top: ${theme.spacing.xSmall}px;
              }

              .MuiFormControl-root {
                max-width: 238px;

                .all-line {
                  display: flex;
                  align-items: center;
                }

                .MuiInputLabel-root {
                  font-family: ${theme.typography.fontFamily.primary};
                  font-weight: ${theme.typography.fontWeight.normal};
                  color: ${theme.colors.white};
                  font-size: ${theme.typography.fontSize.xLarge};
                  line-height: 24px;
                  letter-spacing: 0.5px;
                }

                .Mui-focused,
                .MuiFormLabel-filled {
                  background: #141218;
                  font-family: ${theme.typography.fontFamily.primary};
                  font-size: ${theme.spacing.medium};
                  font-style: normal;
                  font-weight: ${theme.typography.fontWeight.normal};
                  letter-spacing: 0.4px;
                  color: ${theme.colors.white};
                  padding-left: ${theme.spacing.half}px;
                  padding-right: ${theme.spacing.half}px;
                }

                .MuiOutlinedInput-root {
                  height: 56px;
                  line-height: normal;
                  font-family: ${theme.typography.fontFamily.primary};
                  font-weight: ${theme.typography.fontWeight.normal};
                  font-size: ${theme.typography.fontSize.large};
                  letter-spacing: 0.25px;
                  color: ${theme.colors.white};
                  border: 1px solid ${theme.colors.controlBorder};
                  width: 230px;

                  input {
                    height: 56px;
                    padding-top: 0;
                    padding-bottom: 0;
                    width: 36px;
                  }

                  fieldset {
                    line-height: normal;
                    font-family: ${theme.typography.fontFamily.primary};
                    font-weight: ${theme.typography.fontWeight.normal};
                    font-size: ${theme.typography.fontSize.large};
                    letter-spacing: 0.25px;
                    color: ${theme.colors.white};
                  }
                }

                .fixed-w input {
                  width: 150px !important;
                }

                .MuiFormHelperText-root {
                  font-family: ${theme.typography.fontFamily.primary};
                  font-weight: ${theme.typography.fontWeight.bold};
                  font-size: ${theme.typography.fontSize.mediumSmall};
                  line-height: 16px;
                  letter-spacing: 0.5px;
                  color: #ffb4ab;
                  margin-left: 0;
                }
              }

              .MuiInputAdornment-root {
                .MuiTypography-root {
                  font-family: ${theme.typography.fontFamily.primary};
                  font-size: ${theme.typography.fontSize.xLarge};
                  font-style: normal;
                  font-weight: ${theme.typography.fontWeight.normal};
                  line-height: 24px;
                  letter-spacing: 0.5px;
                  color: #968f92;
                  padding-left: ${theme.spacing.xsm}px;
                }
              }
            }
          }
        }

        .drawer-actions {
          background: ${theme.colors.primaryDarker};
          border-top: 1px solid ${theme.colors.controlBorder};
          padding: ${theme.spacing.small}px ${theme.spacing.medium}px 22px;
          display: flex;
          align-items: center;
          position: fixed;
          bottom: 0;
          width: 320px;
          right: 0;

          .deactivate-button {
            margin-left: ${theme.spacing.xSmall}px;
            background: #ffb4ab;
            border-radius: ${theme.attributes.borderRadius.large} !important;
            font-family: Quicksand, sans-serif !important;
            font-weight: ${theme.typography.fontWeight.bold} !important;
            font-size: ${theme.typography.fontSize.large}14px !important;
            line-height: 20px !important;
            display: flex !important;
            align-items: center !important;
            text-align: center !important;
            letter-spacing: 0.1px !important;
            color: #690005;
            white-space: nowrap;
            min-width: 130px;
          }
        }
      }
    }
  }
`

export const IntegrationItem = styled(Box)<{ active?: boolean }>`
  position: relative;

  img {
    border: 1px solid ${theme.colors.controlBorder};
    border-radius: ${theme.attributes.borderRadius.circle};
    ${({ active }) =>
      active &&
      `
       border: 1px solid ${theme.baseColors.info[20]};
      `};
  }

  span {
    width: 8px;
    height: 8px;
    border-radius: ${theme.attributes.borderRadius.circle};
    display: block;
    background: ${theme.colors.controlBorder};
    position: absolute;
    right: -3px;
    top: 10px;
    ${({ active }) =>
      active &&
      `
        background: ${theme.baseColors.info[20]};
      `};
  }

  .circle-new {
    z-index: 10;
    top: ${theme.spacing.xsm}px;
    right: -7px;
  }
`

export const IntegrationTextItem = styled(Box)<{ active: boolean }>`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .MuiTypography-root {
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .box-wrapper {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xsm}px;
    margin-left: ${theme.spacing.xSmall}px;

    .text {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium} !important;
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-left: 0 !important;
      color: ${theme.colors.controlBorder} !important;

      ${({ active }) =>
        active &&
        `
        color: ${theme.baseColors.info[20]} !important;
      `};
    }

    span {
      width: 8px;
      height: 8px;
      border-radius: ${theme.attributes.borderRadius.circle};
      display: block;
      background: ${theme.colors.controlBorder};

      ${({ active }) =>
        active &&
        `
        background: ${theme.baseColors.info[20]};
      `};
    }
  }
`

export const IntegrationInstanceCard = styled(Box)<{ isFailed?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.xSmall}px;
  background: #30262f;
  box-shadow: ${theme.attributes.boxShadow.feedSection};
  border-radius: ${theme.attributes.borderRadius.quaternary};
  padding: ${theme.spacing.xSmall}px ${theme.spacing.md}px ${theme.spacing.xSmall}px ${theme.spacing.xSmall}px;
  height: 56px;

  img {
    margin-left: ${({ isFailed }) => (isFailed ? '0' : `${theme.spacing.xsm}px`)};
    object-fit: cover;
    min-width: 40px;
  }

  .MuiTypography-root {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.neutral};
    margin-left: ${theme.spacing.xSmall}px;
    word-break: break-all;
  }
`
