import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DeleteInstanceWrapper = styled(Box)`
  background: ${theme.colors.backgroundMedium};
  border-radius: ${theme.attributes.borderRadius.secondary};
  padding: ${theme.spacing.medium}px 24px 18px;

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiFormControl-root {
      margin-bottom: ${theme.spacing.medium}px;
      width: 360px;

      label {
        color: ${theme.baseColors.greyscale[80]};

        .MuiInputLabel-asterisk {
          color: #fe455d;
        }
      }

      .MuiOutlinedInput-root {
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.xLarge};
        line-height: 24px;
        letter-spacing: 0.5px;
        color: ${theme.baseColors.greyscale[80]};
        border-radius: ${theme.attributes.borderRadius.small};

        fieldset {
          border: 1px solid ${theme.colors.controlBorder} !important;
        }
      }
    }
  }
`

export const DeleteInstanceTitle = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.normal};
  font-size: ${theme.typography.fontSize.xxxLarge};
  line-height: 32px;
  color: ${theme.colors.neutral};
  mix-blend-mode: normal;
  margin-bottom: ${theme.spacing.small}px;
`

export const DeleteInstanceSubtitle = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.normal};
  ${theme.typography.fontSize.large};
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${theme.colors.neutral};
  mix-blend-mode: normal;
  margin-bottom: ${theme.spacing.small}px;
`

export const DeleteInstanceActionsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  margin-bottom: ${theme.spacing.xSmall}px;

  .MuiCircularProgress-root {
    color: #690005;
  }

  .MuiButtonBase-root:first-of-type {
    margin-right: ${theme.spacing.xSmall}px;
  }

  .MuiButtonBase-root:last-of-type {
    background: #ffb4ab;
    color: #690005;

    svg {
      path {
        fill: #690005;
      }
    }

    &.Mui-disabled {
      background: rgba(227, 227, 227, 0.12);
      color: ${theme.colors.neutral};

      svg {
        path {
          fill: ${theme.colors.neutral};
        }
      }
    }
  }
`
