import { Box } from '@mui/material'
import { styled as muiStyled } from '@mui/system'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const SearchVendorsContainer = muiStyled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  gap: ${theme.spacing.small}px;
  width: 100%;
  margin-top: 18px;
`

export const AutocompleteContainer = muiStyled('div')`
  position: relative;
  width: 100%;
`

export const SearchVendorsInputWrapper = styled(Box)`
  width: 100%;
  gap: 2px;
  display: flex;
  flex-flow: column wrap;

  .form-label-wrapper {
    .form-label-text {
      overflow: hidden;
      color: ${theme.colors.white};
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }
  }

  & .MuiFormControl-root {
    width: 100%;
    border-radius: ${theme.attributes.borderRadius.small};
    border: none;
    outline: 0;
    height: 40px;

    & .MuiOutlinedInput-root {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
      height: 40px;
      padding-right: 0;

      & .MuiOutlinedInput-input {
        padding: 0 ${theme.spacing.xSmall}px;
        overflow: hidden;
        color: ${theme.colors.white};
        text-overflow: ellipsis;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;

        &::placeholder {
          overflow: hidden;
          color: ${theme.baseColors.secondary[80]};
          opacity: 1;
          text-overflow: ellipsis;
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
        }
      }

      & .end-adornment-close-icon {
        color: ${theme.colors.white};
        padding: ${theme.spacing.half}px;
        margin-right: ${theme.spacing.half}px;

        &:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }

      fieldset {
        border: 1px solid ${theme.colors.controlBorder};

        span {
          font-size: 8.5px;
        }
      }

      &:hover fieldset {
        border-color: ${theme.colors.controlBorder};
      }

      &.Mui-focused {
        border: none;

        & fieldset {
          border: 2px solid ${theme.colors.textPrimary};
        }
      }
    }

    & .search-vendors-loading-indicator {
      color: ${theme.colors.textPrimary};
    }
  }
`

export const ResultListItem = styled('li')<{ iscustomvendor?: string }>`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.md}px;
  cursor: default;
  list-style: none;

  .option-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  &:hover {
    cursor: pointer;
    background: rgba(208, 188, 255, 0.08);
  }

  &[aria-selected='true'] {
    background: rgba(208, 188, 255, 0.08);
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background: rgba(208, 188, 255, 0.08);
  }

  &[aria-selected='true'].Mui-focused,
  &[aria-selected='true'].Mui-focusVisible {
    background: rgba(208, 188, 255, 0.08);
  }

  ${({ iscustomvendor }) =>
    iscustomvendor &&
    iscustomvendor === 'true' &&
    `    
      &:hover {
        cursor: pointer;
        background: rgba(208, 188, 255, 0.08) !important;  
  
        &:last-of-type {
          box-shadow: none;
          background: transparent;
        }
      }
    
      &:last-of-type {
        background: transparent;
        
        &:hover {
          background: rgba(208, 188, 255, 0.08) !important;
        }
      }
    `}

  .vendor-logo-icon {
    margin-right: ${theme.spacing.small}px;
    width: 24px;
    height: 24px;
    object-fit: cover;
    object-position: center;
  }

  .add-custom-loading-wrapper {
    color: ${theme.colors.textPrimary};
  }
`

export const ResultsList = styled('ul')<{ hideborder?: string; length: number }>`
  font-family: ${theme.typography.fontFamily.primary};
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
  background: ${theme.colors.borderLow};
  border-radius: ${theme.attributes.borderRadius.secondary};
  max-height: 250px;

  ${({ hideborder }) =>
    hideborder &&
    hideborder === 'true' &&
    `
      border-radius: 0 0 ${theme.attributes.borderRadius.secondary} ${theme.attributes.borderRadius.secondary};
  `};

  ${({ length }) =>
    length > 5 &&
    `
    overflow-y: scroll;
  `};

  ::-webkit-scrollbar {
    width: 4px;
    background: ${theme.colors.controlBorder};
    border-radius: ${theme.attributes.borderRadius.secondary};
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.secondary};
  }
`

export const SearchVendorsResultsContainer = styled('div')`
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.borderLow};
  box-shadow: ${theme.attributes.boxShadow.chipCircle};
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: ${theme.spacing.xSmall}px;

  .search-vendors-results-text {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
    margin: ${theme.spacing.xSmall}px 0 0 ${theme.spacing.md}px;
  }

  .search-vendors-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 260px;

    .search-vendors-loading-progress {
      color: ${theme.colors.textPrimary};
    }
  }

  .search-vendors-empty-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: ${theme.spacing.half}px;
    padding: 39px ${theme.spacing.small}px ${theme.spacing.small}px ${theme.spacing.small}px;

    .search-vendors-empty-title {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .search-vendors-empty-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`
