import {
  FC,
  useMemo,
  useCallback,
  SyntheticEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
  forwardRef,
  useEffect
} from 'react'
import {
  Box,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment
} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import dayjs from 'dayjs'
import axios from '../../../../lib/axios'
import theme from 'theme'

/* Utils */
import {
  SSMAssetType,
  SSMGroupedData,
  SSMNistPillar,
  SSMResponseProduct,
  SSMResponseProductExtended
} from '../../../../models'
import { useAppDispatch } from '../../../../store/hooks'
import { notification } from '../../../../store/notifications/notificationSlice'
import { DrawerProps } from '../SSM'
import { equalPiecesGen, formatNumberCommas } from '../../../../lib/utils'

/* Components */
import { Accordion, Divider } from './assetAccordion.styles'
import { ThemeButton } from '../../../components/buttons'
import SplitButton from '../../../components/buttons/split/SplitButton'
import SingleDatePicker from '../../../components/date-picker'
import { CostDistribution, getBudgetBreakpoints, updateVendorRecentSearches } from '../securityStackMap.utils'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      valueIsNumericString
    />
  )
})

const expandIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M6 9.5L12 15.5L18 9.5H6Z" fill={theme.colors.textPrimary} />
  </svg>
)

const sortByCostFn = (a: any, b: any) => (b.cost > a.cost ? -1 : 1)

interface Props {
  asset: SSMResponseProduct
  name: string
  expanded: string | false
  handleChange: (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => void
  thisNist: SSMNistPillar | null
  thisAsset: SSMAssetType | null
  fetchAndGroupData: () => void
  updateDrawerProps: (nist: SSMNistPillar, asset: SSMAssetType, assets: SSMResponseProduct[]) => void
  assets: SSMResponseProduct[]
  setConfirmRemoveProps: any
  drawerProps: DrawerProps
  setRemoving: Dispatch<SetStateAction<boolean>>
  productInstances: SSMResponseProductExtended[]
  groupedData: SSMGroupedData
  setGroupedData: Dispatch<SetStateAction<SSMGroupedData>>
  setExpanded: Dispatch<SetStateAction<string | false>>
  product?: SSMResponseProduct
  removing: boolean
  maxCellValue: number
  setBudgetBreakpoints: Dispatch<SetStateAction<number[]>>
  newProductLoading: boolean
  updateCostDistribution: (newValue: CostDistribution, asset: SSMResponseProduct) => void
  setEmptyStateBanner: Dispatch<SetStateAction<boolean>>
}

const AssetAccordion: FC<Props> = ({
  asset,
  thisNist,
  thisAsset,
  name,
  expanded,
  handleChange,
  fetchAndGroupData,
  updateDrawerProps,
  setConfirmRemoveProps,
  assets,
  drawerProps,
  setRemoving,
  productInstances,
  groupedData,
  setGroupedData,
  setExpanded,
  product,
  removing,
  maxCellValue,
  setBudgetBreakpoints,
  newProductLoading,
  updateCostDistribution,
  setEmptyStateBanner
}) => {
  const dispatch = useAppDispatch()
  const inputRef = useRef(null)
  const [anchorElStartDate, setAnchorElStartDate] = useState(null)
  const [anchorElEndDate, setAnchorElEndDate] = useState(null)
  const [openStartDate, setOpenStartDate] = useState(false)
  const [openEndDate, setOpenEndDate] = useState(false)
  const [startDate, setStartDate] = useState(asset.start_date ? dayjs(asset.start_date).format('YYYY/MM/DD') : '')
  const [endDate, setEndDate] = useState(asset.end_date ? dayjs(asset.end_date).format('YYYY/MM/DD') : '')
  const [annualContractValue, setAnnualContractValue] = useState(String(product ? product.cost : asset.cost))
  const [expenseCalculationFields, setExpenseCalculationFields] = useState<any[]>([])
  const [saving, setSaving] = useState(false)
  const [removeType, setRemoveType] = useState<'thisTile' | 'allTiles'>('thisTile')
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [currentAsset, setCurrentAsset] = useState(asset)
  const [initialSetFields, setInitialSetFields] = useState(false)
  const [costDistribution, setCostDistribution] = useState<CostDistribution>(
    product?.is_auto ? CostDistribution.Even : CostDistribution.Manual
  )
  const logoPath =
    asset.name === 'right_hand'
      ? `/icons/square/right_hand_icon.svg`
      : `${process.env.REACT_APP_LOGO_HOST}/${asset.logo_path}`

  const sumNotMatchedWarning = useMemo(() => {
    const acv = parseInt(annualContractValue, 10)
    const fieldsCost = expenseCalculationFields.reduce((acc, obj) => acc + obj.value, 0)

    if (annualContractValue === '' && fieldsCost === 0) return false
    return expenseCalculationFields.length > 1 && acv !== fieldsCost
  }, [annualContractValue, expenseCalculationFields])

  useEffect(() => {
    if (!saving && productInstances.length > 0 && !initialSetFields) {
      const expenseCalculationFieldsRes: any[] = []

      productInstances.forEach((product) => {
        expenseCalculationFieldsRes.push({
          nist: product.nist,
          asset: product.asset,
          name: product.name,
          displayName: product.display_name,
          value: product.cost
        })
      })

      setExpenseCalculationFields(expenseCalculationFieldsRes.sort(sortByCostFn))
      setInitialSetFields(true)
    }
  }, [saving, asset, initialSetFields, sumNotMatchedWarning, productInstances])

  useEffect(() => {
    handleDiscard()

    const savedCostDistribution = product?.is_auto ? CostDistribution.Even : CostDistribution.Manual
    setCostDistribution(savedCostDistribution)
  }, [expanded])

  useEffect(() => {
    if (product) {
      setAnnualContractValue(String(product.cost))
    }
  }, [product, expanded])

  useEffect(() => {
    if (product && productInstances.length <= 1 && !saving && !newProductLoading) {
      const thisAsset = { ...currentAsset }
      setCurrentAsset({
        ...thisAsset,
        cost: product.cost
      })
    } else if (productInstances.length > 1 && !saving && !newProductLoading && asset) {
      const thisAsset = { ...currentAsset }
      setCurrentAsset({
        ...thisAsset,
        cost: asset.cost
      })
    }
  }, [product, productInstances, saving, newProductLoading])

  const handleChangeCalculationFields = (index: number, event: any) => {
    const value = parseInt(event.target.value, 10)

    const values = [...expenseCalculationFields]
    values[index].value = value
    setExpenseCalculationFields(values.sort(sortByCostFn))
  }

  const handleSave = async () => {
    setSaving(true)

    const asset = { ...currentAsset }
    updateCostDistribution(costDistribution, asset)
    const maxFieldsValue = Math.max(...expenseCalculationFields.map((e) => parseInt(e.value, 10)))
    if (maxFieldsValue > maxCellValue) {
      const newBreakpoints = getBudgetBreakpoints(maxFieldsValue)
      setBudgetBreakpoints(newBreakpoints)
    }

    const costGen = equalPiecesGen(expenseCalculationFields.length, parseInt(annualContractValue, 10))

    const costValues = expenseCalculationFields
      .map((n) => {
        return {
          ...n,
          cost: costGen.next().value
        }
      })
      .reverse()

    const cells = expenseCalculationFields.map((field, i) => {
      return {
        nist: field.nist.toLowerCase(),
        asset: field.asset.toLowerCase(),
        cost:
          productInstances.length <= 1
            ? parseInt(annualContractValue, 10)
            : costDistribution === CostDistribution.Even
            ? costValues[i].cost
            : parseInt(field.value, 10)
      }
    })
    const cellsSum = cells.reduce((acc, obj) => acc + obj.cost, 0)
    const payload: any = {
      itemId: asset.uuid,
      startDate,
      endDate,
      cost: parseInt(annualContractValue, 10)
    }
    if (cells.length > 1 && costDistribution === CostDistribution.Manual) {
      payload.cells = cells
    }

    const thisField = expenseCalculationFields.find((n) => n.asset === thisAsset && n.nist === thisNist)
    const newSplitCost = costValues.find((n) => n.asset === thisAsset && n.nist === thisNist)
    asset.cost =
      productInstances.length <= 1
        ? parseInt(annualContractValue, 10)
        : costDistribution === CostDistribution.Even && newSplitCost
        ? parseInt((newSplitCost as any).value, 10)
        : thisField
        ? parseInt(thisField.value, 10)
        : asset.cost
    setCurrentAsset(asset)

    const groupArr: any[] = []
    groupedData.forEach((group) => {
      const filteredItems = group.items.map((item) => {
        if ((item as any).uuid === asset.uuid) {
          const cellItem = cells.filter((n) => n.asset === group.asset && n.nist === group.nist)

          return {
            ...item,
            tilesTotal: cellsSum,
            cost: cellItem.length > 0 ? cellItem[0].cost : item.cost
          }
        }

        return {
          ...item,
          tilesTotal: cellsSum
        }
      })

      groupArr.push({
        ...group,
        items: filteredItems
      })
    })
    setGroupedData(groupArr)

    const itemFromDrawer = drawerProps.assets.find((n) => n.uuid === asset.uuid)

    if (itemFromDrawer) {
      const itemFromDrawerModified: SSMResponseProduct = {
        ...itemFromDrawer,
        cost: parseInt(annualContractValue, 10)
      }
      const newDrawerProps: DrawerProps = {
        ...drawerProps,
        assets: drawerProps.assets.map((n) => (n.uuid === asset.uuid ? itemFromDrawerModified : n))
      }
      updateDrawerProps(
        newDrawerProps.nist as SSMNistPillar,
        newDrawerProps.asset as SSMAssetType,
        newDrawerProps.assets
      )
    }

    dispatch(
      notification({
        open: true,
        variant: 'success',
        message: `${expenseCalculationFields[0].displayName} successfully updated.`
      })
    )

    try {
      const res = await axios.post('/api/v3/ssm/budget', payload)

      if (res.status.toString().includes('20')) {
        await fetchAndGroupData()
      }
    } catch (err) {
      console.error(err)
      await fetchAndGroupData()
      dispatch(
        notification({
          open: true,
          variant: 'error',
          message: `Failed to update ${expenseCalculationFields[0].displayName}.`
        })
      )
    } finally {
      setSaving(false)
    }
  }

  const handleDiscard = () => {
    setAnnualContractValue(String(product?.cost || 0))
    setStartDate(dayjs(asset.start_date || '').format('YYYY/MM/DD'))
    setEndDate(dayjs(asset.end_date || '').format('YYYY/MM/DD'))

    const expenseCalculationFieldsRes: any[] = []
    productInstances.forEach((product) => {
      expenseCalculationFieldsRes.push({
        nist: product.nist,
        asset: product.asset,
        name: product.name,
        displayName: product.display_name,
        value: product.cost
      })
    })
    setExpenseCalculationFields(expenseCalculationFieldsRes.sort(sortByCostFn))

    setCostDistribution(product?.is_auto ? CostDistribution.Even : CostDistribution.Manual)
    setSaveDisabled(true)
  }

  const handleRemove = async () => {
    setRemoving(true)
    const updatedGroupedData = groupedData.map((group) => {
      if (group.nist === thisNist && group.asset === thisAsset) {
        const updatedItems = group.items.filter((n: any) => n.display_name !== asset.display_name)
        updateDrawerProps(thisNist as SSMNistPillar, thisAsset as SSMAssetType, updatedItems as any)
        return {
          ...group,
          items: updatedItems
        }
      }
      return group
    })
    const currentGridItems = updatedGroupedData.map((n) => n.items.map((c) => c)).flat()
    if (!currentGridItems.length) {
      setEmptyStateBanner(true)
    }
    setGroupedData(updatedGroupedData)
    dispatch(
      notification({
        open: true,
        variant: 'success',
        message: `${asset.display_name} was successfully removed from the Security Stack Map`
      })
    )
    setExpanded(false)

    try {
      const res = await axios.delete('/api/v3/ssm', {
        data: {
          itemId: asset.uuid,
          nist: thisNist?.toLowerCase() || '',
          asset: thisAsset?.toLowerCase() || ''
        }
      })

      if (res.status.toString().includes('20')) {
        await fetchAndGroupData()
      }
    } catch (err) {
      console.error(err)
      await fetchAndGroupData()
      dispatch(
        notification({
          open: true,
          variant: 'error',
          message: `Failed to remove ${asset.display_name} from the Security Stack Map`
        })
      )
    } finally {
      setRemoving(false)
    }
  }

  const handleRemoveAll = () => {
    setConfirmRemoveProps({
      asset,
      thisNist,
      thisAsset,
      assets,
      open: true,
      displayName: asset.display_name,
      numOfTiles: productInstances.length
    })
  }

  useEffect(() => {
    if (saving) {
      setSaveDisabled(true)
    }
  }, [saving])

  // const saveDisabled = useMemo(() => {
  //   let fieldsAreChanged = false
  //
  //   if (productInstances.length > 0 && expenseCalculationFields.length > 0) {
  //     const instances = productInstances.map((n) => ({
  //       cost: n.cost,
  //       name: n.name,
  //       asset: n.asset,
  //       nist: n.nist
  //     }))
  //
  //     const booleanFields = expenseCalculationFields.map((n) => {
  //       const instance = instances.find((c) => c.nist === n.nist && c.asset === n.asset)
  //
  //       return !!(instance && instance?.cost === parseInt(n.value, 10))
  //     })
  //
  //     fieldsAreChanged = booleanFields.every((n) => n)
  //   }
  //
  //   const productCost = product ? product.cost : 0
  //
  //   const sum = productInstances.reduce((acc, obj) => acc + obj.cost, 0)
  //   if (
  //     dayjs(startDate).format('YYYY/MM/DD') === dayjs(asset.start_date).format('YYYY/MM/DD') &&
  //     dayjs(endDate).format('YYYY/MM/DD') === dayjs(asset.end_date).format('YYYY/MM/DD') &&
  //     fieldsAreChanged &&
  //     sum === asset.tilesTotal &&
  //     productCost === parseInt(annualContractValue, 10)
  //   ) {
  //     return true
  //   }
  //   if (saving) return true
  //   if (!annualContractValue) return true
  //
  //   return false
  //   // return annualContractValue === '0'
  // }, [startDate, endDate, annualContractValue, asset, productInstances, expenseCalculationFields, product])

  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  const changeSaveDisableStatus = useCallback((disable?: boolean) => {
    setSaveDisabled(Boolean(disable))
  }, [])

  const fieldsTotalCost = useMemo(
    () => expenseCalculationFields.reduce((acc, obj) => (isNaN(acc) ? 0 : acc) + (isNaN(obj.value) ? 0 : obj.value), 0),
    [expenseCalculationFields]
  )

  return (
    <div>
      <Accordion expanded={expanded === name} onChange={handleChange(name)}>
        <AccordionSummary expandIcon={expandIcon} aria-controls="asset-accordion-content" id="asset-accordion-header">
          {!newProductLoading && (!logoPath || logoPath.includes('/N/A') || logoPath.includes('/null')) ? (
            <Box className="missing-vendor-logo">
              {asset.vendor && asset.vendor.display_name ? asset.vendor.display_name[0] : asset.display_name[0]}
            </Box>
          ) : !logoPath || logoPath.includes('/N/A') || logoPath.includes('/null') ? (
            <Box className="missing-vendor-logo">
              {asset.vendor && asset.vendor.display_name ? asset.vendor.display_name[0] : asset.display_name[0]}
            </Box>
          ) : (
            <img className="summary-logo-image" src={logoPath} alt={asset.name} width="40px" height="40px" />
          )}
          <Typography className="asset-name">
            {asset.vendor && asset.vendor.display_name
              ? `${asset.vendor.display_name} ${asset.display_name}`
              : asset.display_name}
          </Typography>
          {sumNotMatchedWarning && <Box className="asset-summary-indicator" />}
          <Box className="asset-summary-space" />
          <Typography className="asset-value">${formatNumberCommas(Math.round(currentAsset.cost))}</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Box className="content-vendor-buttons">
            {asset.vendor ? (
              <>
                <ThemeButton themevariant="secondary" onClick={() => window.open(asset.vendor?.url || '', '_blank')}>
                  <>
                    <OpenInNewIcon />
                    Vendor page
                  </>
                </ThemeButton>
                <ThemeButton themevariant="secondary" onClick={() => window.open(asset.url || '', '_blank')}>
                  <>
                    <OpenInNewIcon />
                    Product page
                  </>
                </ThemeButton>
              </>
            ) : (
              <>
                <ThemeButton themevariant="secondary" onClick={() => window.open(asset.url || '', '_blank')}>
                  <>
                    <OpenInNewIcon />
                    Product page
                  </>
                </ThemeButton>
              </>
            )}
          </Box>
          <Box className="contract-details">
            <Typography className="contract-details-title">License Details</Typography>
            <Typography className="contract-details-text">
              Edit details to see cost distribution in budget view
            </Typography>
          </Box>
          <Box className="inputs-wrapper">
            <Box className="dates-wrapper">
              <SingleDatePicker
                anchorEl={anchorElStartDate}
                handleChange={() => setSaveDisabled(false)}
                setAnchorEl={setAnchorElStartDate}
                inputRef={inputRef}
                open={openStartDate}
                setOpen={setOpenStartDate}
                value={startDate}
                setValue={setStartDate}
                sourceOptions={[]}
                source=""
                title="Start date"
              />
              <SingleDatePicker
                anchorEl={anchorElEndDate}
                handleChange={() => setSaveDisabled(false)}
                setAnchorEl={setAnchorElEndDate}
                inputRef={inputRef}
                open={openEndDate}
                setOpen={setOpenEndDate}
                value={endDate}
                setValue={setEndDate}
                sourceOptions={[]}
                source=""
                title="End date"
                disableFuture={false}
                placement="bottom-end"
                minDate={dayjs(startDate)}
              />
            </Box>
            <FormControl className="form-control" variant="standard">
              <Typography>Annual license cost</Typography>
              <TextField
                value={annualContractValue}
                disabled={saving}
                onChange={(e) => {
                  setAnnualContractValue(e.target.value)
                  setSaveDisabled(false)
                  updateVendorRecentSearches(product?.uuid || asset.uuid, { cost: e.target.value })
                  if (costDistribution === CostDistribution.Even) {
                    const prev = [...expenseCalculationFields]
                    setExpenseCalculationFields(
                      prev
                        .map((n) => ({ ...n, value: parseInt(e.target.value, 10) / productInstances.length }))
                        .sort(sortByCostFn)
                    )
                  }
                }}
                name="numberformat"
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                  autoComplete: 'off',
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  onKeyPress: preventMinus
                }}
              />
            </FormControl>
          </Box>

          {productInstances.length > 1 && (
            <Box className="expense-calculation">
              <Typography className="expense-calculation-title">Cost Distribution</Typography>
              <Typography className="expense-calculation-text">
                The product appears in {productInstances.length} cells. Select the cost distribution below:
              </Typography>
              <FormControl className="expense-radio-form-control">
                <RadioGroup
                  value={costDistribution}
                  onChange={(e) => {
                    setCostDistribution(e.target.value as CostDistribution)
                    setSaveDisabled(false)

                    if (e.target.value === CostDistribution.Even) {
                      const costGen = equalPiecesGen(expenseCalculationFields.length, parseInt(annualContractValue, 10))

                      const costValues = expenseCalculationFields
                        .map((n) => {
                          return {
                            ...n,
                            value: costGen.next().value
                          }
                        })
                        .reverse()

                      const cells = expenseCalculationFields.map((field, i) => {
                        return {
                          nist: field.nist.toLowerCase(),
                          asset: field.asset.toLowerCase(),
                          value: costValues[i].value
                        }
                      })
                      const settingFields = [
                        ...expenseCalculationFields.map((n, i) => ({ ...n, value: cells[i].value }))
                      ]
                      setExpenseCalculationFields(settingFields.sort(sortByCostFn))
                      changeSaveDisableStatus(false)
                    } else {
                      const current = expenseCalculationFields.map((n) => {
                        const res = productInstances.find((c) => c.asset === n.asset && c.nist === n.nist)

                        if (res) {
                          return {
                            ...n,
                            value: res.cost
                          }
                        }

                        return n
                      })
                      setExpenseCalculationFields(current.sort(sortByCostFn))
                    }
                  }}
                  name="expense-calculation-group"
                >
                  <FormControlLabel
                    value="even"
                    control={<Radio disabled={saving} />}
                    label={`Evenly distribute cost across cells ($${
                      annualContractValue
                        ? formatNumberCommas(Math.round(parseInt(annualContractValue, 10) / productInstances.length))
                        : 0
                    } each)`}
                  />
                  <FormControlLabel
                    value="manual"
                    control={<Radio disabled={saving} />}
                    label="Edit each cell’s cost manually"
                  />
                </RadioGroup>
              </FormControl>

              {costDistribution === CostDistribution.Manual && (
                <Box className="edit-calculation-wrapper">
                  {expenseCalculationFields.length > 0 &&
                    expenseCalculationFields.map((field: any, index) => {
                      return (
                        <Box className="edit-calculation-row" key={index}>
                          <Box className="edit-calculation-row-cell">
                            <Typography>
                              {field.nist}/{field.asset}
                            </Typography>{' '}
                            &nbsp;
                            {thisNist === field.nist && thisAsset === field.asset && (
                              <Typography variant="subtitle1" className="this-cell">
                                (current cell)
                              </Typography>
                            )}
                          </Box>
                          <Box className="edit-calculation-row-value">
                            <TextField
                              disabled={saving}
                              value={Math.round(field.value)}
                              onChange={(e) => {
                                setSaveDisabled(false)
                                handleChangeCalculationFields(index, e)
                              }}
                              name={field.name}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                                autoComplete: 'off',
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                onKeyPress: preventMinus
                              }}
                            />
                          </Box>
                        </Box>
                      )
                    })}
                </Box>
              )}
            </Box>
          )}

          {sumNotMatchedWarning && (
            <Box className="sum-not-matched-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M1 21.5H23L12 2.5L1 21.5ZM13 18.5H11V16.5H13V18.5ZM13 14.5H11V10.5H13V14.5Z" fill="black" />
              </svg>
              <Typography>
                The cells' cost total ($
                {formatNumberCommas(fieldsTotalCost)}) doesn't match the license cost
              </Typography>
            </Box>
          )}
          <Divider />
          <Box className="actions-wrapper">
            <Box className="main-action-bar">
              <ThemeButton
                themevariant="primary"
                disabled={saveDisabled}
                onClick={handleSave}
                sx={{ height: '40px !important' }}
              >
                <>
                  <SaveIcon />
                  Save
                </>
              </ThemeButton>
              <ThemeButton
                themevariant="tertiary"
                disabled={saveDisabled}
                onClick={handleDiscard}
                sx={{ height: '40px !important' }}
              >
                <>
                  <CloseIcon />
                  Discard
                </>
              </ThemeButton>
            </Box>
            <SplitButton
              options={[
                {
                  text: 'Remove from this cell only',
                  action: () => {
                    handleRemove()
                    setRemoveType('thisTile')
                  }
                },
                {
                  text: 'Remove from all cells',
                  action: () => {
                    setRemoveType('allTiles')
                    handleRemoveAll()
                  }
                }
              ]}
              activeOption={removeType === 'thisTile' ? 0 : 1}
              onClick={handleRemove}
              buttonText="Remove"
              buttonIcon={<DeleteOutlineIcon />}
              disabled={removing}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AssetAccordion
