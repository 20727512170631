import { Box, Accordion as MuiAccordion } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Accordion = styled(MuiAccordion)`
  border: 1px solid ${theme.colors.borderLow};
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.surfaceItemBackground};
  width: 100%;

  .MuiAccordionSummary-root {
    border-radius: ${theme.attributes.borderRadius.secondary};
    background: ${theme.colors.surfaceItemBackground};

    .summary-logo-image {
      min-width: 40px !important;
      min-height: 40px !important;
      height: 40px !important;
      width: 40px !important;
    }

    &.Mui-expanded {
      border-radius: ${theme.attributes.borderRadius.secondary} ${theme.attributes.borderRadius.secondary} 0 0;
    }

    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.small}px;
      margin-top: ${theme.spacing.small}px;
      margin-bottom: ${theme.spacing.small}px;

      .asset-name {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.header};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: normal;
      }

      .asset-summary-indicator {
        width: 8px !important;
        height: 8px !important;
        min-width: 8px !important;
        min-height: 8px !important;
        border-radius: ${theme.attributes.borderRadius.circle};
        background: ${theme.baseColors.info[30]};
      }

      .missing-vendor-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #c3c3c3;
        border-radius: ${theme.attributes.borderRadius.circle};
        color: ${theme.baseColors.greyscale[0]};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.header};
        font-weight: ${theme.typography.fontWeight.bold};
        min-width: 40px !important;
        min-height: 40px !important;
        height: 40px !important;
        width: 40px !important;
      }

      .asset-summary-space {
        flex-grow: 1;
      }

      .asset-value {
        color: ${theme.colors.textSecondaryLow};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.header};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
        margin-right: ${theme.spacing.small}px;
      }
    }
  }

  .MuiCollapse-wrapper {
    background: ${theme.colors.surfaceItemBackground};
    border-radius: ${theme.attributes.borderRadius.secondary};

    .MuiAccordionDetails-root {
      padding-top: ${theme.spacing.medium}px;

      .content-vendor-buttons {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        gap: ${theme.spacing.small}px;

        .MuiButtonBase-root {
          height: 40px;
          color: ${theme.colors.textPrimary};
          text-align: center;
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.thin};
          line-height: normal;
          padding: ${theme.spacing.xSmall}px ${theme.spacing.medium}px ${theme.spacing.xSmall}px
            ${theme.spacing.small}px;
        }
      }

      .contract-details {
        margin: ${theme.spacing.medium}px 0 ${theme.spacing.small}px 0;
        gap: ${theme.spacing.xSmall}px;

        .contract-details-title {
          color: ${theme.colors.white};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.header};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: normal;
        }

        .contract-details-text {
          color: ${theme.baseColors.secondary[80]};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
        }
      }

      .inputs-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: ${theme.spacing.medium}px;

        .dates-wrapper {
          display: flex;
          align-items: center;
          gap: ${theme.spacing.small}px;
          margin-bottom: ${theme.spacing.small}px;
          flex-basis: 100%;
          width: 100%;
        }

        .form-control {
          gap: ${theme.spacing.half}px;
          width: 100%;

          .MuiOutlinedInput-root {
            height: 40px;
            overflow: hidden;
            color: ${theme.colors.white};
            text-overflow: ellipsis;
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: normal;
            border-radius: ${theme.attributes.borderRadius.small};
            border: 1px solid ${theme.colors.borderLow};
            padding-left: ${theme.spacing.xSmall}px;

            &.Mui-focused > fieldset {
              border-color: ${theme.colors.borderLow};
              border-width: 1px;
            }

            &:hover {
              border-color: ${theme.colors.borderLow};
            }

            input {
              margin-left: -7px;
            }

            &.Mui-disabled {
              input {
                -webkit-text-fill-color: ${theme.colors.white};
              }
            }
          }

          .MuiTypography-root {
            overflow: hidden;
            color: ${theme.colors.white};
            text-overflow: ellipsis;
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: normal;

            &.Mui-focused {
              color: ${theme.colors.white} !important;
            }
          }
        }
      }

      .expense-calculation {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing.xSmall}px;
        margin-bottom: ${theme.spacing.small}px;

        .expense-calculation-title {
          color: ${theme.colors.white};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.header};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: normal;
        }

        .expense-calculation-text {
          color: ${theme.colors.white};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
        }

        .expense-radio-form-control {
          .MuiRadio-root {
            color: ${theme.colors.textPrimary};
          }

          .MuiFormControlLabel-label {
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: normal;
          }
        }

        .edit-calculation-wrapper {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing.small}px;
          margin-top: ${theme.spacing.xSmall}px;

          .edit-calculation-row {
            display: flex;
            gap: ${theme.spacing.small}px;
            align-items: center;
            flex-basis: 100%;
            width: 100%;

            .edit-calculation-row-cell {
              flex-basis: 50%;
              display: flex;
              align-items: center;
              height: 40px;
              border-radius: ${theme.attributes.borderRadius.small};
              background: ${theme.baseColors.surface[50]};
              padding: ${theme.spacing.xSmall}px;
              min-width: 278px;

              .MuiTypography-root {
                white-space: nowrap;
                overflow: hidden;
                color: ${theme.colors.white};
                text-overflow: ellipsis;
                font-family: ${theme.typography.fontFamily.primary};
                font-size: ${theme.typography.fontSize.xLarge};
                font-style: normal;
                font-weight: ${theme.typography.fontWeight.normal};
                line-height: normal;
              }

              .MuiTypography-root.this-cell {
                white-space: nowrap;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                color: ${theme.colors.textPrimary};
                text-overflow: ellipsis;
                font-family: ${theme.typography.fontFamily.primary};
                font-size: ${theme.typography.fontSize.xLarge};
                font-style: normal;
                font-weight: ${theme.typography.fontWeight.normal};
                line-height: normal;
              }
            }

            .edit-calculation-row-value {
              height: 40px;
              flex-basis: 50%;
              width: 100%;

              .MuiFormControl-root {
                width: 100%;
              }

              .MuiInputAdornment-root .MuiTypography-root {
                overflow: hidden;
                color: ${theme.colors.white};
                text-overflow: ellipsis;
                font-family: ${theme.typography.fontFamily.primary};
                font-size: ${theme.typography.fontSize.xLarge};
                font-style: normal;
                font-weight: ${theme.typography.fontWeight.normal};
                line-height: normal;
              }

              .MuiOutlinedInput-root {
                width: 100%;
                height: 40px;
                overflow: hidden;
                color: ${theme.colors.white};
                text-overflow: ellipsis;
                font-family: ${theme.typography.fontFamily.primary};
                font-size: ${theme.typography.fontSize.xLarge};
                font-style: normal;
                font-weight: ${theme.typography.fontWeight.normal};
                line-height: normal;
                border-radius: ${theme.attributes.borderRadius.small};
                border: 1px solid ${theme.colors.borderLow};
                padding-left: ${theme.spacing.xSmall}px;

                &.Mui-focused > fieldset {
                  border-color: ${theme.colors.borderLow};
                  border-width: 1px;
                }

                &.Mui-disabled {
                  input {
                    -webkit-text-fill-color: ${theme.colors.white};
                  }
                }

                &:hover {
                  border-color: ${theme.colors.borderLow};
                }

                input {
                  margin-left: -7px;
                }
              }
            }
          }
        }
      }

      .sum-not-matched-wrapper {
        border-radius: ${theme.attributes.borderRadius.small};
        background: ${theme.baseColors.info[30]};
        width: 100%;
        display: flex;
        align-items: center;
        gap: ${theme.spacing.sm}px;
        padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
        min-height: 40px;
        margin-top: ${theme.spacing.medium}px;
        margin-bottom: ${theme.spacing.medium}px;

        .MuiTypography-root {
          color: ${theme.baseColors.greyscale[10]};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
        }
      }

      .actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
        gap: ${theme.spacing.small}px;
        padding-top: ${theme.spacing.small}px;

        .main-action-bar {
          gap: ${theme.spacing.xSmall}px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`

export const Divider = styled(Box)`
  width: calc(100% - 8px);
  height: 1px;
  background: ${theme.colors.borderLow};
  display: flex;
  justify-self: center;
  margin: 0 auto;
`
