import { FC, useState, useMemo } from 'react'
import { Box, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import theme from 'theme'

/* Utils */
import { CPILibraryItem } from '../../../models'
import useSearchLabel from '../../../hooks/useSearchLabel'

/* Components */
import { SelectDropdownIcon } from '../svg'
import { CategoryMenuProps, NavigationFormControl, NavigationSelect } from './reportsNavigationSelectField.styles'
import SelectSearchbar from '../form-components/SelectSearchbar'
import { LibraryDetailsHoverTooltip } from '../../pages/library/library-details/components/LibraryDetailsTooltip'
import CpiChip from '../cpi-chip'

interface Props extends SelectProps {
  options: CPILibraryItem[]
  value: any
  minWidth?: string
  maxHeight?: string
  noborder?: string
  handleChange: (newValue: string) => void
  formControlSx?: Record<string, unknown>
}

const ReportsNavigationSelectField: FC<Props> = ({
  options,
  placeholder,
  handleChange,
  maxHeight,
  noborder,
  minWidth,
  value,
  formControlSx,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const convertedOptions = useMemo(
    () =>
      options.map((item) => ({
        label: item.display_name,
        name: item.title
      })),
    [options]
  )

  const { search, setSearch, labelOptions, highlightMatch } = useSearchLabel(convertedOptions, null, true)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <NavigationFormControl
      sx={{
        minWidth,
        ...formControlSx
      }}
      isselected={
        anchorEl || (placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')))
          ? 'true'
          : 'false'
      }
    >
      <NavigationSelect
        noborder={noborder}
        isselected={
          placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
        }
        labelId="library-select-label"
        id="library-select"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          PaperProps: {
            ...CategoryMenuProps.PaperProps,
            style: {
              ...CategoryMenuProps.PaperProps.style,
              maxHeight: maxHeight ?? CategoryMenuProps.PaperProps.style.maxHeight
            },
            sx: {
              ...CategoryMenuProps.PaperProps.sx,
              '.MuiListItemText-root .MuiTypography-root': {
                fontFamily: theme.typography.fontFamily.primary,
                fontWeight: theme.typography.fontWeight.bold,
                fontSize: `${theme.typography.fontSize.large} !important`,
                lineHeight: '20px !important',
                letterSpacing: '0.1px',
                color: `${theme.colors.neutral} !important`,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }
            }
          },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null)
        }}
        IconComponent={(props) => (
          <SelectDropdownIcon
            {...props}
            width={24}
            height={24}
            style={{
              transform: anchorEl ? 'rotate(180deg)' : 'none'
            }}
          />
        )}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          let chipText = ''
          const foundOption = labelOptions.length > 0 ? labelOptions.find((n) => n.name === selected) : undefined
          if (foundOption) {
            chipText = foundOption.label
          }

          if (chipText.length > 0 && (selected as string).length > 0) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '3px'
                }}
              >
                <>
                  <CpiChip text={chipText} sx={{ maxWidth: '61px', marginRight: `${theme.spacing.medium}px` }} />
                  <Typography className="text-with-chip">{selected as string}</Typography>
                </>
              </Box>
            )
          } else return selected
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        value={value}
        input={<OutlinedInput onMouseDown={handleClick} />}
        sx={{ minWidth }}
      >
        <SelectSearchbar search={search} setSearch={setSearch} placeholder={`Search ${placeholder}`} />

        {labelOptions.map((option) => (
          <LibraryDetailsHoverTooltip title={option.name} arrow placement="bottom" key={option.label}>
            <MenuItem
              value={option.name}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
                handleChange(option.name)
              }}
            >
              <CpiChip text={option.label} />
              <ListItemText className="list-item" primary={highlightMatch(option.name)} />
            </MenuItem>
          </LibraryDetailsHoverTooltip>
        ))}
      </NavigationSelect>
    </NavigationFormControl>
  )
}

export default ReportsNavigationSelectField
