import theme from 'theme'

const LegendFindingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
    <rect x="1.16667" y="1.89506" width="12.6667" height="12.6667" rx="6.33333" fill={theme.colors.primaryDark} />
    <rect
      x="1.16667"
      y="1.89506"
      width="12.6667"
      height="12.6667"
      rx="6.33333"
      fill={theme.colors.textPrimary}
      fillOpacity="0.11"
    />
    <rect
      x="1.16667"
      y="1.89506"
      width="12.6667"
      height="12.6667"
      rx="6.33333"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="2.33333"
    />
  </svg>
)

export default LegendFindingIcon
