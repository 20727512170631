import { Typography, Drawer as MuiDrawer, Box, IconButton, Button } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    max-width: 523px;
    overflow-x: hidden;

    > .MuiBox-root {
      padding: 0;

      > .MuiButtonBase-root:first-of-type {
        top: 28px;
        right: 25px;
      }
    }

    background-color: ${theme.colors.backgroundDark};
  }
`

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  min-width: 523px;
  background-color: ${theme.colors.backgroundDark};
  overflow-x: hidden;
`

export const FormWrapper = styled(Box)`
  padding-left: 27px;
  margin-top: 12px;
  overflow-x: hidden;
  position: relative;
  min-height: calc(100vh - 104px);
  height: auto;
`

export const InnerForm = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  padding-bottom: 100px;
  overflow-x: hidden;
`

export const Header = styled(Box)`
  padding: ${theme.spacing.medium}px ${theme.spacing.medium}px 0;
`

export const HeaderIconButton = styled(IconButton)`
  position: absolute;
  top: ${theme.spacing.medium}px;
  right: ${theme.spacing.medium}px;
`

export const Title = styled(Typography)`
  text-transform: capitalize;
  font-size: 36px;
  line-height: 44px;
  font-weight: 800;
  color: ${theme.colors.white};
  margin-bottom: ${theme.spacing.half}px;
`

export const Subtitle = styled(Typography)`
  ${theme.typography.fontSize.large};
  line-height: 20px;
  font-weight: 300;
  color: #dbdbdb;
`

export const FinishButton = styled(Button)<{ disabled?: boolean }>`
  position: absolute;
  bottom: ${theme.spacing.xLarge}px;
  right: ${theme.spacing.xLarge}px;
  width: 200px;
  height: 36px;
  color: ${theme.colors.purpleDark};
  background: ${theme.colors.rawSienneCore};
  box-shadow: ${theme.attributes.boxShadow.tertiary};
  border-radius: ${theme.attributes.borderRadius.secondary};
  text-transform: capitalize;
  font-size: ${theme.typography.fontSize.xLarge};
  line-height: 24px;
  font-weight: 700;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: rgba(255, 255, 255, 0.4);
  `};
`

export const inputLabelStyles = {
  color: '#DBDBDB',
  fontSize: theme.typography.fontSize.medium,
  lineHeight: '16px',
  mt: `${theme.spacing.small}px`,
  mb: '2px'
}

export const textFieldStyles = {
  width: '441px',
  height: '32px',
  backgroundColor: theme.colors.white
}

export const textAreaStyles = {
  width: '441px',
  backgroundColor: theme.colors.white,
  border: '1px solid #ACACAC',
  borderRadius: theme.attributes.borderRadius.small,
  fontFamily: '"Rubik", "Source Sans Pro", sans-serif',
  outline: 'none'
}

export const drawerContainer = { width: '100%', minWidth: '523px', backgroundColor: theme.colors.backgroundDark }

export const ViewTicketActionWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 18px;
  left: 40px;
  width: calc(100% - 80px);
`

export const DeleteTicketWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column wrap;
  padding: ${theme.spacing.small}px ${theme.spacing.small}px ${theme.spacing.xSmall}px;
`

export const DeleteTicketButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${theme.spacing.medium}px;

  .MuiButtonBase-root:first-of-type {
    margin-right: ${theme.spacing.xLarge}px !important;
  }

  .MuiButtonBase-root:nth-of-type(2) {
    margin: 0 !important;
  }
`

export const DeleteTicketTitle = styled(Typography)`
  font-size: ${theme.typography.fontSize.xxLarge};
  line-height: 28px;
  font-weight: 800;
  color: #f5f5f5;
`

export const DeleteTicketText = styled(Typography)`
  ${theme.typography.fontSize.large};
  line-height: 22px;
  font-weight: ${theme.typography.fontWeight.normal};
  color: #f5f5f5;
  margin-top: ${theme.spacing.small}px;
`
