import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const PermissionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: theme.attributes.borderRadius.mediumSmall,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.chipCircle,
    width: '105px'
  },
  '.tooltip-wrapper-permissions': {
    display: 'flex',
    alignItems: 'center',
    width: '50px'
  },
  '.tooltip-title': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.xxxLarge,
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '32px',
    marginBottom: '3px'
  },
  '.tooltip-text': {
    color: `${theme.colors.white} !important`,
    fontSize: `${theme.typography.fontSize.medium} !important`,
    fontFamily: "'Quicksand', sans-serif !important",
    lineHeight: '16px !important',
    fontWeight: `${theme.typography.fontWeight.normal} !important`,
    letterSpacing: '0.4px !important',

    '&.nowrap': {
      whiteSpace: 'nowrap'
    }
  }
})
