import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const BenchmarkingTabWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 35px 0;
  width: 100%;
  gap: ${theme.spacing.xLarge}px;
`
