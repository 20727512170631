import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${theme.spacing.large}px;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${theme.spacing.xSmall}px;

    .content-title {
      color: ${theme.colors.white};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;
    }

    .content-description {
      color: ${theme.baseColors.secondary[80]};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }
  }
`
