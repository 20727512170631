import { FC, Ref, Dispatch, useMemo, useState, SetStateAction } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import theme from 'theme'

/* Utils */
import { FrameworkCategoriesForm, FrameworkCategoryWeightItem, FrameworkType } from '../../../../models'

/* Components */
import {
  CategoriesWrapper,
  CategoriesContainer,
  CategoriesSummary,
  SummaryInner,
  CategoriesWeight,
  CategoryWeightList,
  CategorySkeletonWrapper,
  CategorySkeleton
} from './styles/categories.styles'
import { ThemeButton } from '../../../components/buttons'
import { PlusIcon } from '../../../components/svg'
import CategoryWeightItem from './components/category-weight-item'
import TotalMustEqual from './components/TotalMustEqual'
import EvenWeightButton from './components/EvenWeightButton'
import CategoriesDonutChart from './components/CategoriesDonutChart'
import ArrowUpTooltip from '../../../components/svg/ArrowUpTooltip'
import { tooltipClasses } from '@mui/material/Tooltip'
import { FrameworksTooltip } from '../components/FrameworksTooltip'

const colors = [
  theme.baseColors.primary[90],
  theme.colors.textPrimary,
  theme.baseColors.primary[70],
  theme.baseColors.primary[60],
  theme.baseColors.primary[50],
  theme.baseColors.primary[40]
]
const customFrameworkColors = [
  { chipBgColor: 'rgba(255,235,249,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(255,224,250,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(255,203,253,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(255,169,252,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(236,133,236,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(206,107,207,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(176,81,179,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(148,55,152,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(127,40,131,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(112,21,119,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(92,7,99,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(77,0,83,1)', chipColor: 'rgba(255,255,255,1)' }
]
const stubDonutData = Array.from(Array(5).keys()).map((key) => ({
  id: key,
  name: key,
  value: 20,
  color: customFrameworkColors[key].chipBgColor
}))

interface Props {
  categoriesContainerRef: Ref<HTMLDivElement>
  selectedCategoryItem: FrameworkCategoryWeightItem | undefined
  setSelectedCategoryItem: Dispatch<SetStateAction<FrameworkCategoryWeightItem | undefined>>
  handleDeleteCategory: (categoryId: string) => void
  framework: FrameworkType
  categoriesForm: FrameworkCategoriesForm
  setCategoriesForm: Dispatch<SetStateAction<FrameworkCategoriesForm>>
  evenWeights: (categoryId: string, type: 'categories' | 'cpis') => void
  hasEditRole: boolean
  thisPath: string
  initialLoad: boolean
  categoriesTotal: number
  setDiscardDisabled: Dispatch<SetStateAction<boolean>>
  setEdited: Dispatch<SetStateAction<boolean>>
  categoryItemsPristine: FrameworkCategoryWeightItem[]
}

const Categories: FC<Props> = ({
  categoriesContainerRef,
  selectedCategoryItem,
  setSelectedCategoryItem,
  handleDeleteCategory,
  framework,
  categoriesForm,
  setCategoriesForm,
  evenWeights,
  hasEditRole,
  initialLoad,
  categoriesTotal,
  thisPath,
  setDiscardDisabled,
  setEdited,
  categoryItemsPristine
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [theseCategories, setTheseCategories] = useState(categoriesForm.categories)

  const handleAddCategoryItem = () => {
    const formColors = categoriesForm.categories.map((n) => n.chip_bg_color)
    const filteredColors = [...customFrameworkColors.filter((c) => !formColors.includes(c.chipBgColor))]
    const theseCategoriesNamesCustom = categoriesForm.categories.filter((n) => n.name.includes('Category '))
    const theseCategoriesIdsCustom = categoriesForm.categories.filter((n) => n.id.includes('placeholder-id-'))

    let newIndex = 1
    let newIdIndex = 1
    if (theseCategoriesNamesCustom.length > 0) {
      const theseNumbers = theseCategoriesNamesCustom.map((c) => parseInt(c.name.replace('Category ', ''), 10))
      const maxNumber = Math.max(...theseNumbers)

      newIndex = maxNumber + 1
    }
    if (theseCategoriesIdsCustom.length > 0) {
      const theseNumbers = theseCategoriesIdsCustom.map((c) => parseInt(c.id.replace('placeholder-id-', ''), 10))
      const maxNumber = Math.max(...theseNumbers)

      newIdIndex = maxNumber + 1
    }

    const newCategoryName = `Category ${newIndex}`
    const newCategoryId = `placeholder-id-${newIdIndex}`
    const payload = {
      name: newCategoryName,
      cpis: [],
      id: newCategoryId,
      chip_bg_color: filteredColors[0].chipBgColor,
      weight: 0,
      chip_color: filteredColors[0].chipColor,
      isEdit: true
    }
    const newCategories = [payload, ...categoriesForm.categories]

    setEdited(true)
    setDiscardDisabled(false)
    setSelectedCategoryItem(payload)
    setCategoriesForm((pre: any) => ({
      ...pre,
      categories: newCategories
    }))
    setTheseCategories((pre) => [...pre, payload])
  }

  const donutChartData = useMemo(
    () =>
      !initialLoad
        ? stubDonutData
        : categoriesForm.categories
            .filter((n) => n.weight > 0)
            .map((n: any, index: number) => ({
              id: n.id,
              name: n.name,
              value: n.weight,
              color: n.chip_bg_color || colors[index] || colors[0]
            })),
    [initialLoad, categoriesForm]
  )

  const isCategoriesValid = useMemo(() => {
    if (!initialLoad) return true
    if (categoriesForm.categories.every((n) => !n.cpis.length)) return true
    const isValid = true
    let sum = 0

    categoriesForm.categories.forEach((category) => {
      if (category.cpis.length > 0) {
        if (category.weight) {
          sum += +category.weight
        }

        /* Check CPI Weights for category */
        // let cpiSum = 0
        // category.cpis.forEach((cpi) => {
        //   if (cpi.isActive) {
        // cpiSum += cpi.weight
        // }
        // })
        // if (category.cpis.every((n) => !n.isActive)) cpiSum = 100

        // if (cpiSum !== 100) isValid = false
      }
    })

    return isValid && sum === 100
  }, [initialLoad, categoriesForm])

  const totalValue = useMemo(() => (initialLoad ? categoriesTotal : 100), [categoriesTotal, initialLoad])

  const someCpisHaveNoWeight = categoriesForm.categories
    .map((n) => !n.cpis.map((cpi) => cpi.isActive))
    .flat()
    .some((n) => !!n)

  const noCategoriesHaveCpis = categoriesForm.categories.every((cat) => !cat.cpis.length)

  const remainingHeight = window.innerHeight - 496
  const categoriesHeightTotal = categoriesForm.categories.length * 88
  const hasScrollbar = remainingHeight < categoriesHeightTotal + 40

  return (
    <CategoriesWrapper ref={categoriesContainerRef} className="categories-section-wrapper">
      <CategoriesContainer className="categories-container">
        <CategoriesSummary className="categories-summary-wrapper">
          <Box className="categories-summary-header">
            <Typography className="header-text">Categories</Typography>
            {hasEditRole && framework === FrameworkType.Custom && (
              <>
                {!initialLoad || categoriesForm.categories.length >= 12 ? (
                  <FrameworksTooltip
                    title={
                      <>
                        <Box className="tooltip-wrapper">
                          <ArrowUpTooltip />
                          <Typography className="tooltip-text">Maximum 12 categories reached</Typography>
                        </Box>
                      </>
                    }
                    sx={{
                      [`& .${tooltipClasses.tooltip}`]: {
                        width: 'fit-content !important'
                      }
                    }}
                  >
                    <ThemeButton
                      themevariant="tertiary"
                      onClick={handleAddCategoryItem}
                      sx={{ pointerEvents: 'unset !important' }}
                      disabled
                    >
                      <>
                        <PlusIcon />
                        Add Category
                      </>
                    </ThemeButton>
                  </FrameworksTooltip>
                ) : (
                  <ThemeButton themevariant="tertiary" onClick={handleAddCategoryItem} disabled={false}>
                    <>
                      <PlusIcon />
                      Add Category
                    </>
                  </ThemeButton>
                )}
              </>
            )}
          </Box>
          <SummaryInner className="summary-inner">
            <Box className="inner-content">
              <Typography className="inner-text">Weight Distribution</Typography>

              {hasEditRole && (
                <Box className="inner-content-actions">
                  <TotalMustEqual type={isCategoriesValid ? 'info' : 'error'} />
                  <EvenWeightButton
                    onClick={() => evenWeights(selectedCategoryItem?.id || '', 'categories')}
                    disabled={noCategoriesHaveCpis}
                  />
                </Box>
              )}
            </Box>
            <CategoriesDonutChart
              selectedCategoryItem={selectedCategoryItem}
              setSelectedCategoryItem={setSelectedCategoryItem}
              colors={!initialLoad ? colors : categoriesForm.categories.map((category) => category.chip_bg_color)}
              data={donutChartData}
              totalValue={totalValue}
              framework={framework}
              initialLoad={initialLoad}
            />
          </SummaryInner>
        </CategoriesSummary>
      </CategoriesContainer>
      <CategoriesWeight className="categories-weight-wrapper" haseditrole={hasEditRole ? 'true' : 'false'}>
        <Box className="categories-weight-header">
          <Typography>Category</Typography>
          <Typography
            sx={
              !initialLoad
                ? { mr: '68px !important' }
                : !framework.includes('custom') || someCpisHaveNoWeight || noCategoriesHaveCpis
                ? { mr: '14px !important' }
                : undefined
            }
          >
            Weight
          </Typography>
        </Box>

        {initialLoad && categoriesForm.categories.length > 0 ? (
          <CategoryWeightList className={`category-weight-list ${hasScrollbar && 'has-scrollbar'}`}>
            {categoriesForm.categories.map((cat) => {
              const weights = cat.cpis.map((n) => n.weight).filter((n) => !isNaN(n))
              const sumWeights = weights.reduce((acc, obj) => acc + obj, 0)

              return (
                <CategoryWeightItem
                  key={cat.id}
                  categories={categoriesForm.categories}
                  categoriesForm={categoriesForm}
                  setCategoriesForm={setCategoriesForm}
                  categoryItem={cat}
                  selected={selectedCategoryItem?.id === cat.id}
                  setSelectedCategoryItem={setSelectedCategoryItem}
                  handleDeleteCategory={handleDeleteCategory}
                  framework={framework}
                  hasEditRole={hasEditRole}
                  thisPath={thisPath}
                  setDiscardDisabled={setDiscardDisabled}
                  cpisInvalidWeight={sumWeights !== 100 && cat.cpis.some((n) => n.isActive)}
                  categoryItemsPristine={categoryItemsPristine}
                  setEdited={setEdited}
                />
              )
            })}
          </CategoryWeightList>
        ) : (
          <CategorySkeletonWrapper className="category-skeleton-wrapper">
            {Array.from(Array(4).keys()).map((key) => (
              <CategorySkeleton key={key}>
                <Skeleton
                  variant="rectangular"
                  component="div"
                  animation="wave"
                  width="80%"
                  height="100%"
                  className="card-skeleton-inner"
                />
                <Skeleton
                  variant="rectangular"
                  component="div"
                  animation="wave"
                  width="20%"
                  height="100%"
                  className="card-skeleton-inner"
                />
              </CategorySkeleton>
            ))}
          </CategorySkeletonWrapper>
        )}
      </CategoriesWeight>
    </CategoriesWrapper>
  )
}

export default Categories
