import { FC, Dispatch, SetStateAction, useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'
import theme from 'theme'

/* Utils */
import {
  ChartData,
  CompetitorAnalysisData,
  CPIFrequency,
  CPILibraryItem,
  CPIManifest,
  CPISeverity,
  CPITimePeriod,
  DetailedComparisonData,
  TrendsDataPayload
} from '../../../../../../models'

/* Components */
import { BenchmarkingTabWrapper } from './benchmarkingTab.styles'
import CompetitorAnalysis from './components/CompetitorAnalysis'
import DetailedComparison from './components/DetailedComparison'
import axios from '../../../../../../lib/axios'
import { getPayloadTimePeriod } from '../../../utils'

interface Props {
  active?: boolean
  sla?: number
  unit: string
  cpi: CPILibraryItem | null
  benchmarkingTabLoading: boolean
  showMessage: boolean
  healthDirection: string
  competitorAnalysisData: CompetitorAnalysisData | null
  detailedComparisonData: DetailedComparisonData | null
  setCriticalSla: Dispatch<SetStateAction<number>>
  selectedSeverity: CPISeverity
  selectedDataSourceIds: string[]
  selectedFrequency: CPIFrequency
  selectedTimePeriod: CPITimePeriod
  manifestData: CPIManifest
}

const BenchmarkingTab: FC<Props> = ({
  active,
  sla,
  unit,
  benchmarkingTabLoading,
  competitorAnalysisData,
  detailedComparisonData,
  healthDirection,
  showMessage,
  cpi,
  setCriticalSla,
  selectedSeverity,
  selectedDataSourceIds,
  selectedFrequency,
  selectedTimePeriod,
  manifestData
}) => {
  const location = useLocation()

  const fetchSla = async () => {
    if (!cpi) return

    const timePeriod = getPayloadTimePeriod(selectedTimePeriod)
    const payload: TrendsDataPayload = {
      section: selectedSeverity.toLowerCase() as any,
      integration_ids: selectedDataSourceIds[0] === '' ? [''] : selectedDataSourceIds, // configured integration id
      frequency: selectedFrequency,
      timePeriod
    }

    let severities: any = []
    const userInput = manifestData.ui.trend_chart.user_input
    if (userInput.formula_params[0]?.sections.length > 0) {
      severities = userInput.formula_params[0]?.sections
    } else if (userInput.visualization_param.sections.length > 1) {
      severities = userInput.visualization_param.sections
    }

    const newSection = severities.find((item: { display_name: CPISeverity }) => item.display_name === payload.section)
    if (newSection) payload.section = newSection.name

    try {
      const res = await axios.post(`/api/v3/cpis/${cpi?.name}/data`, payload)

      if (res.status === 201) {
        const { data }: { data: ChartData } = res.data
        setCriticalSla(data.sla)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (cpi && manifestData) {
      fetchSla().catch((e) => e)
    }
  }, [cpi, manifestData, location])

  if (benchmarkingTabLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          marginTop: `${theme.spacing.xLarge}px`
        }}
      >
        <CircularProgress size="36px" />
      </Box>
    )
  }
  return (
    <BenchmarkingTabWrapper>
      {competitorAnalysisData && (
        <CompetitorAnalysis
          data={competitorAnalysisData}
          sla={sla}
          unit={unit}
          active={Boolean(active)}
          healthDirection={healthDirection}
          showMessage={showMessage}
        />
      )}
      {active && detailedComparisonData && (
        <DetailedComparison data={detailedComparisonData} showMessage={showMessage} />
      )}
    </BenchmarkingTabWrapper>
  )
}

export default BenchmarkingTab
