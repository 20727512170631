import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.medium}px;
  flex-basis: 50%;
  width: 100%;
`

export const DateRangePickerContainer = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${theme.spacing.half}px;
  width: 100%;

  .label-text {
    overflow: hidden;
    color: ${theme.colors.white};
    text-overflow: ellipsis;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }

  .text-field {
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.controlBorder};
    display: flex;
    height: 40px;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
    align-self: stretch;
    overflow: hidden;
    width: 100%;
    padding: 0;

    .MuiOutlinedInput-root {
      width: 100%;
      height: 40px;
      overflow: hidden;
      color: ${theme.colors.white};
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      padding-right: ${theme.spacing.xSmall}px;
      flex-grow: 1;

      input {
        width: 100%;
        padding-left: ${theme.spacing.xSmall}px;
      }

      .calendar-icon {
        cursor: pointer;
      }
    }

    fieldset {
      border: none;
    }
  }

  .calendar-container {
    background: ${theme.colors.backgroundMedium};
    box-shadow: ${theme.attributes.boxShadow.primary};
    border-bottom-left-radius: ${theme.attributes.borderRadius.small};
    border-bottom-right-radius: ${theme.attributes.borderRadius.small};
    z-index: 1000000000;
    top: 80px;
    position: absolute;
  }

  .calendar-header {
    background: ${theme.colors.backgroundMedium};
    box-shadow: ${theme.attributes.boxShadow.secondary};
    border-radius: ${theme.attributes.borderRadius.small} ${theme.attributes.borderRadius.small} 0 0;

    .calendar-header__inner {
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 679px;
      padding: ${theme.spacing.small}px ${theme.spacing.medium}px;

      fieldset {
        border: 1px solid ${theme.colors.controlBorder};
        border-radius: ${theme.attributes.borderRadius.small};
      }

      input {
        font-family: ${theme.typography.fontFamily.primary};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.xLarge};
        line-height: 24px;
        letter-spacing: 0.5px;
        color: ${theme.baseColors.greyscale[80]};
        -webkit-text-fill-color: unset;
      }

      label {
        font-family: ${theme.typography.fontFamily.primary};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.medium};
        line-height: 23px;
        letter-spacing: 0.4px;
        color: ${theme.baseColors.greyscale[80]};
      }
    }
  }

  .calendar-content {
    display: flex;
    align-items: flex-start;

    .MuiPickerStaticWrapper-content {
      width: 660px;
      border-radius: unset;
      background: transparent;

      .MuiDateRangePickerViewDesktop-container {
        width: 330px;

        /* Month Selector */
        .MuiPickersArrowSwitcher-root {
          button svg {
            fill: ${theme.baseColors.greyscale[80]};
          }

          .MuiTypography-root {
            font-family: ${theme.typography.fontFamily.primary};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            font-size: ${theme.typography.fontSize.large};
            line-height: 20px;
            letter-spacing: 0.1px;
            color: ${theme.baseColors.greyscale[80]};
          }
        }

        &.MuiDateRangePickerViewDesktop-container {
          border-right: 1px solid #444746;
        }

        .MuiDayPicker-weekContainer {
          .MuiDateRangePickerDay-root:first-of-type:not(.MuiDateRangePickerDay-rangeIntervalDayHighlightStart, .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd),
          .MuiDateRangePickerDay-root:last-of-type {
            border-radius: 0 !important;
          }
        }
      }
    }

    .MuiDateRangePickerDay-root {
      .MuiButtonBase-root.MuiPickersDay-root {
        font-family: ${theme.typography.fontFamily.primary};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.large};
        line-height: 20px;
        letter-spacing: 0.25px;
        color: ${theme.colors.neutral};

        &.MuiPickersDay-dayOutsideMonth {
          color: ${theme.colors.borderLow};
        }
      }

      &.MuiDateRangePickerDay-rangeIntervalDayHighlight {
        background: ${theme.colors.primaryLight};

        .MuiButtonBase-root.MuiPickersDay-root {
          color: ${theme.baseColors.greyscale[80]};
        }

        &.MuiDateRangePickerDay-rangeIntervalDayHighlightStart,
        &.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
          .MuiDateRangePickerDay-rangeIntervalPreview {
            .MuiButtonBase-root.MuiPickersDay-root {
              background: ${theme.colors.textPrimary};
              color: ${theme.baseColors.primary[20]};

              &.MuiPickersDay-today {
                border: 1px solid ${theme.colors.textPrimary};
                color: ${theme.colors.textPrimary};
              }
            }
          }
        }
      }

      .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
        border: 1px solid ${theme.colors.textPrimary};
      }
    }

    .calendar-picker-wrapper {
      width: 140px;
    }

    .range-selector-wrapper {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      margin-top: 5px;

      & > .MuiButtonBase-root {
        padding: ${theme.spacing.half}px ${theme.spacing.medium}px ${theme.spacing.half}px ${theme.spacing.small}px;
        width: 100%;
        height: 48px;
        background: transparent;
        font-family: ${theme.typography.fontFamily.primary};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.xLarge};
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: none;
        justify-content: flex-start;
        color: #e6e1e5;
      }
    }
  }

  .calendar-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 9px ${theme.spacing.md}px 11px 0;
    border-top: 1px solid #444746;

    & > .MuiButtonBase-root {
      min-width: 69px;
      min-height: 40px;
      border-radius: ${theme.attributes.borderRadius.large};
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: ${theme.spacing.xSmall}px;
      font-family: ${theme.typography.fontFamily.primary};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.textPrimary};
      text-transform: none;

      &:first-of-type {
        margin-right: ${theme.spacing.xSmall}px;
      }

      &:nth-of-type(2) {
        width: 45px;
        min-width: unset;
      }
    }
  }
`

export const dateRangePickerPopperSx = {
  '.MuiPaper-root': {
    display: 'flex',
    background: 'transparent',

    '@media (max-height: 800px)': {
      '.MuiDateCalendar-root': {
        height: '280px !important',
        maxHeight: '280px !important'
      }
    },

    '.MuiPickersToolbar-root': {
      display: 'none'
    },

    '.MuiPickersLayout-root': {
      borderRadius: theme.attributes.borderRadius.secondary,
      background: theme.colors.primaryLight,
      display: 'flex',
      flexDirection: 'column',

      '.MuiPickersYear-root button': {
        background: 'transparent',
        color: theme.baseColors.greyscale[80],

        '&[aria-checked="true"]': {
          background: theme.colors.textPrimary,
          color: '#66006E'
        },

        '&:disabled': {
          color: theme.colors.controlBorder
        }
      },
      '.MuiYearCalendar-root': {
        '::-webkit-scrollbar': {
          width: '6px !important',
          background: theme.colors.controlBorder
        },

        '::-webkit-scrollbar-track': {
          width: '6px !important',
          background: theme.colors.controlBorder,
          borderRadius: theme.attributes.borderRadius.small
        },

        '::-webkit-scrollbar-thumb': {
          width: '6px !important',
          background: theme.colors.textPrimary,
          borderRadius: theme.attributes.borderRadius.mediumSmall
        }
      },
      '.MuiPickersCalendarHeader-root': {
        '.MuiPickersCalendarHeader-switchViewIcon': {
          color: theme.colors.textPrimary
        },
        '.MuiPickersCalendarHeader-labelContainer': {
          '.MuiPickersCalendarHeader-label': {
            color: theme.baseColors.greyscale[80],
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily.primary,
            fontSize: theme.typography.fontSize.large,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeight.bold,
            lineHeight: '20px',
            letterSpacing: '0.1px'
          }
        },

        '.MuiPickersArrowSwitcher-root': {
          '.MuiButtonBase-root': {
            color: theme.baseColors.greyscale[80],

            '&.Mui-disabled': {
              opacity: 0.26
            }
          }
        }
      },

      '@media (max-height: 800px)': {
        '.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition': {
          minHeight: '192px !important'
        }
      },

      '.MuiDayCalendar-root': {
        '.MuiDayCalendar-header': {
          '.MuiTypography-root': {
            color: theme.colors.neutral,
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily.primary,
            fontSize: theme.typography.fontSize.medium,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeight.bold,
            lineHeight: '16px',
            letterSpacing: '0.5px'
          }
        },

        '.MuiDayCalendar-monthContainer': {
          '.MuiDayCalendar-weekContainer': {
            '.MuiDateRangePickerDay-hiddenDayFiller': {
              background: 'transparent !important'
            }
          },

          '.MuiDateRangePickerDay-root': {
            '.MuiButtonBase-root': {
              color: theme.colors.neutral,
              textAlign: 'center',
              fontFamily: theme.typography.fontFamily.primary,
              fontSize: theme.typography.fontSize.large,
              fontStyle: 'normal',
              fontWeight: theme.typography.fontWeight.normal,
              lineHeight: '20px',
              letterSpacing: '0.25px',

              '&.Mui-selected': {
                borderRadius: theme.attributes.borderRadius.circle,
                color: theme.baseColors.primary[20],
                background: theme.colors.textPrimary
              },

              '&.Mui-disabled': {
                color: theme.baseColors.greyscale[80],
                opacity: 0.38
              }
            },

            '&.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              background: 'rgba(106, 83, 113, 1)'
            }
          }
        }
      },

      '.MuiDialogActions-root': {
        '.MuiButton-root': {
          color: theme.colors.textPrimary,
          textAlign: 'center',
          fontFamily: theme.typography.fontFamily.primary,
          fontSize: theme.typography.fontSize.large,
          fontStyle: 'normal',
          fontWeight: theme.typography.fontWeight.bold,
          lineHeight: '20px',
          letterSpacing: '0.1px',

          '&:first-of-type': {
            textTransform: 'capitalize'
          }
        }
      }
    }
  },

  '.MuiPaper-root.MuiPickersPopper-paper': {
    borderRadius: theme.attributes.borderRadius.quaternary,
    background: theme.colors.primaryLight,

    '.MuiPickersArrowSwitcher-root': {
      '.MuiButtonBase-root': {
        color: theme.baseColors.greyscale[80]
      }
    },

    '.MuiPickersCalendarHeader-label': {
      color: theme.baseColors.greyscale[80],
      fontFamily: theme.typography.fontFamily.primary,
      fontSize: theme.typography.fontSize.large,
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeight.bold,
      lineHeight: '20px',
      letterSpacing: '0.1px'
    },

    '.MuiDayCalendar-header': {
      '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel': {
        color: theme.baseColors.greyscale[80],
        textAlign: 'center',
        fontFamily: theme.typography.fontFamily.primary,
        fontSize: theme.typography.fontSize.xLarge,
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeight.bold,
        lineHeight: '24px',
        letterSpacing: '0.1px'
      }
    },

    '.MuiButtonBase-root.MuiPickersDay-root': {
      color: theme.baseColors.greyscale[80],
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily.primary,
      fontSize: theme.typography.fontSize.xLarge,
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeight.bold,
      lineHeight: '24px',
      letterSpacing: '0.1px',

      '&.Mui-selected': {
        background: theme.colors.textPrimary,
        color: '#66006E'
      },
      '&:not(.Mui-selected)': {
        '&:hover': {
          background: theme.colors.textSecondaryLow,
          borderColor: theme.colors.textSecondaryLow
        }
      },
      '&.MuiPickersDay-today': {
        background: 'transparent',
        border: `1px solid ${theme.colors.textPrimary}`,
        color: theme.colors.textPrimary
      },
      '&.Mui-disabled': {
        color: theme.colors.controlBorder
      }
    },

    '.MuiButton-root': {
      color: theme.colors.textPrimary,
      fontFamily: theme.typography.fontFamily.primary,
      fontSize: theme.typography.fontSize.large,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      margin: 0
    }
  }
}
