import { FC } from 'react'
import { Box, SelectChangeEvent, Typography, useMediaQuery } from '@mui/material'

/* Utils */
import { CPITimePeriod, HistoryBenchmarkData, HistoryBenchmarkDataValues } from '../../../../../../../models'

/* Components */
import BenchmarkingSelectField from '../../../../../dashboard/components/Benchmarking/components/BenchmarkingSelectField'
import { CategoryPerformanceScoreWrapper } from '../../../categoryPage.styles'
import BenchmarkingScoreHistoryChart from './BenchmarkingScoreHistoryChart'

interface Props {
  historyBenchmarkData: HistoryBenchmarkData[]
  selectedBenchmark: string
  handleCompareToChange: (e: SelectChangeEvent<string>) => void
  historyBenchmarkValues: HistoryBenchmarkDataValues[]
  selectedTimePeriod: CPITimePeriod
  issolo?: string
  lastUpdate: string
}

const BenchmarkingScoreHistory: FC<Props> = ({
  historyBenchmarkData,
  selectedBenchmark,
  handleCompareToChange,
  historyBenchmarkValues,
  selectedTimePeriod,
  issolo,
  lastUpdate
}) => {
  const singleItemOnScreen = useMediaQuery('(max-width:1440px)')

  if (singleItemOnScreen) {
    return (
      <CategoryPerformanceScoreWrapper issolo={issolo}>
        <Box className="header-block">
          <Typography className="title">Benchmarking Score</Typography>
          <Typography className="date">Last Update: {lastUpdate}</Typography>
        </Box>
        <Box className="single-item-header-wrapper">
          <Box className="chips-wrapper" sx={{ margin: '0 !important' }}>
            <Box className="chip">
              <Box className="chip-circle less" /> <Typography className="chip-sla">Less Strict SLA</Typography>
            </Box>
            <Box className="chip">
              <Box className="chip-circle same" /> <Typography className="chip-sla">Same SLA</Typography>
            </Box>
            <Box className="chip">
              <Box className="chip-circle more" /> <Typography className="chip-sla">More Strict SLA</Typography>
            </Box>
          </Box>

          {historyBenchmarkData.length > 0 && (
            <Box
              className="select-wrapper"
              sx={{
                '.MuiFormControl-root': {
                  marginBottom: '0 !important'
                }
              }}
            >
              <BenchmarkingSelectField
                options={historyBenchmarkData.map((item) => item.industry_name)}
                value={selectedBenchmark}
                handleChange={handleCompareToChange}
                compareText="Compare to"
              />
            </Box>
          )}
        </Box>
        {historyBenchmarkValues.length > 0 && (
          <BenchmarkingScoreHistoryChart data={historyBenchmarkValues} selectedTimePeriod={selectedTimePeriod} />
        )}
      </CategoryPerformanceScoreWrapper>
    )
  }
  return (
    <CategoryPerformanceScoreWrapper issolo={issolo}>
      <Box className="header-block">
        <Typography className="title">Benchmarking Score</Typography>
        <Typography className="date">Last Update: {lastUpdate}</Typography>
      </Box>
      <Box className="chips-wrapper">
        <Box className="chip">
          <Box className="chip-circle less" /> <Typography className="chip-sla">Less Strict SLA</Typography>
        </Box>
        <Box className="chip">
          <Box className="chip-circle same" /> <Typography className="chip-sla">Same SLA</Typography>
        </Box>
        <Box className="chip">
          <Box className="chip-circle more" /> <Typography className="chip-sla">More Strict SLA</Typography>
        </Box>
      </Box>

      {historyBenchmarkData.length > 0 && (
        <Box
          className="select-wrapper"
          sx={{
            '.MuiFormControl-root': {
              marginBottom: '32px !important'
            }
          }}
        >
          <BenchmarkingSelectField
            options={historyBenchmarkData.map((item) => item.industry_name)}
            value={selectedBenchmark}
            handleChange={handleCompareToChange}
            compareText="Compare to"
          />
        </Box>
      )}
      {historyBenchmarkValues.length > 0 && (
        <BenchmarkingScoreHistoryChart data={historyBenchmarkValues} selectedTimePeriod={selectedTimePeriod} />
      )}
    </CategoryPerformanceScoreWrapper>
  )
}

export default BenchmarkingScoreHistory
