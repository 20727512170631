import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs, Button, Switch, Tabs as MuiTabs } from '@mui/material'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
`

export const AccountManagementHeaderContainer = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 100;

  .MuiTab-root {
    text-transform: capitalize;
    padding-bottom: ${theme.spacing.xSmall}px;
  }

  .breadcrumbs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 100%;
    padding: ${theme.spacing.small}px 0 0 ${theme.spacing.medium}px;

    .header-actions {
      display: flex;
      align-items: center;

      .favorite-icon {
        margin-right: 34px;

        .MuiButtonBase-root {
          &:hover,
          &:focus,
          &:active {
            background: rgba(202, 196, 208, 0.08);
          }
        }
      }
    }
  }

  .title-wrapper {
    padding-left: ${theme.spacing.medium}px;

    &.title-wrapper {
      padding: 0 0 0 ${theme.spacing.medium}px;

      .header-title {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.epic};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 40px;
        text-transform: capitalize;
        margin-top: 21px;
        margin-bottom: 0;
      }

      .header-subtitle {
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 20px;
        letter-spacing: 0.25px;
        color: ${theme.colors.neutral};
      }
    }
  }
`

export const HeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/category-page-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: ${theme.attributes.boxShadow.quaternary};
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  mix-blend-mode: luminosity;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .category-breadcrumb {
    color: #debdd9;
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .current-breadcrumb {
    color: ${theme.colors.textPrimary};
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`

export const Tabs = styled(MuiTabs)`
  .MuiTabs-indicator {
    background-color: ${theme.colors.textPrimary};
  }

  .MuiTab-root {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.baseColors.secondary[60]};
    text-transform: capitalize;
  }

  .MuiTab-root.Mui-selected {
    color: ${theme.colors.textPrimary};
    border-bottom: 2px solid ${theme.colors.textPrimary};
  }

  .MuiButtonBase-root {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    margin-right: ${theme.spacing.xLarge}px;
  }
`

export const Tab = styled(Button)<{ active: boolean }>`
  width: 50%;
  max-width: unset;
  min-width: unset;
  height: 64px;
  box-sizing: border-box;
  text-align: left;
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0;

  .MuiTypography-root {
    padding-left: ${theme.spacing.xLarge}px;
    text-transform: none;
    font-family: ${theme.typography.fontFamily.primary};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xxLarge};
    line-height: 28px;

    /* Second row of title */
    &:nth-of-type(2) {
      color: ${({ active }) => (active ? theme.baseColors.greyscale[80] : '&7c7579')};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.medium};
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  svg {
    position: absolute;
    top: ${theme.spacing.sm}px;
    left: ${theme.spacing.sm}px;
  }

  &:first-of-type {
    border-bottom: 2px solid ${theme.colors.textPrimary};

    .MuiTypography-root {
      color: ${theme.colors.neutral};
    }

    svg {
      path {
        fill: ${theme.colors.textPrimary};
      }
    }
  }

  &:last-of-type {
    border-bottom: ${({ active }) =>
      active ? `2px solid ${theme.colors.textPrimary}` : `2px solid ${theme.colors.borderLow}`};

    .MuiTypography-root {
      color: ${({ active }) => (active ? theme.colors.neutral : '#7c7579')};
    }

    svg {
      path {
        fill: ${({ active }) => (active ? theme.colors.textPrimary : '#7c7579')};
      }
    }
  }
`

export const EditFixedPanel = styled(Box)`
  margin: 0 auto ${theme.spacing.large}px 0;
  display: flex;
  justify-content: flex-end;
  width: 555px;

  .panel-block {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;

    .text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }
`

export const LockIconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};
  width: 40px;
  height: 40px;
`

export const UnlockIconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.large};
  background: ${theme.colors.neutral};
  width: 40px;
  height: 40px;
`

export const ContentWrapperMain = styled(Box)`
  margin: ${theme.spacing.large}px auto;

  .loading-wrapper {
    display: flex;
    margin-top: ${theme.spacing.xLarge}px;
    justify-content: center;
  }
`

export const MaterialUISwitch = styled(Switch)(() => ({
  width: 79,
  height: 40,
  margin: 0,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(3px)',

    '&.Mui-checked': {
      color: theme.colors.white,
      transform: 'translateX(41px)',

      '& .MuiSwitch-thumb:before': {
        marginLeft: 1,
        backgroundImage: `url('/unlock.svg')`
      },

      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.colors.textPrimary
      }
    }
  },

  '& .MuiSwitch-thumb': {
    backgroundColor: theme.colors.white,
    width: 33,
    height: 33,
    marginTop: 2,

    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('/lock.svg')`
    }
  },

  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.colors.controlBorder,
    borderRadius: 36,
    height: '40px',
    minWidth: '79px'
  }
}))
