import theme from 'theme'

const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9.70498 6L8.29498 7.41L12.875 12L8.29498 16.59L9.70498 18L15.705 12L9.70498 6Z"
        fill={theme.colors.controlBorder}
      />
    </svg>
  )
}

export default ChevronRight
