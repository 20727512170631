import { Box } from '@mui/material'
import styled from '@emotion/styled'
import theme from 'theme'

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  gap: 18.5px;
  width: 100%;
  position: relative;
`

export const InsightGroupWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18.5px;
  width: 100%;
  color: ${theme.baseColors.secondary[80]};
  font-size: ${theme.typography.fontSize.xLarge};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.normal};
  line-height: normal;

  .group-header-title {
    color: ${theme.baseColors.secondary[80]};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`

export const CardsGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.xSmall}px;
`

export const SSMCardSkeleton = styled(Box)`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    background-color: ${theme.baseColors.surface[50]};

    &:after {
      background: ${theme.colors.ssmCardSkeleton};
    }
  }
`

export const CardLoadingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: ${theme.colors.surfaceItemBackground};
  border-radius: ${theme.attributes.borderRadius.secondary};
  padding: ${theme.spacing.small}px;
  height: 108px;
  width: 100%;
  gap: ${theme.spacing.xSmall}px;

  .card-skeleton-inner {
    border-radius: ${theme.attributes.borderRadius.small};
  }

  .up {
    gap: ${theme.spacing.small}px;
    display: flex;
    align-items: center;
    width: 100%;

    .up-left {
      display: flex;
      align-items: center;

      .card-skeleton-inner.card-up-left {
        width: 60px !important;
        height: 60px !important;
      }
    }

    .up-right {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.xSmall}px;
      width: 100%;

      .card-up-right-1 {
        width: 120px !important;
        height: 20px !important;
      }

      .card-up-right-2 {
        width: 100% !important;
        height: 30px !important;
      }
    }
  }

  .down {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;

    .card-skeleton-inner.card-bottom {
      width: 100px !important;
      height: 20px !important;
    }
  }

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    background-color: ${theme.baseColors.surface[50]};

    &:after {
      background: ${theme.colors.ssmCardSkeleton};
    }
  }
`
