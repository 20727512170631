import theme from '../../../../theme'

const AuditFailedLoginIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 3.5H9.25V2H14.5C15.325 2 16 2.675 16 3.5V14C16 14.825 15.325 15.5 14.5 15.5H9.25V14H14.5V3.5Z"
      fill={theme.colors.textPrimary}
    />
    <path d="M11.25 6L6.25 11M6.25 6L11.25 11" stroke={theme.colors.textPrimary} strokeWidth="1.5" />
  </svg>
)

export default AuditFailedLoginIcon
