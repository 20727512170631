import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const SSOTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.textPrimary
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeight.bold,
    color: theme.baseColors.primary[20],
    letterSpacing: '0.1px',
    borderRadius: theme.attributes.borderRadius.mediumSmall,
    boxShadow: theme.attributes.boxShadow.selectFieldPaper,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))',
    background: theme.colors.textPrimary,
    width: '176px'
  }
})
