import { FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 42
const ITEM_PADDING_TOP = 8

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.backgroundDark,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.small,
      width: '293px !important',
      maxHeight: '407px !important',
      marginTop: '0px',

      '.MuiMenuItem-root': {
        height: '40px',
        paddingLeft: `${theme.spacing.small}px`,
        paddingRight: `${theme.spacing.small}px`,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          paddingRight: '19px',
          paddingLeft: 0,

          svg: {
            path: {
              fill: theme.baseColors.greyscale[80]
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.bold,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: theme.colors.white,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '.list-item': {
        marginLeft: `${theme.spacing.medium}px`
      },

      '&::-webkit-scrollbar-track': {
        background: '#3e2e3d'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#FBD8F5',
        height: '4px',
        width: '4px',
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const,
  getContentAnchorEl: null
}

export const NavigationFormControl = styled(FormControl)<{ isselected?: string; isCustom?: boolean }>`
  width: 100%;
  margin-top: 0;
  margin-right: 28px;
  border-radius: ${({ isCustom }) => (isCustom ? theme.attributes.borderRadius.large : '40px')};
  border: 1px solid ${theme.colors.controlBorder};
  background: transparent;
  outline: none;
  min-width: 113px;
  height: ${({ isCustom }) => (isCustom ? '40px' : '42px')};

  &:hover {
    border: 1px solid ${theme.colors.textPrimary};
    background: rgba(208, 188, 255, 0.16);
  }

  ${({ isCustom }) =>
    isCustom &&
    `
    justify-content: center;
  `};

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
  }

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    border: 1px solid ${theme.colors.textPrimary};
    background: rgba(208, 188, 255, 0.16);
  `};
`

export const NavigationSelect = styled(Select)<{ isselected?: string; noborder?: string; isCustom?: boolean }>`
  min-width: 113px;
  height: 42px;
  background: transparent;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};
  gap: ${theme.spacing.xSmall}px;

  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: ${theme.colors.white};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;

  .text-with-chip {
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: ${theme.baseColors.secondary[40]};
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    path {
      fill: ${({ isCustom }) => (isCustom ? theme.colors.textPrimary : theme.baseColors.greyscale[80])};
    }
  }
`
