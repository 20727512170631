import theme from 'theme'

const DeleteIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2222 8.66667V19.7778H7.33333V8.66667H16.2222ZM14.5556 2H9L7.88889 3.11111H4V5.33333H19.5556V3.11111H15.6667L14.5556 2ZM18.4444 6.44444H5.11111V19.7778C5.11111 21 6.11111 22 7.33333 22H16.2222C17.4444 22 18.4444 21 18.4444 19.7778V6.44444Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default DeleteIcon
