import { FC, useLayoutEffect, useRef, useState } from 'react'
import { Box, IconButton, Typography, Skeleton } from '@mui/material'
import theme from 'theme'

/* Utils */
import { SSMAssetType, SSMNistPillar, SSMResponseProduct } from '../../../../models'
import { useResize } from '../../../../hooks/useResize'

/* Components */
import {
  CardItem,
  IntegrationLogo,
  IntegrationItemWrapper,
  NoIntegrationLogo,
  SSMCardContainer,
  SSMCardInner,
  SSMCardSkeletonWrapper,
  SSMCardSkeleton
} from './ssmCard.styles'
import { IconTooltip } from './IconTooltip'
import { SSMEditCellIcon } from '../../../components/svg'

interface Props {
  nist: SSMNistPillar
  asset: SSMAssetType
  items: SSMResponseProduct[]
  getIntegrationLogo: (name: string) => string
  handleOpenDrawer: (nist: SSMNistPillar, asset: SSMAssetType, assets: SSMResponseProduct[]) => void
  handleCloseDrawer: () => void
  itemHeight: number
  loading: boolean
}

const SSMCard: FC<Props> = ({ nist, asset, items, loading, getIntegrationLogo, handleOpenDrawer, itemHeight }) => {
  const innerCardRef = useRef<HTMLDivElement | null>(null)
  const innerWidth = useResize(innerCardRef)
  const [maxWidthCard, setMaxWidthCard] = useState(90)
  const [maxWidthText, setMaxWidthText] = useState(32)

  useLayoutEffect(() => {
    if (innerCardRef && innerCardRef.current) {
      setMaxWidthCard(innerCardRef.current.clientWidth / 3 - 1)
      const oneItemPadding = items.length * 32 + 50
      const lessThanThreePadding = items.length * 32 - 15
      const textWidth = innerCardRef.current.clientWidth / 3 - 20
      const calculatedTextWidth =
        items.length === 1
          ? textWidth + oneItemPadding
          : items.length >= 3
          ? textWidth
          : textWidth + lessThanThreePadding
      setMaxWidthText(calculatedTextWidth)
    }
  })

  return (
    <SSMCardContainer
      className={`ssm-card-container ${items.length > 0 && 'with-items'}`}
      height={itemHeight}
      isloading={loading ? 'true' : 'false'}
    >
      {loading ? (
        <SSMCardSkeletonWrapper>
          <SSMCardSkeleton>
            <Skeleton
              variant="rectangular"
              component="div"
              animation="wave"
              width="100%"
              height="100%"
              className="card-skeleton-inner"
            />
          </SSMCardSkeleton>
          <SSMCardSkeleton>
            <Skeleton
              variant="rectangular"
              component="div"
              animation="wave"
              width="100%"
              height="100%"
              className="card-skeleton-inner"
            />
          </SSMCardSkeleton>
        </SSMCardSkeletonWrapper>
      ) : (
        <>
          <IconButton className="edit-icon-button" onClick={() => handleOpenDrawer(nist, asset, items)}>
            <SSMEditCellIcon />
          </IconButton>
          <SSMCardInner
            ref={innerCardRef}
            itemslength={items.length}
            className="ssm-card-inner"
            sx={{ justifyContent: items.length > 3 ? 'flex-start' : 'center' }}
            width={innerWidth}
          >
            {items.map((item, itemIndex) => {
              if (item && item.display_name) {
                const logoPath = getIntegrationLogo(item.logo_path)
                return (
                  <IntegrationItemWrapper key={itemIndex} numofitems={items.length}>
                    {item.logo_path && item.logo_path.length > 0 ? (
                      <>
                        <IconTooltip
                          key={itemIndex}
                          PopperProps={{
                            sx: {
                              marginTop: `-${theme.spacing.sm}px !important`
                            }
                          }}
                          title={
                            <>
                              <Box className="tooltip-wrapper">
                                {item.is_product && item.vendor && (
                                  <Typography className="tooltip-text">{item.vendor.display_name}</Typography>
                                )}
                                <Typography
                                  className={item.is_product && item.vendor ? 'tooltip-subtext' : 'tooltip-text'}
                                >
                                  {item.display_name}
                                </Typography>
                              </Box>
                            </>
                          }
                        >
                          <CardItem sx={{ maxWidth: maxWidthCard }}>
                            {!logoPath.includes('default-vendor-logo') && !logoPath.includes('N/A') ? (
                              <IntegrationLogo logo={logoPath} />
                            ) : (
                              <Box className="missing-logo-wrapper">
                                {item.vendor && item.vendor.display_name
                                  ? item.vendor.display_name[0]
                                  : item.display_name
                                  ? item.display_name[0]
                                  : ''}
                              </Box>
                            )}
                            <Typography sx={{ maxWidth: maxWidthText }}>{item.display_name}</Typography>
                          </CardItem>
                        </IconTooltip>
                      </>
                    ) : (
                      <NoIntegrationLogo>
                        <Typography className="no-integration-logo-letter">{item.display_name[0]}</Typography>
                      </NoIntegrationLogo>
                    )}
                  </IntegrationItemWrapper>
                )
              }

              return null
            })}
          </SSMCardInner>
        </>
      )}
    </SSMCardContainer>
  )
}

export default SSMCard
