import { FC } from 'react'
import { NewIntegrationMarkerContainer } from './integration-card/integrationCard.styles'
import theme from 'theme'

interface Props {
  expanded: boolean
  sx?: Record<string, unknown>
}

const BetaIntegrationMarker: FC<Props> = ({ expanded, sx }) => (
  <NewIntegrationMarkerContainer
    expanded={expanded ? 'true' : 'false'}
    sx={{
      ...sx,
      position: 'relative !important',
      top: 'unset !important',
      left: 'unset !important',
      background: `${theme.colors.primaryLight} !important`,
      width: 'fit-content !important',
      justifyContent: 'center !important',
      padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px !important`
    }}
  >
    BETA
  </NewIntegrationMarkerContainer>
)

export default BetaIntegrationMarker
