import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const UserManagementContainer = styled(Box)`
  min-height: calc(100vh - 90px);
  height: 100%;
  padding: ${theme.spacing.medium}px 10%;
`

export const UserManagementWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 14px;
  display: flex;
  align-items: center;

  .MuiButton-root {
    width: 136px !important;
    min-width: 136px !important;
    height: 40px;
    margin-left: 64px;

    svg {
      path {
        fill: ${theme.baseColors.primary[20]};
      }
    }

    &:hover {
      svg {
        path {
          fill: ${theme.baseColors.primary[90]};
        }
      }
    }
  }

  .sections-wrapper {
    background: ${theme.colors.primaryDark};
    padding: 0 27px 13px;
  }
`

export const TableBody = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-height: 100%;
  padding-bottom: ${theme.spacing.xLarge}px;
`

export const TableHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.primaryDarker};
  border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
  gap: ${theme.spacing.xsm}px;
  padding: ${theme.spacing.half}px 16px;

  .header-actions {
    display: flex;
    align-items: center;

    .password-reset {
      margin-left: 42px;
      padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px 16px;
      gap: ${theme.spacing.xSmall}px;
      background: ${theme.colors.primaryLight};
      border-radius: ${theme.attributes.borderRadius.large};
      height: 40px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fbd8f5;
      text-transform: none;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #f8d8f5;
        }
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.1);
        color: ${theme.colors.white};

        svg {
          path {
            fill: ${theme.colors.white};
          }
        }
      }
    }

    .delete-user {
      margin-left: ${theme.spacing.small}px;
      background: #93000a;
      border-radius: ${theme.attributes.borderRadius.large};
      height: 40px;
      padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px 16px;
      gap: ${theme.spacing.xSmall}px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #ffb4ab;
      text-transform: none;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #ffb4ab;
        }
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.1);
        color: ${theme.colors.white};

        svg {
          path {
            fill: ${theme.colors.white};
          }
        }
      }

      &.disabled {
        background: rgba(255, 255, 255, 0.1);
        color: ${theme.colors.white};
        cursor: default;

        svg {
          path {
            fill: ${theme.colors.white};
          }
        }
      }
    }
  }

  .MuiCheckbox-root {
    svg {
      fill: #fbd8f5;
    }
  }

  .pagination-actions {
    display: flex;
    align-items: center;

    .arrow-button {
      svg {
        path {
          fill: ${theme.colors.white};
        }
      }

      &.Mui-disabled {
        svg {
          path {
            fill: ${theme.baseColors.greyscale[80]};
            opacity: 0.3;
          }
        }
      }
    }

    .MuiTypography-root {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.normal};
      font-size: ${theme.typography.fontSize.xLarge};
      line-height: 24px;
      letter-spacing: 0.5px;
      color: ${theme.baseColors.greyscale[80]};
      margin-right: ${theme.spacing.lg}px;
    }

    svg:not(:first-of-type) {
      margin-left: 28px;
    }
  }
`

export const Row = styled(Box)<{ userrole: string }>`
  display: flex;
  align-items: center;
  background: ${theme.colors.backgroundDarker};
  min-height: 41px;
  padding: ${theme.spacing.xSmall}px ${theme.spacing.large}px ${theme.spacing.xSmall}px ${theme.spacing.small}px;
  border-bottom: 1px solid #342f32;

  &:hover {
    cursor: pointer;
    box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  }

  .name {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.xLarge};
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${theme.colors.neutral};
    margin-left: ${theme.spacing.large}px;
    margin-right: ${theme.spacing.small}px;
  }

  .email {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xLarge};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.baseColors.greyscale[80]};
    flex-grow: 1;
    margin-right: ${theme.spacing.small}px;
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    min-width: 40%;

    .permissions-container {
      min-width: 100px;
    }

    .permissions-title {
      width: fit-content;
      min-width: 64px;
      height: 32px;
      border-radius: ${theme.attributes.borderRadius.secondary};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${theme.spacing.xsm}px ${theme.spacing.md}px;
      gap: ${theme.spacing.xSmall}px;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      text-transform: capitalize;

      ${({ userrole }) =>
        userrole === 'owner' &&
        `
          background: ${theme.colors.textPrimary};
          opacity: 0.9;
          color: ${theme.baseColors.primary[20]};
      `};

      ${({ userrole }) =>
        userrole === 'admin' &&
        `
          background: #B2EBF2;
          color: ${theme.colors.primaryDark};
      `};

      ${({ userrole }) =>
        ['cpi manager', 'manager', 'cpi_manager'].includes(userrole) &&
        `
          background: ${theme.baseColors.info[20]};
          color: ${theme.colors.primaryDark};
      `};

      ${({ userrole }) =>
        userrole === 'viewer' &&
        `
          background: ${theme.baseColors.info[30]};
          color: ${theme.colors.primaryDark};
      `};

      ${({ userrole }) =>
        userrole === 'default' &&
        `
           background: ${theme.baseColors.info[10]};
           color: ${theme.colors.white};
       `};

      ${({ userrole }) =>
        (userrole === 'demo' || !userrole) &&
        `
          background: #DEBDD9;
          color: #3F293E;
      `};
    }

    .status-container {
      width: 100%;
      margin-left: 60px;
    }

    .user-status {
      width: fit-content;
      min-width: 73px;
      height: 32px;
      padding: ${theme.spacing.xsm}px ${theme.spacing.small}px;
      gap: ${theme.spacing.xSmall}px;
      border: 1px solid ${theme.colors.controlBorder};
      border-radius: ${theme.attributes.borderRadius.mediumSmall};
      background: transparent;
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: ${theme.colors.neutral};
    }
  }
`

export const EmptyRow = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column wrap;
  background: ${theme.colors.backgroundDarker};
  padding: ${theme.spacing.xSmall}px ${theme.spacing.large}px ${theme.spacing.xSmall}px ${theme.spacing.small}px;
  border-bottom: 1px solid #342f32;
  height: 100%;
  min-height: 100%;
  border-bottom-left-radius: ${theme.attributes.borderRadius.quaternary};
  border-bottom-right-radius: ${theme.attributes.borderRadius.quaternary};

  .title {
    color: ${theme.colors.neutral};
    text-align: center;
    font-size: 36px;
    font-family: ${theme.typography.fontFamily.primary};
    line-height: 44px;
    margin-top: 63px;
  }

  .text {
    color: ${theme.colors.neutral};
    text-align: center;
    font-size: ${theme.typography.fontSize.xLarge};
    font-family: ${theme.typography.fontFamily.primary};
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-top: ${theme.spacing.xSmall}px;
    margin-bottom: 100px;
  }
`
