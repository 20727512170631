import { Dispatch, FC, SetStateAction } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import theme from 'theme'

/* Utils */
import { AnalysisDataTable, IntegrationInstance } from '../../../../../models'

/* Components */
import { Drawer } from './settings.styles'
import RowDataItem from './RowDataItem'
import CloseChipIcon from '../../../../components/svg/CloseChipIcon'

interface Props {
  drawerOpen: boolean
  activeRowItem: AnalysisDataTable | null
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  firstInstance: IntegrationInstance | undefined
  secondInstance: IntegrationInstance | undefined
}

const RowDataDrawer: FC<Props> = ({ drawerOpen, activeRowItem, setDrawerOpen, firstInstance, secondInstance }) => {
  if (!activeRowItem) return <></>
  const coverage = activeRowItem.coverage === 'both' ? 'both' : activeRowItem.coverage.slice(0, -9)
  const thisInstance =
    firstInstance && firstInstance.integration_name === coverage
      ? firstInstance
      : secondInstance && secondInstance.integration_name === coverage
      ? secondInstance
      : null

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box role="presentation">
        <Box className="wrapper-inner">
          <Box className="header">
            <Typography className="title">Asset Details</Typography>
            <IconButton className="close-icon" onClick={() => setDrawerOpen(false)}>
              <CloseChipIcon color={theme.baseColors.secondary[80]} />
            </IconButton>
          </Box>

          <Box className="content">
            <Box className="row-item">
              <Typography className="row-name">Coverage</Typography>
              <Box className="row-data">
                {thisInstance && coverage !== 'both' ? (
                  <RowDataItem instance={thisInstance} text={thisInstance.name || ''} />
                ) : (
                  <>
                    <RowDataItem instance={firstInstance} text={firstInstance?.name || ''} />
                    <RowDataItem instance={secondInstance} text={secondInstance?.name || ''} />
                  </>
                )}
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Host name</Typography>
              <Box className="row-data">
                {thisInstance && coverage !== 'both' ? (
                  <RowDataItem instance={thisInstance} text={activeRowItem?.hostName || ''} />
                ) : (
                  <>
                    <RowDataItem instance={firstInstance} text={activeRowItem?.hostName || ''} />
                    <RowDataItem instance={secondInstance} text={activeRowItem?.secondHostName || ''} />
                  </>
                )}
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Serial number</Typography>
              <Box className="row-data">
                {thisInstance && coverage !== 'both' ? (
                  <RowDataItem instance={thisInstance} text={activeRowItem?.serialNumber || ''} />
                ) : (
                  <>
                    <RowDataItem instance={firstInstance} text={activeRowItem?.serialNumber || ''} />
                    <RowDataItem instance={secondInstance} text={activeRowItem?.secondSerialNumber || ''} />
                  </>
                )}
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">MAC address</Typography>
              <Box className="row-data">
                {thisInstance && coverage !== 'both' ? (
                  <RowDataItem instance={thisInstance} text={activeRowItem?.macAddress || ''} />
                ) : (
                  <>
                    <RowDataItem instance={firstInstance} text={activeRowItem?.macAddress || ''} />
                    <RowDataItem instance={secondInstance} text={activeRowItem?.secondMacAddress || ''} />
                  </>
                )}
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Last seen</Typography>
              <Box className="row-data">
                {thisInstance && coverage !== 'both' ? (
                  <RowDataItem
                    instance={thisInstance}
                    text={dayjs(activeRowItem.lastSeen).format('YYYY-MM-DD HH:MM:ss')}
                    lastSeenMissing={activeRowItem.lastSeen.includes('0001-01-01')}
                  />
                ) : (
                  <>
                    <RowDataItem
                      instance={firstInstance}
                      text={dayjs(activeRowItem.lastSeen).format('YYYY-MM-DD HH:MM:ss')}
                      lastSeenMissing={activeRowItem.lastSeen.includes('0001-01-01')}
                    />
                    <RowDataItem
                      instance={secondInstance}
                      text={dayjs(activeRowItem.secondLastSeen).format('YYYY-MM-DD HH:MM:ss')}
                      lastSeenMissing={activeRowItem.lastSeen.includes('0001-01-01')}
                    />
                  </>
                )}
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Operating system</Typography>
              <Box className="row-data">
                {thisInstance && coverage !== 'both' ? (
                  <RowDataItem instance={thisInstance} text={activeRowItem?.operatingSystem || ''} />
                ) : (
                  <>
                    <RowDataItem instance={firstInstance} text={activeRowItem?.operatingSystem || ''} />
                    <RowDataItem instance={secondInstance} text={activeRowItem?.secondOperatingSystem || ''} />
                  </>
                )}
              </Box>
            </Box>

            {coverage === 'both' && (
              <Box className="row-item">
                <Typography className="row-name">Matched by</Typography>
                <Box className="row-data">
                  <RowDataItem instance={firstInstance} text={activeRowItem?.matchedBy || ''} hideImg />
                  <RowDataItem instance={secondInstance} text={activeRowItem?.matchedBy || ''} hideImg />
                </Box>
              </Box>
            )}

            <Box className="row-item">
              <Typography className="row-name">Asset ID</Typography>
              <Box className="row-data">
                {thisInstance && coverage !== 'both' ? (
                  <RowDataItem instance={thisInstance} text={activeRowItem?.assetId || ''} />
                ) : (
                  <>
                    <RowDataItem instance={firstInstance} text={activeRowItem?.assetId || ''} />
                    <RowDataItem instance={secondInstance} text={activeRowItem?.secondAssetId || ''} />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default RowDataDrawer
