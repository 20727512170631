import React, { FC, SVGProps } from 'react'
import theme from 'theme'

type Props = SVGProps<SVGSVGElement>

const CheckboxIcon: FC<Props> = ({ ...props }) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="12" y="12.5" width="16" height="16" rx="1" stroke={theme.baseColors.greyscale[80]} strokeWidth="2" />
  </svg>
)

export default CheckboxIcon
