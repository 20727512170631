import { Box } from '@mui/material'
import styled from '@emotion/styled'
import theme from 'theme'

export const ChipsContainer = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  position: relative;

  .chips-title {
    overflow: hidden;
    color: ${theme.colors.white};
    text-overflow: ellipsis;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }

  .MuiChip-root {
    background: transparent;
    border-radius: ${theme.attributes.borderRadius.secondary};
    border: 1px solid ${theme.colors.controlBorder};
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${theme.colors.white};
    text-transform: capitalize;

    &.selected {
      color: ${theme.baseColors.primary[20]};
      border: none;
      background: ${theme.colors.textPrimary};
    }

    &:hover {
      background: ${theme.colors.textPrimary} !important;
      color: ${theme.baseColors.primary[20]} !important;
      opacity: 0.9 !important;
    }
  }

  .month-picker-container {
    position: absolute;
    top: 148px;
    left: 297px;
    z-index: 10000;
    width: 420px;
    display: none;
    border-radius: ${theme.attributes.borderRadius.small} ${theme.attributes.borderRadius.small} 0 0;
    background: ${theme.colors.surfaceItemBackground};
    transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.isopen {
      padding: 0;

      > div:first-of-type {
        padding: ${theme.spacing.small}px ${theme.spacing.md}px ${theme.spacing.half}px;
        justify-content: center;
        gap: 170px;

        & [class*='mx-4'] {
          &:before {
            width: 1px;
            height: 44px;
            background-color: #614964;
            position: absolute;
            left: 207.5px;
            top: 0;
            content: '';
            display: block;
          }
        }

        > div:first-of-type,
        > div:last-of-type {
          display: none;
        }
      }
    }

    p {
      color: ${theme.baseColors.greyscale[80]};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    & [class*='gap-4'] {
      border-bottom: 1px solid #614964;

      > div:first-of-type {
        border-right: 1px solid #614964;
      }
    }

    .month-picker-month {
      color: #fbd8f5;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;

      &[class*='variant-start'],
      &[class*='variant-end'] {
        background: ${theme.colors.textPrimary};
        color: ${theme.baseColors.primary[20]};
      }

      &[class*='variant-selected'] {
        background: #614964;
        color: #fbd8f5;
      }

      &[class*='variant-selected'] {
        color: #fbd8f5;
      }

      &[class*='variant-default'] {
        &:hover {
          background: transparent;
        }
      }
    }

    &.isopen {
      display: flex;
    }
  }

  .select-field-wrapper {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
  }
`

export const ViewData = styled(Box)`
  position: absolute;
  top: 239px;
  left: 321px;
  background: ${theme.colors.surfaceItemBackground};
  border-radius: 0 0 ${theme.attributes.borderRadius.small} ${theme.attributes.borderRadius.small};
  z-index: 10000;
  width: 420px;
  height: 56px;
  padding: ${theme.spacing.xSmall}px ${theme.spacing.md}px;
  justify-content: flex-end;
  align-items: center;
  display: none;
  transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.isopen {
    display: flex;
  }
`

export const viewDataStyles = {
  borderColor: 'transparent !important',
  background: 'rgba(208, 188, 255, 0.08) !important',

  '&:hover': {
    background: 'rgba(208, 188, 255, 0.04) !important'
  }
}
