import { FC, useState } from 'react'
import { ListItemText, MenuItem, OutlinedInput, Checkbox, SelectProps } from '@mui/material'
import { SelectDropdownIcon } from '../../../../components/svg'
import { CategoryMenuProps, AuditLogSelect, AuditLogSingleFormControl } from './auditLogSelectField.styles'
import theme from 'theme'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  noborder?: string
}

const AuditLogSingleSelectField: FC<Props> = ({ options, handleChange, noborder, minWidth, value, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <AuditLogSingleFormControl
      sx={{
        minWidth
      }}
      isselected={value !== 20 ? 'true' : 'false'}
      isopen={anchorEl ? 'true' : 'false'}
    >
      <AuditLogSelect
        noborder={noborder}
        isselected={value !== 0 ? 'true' : 'false'}
        labelId="library-select-single-label"
        id="library-select-single"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null),
          PaperProps: {
            ...CategoryMenuProps.PaperProps,
            sx: {
              ...CategoryMenuProps.PaperProps.sx,
              marginTop: `${theme.spacing.half}px`,
              width: '83px',

              '.MuiMenuItem-root': {
                ...CategoryMenuProps.PaperProps.sx['.MuiMenuItem-root'],
                width: '83px',
                height: '48px',
                paddingLeft: `${theme.spacing.small}px`,

                '&:hover': {
                  background: 'rgba(208, 188, 255, 0.16)'
                },

                '&.Mui-selected': {
                  background: 'rgba(208, 188, 255, 0.08)'
                }
              }
            }
          }
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput onMouseDown={handleClick} />}
        sx={{
          minWidth,
          '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            onClick={() => {
              if (!rest.multiple) {
                setAnchorEl(null)
              }
            }}
          >
            {rest.multiple && <Checkbox checked={value.indexOf(option) > -1} />}
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </AuditLogSelect>
    </AuditLogSingleFormControl>
  )
}

export default AuditLogSingleSelectField
