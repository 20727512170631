import { FC, useMemo, useCallback, useEffect, useRef, useState, SyntheticEvent } from 'react'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Tab } from '@mui/material'
import { useAuth } from '@frontegg/react'
import './jiraInstance.styles.css'

/* Utils */
import axios from '../../../../../lib/axios'
import { defaultValues, jiraFieldsImportOrder } from '../setupIntegration.constants'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  ManifestIntegration,
  DataFilter,
  DataMappingOption,
  DataMappingField,
  DataMappingDefaultValues,
  IPreviewData,
  JiraField,
  JiraOperator,
  JiraUser
} from 'models'
import { stringOfNumberedListToArray, customSortByOrder } from 'lib/utils'
import { closeToast, openToast, ToastVariant } from 'store/toasts/toastSlice'
import { modalPropsSelector, ModalType, openModal } from 'store/modals/modalSlice'
import {
  emptyDataFilter,
  emptyDataMappings,
  emptyFormikField,
  emptyUser
} from './data-filtering/dataFilteringControls.constants'

/* Components */
import DataConnection from './data-connection'
import ConnectedProjects from './connected-projects'
import { IntegrationsContainer, JiraTabs, JiraTabsWrapper } from '../integrations.styles'
import EnlargeImageDialog from '../components/enlarge-image-dialog/EnlargeImageDialog'
import { useCallbackPrompt } from '../../../../../hooks/useCallbackPrompt'
import TabPanel, { a11yProps } from '../../../library/library-details/components/LibraryDetailsTabPanel'
import JiraHeader from './JiraHeader'

export enum TestStatus {
  unchecked = 'unchecked',
  successful = 'successful',
  failed = 'failed'
}

interface SchemaItem {
  name: string
  label: string
  default: string | null
  required: boolean
}

export interface DialogProps {
  image: string
  instruction: string
  index: number
}

interface Props {
  setupType: 'add' | 'edit'
}

const SetupJiraInstance: FC<Props> = ({ setupType }) => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user: authUser } = useAuth()
  const closeNotification = useRef<any>(null)
  const modalProps = useAppSelector(modalPropsSelector)
  const [integration, setIntegration] = useState<ManifestIntegration | null>(null)
  const [integrationLoading, setIntegrationLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [testing, setTesting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saving, setSaving] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [testStatus, setTestStatus] = useState<TestStatus>(TestStatus.unchecked)
  const [initialValues, setInitialValues] = useState<Record<string, string>>(defaultValues)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [configSchema, setSchema] = useState<SchemaItem[] | null>(null)
  const [instructions, setInstructions] = useState<RegExpMatchArray | null>(null)
  const [visibleValues, setVisibleValues] = useState<Record<string, boolean>>({})
  const [instructionsOpen, setInstructionsOpen] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    image: '',
    instruction: '',
    index: 0
  })
  const [savedValues, setSavedValues] = useState(defaultValues)
  const [allowSave, setAllowSave] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [activeSection, setActiveSection] = useState(0)
  const [jiraFields, setJiraFields] = useState<JiraField[]>([])
  const [jiraOperators, setJiraOperators] = useState<JiraOperator[]>([])
  const [initialLoad, setInitialLoad] = useState(false)
  const [instanceId, setInstanceId] = useState('')
  const [dataMappingFields, setDataMappingFields] = useState<DataMappingField[]>([])
  const [dataMappingOptions, setDataMappingOptions] = useState<DataMappingOption[]>([])
  const [dataMappingValues, setDataMappingValues] = useState<Record<string, string[]>>({
    closed_status: [],
    critical_severity: [],
    high_severity: [],
    medium_severity: [],
    low_severity: []
  })
  const [dataMappingDefaults, setDataMappingDefaults] = useState<DataMappingDefaultValues[]>([])
  const [dataMappingDefaultsValues, setDataMappingDefaultsValues] = useState({})
  const [dataFilters, setDataFilters] = useState<DataFilter[]>([])
  const [previewData, setPreviewData] = useState<IPreviewData | null>(null)
  const [initialLoadPreview, setInitialLoadPreview] = useState(false)
  const [hasSavedDataFilters, setHasSavedDataFilters] = useState(false)
  const [initialLoadProject, setInitialLoadProject] = useState(false)
  const [instanceLoaded, setInstanceLoaded] = useState(false)
  const [projectDisconnected, setProjectDisconnected] = useState(false)
  const [sectionValues, setSectionValues] = useState({
    appsec: {
      dataFilters: [emptyDataFilter],
      dataMappings: emptyDataMappings
    },
    incidents: {
      dataFilters: [emptyDataFilter],
      dataMappings: emptyDataMappings
    },
    vulnerabilities: {
      dataFilters: [emptyDataFilter],
      dataMappings: emptyDataMappings
    }
  })
  const [jiraFiltersLoading, setJiraFiltersLoading] = useState(false)
  const [noDataMappingFields, setNoDataMappingFields] = useState(false)
  const [valueAssignee, setValueAssignee] = useState<JiraUser[]>([emptyUser])
  const [inputValueAssignee, setInputValueAssignee] = useState('')
  const [valueReporter, setValueReporter] = useState<JiraUser[]>([emptyUser])
  const [inputValueReporter, setInputValueReporter] = useState('')
  const [nameHelperText, setNameHelperText] = useState('')
  const [isSavingFilters, setIsSavingFilters] = useState(false)
  const [vendorsAssignee, setVendorsAssignee] = useState<JiraUser[]>([emptyUser])
  const [selectedOptionsAssignee, setSelectedOptionsAssignee] = useState<JiraUser[]>([])
  const [selectedOptionsReporter, setSelectedOptionsReporter] = useState<JiraUser[]>([])
  const [vendorsReporter, setVendorsReporter] = useState<JiraUser[]>([emptyUser])
  const [refetch, setRefetch] = useState({
    section: 'appsec',
    fetch: false
  })
  const [integrationConfigId, setIntegrationConfigId] = useState('')
  const [allCpis, setAllCpis] = useState([])

  const findOperator = useCallback(
    (thisOp: string) => {
      const found = jiraOperators.find((c) => c.displayName === thisOp)
      if (found) {
        return found.name
      }

      return thisOp
    },
    [jiraOperators]
  )

  const searchByJiraField = async (instanceId: string, apiName: string) => {
    try {
      const res = await axios.get(`/api/v3/integrations/jira/config/${instanceId}/search/${apiName}`)

      return {
        field: apiName,
        jiraField: apiName,
        apiName,
        options: res.data.data
      }
    } catch (err) {
      console.error(err)
    }

    return null
  }

  const getJiraDefaultValues = async (instanceId: string, item: DataMappingField, jiraFieldsArr?: JiraField[]) => {
    try {
      const thisJiraFields = jiraFieldsArr || jiraFields
      const found = thisJiraFields.find((c) => c.id === item.jiraField)

      const res = await axios.get(
        `/api/v3/integrations/jira/config/${instanceId || params.instanceId}/default/${found?.apiName}`
      )

      if (res.status.toString().includes('20')) {
        const { data } = res.data
        setNoDataMappingFields(false)

        return {
          dataMappingField: item,
          dataMappingDefaultValues: data,
          jiraField: found
        }
      }
    } catch (err) {
      console.error(err)
      setNoDataMappingFields(true)
    }
  }

  const fetchDataMappingFields = async (jiraFields: JiraField[]) => {
    try {
      const res = await axios.get('/api/v3/integrations/jira/mapping/fields')

      if (res.status.toString().includes('20')) {
        const { data } = res.data
        const dataMappings = data.map((c: any) => ({
          ...c,
          values: []
        }))

        const jiraFieldsArr: string[] = data.map((c: any) => c.jiraField)
        const promises: any[] = []
        const apiNameArr = jiraFields.filter((c) => jiraFieldsArr.includes(c.id))

        apiNameArr.forEach((field) => {
          const { apiName } = field

          const promise = async () => {
            return await searchByJiraField(instanceId, apiName)
          }
          promises.push(promise())
        })

        const resp: DataMappingOption[] = await Promise.all(promises)
        setDataMappingOptions(resp as DataMappingOption[])
        setDataMappingFields(dataMappings)
        // const t: any = {}
        // dataMappings.forEach((c: any) => {
        //   t[c.id] = []
        // })
        // setDataMappingValues(t)

        /* Get Default Mapping Values */
        const defaultValuesPromises: any[] = []
        dataMappings.forEach((item: DataMappingField) => {
          if (item.hasDefault) {
            const promise = async () => {
              return await getJiraDefaultValues(instanceId, item, jiraFields)
            }
            defaultValuesPromises.push(promise())
          }
        })
        const defaultValuesRes: DataMappingDefaultValues[] = await Promise.all(defaultValuesPromises)
        const c: any = {}
        defaultValuesRes.forEach((item) => {
          const { apiName } = item.jiraField
          c[apiName] = []
        })
        setDataMappingDefaultsValues(c)
        setDataMappingDefaults(defaultValuesRes)

        return data
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchJiraFields = async () => {
    try {
      const res = await axios.get('/api/v3/integrations/jira/fields')

      if (res.status.toString().includes('20')) {
        const { data } = res.data
        const jiraFields = data.fields.sort(
          (a: any, b: any) =>
            customSortByOrder(jiraFieldsImportOrder)[a.id] - customSortByOrder(jiraFieldsImportOrder)[b.id]
        )
        setJiraFields(jiraFields)
        setDataFilters([
          {
            apiName: '',
            field: '',
            operator: '',
            id: '',
            values: []
          }
        ])
        setJiraOperators(data.operators.api)

        await fetchDataMappingFields(jiraFields)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getConfigured = async () => {
    try {
      const res = await axios.get('/api/v3/integrations/configured')
      if (res.status.toString().includes('20')) {
        const { instances } = res.data
        if (instances.length > 0 && params.instanceId) {
          const found = instances.find((c: any) => c.id === params.instanceId)
          if (found) {
            setInstanceId(found.id)
          }
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setInstanceLoaded(true)
    }
  }

  const fetchJiraPreview = async (fields: any[] = dataFilters) => {
    closeNotification.current = null
    clearTimeout(closeNotification.current)

    const payload: any = {
      fields: fields.map((field) => ({
        ...field,
        operator: findOperator(field.operator)
      }))
    }

    if (!payload.fields[0].jqlField) {
      const newFields: any[] = []
      dataFilters.forEach((dataFilter) => {
        const jiraField = jiraFields.find((c) => c.displayName === dataFilter.field)
        const operator = jiraOperators.find((c) => c.displayName === dataFilter.operator)

        if (jiraField) {
          const result = {
            ...dataFilter,
            operator: operator?.name ?? dataFilter.operator,
            id: jiraField.id,
            jqlField: jiraField.jqlField,
            values:
              jiraField.id === 'assignee_accountId'
                ? selectedOptionsAssignee.map((c) => ({
                    id: c.accountId,
                    name: c.emailAddress
                  }))
                : jiraField.id === 'reporter_accountId'
                ? selectedOptionsReporter.map((c) => ({
                    id: c.accountId,
                    name: c.emailAddress
                  }))
                : jiraField.apiIdField && jiraField.apiNameField
                ? dataFilter.values
                : dataFilter.values.map((c) => c.name)
          }
          newFields.push(result)
        }
      })
      payload.fields = newFields
    }

    try {
      const res = await axios.post(`/api/v3/integrations/jira/config/${instanceId}/preview`, payload)

      if (res.status.toString().includes('20')) {
        setPreviewData(res.data)
      }
    } catch (err) {
      console.error(err)
      let message = ''
      if ((err as any).response?.data?.message) {
        message = (err as any).response?.data?.message
      }
      setPreviewData(null)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed to generate preview.',
            description: message
          }
        })
      )
    } finally {
      setInitialLoadPreview(true)
      setTimeout(() => {
        dispatch(closeToast())
      }, 8000)
    }
  }

  const fetchJiraFiltersSaved = async (sectionName?: string) => {
    setJiraFiltersLoading(true)
    try {
      const res = await axios.get(
        `/api/v3/integrations/jira/config/${instanceId}/filter/${
          sectionName || (activeSection === 0 ? 'appsec' : activeSection === 1 ? 'incidents' : 'vulnerabilities')
        }`
      )

      if (res.status.toString().includes('20')) {
        const { data } = res.data

        const foundAssignee = data.find((c: any) => c.id === 'assignee_accountId')
        if (foundAssignee) {
          setValueAssignee([
            {
              ...emptyUser,
              displayName: foundAssignee.values[0].name,
              accountId: foundAssignee.values[0].id,
              emailAddress: foundAssignee.values[0].name
            }
          ])
        }
        const foundReporter = data.find((c: any) => c.id === 'reporter_accountId')
        if (foundReporter) {
          setValueReporter([
            {
              ...emptyUser,
              displayName: foundReporter.values[0].name,
              accountId: foundReporter.values[0].id,
              emailAddress: foundReporter.values[0].name
            }
          ])
        }

        if (data.length > 0 && data[0].id !== '') {
          const splicedData = data.map((c: any) => {
            const found = jiraFields.find((n) => n.id === c.id)
            if (found) {
              return {
                ...c,
                apiName: found.apiName,
                jqlField: found.jqlField,
                field: found.displayName,
                operator: jiraOperators.find((x) => x.name === c.operator)?.displayName || c.operator,
                values:
                  found.apiIdField && found.apiNameField
                    ? c.values
                    : c.values.map((n: any) => ({ id: n, name: n, label: n }))
              }
            }

            return c
          })
          setDataFilters(splicedData)

          try {
            await fetchJiraPreview(splicedData)
            setHasSavedDataFilters(true)
          } catch (err) {
            console.error(err)
          }
        } else {
          setHasSavedDataFilters(false)
          setPreviewData(null)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setInitialLoadProject(true)
      setInitialLoad(true)
      setJiraFiltersLoading(false)
    }
  }

  const fetchJiraMappingSaved = async (sectionName?: string) => {
    try {
      const res = await axios.get(
        `/api/v3/integrations/jira/config/${instanceId}/mapping/${
          sectionName || (activeSection === 0 ? 'appsec' : activeSection === 1 ? 'incidents' : 'vulnerabilities')
        }`
      )

      if (res.status.toString().includes('20')) {
        const { data } = res.data

        if (data.length > 0) {
          const mappingValuesNew: any = {
            closed_status: data.find((c: any) => c.id === 'closed_status')?.values || [],
            critical_severity: data.find((c: any) => c.id === 'critical_severity')?.values || [],
            high_severity: data.find((c: any) => c.id === 'high_severity')?.values || [],
            medium_severity: data.find((c: any) => c.id === 'medium_severity')?.values || [],
            low_severity: data.find((c: any) => c.id === 'low_severity')?.values || []
          }

          setDataMappingValues(mappingValuesNew)
          const thisSection = activeSection === 0 ? 'appsec' : activeSection === 1 ? 'incidents' : 'vulnerabilities'
          setSectionValues((pre) => ({
            ...pre,
            [thisSection]: {
              ...pre[thisSection],
              dataMappings: mappingValuesNew
            }
          }))
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (instanceId && jiraFields.length > 0 && !hasSavedDataFilters && !projectDisconnected) {
      fetchJiraMappingSaved().catch((e) => e)
      fetchJiraFiltersSaved().catch((e) => e)
    }
  }, [instanceId, jiraFields, hasSavedDataFilters, projectDisconnected])

  useEffect(() => {
    if (refetch.fetch) {
      Promise.all([fetchJiraMappingSaved(refetch.section), fetchJiraFiltersSaved(refetch.section)]).finally(() => {
        setRefetch((pre) => ({
          ...pre,
          fetch: false
        }))
      })
    }
  }, [refetch])

  useEffect(() => {
    getConfigured().catch((e) => e)
  }, [])

  useEffect(() => {
    if (!instanceId) return
    fetchJiraFields().catch((e) => e)
  }, [instanceId])

  const handleSubmit = async () => {
    setLoading(true)
    setTesting(true)
    const configValues = {
      ...formik.values
    }
    delete configValues.integrationName
    delete configValues.description
    delete configValues.name

    try {
      const payload = {
        integrationName: integration?.name || '',
        description: formik.values.description,
        name: formik.values.name,
        config: configValues
      }

      let res
      if (setupType === 'add') {
        res = await axios.post(`/api/v3/integrations/${integration?.name || ''}/check_connection`, payload)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete payload.description
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete payload.integrationName
        res = await axios.post(`/api/v3/integrations/config/${params.instanceId}/check_connection`, payload)
      }

      if (res.data.status === 'failed') {
        setTestStatus(TestStatus.failed)
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Connection test failed. Check your network and settings.',
              description: ''
            }
          })
        )
      } else {
        setTestStatus(TestStatus.successful)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Connection Test was successful!',
              description: "Click 'Save' to complete integration process"
            }
          })
        )
        setAllowSave(true)
      }
    } catch (e) {
      console.error(e)
      setTestStatus(TestStatus.failed)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Test Failed',
            description: (e as any).response?.data?.message || 'Please double check your configuration'
          }
        })
      )
    } finally {
      setLoading(false)
      setTesting(false)
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit
  })

  const filtersFormik = useFormik({
    initialValues: { fields: [emptyFormikField] },
    onSubmit: (values) => {
      console.log(values)
    },
    enableReinitialize: initialLoad
  })

  const p2Disabled = useMemo(() => {
    return jiraFiltersLoading
      ? true
      : initialLoadProject && hasSavedDataFilters
      ? true
      : filtersFormik.isSubmitting || isSavingFilters
      ? true
      : dataFilters.some((c) => !c.values.length) ||
        !dataMappingValues?.closed_status?.length ||
        Object.values(dataMappingValues)
          .filter((c: any, i) => i !== 0 && c !== true)
          .every((c) => c.length < 1)
  }, [
    dataFilters,
    filtersFormik.isSubmitting,
    isSavingFilters,
    dataMappingValues,
    initialLoadProject,
    hasSavedDataFilters,
    jiraFiltersLoading
  ])

  const discardDisabled = useMemo(() => {
    let disabled = true
    const values = formik.values

    // if (instructionsOpen) {
    //   return false
    // }

    if (allowSave && (loading || formik.isSubmitting)) {
      return true
    }

    for (const [key, value] of Object.entries(values)) {
      if (value !== (savedValues as any)[key]) {
        disabled = false
      }
    }

    return disabled
  }, [formik.values, allowSave, loading, formik.isSubmitting, savedValues, instructionsOpen])

  const discardP2Disabled = useMemo(() => {
    if (jiraFiltersLoading) return true
    if (tabValue === 1 && !instanceId) return true
    if (initialLoadProject && hasSavedDataFilters) return true
    if (
      dataFilters.length === 1 &&
      dataFilters[0].apiName === '' &&
      dataFilters[0].field === '' &&
      dataFilters[0].operator === '' &&
      !dataFilters[0].values.length &&
      dataMappingValues &&
      !dataMappingValues.closedStatus?.length &&
      !dataMappingValues.critical_severity?.length &&
      !dataMappingValues.high_severity?.length &&
      !dataMappingValues.medium_severity?.length &&
      !dataMappingValues.low_severity?.length
    ) {
      return true
    }

    return false
  }, [
    dataFilters,
    tabValue,
    instanceId,
    jiraFiltersLoading,
    dataMappingValues,
    initialLoadProject,
    hasSavedDataFilters
  ])

  const [enablePrompt, setEnablePrompt] = useState(tabValue === 0 ? discardDisabled : discardP2Disabled)

  useEffect(() => {
    if (tabValue === 0) {
      if (setupType === 'add') {
        setEnablePrompt(false)
      } else {
        setEnablePrompt(!discardDisabled)
      }
    } else {
      if (!modalProps?.open) {
        setEnablePrompt(!discardP2Disabled)
      }
    }
  }, [modalProps, discardDisabled, tabValue, discardP2Disabled, setupType])

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(enablePrompt)

  const okCallback = () => {
    setTabValue(tabValue === 0 ? 1 : 0)
    if (setupType === 'edit') {
      navigate(
        tabValue === 1
          ? `/settings/integrations/atlassian_jira/${instanceId}/edit`
          : `/settings/integrations/atlassian_jira/${instanceId}/edit/appsec`
      )
    }
    if (setupType === 'add') {
      navigate(
        tabValue === 1
          ? `/settings/integrations/atlassian_jira/add`
          : `/settings/integrations/atlassian_jira/add/appsec`
      )
    }
    setDataFilters([emptyDataFilter])
    setDataMappingValues({
      closed_status: [],
      critical_severity: [],
      high_severity: [],
      medium_severity: [],
      low_severity: []
    })
    setPreviewData(null)
    setValueAssignee([emptyUser])
    setInputValueAssignee('')
    setVendorsAssignee([emptyUser])
    setValueReporter([emptyUser])
    setInputValueReporter('')
    setVendorsReporter([emptyUser])
    setSelectedOptionsAssignee([])
    setSelectedOptionsReporter([])
  }

  const okCallbackSection = (newSection: number) => {
    const sectionName = newSection === 0 ? 'appsec' : newSection === 1 ? 'incidents' : 'vulnerabilities'
    setDataFilters([emptyDataFilter])
    setDataMappingValues({
      closed_status: [],
      critical_severity: [],
      high_severity: [],
      medium_severity: [],
      low_severity: []
    })
    setPreviewData(null)
    setValueAssignee([emptyUser])
    setInputValueAssignee('')
    setVendorsAssignee([emptyUser])
    setValueReporter([emptyUser])
    setInputValueReporter('')
    setVendorsReporter([emptyUser])
    setSelectedOptionsAssignee([])
    setSelectedOptionsReporter([])
    setActiveSection(newSection)
    setRefetch({
      section: sectionName,
      fetch: true
    })

    navigate(`/settings/integrations/atlassian_jira/${instanceId}/edit/${sectionName}`)
  }

  useEffect(() => {
    if (showPrompt) {
      dispatch(
        openModal({
          type: ModalType.cancelSetupIntegrationChanges,
          props: { confirmNavigation, cancelNavigation }
        })
      )
    }
  }, [showPrompt])

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  useEffect(() => {
    if (initialValues) {
      const valuesArr = Object.keys(initialValues)
      const valuesObj: Record<string, boolean> = {}
      valuesArr.forEach((key) => (valuesObj[key] = false))
      // setVisibleValues(valuesObj)
    }
  }, [initialValues])

  useEffect(() => {
    if (!integration) return

    if (integration?.documentation?.path) {
      import(`../instructions/${integration?.documentation?.path}_instructions.md`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => {
              setInstructions(stringOfNumberedListToArray(res, integration.name))
            })
            .catch((err) => console.error(err))
            .finally(() => {
              setIntegrationLoading(false)
            })
        })
        .catch((err) => console.error(err))
    } else {
      setTimeout(() => {
        setIntegrationLoading(false)
      }, 200)
    }
  }, [integration])

  const fetchIntegrations = useCallback(async () => {
    try {
      const res = await axios.get('/api/v3/integrations/supported')

      if (res.status === 200) {
        const { integrations } = res.data
        const integration = integrations.find((n: ManifestIntegration) => n.name === 'atlassian_jira')

        if (integration) {
          setIntegration(integration)

          const instanceValues: Record<string, string> = {
            name: integration.name,
            description: integration.description || ''
          }
          const visibleValues: any = {}
          if (integration.config.schema) {
            integration.config.schema.forEach((n: Record<string, any>) => {
              instanceValues[n.name] = ''
              if (n.airbyte_secret) {
                visibleValues[n.name] = !!n.airbyte_secret
              }
            })
          }
          setVisibleValues(visibleValues)
          setInitialValues(instanceValues)
          formik.setValues(instanceValues)
          setSavedValues(instanceValues as any)
        }
      }
    } catch (e) {
      console.error(e)
      if ((e as any).response?.data?.message === 'Unauthorized') {
        window.location.replace('/')
      }
    }
  }, [])

  const fetchInstanceConfig = useCallback(
    async (instanceId?: string) => {
      try {
        const res = await axios.get(
          `/api/v3/integrations/config/${typeof instanceId !== 'undefined' ? instanceId : params.instanceId}`
        )

        if (res.status === 200) {
          const { data: instance } = res.data
          const { config } = instance
          let instanceValues: Record<string, string> = {
            name: instance.name,
            description: instance.description || ''
          }
          if (config.schema) {
            config.schema.forEach((n: Record<string, any>) => {
              instanceValues[n.name] = ''
            })
          } else {
            // const regex = /^\*.*\*$/
            instanceValues = {
              ...instanceValues,
              ...config
            }

            // for (const [key, value] of Object.entries(config)) {
            // const test = regex.test(value as string)
            // if (test) {
            //   instanceValues[key] = ''
            // }
            // }
          }
          setInitialValues(instanceValues)
          formik.setValues(instanceValues)
          setSavedValues(instanceValues as any)
        }
      } catch (e) {
        console.error(e)
      }
    },
    [params]
  )

  const fetchAllCpis = async () => {
    try {
      const res = await axios.get('/api/v3/cpis')

      if (res.status === 200) {
        setAllCpis(res.data.data.cpis)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchData = async () => {
    setDataLoading(true)

    try {
      await fetchIntegrations()
      await fetchAllCpis()
      if (setupType === 'edit' && params.instanceId) {
        await fetchInstanceConfig()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setDataLoading(false)
    }
  }

  useEffect(() => {
    fetchData().catch((e) => e)
  }, [setupType, params.instanceId])

  useEffect(() => {
    if (integration && integration.config.schema) {
      const result = integration.config.schema
      const items: SchemaItem[] = []
      result.forEach((n) => {
        const item = {
          name: n.name,
          label: n.title,
          default: n.default ?? null,
          required: n.required
        }
        items.push(item)
      })
      setSchema(items)

      if (setupType === 'add') {
        const initialValues: Record<string, string> = {}
        if (integration.config.schema) {
          integration.config.schema.forEach((n) => {
            initialValues[n.name] = ''
          })
        }
        setInitialValues(initialValues)
        formik.setValues({ ...formik.values, ...initialValues })
        setSavedValues({ ...formik.values, ...initialValues } as any)
      }
    }
  }, [integration])

  const saveDataFilters = async (section: string) => {
    const fields = dataFilters.map((filter) => {
      const payload: any = {
        ...filter
      }

      const found = jiraFields.find((c) => c.id === filter.id)
      const operator = jiraOperators.find((c) => c.displayName === filter.operator)

      if (found) {
        payload.jqlField = found.jqlField
        payload.id = found.id
        payload.values = found.apiIdField && found.apiNameField ? filter.values : filter.values.map((c) => c.name)
        payload.operator = operator?.name || filter.operator

        if (found.id === 'assignee_accountId') {
          payload.values = selectedOptionsAssignee.map((c) => ({
            id: c.accountId,
            name: c.emailAddress
          }))
        }
        if (found.id === 'reporter_accountId') {
          payload.values = selectedOptionsReporter.map((c) => ({
            id: c.accountId,
            name: c.emailAddress
          }))
        }
      }

      return payload
    })

    const payload = {
      fields
    }

    try {
      await axios.post(`/api/v3/integrations/jira/config/${instanceId}/filter/${section}`, payload)

      return fields
    } catch (err) {
      console.error(err)
    }
  }

  const saveDataMapping = async (section: string) => {
    try {
      const payload: {
        mappings: {
          id: string
          values: {
            id: string
            name: string
            isDefault: boolean
          }[]
        }[]
      } = {
        mappings: []
      }

      dataMappingFields.forEach((mappingField) => {
        const thisApiName = jiraFields.find((n) => n.id === mappingField.jiraField)?.apiName || ''
        const thisOptions = dataMappingOptions.find((c) => c.apiName === thisApiName)
        const optionValues: {
          id: string
          name: string
        }[] = thisOptions ? thisOptions.options.map((c) => ({ id: c.id, name: c.name })) : []
        const findValue = dataMappingValues[mappingField.id]
        if (findValue) {
          payload.mappings.push({
            id: mappingField.id,
            values:
              findValue.length > 0 && typeof findValue[0] !== 'string'
                ? (findValue as any).map((c: any) => ({ ...c, isDefault: dataMappingValues.isDefault }))
                : optionValues.filter((c) => findValue.includes(c.name))
          })
        }
      })

      await axios.post(`/api/v3/integrations/jira/config/${instanceId}/mapping/${section}`, payload)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSave = async () => {
    if (tabValue === 1) {
      setIsSavingFilters(true)
      const section = activeSection === 0 ? 'appsec' : activeSection === 1 ? 'incidents' : 'vulnerabilities'
      clearTimeout(closeNotification.current)

      try {
        const fields = await saveDataFilters(section)
        await saveDataMapping(section)

        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: `${integration?.display_name} Integration changes were saved successfully!`,
              description: ''
            }
          })
        )

        const addCpisCallback = async (cpis: string[]) => {
          const payload = {
            cpis
          }
          /* Add CPIs to integration */
          const res = await axios.post(
            `/api/v3/integrations/config/${params.instanceId || integrationConfigId}/cpis`,
            payload
          )

          if (res.status === 201) {
            const id = res.data.id

            try {
              /* Check async result of adding CPIs to integration */
              const resp = await axios.get(`/api/v3/async/result/${id}`)

              if (resp.status === 200) {
                if (resp.data.status === 'COMPLETED') {
                  dispatch(
                    openToast({
                      variant: ToastVariant.success,
                      props: {
                        text: `${integration?.display_name} was successfully added to ${cpis.length} CPIs.`,
                        description: ''
                      }
                    })
                  )
                  // navigate('/settings/integrations')
                } else {
                  // navigate('/settings/integrations', {
                  //   state: { jobId: id, integrationName: integration?.display_name || '', cpis: cpis.length }
                  // })
                }
              }
            } catch (err) {
              dispatch(
                openToast({
                  variant: ToastVariant.error,
                  props: {
                    text: `Failed to add ${integration?.display_name} to ${cpis.length} CPIs.`,
                    description: ''
                  }
                })
              )
            } finally {
              setTimeout(() => {
                dispatch(closeToast())
              }, 5000)
            }
          }
        }

        const cpisList = (integration?.supported_cpis || []).map((c) => {
          const found: any = allCpis.find((n: any) => n.name === c.name)
          return {
            ...c,
            display_name: found?.display_name || c.name
          }
        })

        dispatch(
          openModal({
            type: ModalType.addCpisToIntegration,
            props: {
              integrationName: formik.values.name || '',
              cpisList,
              addCpisCallback
            }
          })
        )

        setHasSavedDataFilters(true)
        await fetchJiraFiltersSaved()
        await fetchJiraMappingSaved()
        await fetchJiraPreview(fields)
        setProjectDisconnected(false)
      } catch (err) {
        console.error(err)
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Failed Saving Configuration',
              description: (err as any).response?.data?.message || 'Please double check your configuration'
            }
          })
        )
      } finally {
        setTimeout(() => {
          dispatch(closeToast())
        }, 5000)
        setIsSavingFilters(false)
      }
    } else {
      clearTimeout(closeNotification.current)
      setLoading(true)
      setSaving(true)
      const configValues = {
        ...formik.values
      }
      delete configValues.integrationName
      delete configValues.description
      delete configValues.name

      try {
        if (setupType === 'edit') {
          const payload = {
            name: formik.values.name,
            description: formik.values.description,
            config: configValues
          }

          await axios.put(`/api/v3/integrations/config/${params.instanceId}`, payload)
          dispatch(
            openToast({
              variant: ToastVariant.success,
              props: {
                text: 'Integration changes were saved successfully!',
                description: `${integration?.display_name}`
              }
            })
          )
          // await getConfigured()
        } else {
          const payload = {
            integrationName: integration?.name || '',
            description: formik.values.description,
            name: formik.values.name,
            config: configValues
          }

          const configRes = await axios.post('/api/v3/integrations/config', payload)
          const { id } = configRes.data.data
          setIntegrationConfigId(id)
          dispatch(
            openToast({
              variant: ToastVariant.success,
              props: {
                text: 'Integration changes were saved successfully!',
                description: `${integration?.display_name}`
              }
            })
          )

          await fetchInstanceConfig(id)
          setInstanceId(id)
        }

        setSavedValues({
          name: formik.values.name,
          description: formik.values.description,
          ...configValues
        })
        setAllowSave(false)
        setEnablePrompt(false)
        await getConfigured()
      } catch (e) {
        console.error(e)
        setTestStatus(TestStatus.failed)
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Failed Saving Configuration',
              description: (e as any).response?.data?.message || 'Please double check your configuration'
            }
          })
        )
      } finally {
        setLoading(false)
        setSaving(false)
        setTimeout(() => {
          dispatch(closeToast())
        }, 5000)
      }
    }
  }

  const getSetupTypeText = () => {
    const firstLetter = setupType.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = setupType.slice(1)

    return firstLetterCap + remainingLetters
  }

  const handleExpand = () => {
    setInstructionsOpen(!instructionsOpen)
  }

  const handleDiscard = () => {
    if (tabValue === 0) {
      formik.setValues(savedValues)
      setAllowSave(false)
      setTestStatus(TestStatus.unchecked)
      setNameHelperText('')
      formik.setFieldError('name', undefined)
    } else {
      if (hasSavedDataFilters) return
      setDataFilters([emptyDataFilter])
      setDataMappingValues({
        closed_status: [],
        critical_severity: [],
        high_severity: [],
        medium_severity: [],
        low_severity: []
      })
      filtersFormik.setValues((pre: any) => ({
        ...pre,
        fields: [emptyFormikField]
      }))
      const sectionName = activeSection === 0 ? 'appsec' : activeSection === 1 ? 'incidents' : 'vulnerabilities'
      setSectionValues((pre) => ({
        ...pre,
        [sectionName]: {
          dataFilters: [emptyDataFilter],
          dataMappings: {
            closed_status: [],
            critical_severity: [],
            high_severity: [],
            medium_severity: [],
            low_severity: []
          }
        }
      }))
    }
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    if (enablePrompt) {
      setEnablePrompt(false)
      dispatch(
        openModal({
          type: ModalType.cancelSetupIntegrationChanges,
          props: { okCallback, confirmNavigation, cancelNavigation }
        })
      )
    } else {
      if (setupType === 'edit') {
        navigate(
          newValue === 0
            ? `/settings/integrations/atlassian_jira/${instanceId}/edit`
            : `/settings/integrations/atlassian_jira/${instanceId}/edit/appsec`
        )
      }
      if (setupType === 'add') {
        navigate(
          newValue === 0
            ? `/settings/integrations/atlassian_jira/add`
            : `/settings/integrations/atlassian_jira/add/appsec`
        )
      }

      setTabValue(newValue)
    }
  }

  const handleChangeSection = (newSection: number) => {
    if (activeSection === newSection) return

    const sectionName = newSection === 0 ? 'appsec' : newSection === 1 ? 'incidents' : 'vulnerabilities'

    if (!discardP2Disabled) {
      setEnablePrompt(false)
      dispatch(
        openModal({
          type: ModalType.cancelSetupIntegrationChanges,
          props: { okCallback: () => okCallbackSection(newSection), confirmNavigation, cancelNavigation }
        })
      )
    } else {
      setJiraFiltersLoading(true)
      setPreviewData(null)
      setValueAssignee([emptyUser])
      setInputValueAssignee('')
      setVendorsAssignee([emptyUser])
      setValueReporter([emptyUser])
      setInputValueReporter('')
      setVendorsReporter([emptyUser])

      const currentSectionName = activeSection === 0 ? 'appsec' : activeSection === 1 ? 'incidents' : 'vulnerabilities'
      const sectionDataFilters = [...dataFilters]
      const sectionDataMappings = { ...dataMappingValues }
      const newSectionValues = {
        ...sectionValues,
        [currentSectionName]: {
          dataFilters: sectionDataFilters,
          dataMappings: sectionDataMappings
        }
      }
      setSectionValues(newSectionValues)
      const newSectionFilters = sectionValues[sectionName].dataFilters
      const newSectionAssignee: any = newSectionFilters.find((c) => c.id === 'assignee_accountId')
      if (newSectionAssignee) {
        const user = {
          ...emptyUser,
          avatarUrls: {
            ...(newSectionAssignee.values.length
              ? newSectionAssignee.values[0].avatarUrls
              : {
                  '16x16': '',
                  '24x24': '',
                  '32x32': '',
                  '48x48': ''
                })
          },
          emailAddress: newSectionAssignee.values.length ? newSectionAssignee.values[0].name : '',
          displayName: newSectionAssignee.values.length ? newSectionAssignee.values[0].name : '',
          accountId: newSectionAssignee.values.length ? newSectionAssignee.values[0].id : ''
        }
        setValueAssignee([user])
        setInputValueAssignee(newSectionAssignee.values.length ? newSectionAssignee.values[0].name : '')
        setVendorsAssignee([user])
        setSelectedOptionsAssignee([user])
      } else {
        setSelectedOptionsAssignee([])
      }
      const newSectionReporter: any = newSectionFilters.find((c) => c.id === 'reporter_accountId')
      if (newSectionReporter) {
        const user = {
          ...emptyUser,
          avatarUrls: {
            ...(newSectionReporter.values.length
              ? newSectionReporter.values[0].avatarUrls
              : {
                  '16x16': '',
                  '24x24': '',
                  '32x32': '',
                  '48x48': ''
                })
          },
          emailAddress: newSectionReporter.values.length ? newSectionReporter.values[0].name : '',
          displayName: newSectionReporter.values.length ? newSectionReporter.values[0].name : '',
          accountId: newSectionReporter.values.length ? newSectionReporter.values[0].id : ''
        }
        setValueReporter([user])
        setInputValueReporter(newSectionReporter.values.length ? newSectionReporter.values[0].name : '')
        setVendorsReporter([user])
        setSelectedOptionsReporter([user])
      } else {
        setSelectedOptionsReporter([])
      }

      navigate(`/settings/integrations/atlassian_jira/${instanceId}/${setupType}/${sectionName}`)

      const thisDataFilterApiNames = sectionValues[sectionName].dataFilters.map((c) => c.apiName)
      const newJiraFields = jiraFields.filter((c) => thisDataFilterApiNames.includes(c.apiName))

      setDataFilters(sectionValues[sectionName].dataFilters)
      setDataMappingValues(sectionValues[sectionName].dataMappings)
      filtersFormik.setValues((pre: any) => ({
        ...pre,
        fields: newJiraFields.length > 0 ? newJiraFields : [emptyFormikField]
      }))
      setActiveSection(newSection)
      setRefetch({
        section: sectionName,
        fetch: true
      })
    }
  }

  const userRole = useMemo(() => authUser?.roles[0].name || '', [authUser])

  if (!integration || dataLoading) {
    return <IntegrationsContainer />
  }
  return (
    <>
      <IntegrationsContainer hasPadding={true}>
        <JiraHeader
          handleSave={handleSave}
          handleDiscard={handleDiscard}
          allowSave={allowSave}
          handleBack={() => {
            navigate('/settings/integrations')
          }}
          headerTitle={`${getSetupTypeText()} ${integration.display_name} Integration Instance`}
          testStatus={testStatus}
          isSubmitting={formik.isSubmitting}
          isValid={formik.isValid}
          loading={loading}
          schema={integration?.config?.schema}
          discardDisabled={tabValue === 0 ? discardDisabled : discardP2Disabled}
          disabledP2={p2Disabled}
          tabValue={tabValue}
          userRole={userRole}
        />

        <JiraTabsWrapper custom={true} className="jira-tabs-wrapper">
          <JiraTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="jira instance tabs"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            isdetails="true"
          >
            <Tab label="Data Connection" {...a11yProps(0)} />
            <Tab label="Connected Projects" {...a11yProps(1)} />
          </JiraTabs>
        </JiraTabsWrapper>

        <TabPanel value={tabValue} index={0} sx={{ height: '100%', maxHeight: 'calc(100vh - 164px)' }}>
          <DataConnection
            formik={formik}
            testStatus={testStatus}
            setTestStatus={setTestStatus}
            setAllowSave={setAllowSave}
            instructionsOpen={instructionsOpen}
            handleExpand={handleExpand}
            integration={integration}
            visibleValues={visibleValues}
            setVisibleValues={setVisibleValues}
            loading={loading}
            testing={testing}
            integrationLoading={integrationLoading}
            instructions={instructions}
            setDialogProps={setDialogProps}
            setDialogOpen={setDialogOpen}
            nameHelperText={nameHelperText}
            setNameHelperText={setNameHelperText}
            userRole={userRole}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1} sx={{ height: '100%', maxHeight: 'calc(100vh - 164px)' }}>
          <ConnectedProjects
            setupType={setupType}
            noDataMappingFields={noDataMappingFields}
            handleChangeSection={handleChangeSection}
            setProjectDisconnected={setProjectDisconnected}
            instanceLoaded={instanceLoaded}
            instanceId={instanceId}
            activeSection={activeSection}
            initialLoadProject={initialLoadProject}
            hasSavedDataFilters={hasSavedDataFilters}
            dataFilters={dataFilters}
            setDataFilters={setDataFilters}
            setHasSavedDataFilters={setHasSavedDataFilters}
            setPreviewData={setPreviewData}
            filtersFormik={filtersFormik}
            jiraFields={jiraFields}
            jiraOperators={jiraOperators}
            searchByJiraField={searchByJiraField}
            fetchJiraPreview={fetchJiraPreview}
            dataMappingFields={dataMappingFields}
            dataMappingOptions={dataMappingOptions}
            dataMappingValues={dataMappingValues}
            setDataMappingValues={setDataMappingValues}
            dataMappingDefaults={dataMappingDefaults}
            dataMappingDefaultsValues={dataMappingDefaultsValues}
            setDataMappingDefaultsValues={setDataMappingDefaultsValues}
            previewData={previewData}
            initialLoadPreview={initialLoadPreview}
            jiraFiltersLoading={jiraFiltersLoading}
            valueAssignee={valueAssignee}
            setValueAssignee={setValueAssignee}
            valueReporter={valueReporter}
            setValueReporter={setValueReporter}
            inputValueAssignee={inputValueAssignee}
            setInputValueAssignee={setInputValueAssignee}
            inputValueReporter={inputValueReporter}
            setInputValueReporter={setInputValueReporter}
            vendorsAssignee={vendorsAssignee}
            setVendorsAssignee={setVendorsAssignee}
            vendorsReporter={vendorsReporter}
            setVendorsReporter={setVendorsReporter}
            selectedOptionsAssignee={selectedOptionsAssignee}
            setSelectedOptionsAssignee={setSelectedOptionsAssignee}
            selectedOptionsReporter={selectedOptionsReporter}
            setSelectedOptionsReporter={setSelectedOptionsReporter}
            userRole={userRole}
          />
        </TabPanel>
      </IntegrationsContainer>
      <EnlargeImageDialog handleCloseDialog={handleCloseDialog} open={dialogOpen} dialogProps={dialogProps} />
    </>
  )
}

export default SetupJiraInstance
