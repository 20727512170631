import React, { FC, SVGProps } from 'react'
import theme from 'theme'

type Props = SVGProps<SVGSVGElement>

const InfoIconDangerFilled: FC<Props> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 1.83301C4.32065 1.83301 1.33398 4.81967 1.33398 8.49967C1.33398 12.1797 4.32065 15.1663 8.00065 15.1663C11.6807 15.1663 14.6673 12.1797 14.6673 8.49967C14.6673 4.81967 11.6807 1.83301 8.00065 1.83301ZM7.33398 11.833V10.4997H8.66732V11.833H7.33398ZM7.33398 5.16634V9.16634H8.66732V5.16634H7.33398Z"
      fill={theme.baseColors.info[50]}
    />
  </svg>
)

export default InfoIconDangerFilled
