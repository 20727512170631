import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)<{ is_coming_soon: string }>`
  width: 256px;
  min-width: 256px;
  height: 492px;
  position: relative;

  @media (min-width: 1440px) {
    width: 300px;
    min-width: 300px;
  }

  @media (min-width: 1920px) {
    width: 317px;
    min-width: 317px;
  }

  .coming-soon-wrapper {
    position: absolute;
    top: 7px;
    left: 123px;

    .chip-title {
      white-space: nowrap;
      text-transform: none;
    }
  }

  .new-wrapper {
    position: absolute;
    top: 0;
    left: 76px;

    .chip-title {
      white-space: nowrap;
      text-transform: none;
    }
  }
`

export const Background = styled(Box)<{ backgroundUrl: string }>`
  width: 100%;
  height: 256px;
  background: ${({ backgroundUrl }) => `url("${backgroundUrl}") no-repeat`};
  background-size: cover;
  background-position: center center;
  border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
`

export const Content = styled(Box)`
  width: 100%;
  height: auto;
  min-height: 236px;
  padding: ${theme.spacing.medium}px ${theme.spacing.medium}px ${theme.spacing.large}px;
  gap: ${theme.spacing.xSmall}px;
  border-radius: 0 0 ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundDarker};
  display: flex;
  flex-direction: column;

  .title {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 28px;
  }

  .text {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;
    letter-spacing: 0.25px;
    flex-grow: 1;
    margin-bottom: ${theme.spacing.xSmall}px;
  }
`
