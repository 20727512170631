import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const FrameworksTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '33px',
    padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.surfaceItemBackgroundLow,
    boxShadow: theme.attributes.boxShadow.selectFieldPaper,
    margin: 0,
    width: '99px'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    '.arrow-up': {
      position: 'absolute',
      top: '-20px',
      left: 'calc(50% - 8px)'
    }
  },
  '.tooltip-text': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: 'normal',
    whiteSpace: 'nowrap'
  }
})
