import { FC, SVGProps } from 'react'
import theme from 'theme'

const PaginationArrowRight: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.11499 18.23L1.88499 20L11.885 10L1.88499 0L0.11499 1.77L8.34499 10L0.11499 18.23Z"
      fill={theme.baseColors.greyscale[80]}
    />
  </svg>
)

export default PaginationArrowRight
