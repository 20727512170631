import theme from '../../../../theme'

const AuditResetPasswordIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 9.96387C4.5 8.72637 5.0025 7.60137 5.82 6.78387L4.755 5.71887C3.675 6.80637 3 8.30637 3 9.96387C3 13.0239 5.2875 15.5439 8.25 15.9114V14.3964C6.1275 14.0364 4.5 12.1914 4.5 9.96387ZM15 9.96387C15 6.64887 12.315 3.96387 9 3.96387C8.955 3.96387 8.91 3.97137 8.865 3.97137L9.6825 3.15387L8.625 2.08887L6 4.71387L8.625 7.33887L9.6825 6.28137L8.8725 5.47137C8.9175 5.47137 8.9625 5.46387 9 5.46387C11.4825 5.46387 13.5 7.48137 13.5 9.96387C13.5 12.1914 11.8725 14.0364 9.75 14.3964V15.9114C12.7125 15.5439 15 13.0239 15 9.96387Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default AuditResetPasswordIcon
