import { FormControl as MuiFormControl } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const FormControl = styled(MuiFormControl)<{ iserrored?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto !important;
  position: unset;
  gap: ${theme.spacing.xSmall}px;

  .helper-text {
    color: ${theme.baseColors.info[50]};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    position: unset;
  }

  ${({ iserrored }) =>
    iserrored &&
    iserrored === 'true' &&
    `
      .MuiOutlinedInput-root {
        fieldset {
          border-color: ${theme.colors.textPrimary} !important;
        }
      }
    `};

  .form-control-label {
    color: ${theme.colors.white};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    position: unset;
  }

  .MuiOutlinedInput-root {
    width: 100%;

    &.Mui-disabled {
      color: ${theme.colors.white};
      -webkit-text-fill-color: ${theme.colors.white};

      .MuiOutlinedInput-input {
        &::placeholder {
          color: ${theme.colors.white};
          -webkit-text-fill-color: ${theme.colors.white};
        }
      }
    }

    .MuiOutlinedInput-input {
      &::placeholder {
        overflow: hidden;
        color: ${theme.colors.textSecondaryLow} !important;
        -webkit-text-fill-color: ${theme.colors.textSecondaryLow} !important;
        text-overflow: ellipsis;
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
        opacity: 1;
      }

      &.Mui-disabled {
        color: ${theme.colors.white};
        -webkit-text-fill-color: ${theme.colors.white};
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiOutlinedInput-root {
      width: 100%;

      .MuiOutlinedInput-input {
        &::placeholder {
          overflow: hidden;
          color: ${theme.colors.textSecondaryLow};
          text-overflow: ellipsis;
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
          opacity: 1;
        }
      }
    }
  }
`
