import { FC, useMemo } from 'react'
import { IconButton, Typography } from '@mui/material'
import theme from 'theme'

/* Utils */
import { formatNumberCommas } from '../../../../lib/utils'
import { SSMAssetType, SSMNistPillar, SSMResponseProduct } from '../../../../models'

/* Components */
import { Container } from './budgetViewCard.styles'
import SSMEditCellIcon from '../../../components/svg/ssm/SSMEditCellIcon'

const icons = [
  '/ssm/budget-icons/scale1.svg',
  '/ssm/budget-icons/scale2.svg',
  '/ssm/budget-icons/scale3.svg',
  '/ssm/budget-icons/scale4.svg',
  '/ssm/budget-icons/scale5.svg'
]
const colors = [theme.baseColors.info[20], '#83BC63', '#57A244', '#2C8926', '#007007']

interface Props {
  handleOpenDrawer: (nist: SSMNistPillar, asset: SSMAssetType, assets: SSMResponseProduct[]) => void
  nist: SSMNistPillar
  asset: SSMAssetType
  budgetBreakpoints: number[]
  items: SSMResponseProduct[]
  sumNotMatching: boolean
  cellTotalCost: number
  itemHeight: number
}

const BudgetViewCard: FC<Props> = ({
  handleOpenDrawer,
  cellTotalCost,
  nist,
  budgetBreakpoints,
  asset,
  items,
  sumNotMatching,
  itemHeight
}) => {
  const background = useMemo(() => {
    if (!items.length) return theme.colors.primaryDark
    if (cellTotalCost <= budgetBreakpoints[0]) {
      return colors[0]
    }
    if (cellTotalCost > budgetBreakpoints[0] && cellTotalCost <= budgetBreakpoints[1]) {
      return colors[1]
    }
    if (cellTotalCost > budgetBreakpoints[1] && cellTotalCost <= budgetBreakpoints[2]) {
      return colors[2]
    }
    if (cellTotalCost > budgetBreakpoints[2] && cellTotalCost <= budgetBreakpoints[3]) {
      return colors[3]
    }
    if (cellTotalCost > budgetBreakpoints[3] && cellTotalCost <= budgetBreakpoints[4]) {
      return colors[4]
    }

    return theme.colors.primaryDark
  }, [cellTotalCost, items, budgetBreakpoints])

  const icon = useMemo(() => {
    if (cellTotalCost <= budgetBreakpoints[0]) {
      return icons[0]
    }
    if (cellTotalCost > budgetBreakpoints[0] && cellTotalCost <= budgetBreakpoints[1]) {
      return icons[1]
    }
    if (cellTotalCost > budgetBreakpoints[1] && cellTotalCost <= budgetBreakpoints[2]) {
      return icons[2]
    }
    if (cellTotalCost > budgetBreakpoints[2] && cellTotalCost <= budgetBreakpoints[3]) {
      return icons[3]
    }
    if (cellTotalCost > budgetBreakpoints[3] && cellTotalCost <= budgetBreakpoints[4]) {
      return icons[4]
    }

    return icons[0]
  }, [cellTotalCost, budgetBreakpoints])

  return (
    <Container backgroundColor={background} className={`${items.length > 0 && 'with-items'}`} height={itemHeight}>
      <IconButton className="edit-icon-button" onClick={() => handleOpenDrawer(nist, asset, items)}>
        <SSMEditCellIcon />
      </IconButton>
      {items.length > 0 && <img src={icon} alt="" width="32px" />}
      {items.length > 0 && <Typography>{formatNumberCommas(cellTotalCost)}</Typography>}
      {sumNotMatching && (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
          <circle cx="4.09999" cy="4.5" r="4" fill={theme.baseColors.info[30]} />
        </svg>
      )}
    </Container>
  )
}

export default BudgetViewCard
