import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  padding: 9px ${theme.spacing.small}px ${theme.spacing.xSmall}px ${theme.spacing.small}px;
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.sm}px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  border: 1px solid ${theme.colors.controlBorder};
  background: ${theme.colors.backgroundLight};
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  min-width: 258px;
  margin-bottom: ${theme.spacing.small}px;

  .header {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
    margin-bottom: 3px;

    .title {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }

  .description {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: ${theme.spacing.xSmall}px;
    word-break: break-word;
  }

  .date {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${theme.colors.controlBorder};
    margin-bottom: 0;
  }

  .actions-wrapper {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xSmall}px;
    justify-content: center;

    .edit-button,
    .pined-button {
      svg {
        width: 20px !important;
        height: 20px !important;
      }

      :hover {
        background: rgba(208, 195, 204, 0.15);
      }
    }

    .is-pined {
      background: ${theme.colors.textPrimary};
      border-color: ${theme.colors.controlBorder};
    }

    .is-disabled {
      cursor: inherit;
      border-color: ${theme.colors.controlBorder};
      background: rgba(227, 227, 227, 0.13);
    }

    .edit-button {
      svg {
        path {
          fill: ${theme.colors.white};
        }
      }
    }

    .MuiIconButton-root {
      border: 1px solid ${theme.colors.controlBorder};
      padding: 5px;
    }
  }
`
