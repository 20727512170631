import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`

export const Content = styled(Box)<{ isFiltersOpen?: string }>`
  display: flex;
  justify-content: center;
  height: calc(100vh - 100px);
  overflow-y: hidden;
  padding-bottom: ${theme.spacing.xLarge}px;
  width: 100%;
  flex-basis: 100%;
  transition: all 0.2s ease-in-out;

  ${({ isFiltersOpen }) =>
    isFiltersOpen &&
    isFiltersOpen === 'true' &&
    `
      height: calc(100vh - 188px);
    `};

  .insights-list-wrapper {
    width: 100%;
    flex-basis: 50%;
    padding: ${theme.spacing.small}px ${theme.spacing.medium}px 0;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: ${theme.colors.borderLow};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.textPrimary};
      border-radius: ${theme.attributes.borderRadius.quaternary};
    }
  }
`

export const NoResultsWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${theme.spacing.large}px;

  .no-results-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing.xSmall}px;

    .no-results-header {
      color: ${theme.colors.white};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;
    }

    .no-results-text {
      color: ${theme.baseColors.secondary[80]};
      text-align: center;
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }
  }

  .svg-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`

export const dateRangePickerPopperSx = {
  '.MuiPaper-root': {
    position: 'absolute',

    display: 'flex',
    background: 'transparent',

    '.MuiPickersToolbar-root': {
      display: 'none'
    },
    '.MuiPickersLayout-root': {
      borderRadius: theme.attributes.borderRadius.mediumSmall,
      background: theme.colors.primaryLight,
      display: 'flex',
      flexDirection: 'column',

      '.MuiPickersCalendarHeader-root': {
        '.MuiPickersCalendarHeader-labelContainer': {
          '.MuiPickersCalendarHeader-label': {
            color: theme.baseColors.greyscale[80],
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily.primary,
            fontSize: theme.typography.fontSize.large,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeight.bold,
            lineHeight: '20px',
            letterSpacing: '0.1px'
          }
        },

        '.MuiPickersArrowSwitcher-root': {
          '.MuiButtonBase-root': {
            color: theme.baseColors.greyscale[80],

            '&.Mui-disabled': {
              opacity: 0.26
            }
          }
        }
      },

      '.MuiDayCalendar-root': {
        '.MuiDayCalendar-header': {
          '.MuiTypography-root': {
            color: theme.colors.neutral,
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily.primary,
            fontSize: theme.typography.fontSize.medium,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeight.bold,
            lineHeight: '16px',
            letterSpacing: '0.5px'
          }
        },

        '.MuiDayCalendar-monthContainer': {
          '.MuiDayCalendar-weekContainer': {
            '.MuiDateRangePickerDay-hiddenDayFiller': {
              background: 'transparent !important'
            }
          },

          '.MuiDateRangePickerDay-root': {
            '.MuiButtonBase-root': {
              color: theme.colors.neutral,
              textAlign: 'center',
              fontFamily: theme.typography.fontFamily.primary,
              fontSize: theme.typography.fontSize.large,
              fontStyle: 'normal',
              fontWeight: theme.typography.fontWeight.normal,
              lineHeight: '20px',
              letterSpacing: '0.25px',

              '&.Mui-selected': {
                borderRadius: theme.attributes.borderRadius.circle,
                color: theme.baseColors.primary[20],
                background: theme.colors.textPrimary
              },

              '&.Mui-disabled': {
                color: theme.baseColors.greyscale[80],
                opacity: 0.38
              }
            },

            '&.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              background: 'rgba(106, 83, 113, 1)'
            }
          }
        }
      },

      '.MuiDialogActions-root': {
        '.MuiButton-root': {
          color: theme.colors.textPrimary,
          textAlign: 'center',
          fontFamily: theme.typography.fontFamily.primary,
          fontSize: theme.typography.fontSize.large,
          fontStyle: 'normal',
          fontWeight: theme.typography.fontWeight.bold,
          lineHeight: '20px',
          letterSpacing: '0.1px',

          '&:first-of-type': {
            textTransform: 'capitalize'
          }
        }
      }
    }
  }
}
