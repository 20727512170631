import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const PerformanceScoreWrapper = styled(Box)<{ isempty: string }>`
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundDarker};
  padding: ${theme.spacing.small}px;
  width: 100%;
  height: 310px;

  @media (min-width: 1920px) {
    height: 310px;
    padding: 22px ${theme.spacing.large}px;
  }

  ${({ isempty }) =>
    isempty === 'true' &&
    `
      opacity: 0.38;
      min-width: 497px;
      height: 367px;
  `};

  .title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title-block {
      display: flex;
      align-items: center;
    }

    .date {
      color: ${theme.colors.controlBorder};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: ${theme.spacing.small}px;
    }
  }

  .pizza-chart-container {
    padding: 0 ${theme.spacing.lg}px ${theme.spacing.medium}px 0;
    margin-left: -15px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (min-width: 1900px) {
      justify-content: center;
      gap: ${theme.spacing.medium}px;
    }
  }

  .pizza-chart-wrapper {
    margin-top: ${theme.spacing.lg}px;
  }
`

export const OverallScoreWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: ${theme.attributes.borderRadius.normal};
  background: ${theme.colors.primaryLight};
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  margin: ${theme.spacing.small}px 0;

  .score-value {
    color: #fbd8f5;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 28px;
  }

  .score-label {
    color: #fbd8f5;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-left: ${theme.spacing.xSmall}px;
    margin-bottom: 2px;
  }
`

export const EmptyPerformanceCategoriesWrapper = styled(Box)`
  width: 214px;
  height: 195px;
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  border-radius: ${theme.attributes.borderRadius.quaternary};
  margin-left: ${theme.spacing.lg};

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1280px) {
    margin-left: 45px;
  }

  .category-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.small}px ${theme.spacing.md}px;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.borderLow};
    }

    &:first-of-type {
      border-radius: ${theme.spacing.small}px ${theme.spacing.small}px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 ${theme.spacing.small}px ${theme.spacing.small}px;
    }

    &:hover {
      background: ${theme.colors.primaryLight};
      cursor: pointer;
      border-bottom: 1px solid ${theme.colors.controlBorder};
    }

    .name {
      color: ${theme.baseColors.greyscale[80]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    .value {
      color: ${theme.baseColors.greyscale[80]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      margin-left: ${theme.spacing.xSmall}px;
    }
  }
`

export const PerformanceCategoriesWrapper = styled(Box)`
  max-width: 280px;
  background: ${theme.colors.backgroundMedium};
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  border-radius: ${theme.attributes.borderRadius.quaternary};
  margin-left: 29px;
  max-height: 240px;
  overflow-y: auto;
  width: 240px;
  height: 240px;

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #debdd9;
    border-radius: ${theme.attributes.borderRadius.secondary};
  }
`

export const PerformanceCategoryItem = styled(Box)<{ isselected?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.small}px ${theme.spacing.md}px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.borderLow};
  }

  &:first-of-type {
    border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary};
  }

  &:hover {
    background: ${theme.colors.primaryLight};
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.controlBorder};
  }

  .name {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;

    &:hover {
      color: ${theme.colors.textPrimary};
      text-decoration-line: underline;
    }
  }

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: ${theme.colors.primaryLight};
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.controlBorder};
    
    .name {
      color: ${theme.colors.textPrimary};
      text-decoration-line: underline;
    }
  `};

  .value {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-left: ${theme.spacing.xSmall}px;
  }

  .explore-category {
    color: ${theme.colors.textPrimary};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
`
