import { FC } from 'react'
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { TextField } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { dateFormat } from '../../../../../lib/utils'
import { DateRangePickerContainer } from '../auditLog.styles'
import theme from 'theme'

interface Props {
  dateValues: DateRange<Dayjs>
  handleDateChange: (date: DateRange<Dayjs>) => Promise<void>
}

const AuditDatePicker: FC<Props> = ({ dateValues, handleDateChange }) => {
  return (
    <DateRangePickerContainer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          calendars={1}
          value={dateValues}
          onAccept={(date) => {
            handleDateChange(date)
          }}
          slotProps={{
            popper: {
              sx: {
                '.MuiPaper-root.MuiPickersPopper-paper': {
                  borderRadius: theme.attributes.borderRadius.quaternary,
                  background: theme.colors.primaryLight,

                  '.MuiPickersArrowSwitcher-root': {
                    '.MuiButtonBase-root': {
                      color: theme.baseColors.greyscale[80]
                    }
                  },

                  '.MuiPickersCalendarHeader-label': {
                    color: theme.baseColors.greyscale[80],
                    fontFamily: theme.typography.fontFamily.primary,
                    fontSize: theme.typography.fontSize.large,
                    fontStyle: 'normal',
                    fontWeight: theme.typography.fontWeight.bold,
                    lineHeight: '20px',
                    letterSpacing: '0.1px'
                  },

                  '.MuiDayCalendar-header': {
                    '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel': {
                      color: theme.baseColors.greyscale[80],
                      textAlign: 'center',
                      fontFamily: theme.typography.fontFamily.primary,
                      fontSize: theme.typography.fontSize.xLarge,
                      fontStyle: 'normal',
                      fontWeight: theme.typography.fontWeight.bold,
                      lineHeight: '24px',
                      letterSpacing: '0.1px'
                    }
                  },

                  '.MuiButtonBase-root.MuiPickersDay-root': {
                    color: theme.baseColors.greyscale[80],
                    textAlign: 'center',
                    fontFamily: theme.typography.fontFamily.primary,
                    fontSize: theme.typography.fontSize.xLarge,
                    fontStyle: 'normal',
                    fontWeight: theme.typography.fontWeight.bold,
                    lineHeight: '24px',
                    letterSpacing: '0.1px',

                    '&.Mui-selected': {
                      background: theme.colors.textPrimary,
                      color: '#66006E'
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        background: theme.colors.textSecondaryLow,
                        borderColor: theme.colors.textSecondaryLow
                      }
                    },
                    '&.MuiPickersDay-today': {
                      background: 'transparent',
                      border: `1px solid ${theme.colors.textPrimary}`,
                      color: theme.colors.textPrimary
                    },
                    '&.Mui-disabled': {
                      color: theme.colors.controlBorder
                    }
                  },

                  '.MuiButton-root': {
                    color: theme.colors.textPrimary,
                    fontFamily: theme.typography.fontFamily.primary,
                    fontSize: theme.typography.fontSize.large,
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    margin: 0
                  }
                }
              }
            },
            actionBar: {
              actions: ['cancel', 'accept']
            }
          }}
          format="MM/DD/YYYY"
          slots={{
            textField: (props) => {
              return (
                <>
                  <span>{props.label === 'Start' ? 'From' : 'To'}</span>
                  <TextField
                    className="text-field"
                    {...props}
                    label=""
                    value={`${dayjs((props as any).value).format(dateFormat.auditLogDefault)}`}
                  />
                </>
              )
            }
          }}
          showDaysOutsideCurrentMonth
          disableFuture
        />
      </LocalizationProvider>
    </DateRangePickerContainer>
  )
}

export default AuditDatePicker
