import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)<{ align?: string }>`
  position: static;
  padding: ${theme.spacing.medium}px;
  top: 100px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -ms-available;
  min-height: 100px;
  z-index: 1001;
  display: flex;
  background: transparent;
  align-items: ${({ align }) => (align ? 'flex-end' : ' center')};
`
