import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Box, ListItemText, MenuItem, OutlinedInput, SelectProps } from '@mui/material'
import { SelectDropdownIcon } from '../../../components/svg'
import { CategoryMenuProps, NavigationFormControl, NavigationSelect } from './NavigationSelectField.styles'
import SelectSearchbar from '../../../components/form-components/SelectSearchbar'
import useSearchLabel from '../../../../hooks/useSearchLabel'
import { CPILibraryItem, IChartDataCategory } from '../../../../models'
import { useNavigate } from 'react-router-dom'
import { formatCPIDisplayNameForURL } from '../../../../lib/utils'
import { LibraryDetailsHoverTooltip } from '../library-details/components/LibraryDetailsTooltip'
import { categoryToUrl, urlToCategoryCapitalized } from '../utils'
import theme from 'theme'

interface Props extends SelectProps {
  options: CPILibraryItem[] | IChartDataCategory[]
  value: any
  minWidth?: string
  maxHeight?: string
  noborder?: string
  setInitialLoad?: Dispatch<SetStateAction<boolean>>
  setRenderingChart?: Dispatch<SetStateAction<boolean>>
  version: string
  callback?: () => void
}

const NavigationSelectField: FC<Props> = ({
  options,
  placeholder,
  maxHeight,
  noborder,
  minWidth,
  value,
  version,
  setInitialLoad,
  setRenderingChart,
  callback,
  ...rest
}) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)

  const convertedOptions = options.map((item) => {
    if (version === 'cpi' && 'display_name' in item && 'title' in item) {
      return {
        label: item.display_name,
        name: item.title
      }
    } else if ('score' in item) {
      return {
        label: urlToCategoryCapitalized(item.category),
        name: urlToCategoryCapitalized(item.category)
      }
    }
    return { label: '', name: '' }
  })

  const { search, setSearch, labelOptions, highlightMatch } = useSearchLabel(convertedOptions, null, true)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <NavigationFormControl
      sx={{
        minWidth
      }}
      isselected={
        anchorEl || (placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')))
          ? 'true'
          : 'false'
      }
    >
      <NavigationSelect
        noborder={noborder}
        isselected={
          placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
        }
        labelId="library-select-label"
        id="library-select"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          PaperProps: {
            ...CategoryMenuProps.PaperProps,
            style: {
              ...CategoryMenuProps.PaperProps.style,
              maxHeight: maxHeight ?? CategoryMenuProps.PaperProps.style.maxHeight
            }
          },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null),
          style: { display: anchorEl ? 'block' : 'none' }
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          return selected
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        value={value}
        input={<OutlinedInput onMouseDown={handleClick} />}
        sx={{
          minWidth,
          pointerEvents: rest.disabled ? 'none' : 'unset',
          '.MuiSelect-select.Mui-disabled': {
            '-webkit-text-fill-color': theme.colors.white
          }
        }}
      >
        <SelectSearchbar search={search} setSearch={setSearch} placeholder={`Search ${placeholder}`} />

        <Box
          sx={{
            height: '100%',
            maxHeight: '279px',
            overflowY: 'auto',
            width: '100%'
          }}
        >
          {labelOptions.map((option) => (
            <LibraryDetailsHoverTooltip
              title={version === 'cpi' ? option.label + ' ' + option.name : option.label}
              arrow
              placement="left"
              key={option.label}
            >
              <MenuItem
                value={version === 'cpi' ? option.label + ' ' + option.name : option.label}
                onClick={() => {
                  if (!rest.multiple) {
                    setAnchorEl(null)
                  }
                  if (setInitialLoad) {
                    setInitialLoad(false)
                  }
                  if (setRenderingChart) {
                    setRenderingChart(true)
                  }
                  if (callback) {
                    callback()
                  }

                  navigate(
                    `/library/${
                      version === 'cpi'
                        ? `cpis/${formatCPIDisplayNameForURL(option.label)}`
                        : `categories/${categoryToUrl(option.label)}`
                    }`
                  )
                }}
              >
                <ListItemText
                  className="list-item"
                  primary={highlightMatch(version === 'cpi' ? option.label + ' ' + option.name : option.label)}
                />
              </MenuItem>
            </LibraryDetailsHoverTooltip>
          ))}
        </Box>
      </NavigationSelect>
    </NavigationFormControl>
  )
}

export default NavigationSelectField
