import { FC } from 'react'
import { Typography, IconButton, DialogContent, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import theme from 'theme'

/* Components */
import { Dialog } from './enlargeImageDialog.styles'
import ThemeButton from '../../../../../components/buttons/primary/ThemeButton'

interface Props {
  handleCloseDialog: () => void
  open: boolean
  dialogProps: {
    instruction: string
    image: string
    index: number
  }
}

const EnlargeImageDialog: FC<Props> = ({ handleCloseDialog, open, dialogProps: { instruction, index, image } }) => {
  return (
    <Dialog onClose={handleCloseDialog} aria-labelledby="enlarge-image-dialog" open={open}>
      <Box className="header">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.6667 9.33329H17.3334V12H14.6667V9.33329ZM14.6667 14.6666H17.3334V22.6666H14.6667V14.6666ZM16.0001 2.66663C8.64008 2.66663 2.66675 8.63996 2.66675 16C2.66675 23.36 8.64008 29.3333 16.0001 29.3333C23.3601 29.3333 29.3334 23.36 29.3334 16C29.3334 8.63996 23.3601 2.66663 16.0001 2.66663ZM16.0001 26.6666C10.1201 26.6666 5.33341 21.88 5.33341 16C5.33341 10.12 10.1201 5.33329 16.0001 5.33329C21.8801 5.33329 26.6667 10.12 26.6667 16C26.6667 21.88 21.8801 26.6666 16.0001 26.6666Z"
            fill={theme.colors.textPrimary}
          />
        </svg>
        <Typography className="header-title">Integration Instructions</Typography>
      </Box>

      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          position: 'absolute',
          right: theme.spacing.small,
          top: theme.spacing.small,
          color: theme.colors.textPrimary,

          '&:hover': {
            background: theme.baseColors.primary[20],
            svg: {
              path: {
                fill: theme.colors.white
              }
            }
          }
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Box className="image-wrapper">
          <img src={image} alt="" className="image" />
        </Box>

        <Box className="instructions-right-side">
          <Typography className="step-text">Step {index}</Typography>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{instruction}</ReactMarkdown>
        </Box>
      </DialogContent>

      <Box className="footer">
        <ThemeButton themevariant="secondary" onClick={handleCloseDialog} sx={{ minWidth: '95px' }}>
          Cancel
        </ThemeButton>
        <ThemeButton themevariant="primary" onClick={handleCloseDialog} sx={{ minWidth: '95px' }}>
          OK
        </ThemeButton>
      </Box>
    </Dialog>
  )
}

export default EnlargeImageDialog
