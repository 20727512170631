import theme from 'theme'

const ExportCsvIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.7368 17.7368L22 12.4737L16.7368 7.21053L15.2526 8.69474L17.9684 11.4211H8.31579V13.5263H17.9684L15.2526 16.2526L16.7368 17.7368Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M18.8421 19.8421H4.10526V5.10526H18.8421V7.21053H20.9474V5.10526C20.9474 3.94737 20.0105 3 18.8421 3H4.10526C2.94737 3 2 3.94737 2 5.10526V19.8421C2 21 2.94737 21.9474 4.10526 21.9474H18.8421C20.0105 21.9474 20.9474 21 20.9474 19.8421V17.7368H18.8421V19.8421Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default ExportCsvIcon
