import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isSort }) =>
    !isSort &&
    `
   gap: ${theme.spacing.xSmall}px;
  `};

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    color: ${theme.colors.white};
    white-space: nowrap;
  }
`

export const LibraryFormControl = styled(FormControl)<{
  isselected?: string
  iscustom?: string
}>`
  outline: none;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.large};
  display: flex;
  position: relative;

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
  }
`

export const LibrarySelect = styled(Select)<{ menuopen?: string; isselected?: string }>`
  height: 40px;
  background: transparent;
  gap: ${theme.spacing.xSmall}px;
  position: relative;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder} !important;
  color: ${theme.colors.textPrimary};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.xLarge};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.thin};
  line-height: normal;

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
    padding-right: 48px;
    padding-left: ${theme.spacing.medium}px;
  }

  svg.svg-dropdown {
    right: ${theme.spacing.small}px;
    width: 24px;
    height: 24px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg);
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg);
    }

    path {
      fill: ${theme.colors.textPrimary};
    }
  }

  ${({ menuopen }) =>
    menuopen &&
    menuopen === 'true' &&
    `
    background: ${theme.baseColors.primary[20]};
    color: ${theme.colors.white};
    
    svg.svg-dropdown {
      path {
        fill: ${theme.colors.white};
      }
    }
  `};

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  &:hover {
    border-radius: ${theme.attributes.borderRadius.large};
    border: 1px solid ${theme.colors.controlBorder} !important;
    background: ${theme.baseColors.secondary[30]};
    color: ${theme.baseColors.primary[70]};

    svg.svg-dropdown {
      path {
        fill: ${theme.baseColors.primary[70]};
      }
    }
  }

  &:focus {
    border-color: ${theme.colors.white};
    color: ${theme.colors.textPrimary};
  }

  &.Mui-disabled {
    background: ${theme.baseColors.surface[50]};
    color: ${theme.baseColors.greyscale[50]};
    pointer-events: none;

    .MuiSelect-select.MuiSelect-outlined {
      -webkit-text-fill-color: unset;
    }

    svg.svg-dropdown {
      path {
        fill: ${theme.baseColors.greyscale[50]};
      }
    }
  }
`

export const SelectDropdownMenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.primaryLight,
      borderRadius: theme.attributes.borderRadius.secondary,
      minWidth: '250px !important',
      maxHeight: '600px !important',
      marginTop: `${theme.spacing.half}px`,

      '.MuiList-root': {
        background: theme.colors.primaryLight,
        paddingTop: '8px',
        paddingBottom: `${theme.spacing.xSmall}px`
      },

      '.MuiMenuItem-root': {
        width: '100%',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          padding: `${theme.spacing.xSmall}px`,

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          },

          svg: {
            path: {
              fill: '#d0c3cc'
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          color: theme.colors.white,
          fontSize: theme.typography.fontSize.xLarge,
          fontStyle: 'normal',
          fontWeight: theme.typography.fontWeight.normal,
          lineHeight: 'normal'
        }
      },

      '&::-webkit-scrollbar-track': {
        background: theme.baseColors.surface[50]
      },

      '&::-webkit-scrollbar-thumb': {
        background: theme.colors.textPrimary,
        height: '4px',
        width: '4px',
        border: '4px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
