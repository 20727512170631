import theme from 'theme'

const SLAChangeLabelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM12.1275 11.07L11.3025 10.245C11.835 9.2475 11.7 7.9875 10.86 7.1475C10.3425 6.63 9.675 6.375 9 6.375C8.9775 6.375 8.955 6.3825 8.9325 6.3825L9.75 7.2L8.955 7.995L6.8325 5.8725L8.955 3.75L9.75 4.545L9.03 5.265C9.9825 5.2725 10.9275 5.625 11.655 6.345C12.93 7.6275 13.0875 9.615 12.1275 11.07ZM11.1675 12.1275L9.045 14.25L8.25 13.455L8.9625 12.7425C8.0175 12.735 7.0725 12.3675 6.3525 11.6475C5.07 10.365 4.9125 8.385 5.8725 6.93L6.6975 7.755C6.165 8.7525 6.3 10.0125 7.14 10.8525C7.665 11.3775 8.3625 11.6325 9.06 11.61L8.25 10.8L9.045 10.005L11.1675 12.1275Z"
        fill={theme.baseColors.info[40]}
      />
    </svg>
  )
}

export default SLAChangeLabelIcon
