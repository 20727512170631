import { Box, Slider as MuiSlider } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const CompetitorAnalysisLegend = styled(Box)`
  margin: ${theme.spacing.medium}px 0 38px;
  gap: ${theme.spacing.medium}px;
  display: flex;
  align-items: center;

  .legend-item {
    display: flex;
    align-items: center;

    .legend-color {
      width: 16px;
      height: 16px;

      &.blue {
        border-radius: 2px;
        border: 1px solid #8e9aff;
        background: #8e9aff;
      }

      &.purple {
        border-radius: 2px;
        border: 1px solid ${theme.baseColors.primary[30]};
        background: ${theme.baseColors.primary[30]};
      }

      &.white {
        border: 1px solid ${theme.colors.white};
        background: linear-gradient(180deg, ${theme.colors.white} 0%, rgba(255, 255, 255, 0.4) 100%);
        box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
      }

      &.orange {
        background: linear-gradient(180deg, ${theme.baseColors.info[40]} 0%, rgba(240, 149, 67, 0.4) 100%);
        stroke-width: 2px;
        border: 2px solid ${theme.baseColors.info[40]};
        stroke: ${theme.baseColors.info[40]};
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
        border-radius: ${theme.attributes.borderRadius.circle};
      }
    }

    .legend-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      margin-left: ${theme.spacing.xSmall}px;
    }
  }
`

export const AnalysisGroupsWrapper = styled(Box)``

export const AnalysisItem = styled(Box)`
  margin-bottom: 35px;

  .analysis-category-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const AnalysisItemDataContainer = styled(Box)`
  position: relative;
`

export const Slider = styled(MuiSlider)(() => ({
  color: '#3a8589',
  height: 32,
  padding: '9px 0',

  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    borderRadius: '0',

    '.MuiSlider-valueLabel': {
      width: '200px',
      borderRadius: theme.attributes.borderRadius.secondary,
      border: `1px solid ${theme.colors.controlBorder}`,
      background: theme.colors.borderLow,
      boxShadow: theme.attributes.boxShadow.overallScoreWrapper,
      padding: `${theme.spacing.small}px`,

      '.tooltip-value': {
        '.MuiTypography-root': {
          color: theme.colors.white,
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontStyle: 'normal',
          textAlign: 'start',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap'
        },

        '.tooltip-title': {
          fontSize: theme.typography.fontSize.xxxLarge,
          lineHeight: '32px',
          marginBottom: '3px'
        },

        '&.tooltip-text': {
          fontSize: theme.typography.fontSize.medium,
          lineHeight: '16px',
          letterSpacing: '0.4px'
        }
      }
    },

    '&[data-index="0"]': {
      display: 'none'
    },

    '&[data-index="1"]': {
      width: '32px',
      height: '32px',
      background: `linear-gradient(180deg, ${theme.baseColors.info[40]} 0%, rgba(240, 149, 67, 0.40) 100%)`,
      strokeWidth: '2px',
      stroke: theme.baseColors.info[40],
      border: `2px solid ${theme.baseColors.info[40]}`,
      outline: 'none',
      filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.30)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.15))',
      borderRadius: theme.attributes.borderRadius.circle,

      '&:hover': {
        stroke: '#FFD2AA',
        border: '2px solid #FFD2AA'
      }
    },

    '&[data-index="2"]': {
      width: '8px',
      height: '30px',
      border: `1px solid ${theme.colors.white}`,
      background: `linear-gradient(180deg, ${theme.colors.white} 0%, rgba(255, 255, 255, 0.40) 100%)`,
      boxShadow: theme.attributes.boxShadow.feedSection,
      borderRadius: '0 !important',

      '&:hover': {
        border: '1px solid rbga(255, 255, 255, 0.8)',
        boxShadow: 'none'
      }
    },

    '&[data-index="3"]': {
      display: 'none'
    },

    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)'
    }
  },

  '& .MuiSlider-track': {
    height: 32,
    background: `linear-gradient(90deg, ${theme.baseColors.primary[30]} 0%, #8255B9 47.92%, #8E9AFF 100%)`,
    borderRadius: 0,
    border: 'none'
  },

  '& .MuiSlider-rail': {
    color: theme.colors.primaryDark,
    borderRadius: '2px',
    border: '1px solid #7C7579',
    opacity: 1,
    height: 32
  },

  '& .MuiSlider-mark': {
    display: 'none'
  },

  '& .MuiSlider-markLabel': {
    marginTop: '15px',
    color: theme.baseColors.greyscale[80],
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.medium,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.bold,
    lineHeight: '16px',
    letterSpacing: '0.5px'
  }
}))

export const SliderInactive = styled(MuiSlider)(() => ({
  color: '#3a8589',
  height: 32,
  padding: '9px 0',

  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    borderRadius: '0',

    '.MuiSlider-valueLabel': {
      width: '200px',
      borderRadius: theme.attributes.borderRadius.secondary,
      border: `1px solid ${theme.colors.controlBorder}`,
      background: theme.colors.borderLow,
      boxShadow: theme.attributes.boxShadow.overallScoreWrapper,
      padding: `${theme.spacing.small}px`,

      '.tooltip-value': {
        '.MuiTypography-root': {
          color: theme.colors.white,
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontStyle: 'normal',
          textAlign: 'start',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap'
        },

        '.tooltip-title': {
          fontSize: theme.typography.fontSize.xxxLarge,
          lineHeight: '32px',
          marginBottom: '3px'
        },

        '&.tooltip-text': {
          fontSize: theme.typography.fontSize.medium,
          lineHeight: '16px',
          letterSpacing: '0.4px'
        }
      }
    },

    '&[data-index="0"]': {
      display: 'none'
    },

    '&[data-index="1"]': {
      width: '8px',
      height: '30px',
      border: `1px solid ${theme.colors.white}`,
      background: `linear-gradient(180deg, ${theme.colors.white} 0%, rgba(255, 255, 255, 0.40) 100%)`,
      boxShadow: theme.attributes.boxShadow.feedSection,
      borderRadius: '0 !important',

      '&:hover': {
        border: '1px solid rbga(255, 255, 255, 0.8)',
        boxShadow: 'none'
      }
    },

    '&[data-index="2"]': {
      display: 'none'
    },

    '&[data-index="3"]': {
      display: 'none'
    },

    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)'
    }
  },

  '& .MuiSlider-track': {
    height: 32,
    background: `linear-gradient(90deg, ${theme.baseColors.primary[30]} 0%, #8255B9 47.92%, #8E9AFF 100%)`,
    borderRadius: 0,
    border: 'none'
  },

  '& .MuiSlider-rail': {
    color: theme.colors.primaryDark,
    borderRadius: '2px',
    border: '1px solid #7C7579',
    opacity: 1,
    height: 32
  },

  '& .MuiSlider-mark': {
    display: 'none'
  },

  '& .MuiSlider-markLabel': {
    marginTop: '15px',
    color: theme.baseColors.greyscale[80],
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.medium,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.bold,
    lineHeight: '16px',
    letterSpacing: '0.5px'
  }
}))
