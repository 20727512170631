import { Box, Modal as MuiModal } from '@mui/material'
import styled from '@emotion/styled/macro'
import { DataStatus } from '../../../../../../../models'
import theme from 'theme'

export const Modal = styled(MuiModal)`
  z-index: 3000;

  .modal-wrapper-inner {
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: ${theme.attributes.borderRadius.quaternary};
    border: 2px solid #bb95ea;
    background: linear-gradient(
        180deg,
        rgba(187, 149, 234, 0.3) 0%,
        rgba(187, 149, 234, 0) 30.5%,
        rgba(187, 149, 234, 0) 86%
      ),
      linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #000;
    width: calc(100vw - 115px);
    height: calc(100vh - 50px);
    top: 50%;
    left: 51%;
    padding: 5px ${theme.spacing.md}px ${theme.spacing.md}px ${theme.spacing.xsm}px;
    max-width: 1724px;

    .close-modal-button {
      position: absolute;
      top: ${theme.spacing.xSmall}px;
      right: ${theme.spacing.xSmall}px;
      padding: 0;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.md}px;
    margin-bottom: ${theme.spacing.xsm}px;

    .header-title {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 28px;
    }
  }
`

export const ChartWrapperPredictions = styled(Box)<{
  status?: DataStatus
  severity: 'healthy' | 'problematic'
  zone: string
}>`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  padding: ${theme.spacing.medium}px;
  max-width: 1686px;
  margin-left: auto;
  margin-right: auto;

  .chart-container {
    width: 100%;
    height: 499px;
    margin-right: 41px;
    max-width: 1724px;

    .trends-d3-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .svg-d3 {
        border-bottom-right-radius: ${theme.attributes.borderRadius.quaternary};
        border-bottom-left-radius: ${theme.attributes.borderRadius.quaternary};
        border: 1px solid rgba(147, 143, 153, 0.16);
        background: ${theme.colors.backgroundLight};
        box-shadow: ${theme.attributes.boxShadow.feedSection};

        .event-indicator-count {
          cursor: pointer;
        }

        .add-event-button {
          cursor: pointer;
          display: none;

          &:hover {
            .add-button-circle {
              fill: #4e414d;
              stroke: ${theme.baseColors.greyscale[80]};
            }
          }
        }

        .add-button-tooltip {
          display: none;
        }

        .count-tooltip {
          display: none;
        }

        .sla {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${theme.spacing.small}px;
          background: rgba(208, 188, 255, 0.16);
          box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
          border: 0 solid ${theme.colors.controlBorder};
          border-radius: ${theme.attributes.borderRadius.quaternary};
          backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
          z-index: 1000000;

          .sla-tooltip-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${theme.spacing.small}px;
            background: rgba(208, 188, 255, 0.16);
            box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
            border: 0 solid ${theme.colors.controlBorder};
            border-radius: ${theme.attributes.borderRadius.quaternary};
            backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
            z-index: 1000000;
          }
        }
      }

      .data-point-tooltip {
        display: none;
        position: absolute;
        opacity: 1;
        padding: ${theme.spacing.small}px;
        gap: ${theme.spacing.sm}px;
        border-radius: ${theme.attributes.borderRadius.secondary};
        border: 1px solid ${theme.colors.controlBorder};
        background: ${theme.colors.borderLow};
        box-shadow: ${theme.attributes.boxShadow.chipCircle};
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
        z-index: 10000;

        .prediction-text-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 232px;
          gap: ${theme.spacing.xSmall}px;
          margin-top: -${theme.spacing.half}px;

          .tooltip-performance {
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            line-height: 24px;
            letter-spacing: 0.1px;
          }

          svg {
            margin-left: -${theme.spacing.xsm}px;
          }
        }

        .tooltip-units-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 232px;

          .tooltip-units-prediction {
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.large};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .tooltip-performance,
        .tooltip-units {
          color: ${theme.colors.white};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .tooltip-performance {
          margin: ${theme.spacing.xSmall}px 0;

          &.prediction-date {
            color: ${theme.colors.white};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.bold};
            line-height: 24px;
            letter-spacing: 0.1px;
            margin-top: 2px;
          }
        }

        .tooltip-units {
          margin: 0;
        }

        .severity-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .chart-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  .chart-container::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar-thumb:hover {
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar:vertical {
    display: none;
  }
`
