import { Box, Divider } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-basis: 100%;
  height: 100%;
  min-height: calc(100vh - 100px);
`

export const Section = styled(Box)`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .analysis-description-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${theme.spacing.medium}px;

    .content-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: ${theme.spacing.small}px;
      max-width: 420px;

      .pink-header-text {
        color: ${theme.colors.textPrimary};
        text-align: center;
        font-size: ${theme.typography.fontSize.small};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.thick};
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .header-text {
        color: ${theme.colors.white};
        text-align: center;
        font-size: ${theme.typography.fontSize.header};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: normal;
      }

      .description-text {
        color: ${theme.colors.white};
        text-align: center;
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      }
    }
  }
`

export const Separator = styled(Divider)`
  background: ${theme.colors.borderLow};
  margin: auto;
`
