import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  margin-bottom: ${theme.spacing.small}px;

  .MuiMultiInputDateRangeField-separator {
    display: none;
  }

  .MuiFormControl-root {
    height: 56px;
    width: 100%;

    .MuiInputLabel-root {
      color: ${theme.colors.controlBorder};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      top: 2px;
      background: #141218;
    }

    .MuiOutlinedInput-root {
      height: 56px;
      border: 1px solid ${theme.colors.controlBorder};
      border-radius: ${theme.attributes.borderRadius.tertiary};
      background: transparent;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};

      &.Mui-focused {
        color: ${theme.colors.controlBorder};
        border: 1px solid ${theme.colors.controlBorder};
      }

      input {
        height: 56px;
      }

      svg {
        path {
          fill: ${theme.colors.controlBorder};
        }
      }
    }
  }
`
