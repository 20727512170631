import { Autocomplete } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const StyledAutocomplete = styled(Autocomplete)`
  min-width: 320px;
  height: 40px;
  flex-grow: 1;
  background: transparent;
  border: 1px solid ${theme.colors.controlBorder};
  border-radius: ${theme.attributes.borderRadius.small};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${theme.baseColors.greyscale[80]};

  @media (max-width: 1330px) {
    min-width: 270px;
  }

  &.Mui-focused {
    border: 1px solid ${theme.colors.textPrimary};
  }

  .MuiOutlinedInput-root {
    padding: 0 ${theme.spacing.small}px;
    height: 40px;
    color: #e3e3e3;
    min-width: 320px;

    .MuiAutocomplete-endAdornment {
      top: 1px;

      .MuiButtonBase-root {
        padding: ${theme.spacing.xsm}px;
        visibility: visible;

        &:hover {
          background: rgba(202, 196, 208, 0.08);
        }

        svg {
          path {
            fill: ${theme.baseColors.greyscale[80]};
          }
        }
      }
    }

    .MuiOutlinedInput-input {
      padding-left: ${theme.spacing.sm}px;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      letter-spacing: 0.1px;
      overflow: hidden;
      color: ${theme.colors.white};
      text-overflow: ellipsis;
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;

      &::placeholder {
        opacity: 1;
        overflow: hidden;
        color: ${theme.colors.textSecondaryLow};
        text-overflow: ellipsis;
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      }
    }

    &::placeholder {
      opacity: 1;
    }

    fieldset {
      border: none;
    }
  }
`
