import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const CPIsContainer = styled(Box)`
  width: 100%;
  flex-basis: 50%;
  gap: ${theme.spacing.md}px;
  display: flex;
  flex-direction: column;
`

export const CPIsHeader = styled(Box)`
  gap: ${theme.spacing.small}px;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding-right: ${theme.spacing.medium}px;

  .cpis-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;

    .header-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      flex-grow: 1;
    }
  }
`

export const Content = styled(Box)<{ haseditrole?: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 216px);
  padding-right: ${theme.spacing.xSmall}px;

  .categories-weight-header {
    background: ${theme.colors.surfaceContainerBackground};
    border-radius: ${theme.attributes.borderRadius.quaternary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 43px;
    padding: ${theme.spacing.md}px ${theme.spacing.medium}px;

    > .MuiTypography-root {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;

      &:last-of-type {
        &:last-of-type {
          margin-right: ${({ haseditrole }) =>
            haseditrole && haseditrole === 'true' ? '80px' : `${theme.spacing.medium}px`};
        }
      }
    }
  }
`

export const CpisList = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.md}px;
  width: 100%;
`

export const NothingFound = styled(Box)`
  flex-direction: column;
  gap: ${theme.spacing.small}px;
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  .nothing-found-title {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.epic};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }

  .nothing-found-text {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background: ${theme.colors.controlBorder};
  margin: ${theme.spacing.half}px 0;
`

export const CpiListSummary = styled(Box)<{ iserrored: string; haseditrole?: string }>`
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.surfaceItemBackground};
  display: flex;
  padding: ${theme.spacing.md}px ${theme.spacing.medium}px;
  justify-content: ${({ haseditrole }) => (haseditrole && haseditrole === 'true' ? 'space-between' : 'flex-end')};
  align-items: center;
  min-height: 80px;

  .summary-start,
  .summary-end {
    display: flex;
    align-items: center;
  }

  .summary-start {
    gap: ${theme.spacing.medium}px;
  }

  .summary-end {
    gap: ${theme.spacing.xSmall}px;

    .total-text {
      color: ${theme.baseColors.greyscale[80]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .total-value {
      color: ${({ iserrored }) => (iserrored === 'true' ? theme.baseColors.info[50] : theme.colors.white)};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 28px;
    }
  }
`
