import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Box, Chip, Stack, Typography } from '@mui/material'

/* Utils */
import {
  CategoryDataPayload,
  CPITimePeriod,
  HistoryBenchmarkData,
  HistoryPerformanceData,
  IFramework,
  PerformanceScoreCpi
} from '../../../../../../models'
import { DataSourceError } from '../../../../dashboard/sections/category-activity/components/DataSources'

/* Components */
import { CategoryTabWrapper } from '../../categoryPage.styles'
import { EmptyDataContainer } from './currentFindings.styles'
import PerformanceScoreHistory from '../historical-data/components/PerformanceScore'
import PerformanceScore from './components/PerformanceScore'
import { ChipsContainer } from '../../../components/library-components.styles'
import { defaultCategoriesTimePeriodValues } from '../../../library.constants'
import { categoryToUrl, getPayloadTimePeriod } from '../../../utils'
import { Category } from '../../CategoryPage'
import dayjs from 'dayjs'
import { dateFormat } from '../../../../../../lib/utils'

interface Props {
  allCategories: Category[]
  containersMaxWidth: number
  categoryName: string
  performanceValue: number
  performanceRows: PerformanceScoreCpi[]
  performanceLoading: boolean
  historyPerformanceData: HistoryPerformanceData[]
  selectedTimePeriod: CPITimePeriod
  historyBenchmarkData: HistoryBenchmarkData[]
  setSelectedTimePeriod: Dispatch<SetStateAction<CPITimePeriod>>
  fetchHistoryPerformance: (payload: CategoryDataPayload) => void
  fetchHistoryBenchmark: (payload: CategoryDataPayload) => void
  lastUpdatePerformance: string
  fw: string
  customFramework: IFramework | null
  dataSourceErrors: DataSourceError[]
}

const PerformanceTab: FC<Props> = ({
  allCategories,
  containersMaxWidth,
  categoryName,
  performanceValue,
  performanceRows,
  performanceLoading,
  historyPerformanceData,
  selectedTimePeriod,
  historyBenchmarkData,
  setSelectedTimePeriod,
  fetchHistoryPerformance,
  fetchHistoryBenchmark,
  lastUpdatePerformance,
  fw,
  customFramework,
  dataSourceErrors
}) => {
  const numberOfStripes = useMemo(() => {
    switch (selectedTimePeriod) {
      case CPITimePeriod.Week:
        return 7
      case CPITimePeriod.Month:
        return 4
      case CPITimePeriod.ThreeMonths:
        return 3
      case CPITimePeriod.SixMonths:
        return 6
      default:
        return 12
    }
  }, [selectedTimePeriod, historyPerformanceData])

  const handleChangeTimePeriod = async (period: CPITimePeriod) => {
    setSelectedTimePeriod(period)
    const cat = categoryToUrl(categoryName)
    let category = allCategories.find((n) => {
      const id = cat === 'identity-and-access-management' ? 'iam' : cat

      return n.id === id
    })

    if (fw === 'custom' && customFramework) {
      category = allCategories.find((n) =>
        customFramework && fw === 'custom' ? n.title.toLowerCase() === categoryName : n.id === categoryName
      )
    }

    const timePeriod = getPayloadTimePeriod(period)
    const payload: CategoryDataPayload = {
      timePeriod,
      category: category?.id || cat
    }

    await fetchHistoryPerformance(payload)
    await fetchHistoryBenchmark(payload)
  }

  const lastUpdate = useMemo(() => {
    if (historyPerformanceData && historyPerformanceData.length > 0) {
      const lastValue = dayjs(historyPerformanceData[historyPerformanceData.length - 1].xValue || '').format(
        dateFormat.lastUpdate
      )
      const nextMonday = dayjs(lastValue).weekday(8)

      return dayjs(nextMonday).format(dateFormat.lastUpdate)
    }

    return dayjs().format(dateFormat.lastUpdate)
  }, [historyPerformanceData])

  if (!performanceLoading && !performanceRows.length) {
    return (
      <EmptyDataContainer>
        <Typography>No data for category</Typography>
      </EmptyDataContainer>
    )
  }
  return (
    <CategoryTabWrapper>
      <Box className="container">
        <Box className="container-inner">
          {!!performanceRows.length && (
            <Box className="title-wrapper" sx={{ maxWidth: containersMaxWidth }}>
              <Typography className="title">Current Findings</Typography>
            </Box>
          )}

          <Box className="container-inner-2">
            {/* Current Findings */}
            {performanceRows.length > 0 && (
              <PerformanceScore
                containersMaxWidth={containersMaxWidth}
                categoryName={categoryName}
                rows={performanceRows}
                performanceValue={performanceValue}
                lastUpdatePerformance={lastUpdatePerformance}
                dataSourceErrors={dataSourceErrors}
              />
            )}

            {/* Historical Data */}
            {historyPerformanceData.length > 0 && (
              <>
                <Box className="container-header">
                  <Box className="title-wrapper">
                    <Typography className="title">Historical Data</Typography>
                  </Box>
                  <ChipsContainer sx={{ marginBottom: '20px !important' }}>
                    <Typography className="chips-title">Time Period:</Typography>
                    <Stack direction="row" spacing={1}>
                      {defaultCategoriesTimePeriodValues.map((n: CPITimePeriod) => (
                        <Chip
                          key={n}
                          label={n}
                          variant="outlined"
                          onClick={() => handleChangeTimePeriod(n)}
                          className={selectedTimePeriod === n ? 'selected' : ''}
                        />
                      ))}
                    </Stack>
                  </ChipsContainer>
                </Box>
                <PerformanceScoreHistory
                  issolo={historyBenchmarkData.length > 0 ? 'false' : 'true'}
                  data={historyPerformanceData}
                  selectedTimePeriod={selectedTimePeriod}
                  numberOfStripes={numberOfStripes}
                  performanceValue={performanceValue}
                  lastUpdate={lastUpdate}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </CategoryTabWrapper>
  )
}

export default PerformanceTab
