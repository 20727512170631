import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { ActiveCPIIcon, InactiveCpiLibraryIcon } from '../svg'
import ArrowUpTooltip from '../svg/ArrowUpTooltip'
import { FrameworksTooltip } from '../../pages/framework-configuration/components/FrameworksTooltip'
import theme from 'theme'

const Wrapper = styled(Box)<{ isactive: string; short?: string }>`
  width: fit-content;
  height: 32px;
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  border-radius: ${theme.attributes.borderRadius.secondary};
  padding: ${theme.spacing.xsm}px ${theme.spacing.small}px ${theme.spacing.xsm}px ${theme.spacing.xSmall}px;
  gap: ${theme.spacing.xSmall}px;
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${theme.colors.white};

  &:hover {
    cursor: pointer;
  }

  ${({ isactive }) =>
    isactive === 'true' &&
    `
      background: ${theme.baseColors.info[10]};
  `};

  ${({ isactive }) =>
    isactive === 'false' &&
    `
      background: ${theme.colors.borderLow};
  `};

  ${({ short }) =>
    short === 'true' &&
    `
      border-radius: ${theme.attributes.borderRadius.circle};
      width: 30px;
      height: 30px;
      padding: ${theme.spacing.xsm}px;
  `};
`

interface Props {
  active: boolean
  short?: boolean
  sx?: Record<string, unknown>
}

const CpiActiveButton: FC<Props> = ({ active, short, sx }) => (
  <FrameworksTooltip
    title={
      <>
        <Box className="tooltip-wrapper">
          <ArrowUpTooltip />
          <Typography className="tooltip-text">CPI {active ? 'active' : 'inactive'}</Typography>
        </Box>
      </>
    }
  >
    <Wrapper sx={sx} isactive={active ? 'true' : 'false'} short={short ? 'true' : 'false'}>
      {active ? <ActiveCPIIcon /> : <InactiveCpiLibraryIcon />}
      {!short && <>{active ? 'Active' : 'Inactive'}</>}
    </Wrapper>
  </FrameworksTooltip>
)

export default CpiActiveButton
