import styled from '@emotion/styled/macro'
import { Box, FormControl, Select } from '@mui/material'
import theme from 'theme'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    color: ${theme.colors.white};
    white-space: nowrap;
  }
`

export const JiraFormControl = styled(FormControl)<{
  noborder?: string
  isselected?: string
  iscustom?: string
  isSort?: boolean
  isopened?: string
}>`
  outline: none;
  width: 100%;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.small};
  display: flex;
  position: relative;
  border: 1px solid ${theme.colors.controlBorder};
  min-width: 100%;

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.1px;
    width: 100%;
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-weight: ${theme.typography.fontWeight.normal};
    text-align: left;
    min-width: unset;
    padding-right: 28px !important;

    .MuiOutlinedInput-input {
      &.Mui-disabled {
        -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  &:hover {
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: ${theme.colors.textPrimary};
    }
  }

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const JiraSelect = styled(Select)<{
  isselected?: string
  noborder?: string
  iscustom?: string
}>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  gap: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};
  position: relative;

  color: ${theme.colors.white};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: rgba(234, 221, 255, 0.08);
    overflow: hidden;
    color: ${theme.colors.white};
    text-overflow: ellipsis;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    
    .MuiOutlinedInput-root {
      .MuiSelect-select {
        color: ${theme.colors.textPrimary} !important;
        overflow: hidden;
        color: ${theme.colors.white};
        text-overflow: ellipsis;
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      }
    }    
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    min-width: 122px;
    height: 40px;
  `};

  .MuiOutlinedInput-root {
    ${({ iscustom }) =>
      iscustom &&
      iscustom === 'true' &&
      `
      height: 40px;
  `};
  }

  svg.svg-dropdown {
    top: calc(50% - 0.7em);
    right: ${theme.spacing.xsm}px;
    width: 24px;
    height: 24px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: ${theme.colors.white};
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: ${theme.colors.white}; !important;
    }
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.backgroundDark,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.small,
      minWidth: '321px !important',
      maxHeight: '600px !important',
      marginTop: `${theme.spacing.half}px`,
      marginLeft: '-2px',

      '.MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,

        '.results-text': {
          color: theme.colors.textSecondaryLow,
          fontSize: theme.typography.fontSize.small,
          fontStyle: 'normal',
          fontWeight: theme.typography.fontWeight.thick,
          lineHeight: 'normal',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          paddingLeft: `${theme.spacing.xSmall}px`,
          margin: `${theme.spacing.xSmall}px 0`
        },

        '.list-wrapper': {
          maxHeight: '240px',
          overflowY: 'auto',

          '.no-options-wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: `${theme.spacing.xSmall}px`,

            '.no-optons-text': {
              color: theme.colors.white,
              fontSize: theme.typography.fontSize.xLarge,
              fontStyle: 'normal',
              fontWeight: theme.typography.fontWeight.bold,
              lineHeight: '24px',
              letterSpacing: '0.1px'
            }
          },

          '&::-webkit-scrollbar-track': {
            background: theme.colors.borderLow,
            borderRadius: theme.attributes.borderRadius.secondary
          },

          '&::-webkit-scrollbar-thumb': {
            background: theme.colors.textPrimary,
            height: '4px',
            width: '4px',
            border: '4px solid transparent',
            backgroundClip: 'content-box'
          }
        },

        '.results-header-text': {
          color: theme.colors.textSecondaryLow,
          fontSize: theme.typography.fontSize.small,
          fontStyle: 'normal',
          fontWeight: theme.typography.fontWeight.bold,
          lineHeight: 'normal',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          margin: `${theme.spacing.xSmall}px ${theme.spacing.xSmall}px ${theme.spacing.xSmall}px 0`
        }
      },

      '.MuiMenuItem-root': {
        height: '48px',
        paddingLeft: `${theme.spacing.sm}px`,
        paddingRight: `${theme.spacing.small}px`,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '&.reset-button-wrapper': {
          justifyContent: 'flex-end',
          alignItems: 'center',

          '&.Mui-disabled': {
            opacity: '0.38',

            '.MuiButton-root.reset-button': {
              color: theme.colors.white
            }
          },
          '&:hover': {
            '.MuiButton-root.reset-button': {
              background: 'rgba(234, 221, 255, 0.08)'
            }
          },
          '.MuiButton-root.reset-button': {
            borderRadius: theme.attributes.borderRadius.large,
            background: 'transparent',
            display: 'flex',
            padding: `${theme.spacing.sm}px ${theme.spacing.small}px`,
            justifyContent: 'center',
            alignItems: 'center',
            gap: `${theme.spacing.xSmall}px`,
            color: theme.colors.textPrimary,
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily.primary,
            fontSize: theme.typography.fontSize.large,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeight.bold,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            textTransform: 'none'
          }
        },

        '.MuiCheckbox-root': {
          padding: `${theme.spacing.xSmall}px`,

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: 'normal',
          color: theme.colors.white,
          paddingLeft: `${theme.spacing.xSmall}px`
        },

        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: theme.attributes.boxShadow.chipCircle,
            margin: '-4.5px',
            borderRadius: theme.attributes.borderRadius.circle,

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar-track': {
        background: theme.baseColors.surface[50]
      },

      '&::-webkit-scrollbar-thumb': {
        background: theme.colors.textPrimary,
        height: '4px',
        width: '4px',
        border: '4px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
