import styled from '@emotion/styled'
import { Box } from '@mui/material'
import theme from 'theme'

export const SearchBarWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  position: relative;
  width: 200px;

  .search-icon {
    position: absolute;
    left: ${theme.spacing.sm}px;
    top: 9px;
    z-index: 1;
  }

  .clear-btn {
    position: absolute;
    right: 0;
    top: 0;
  }

  .search-bar {
    width: 100%;
    border-radius: 28px;
    height: 40px;
  }

  .MuiOutlinedInput-root {
    height: 40px;
  }

  .MuiOutlinedInput-input {
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.controlBorder};
    padding: ${theme.spacing.xSmall}px ${theme.spacing.xLarge}px ${theme.spacing.xSmall}px ${theme.spacing.xLarge}px;
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    box-sizing: border-box;
    height: 40px;

    &::placeholder {
      color: ${theme.colors.neutral};
      opacity: 1;
    }

    &:focus {
      border-color: ${theme.colors.textPrimary};
    }
  }

  fieldset {
    border: none;
  }
`
