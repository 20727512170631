import React, { Dispatch, FC, ReactNode, SetStateAction, SyntheticEvent, useMemo, useState } from 'react'
import { Box, IconButton, Tab, Typography } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import {
  CPIFrequency,
  CustomLabelCountI,
  CustomLabelDate,
  CustomLabelI,
  CustomLabelsListI,
  DateRangeI,
  VisibleLabel
} from '../../../../../../../../models'
import { dateFormat } from '../../../../../../../../lib/utils'
import { CUSTOM_LABEL_ID, fillDatesBetweenRange } from '../../../../../utils'
import axios from '../../../../../../../../lib/axios'

/* Components */
import { DateLabelPanel, LabelsShowModeWrapper, LabelsTabs, TabPanelWrapper } from './LabelsShowMode.styles'
import { LibraryDetailsHoverTooltip } from '../../../../components/LibraryDetailsTooltip'
import { ThemeButton } from '../../../../../../../components/buttons'
import CustomLabel from './CustomLabel'
import NewLabelSvg from '../../../../../../../components/svg/labels/NewLabelSvg'
import UnPinAll from '../../../../../../../components/svg/labels/UnPinAll'
import FilterSvg from '../../../../../../../components/svg/labels/FilterSvg'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

interface Props {
  appliedFilter: any
  setLoading: Dispatch<SetStateAction<boolean>>
  customLabels: CustomLabelsListI
  customLabelsCount: CustomLabelCountI
  updateEditLabel: (label: any) => void
  updateMode: (newMode: string) => void
  updateLabels: () => void
  mode: string
  loading: boolean
  dateRange: DateRangeI
  customDate: CustomLabelDate
  unpinLabel: (labelId: string) => Promise<void>
  pinLabel: (labelId: string) => Promise<void>
  visibleLabels: VisibleLabel[]
  setVisibleLabels: Dispatch<SetStateAction<VisibleLabel[]>>
  pinningLabel: boolean
  setPinningLabel: Dispatch<SetStateAction<boolean>>
  selectedFrequency: CPIFrequency
}

const LabelsShowMode: FC<Props> = ({
  customLabels,
  appliedFilter,
  updateMode,
  setLoading,
  loading,
  updateEditLabel,
  customLabelsCount,
  updateLabels,
  mode,
  dateRange,
  customDate,
  unpinLabel,
  pinLabel,
  visibleLabels,
  setVisibleLabels,
  pinningLabel,
  setPinningLabel,
  selectedFrequency
}) => {
  const [tabValue, setTabValue] = useState(+mode.substring(mode.length - 1) || 0)
  const [count, setCount] = useState(0)

  const handleChangeTabValue = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
    updateMode(newValue === 0 ? 'show-0' : 'show-1')
  }

  const pinnedLabels = useMemo(() => {
    if (customLabelsCount?.pinned_labels?.length) {
      let arr: CustomLabelI[] = []
      arr = [...customLabelsCount.pinned_labels]
      setCount(arr.length)

      return arr.reduce((accumulator: { [x: string]: any[] }, currentObject: CustomLabelI) => {
        const date =
          currentObject.action === CUSTOM_LABEL_ID
            ? dayjs(currentObject.data.date).format(dateFormat.customLabel)
            : dayjs(currentObject.created_at).format(dateFormat.customLabel)

        if (!accumulator[date]) accumulator[date] = [currentObject]
        else accumulator[date].push(currentObject)

        return accumulator
      }, {} as CustomLabelsListI)
    }

    return {}
  }, [customLabelsCount, tabValue])

  const unPinAll = async () => {
    const prevVisibleLabels = [...visibleLabels]
    try {
      setLoading(true)
      const body = {
        ids: customLabelsCount?.pinned_labels.map((item) => item.id)
      }
      setVisibleLabels([])
      await axios.delete(`/api/v3/labels/cpis/pin`, { data: body } as any)
      await updateLabels()
      await updateMode('show-0')
    } catch (e) {
      console.error(e)
      setVisibleLabels(prevVisibleLabels)
    } finally {
      setLoading(false)
    }
  }

  const renderCustomDateRange = () => {
    const filledDates = fillDatesBetweenRange(new Date(customDate.start), new Date(customDate.end))
    const containedDates = filledDates.map((n: string | number | Date | dayjs.Dayjs | null | undefined) =>
      dayjs(n).format(dateFormat.customLabel)
    )

    return Object.keys(customLabels)
      .filter((n) => containedDates.includes(n))
      .sort((a, b) => (new Date(b).getTime() > new Date(a).getTime() ? -1 : 1))
      .map((date, index) => {
        return (
          <DateLabelPanel key={index}>
            <Typography className="date">{dayjs(date).format(dateFormat.short)}</Typography>

            {customLabels[date].map((label: CustomLabelI) => {
              return (
                <CustomLabel
                  key={label.id}
                  setLoading={setLoading}
                  updateMode={updateMode}
                  customLabel={label}
                  customLabelsCount={customLabelsCount}
                  updateEditLabel={updateEditLabel}
                  updateLabels={updateLabels}
                  unpinLabel={unpinLabel}
                  pinLabel={pinLabel}
                  pinningLabel={pinningLabel}
                  setPinningLabel={setPinningLabel}
                />
              )
            })}
          </DateLabelPanel>
        )
      })
  }

  return (
    <LabelsShowModeWrapper>
      <LabelsTabs
        value={tabValue}
        onChange={handleChangeTabValue}
        aria-label="library details tabs"
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        isdetails="true"
      >
        <Tab label="All Labels" {...a11yProps(0)} />
        <Tab label={`Pinned Labels(${count})`} {...a11yProps(1)} />
      </LabelsTabs>

      <TabPanelWrapper>
        {tabValue === 0 || (tabValue === 1 && count) ? (
          <Box className="filters-wrapper">
            <Box className="filters-block">
              <Typography className="dates">
                {dateRange.maxDate === '' ? (
                  dateRange.minDate
                ) : dateRange.minDate === dateRange.maxDate ? (
                  dayjs(dateRange.minDate).format(dateFormat.shortUnComma)
                ) : (
                  <>
                    {dayjs(appliedFilter.startDate || dateRange.minDate).format(dateFormat.shortUnComma)} -{' '}
                    {selectedFrequency === CPIFrequency.Weekly
                      ? dayjs().format(dateFormat.shortUnComma)
                      : dayjs(appliedFilter.endDate || dateRange.maxDate).format(dateFormat.shortUnComma)}
                  </>
                )}
              </Typography>

              <LibraryDetailsHoverTooltip title="Filter by Date & Label Type" arrow placement="bottom-end">
                <IconButton className="filter-button" size="small" onClick={() => updateMode('filter')}>
                  <FilterSvg />
                </IconButton>
              </LibraryDetailsHoverTooltip>
            </Box>
          </Box>
        ) : (
          ''
        )}

        <Box className="content">
          {!loading && tabValue === 0 && Object.keys(customLabels).length ? (
            <Typography className="info">Pin up to 4 labels to the chart:</Typography>
          ) : !loading && tabValue === 1 ? (
            <Typography className="info">{count ? 'Labels pinned to chart:' : 'No labels pinned to chart.'}</Typography>
          ) : (
            ''
          )}
          <TabPanel value={tabValue} index={0}>
            {!loading && !Object.keys(customLabels).length ? (
              <Typography className="info">No labels in this time period.</Typography>
            ) : !loading ? (
              <>
                {/* Render list of all Labels and System Events */}
                {Object.keys(customLabels).length &&
                  mode === 'show-0' &&
                  Object.keys(customLabels)
                    .sort((a, b) => (new Date(b).getTime() > new Date(a).getTime() ? -1 : 1))
                    .map((date, index) => {
                      return (
                        <DateLabelPanel key={index}>
                          <Typography className="date">{dayjs(date).format(dateFormat.short)}</Typography>

                          {customLabels[date].map((label: CustomLabelI) => {
                            return (
                              <CustomLabel
                                key={label.id}
                                setLoading={setLoading}
                                updateMode={updateMode}
                                customLabel={label}
                                customLabelsCount={customLabelsCount}
                                updateEditLabel={updateEditLabel}
                                updateLabels={updateLabels}
                                unpinLabel={unpinLabel}
                                pinLabel={pinLabel}
                                pinningLabel={pinningLabel}
                                setPinningLabel={setPinningLabel}
                              />
                            )
                          })}
                        </DateLabelPanel>
                      )
                    })}

                {/* Render list of Labels and System Events for selected chart stripe single date */}
                {Object.keys(customLabels).length &&
                  mode === 'show-date' &&
                  customDate.start.length > 0 &&
                  !customDate.end.length && (
                    <DateLabelPanel>
                      <Typography className="date">{dayjs(customDate.start).format(dateFormat.short)}</Typography>

                      {customLabels[customDate.start]?.length &&
                        customLabels[customDate.start].map((label: CustomLabelI) => {
                          return (
                            <CustomLabel
                              key={label.id}
                              setLoading={setLoading}
                              updateMode={updateMode}
                              customLabel={label}
                              customLabelsCount={customLabelsCount}
                              updateEditLabel={updateEditLabel}
                              updateLabels={updateLabels}
                              unpinLabel={unpinLabel}
                              pinLabel={pinLabel}
                              pinningLabel={pinningLabel}
                              setPinningLabel={setPinningLabel}
                            />
                          )
                        })}
                    </DateLabelPanel>
                  )}

                {/* Render list of Labels and System Events for selected chart stripe date range */}
                {Object.keys(customLabels).length &&
                  mode === 'show-date' &&
                  customDate.start.length > 0 &&
                  customDate.end.length > 0 &&
                  renderCustomDateRange()}
              </>
            ) : (
              ''
            )}

            <Box className="drawer-actions">
              <Box className="icon-block">
                <NewLabelSvg />
              </Box>
              <ThemeButton
                themevariant="secondary"
                type="button"
                className="deactivate-button"
                onClick={() => updateMode('create')}
              >
                Create Custom Label
              </ThemeButton>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {Object.keys(pinnedLabels).length > 0 &&
              Object.keys(pinnedLabels)
                .sort((a, b) => (new Date(b).getTime() > new Date(a).getTime() ? -1 : 1))
                .map((date: any, index) => {
                  return (
                    <DateLabelPanel key={index}>
                      <Typography className="date">{dayjs(date).format(dateFormat.short)}</Typography>

                      {pinnedLabels[date].map((label: CustomLabelI) => {
                        return (
                          <CustomLabel
                            key={label.id}
                            setLoading={setLoading}
                            updateMode={updateMode}
                            customLabel={label}
                            customLabelsCount={customLabelsCount}
                            updateEditLabel={updateEditLabel}
                            updateLabels={updateLabels}
                            unpinLabel={unpinLabel}
                            pinLabel={pinLabel}
                            pinningLabel={pinningLabel}
                            setPinningLabel={setPinningLabel}
                          />
                        )
                      })}
                    </DateLabelPanel>
                  )
                })}

            <Box className="drawer-actions">
              <ThemeButton
                themevariant="primary"
                type="button"
                className={`deactivate-button unpin-btn ${!count ? 'disabled-button' : ''}`}
                onClick={() => unPinAll()}
                disabled={!count}
              >
                <>
                  <UnPinAll />
                  Unpin All Labels
                </>
              </ThemeButton>
            </Box>
          </TabPanel>
        </Box>
      </TabPanelWrapper>
    </LabelsShowModeWrapper>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`labels-tabpanel-${index}`}
      aria-labelledby={`labels-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `labels-tab-${index}`,
    'aria-controls': `labels-tabpanel-${index}`
  }
}

export default LabelsShowMode
