import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import theme from 'theme'

// Components
import { Wrapper } from './FilterLabels.style'
import { Box, Checkbox, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon } from '../../../../../../../components/svg'
import { ThemeButton } from '../../../../../../../components/buttons'
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import { DateRangeI, SystemEventName } from '../../../../../../../../models'

const options = [
  {
    id: 1,
    label: 'SLA Changes',
    value: SystemEventName.SlaChangedEvent,
    checked: false
  },
  {
    id: 2,
    label: 'CPI Activated',
    value: SystemEventName.CpiActivated,
    checked: false
  },
  {
    id: 3,
    label: 'Instance Added',
    value: SystemEventName.CpiInstanceAdded,
    checked: false
  },
  {
    id: 4,
    label: 'Instance Removed',
    value: SystemEventName.CpiInstanceRemoved,
    checked: false
  },
  {
    id: 5,
    label: 'Custom Labels',
    value: SystemEventName.CpiCustom,
    checked: false
  }
]

interface Props {
  appliedFilter: any
  updateMode: (newMode: string) => void
  updateLabels: () => void
  filterLabelsData: (filters: any) => void
  dateRange: DateRangeI
  redirectToMode: string
}

const FilterLabels: FC<Props> = ({
  updateMode,
  updateLabels,
  dateRange,
  redirectToMode,
  filterLabelsData,
  appliedFilter
}) => {
  const listOfOptions = useMemo(() => {
    return options.map((item) => {
      item.checked = !!appliedFilter.types.includes(item.value)
      return item
    })
  }, [appliedFilter])

  const [isChanged, setIsChanged] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [types, setTypes] = useState(JSON.parse(JSON.stringify(appliedFilter.types ? listOfOptions : options)))
  const [dates, setDates] = useState({
    startDate: appliedFilter.startDate || dateRange.minDate,
    endDate: appliedFilter.endDate || dateRange.maxDate
  })

  useEffect(() => {
    const findItem = types.find((item: { checked: boolean }) => item.checked)
    if (!findItem && appliedFilter.types.length) return setIsDisabled(false)

    setIsDisabled(!findItem)
  }, [types])

  const changeHandler = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    setIsChanged(true)
    const temp = [...types]
    temp[index].checked = e.target.checked
    setTypes(temp)
  }

  const handleDateChange = (date: any) => {
    setIsChanged(true)
    setIsDisabled(false)
    setDates({
      startDate: dayjs(date[0]).format('YYYY-MM-DD'),
      endDate: dayjs(date[1]).format('YYYY-MM-DD')
    })
  }

  const applyFilters = () => {
    const filters = {
      ...dates,
      types: types.filter((item: { checked: boolean }) => item.checked).map((i: { value: string }) => i.value)
    }

    updateMode('show-0')
    filterLabelsData(filters)
  }

  const resetFilters = () => {
    filterLabelsData(null)
    setIsChanged(false)
    updateLabels()

    setTimeout(() => {
      setDates({
        startDate: dateRange.minDate,
        endDate: dateRange.maxDate
      })
      setTypes(JSON.parse(JSON.stringify(options)))
    }, 300)
  }

  return (
    <Wrapper>
      <Box className="content">
        <Typography className="label">Filter by date: </Typography>
        <Box className="dates">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              calendars={1}
              value={[dayjs(dates.startDate), dayjs(dates.endDate)]}
              label="Date"
              format="MM/DD/YYYY"
              maxDate={dayjs(dateRange.maxDate)}
              minDate={dayjs(dateRange.minDate)}
              onAccept={(date) => {
                handleDateChange(date)
              }}
              slotProps={{
                popper: {
                  sx: {
                    '.MuiPaper-root.MuiPickersPopper-paper': {
                      borderRadius: '16px',
                      background: theme.colors.primaryLight,

                      '.MuiPickersArrowSwitcher-root': {
                        '.MuiButtonBase-root': {
                          color: '#CAC4D0'
                        }
                      },
                      '.MuiPickersCalendarHeader-label': {
                        color: '#CAC4D0',
                        fontFamily: theme.typography.fontFamily.primary,
                        fontSize: theme.typography.fontSize.large,
                        fontStyle: 'normal',
                        fontWeight: theme.typography.fontWeight.bold,
                        lineHeight: '20px',
                        letterSpacing: '0.1px'
                      },

                      '.MuiDayCalendar-header': {
                        '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel': {
                          color: '#E6E0E9',
                          textAlign: 'center',
                          fontFamily: theme.typography.fontFamily.primary,
                          fontSize: theme.typography.fontSize.xLarge,
                          fontStyle: 'normal',
                          fontWeight: theme.typography.fontWeight.bold,
                          lineHeight: '24px',
                          letterSpacing: '0.1px'
                        }
                      },

                      '.MuiButtonBase-root.MuiPickersDay-root': {
                        color: '#E6E0E9',
                        textAlign: 'center',
                        fontFamily: theme.typography.fontFamily.primary,
                        fontSize: theme.typography.fontSize.xLarge,
                        fontStyle: 'normal',
                        fontWeight: theme.typography.fontWeight.bold,
                        lineHeight: '24px',
                        letterSpacing: '0.1px',

                        '&.Mui-selected': {
                          background: theme.colors.textPrimary,
                          color: '#66006E'
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            background: theme.colors.textSecondaryLow,
                            borderColor: theme.colors.textSecondaryLow
                          }
                        },
                        '&.MuiPickersDay-today': {
                          background: 'transparent',
                          border: `1px solid ${theme.colors.textPrimary}`,
                          color: theme.colors.textPrimary
                        },
                        '&.Mui-disabled': {
                          color: theme.colors.controlBorder
                        }
                      },

                      '.MuiButton-root': {
                        color: theme.colors.textPrimary,
                        fontFamily: theme.typography.fontFamily.primary,
                        fontSize: theme.typography.fontSize.large,
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        margin: '0'
                      }
                    }
                  }
                },
                actionBar: {
                  actions: ['cancel', 'accept']
                }
              }}
            />
          </LocalizationProvider>
        </Box>

        <Typography className="label">Filter by label type: </Typography>
        <Box className="types">
          {types.map((item: any, index: number) => {
            return (
              <Box key={item.id} className="checkbox-wrapper">
                <Checkbox
                  id={`id-${item.id}`}
                  inputProps={{ 'aria-label': 'Checkbox' }}
                  checked={item.checked}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => changeHandler(e, index)}
                  icon={<InstanceUncheckedIcon />}
                  checkedIcon={<InstanceCheckedIcon />}
                />
                <label htmlFor={`id-${item.id}`}>{item.label}</label>
              </Box>
            )
          })}
        </Box>

        <Box className="reset-block">
          <Typography className="reset" onClick={resetFilters}>
            Reset
          </Typography>
        </Box>

        <Box className="drawer-actions edit-actions filters-actions">
          <ThemeButton
            themevariant="primary"
            type="button"
            className={`${isDisabled || !isChanged ? 'disabled-btn' : ''} edit-button save-btn`}
            disabled={isDisabled || !isChanged}
            onClick={applyFilters}
          >
            Apply Filters
          </ThemeButton>
          <span onClick={() => updateMode(redirectToMode || 'show-0')}>Cancel</span>
        </Box>
      </Box>
    </Wrapper>
  )
}

export default FilterLabels
