export interface User {
  id: string
  name: string
  email: string
  phone_number: number | null
  profile_picture_url: string
  tenant_id: string
  verified: boolean | null
  created_at: string
  role: string
}

export interface UserRole {
  id: string
  name: string
  description: string
  is_default: boolean
}

export interface UserData {
  limit: number
  total: number
  users: User[]
}

export interface UserPermission {
  categoryId: string
  createdAt: string
  description: string
  fePermission: boolean
  id: string
  key: string
  name: string
  updatedAt: string
}

export interface UserRole {
  createdAt: string
  description: string
  firstUserRole: boolean
  id: string
  isDefault: boolean
  key: string
  level: number
  name: string
  permissions: string[] // string of permission id's
  tenantId: string | null
  updatedAt: string
  vendorId: string
}

export enum CategoryFramework {
  Onyxia = 'onyxia',
  NIST = 'nist',
  Custom = 'custom'
}

export interface Tenant {
  id: string
  logo?: string
  name: string
  slug: string
  timeZone: string
  industry: string
  subscription?: null
  cyFramework: CategoryFramework
}
