import theme from 'theme'

const StubPerformanceCategories = () => (
  <svg width="292" height="254" viewBox="0 0 292 274" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_27_18148)">
      <mask id="mask0_27_18148" maskUnits="userSpaceOnUse" x="0" y="0" width="292" height="254">
        <rect width="292" height="254" rx="24" fill={theme.colors.primaryDark} />
        <rect width="292" height="254" rx="24" fill={theme.colors.textPrimary} fillOpacity="0.11" />
      </mask>
      <g mask="url(#mask0_27_18148)">
        <path d="M-355.351 -221.158H1002.41V612.526H-355.351V-221.158Z" fill={theme.colors.primaryDark} />
        <path d="M-355.351 -221.158H1002.41V612.526H-355.351V-221.158Z" fill="url(#paint0_linear_27_18148)" />
      </g>
      <line x1="4.37114e-08" y1="37.5" x2="267" y2="37.5" stroke={theme.colors.borderLow} />
      <line x1="4.37114e-08" y1="79.5" x2="267" y2="79.5" stroke={theme.colors.borderLow} />
      <line x1="4.37114e-08" y1="121.5" x2="267" y2="121.5" stroke={theme.colors.borderLow} />
      <line x1="4.37114e-08" y1="163.5" x2="267" y2="163.5" stroke={theme.colors.borderLow} />
      <line x1="4.37114e-08" y1="221.5" x2="267" y2="221.5" stroke={theme.colors.borderLow} />
      <line x1="4.37114e-08" y1="263.5" x2="267" y2="263.5" stroke={theme.colors.borderLow} />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_27_18148"
        x1="-355.351"
        y1="-221.158"
        x2="978.764"
        y2="-123.006"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={theme.colors.primaryLight} stopOpacity="0.12" />
        <stop offset="0.375" stopColor={theme.colors.primaryLight} stopOpacity="0.71" />
        <stop offset="0.557292" stopColor={theme.colors.primaryLight} stopOpacity="0.9" />
        <stop offset="0.739583" stopColor={theme.colors.primaryLight} stopOpacity="0.71" />
        <stop offset="1" stopColor={theme.colors.primaryLight} stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_27_18148">
        <rect width="292" height="254" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default StubPerformanceCategories
