import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import theme from 'theme'

/* Components */
import ThemeButton from '../../../components/buttons/primary/ThemeButton'
import { RightIconButton } from '../../../components/svg'

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.small}px;
  height: 52px;
  border-radius: ${theme.attributes.borderRadius.normal};
  background: ${theme.colors.backgroundMedium};
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  width: 100%;

  .text {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-right: ${theme.spacing.md}px;
  }
`

interface Props {
  category: string
}

const ExploreCategoryCard: FC<Props> = ({ category }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/library', { state: { navigatedCategory: category } })
  }

  return (
    <Wrapper>
      <Typography className="text">{category}</Typography>
      <ThemeButton themevariant="tertiary" onClick={handleClick}>
        <>
          View All
          <RightIconButton />
        </>
      </ThemeButton>
    </Wrapper>
  )
}

export default ExploreCategoryCard
