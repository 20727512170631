import { FC } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import theme from 'theme'

/* Components */
import { disabledStyles, ThemeButton } from '../../../../components/buttons'
import { IntegrationsCheckIcon, IntegrationsFailIcon } from '../../../../components/svg'
import { PermissionTooltip } from '../../../../components/tooltip'

/* Utils */
import { TestStatus } from '../SetupIntegrationInstance'
import { ManifestIntegration } from '../../../../../models'

interface Props {
  formik: any
  testDisabled: boolean
  loading: boolean
  nameHelperText: string
  integration: ManifestIntegration | null
  testing: boolean
  testStatus: TestStatus
  noPermission?: boolean
}

const TestConnectionButton: FC<Props> = ({
  formik,
  testDisabled,
  loading,
  nameHelperText,
  integration,
  testing,
  testStatus,
  noPermission
}) => (
  <>
    {noPermission ? (
      <PermissionTooltip
        PopperProps={{
          sx: {
            marginTop: `-${theme.spacing.sm}px !important`
          }
        }}
        title={
          <>
            <Box className="tooltip-wrapper-permissions">
              <Typography className="tooltip-text">Insufficient permissions</Typography>
            </Box>
          </>
        }
      >
        <ThemeButton
          type="button"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          disabled={false}
          themevariant="secondary"
          startIcon={testing && <CircularProgress size="24px" />}
          sx={{
            minWidth: '190px',
            pointerEvents: noPermission ? 'unset !important' : '',

            '.MuiCircularProgress-root': {
              color: theme.colors.textPrimary
            },
            '&.Mui-disabled': {
              borderRadius: theme.attributes.borderRadius.large,
              border:
                testStatus === TestStatus.unchecked && !testing
                  ? ''
                  : `1px solid ${theme.colors.controlBorder} !important`,
              background: testStatus === TestStatus.unchecked && !testing ? '' : 'transparent !important',
              color:
                testStatus === TestStatus.unchecked && !testing
                  ? theme.colors.textPrimary
                  : `${theme.colors.white} !important`
            },
            ...disabledStyles.secondary
          }}
        >
          <>
            {testStatus === TestStatus.unchecked && (
              <>
                {testing ? (
                  'Testing...'
                ) : (
                  <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.5556 3L22 7.44444L17.5556 11.8889V8.55556H13.1111V6.33333H17.5556V3ZM9.77778 6.33333C9.16667 6.33333 8.66667 6.83333 8.66667 7.44444C8.66667 8.05556 9.16667 8.55556 9.77778 8.55556C10.3889 8.55556 10.8889 8.05556 10.8889 7.44444C10.8889 6.83333 10.3889 6.33333 9.77778 6.33333ZM5.33333 6.33333C4.72222 6.33333 4.22222 6.83333 4.22222 7.44444C4.22222 8.05556 4.72222 8.55556 5.33333 8.55556C5.94444 8.55556 6.44444 8.05556 6.44444 7.44444C6.44444 6.83333 5.94444 6.33333 5.33333 6.33333ZM6.44444 17.4444H10.8889V15.2222H6.44444V11.8889L2 16.3333L6.44444 20.7778V17.4444ZM14.2222 17.4444C14.8333 17.4444 15.3333 16.9444 15.3333 16.3333C15.3333 15.7222 14.8333 15.2222 14.2222 15.2222C13.6111 15.2222 13.1111 15.7222 13.1111 16.3333C13.1111 16.9444 13.6111 17.4444 14.2222 17.4444ZM18.6667 17.4444C19.2778 17.4444 19.7778 16.9444 19.7778 16.3333C19.7778 15.7222 19.2778 15.2222 18.6667 15.2222C18.0556 15.2222 17.5556 15.7222 17.5556 16.3333C17.5556 16.9444 18.0556 17.4444 18.6667 17.4444Z"
                        fill={theme.colors.textPrimary}
                      />
                    </svg>
                    Test Connection
                  </>
                )}
              </>
            )}
            {testStatus === TestStatus.successful && (
              <>
                <IntegrationsCheckIcon />
                Test Successful
              </>
            )}
            {testStatus === TestStatus.failed && (
              <>
                <IntegrationsFailIcon />
                Test Failed
              </>
            )}
          </>
        </ThemeButton>
      </PermissionTooltip>
    ) : (
      <ThemeButton
        type="submit"
        disabled={
          formik.isSubmitting ||
          testDisabled ||
          !formik.isValid ||
          loading ||
          !integration?.config?.schema ||
          nameHelperText.length > 0
        }
        themevariant="secondary"
        startIcon={testing && <CircularProgress size="24px" />}
        sx={{
          minWidth: '190px',

          '.MuiCircularProgress-root': {
            color: theme.colors.textPrimary
          },
          '&.Mui-disabled': {
            borderRadius: theme.attributes.borderRadius.large,
            border:
              testStatus === TestStatus.unchecked && !testing
                ? ''
                : `1px solid ${theme.colors.controlBorder} !important`,
            background: testStatus === TestStatus.unchecked && !testing ? '' : 'transparent !important',
            color:
              testStatus === TestStatus.unchecked && !testing
                ? theme.colors.textPrimary
                : `${theme.colors.white} !important`
          }
        }}
      >
        <>
          {testStatus === TestStatus.unchecked && (
            <>
              {testing ? (
                'Testing...'
              ) : (
                <>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.5556 3L22 7.44444L17.5556 11.8889V8.55556H13.1111V6.33333H17.5556V3ZM9.77778 6.33333C9.16667 6.33333 8.66667 6.83333 8.66667 7.44444C8.66667 8.05556 9.16667 8.55556 9.77778 8.55556C10.3889 8.55556 10.8889 8.05556 10.8889 7.44444C10.8889 6.83333 10.3889 6.33333 9.77778 6.33333ZM5.33333 6.33333C4.72222 6.33333 4.22222 6.83333 4.22222 7.44444C4.22222 8.05556 4.72222 8.55556 5.33333 8.55556C5.94444 8.55556 6.44444 8.05556 6.44444 7.44444C6.44444 6.83333 5.94444 6.33333 5.33333 6.33333ZM6.44444 17.4444H10.8889V15.2222H6.44444V11.8889L2 16.3333L6.44444 20.7778V17.4444ZM14.2222 17.4444C14.8333 17.4444 15.3333 16.9444 15.3333 16.3333C15.3333 15.7222 14.8333 15.2222 14.2222 15.2222C13.6111 15.2222 13.1111 15.7222 13.1111 16.3333C13.1111 16.9444 13.6111 17.4444 14.2222 17.4444ZM18.6667 17.4444C19.2778 17.4444 19.7778 16.9444 19.7778 16.3333C19.7778 15.7222 19.2778 15.2222 18.6667 15.2222C18.0556 15.2222 17.5556 15.7222 17.5556 16.3333C17.5556 16.9444 18.0556 17.4444 18.6667 17.4444Z"
                      fill={theme.colors.textPrimary}
                    />
                  </svg>
                  Test Connection
                </>
              )}
            </>
          )}
          {testStatus === TestStatus.successful && (
            <>
              <IntegrationsCheckIcon />
              Test Successful
            </>
          )}
          {testStatus === TestStatus.failed && (
            <>
              <IntegrationsFailIcon />
              Test Failed
            </>
          )}
        </>
      </ThemeButton>
    )}
  </>
)

export default TestConnectionButton
