import theme from 'theme'

const LegendPerformanceIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{ mixBlendMode: 'hard-light' }}>
      <path d="M0 0H16V16H0V0Z" fill="url(#paint0_linear_4675_91633)" />
    </g>
    <mask id="path-2-inside-1_4675_91633" fill="white">
      <path d="M0 0H16V16H0V0Z" />
    </mask>
    <path d="M0 3H16V-3H0V3Z" fill={theme.baseColors.info[20]} mask="url(#path-2-inside-1_4675_91633)" />
    <defs>
      <linearGradient id="paint0_linear_4675_91633" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A5BE81" />
        <stop offset="1" stopColor="#A5BE81" stopOpacity="0.4" />
      </linearGradient>
    </defs>
  </svg>
)

export default LegendPerformanceIcon
