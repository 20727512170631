import { Box, Tabs } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DashboardContainer = styled(Box)`
  width: 100%;
  background: ${theme.colors.primaryDark};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .dashboard-title {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxLarge};
    font-weight: ${theme.typography.fontWeight.normal};
    font-style: normal;
    line-height: 28px;
  }

  .desktop-section {
    display: flex;
    flex-direction: column;
  }
`

export const CompanyInfo = styled(Box)`
  padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
  border-radius: ${theme.attributes.borderRadius.secondary};
  border: 1px solid ${theme.colors.borderLow};
  background: #3f293e;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;

  .company-name {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-weight: ${theme.typography.fontWeight.bold};
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const DashboardLogoWrapper = styled(Box)`
  display: flex;
  align-items: center;

  img {
    max-width: 52px;
    height: 24px;
    object-fit: cover;
    object-position: center center;
  }

  .MuiTypography-root {
    margin: 0 ${theme.spacing.medium}px;
  }
`

export const Main = styled(Box)`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  background: ${theme.colors.primaryDark};
`

export const DashboardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${theme.colors.primaryDark};
  margin: 0;
  padding: ${theme.spacing.medium}px;
  gap: ${theme.spacing.medium}px;

  .main-content-container {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.medium}px;
    border-radius: ${theme.attributes.borderRadius.quaternary};
    flex-basis: 100%;

    .main-scroll-wrapper {
      width: 100%;
      flex-basis: 66.66%;
    }
  }
`

export const HeroSectionWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${theme.spacing.medium}px;
  position: relative;
  width: 100%;
  flex-basis: 100%;

  .performance-score-section {
    width: 100%;
    flex-basis: 66.66%;
    display: flex;
    align-items: center;
    gap: ${theme.spacing.medium}px;
  }
`

export const IntelFeedWrapper = styled(Box)<{ width: number }>`
  background: transparent;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  display: flex;
  flex-flow: column wrap;
  gap: ${theme.spacing.medium}px;
  box-sizing: border-box;
  flex-basis: 40%;
  width: 100%;

  @media (min-width: 1440px) {
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      align-items: center;
      color: ${theme.colors.white};
      font-size: ${theme.typography.fontSize.xLarge};
      font-weight: ${theme.typography.fontWeight.bold};
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.1px;

      svg {
        margin-right: ${theme.spacing.xSmall}px;
      }
    }

    .MuiTypography-root {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-weight: ${theme.typography.fontWeight.bold};
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }
`

export const IntelFeedCardWrapper = styled(Box)<{ read: boolean }>`
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.baseColors.surface[40]};
  display: flex;
  width: 488px;
  min-width: 488px;
  height: 270px;
  padding: ${theme.spacing.small}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.small}px;

  .header-container {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.medium}px;
    justify-content: space-between;
  }

  .expanded-subtext {
    flex-grow: 1;
  }

  .expanded-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      path {
        fill: ${theme.colors.textPrimary};
      }
    }

    .report-button {
      text-align: center;
      font-style: normal;
      border-radius: ${theme.attributes.borderRadius.large};
      font-weight: ${theme.typography.fontWeight.thin};
      font-size: ${theme.typography.fontSize.xLarge};
      color: ${theme.colors.textPrimary};
      gap: ${theme.spacing.xSmall}px;
      line-height: normal;
      text-transform: none;

      svg {
        path {
          fill: ${theme.colors.textPrimary};
        }
      }

      &:hover {
        border-radius: ${theme.attributes.borderRadius.large};
        background: ${theme.baseColors.primary[70]};
        color: ${theme.baseColors.primary[20]};

        svg {
          path {
            fill: ${theme.baseColors.primary[20]};
          }
        }
      }
    }

    .expanded-icons {
      .MuiIconButton-root {
        &:hover {
          background: ${theme.baseColors.primary[70]};
          color: ${theme.baseColors.primary[20]};

          svg {
            path {
              fill: ${theme.baseColors.primary[20]};
            }
          }
        }
      }
    }
  }
`

export const IntelFeedBackground = styled(Box)<{ url: string; fallBackUrl: string }>`
  width: 107px;
  min-width: 107px;
  height: 70px;
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${({ url, fallBackUrl }) =>
    `url("${url}") center/cover no-repeat, url("${fallBackUrl}") center/cover no-repeat`};
`

export const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.spacing.lg}px;
  width: 100%;
  flex-grow: 1;
`

export const IntelFeedTabs = styled(Tabs)`
  background: transparent;
  min-height: unset;
  height: 32px;

  .MuiButtonBase-root {
    width: 90px;
    height: 32px;
    min-height: unset;
    padding: ${theme.spacing.xsm}px ${theme.spacing.md}px;
    gap: ${theme.spacing.md}px;
    background: transparent;
    font-family: ${theme.typography.fontFamily.primary};
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    text-transform: none;
    border: 1px solid ${theme.colors.controlBorder};
    border-radius: ${theme.attributes.borderRadius.large} 0 0 ${theme.attributes.borderRadius.large};
    color: ${theme.colors.textPrimary};
    text-align: center;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.thin};
    line-height: normal;

    &:last-of-type {
      width: 80px;
      border-radius: 0 ${theme.attributes.borderRadius.large} ${theme.attributes.borderRadius.large} 0;
      border-left: 0;
    }

    &.Mui-selected {
      background: ${theme.baseColors.primary[20]};
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.controlBorder};

      &:last-of-type {
        border-left: 0;
      }
    }
  }
`

export const IntelFeedTabPanel = styled(Box)`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.medium}px;
  padding-bottom: ${theme.spacing.xSmall}px;
  min-height: 292px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 14px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: ${theme.colors.borderLow};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.quaternary};
  }
`

export const FeedSectionWrapper = styled(Box)`
  width: 100%;
  box-shadow: ${theme.attributes.boxShadow.feedSection};
  padding: ${theme.spacing.medium}px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundDarker};
  height: 405px;
`

export const MainScrollSection = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundDarker};
  padding: ${theme.spacing.small}px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: unset;
  overflow: visible;

  @media (min-width: 1920px) {
    padding: 22px ${theme.spacing.large}px;
  }

  @media (max-width: 1400px) {
    flex-grow: unset;
  }

  .empty-wrapper {
    margin-top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      width: 577px;
      margin: ${theme.spacing.medium}px auto;
      text-align: center;
      font-style: normal;
      line-height: 36px;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxxxLarge};
      font-weight: ${theme.typography.fontWeight.normal};

      @media (max-width: 600px) {
        font-size: ${theme.typography.fontSize.xxxLarge};
        font-weight: ${theme.typography.fontWeight.normal};
        font-style: normal;
        line-height: 32px;
      }
    }
  }

  .chart-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-wrapper {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      margin-left: 3px;
    }

    .chart-title {
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-weight: ${theme.typography.fontWeight.normal};
      color: ${theme.colors.white};
      font-style: normal;
      line-height: 32px;
    }
  }

  .cpis-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.small}px;

    .MuiTypography-root {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.normal};
      font-size: ${theme.typography.fontSize.xxxLarge};
      color: ${theme.colors.neutral};
      line-height: 32px;
    }

    svg {
      margin-right: ${theme.spacing.xSmall}px;

      path {
        fill: ${theme.colors.neutral};
      }
    }
  }

  .no-data-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.xxLarge};
      color: ${theme.colors.white};
      line-height: 28px;
    }
  }

  .categories-grid-wrapper {
    height: 100%;
    padding: 0 ${theme.spacing.small}px;
    margin: 0 -${theme.spacing.small}px;
  }
`

export const CPIsSectionHeader = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.medium}px;

  .title-block {
    display: flex;
    align-items: center;
  }

  .MuiTypography-root {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.xLarge};
    color: ${theme.colors.neutral};
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .date {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.large};
    color: ${theme.baseColors.greyscale[60]};
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  svg {
    margin-right: ${theme.spacing.xSmall}px;

    path {
      fill: ${theme.colors.neutral};
    }
  }
`

export const InfoChipsWrapper = styled(Box)<{ forbenchmark?: string; isstub?: string }>`
  display: flex;
  align-items: center;

  ${({ forbenchmark }) =>
    forbenchmark &&
    forbenchmark === 'true' &&
    `
    margin-bottom: 26px;
    width: 100%;
  `};

  ${({ isstub }) =>
    isstub &&
    isstub === 'true' &&
    `
      margin-top: ${theme.spacing.md}px;
  `};

  .chip {
    display: flex;
    align-items: center;

    .chip-circle {
      margin-right: ${theme.spacing.half}px;
      border-radius: ${theme.attributes.borderRadius.tertiary};
      box-shadow: ${theme.attributes.boxShadow.chipCircle};
      width: 12px;
      height: 12px;

      &.pink {
        background: ${theme.colors.textPrimary};
      }

      &.blue {
        background: ${theme.colors.info[10]};
      }

      &.less {
        background: #8e9aff;
      }

      &.same {
        background: ${theme.baseColors.info[40]};
      }

      &.more {
        background: ${theme.baseColors.primary[50]};
      }

      &.stub {
        background: ${theme.colors.borderLow};
      }
    }

    .MuiTypography-root {
      font-family: ${theme.typography.fontFamily.primary};
      color: ${theme.colors.neutral};
      font-weight: ${theme.typography.fontWeight.normal};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      line-height: 16px;
      letter-spacing: 0.4px;
      white-space: nowrap;

      ${({ forbenchmark }) =>
        forbenchmark &&
        forbenchmark === 'true' &&
        `
          font-family: ${theme.typography.fontFamily.primary};
          font-weight: ${theme.typography.fontWeight.bold};
          font-size: ${theme.typography.fontSize.medium};
          color: ${theme.colors.white};
          font-style: normal;
          line-height: 16px;
          letter-spacing: 0.5px;
        `};
    }

    &:not(:first-of-type) {
      margin-left: ${theme.spacing.medium}px;
    }
  }
`

export const ExploreCategoriesWrapper = styled(Box)`
  display: flex;
  gap: ${theme.spacing.small}px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`
