import { Box, Drawer as MuiDrawer } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Drawer = styled(MuiDrawer)`
  > .MuiPaper-root {
    width: 33.33%;
    border-left: 1px solid ${theme.colors.borderLow};
    background: ${theme.colors.surfaceContainerBackground};
    overflow: hidden;
  }
`

export const Wrapper = styled(Box)`
  height: 100%;

  .header {
    height: 100px;
    border-bottom: 1px solid ${theme.colors.borderLow};
    padding: ${theme.spacing.medium}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiTypography-root {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;
    }

    .close-icon {
      width: 40px;
      height: 40px;
      color: ${theme.colors.textPrimary};

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .sub-header {
    padding: ${theme.spacing.medium}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-button {
      height: 40px;
      color: ${theme.baseColors.primary[20]};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;
      gap: ${theme.spacing.xSmall}px;
      padding: ${theme.spacing.xSmall}px ${theme.spacing.medium}px ${theme.spacing.xSmall}px ${theme.spacing.small}px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .disabled-add-button {
      padding: ${theme.spacing.sm}px 22px ${theme.spacing.sm}px 14px;
      min-width: 91px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      border-radius: ${theme.attributes.borderRadius.large};
      background: ${theme.baseColors.surface[50]};
      cursor: default;
      color: ${theme.baseColors.greyscale[50]};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;
      white-space: nowrap;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: ${theme.baseColors.greyscale[50]};
        }
      }
    }

    .items-wrapper {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;

      .items-text {
        color: ${theme.colors.textSecondaryLow};
        text-align: center;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      }

      .more-icon {
        color: ${theme.colors.textPrimary};

        &:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
`

export const Content = styled(Box)`
  display: flex;
  padding: ${theme.spacing.medium}px;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  height: calc(100vh - 188px);
  overflow-y: auto;

  .empty-box-wrapper {
    border: 1px solid ${theme.colors.borderLow};
    gap: ${theme.spacing.large}px;
    padding: ${theme.spacing.medium}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .text-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: ${theme.spacing.xSmall}px;

      .empty-box-header {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xxxLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: normal;
      }

      .empty-box-text {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;

        span {
          color: ${theme.colors.textPrimary};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: normal;
          cursor: pointer;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    background: ${theme.colors.borderLow};
    width: 12px !important;
    border: solid 6px transparent;
    border-radius: ${theme.attributes.borderRadius.mediumSmall};
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    border: solid 6px ${theme.colors.borderLow};
    background: ${theme.colors.white};
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid ${theme.colors.borderLow};
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${theme.colors.textPrimary};
  }
`
