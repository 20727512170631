import { Box, Select, FormControl } from '@mui/material'
import styled from '@emotion/styled'
import theme from 'theme'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isSort }) =>
    !isSort &&
    `
      gap: ${theme.spacing.xSmall}px;
  `};

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    color: ${theme.colors.white};
    white-space: nowrap;
  }
`

export const ResetDivider = styled(Box)`
  background: ${theme.colors.borderLow};
  height: 1px;
  width: 100%;
  margin: ${theme.spacing.md}px 0;
`

export const LibrarySelect = styled(Select)<{ isselected?: string; noborder?: string; iscustom?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  gap: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.small};
  border: 1px solid ${theme.colors.controlBorder};
  position: relative;

  ${({ noborder }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: ${theme.colors.white};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.xLarge};
  font-weight: ${theme.typography.fontWeight.normal};
  text-align: left;

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    height: 40px;
  `};

  .MuiOutlinedInput-root {
    ${({ iscustom }) =>
      iscustom &&
      iscustom === 'true' &&
      `
      height: 40px;
  `};
  }

  svg.svg-dropdown {
    top: calc(50% - 0.8em);
    right: ${theme.spacing.xSmall}px;
    width: 24px;
    height: 24px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: ${theme.colors.white};
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: ${theme.colors.white} !important;
    }
  }

  &.Mui-disabled {
    svg.svg-dropdown {
      path {
        fill: ${theme.baseColors.greyscale[50]} !important;
      }
    }
  }
`

export const LibraryFormControl = styled(FormControl)<{
  noborder?: string
  isselected?: string
  iscustom?: string
  isSort?: boolean
  isdisabled?: string
}>`
  outline: none;
  min-width: 120px;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.small};
  display: flex;
  position: relative;
  max-width: ${({ isSort }) => (isSort ? 'auto' : '200px')};

  ${({ noborder }) =>
    noborder !== 'true' &&
    `
    border: 1px solid ${theme.colors.controlBorder};
  `};

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-weight: ${theme.typography.fontWeight.normal};
    text-align: left;

    &.Mui-disabled {
      pointer-events: none;
      border: 1px solid transparent;
    }

    .MuiOutlinedInput-input {
      &.Mui-disabled {
        -webkit-text-fill-color: ${theme.baseColors.greyscale[50]};
      }
    }
  }

  &:hover {
    border: 1px solid ${theme.colors.textPrimary};
    background: rgba(208, 188, 255, 0.16);
  }

  ${({ isdisabled }) =>
    isdisabled &&
    isdisabled === 'true' &&
    `
    border: 1px solid ${theme.baseColors.greyscale[50]};
    
        &:hover {
      border-color: ${theme.baseColors.greyscale[50]};
    }
  `};

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.backgroundDark,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.small,
      height: 'unset',
      minWidth: '250px',
      maxHeight: '348px !important',
      marginTop: `${theme.spacing.half}px`,
      overflowY: 'hidden',

      '.MuiList-root': {
        background: theme.colors.surfaceItemBackground,
        padding: `0 ${theme.spacing.xSmall}px !important`,
        overflowY: 'auto',
        maxHeight: '325px',
        height: '100%',

        '&::-webkit-scrollbar-track': {
          background: theme.baseColors.surface[50]
        },

        '&::-webkit-scrollbar-thumb': {
          background: theme.colors.textPrimary,
          height: '4px',
          width: '4px',
          border: '4px solid transparent',
          backgroundClip: 'content-box'
        },

        '.results-header-text': {
          color: theme.colors.textSecondaryLow,
          fontSize: theme.typography.fontSize.small,
          fontStyle: 'normal',
          fontWeight: theme.typography.fontWeight.bold,
          lineHeight: 'normal',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          margin: `0 ${theme.spacing.xSmall}px {theme.spacing.xSmall$px 0`
        }
      },

      '.MuiMenuItem-root': {
        height: '40px',
        padding: 0,
        paddingRight: `${theme.spacing.xSmall}px`,
        borderRadius: theme.attributes.borderRadius.small,

        '.integration-icon': {
          width: '24px',
          height: '24px',
          minWidth: '24px',
          borderRadius: theme.attributes.borderRadius.circle
        },

        '&:hover': {
          background: `${theme.colors.primaryLight} !important`
        },

        '.MuiCheckbox-root': {
          padding: `${theme.spacing.xSmall}px`,

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          }
        },

        '.MuiListItemText-root': {
          paddingLeft: `${theme.spacing.xSmall}px`,

          '.MuiTypography-root': {
            fontFamily: theme.typography.fontFamily.primary,
            fontWeight: theme.typography.fontWeight.bold,
            fontSize: theme.typography.fontSize.xLarge,
            lineHeight: '24px',
            letterSpacing: '0.1px',
            color: theme.colors.white,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: theme.attributes.boxShadow.chipCircle,
            margin: '-4.5px',
            borderRadius: theme.attributes.borderRadius.circle,

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
