import { FC, ReactNode } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Dialog } from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { modalPropsSelector, closeModal } from '../../../store/modals/modalSlice'
import theme from 'theme'

interface Props {
  content: ReactNode
  sx?: Record<string, unknown>
  paperStyles?: Record<string, unknown>
  disableCloseOnClickOutside?: boolean
}

const Modal: FC<Props> = ({ content, sx, disableCloseOnClickOutside, paperStyles }) => {
  const dispatch = useAppDispatch()
  const { open } = useAppSelector(modalPropsSelector)

  const handleClose = (e: Event, reason: string) => {
    if (disableCloseOnClickOutside && reason === 'backdropClick') {
      return
    }

    dispatch(closeModal())
  }

  const muiTheme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            width: (sx?.width as string) ?? '715px',
            maxWidth: (sx?.width as string) ?? '715px',
            background: theme.colors.backgroundDark,
            color: theme.colors.white,
            ...paperStyles
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            borderColor: theme.colors.primary
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            boxShadow: theme.attributes.boxShadow.chipCircle
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={muiTheme}>
      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="dialog-title"
        disableEscapeKeyDown={disableCloseOnClickOutside}
      >
        {content}
      </Dialog>
    </ThemeProvider>
  )
}

export default Modal
