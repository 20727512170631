import { FC, SVGProps } from 'react'
import theme from 'theme'

const NewIntegrationIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_216_60080)">
      <path
        d="M11.084 5.25016L11.8132 3.646L13.4173 2.91683L11.8132 2.18766L11.084 0.583496L10.3548 2.18766L8.75065 2.91683L10.3548 3.646L11.084 5.25016ZM6.70898 5.54183L5.25065 2.3335L3.79232 5.54183L0.583984 7.00016L3.79232 8.4585L5.25065 11.6668L6.70898 8.4585L9.91732 7.00016L6.70898 5.54183ZM11.084 8.75016L10.3548 10.3543L8.75065 11.0835L10.3548 11.8127L11.084 13.4168L11.8132 11.8127L13.4173 11.0835L11.8132 10.3543L11.084 8.75016Z"
        fill={theme.colors.white}
      />
    </g>
    <defs>
      <clipPath id="clip0_216_60080">
        <rect width="14" height="14" fill={theme.colors.white} />
      </clipPath>
    </defs>
  </svg>
)

export default NewIntegrationIcon
