import { Grid, Box, InputLabel as MuiInputLabel, FormControl as MuiFormControl } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Grid)`
  min-height: 364px;
  height: 100%;
`

export const GridItem = styled(Grid)`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

export const GridItemWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 73px;
`

export const InputLabel = styled(MuiInputLabel)`
  color: #dbdbdb;
  font-size: ${theme.typography.fontSize.medium};
  line-height: 16px;
  margin-bottom: 2px;
`

export const FormControl = styled(MuiFormControl)`
  width: 25ch;
`

export const outlinedInputSx = {
  width: '280px',
  height: '32px',
  backgroundColor: theme.colors.white
}
