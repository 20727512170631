import { FC } from 'react'
import { Typography, IconButton, DialogContent, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import theme from 'theme'

import { Dialog } from './leaveSetupIntegrations.styles'
import ThemeButton from '../../../../components/buttons/primary/ThemeButton'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'

const LeaveSetupIntegrations: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)

  const handleCancel = () => {
    if (modalProps?.props?.cancelNavigation) {
      modalProps?.props?.cancelNavigation()
    }

    dispatch(closeModal())

    if (modalProps?.props?.cancelCallback) {
      modalProps.props.cancelCallback()
    }
  }

  const handleOk = () => {
    if (modalProps?.props?.confirmNavigation) {
      modalProps?.props?.confirmNavigation()
    }

    dispatch(closeModal())

    if (modalProps?.props?.okCallback) {
      modalProps?.props?.okCallback()
    }
  }

  return (
    <Dialog onClose={handleCancel} aria-labelledby="enlarge-image-dialog" open={modalProps.open}>
      <Box className="header">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.33337 28.6668H30.6667L16 3.3335L1.33337 28.6668ZM17.3334 24.6668H14.6667V22.0002H17.3334V24.6668ZM17.3334 19.3335H14.6667V14.0002H17.3334V19.3335Z"
            fill={theme.colors.textPrimary}
          />
        </svg>

        <Typography className="header-title">Warning</Typography>
      </Box>

      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: theme.colors.textPrimary,

          '&:hover': {
            background: theme.baseColors.primary[20],
            svg: {
              path: {
                fill: theme.colors.white
              }
            }
          }
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Typography className="content-header">You have unsaved changes in this page.</Typography>
        <Typography className="content-description">
          To save the changes, click ‘Cancel’ and then click the ‘Save’ button in the top right corner of the page.
        </Typography>
        <Typography className="content-description">To proceed without saving the changes, click ‘OK’</Typography>
      </DialogContent>

      <Box className="footer">
        <ThemeButton themevariant="secondary" onClick={handleCancel} sx={{ minWidth: '95px' }}>
          Cancel
        </ThemeButton>
        <ThemeButton themevariant="primary" onClick={() => handleOk()} sx={{ minWidth: '95px' }}>
          OK
        </ThemeButton>
      </Box>
    </Dialog>
  )
}

export default LeaveSetupIntegrations
