import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip as MuiTooltip } from '@mui/material'
import theme from 'theme'

export const DataSourceTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.chipCircle
  },
  '.tooltip-list': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: `${theme.spacing.small}px`
  },
  '.tooltip-wrapper': {
    display: 'flex',
    alignItems: 'center',

    img: {
      marginRight: `${theme.spacing.xSmall}px`,
      borderRadius: theme.attributes.borderRadius.circle
    }
  },
  '.tooltip-title': {
    color: theme.colors.neutral,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.mediumSmall,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.bold,
    lineHeight: '16px',
    letterSpacing: '0.5px'
  },
  '.tooltip-category': {
    textTransform: 'capitalize',
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.medium,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  },
  '.sources-count-text': {
    color: theme.colors.white,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.xLarge,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeight.normal,
    lineHeight: 'normal'
  }
})
