import { FC, HTMLAttributes } from 'react'
import { NoIntegrationLogo, ResultListItem } from './searchVendors.styles'
import { SSMResponseItem, Vendor } from '../../../../models'
import { IconTooltipDisabled } from './IconTooltipDisabled'
import { Box, Typography } from '@mui/material'
import theme from 'theme'

interface Props {
  option: Vendor
  optionProps: HTMLAttributes<HTMLLIElement>
  getIntegrationLogo: (urlPath: string) => string
  vendor: SSMResponseItem | undefined
  product: SSMResponseItem | null
}

const ListItemDisabled: FC<Props> = ({ option, optionProps, getIntegrationLogo, vendor, product }) => {
  return (
    <ResultListItem {...optionProps} onClick={(e) => e.preventDefault()}>
      <div style={{ position: 'relative' }}>
        <IconTooltipDisabled
          placement="left-start"
          PopperProps={{
            sx: {
              marginRight: '7px !important'
            }
          }}
          title={
            <>
              <Box className="tooltip-wrapper is-disabled-wrapper">
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="arrow-right"
                >
                  <path d="M12 7.0718L0 0.143593V14L12 7.0718Z" fill={theme.colors.controlBorder} />
                </svg>
                <Typography className="is-disabled-text">Product exists in this cell</Typography>
              </Box>
            </>
          }
        >
          <Box className="list-item-wrapper">
            {option.logo_path && option.logo_path.length > 0 && option.logo_path !== 'N/A' ? (
              <img src={getIntegrationLogo(option.logo_path)} width="24px" height="24px" alt={option.display_name} />
            ) : (
              <NoIntegrationLogo>
                <Typography className="no-integration-logo-letter">
                  {option.vendor && option.vendor.display_name ? option.vendor.display_name[0] : option.display_name[0]}
                </Typography>
              </NoIntegrationLogo>
            )}

            <Typography className="option-text text-disabled">
              {vendor?.display_name || ''}
              {product && ` ${product.display_name}`}
            </Typography>
          </Box>
        </IconTooltipDisabled>
      </div>
    </ResultListItem>
  )
}

export default ListItemDisabled
