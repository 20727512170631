import { FC, SVGProps } from 'react'
import theme from 'theme'

const SelectDropdownIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill={theme.colors.textPrimary} />
  </svg>
)

export default SelectDropdownIcon
