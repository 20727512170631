import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DeselectNotificationWrapper = styled(Box)`
  padding: 28px;
  background: ${theme.colors.backgroundDarker};

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 32px;
    color: ${theme.colors.neutral};
    margin-bottom: ${theme.spacing.md}px;
  }

  .description {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.colors.neutral};
    max-width: 422px;
  }
`

export const ActionWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.xSmall}px;
  margin-top: 28px;
`
