import { Box, DialogActions, DialogContent } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)``

export const TransferOwnershipWrapper = styled(Box)`
  .content {
    padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
    margin-bottom: ${theme.spacing.xLarge}px;

    .title {
      font-size: 36px;
      font-family: ${theme.typography.fontFamily.primary};
      line-height: 44px;
      color: ${theme.colors.neutral};
    }

    .text {
      color: ${theme.colors.neutral};
      ${theme.typography.fontSize.large};
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      margin-top: ${theme.spacing.xSmall}px;
    }
  }
`

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 28px;
    color: ${theme.colors.neutral};
  }
`

export const Content = styled(DialogContent)`
  padding: 0;

  .copy-btn {
    &:hover {
      background: rgba(208, 188, 255, 0.12) !important;
    }

    &:focus {
      background: rgba(208, 188, 255, 0.12) !important;
    }
  }

  .form-group {
    margin-bottom: ${theme.spacing.small}px;
    position: relative;
    width: 92%;
    padding-top: ${theme.spacing.half}px;

    .first svg {
      width: 24px;
      height: 24px;
    }

    .sso-url-info {
      position: absolute;
      right: -${theme.spacing.xLarge}px;
      top: ${theme.spacing.xSmall}px;
    }

    .MuiFormControl-root {
      width: 100%;
      border: 1px solid ${theme.colors.controlBorder};
      border-radius: ${theme.attributes.borderRadius.small};
    }

    .MuiOutlinedInput-root.Mui-focused {
      border: 2px solid ${theme.colors.textPrimary};
      outline: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0 !important;
    }

    .not-valid {
      .MuiOutlinedInput-root {
        border: 2px solid #ffb4ab;
      }

      .MuiInputLabel-root {
        color: #ffb4ab;
      }
    }

    label {
      background: ${theme.colors.primaryDark};
      padding-left: 6px;
      padding-right: 6px;
      color: ${theme.colors.neutral};
    }

    label.Mui-focused {
      color: ${theme.colors.neutral};
      background: ${theme.colors.primaryDark};
    }

    input,
    textarea {
      color: ${theme.colors.neutral} !important;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .Mui-disabled {
      color: ${theme.colors.neutral} !important;
      -webkit-text-fill-color: ${theme.colors.neutral} !important;
    }
  }

  .user-roles {
    display: flex;
    align-items: center;

    .name {
      font-family: ${theme.typography.fontFamily.primary};
      ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }

  .roles-dropdown {
    max-width: 150px;
  }

  .claim-panel {
    margin-top: 22px;

    .name {
      font-family: ${theme.typography.fontFamily.primary};
      margin-bottom: ${theme.spacing.small}px;
      color: ${theme.colors.white};
      ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    .claim-content {
      padding: 10px;
      border-radius: ${theme.attributes.borderRadius.small};
      border: 1px solid ${theme.colors.controlBorder};
      box-shadow: ${theme.attributes.boxShadow.chipCircle};

      .form-group {
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

export const Actions = styled(DialogActions)`
  margin-top: 36px;
`
