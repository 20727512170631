import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 0

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;
`

export const AuditLogFormControl = styled(FormControl)<{ isselected?: string }>`
  border: 1px solid ${theme.colors.controlBorder};
  background: transparent;
  outline: none;
  min-width: 120px;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.large};

  .label-text {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${theme.baseColors.greyscale[80]};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  &:hover {
    border-radius: ${theme.attributes.borderRadius.large};
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: ${theme.colors.textPrimary};
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: ${theme.spacing.small}px;

    path {
      fill: ${theme.colors.textPrimary};
    }
  }

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const AuditLogSingleFormControl = styled(FormControl)<{ isselected: string; isopen: string }>`
  background: transparent;
  outline: none;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};

  &:hover {
    cursor: default !important;
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    min-width: unset;
    padding: ${theme.spacing.sm}px ${theme.spacing.small}px;

    &:hover {
      cursor: default !important;
      border-radius: ${theme.attributes.borderRadius.large};
      background: rgba(234, 221, 255, 0.08);

      .MuiSelect-select {
        cursor: default !important;
      }

      .MuiListItemText-root {
        cursor: default !important;

        .MuiTypography-root {
          cursor: default;
          color: ${theme.colors.textPrimary};
        }
      }
    }

    .MuiSelect-select.MuiSelect-outlined {
      padding-left: 0;
      padding-right: ${theme.spacing.small}px;
    }

    .MuiListItemText-root {
      .MuiTypography-root {
        color: ${theme.colors.white};
        text-align: center;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    ${({ isopen }) =>
      isopen === 'true' &&
      `
        cursor: default !important;
        border-radius: ${theme.attributes.borderRadius.large};
        background: rgba(234, 221, 255, 0.08);
  
        .MuiSelect-select {
          cursor: default !important;
        }

        .MuiListItemText-root {
          cursor: default !important;
  
          .MuiTypography-root {
            cursor: default;
            color: ${theme.colors.textPrimary};
          }
        }
    `};
  }

  svg {
    top: calc(50% - 0.6em);
    right: ${theme.spacing.sm}px;

    path {
      fill: ${theme.colors.textPrimary};
    }
  }

  ${({ isselected }) =>
    isselected === 'true' &&
    `
      border-radius: ${theme.attributes.borderRadius.large};
      border: 1px solid ${theme.colors.textPrimary};
      background: rgba(208, 188, 255, 0.16);
      
      .MuiOutlinedInput-root { 
        .MuiListItemText-root {
          .MuiTypography-root {
            color: ${theme.colors.textPrimary};
          }
        }
      }
    `}
`

export const AuditLogSelect = styled(Select)<{ isselected?: string; noborder?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};
  gap: ${theme.spacing.xSmall}px;

  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: ${theme.colors.white};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: ${theme.baseColors.secondary[40]};
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    color: ${theme.baseColors.greyscale[80]};
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.backgroundDark,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.small,
      minWidth: '288px',

      '.MuiMenuItem-root': {
        height: '32px',
        paddingLeft: `${theme.spacing.small}px`,
        paddingRight: `${theme.spacing.small}px`,
        minWidth: '288px',

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important',

          '&:last-of-type': {
            background: 'transparent !important'
          }
        },

        '&.reset-button-wrapper': {
          justifyContent: 'flex-end',
          alignItems: 'center',

          '&.Mui-disabled': {
            opacity: '0.38',

            '.MuiButton-root.reset-button': {
              color: theme.colors.white
            }
          },

          '&:hover': {
            '.MuiButton-root.reset-button': {
              background: 'rgba(234, 221, 255, 0.08)'
            }
          },

          '.MuiButton-root.reset-button': {
            borderRadius: theme.attributes.borderRadius.large,
            background: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: `${theme.spacing.xSmall}px`,
            color: theme.colors.textPrimary,
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily.primary,
            fontSize: theme.typography.fontSize.large,
            fontStyle: 'normal',
            fontWeight: theme.typography.fontWeight.bold,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            textTransform: 'none'
          }
        },

        '.MuiCheckbox-root': {
          paddingRight: '19px',
          paddingLeft: 0
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontSize: theme.typography.fontSize.large,
          lineHeight: '20px',
          letterSpacing: '0.25px',
          color: theme.baseColors.greyscale[80]
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: theme.attributes.boxShadow.chipCircle,
            margin: '-4.5px',
            borderRadius: theme.attributes.borderRadius.circle,

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
