import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DataSourceWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .header-wrapper {
    display: flex;
    align-items: center;

    sup {
      vertical-align: super;
      font-size: ${theme.typography.fontSize.small};
    }

    .source-selector-title {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      letter-spacing: 0.1px;
      margin-bottom: ${theme.spacing.half}px;
    }
  }
`
