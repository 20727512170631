import { FC, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import theme from 'theme'

/* Utils */
import axios from '../../../../lib/axios'
import { openInNewTab } from '../reports.utils'
import { useAppDispatch } from '../../../../store/hooks'
import { AllCPIsReportType, dateFormat } from '../../../../lib/utils'
import { closeToast, openToast, ToastVariant } from '../../../../store/toasts/toastSlice'
// import { useFramework } from '../../../../hooks/useFramework'

/* Components */
import TabsHeader from '../../../components/tabs-header'
import { ThemeButton } from '../../../components/buttons'
import {
  ActionsWrapper,
  CustomizerContainer,
  FormItemInfoWrapper,
  FormItemWrapper,
  FormWrapper,
  Wrapper
} from '../reports.styles'
import { DownloadIcon, InfoIconLarge } from '../../../components/svg'
import ReportsSelectField from '../customize-report/components/ReportsSelectField'

dayjs.extend(isBetween)

const QuarterlyReportBuilder: FC = () => {
  const dispatch = useAppDispatch()
  const closeNotification = useRef<any>(null)
  const [generatingCpiReport, setGeneratingCpiReport] = useState(false)
  const [selectedQuarter, setSelectedQuarter] = useState('')
  const [code, setCode] = useState('')
  // const { framework, customFramework } = useFramework()

  const { data, error, isFetching } = useQuery(
    ['code', { code }],
    () => axios.get(`/api/v1/reports/download_url/${code}`),
    {
      refetchInterval: 5000,
      enabled: !!code
    }
  )

  useEffect(() => {
    if (error && (error as any).response?.status === 418) {
      setGeneratingCpiReport(false)
      setCode('')
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: (error as any).response?.data.error || 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }, [error])

  useEffect(() => {
    if (data && !error && !isFetching) {
      const downloadUrl = data.data.reportDownloadUrl.url
      openInNewTab(`${downloadUrl}`)

      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setCode('')
    }
  }, [data, error, isFetching, code])

  const generateFullReport = async () => {
    setGeneratingCpiReport(true)

    // const fw = framework === 'onyxia' ? 'onyxia' : framework === 'nist' ? 'nist' : 'custom'

    try {
      const parts = selectedQuarter.split(' ')
      const quarter = parseInt(parts[0].replace('Q', ''), 10)
      const year = parts[1]
      const startMonth = quarter === 1 ? '01' : quarter === 2 ? '04' : quarter === 3 ? '07' : '10'
      const endMonth = quarter === 1 ? '03' : quarter === 2 ? '06' : quarter === 3 ? '09' : '12'
      const daysInEndMonth = dayjs(`${year}-${endMonth}-01`).daysInMonth()
      const start = dayjs(`${year}-${startMonth}-01`)
      const end = dayjs(`${year}-${endMonth}-${daysInEndMonth}`)

      const dateFrom = dayjs(start).format(dateFormat.customLabel)
      const dateTo = dayjs(end).format(dateFormat.customLabel)

      const payload = {
        dateFrom,
        dateTo,
        type: AllCPIsReportType.QUARTERLY
      }

      const res = await axios.post(`/api/v1/reports/all_cpis`, payload)

      if (res.status.toString().startsWith('2')) {
        const { code } = res.data
        setCode(code)
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
    } finally {
      setGeneratingCpiReport(false)
    }
  }

  const getQuarterOptions = () => {
    const today = dayjs()
    const yearFormatted = dayjs(today).get('year')
    const daysInMarch = dayjs(`${yearFormatted}-03-01`).daysInMonth()
    const daysInJune = dayjs(`${yearFormatted}-06-01`).daysInMonth()
    const daysInSeptember = dayjs(`${yearFormatted}-09-01`).daysInMonth()
    const daysInDecember = dayjs(`${yearFormatted}-12-01`).daysInMonth()

    const firstQuarter = {
      start: `${yearFormatted}-01-01`,
      end: `${yearFormatted}-03-${daysInMarch}`,
      secondary: {
        start: `${yearFormatted}-03-15`,
        end: `${yearFormatted}-03-${daysInMarch}`
      }
    }
    const secondQuarter = {
      start: `${yearFormatted}-04-01`,
      end: `${yearFormatted}-06-${daysInJune}`,
      secondary: {
        start: `${yearFormatted}-06-15`,
        end: `${yearFormatted}-06-${daysInJune}`
      }
    }
    const thirdQuarter = {
      start: `${yearFormatted}-07-01`,
      end: `${yearFormatted}-09-${daysInSeptember}`,
      secondary: {
        start: `${yearFormatted}-09-15`,
        end: `${yearFormatted}-09-${daysInSeptember}`
      }
    }
    const fourthQuarter = {
      start: `${yearFormatted}-10-01`,
      end: `${yearFormatted}-12-${daysInDecember}`,
      secondary: {
        start: `${yearFormatted}-12-15`,
        end: `${yearFormatted}-12-${daysInDecember}`
      }
    }

    const getQuarter = () => {
      let quarter = 1
      let year = yearFormatted
      const isBetweenFirst = dayjs(today).isBetween(firstQuarter.start, firstQuarter.end, 'day', '[]')
      const isBetweenSecond = dayjs(today).isBetween(secondQuarter.start, secondQuarter.end, 'day', '[]')
      const isBetweenThird = dayjs(today).isBetween(thirdQuarter.start, thirdQuarter.end, 'day', '[]')
      const isBetweenFourth = dayjs(today).isBetween(fourthQuarter.start, fourthQuarter.end, 'day', '[]')

      if (isBetweenFirst) {
        const isBetweenSecondary = dayjs(today).isBetween(
          firstQuarter.secondary.start,
          firstQuarter.secondary.end,
          'day',
          '[]'
        )
        quarter = isBetweenSecondary ? 1 : 4
        if (!isBetweenSecondary) {
          year = yearFormatted - 1
        }
      } else if (isBetweenSecond) {
        const isBetweenSecondary = dayjs(today).isBetween(
          secondQuarter.secondary.start,
          secondQuarter.secondary.end,
          'day',
          '[]'
        )
        quarter = isBetweenSecondary ? 2 : 1
      } else if (isBetweenThird) {
        const isBetweenSecondary = dayjs(today).isBetween(
          thirdQuarter.secondary.start,
          thirdQuarter.secondary.end,
          'day',
          '[]'
        )
        quarter = isBetweenSecondary ? 3 : 2
      } else if (isBetweenFourth) {
        const isBetweenSecondary = dayjs(today).isBetween(
          fourthQuarter.secondary.start,
          fourthQuarter.secondary.end,
          'day',
          '[]'
        )
        quarter = isBetweenSecondary ? 4 : 3
      }

      return { quarter, year }
    }
    const currentQuarter = getQuarter()
    const remainingQuarters = [1, 2, 3, 4].filter((n) => n !== currentQuarter.quarter)

    const allQuarters: string[] = [`Q${currentQuarter.quarter} ${currentQuarter.year}`]

    for (let i = 0; i < remainingQuarters.length; i++) {
      const thisQuarter = remainingQuarters[i]

      if (thisQuarter > currentQuarter.quarter) {
        const thisYear = yearFormatted - 1
        allQuarters.push(`Q${thisQuarter} ${thisYear}`)
      } else if (thisQuarter < currentQuarter.quarter) {
        const thisYear = yearFormatted > currentQuarter.year ? currentQuarter.year : yearFormatted
        allQuarters.push(`Q${thisQuarter} ${thisYear}`)
      }
    }

    return allQuarters.sort((a, b) => {
      const partA = a.split(' ')
      const yearA = parseInt(partA[1], 10)
      const quarterA = parseInt(partA[0].replace('Q', ''), 10)
      const partB = b.split(' ')
      const yearB = parseInt(partB[1], 10)
      const quarterB = parseInt(partB[0].replace('Q', ''), 10)

      if (yearA === yearB) {
        return quarterA > quarterB ? 1 : -1
      }
      if (yearA > yearB) {
        return quarterA < quarterB ? 1 : -1
      }
      return quarterB > quarterA ? 1 : -1
    })
  }

  return (
    <CustomizerContainer>
      <TabsHeader
        tabValue={0}
        parents={['Reports']}
        handleTabChange={() => {
          //
        }}
        title="Customize a Quarterly Report"
        tabLabels={[]}
        hideIntegrationsTabs={true}
      />

      <Wrapper>
        <FormWrapper>
          <FormItemWrapper>
            <Typography className="title">Select the quarter:</Typography>
            <ReportsSelectField
              options={getQuarterOptions()}
              value={selectedQuarter}
              handleChange={(e: any) => setSelectedQuarter(e.target.value)}
            />
            <FormItemInfoWrapper>
              <Box className="icon-wrapper">
                <InfoIconLarge />
              </Box>
              <Typography className="info-text">
                Note: Quarterly reports are enabled after the 15th day of the last month in a quarter to ensure there is
                sufficient data available for accurate representation.
              </Typography>
            </FormItemInfoWrapper>
          </FormItemWrapper>
        </FormWrapper>

        <ActionsWrapper sx={{ marginBottom: '50px' }}>
          <ThemeButton onClick={generateFullReport} disabled={!selectedQuarter || generatingCpiReport || isFetching}>
            <>
              {generatingCpiReport || isFetching ? (
                <CircularProgress size={18} color="secondary" />
              ) : (
                <DownloadIcon fill={!selectedQuarter ? theme.baseColors.greyscale[80] : theme.baseColors.primary[20]} />
              )}
              Download PDF
            </>
          </ThemeButton>
        </ActionsWrapper>
      </Wrapper>
    </CustomizerContainer>
  )
}

export default QuarterlyReportBuilder
