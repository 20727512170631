import { FC, SVGProps } from 'react'
import theme from 'theme'

const CopyLineIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.375 0.75H3.375C2.55 0.75 1.875 1.425 1.875 2.25V12.75H3.375V2.25H12.375V0.75ZM11.625 3.75H6.375C5.55 3.75 4.8825 4.425 4.8825 5.25L4.875 15.75C4.875 16.575 5.5425 17.25 6.3675 17.25H14.625C15.45 17.25 16.125 16.575 16.125 15.75V8.25L11.625 3.75ZM6.375 15.75V5.25H10.875V9H14.625V15.75H6.375Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default CopyLineIcon
