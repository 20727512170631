import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const FormWrapper = styled(Box)`
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.colors.textPrimary} !important;
  }

  .MuiInputLabel-root.Mui-focused {
    color: ${theme.colors.textPrimary} !important;
  }

  .texts {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xLarge};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.colors.neutral};
    text-transform: capitalize;
  }

  svg {
    color: ${theme.colors.white};
  }

  .MuiList-root {
    background: red;
  }
`
