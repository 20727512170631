import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  position: relative;
  display: inline-flex;
  margin: 0 auto;
  flex-grow: 1;

  @media (max-width: 1445px) {
    flex-grow: 0;
    margin: 0;
  }

  .over-perform {
    position: absolute;
    right: -151px;
    bottom: 0;

    .info {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;

      .over-perform-value {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
      }
    }

    .over-perform-desc {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`

export const CircleInner = styled(CircularProgress)`
  color: ${theme.baseColors.info[10]};
  animation-duration: 550ms;
  position: absolute;
  left: 0;
`

export const CircleOuter = styled(CircularProgress)`
  color: ${theme.colors.textPrimary};
`

export const ValueWrapper = styled(Box)`
  top: 5px;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 200px;
  flex-direction: column;

  @media (max-width: 1440px) {
    top: 0;
  }

  .value {
    color: ${theme.colors.white};
    text-shadow: 0 4.341px 4.341px rgba(0, 0, 0, 0.3), 0 8.683px 13.024px rgba(0, 0, 0, 0.15);
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.epic};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 40px;
    margin-bottom: ${theme.spacing.xSmall}px;
  }

  .caption {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`
