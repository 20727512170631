import { Button } from '@mui/material'
import styled from '@emotion/styled/macro'
import type { ThemeVariant } from './ThemeButton'
import theme from 'theme'

export const StyledButton = styled(Button)<{
  themevariant?: ThemeVariant
  noActive?: boolean | undefined
  font?: string
}>`
  border: 1px solid ${theme.colors.textPrimary};
  border-radius: ${theme.attributes.borderRadius.large};
  background: ${theme.colors.textPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.sm}px 22px ${theme.spacing.sm}px 14px;
  gap: ${theme.spacing.xSmall}px;
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${theme.baseColors.primary[20]};
  height: 40px;
  text-transform: none;
  white-space: nowrap;

  &:hover {
    background: rgba(208, 188, 255, 0.12);
    color: ${theme.baseColors.primary[90]};
  }

  ${({ font }) =>
    font === 'big' &&
    `
     font-size: ${theme.typography.fontSize.xLarge};
     font-style: normal;
     font-weight: ${theme.typography.fontWeight.thin};
     line-height: normal;
  `};

  ${({ noActive }) =>
    !noActive &&
    `
      &:active,
      &:focus {
        background: rgba(208, 188, 255, 0.12);
        color: ${theme.baseColors.primary[90]};
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'primary' &&
    `
      color: ${theme.baseColors.primary[20]};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;

      svg {
        path {
          fill: ${theme.baseColors.primary[20]};
        }
      }
      
      &:hover {
        background: ${theme.baseColors.primary[70]};
        color: ${theme.baseColors.primary[20]};
        border-color: ${theme.baseColors.primary[30]};
        box-shadow: ${theme.attributes.boxShadow.quaternary};
        
        svg {
          path {
            fill: ${theme.baseColors.primary[20]};
          }
        }
      }
      
      &:active, &:focus {
        background: ${theme.colors.textPrimary};
        color: ${theme.baseColors.primary[20]};
        border-color: ${theme.colors.white};
        
        svg {
          path {
            fill: ${theme.baseColors.primary[20]};
          }
        }
      }
      
      &.Mui-disabled {
        color: ${theme.baseColors.greyscale[50]} !important;
        background: ${theme.baseColors.surface[50]};
        border: none;
        
        svg {
          path {
            fill: ${theme.baseColors.greyscale[50]};
          }
        }
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'secondary' &&
    `
      font-size: ${theme.typography.fontSize.xLarge};
      font-weight: ${theme.typography.fontWeight.thin};
      padding: ${theme.spacing.sm}px ${theme.spacing.small}px;
      height: 40px;
      color: ${theme.colors.textPrimary};
      background: transparent;
      border-color: ${theme.colors.controlBorder};
      
      svg {
        path {
          fill: ${theme.colors.textPrimary};
        }
      }
      
      &:hover {
        border: 1px solid ${theme.colors.controlBorder};
        background: ${theme.baseColors.secondary[30]};
        color: ${theme.colors.textPrimary};
        
        svg {
          path {
            fill: ${theme.colors.textPrimary};
         }
        }
      }
      
      &:active, &:focus {
        background: transparent;
        color: ${theme.colors.textPrimary};
        border: 1px solid ${theme.colors.white};
        
        svg {
          path {
            fill: ${theme.colors.textPrimary};
         }
        }
      }
      
      &:active {
        background: ${theme.baseColors.primary[20]};
        color: ${theme.colors.white};
        
        svg {
          path {
            fill: ${theme.colors.white};
          }
        }
      }
      
      &:disabled {
        border: none;
        background: ${theme.baseColors.surface[50]};
        color: ${theme.baseColors.greyscale[50]};
        
        svg {
          path {
            fill: ${theme.baseColors.greyscale[50]};
          }
        }
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'tertiary' &&
    `
      color: ${theme.colors.textPrimary};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;
      background: transparent;
      border: none;
      
      svg {
        path {
          fill: ${theme.colors.textPrimary};
        }
      }
      
      &:hover {
        background: ${theme.baseColors.secondary[30]};
        color: ${theme.colors.textPrimary};
        
        svg {
          path {
            fill: ${theme.colors.textPrimary};
          }
        }
      }
      
      &:active {
        background: ${theme.baseColors.primary[20]};
        color: ${theme.colors.white};
      }
      
      &:focus {
        background: transparent;
        color: ${theme.colors.textPrimary};
        border: 1px solid ${theme.colors.white};
      }
      
      &:disabled {
        background: ${theme.baseColors.surface[50]};
        color: ${theme.baseColors.greyscale[50]};
        
        svg {
          path {
            fill: ${theme.baseColors.greyscale[50]};
          }
        }
      }
      
      &.Mui-disabled {
        svg {
          path {
            fill: ${theme.baseColors.greyscale[50]} !important;
          }
        }
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'quaternary' &&
    `
      color: ${theme.colors.textPrimary};
      background: ${theme.colors.backgroundDarker};
      border: none;
      
      svg {
        path {
          fill: ${theme.colors.textPrimary};
        }
      }
      
      &.Mui-disabled {
        background: rgba(227, 227, 227, 0.12);
        color: ${theme.colors.neutral};

        svg {
          path {
            fill: ${theme.colors.neutral};
            opacity: 0.38;
          }
        }
      }
      
      &:hover {
        background: rgba(208, 188, 255, 0.08);
        color: ${theme.colors.textPrimary};
        
        svg {
          path {
            fill: ${theme.colors.textPrimary};
          }
        }
      }
      
      &:active, &:focus {
        background: rgba(208, 188, 255, 0.12);
        color: ${theme.colors.textPrimary};
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'ghost' &&
    `
      color: ${theme.baseColors.primary[20]};
      background: ${theme.colors.textPrimary};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-weight: ${theme.typography.fontWeight.thin};
      border: none;
      border: 1px solid transparent;
      
      svg {
        path {
          fill: ${theme.baseColors.primary[20]};
        }
      }
      
     &:hover {
      background: ${theme.baseColors.primary[70]};
      color: ${theme.baseColors.primary[20]};
      border: 1px solid transparent;
     }
     
     &:focus {
      border: 1px solid ${theme.colors.white};
      background: ${theme.colors.textPrimary};
     }
     
     &:active {
      background: ${theme.baseColors.primary[20]};
     }
     
     &:disabled {
      background: ${theme.baseColors.surface[50]};
      color: ${theme.baseColors.greyscale[50]};
      
      svg {
        path {
          fill: ${theme.baseColors.greyscale[50]};
        }
      }
     }
  `};
`

export const disabledStyles = {
  secondary: {
    textTransform: 'none !important',
    border: 'none !important',
    background: `${theme.baseColors.surface[50]} !important`,
    color: `${theme.baseColors.greyscale[50]} !important`,
    cursor: 'default',
    svg: { path: { fill: `${theme.baseColors.greyscale[50]} !important` } }
  }
}
