import theme from 'theme'

const OverPerformIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
    <path
      d="M4.90109 1.01523C5.31886 0.311628 6.33739 0.311628 6.75516 1.01523L11.058 8.26208C11.4847 8.98075 10.9667 9.89062 10.1309 9.89062H1.52531C0.689507 9.89062 0.171569 8.98074 0.598278 8.26208L4.90109 1.01523Z"
      fill={theme.colors.textPrimary}
      stroke="white"
      strokeWidth="0.71875"
    />
  </svg>
)

export default OverPerformIcon
