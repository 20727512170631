import { Dispatch, SetStateAction, useCallback, useState, useLayoutEffect, forwardRef, Fragment } from 'react'
import { Box, Typography, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import dayjs from 'dayjs'
import theme from 'theme'

/* Utils */
import axios from '../../../../../lib/axios'
import { Pagination } from '../../useCoverageAnalyzerData'
import {
  AcaCoverageFilter,
  AcaLastSeen,
  AcaOperatingSystem,
  AnalysisDataTable,
  IntegrationInstance,
  LastSeenWithin,
  OperatingSystem,
  TableHeaders
} from '../../../../../models'
import { camelToSnake } from 'lib/utils'

/* Components */
import { TableContainerW, TableWrapper } from './DataTable.styles'
import TableColumnArrow from '../../../../components/svg/coverage-analyzer/TableColumnArrow'
import { IconTooltip } from 'ui/pages/security-stack-map/components/IconTooltip'
import LoadingRows from '../LoadingRows'

const getIntegrationLogo = (name: string) => {
  if (name) {
    return `/icons/circle/${name}_icon.svg`
  }

  return '/icons/circle/crowdstrike_falcon_icon.svg'
}

type Ref = HTMLDivElement | null

interface Props {
  columns: TableHeaders[]
  visibleColumns: TableHeaders[]
  setColumns: Dispatch<SetStateAction<TableHeaders[]>>
  setOpenRowDrawer: Dispatch<SetStateAction<boolean>>
  setActiveRowItem: Dispatch<SetStateAction<AnalysisDataTable | null>>
  firstInstanceName: string
  secondInstanceName: string
  firstInstance: IntegrationInstance | undefined
  secondInstance: IntegrationInstance | undefined
  pagination: Pagination
  setPagination: Dispatch<SetStateAction<Pagination>>
  selectedSeenWithin: LastSeenWithin
  selectedOperatingSystem: OperatingSystem
  searchTerm: string
  handleSort: ({
    column,
    direction,
    integrationIds
  }: {
    column: string
    direction: string
    integrationIds: string[]
  }) => Promise<void>
  rows: AnalysisDataTable[]
  setRows: Dispatch<SetStateAction<AnalysisDataTable[]>>
  fetchTotalAssets: ({
    integrationIds,
    lastSeenWithin
  }: {
    integrationIds: string[]
    lastSeenWithin: AcaLastSeen
  }) => Promise<void>
  setLoadingAnalysisData: Dispatch<SetStateAction<boolean>>
  firstSourceInField: string
  secondSourceInField: string
  setFirstSource: Dispatch<SetStateAction<string>>
  setSecondSource: Dispatch<SetStateAction<string>>
  selectedInstances: IntegrationInstance[]
  search: string
  tableFilters: boolean[]
}

const DataTable = forwardRef<Ref, Props>(
  (
    {
      columns,
      visibleColumns,
      setColumns,
      setOpenRowDrawer,
      setActiveRowItem,
      firstInstanceName,
      secondInstanceName,
      firstInstance,
      secondInstance,
      pagination,
      selectedSeenWithin,
      selectedOperatingSystem,
      searchTerm,
      setPagination,
      handleSort,
      rows,
      setRows,
      fetchTotalAssets,
      setLoadingAnalysisData,
      firstSourceInField,
      secondSourceInField,
      setFirstSource,
      setSecondSource,
      selectedInstances,
      search,
      tableFilters
    },
    ref
  ) => {
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(!!pagination.next_page)
    const [distanceBottom, setDistanceBottom] = useState(0)

    const canShow = (key: string) => {
      const findItem = columns.find((column) => column.dataKey === key)
      return findItem?.show
    }

    const openDrawer = (item: AnalysisDataTable) => {
      setOpenRowDrawer(true)
      setActiveRowItem(item)
    }

    const sortData = async (index: number) => {
      const firstSelectedId = selectedInstances[0].id
      const secondSelectedId = selectedInstances[1].id
      if (firstSelectedId !== firstSourceInField) {
        setFirstSource(firstSelectedId)
      }
      if (secondSelectedId !== secondSourceInField) {
        setSecondSource(secondSelectedId)
      }

      const temp = [...columns.map((c) => ({ ...c, sort: false }))]

      const key = temp[index].dataKey
      if (key === 'matchedBy') return

      temp[index].sortType = temp[index].sortType === 'asc' ? 'desc' : 'asc'
      temp[index].sort = true
      const tempName = camelToSnake(temp[index].dataKey)
      const column =
        tempName === 'original_serial_number' ? 'serial_number' : tempName === 'last_address' ? 'last_seen' : tempName
      const direction = temp[index].sortType.toUpperCase()

      setColumns(temp)

      await handleSort({ column, direction, integrationIds: selectedInstances.map((n) => n.id) })
    }

    const loadMore = useCallback(async () => {
      const loadItems = async () => {
        try {
          const sort = columns.find((c) => c.sort)
          const payload: any = {
            operating_system: selectedOperatingSystem.toLowerCase() as AcaOperatingSystem,
            integration_ids: [firstInstance?.id || '', secondInstance?.id || ''],
            last_seen: selectedSeenWithin.toLowerCase() as AcaLastSeen,
            page_number: pagination.current_page + 1,
            sort_by: sort ? camelToSnake(sort.dataKey) : 'last_seen',
            search_term: searchTerm
          }
          if (sort) {
            payload.sort_direction = sort.sortType.toUpperCase()
          }

          const coverageFilterIndex = tableFilters.findIndex((c) => c)

          if (coverageFilterIndex > -1) {
            payload.coverage =
              coverageFilterIndex === 0
                ? AcaCoverageFilter.FirstNotSecond
                : coverageFilterIndex === 1
                ? AcaCoverageFilter.SecondNotFirst
                : coverageFilterIndex === 2
                ? AcaCoverageFilter.Intersection
                : AcaCoverageFilter.SymmetricDifference
          } else {
            delete payload.coverage
          }

          try {
            await fetchTotalAssets({
              integrationIds: [firstInstance?.id || '', secondInstance?.id || ''],
              lastSeenWithin: selectedSeenWithin.toLowerCase() as AcaLastSeen
            })
          } catch (err) {
            console.error(err)
            setLoadingAnalysisData(false)
          }

          const res = await axios.post('/api/v3/aca/comparison', payload)

          if (res.status.toString().includes('20')) {
            const d = res.data.data.map((n: any) => {
              if (n.covered_by === 'both') {
                const firstSourceData = n[firstInstanceName]
                const secondSourceData = n[secondInstanceName]
                const sameHostName = firstSourceData?.host_name === secondSourceData?.host_name
                const sameMacAddress = firstSourceData?.mac_address === secondSourceData?.mac_address
                const sameOperatingSystem = firstSourceData?.operating_system === secondSourceData?.operating_system
                const sameSerialNumber = firstSourceData?.serial_number === secondSourceData?.serial_number
                const sameAssetId = firstSourceData?.asset_id === secondSourceData?.asset_id

                return {
                  coverage: n.covered_by,
                  matchedBy: n.matched_by,
                  assetId: firstSourceData.asset_id,
                  hostName: firstSourceData.host_name,
                  serialNumber: firstSourceData.serial_number,
                  macAddress: firstSourceData.mac_address,
                  lastSeen: firstSourceData.last_seen,
                  operatingSystem: firstSourceData.operating_system,
                  sameHostName,
                  secondHostName: secondSourceData.host_name,
                  sameAssetId,
                  secondAssetId: secondSourceData.asset_id,
                  sameMacAddress,
                  secondMacAddress: secondSourceData.mac_address,
                  sameOperatingSystem,
                  secondOperatingSystem: secondSourceData.operating_system,
                  sameSerialNumber,
                  secondSerialNumber: secondSourceData.serial_number
                }
              }

              return {
                coverage: n.covered_by,
                matchedBy: n.matched_by,
                assetId: n.asset_id,
                hostName: n.host_name,
                serialNumber: n.serial_number,
                macAddress: n.mac_address,
                lastSeen: n.last_seen,
                operatingSystem: n.operating_system
              }
            })

            setRows([...rows, ...d])
            setHasMore(!!res.data.pagination.next_page)
            setPagination(res.data.pagination)
          }
        } catch (err) {
          console.error(err)
        } finally {
          setLoading(false)
        }
      }

      setLoading(true)
      await loadItems()
    }, [rows])

    const handleScroll = useCallback(async () => {
      if (!ref || !(ref as any).current) return

      const bottom = (ref as any).current.scrollHeight - (ref as any).current.clientHeight

      if (!distanceBottom) {
        setDistanceBottom(Math.round(bottom + 10))
      }
      if ((ref as any).current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
        await loadMore()
      }
    }, [loading, loadMore, distanceBottom, hasMore])

    useLayoutEffect(() => {
      const tableContainer = (ref as any).current
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll)

        return () => tableContainer.removeEventListener('scroll', handleScroll)
      }
    }, [handleScroll])

    const renderSingleInstance = (row: AnalysisDataTable) => {
      if (!firstInstance || !secondInstance) return null

      const instanceIdPart = row.coverage.slice(-8)
      const result = firstInstance.id.includes(instanceIdPart) ? firstInstance : secondInstance

      return (
        <IconTooltip
          PopperProps={{
            sx: {
              marginTop: `-${theme.spacing.sm}px !important`
            }
          }}
          title={
            <>
              <Box className="tooltip-wrapper">
                {result && (
                  <>
                    {result.integration_name && (
                      <Typography className="tooltip-text">{result.integration_name}</Typography>
                    )}
                    <Typography className={result.integration_name ? 'tooltip-subtext' : 'tooltip-text'}>
                      {result.name}
                    </Typography>
                  </>
                )}
              </Box>
            </>
          }
          placement="bottom-start"
        >
          <img src={getIntegrationLogo(row.coverage.slice(0, -9))} alt="" width="24px" height="24px" />
        </IconTooltip>
      )
    }

    const highlightMatch = (text: string) => {
      if (!search) return text

      const regex = new RegExp(`(${search})`, 'gi')
      return text.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{ color: theme.colors.textPrimary }}>
            {part}
          </span>
        ) : (
          <Fragment key={index}>{part}</Fragment>
        )
      )
    }

    return (
      <Box>
        <TableContainerW ref={ref}>
          <TableWrapper sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                {columns
                  .filter((item) => item.show)
                  .map((column, index) => {
                    return (
                      <TableCell
                        key={index}
                        className={column.sort ? 'no-padding' : ''}
                        onClick={() => sortData(index)}
                      >
                        <Box className="row-wrapper header-cell-inner">
                          <Typography className="row-header-text">{column.name}</Typography>

                          {column.sort && (
                            <Box
                              className={`${column.sortType === 'desc' ? '' : 'desc-icon'} icon-wrapper`}
                              onClick={() => sortData(index)}
                            >
                              <TableColumnArrow />
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    )
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                if (loading && index > rows.length - 5) {
                  return <LoadingRows height="50px !important" columns={visibleColumns.length} />
                }
                return (
                  <TableRow key={index} onClick={() => openDrawer(row)}>
                    {canShow('coverage') && (
                      <TableCell>
                        {row.coverage === 'both' ? (
                          <Box className="coverage-wrapper">
                            <IconTooltip
                              PopperProps={{
                                sx: {
                                  marginTop: `-${theme.spacing.sm}px !important`
                                }
                              }}
                              title={
                                <>
                                  <Box className="tooltip-wrapper">
                                    {firstInstance?.integration_name && (
                                      <Typography className="tooltip-text">
                                        {firstInstance?.integration_name}
                                      </Typography>
                                    )}
                                    <Typography
                                      className={firstInstance?.integration_name ? 'tooltip-subtext' : 'tooltip-text'}
                                    >
                                      {firstInstance?.name}
                                    </Typography>
                                  </Box>
                                </>
                              }
                              placement="bottom-start"
                            >
                              <img
                                src={getIntegrationLogo(firstInstance?.integration_name || '')}
                                alt=""
                                width="24px"
                                height="24px"
                              />
                            </IconTooltip>
                            <IconTooltip
                              PopperProps={{
                                sx: {
                                  marginTop: `-${theme.spacing.sm}px !important`
                                }
                              }}
                              title={
                                <>
                                  <Box className="tooltip-wrapper">
                                    {secondInstance?.integration_name && (
                                      <Typography className="tooltip-text">
                                        {secondInstance?.integration_name}
                                      </Typography>
                                    )}
                                    <Typography
                                      className={secondInstance?.integration_name ? 'tooltip-subtext' : 'tooltip-text'}
                                    >
                                      {secondInstance?.name}
                                    </Typography>
                                  </Box>
                                </>
                              }
                              placement="bottom-start"
                            >
                              <img
                                src={getIntegrationLogo(secondInstance?.integration_name || '')}
                                alt=""
                                width="24px"
                                height="24px"
                              />
                            </IconTooltip>
                          </Box>
                        ) : (
                          renderSingleInstance(row)
                        )}
                      </TableCell>
                    )}

                    {canShow('hostName') && (
                      <TableCell>
                        <Box className="cell-wrapper">
                          <Typography className={`ellipsis ${!row.hostName && !row.secondHostName && 'empty-cell'}`}>
                            {highlightMatch(row.hostName || row.secondHostName || '━━')}
                          </Typography>
                          {row.hostName && row.secondHostName && (
                            <Typography className="count" onClick={() => openDrawer(row)}>
                              +1
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    )}

                    {canShow('serialNumber') && (
                      <TableCell>
                        <Box className="cell-wrapper">
                          <Typography
                            className={`ellipsis ${!row.serialNumber && !row.secondSerialNumber && 'empty-cell'}`}
                          >
                            {highlightMatch(row.serialNumber || row.secondSerialNumber || '━━')}
                          </Typography>
                          {row.serialNumber && row.secondSerialNumber && (
                            <Typography className="count" onClick={() => openDrawer(row)}>
                              +1
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    )}

                    {canShow('macAddress') && (
                      <TableCell>
                        <Box className="cell-wrapper">
                          <Typography
                            className={`ellipsis ${!row.macAddress && !row.secondMacAddress && 'empty-cell'}`}
                          >
                            {highlightMatch(row.macAddress || row.secondMacAddress || '━━')}
                          </Typography>
                          {row.macAddress && row.secondMacAddress && (
                            <Typography className="count" onClick={() => openDrawer(row)}>
                              +1
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    )}

                    {canShow('lastSeen') && (
                      <TableCell>
                        <Box className="cell-wrapper">
                          <Typography
                            className={`ellipsis ${
                              ((!row.lastSeen && !row.secondLastSeen) || row.lastSeen.includes('0001-01-01')) &&
                              'empty-cell'
                            }`}
                          >
                            {row.lastSeen.includes('0001-01-01')
                              ? '━━'
                              : row.lastSeen
                              ? dayjs(row.lastSeen || '').format('YYYY-MM-DD HH:MM:ss')
                              : row.secondLastSeen
                              ? dayjs(row.secondLastSeen || '').format('YYYY-MM-DD HH:MM:ss')
                              : '━━'}
                          </Typography>
                          {row.lastSeen && row.secondLastSeen && (
                            <Typography className="count" onClick={() => openDrawer(row)}>
                              +1
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    )}

                    {canShow('operatingSystem') && (
                      <TableCell>
                        <Box className="cell-wrapper">
                          <Typography
                            className={`ellipsis ${!row.operatingSystem && !row.secondOperatingSystem && 'empty-cell'}`}
                          >
                            {row.operatingSystem || row.secondOperatingSystem || '━━'}
                          </Typography>
                          {row.operatingSystem && row.secondOperatingSystem && (
                            <Typography className="count" onClick={() => openDrawer(row)}>
                              +1
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    )}

                    {canShow('matchedBy') && (
                      <TableCell>
                        <Box className="cell-wrapper">
                          <Typography className="ellipsis">{row.matchedBy || ''}</Typography>
                          {row.matchedBy && (
                            <Typography className="count" onClick={() => openDrawer(row)}>
                              +1
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    )}

                    {canShow('assetId') && (
                      <TableCell>
                        <Box className="cell-wrapper">
                          <Typography className="ellipsis">{row.assetId || ''}</Typography>
                          {row.assetId && row.secondAssetId && (
                            <Typography className="count" onClick={() => openDrawer(row)}>
                              +1
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </TableWrapper>
        </TableContainerW>
      </Box>
    )
  }
)

export default DataTable
