import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const PizzaChartWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 203px;

  img {
    position: absolute;
    width: 434px;
    height: 418px;
    top: 147px;
    border-radius: ${theme.attributes.borderRadius.circle};
  }

  svg#chart {
    margin-bottom: 17px;
    padding-left: ${theme.spacing.small}px;
    overflow: visible;
  }
`

export const SetupWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  position: absolute;
  z-index: 1;

  .setup-title {
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.huge};
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 52px;
    max-width: 334px;
    margin-bottom: 21px;
  }
`
