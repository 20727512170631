import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
`

export const Wrapper = styled(Box)`
  padding-left: ${theme.spacing.medium}px;
  padding-right: ${theme.spacing.medium}px;
`
