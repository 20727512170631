import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)<{ isactive: string }>`
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${({ isactive }) =>
    isactive === 'true' ? theme.colors.surfaceItemBackground : theme.colors.surfaceItemBackgroundLow};
  width: 100%;
  display: flex;
  padding: ${theme.spacing.small}px;
  align-items: center;
  gap: ${theme.spacing.small}px;
  min-height: 105px;

  .MuiCheckbox-root {
    color: ${theme.baseColors.greyscale[80]};
    padding: 0;

    &.Mui-checked {
      color: ${theme.colors.textPrimary};
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.small}px;

    .inner-header,
    .inner-footer {
      display: flex;
      align-items: center;
    }

    .inner-header {
      gap: ${theme.spacing.small}px;

      .title {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.header};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: normal;
      }
    }

    .inner-footer {
      gap: ${theme.spacing.medium}px;

      .category-chip-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing.xSmall}px;
        position: relative;

        .info-icon {
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;

          &:hover {
            cursor: pointer;
          }

          path {
            fill: ${theme.baseColors.secondary[80]};
          }
        }
      }
    }
  }
`
