import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs, Switch } from '@mui/material'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
`

export const AccountManagementHeaderContainer = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 100;

  .breadcrumbs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 100%;
    padding: ${theme.spacing.small}px 0 0 ${theme.spacing.medium}px;

    .header-actions {
      display: flex;
      align-items: center;

      .favorite-icon {
        margin-right: 34px;

        .MuiButtonBase-root {
          &:hover,
          &:focus,
          &:active {
            background: rgba(202, 196, 208, 0.08);
          }
        }
      }
    }
  }

  .title-wrapper {
    padding-left: ${theme.spacing.medium}px;

    &.title-wrapper {
      padding: 0 0 ${theme.spacing.medium}px ${theme.spacing.medium}px;

      .header-title {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.epic};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 40px;
        text-transform: capitalize;
        margin-top: ${theme.spacing.medium}px;
        margin-bottom: ${theme.spacing.xSmall}px;
      }

      .header-subtitle {
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 20px;
        letter-spacing: 0.25px;
        color: ${theme.colors.neutral};
      }
    }
  }
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: ${theme.colors.textPrimary};
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`

export const EditFixedPanel = styled(Box)`
  position: fixed;
  right: 24px;
  top: 96px;

  .panel-block {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;

    .text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }
`

export const UnlockIconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.large};
  background: ${theme.colors.neutral};
  width: 40px;
  height: 40px;
`

export const ContentWrapper = styled(Box)`
  margin: ${theme.spacing.medium}px auto;
`

export const MaterialUISwitch = styled(Switch)(() => ({
  width: 79,
  height: 40,
  margin: 0,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(3px)',
    '&.Mui-checked': {
      color: theme.colors.white,
      transform: 'translateX(41px)',
      '& .MuiSwitch-thumb:before': {
        marginLeft: 1,
        backgroundImage: `url('/unlock.svg')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.colors.textPrimary
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.colors.white,
    width: 33,
    height: 33,
    marginTop: 2,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('/lock.svg')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.colors.controlBorder,
    borderRadius: 36,
    height: '40px',
    minWidth: '79px'
  }
}))
