import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)<{ isdisabled?: string }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.small}px;
  cursor: pointer;
  width: 100%;
  min-width: 881px;
  height: 80px;
  background: ${theme.colors.backgroundMedium};
  border-radius: ${theme.attributes.borderRadius.tertiary};

  ${({ isdisabled }) =>
    isdisabled &&
    isdisabled === 'true' &&
    `
    cursor: default;
  `};

  .title-wrapper {
    display: flex;
    flex-flow: column wrap;
  }
`

export const Title = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  color: ${theme.colors.neutral};
  font-size: ${theme.typography.fontSize.xxLarge};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.normal};
  line-height: 28px;
`

export const Description = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  color: ${theme.colors.neutral};
  font-size: ${theme.typography.fontSize.xLarge};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.normal};
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: ${theme.spacing.half}px;
`
