import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const SSMCardContainer = styled(Box)<{ height: number; isloading?: string }>`
  padding: ${theme.spacing.small}px;
  border: 1px solid ${theme.colors.borderLow};
  background: ${theme.colors.primaryDark};
  position: relative;
  display: flex;
  min-height: 140px;
  height: ${({ height }) => `${height}px`};

  .edit-icon-button {
    position: absolute;
    top: 0;
    right: 0.4px;
    border-radius: 0 0 0 ${theme.attributes.borderRadius.secondary};
    background: ${theme.colors.textPrimary};
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    padding: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    .edit-icon-button {
      opacity: 1;
    }
  }

  &.with-items {
    background: ${theme.colors.surfaceItemBackground};
  }

  &:hover {
    border: 1px solid ${theme.colors.controlBorder};
    background: ${theme.baseColors.surface[50]};
  }

  ${({ isloading }) =>
    isloading &&
    isloading === 'true' &&
    `
      border: 1px solid ${theme.colors.surfaceContainerBackground};
      background:${theme.colors.surfaceItemBackground};
  `};
`

export const CardItem = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.half}px;
  width: 100%;

  .MuiTypography-root {
    overflow: hidden;
    color: ${theme.colors.white};
    text-align: center;
    text-overflow: ellipsis;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    white-space: nowrap;
  }

  .missing-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #c3c3c3;
    border-radius: ${theme.attributes.borderRadius.circle};
    color: ${theme.baseColors.greyscale[0]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    text-align: center;
    text-transform: capitalize;
  }
`

export const IntegrationLogo = styled(Box)<{ logo: string }>`
  border: 1px solid #342f32;
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  width: 32px;
  height: 32px;
  border-radius: ${theme.spacing.medium}px;
  background: ${({ logo }) => `url("${logo}") no-repeat center`};
  background-size: cover;
`

export const IntegrationItemWrapper = styled(Box)<{ numofitems: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: ${({ numofitems }) => (numofitems === 1 ? '100%' : numofitems === 2 ? '50%' : '33.33%')};
`

export const NoIntegrationLogo = styled(Box)`
  border: 1px solid #342f32;
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  width: 41px;
  height: 41px;
  border-radius: ${theme.attributes.borderRadius.circle};
  background: ${theme.baseColors.greyscale[80]};
  background-size: cover;
  margin-left: ${theme.spacing.half}px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1280px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1600px) and (max-height: 1079px) {
    width: 22px;
    height: 22px;
  }

  .no-integration-logo-letter {
    color: #342f32;
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall}px;
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`

export const SSMCardInner = styled(Box)<{ itemslength?: number; isediting?: string; isCurrentEdit?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-basis: 100%;
  row-gap: ${theme.spacing.small}px;

  ${({ isCurrentEdit }) =>
    isCurrentEdit &&
    `
     background: ${theme.colors.primaryLight};
    `};

  ${({ isediting }) =>
    isediting &&
    isediting === 'true' &&
    `
      &:hover {
        border-radius: 2px;
        border: 1px solid #DEBDD9;
        background: ${theme.colors.primaryLight};
        box-shadow: ${theme.attributes.boxShadow.chipCircle};
        cursor: pointer;
      }
    `};

  ${({ itemslength }) =>
    itemslength &&
    itemslength > 3 &&
    `
    &:hover {
      cursor: default;
    }
  `};

  .text {
    color: ${theme.colors.neutral};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`

export const SSMCardSkeletonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing.small}px;
  width: 100%;
`

export const SSMCardSkeleton = styled(Box)`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.primaryLight};

    &:after {
      background: ${theme.colors.ssmCardSkeleton};
    }
  }
`
