import theme from 'theme'

const EditIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.295 2.6925L15.3075 3.705C15.9 4.29 15.9 5.2425 15.3075 5.8275L5.385 15.75H2.25V12.615L10.05 4.8075L12.1725 2.6925C12.7575 2.1075 13.71 2.1075 14.295 2.6925ZM3.75 14.25L4.8075 14.295L12.1725 6.9225L11.115 5.865L3.75 13.23V14.25Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default EditIcon
