import { FC } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import theme from 'theme'

interface Props {
  open: boolean
  handleClose: () => void
}

const CoverageLoading: FC<Props> = ({ open, handleClose }) => (
  <Backdrop
    sx={{
      color: theme.colors.white,
      zIndex: 10000000 + 1,
      width: 'calc(100% - 64px)',
      marginLeft: '64px',

      '.MuiCircularProgress-root': {
        boxShadow: `inset 0 0 0 ${(12 / 44) * 12}px ${theme.colors.primaryDark}`,
        borderRadius: theme.attributes.borderRadius.circle,

        svg: {
          circle: {
            stroke: theme.colors.textPrimary
          }
        }
      }
    }}
    open={open}
    onClick={handleClose}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
)

export default CoverageLoading
