import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  display: flex;
  padding: ${theme.spacing.half}px ${theme.spacing.small}px;
  align-items: center;
  gap: ${theme.spacing.small}px;
  align-self: stretch;
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.backgroundMedium};

  .text {
    color: ${theme.colors.white};
    text-align: center;
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: normal;
  }

  .link-text {
    color: ${theme.colors.textPrimary};
    text-align: center;
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: normal;
  }
`
