import { Dialog as MuiDialog } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 640px;
    min-width: 640px;
    border-radius: ${theme.attributes.borderRadius.quaternary};
  }

  .MuiDialogTitle-root {
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
    height: 72px;
    justify-content: space-between;
    border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
    background: ${theme.colors.surfaceItemBackground};

    .header {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;

      .header-title {
        color: ${theme.colors.textPrimary};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.header};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: normal;
      }
    }
  }

  .MuiDialogContent-root {
    padding: ${theme.spacing.medium}px !important;
    min-height: 336px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.small}px;
    background: ${theme.colors.surfaceContainerBackground};

    .content-title {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.header};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;

      span {
        color: ${theme.colors.textPrimary};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.header};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: normal;
      }
    }
  }

  .MuiDialogActions-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    border-radius: 0 0 ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary};
    background: ${theme.colors.surfaceItemBackground};
    height: 72px;
    padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
    gap: ${theme.spacing.xSmall}px;

    .MuiButtonBase-root {
      margin-left: 0;
    }
  }
`

export const List = styled('ul')`
  list-style-type: none;
  padding-left: 0;
  color: ${theme.colors.white};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.xLarge};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.normal};
  line-height: normal;
  margin: 0;
  padding-inline-start: 0;

  li {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    padding: ${theme.spacing.half}px ${theme.spacing.xSmall}px ${theme.spacing.half}px 0;
  }
`
