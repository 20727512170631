import React, { FC, useMemo } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import theme from 'theme'

/* Components */
import { Container } from './jiraHeader.styles'
import ThemeButton from '../../../../components/buttons/primary/ThemeButton'
import SaveSvg from '../../../../components/svg/labels/SaveSvg'
import { TestStatus } from './SetupJiraInstance'
import BetaIntegrationMarker from '../components/BetaIntegrationMarker'
import { PermissionTooltip } from '../../../../components/tooltip'
import { disabledStyles } from '../../../../components/buttons'

interface Props {
  headerTitle: string
  handleSave: () => void
  handleDiscard: () => void
  handleBack?: () => void
  testStatus: any
  isSubmitting: any
  isValid: any
  loading: any
  schema: any
  allowSave: boolean
  discardDisabled: boolean
  disabledP2: boolean
  tabValue: number
  userRole: string
}

const JiraHeader: FC<Props> = ({
  headerTitle,
  handleSave,
  handleDiscard,
  handleBack,
  testStatus,
  isSubmitting,
  isValid,
  loading,
  schema,
  allowSave,
  discardDisabled,
  disabledP2,
  tabValue,
  userRole
}) => {
  const disabled = useMemo(() => {
    if (tabValue === 0) {
      if (testStatus !== TestStatus.successful) return true
      if (allowSave) return loading || !isValid || !schema || isSubmitting
      if (!allowSave) return true

      return loading || !isValid || !schema || isSubmitting
    }

    return disabledP2
  }, [tabValue, disabledP2, testStatus, allowSave, loading, isValid, schema, isSubmitting])

  return (
    <Container>
      <Box className="wrapper" sx={{ backgroundColor: `${theme.colors.primaryDark} !important` }}>
        {typeof handleBack !== 'undefined' && (
          <Box className="back-arrow-wrapper">
            <IconButton onClick={handleBack}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 10.75H6.7875L13.775 3.7625L12 2L2 12L12 22L13.7625 20.2375L6.7875 13.25H22V10.75Z"
                  fill={theme.colors.textPrimary}
                />
              </svg>
            </IconButton>
          </Box>
        )}

        <Box className="header-content">
          <Typography className="header-title">{headerTitle}</Typography>
          <BetaIntegrationMarker expanded={false} sx={{ marginTop: '2px !important' }} />
        </Box>

        <Box className="header-actions">
          {['Viewer', 'Demo'].includes(userRole) ? (
            <PermissionTooltip
              PopperProps={{
                sx: {
                  marginTop: `-${theme.spacing.sm}px !important`
                }
              }}
              title={
                <>
                  <Box className="tooltip-wrapper-permissions">
                    <Typography className="tooltip-text">Insufficient permissions</Typography>
                  </Box>
                </>
              }
            >
              <ThemeButton themevariant="primary" sx={disabledStyles.secondary}>
                <>
                  <SaveSvg />
                  Save
                </>
              </ThemeButton>
            </PermissionTooltip>
          ) : (
            <ThemeButton themevariant="primary" onClick={handleSave} disabled={disabled}>
              <>
                <SaveSvg />
                Save
              </>
            </ThemeButton>
          )}
          {['Viewer', 'Demo'].includes(userRole) ? (
            <PermissionTooltip
              PopperProps={{
                sx: {
                  marginTop: `-${theme.spacing.sm}px !important`
                }
              }}
              title={
                <>
                  <Box className="tooltip-wrapper-permissions">
                    <Typography className="tooltip-text">Insufficient permissions</Typography>
                  </Box>
                </>
              }
            >
              <ThemeButton themevariant="secondary" sx={disabledStyles.secondary}>
                <>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                      fill={theme.colors.textPrimary}
                    />
                  </svg>
                  Discard
                </>
              </ThemeButton>
            </PermissionTooltip>
          ) : (
            <ThemeButton themevariant="secondary" onClick={handleDiscard} disabled={discardDisabled}>
              <>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                    fill={theme.colors.textPrimary}
                  />
                </svg>
                Discard
              </>
            </ThemeButton>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default JiraHeader
