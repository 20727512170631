import * as d3 from 'd3'
import { DataStatus } from '../../../../../../../models'
import theme from 'theme'

interface Props {
  svg:
    | d3.Selection<SVGSVGElement | null, unknown, null, undefined>
    | d3.Selection<SVGGElement, unknown, null, undefined>
  status: DataStatus
  width: number
  chartHeightStart: number
  recentPerformanceYPositionValue: number
}

/* Recent Performance Line */
export const appendRecentPerformanceLine = ({
  svg,
  status,
  width,
  chartHeightStart,
  recentPerformanceYPositionValue
}: Props) => {
  const existingRecentPerformanceLine = svg.select('.recent-performance-line-left')
  if (!existingRecentPerformanceLine.empty()) existingRecentPerformanceLine.remove()

  if (status !== DataStatus.Ready) return

  svg
    .append('line')
    .attr('class', 'recent-performance-line-left')
    .attr('x1', 0)
    .attr('x2', width)
    .attr('y1', recentPerformanceYPositionValue || chartHeightStart)
    .attr('y2', recentPerformanceYPositionValue || chartHeightStart)
    .attr('stroke', theme.baseColors.info[20])
    .attr('stroke-width', 2)
    .attr('stroke-dasharray', '4 4')
}
