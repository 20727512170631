import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from 'theme'

export const ResetWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: ${theme.colors.backgroundDark};

  .reset-button {
    border-radius: ${theme.attributes.borderRadius.large};
    cursor: pointer;
    color: ${theme.colors.textPrimary};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: none;
    width: 100% !important;
    height: 40px;

    &:hover {
      background: rgba(234, 221, 255, 0.08);
    }

    &.Mui-disabled {
      background: ${theme.baseColors.surface[50]} !important;
      opacity: 1;
      color: ${theme.baseColors.greyscale[50]};
      text-align: center;
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;
    }
  }
`
