import { FC, useCallback } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormikErrors } from 'formik'
import styled from '@emotion/styled/macro'
import theme from 'theme'

/* Components */
import { SmallArrow, LargeArrow } from '../../../svg'
import UncontrolledSelectField from '../../../form-components/UncontrolledSelectField'

/* Utils */
import { FormValues } from './schemaMapping.constants'
import { ProjectMeta } from '../../../../../models'
import { capitalizeString, sanitizeString } from '../../../../../lib/utils'

const FieldWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.medium}px;
`

const mappableFieldIds = ['assignee', 'duedate', 'priority']

interface Props {
  metadata: ProjectMeta[]
  values: FormValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<any>> | Promise<void>
}

const SchemaMapping: FC<Props> = ({ metadata, setFieldValue, values }) => {
  const getSanitizedString = (str: string) => {
    const string = capitalizeString(str)

    return sanitizeString(string, '_', ' ')
  }

  const getOptions = useCallback(
    (projectIndex: number, issueTypeIndex: number, fieldIndex: number) => {
      return metadata[projectIndex].issueTypes[issueTypeIndex].fields[fieldIndex].customFields &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        metadata[projectIndex].issueTypes[issueTypeIndex].fields[fieldIndex].customFields!.length > 0
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          metadata[projectIndex].issueTypes[issueTypeIndex].fields[fieldIndex].customFields!.map(
            (customField) => customField.name
          )
        : []
    },
    [metadata]
  )

  if (!metadata || metadata.length < 1 || values.projects.length < 1) {
    return <div>loading..</div>
  }
  return (
    <div style={{ maxWidth: '560px' }}>
      {metadata.map((project, projectIndex) => (
        <Accordion
          key={project.id}
          sx={{
            background: theme.colors.backgroundDark,
            color: theme.colors.white,

            '&.Mui-expanded': {
              background: '#6C6596'
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.colors.white }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: theme.typography.fontSize.xLarge,
                lineHeight: '24px',
                fontWeight: 700
              }}
            >
              {project.name}
            </Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ pl: '24px', pr: '2px' }}>
            <Typography
              variant="body1"
              pb="16px"
              sx={{ fontSize: theme.typography.fontSize.large, lineHeight: '20px' }}
            >
              Issue Types:
            </Typography>
            {project.issueTypes.map((issueType, issueTypeIndex) => (
              <Accordion
                key={`${issueType.id}-${issueType.name}`}
                sx={{
                  background: theme.colors.backgroundDark,
                  color: theme.colors.white,

                  '&.Mui-expanded': {
                    background: theme.colors.primary
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: theme.colors.white }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    padding: '30px 24px'
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: theme.typography.fontSize.large, lineHeight: '20px', fontWeight: 700 }}
                  >
                    {issueType.name}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',

                      '& .MuiTypography-root': {
                        fontSize: theme.typography.fontSize.large,
                        lineHeight: '20px',
                        fontWeight: theme.typography.fontWeight.normal,
                        color: theme.colors.white
                      }
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: theme.typography.fontSize.medium,
                        lineHeight: '16px',
                        fontWeight: 700,
                        marginRight: `${theme.spacing.medium}px`
                      }}
                    >
                      Jira Field
                    </Typography>
                    <LargeArrow />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: theme.typography.fontSize.medium,
                        lineHeight: '16px',
                        fontWeight: 700,
                        marginLeft: `${theme.spacing.xSmall}px`
                      }}
                    >
                      Onyxia Field
                    </Typography>
                  </Box>

                  {/* ------------------------- Mapped Fields ------------------------- */}
                  {issueType.fields.map((field, fieldIndex) => {
                    if (mappableFieldIds.includes(field.id)) {
                      return (
                        <Box key={field.id} sx={{ mt: '22px', display: 'flex', flexDirection: 'column' }}>
                          <FieldWrapper>
                            <UncontrolledSelectField
                              placeholder={getSanitizedString(field.name)}
                              name={`projects[${projectIndex}].issueTypes[${issueTypeIndex}].fields[${fieldIndex}].sourceField`}
                              options={getOptions(projectIndex, issueTypeIndex, fieldIndex)}
                              setFieldValue={setFieldValue}
                              value={getSanitizedString(
                                values.projects[projectIndex].issueTypes[issueTypeIndex].fields[fieldIndex].sourceField
                              )}
                            />
                            <SmallArrow />
                            <Typography
                              variant="body1"
                              ml="8px"
                              sx={{
                                fontSize: theme.typography.fontSize.large,
                                lineHeight: '20px',
                                fontWeight: theme.typography.fontWeight.normal
                              }}
                              textTransform="capitalize"
                            >
                              {sanitizeString(field.name, '_', ' ')}
                            </Typography>
                          </FieldWrapper>
                        </Box>
                      )
                    }

                    return null
                  })}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default SchemaMapping
