import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const HistoricalPerformanceChartContainer = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.small};
  z-index: 10;
  position: relative;

  .bordered-wrapper {
    position: absolute;
    height: 481px;
    width: 732px;
    top: -1px;
    right: 1px;
    z-index: 10;
    pointer-events: none;
    display: none;
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.controlBorder};
    box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};

    @media (min-width: 900px) {
      display: block;
    }

    @media (min-width: 1441px) {
      left: auto;
    }

    @media (min-width: 1510px) {
      left: auto;
      right: 0;
    }
  }

  #historical-performance-svg {
    border-radius: ${theme.attributes.borderRadius.small};
    z-index: 10;

    g.y-axis {
      .tick:first-of-type {
        transform: translate(13px, 448px) !important;
      }

      .tick:nth-child(4) {
        transform: translate(4px, 210px) !important;
      }

      .tick:last-of-type {
        transform: translate(0, -9px) !important;
      }
    }

    g.y-axis-perform {
      .tick:first-of-type {
        transform: translate(13px, 448px) !important;
      }

      .tick:nth-child(7) {
        transform: translate(0px, 150px) !important;
      }

      .tick:nth-child(4) {
        transform: translate(4px, 300px) !important;
      }

      .tick:last-of-type {
        transform: translate(0, -9px) !important;
      }
    }
  }

  .historical-performance-tooltip {
    display: none;
    position: absolute;
    opacity: 1;
    padding: ${theme.spacing.small}px;
    gap: ${theme.spacing.sm}px;
    border-radius: ${theme.attributes.borderRadius.secondary};
    border: 1px solid ${theme.colors.controlBorder};
    background: ${theme.colors.borderLow};
    box-shadow: ${theme.attributes.boxShadow.chipCircle};
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
    width: 200px;
    z-index: 9999;

    .tooltip-performance,
    .tooltip-units {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
    }

    .tooltip-performance {
      font-size: ${theme.typography.fontSize.xxxLarge};
      line-height: 32px;
      margin: 0 0 ${theme.spacing.half}px 0;
    }

    .tooltip-units {
      font-size: ${theme.typography.fontSize.medium};
      line-height: 16px;
      letter-spacing: 0.4px;
      margin: 0;
    }

    .severity-text {
      text-transform: capitalize;
    }
  }
`
