import { FC, Dispatch, SetStateAction } from 'react'

/* Utils */
import {
  IChartData,
  IFramework,
  IntegrationInstance
} from '../../../../../models'

/* Components */
import { HeroSectionWrapper } from '../../dashboard.styles'
import DashboardHeroEmpty from '../../components/DashboardHeroEmpty'
import DashboardHero from '../../components/DashboardHero'

interface Props {
  performanceScore: number
  cpisActivated: number
  chartLoading: boolean
  initialLoad: boolean
  chartData: IChartData[]
  noCategories: boolean
  configuredIntegrations: IntegrationInstance[]
  activeUsers: number
  availableCpis: string[]
  handleCategoryClick: (category: string) => void
  lastUpdatePerformance: string
  unreadInsightsCount: number
  insightsCountLoading: boolean
  insightsData: any
  setHoveredCard: Dispatch<SetStateAction<string>>
  customFramework: IFramework | null
}

const HeroSection: FC<Props> = ({
  performanceScore,
  cpisActivated,
  chartLoading,
  initialLoad,
  chartData,
  noCategories,
  configuredIntegrations,
  activeUsers,
  availableCpis,
  handleCategoryClick,
  lastUpdatePerformance,
  unreadInsightsCount,
  insightsCountLoading,
  insightsData,
  setHoveredCard,
  customFramework
}) => (
  <HeroSectionWrapper>
    {initialLoad && noCategories ? (
      <DashboardHeroEmpty
        cpisActivated={cpisActivated}
        performanceScore={performanceScore}
        configuredIntegrations={configuredIntegrations}
        activeUsers={activeUsers}
        availableCpis={availableCpis}
      />
    ) : (
      <DashboardHero
        performanceScore={performanceScore}
        cpisActivated={cpisActivated}
        chartLoading={chartLoading}
        initialLoad={initialLoad}
        chartData={chartData}
        noCategories={noCategories}
        configuredIntegrations={configuredIntegrations}
        activeUsers={activeUsers}
        availableCpis={availableCpis}
        handleCategoryClick={handleCategoryClick}
        lastUpdatePerformance={lastUpdatePerformance}
        unreadInsightsCount={unreadInsightsCount}
        insightsCountLoading={insightsCountLoading}
        insightsData={insightsData}
        setHoveredCard={setHoveredCard}
        customFramework={customFramework}
      />
    )}
  </HeroSectionWrapper>
)

export default HeroSection
