import theme from '../../../../theme'

const AuditAssignedRolesIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 12H10.5V13.5H5.25V12ZM5.25 9H12.75V10.5H5.25V9ZM5.25 6H12.75V7.5H5.25V6ZM14.25 3H11.115C10.8 2.13 9.975 1.5 9 1.5C8.025 1.5 7.2 2.13 6.885 3H3.75C3.645 3 3.5475 3.0075 3.45 3.03C3.1575 3.09 2.895 3.24 2.6925 3.4425C2.5575 3.5775 2.445 3.7425 2.37 3.9225C2.295 4.095 2.25 4.29 2.25 4.5V15C2.25 15.2025 2.295 15.405 2.37 15.585C2.445 15.765 2.5575 15.9225 2.6925 16.065C2.895 16.2675 3.1575 16.4175 3.45 16.4775C3.5475 16.4925 3.645 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V4.5C15.75 3.675 15.075 3 14.25 3ZM9 2.8125C9.3075 2.8125 9.5625 3.0675 9.5625 3.375C9.5625 3.6825 9.3075 3.9375 9 3.9375C8.6925 3.9375 8.4375 3.6825 8.4375 3.375C8.4375 3.0675 8.6925 2.8125 9 2.8125ZM14.25 15H3.75V4.5H14.25V15Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default AuditAssignedRolesIcon
