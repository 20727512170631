import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const CategoriesWrapper = styled(Box)`
  width: 100%;
  flex-basis: 50%;
  margin-right: 13.05px;
`

export const CategoriesContainer = styled(Box)`
  width: 100%;
  flex-basis: 50%;
  gap: ${theme.spacing.md}px;
  display: flex;
  flex-direction: column;
`

export const CategoriesSummary = styled(Box)`
  gap: ${theme.spacing.small}px;
  display: flex;
  flex-direction: column;

  .categories-summary-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }
  }
`

export const SummaryInner = styled(Box)`
  padding: ${theme.spacing.medium}px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.surfaceItemBackground};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 241px;
  gap: ${theme.spacing.md}px;

  .inner-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    .inner-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.header};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }

    .inner-content-actions {
      height: 100%;
      display: flex;
      align-items: flex-end;
      gap: ${theme.spacing.medium}px;
      flex-flow: row wrap;
      row-gap: 0;
    }
  }
`

export const CategoriesWeight = styled(Box)<{ haseditrole?: string }>`
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: transparent;
  margin: ${theme.spacing.small}px 0 ${theme.spacing.half}px;
  gap: ${theme.spacing.small}px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .categories-weight-header {
    background: ${theme.colors.surfaceContainerBackground};
    border-radius: ${theme.attributes.borderRadius.quaternary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 43px;
    padding: ${theme.spacing.md}px ${theme.spacing.medium}px;

    > .MuiTypography-root {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      width: fit-content;

      &:last-of-type {
        margin-right: ${({ haseditrole }) =>
          haseditrole && haseditrole === 'true' ? '80px' : `${theme.spacing.medium}px`};
      }
    }
  }
`

export const CategoryWeightList = styled(Box)`
  height: calc(100vh - 496px);
  overflow-y: auto;
  gap: ${theme.spacing.small}px;
  display: flex;
  flex-direction: column;
  padding-right: 36px;
  padding-bottom: ${theme.spacing.xLarge}px;
  width: calc(100% + 36px);

  &.has-scrollbar {
    width: calc(100% + 48px);
  }
`

export const CategorySkeletonWrapper = styled(Box)`
  gap: ${theme.spacing.md}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
`

export const CategorySkeleton = styled(Box)`
  width: 100%;
  flex-grow: 1;
  height: 72px !important;
  min-height: 72px !important;
  padding: ${theme.spacing.md}px ${theme.spacing.medium}px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.surfaceContainerBackground};
  flex-basis: 100%;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.small}px;

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    min-height: 48px;
    background-color: ${theme.colors.surfaceItemBackground};
    border-radius: ${theme.attributes.borderRadius.quaternary};

    &:after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 15.32%,
        rgba(255, 255, 255, 0.35) 49.89%,
        rgba(255, 255, 255, 0) 83.78%
      );
    }
  }

  &.category-skeleton-cpi-drawer {
    height: 105px !important;
    min-height: 105px !important;

    .card-skeleton-inner {
      min-height: 68px;
    }
  }
`
