import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const LoadingDeleteInstanceWrapper = styled(Box)`
  background: ${theme.colors.backgroundDarker};
  padding: 28px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DeleteInstanceWrapper = styled(Box)`
  background: ${theme.colors.backgroundDarker};
  padding: 28px;
`

export const DeleteInstanceTitle = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.normal};
  font-size: ${theme.typography.fontSize.xxxLarge};
  line-height: 32px;
  color: ${theme.colors.neutral};
  margin-bottom: ${theme.spacing.sm}px;
`

export const DeleteInstanceSubtitle = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.normal};
  font-size: ${theme.typography.fontSize.xLarge};
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${theme.colors.neutral};
  margin-bottom: 26px;
`

export const InfoTooltipWrapper = styled(Box)`
  .header {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
    margin-bottom: 3px;

    .title {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
      color: ${theme.colors.white};
    }
  }

  .description {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${theme.colors.white};
  }

  .small {
    width: 220px;
  }
`

export const CPILoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const DeleteInstanceCPICardWrapper = styled(Box)<{ noBg: boolean }>`
  width: 100%;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${({ noBg }) =>
    noBg ? '' : 'linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%)'};
  margin-bottom: 29px;
`

export const DeleteInstanceCPICard = styled(Box)<{ justifyCenter: boolean; step: number }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.borderLow};
  padding: 13px;
  justify-content: ${({ justifyCenter }) => (justifyCenter ? 'flex-start' : ' space-between')};

  &:last-child {
    border-bottom: none;
  }

  .cpi {
    border-radius: 18px;
    background: #debdd9;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    padding: 2px ${theme.spacing.xSmall}px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3f293e;
    margin-left: ${theme.spacing.small}px;
    white-space: nowrap;
    margin-right: 18px;
  }

  .cpi-empty {
    width: 100px;
  }

  .name {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.colors.white};
    width: ${({ step }) => (step === 1 ? '405px' : '')};
  }

  .label {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
    margin-left: ${theme.spacing.half}px;
    margin-right: ${theme.spacing.small}px;
    white-space: nowrap;
  }

  .disabled-label {
    opacity: 0.2;
  }
`

export const CPIChip = styled(Box)<{ justifyCenter: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyCenter }) => (justifyCenter ? 'flex-start' : ' space-between')};
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  .cpi {
    border-radius: 18px;
    background: #debdd9;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    padding: 2px ${theme.spacing.xSmall}px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3f293e;
    white-space: nowrap;
    margin-right: ${theme.spacing.sm}px;
  }

  .name {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.colors.white};
    width: 405px;
  }

  .label {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${theme.colors.white};
    margin-left: ${theme.spacing.half}px;
    margin-right: ${theme.spacing.small}px;
    white-space: nowrap;
  }

  .disabled-label {
    opacity: 0.2;
  }
`

export const DeleteInstanceActionsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  margin-bottom: ${theme.spacing.xSmall}px;

  .MuiCircularProgress-root {
    color: #690005;
  }

  .MuiButtonBase-root:first-of-type {
    margin-right: ${theme.spacing.xSmall}px;
  }

  .MuiButtonBase-root:last-of-type {
    background: #ffb4ab;
    color: #690005;

    svg {
      path {
        fill: #690005;
      }
    }
  }
`
