import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)<{ isloading?: string }>`
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.backgroundMedium};
  display: flex;
  padding: ${theme.spacing.small}px;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.small}px;
  width: 100%;

  &:hover {
    background: ${theme.baseColors.secondary[30]};
    cursor: pointer;

    ${({ isloading }) =>
      isloading &&
      isloading === 'true' &&
      `
        cursor: default;
        background: ${theme.colors.surfaceItemBackground};
    `};
  }

  @media (max-width: 1463px) {
    flex-direction: row;
    height: 56px;
    padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
    align-items: center;
  }

  .details-card-header {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;

    .MuiTypography-root {
      color: ${theme.colors.textPrimary};
      text-align: center;
      font-size: ${theme.typography.fontSize.epic};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;
    }
  }

  .details-card-content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .MuiTypography-root {
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${theme.colors.white};
      text-align: center;
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      max-width: 140px;

      @media (max-width: 1463px) {
        white-space: nowrap;
        max-width: unset;
      }
    }
  }
`
