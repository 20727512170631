import { FC, MouseEvent } from 'react'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import theme from 'theme'

/* Components */
import { MarkMenu } from '../listToolbar.styles'

interface Props {
  markMenuOpen: boolean
  anchorEl: null | HTMLElement
  handleClick: (event: MouseEvent<HTMLElement>) => void
  handleClose: () => void
  markMenuOptions: string[]
  handleMarkMenuOption: (option: string) => void
}

const MarkReadMenu: FC<Props> = ({
  markMenuOptions,
  markMenuOpen,
  handleMarkMenuOption,
  handleClick,
  handleClose,
  anchorEl
}) => {
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={markMenuOpen ? 'long-menu' : undefined}
        aria-expanded={markMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: markMenuOpen ? theme.baseColors.secondary[80] : theme.colors.textPrimary,
          background: markMenuOpen ? theme.baseColors.primary[20] : '',
          '&:hover': {
            background: theme.baseColors.primary[70],

            svg: {
              path: {
                fill: theme.baseColors.primary[20]
              }
            }
          }
        }}
      >
        <MoreVertIcon
          sx={{
            path: {
              fill: markMenuOpen ? theme.colors.white : theme.colors.textPrimary
            }
          }}
        />
      </IconButton>
      <MarkMenu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={markMenuOpen}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            marginTop: '5px',
            borderRadius: theme.attributes.borderRadius.small,
            width: '200px',
            maxHeight: '98px',
            border: `1px solid ${theme.colors.borderLow}`,
            background: theme.colors.surfaceItemBackground,
            padding: 0,

            '.MuiList-root': {
              padding: `${theme.spacing.xSmall}px`,

              '.MuiMenuItem-root': {
                borderRadius: theme.attributes.borderRadius.small,
                height: '40px',
                overflow: 'hidden',
                color: theme.colors.white,
                textOverflow: 'ellipsis',
                fontSize: theme.typography.fontSize.xLarge,
                fontStyle: 'normal',
                fontWeight: theme.typography.fontWeight.normal,
                lineHeight: 'normal',
                padding: `0 ${theme.spacing.xSmall}px`,

                '&:hover': {
                  background: theme.colors.primaryLight
                }
              }
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {markMenuOptions.map((option) => (
          <MenuItem key={option} onClick={() => handleMarkMenuOption(option)}>
            {option}
          </MenuItem>
        ))}
      </MarkMenu>
    </div>
  )
}

export default MarkReadMenu
