import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import theme from 'theme'

/* Components */
import { Container, FrameworkSelectCardContainer, Header, Content } from './styles/frameworkSelectCard.styles'
import { ThemeButton } from '../../../components/buttons'
import { DeleteFrameworkTooltip } from './DeleteFrameworkTooltip'
import { PermissionTooltip } from '../../../components/tooltip'

interface Props {
  content: string
  title: string
  headerIcon: string
  selected: boolean
  handleChange: () => void
  handleViewDetails: () => void
  handleDelete?: () => void
  deleteDisabled?: boolean
  loading: boolean
  hasSelectPermission?: boolean
}

const FrameworkSelectCard: FC<Props> = ({
  content,
  handleDelete,
  title,
  deleteDisabled,
  headerIcon,
  selected,
  handleChange,
  handleViewDetails,
  loading,
  hasSelectPermission
}) => {
  return (
    <Container>
      <FrameworkSelectCardContainer
        isSelected={selected ? 'true' : 'false'}
        onClick={handleChange}
        isloading={loading ? 'true' : 'false'}
        hasSelectPermission={hasSelectPermission ? 'true' : 'false'}
      >
        <Header className="select-card-header">
          <Box className="header-framework">
            <img src={headerIcon} alt={`${title} Framework`} width="40px" height="40px" />
            <Typography className="header-title">{title}</Typography>
          </Box>
          {selected && (
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path
                d="M13.9271 26.7158L6.02491 18.8136L3.33398 21.4855L13.9271 32.0787L36.6673 9.33848L33.9953 6.6665L13.9271 26.7158Z"
                fill={theme.colors.textPrimary}
              />
            </svg>
          )}
        </Header>
        <Content className="select-card-content">
          <Typography className="content-text">{content}</Typography>
        </Content>
      </FrameworkSelectCardContainer>
      <Box className="actions-wrapper">
        {typeof handleDelete !== 'undefined' && (
          <>
            {!hasSelectPermission ? (
              <PermissionTooltip
                PopperProps={{
                  sx: {
                    marginTop: `-${theme.spacing.sm}px !important`
                  }
                }}
                title={
                  <>
                    <Box className="tooltip-wrapper-permissions">
                      <Typography className="tooltip-text">Insufficient permissions</Typography>
                    </Box>
                  </>
                }
              >
                <Box className="disabled-delete-button">Delete</Box>
              </PermissionTooltip>
            ) : deleteDisabled ? (
              <DeleteFrameworkTooltip
                title={
                  <>
                    <Box className="tooltip-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                        className="arrow-up"
                      >
                        <path d="M7.49998 0L14.4282 12H0.571777L7.49998 0Z" fill={theme.colors.controlBorder} />
                      </svg>
                      <Typography className="tooltip-text">Can’t delete an active framework</Typography>
                    </Box>
                  </>
                }
              >
                <Box className="disabled-delete-button">Delete</Box>
              </DeleteFrameworkTooltip>
            ) : (
              <ThemeButton
                themevariant="tertiary"
                onClick={handleDelete}
                className="delete-button"
                sx={{
                  pointerEvents: loading ? 'none' : '',
                  color: `${theme.baseColors.secondary[80]} !important`,
                  border: '1px solid transparent !important',

                  '&:focus,&:active': {
                    border: '1px solid transparent !important'
                  }
                }}
              >
                Delete
              </ThemeButton>
            )}
          </>
        )}
        <ThemeButton
          themevariant="secondary"
          onClick={handleViewDetails}
          sx={{
            pointerEvents: loading ? 'none' : ''
          }}
        >
          View Details
        </ThemeButton>
      </Box>
    </Container>
  )
}

export default FrameworkSelectCard
