import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const ScoreWrapper = styled(Box)`
  width: 100%;
  height: 310px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundDarker};
  padding: ${theme.spacing.small}px;

  .title-wrapper {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: ${theme.spacing.xSmall}px;
    }
  }

  .content {
    width: 197px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1445px) {
      flex-direction: column;
    }

    @media (max-width: 1370px) {
      justify-content: flex-start;
    }

    .over-perform {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: ${theme.spacing.half}px;
      margin-top: ${theme.spacing.md}px;

      @media (max-width: 1445px) {
        margin-top: 0;
      }

      svg {
        width: 12px;
        min-width: 12px;
        margin-left: ${theme.spacing.xSmall}px;
      }

      b {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      .value {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.medium};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }
  }
`
