import { FC } from 'react'
import theme from 'theme'

interface Props {
  thirdBreakpoint?: boolean
}

const StubCategoriesSection: FC<Props> = ({ thirdBreakpoint }) => (
  <svg
    width={thirdBreakpoint ? '223' : '243'}
    height="240"
    viewBox="0 0 251 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_27_18636)">
      <rect x="4" y="3" width="243" height="232" rx="24" fill={theme.colors.primaryDark} />
      <rect x="4" y="3" width="243" height="232" rx="24" fill={theme.colors.textPrimary} fillOpacity="0.08" />
    </g>
    <rect x="25.5752" y="21.2593" width="160.107" height="20.4074" rx="10.2037" fill={theme.colors.primaryDark} />
    <rect
      x="25.5752"
      y="21.2593"
      width="160.107"
      height="20.4074"
      rx="10.2037"
      fill={theme.colors.textPrimary}
      fillOpacity="0.08"
    />
    <rect x="25.5752" y="21.2593" width="160.107" height="20.4074" rx="10.2037" fill="url(#paint0_linear_27_18636)" />
    <rect x="22.168" y="58.8521" width="179.411" height="124.593" rx="8" fill={theme.colors.primaryDark} />
    <rect
      x="22.168"
      y="58.8521"
      width="179.411"
      height="124.593"
      rx="8"
      fill={theme.colors.textPrimary}
      fillOpacity="0.08"
    />
    <rect x="22.168" y="58.8521" width="179.411" height="124.593" rx="8" fill="url(#paint1_linear_27_18636)" />
    <rect x="22.168" y="200.629" width="88.5701" height="17.1852" rx="8.5926" fill={theme.colors.primaryDark} />
    <rect
      x="22.168"
      y="200.629"
      width="88.5701"
      height="17.1852"
      rx="8.5926"
      fill={theme.colors.textPrimary}
      fillOpacity="0.08"
    />
    <rect x="22.168" y="200.629" width="88.5701" height="17.1852" rx="8.5926" fill="url(#paint2_linear_27_18636)" />
    <rect x="115.28" y="200.629" width="18.1682" height="17.1852" rx="8.5926" fill={theme.colors.primaryDark} />
    <rect
      x="115.28"
      y="200.629"
      width="18.1682"
      height="17.1852"
      rx="8.5926"
      fill={theme.colors.textPrimary}
      fillOpacity="0.08"
    />
    <rect x="115.28" y="200.629" width="18.1682" height="17.1852" rx="8.5926" fill="url(#paint3_linear_27_18636)" />
    <rect x="137.99" y="200.629" width="18.1682" height="17.1852" rx="8.5926" fill={theme.colors.primaryDark} />
    <rect
      x="137.99"
      y="200.629"
      width="18.1682"
      height="17.1852"
      rx="8.5926"
      fill={theme.colors.textPrimary}
      fillOpacity="0.08"
    />
    <rect x="137.99" y="200.629" width="18.1682" height="17.1852" rx="8.5926" fill="url(#paint4_linear_27_18636)" />
    <defs>
      <filter
        id="filter0_dd_27_18636"
        x="0"
        y="0"
        width="251"
        height="240"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27_18636" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_27_18636" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_27_18636" result="effect2_dropShadow_27_18636" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_27_18636" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_27_18636"
        x1="34.1406"
        y1="31.463"
        x2="173.164"
        y2="31.463"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#433242" />
        <stop offset="1" stopColor="#433242" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_27_18636"
        x1="31.7661"
        y1="121.148"
        x2="187.551"
        y2="121.148"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#433242" />
        <stop offset="1" stopColor="#433242" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_27_18636"
        x1="26.9063"
        y1="209.222"
        x2="103.813"
        y2="209.222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#433242" />
        <stop offset="1" stopColor="#433242" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_27_18636"
        x1="116.252"
        y1="209.222"
        x2="132.028"
        y2="209.222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#433242" />
        <stop offset="1" stopColor="#433242" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_27_18636"
        x1="138.962"
        y1="209.222"
        x2="154.738"
        y2="209.222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#433242" />
        <stop offset="1" stopColor="#433242" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default StubCategoriesSection
