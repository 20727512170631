import { Autocomplete } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const StyledAutocomplete = styled(Autocomplete)`
  min-width: 300px;
  width: 100%;
  height: 40px;
  flex-grow: 1;
  background: transparent;
  color: ${theme.baseColors.secondary[80]};
  text-overflow: ellipsis;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.xLarge};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.normal};
  line-height: normal;
  border-radius: ${theme.attributes.borderRadius.small};
  border: 1px solid ${theme.colors.controlBorder};

  @media (max-width: 1330px) {
    min-width: 270px;
  }

  &.Mui-focused {
    border: 1px solid ${theme.colors.textPrimary};
  }

  .MuiOutlinedInput-root {
    padding: 0 ${theme.spacing.xSmall}px;
    height: 40px;
    color: #e3e3e3;

    svg {
      path {
        fill: ${theme.colors.white};
      }
    }

    .MuiOutlinedInput-input {
      padding-left: ${theme.spacing.xSmall}px;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      overflow: hidden;
      color: ${theme.baseColors.secondary[80]};
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;

      &::placeholder {
        opacity: 1;
        overflow: hidden;
        color: ${theme.baseColors.secondary[80]};
        text-overflow: ellipsis;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      }
    }

    fieldset {
      border: none;
    }
  }
`
