import styled from '@emotion/styled/macro'
import { Drawer as MuiDrawer } from '@mui/material'
import theme from 'theme'

export const Drawer = styled(MuiDrawer)`
  .loading-wrapper {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: rgba(20, 18, 24, 0.65);
    z-index: 10;

    > div {
      margin-top: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }

  .MuiPaper-root {
    background: #141218;
    box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
    min-width: 320px;
    padding-top: ${theme.spacing.md}px;
    padding-bottom: ${theme.spacing.md}px;
    border-left: 1px solid ${theme.colors.controlBorder};

    ::-webkit-scrollbar {
      display: none;
    }

    .wrapper-inner {
      width: 100%;

      .header-block {
        padding-left: ${theme.spacing.medium}px;
        padding-right: ${theme.spacing.medium}px;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: ${theme.spacing.md}px;

          .close-icon {
            width: 40px;
            height: 40px;
            margin-right: ${theme.spacing.xSmall}px;

            &:hover {
              background: rgba(202, 196, 208, 0.12);
            }

            svg {
              path {
                fill: ${theme.colors.white};
              }
            }
          }

          .title {
            font-family: ${theme.typography.fontFamily.primary};
            font-weight: ${theme.typography.fontWeight.normal};
            font-size: ${theme.typography.fontSize.xxLarge};
            line-height: 28px;
            color: ${theme.colors.white};
          }
        }

        .custom-header {
          justify-content: flex-start;
        }

        .dates {
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 20px;
          letter-spacing: 0.1px;
          padding-bottom: ${theme.spacing.lg}px;
        }
      }

      .drawer-actions {
        background: ${theme.colors.primaryDarker};
        border-top: 1px solid rgba(222, 189, 217, 0.5);
        padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
        display: flex;
        align-items: center;
        gap: ${theme.spacing.small}px;
        position: fixed;
        bottom: 0;
        width: 320px;
        right: 0;

        .icon-block {
          border: 1px solid ${theme.colors.controlBorder};
          border-radius: 100%;
          padding: ${theme.spacing.xSmall}px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .MuiButton-root {
          border-radius: 44px;
          border: 1px solid ${theme.colors.controlBorder};
          background: ${theme.colors.backgroundLight};
          box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
          color: ${theme.colors.white};
          width: 100%;
        }
      }

      .edit-actions {
        button {
          padding-right: ${theme.spacing.xSmall}px;
        }

        .save-btn {
          border: 1px solid rgba(227, 227, 227, 0.12) !important;
          background: ${theme.colors.textPrimary};
          color: ${theme.baseColors.primary[20]};

          &:hover {
            svg {
              path {
                fill: ${theme.baseColors.primary[20]};
              }
            }
          }
        }
      }

      .unpin-btn {
        border: 1px solid rgba(227, 227, 227, 0.12) !important;
        background: ${theme.colors.textPrimary} !important;
        color: ${theme.baseColors.primary[20]} !important;
        font-weight: ${theme.typography.fontWeight.bold} !important;

        &:hover {
          svg {
            path {
              fill: ${theme.baseColors.primary[20]};
            }
          }
        }
      }

      .disabled-button {
        background: rgba(227, 227, 227, 0.12) !important;
        color: ${theme.colors.neutral} !important;

        svg {
          path {
            fill: ${theme.colors.neutral} !important;

            &:last-child {
              stroke: ${theme.colors.neutral} !important;
            }
          }
        }
      }
    }
  }
`
