import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const ChartContainer = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.small};
  position: relative;

  .bordered-wrapper {
    position: absolute;
    height: 116px;
    width: calc(100% - 40px);
    top: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
    display: none;
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.borderLow};
    box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};

    @media (min-width: 900px) {
      display: block;
    }

    @media (min-width: 1440px) {
      right: 10px;
      height: 124px;
    }

    @media (min-width: 1920px) {
      height: 124px;
    }
  }

  #category-chart-svg,
  .category-chart-svg {
    border-radius: ${theme.attributes.borderRadius.small};
    z-index: 10;
  }

  .category-chart-tooltip {
    display: none;
    position: absolute;
    opacity: 1;
    padding: ${theme.spacing.small}px;
    gap: ${theme.spacing.sm}px;
    border-radius: ${theme.attributes.borderRadius.mediumSmall};
    border: 1px solid ${theme.colors.controlBorder};
    background: ${theme.colors.borderLow};
    box-shadow: ${theme.attributes.boxShadow.chipCircle};
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
    width: 240px;
    z-index: 100000 !important;

    .tooltip-performance,
    .tooltip-units {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
    }

    .tooltip-performance {
      font-size: ${theme.typography.fontSize.xxxLarge};
      line-height: 32px;
      margin: 0 0 4px 0;
    }

    .tooltip-units {
      font-size: ${theme.typography.fontSize.medium};
      line-height: 16px;
      letter-spacing: 0.4px;
      margin: 0;
    }

    .severity-text {
      text-transform: capitalize;
    }
  }
`
