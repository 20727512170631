import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  border-top: 1px solid rgba(222, 189, 217, 0.5);

  .MuiMultiInputDateRangeField-separator {
    display: none;
  }

  .MuiMultiInputDateRangeField-root {
    flex-direction: column;
    align-items: self-end;
  }

  .MuiFormControl-root {
    height: 56px;
    width: 100%;
    margin-bottom: ${theme.spacing.small}px;

    .MuiInputLabel-root {
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      top: 2px;
      color: ${theme.colors.white};
      background: #141218;
    }

    .MuiOutlinedInput-root {
      height: 56px;
      border: 1px solid ${theme.colors.controlBorder};
      border-radius: ${theme.attributes.borderRadius.tertiary};
      background: transparent;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};

      &.Mui-focused {
        color: ${theme.colors.controlBorder};
        border: 1px solid ${theme.colors.controlBorder};
      }

      input {
        height: 56px;
      }

      svg {
        path {
          fill: ${theme.colors.controlBorder};
        }
      }
    }
  }

  .content {
    padding-top: ${theme.spacing.small}px;
    padding-left: ${theme.spacing.medium}px;
    padding-right: ${theme.spacing.medium}px;

    .reset-block {
      display: flex;
      justify-content: flex-end;
      margin-top: ${theme.spacing.sm}px;
    }

    .reset {
      color: ${theme.colors.controlBorder};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      cursor: pointer;
    }

    .label {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: ${theme.spacing.small}px;
    }

    .dates {
      border-bottom: 1px solid rgba(222, 189, 217, 0.5);
      margin-bottom: ${theme.spacing.small}px;
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.small}px;

      label {
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
        color: ${theme.colors.white};
        cursor: pointer;
      }
    }

    .filters-actions {
      justify-content: center;

      button {
        width: 131px !important;
        justify-content: center;
        padding-right: 19px !important;
      }

      .disabled-btn {
        background: rgba(227, 227, 227, 0.12) !important;
        color: ${theme.colors.neutral} !important;
      }

      span {
        color: ${theme.colors.textPrimary};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
        cursor: pointer;
      }
    }
  }
`
