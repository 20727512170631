import { Dialog as MuiDialog } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Dialog = styled(MuiDialog)`
  width: 100%;
  height: 100%;
  z-index: 100000;

  .MuiDialog-container {
    .MuiPaper-root {
      height: 480px;
      background: #292128;
      border-radius: ${theme.attributes.borderRadius.quaternary};

      .header {
        display: flex;
        align-items: center;
        height: 72px;
        padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
        gap: ${theme.spacing.xSmall}px;
        border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
        background: ${theme.colors.surfaceItemBackground};

        .header-title {
          font-family: ${theme.typography.fontFamily.primary};
          color: ${theme.colors.textPrimary};
          font-size: ${theme.typography.fontSize.header};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: normal;
        }
      }

      .MuiDialogContent-root {
        padding: ${theme.spacing.medium}px;
        gap: ${theme.spacing.md}px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border: 0;

        .checkboxes-form-group {
          margin-left: ${theme.spacing.half}px;
          margin-top: 12px;
          gap: ${theme.spacing.xSmall}px;

          .MuiCheckbox-root {
            padding: ${theme.spacing.xSmall}px;
          }

          .MuiFormControlLabel-root {
            color: ${theme.colors.white};
            font-size: ${theme.typography.fontSize.xLarge};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: normal;
            font-family: ${theme.typography.fontFamily.primary};

            .MuiTypography-root {
              color: ${theme.colors.white};
              font-size: ${theme.typography.fontSize.xLarge};
              font-style: normal;
              font-weight: ${theme.typography.fontWeight.normal};
              line-height: normal;
              font-family: ${theme.typography.fontFamily.primary};
            }
          }
        }

        .content-header {
          color: ${theme.colors.white};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: 22px;
          font-family: ${theme.typography.fontFamily.primary};
        }

        .content-description {
          color: ${theme.colors.white};
          font-size: ${theme.typography.fontSize.xLarge};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          font-family: ${theme.typography.fontFamily.primary};
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
        border-radius: 0 0 16px 16px;
        background: ${theme.colors.surfaceItemBackground};
        gap: ${theme.spacing.xSmall}px;
      }
    }
  }
`
