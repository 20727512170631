import { Box, Button as MuiButton, TextField as MuiTextField } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DeleteIntegrationContainer = styled(Box)`
  max-width: 600px;
  background: ${theme.colors.backgroundDarker};
  border-radius: ${theme.attributes.borderRadius.secondary};

  .MuiDialogTitle-root {
    box-shadow: none;
  }

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xxxLarge};
    line-height: 32px;
    color: ${theme.colors.neutral};
    mix-blend-mode: normal;
  }

  .description {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${theme.colors.neutral};
    mix-blend-mode: normal;
    margin-bottom: ${theme.spacing.small}px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiFormControl-root {
      height: 56px;

      .MuiOutlinedInput-root {
        height: 56px;
        border: 1px solid ${theme.colors.controlBorder};
        border-radius: ${theme.attributes.borderRadius.small};
        background: transparent;
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.normal};
        font-size: ${theme.typography.fontSize.xLarge};
        line-height: 24px;
        letter-spacing: 0.5px;
        color: ${theme.baseColors.greyscale[80]};
        min-width: 360px;

        input {
          color: ${theme.baseColors.greyscale[80]};
          font-family: ${theme.typography.fontFamily.primary};
          font-weight: ${theme.typography.fontWeight.normal};
          font-size: ${theme.typography.fontSize.xLarge};
          line-height: 24px;
          letter-spacing: 0.5px;

          &::placeholder {
            color: ${theme.baseColors.greyscale[80]};
          }
        }
      }
    }
  }
`

export const ConfirmButton = styled(MuiButton)`
  text-transform: capitalize;
  padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px ${theme.spacing.small}px;
  gap: ${theme.spacing.xSmall}px;
  background: #ffb4ab;
  border-radius: ${theme.attributes.borderRadius.large};
  min-width: 120px;
  height: 40px;
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #690005;
  margin-right: ${theme.spacing.small}px;
  margin-bottom: ${theme.spacing.sm}px;

  svg {
    path {
      fill: #690005;
    }
  }

  &:hover {
    background: #ffb4ab;
    opacity: 0.9;
    color: #690005;
  }

  &.Mui-disabled {
    background: rgba(227, 227, 227, 0.12);
    color: ${theme.colors.neutral};

    svg {
      path {
        fill: ${theme.colors.neutral};
      }
    }
  }
`

export const CancelButton = styled(MuiButton)`
  text-transform: capitalize;
  margin-right: ${theme.spacing.xSmall}px;
  padding: ${theme.spacing.sm}px ${theme.spacing.md}px;
  gap: ${theme.spacing.xSmall}px;
  min-width: 80px;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.large};
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${theme.colors.textPrimary};
`

export const TextField = styled(MuiTextField)`
  .MuiInputBase-root {
    height: 32px;
  }

  .MuiOutlinedInput-input {
    color: #140c40;
    font-size: ${theme.typography.fontSize.large};
    line-height: 32px;
    font-weight: ${theme.typography.fontWeight.normal};
  }

  .MuiOutlinedInput-root {
    background: ${theme.colors.white};
  }
`
