import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  display: flex;
  height: 310px;
  padding: ${theme.spacing.medium}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.medium}px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundDarker};
  width: 100%;
  flex-basis: 33.33%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .header-start {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;

      .header-title {
        color: ${theme.colors.white};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
      }
    }

    .header-date {
      color: ${theme.colors.textSecondaryLow};
      text-align: right;
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.medium}px;
    width: 100%;

    @media (max-width: 1463px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: ${theme.spacing.small}px;
    }
  }

  @media (max-width: 1463px) {
    overflow-y: auto;
  }
`
