import { Box, FormGroup } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
`

export const CustomizerContainer = styled(Box)`
  width: 100%;
`

export const Wrapper = styled(Box)`
  width: 100%;
  margin-top: ${theme.spacing.small}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -64px; /* Offset the sidebar */
`

export const FormWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${theme.spacing.medium}px 0;
  gap: ${theme.spacing.medium}px;
`

export const FormItemWrapper = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundLight};
  padding: ${theme.spacing.small}px ${theme.spacing.medium}px ${theme.spacing.medium}px ${theme.spacing.medium}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  gap: ${theme.spacing.xSmall}px;
  width: 504px;
  min-height: 128px;

  .title {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const MonthPickerWrapper = styled(Box)<{ isopen: string }>`
  display: none;
  align-items: center;
  box-shadow: ${theme.attributes.boxShadow.chipCircle};
  border-radius: ${theme.attributes.borderRadius.secondary};
  background: ${theme.colors.primaryLight};
  width: 456px;
  margin-top: -${theme.spacing.xSmall}px;

  ${({ isopen }) =>
    isopen === 'true' &&
    `
      display: flex;
    `};

  .month-calendar-container {
    padding-top: ${theme.spacing.small}px;

    &:first-of-type {
      border-right: 1px solid ${theme.baseColors.secondary[40]};
    }

    .month-picker-monthly-title {
      color: ${theme.baseColors.greyscale[80]};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .MuiMonthCalendar-root {
      width: 228px;

      .MuiPickersMonth-root {
        .MuiPickersMonth-monthButton {
          width: 40px;
          height: 40px;
          color: #fbd8f5;
          text-align: center;
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: 20px;
          letter-spacing: 0.25px;

          &.Mui-selected {
            border-radius: ${theme.attributes.borderRadius.circle};
            color: ${theme.baseColors.primary[20]};
            background: ${theme.colors.textPrimary};
          }

          &.Mui-disabled {
            color: ${theme.baseColors.greyscale[80]};
          }
        }
      }

      .MuiPickersCalendarHeader-root {
        div {
          display: flex;
          justify-content: center;
          width: 100%;
        }

        .MuiPickersArrowSwitcher-root {
          display: none;
        }
      }
    }
  }
`

export const ActionsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  max-width: 504px;

  .buttons-container {
    display: flex;
    flex-direction: row;
  }
`

export const FormItemInfoWrapper = styled(Box)`
  display: flex;
  padding: 13px ${theme.spacing.small}px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.secondary};
  border: 1px solid #8e9aff;
  background: rgba(142, 154, 255, 0.4);
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  .info-text {
    margin-left: ${theme.spacing.small}px;
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`

export const SelectCpisWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .controls {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xSmall}px;
    margin-top: 2px;
    margin-bottom: ${theme.spacing.xSmall}px;

    .MuiButtonBase-root {
      padding: ${theme.spacing.sm}px ${theme.spacing.md}px !important;
      border-radius: 0 !important;

      &:first-of-type {
        margin-left: -${theme.spacing.sm}px !important;
      }
    }
  }
`

export const CpisList = styled(FormGroup)`
  .MuiFormControlLabel-root {
    .MuiCheckbox-root {
      svg {
        path {
          fill: ${theme.baseColors.greyscale[80]};
        }
      }
    }
  }

  .label-wrapper {
    display: flex;
    align-items: center;

    .label-title {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-left: ${theme.spacing.small}px;

      &.no-margin {
        margin-left: 0;
      }
    }
  }
`
