import { FC, useEffect, useRef, useState } from 'react'
import { useAuth } from '@frontegg/react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import axios from '../../../../lib/axios'

/* Utils */
import { ChartData, CPIManifest, CPITimePeriod, DataStatus, IntegrationInstance } from '../../../../models'
import { INITIALIZING_TIMER, initialValues } from '../library.constants'
import useLibraryDetailsData from './useLibraryDetailsData'
import { getPayloadTimePeriod } from '../utils'
import { useAppDispatch } from '../../../../store/hooks'

/* Components */
import { LibraryDetailsContainer } from './libraryDetails.styles'
import { LoadingWrapper } from '../library.styles'
import TabPanel from './components/LibraryDetailsTabPanel'
import LibraryDetailsHeader from './components/LibraryDetailsHeader'
import TrendsTab from './sections/trends-tab'
import AboutTab from './sections/about-tab'
import BenchmarkingTab from './sections/benchmarking-tab'
import CPILibrarySettingsDrawer from './sections/settings-drawer'
import { closeToast, openToast, ToastVariant } from '../../../../store/toasts/toastSlice'
import { useSelector } from 'react-redux'
import { accountSettingsSelector } from '../../../../store/user/userSlice'
import PredictionModal from './sections/trends-tab/sections/PredictionModal'

dayjs.extend(relativeTime)

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noreferrer')
}

export interface Checked {
  [key: string]: boolean
}

export interface CustomColors {
  chip_bg_color: string
  chip_color: string
}

const LibraryDetails: FC = () => {
  const { cpiName } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const closeNotification = useRef<any>(null)
  const accountSettings = useSelector(accountSettingsSelector)
  const { user: authUser } = useAuth()
  const [currentSeverityStatus, setCurrentSeverityStatus] = useState<'healthy' | 'problematic'>('healthy')
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [defaultValues, setDefaultValues] = useState(initialValues)
  const [chartData, setChartData] = useState<ChartData | null>(null)
  const [criticalSla, setCriticalSla] = useState(0)
  const [fetchingInterval, setFetchingInterval] = useState(INITIALIZING_TIMER)
  const [emptyInfoTooltipOpen, setEmptyInfoTooltipOpen] = useState(false)
  const [slaDescriptionTooltipOpen, setSlaDescriptionTooltipOpen] = useState(false)
  const [healthDirection, setHealthDirection] = useState('')
  const isMediumHeight = useMediaQuery('(max-height:900px)')
  const [isExportingReport, setIsExportingReport] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [key, setKey] = useState(0)
  const [code, setCode] = useState('')
  const [predictionModalOpen, setPredictionModalOpen] = useState(false)
  const [redirectToMode, setRedirectToMode] = useState('show-0')
  const [mode, setMode] = useState('create')
  const [labelDrawerOpen, setLabelDrawerOpen] = useState(false)

  const handlePredictionModalOpen = () => setPredictionModalOpen(true)
  const handlePredictionModalClose = () => setPredictionModalOpen(false)

  const {
    userConfiguredIntegrations,
    cpi,
    cpiConfiguration,
    manifestData,
    competitorAnalysisData,
    detailedComparisonData,
    active,
    showBenchmarkingTab,
    initialLoad,
    activeSources,
    checked,
    setChecked,
    notConfiguredInstancesApplicableToCpi,
    cpiSupportedAndUserConfiguredList,
    dataSourceValue,
    dataSourceOptions,
    deactivateCpi,
    favorite,
    updateFavorite,
    selectedSeverity,
    selectedTimePeriod,
    timePeriodValues,
    frequencyValues,
    selectedFrequency,
    setSelectedSeverity,
    setSelectedTimePeriod,
    setSelectedFrequency,
    setDataSourceValue,
    selectedDataSourceIds,
    setSelectedDataSourceIds,
    fetchCpiConfig,
    fetchAll,
    stripes,
    setStripes,
    setInitialLoad,
    customTimePeriodValue,
    setCustomTimePeriodValue,
    hasPredictionsEnabled,
    predictionData,
    refetchPredictionData,
    predictionModelAvailable,
    daysUntilDataAvailable,
    selectedType,
    setSelectedType,
    customColors,
    customFramework,
    fw,
    fetchPredictionData,
    setPredictionData,
    renderingChart,
    setRenderingChart,
    tabValue,
    setTabValue
  } = useLibraryDetailsData({ cpiName })

  const { data, error, isFetching } = useQuery(
    ['code', { code }],
    () => axios.get(`/api/v1/reports/download_url/${code}`),
    {
      refetchInterval: 5000,
      enabled: !!code
    }
  )

  useEffect(() => {
    if (location.state && (location.state as any).isAboutTab) {
      if (active) {
        setTabValue(1)
      } else {
        setTabValue(0)
      }
      setTimeout(() => {
        navigate(location.pathname, {})
      }, 2000)
    }
    if (location.state && (location.state as any).openConfig) {
      setDrawerOpen(true)
      setTimeout(() => {
        navigate(location.pathname, {})
      }, 2000)
    }

    if (location.hash === '#about' && active) return setTabValue(1)
    setKey(key + 1)
  }, [location, active])

  useEffect(() => {
    if (initialLoad) {
      fetchAll(cpiName).catch((e) => e)
    }
  }, [cpiName, key])

  useEffect(() => {
    if (data && !error && !isFetching) {
      const downloadUrl = data.data.reportDownloadUrl.url
      openInNewTab(`${downloadUrl}`)
      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: `${cpi?.display_name} Report PDF Successfully Downloaded`,
            description: ''
          }
        })
      )
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setCode('')
    }
  }, [data, error, isFetching, code])

  const getIntegrationLogo = (integrationName: string) => {
    if (!manifestData) return ''

    const supportedIntegration = manifestData.supported_integrations.find((n) => n.name === integrationName)

    if (supportedIntegration) {
      return `/icons/rounded/${supportedIntegration.logo_url}`
    }

    return '/icons/rounded/crowdstrike_falcon_icon.svg'
  }

  const handleChangeInstance = (isChecked: boolean, name: string) => {
    setChecked((checked) => ({
      ...checked,
      [name]: isChecked
    }))
  }

  useEffect(() => {
    if (cpiConfiguration && active) {
      const values: any = {
        cpiCategoryValue: ''
      }
      if (cpiConfiguration.user_input.visualization_param.identical_section_values) {
        values.sla = cpiConfiguration.user_input.visualization_param.identical_value
      }
      if (
        !cpiConfiguration.user_input.visualization_param.identical_section_values &&
        cpiConfiguration.user_input.visualization_param.sections
      ) {
        cpiConfiguration.user_input.visualization_param.sections.forEach(({ name, value }) => {
          values[name] = value
        })
      }
      if (cpiConfiguration.user_input.formula_params && cpiConfiguration.user_input.formula_params.length > 0) {
        if (cpiConfiguration.user_input.formula_params[0].sections.length > 0) {
          cpiConfiguration.user_input.formula_params[0].sections.forEach(({ name, value }) => {
            values[name] = value
          })
        } else if (cpiConfiguration.user_input.formula_params[0]?.value) {
          values[cpiConfiguration.user_input.formula_params[0].name] =
            cpiConfiguration.user_input.formula_params[0].value
        }
      }
      setDefaultValues(values)
    } else if (manifestData) {
      const values: any = {
        cpiCategoryValue: ''
      }
      if (manifestData.ui.trend_chart.user_input.visualization_param.identical_section_values) {
        values.sla = manifestData.ui.trend_chart.user_input.visualization_param.identical_value
      }
      if (!manifestData.ui.trend_chart.user_input.visualization_param.identical_section_values) {
        manifestData.ui.trend_chart.user_input.visualization_param.sections.forEach(({ name, value }) => {
          values[name] = value
        })
      }
      if (
        manifestData.ui.trend_chart.user_input.formula_params &&
        manifestData.ui.trend_chart.user_input.formula_params.length > 0
      ) {
        if (manifestData.ui.trend_chart.user_input.formula_params[0].sections.length > 0) {
          manifestData.ui.trend_chart.user_input.formula_params[0].sections.forEach(({ name, value }) => {
            values[name] = value
          })
        } else if (manifestData.ui.trend_chart.user_input.formula_params[0]?.value) {
          values[manifestData.ui.trend_chart.user_input.formula_params[0].name] =
            manifestData.ui.trend_chart.user_input.formula_params[0].value
        }
      }
      setDefaultValues(values)
    }
  }, [manifestData, cpiConfiguration])

  const getDefaultChecked = (integration: IntegrationInstance) => {
    if (cpiConfiguration) {
      const configured = cpiConfiguration.integrations.map((n) => n.id)
      if (active && !!configured) {
        const res = cpiSupportedAndUserConfiguredList.filter((n) => configured.includes(n.id))

        return !!res.find((n) => n.name === integration.name)
      }
    }

    return false
  }

  const handleEmptyInfoClick = () => {
    setEmptyInfoTooltipOpen(!emptyInfoTooltipOpen)
  }

  const handleSlaDescriptionTooltipClick = () => {
    setSlaDescriptionTooltipOpen(!slaDescriptionTooltipOpen)
  }

  const handleReportExport = async () => {
    if (!cpi) return

    if (!manifestData) return

    const thisSection = manifestData.ui.trend_chart.user_input.visualization_param.sections.find(
      (n) => n.display_name === selectedSeverity
    )

    let timePeriod: '1w' | '1m' | '3m' | '6m' | '1y' | undefined = getPayloadTimePeriod(selectedTimePeriod)
    let dateFrom = ''
    let dateTo = ''
    if (selectedTimePeriod === CPITimePeriod.Custom && customTimePeriodValue.length > 0) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const firstPart = customTimePeriodValue.slice(0, 3)
      const firstMonth = months.indexOf(firstPart) + 1
      const firstMonthFormatted = `${firstMonth}`.padStart(2, '0')
      const endYearPart = customTimePeriodValue.slice(-2)
      const endYear = `20${endYearPart}`

      if (customTimePeriodValue.length > 13) {
        const secondPart = customTimePeriodValue.slice(8, 11)
        const secondMonth = months.indexOf(secondPart) + 1
        const secondMonthFormatted = `${secondMonth}`.padStart(2, '0')
        const startYearPart = customTimePeriodValue.slice(5, 7)
        const startYear = `20${startYearPart}`
        const daysInMonth = dayjs(`${endYear}-${secondMonthFormatted}-01`).daysInMonth()

        dateFrom = `${startYear}-${firstMonthFormatted}-01`
        dateTo = `${endYear}-${secondMonthFormatted}-${daysInMonth}`
        timePeriod = undefined
      } else {
        const secondPart = customTimePeriodValue.slice(4, 7)
        const secondMonth = months.indexOf(secondPart) + 1
        const secondMonthFormatted = `${secondMonth}`.padStart(2, '0')
        const daysInMonth = dayjs(`${endYear}-${secondMonthFormatted}-01`).daysInMonth()

        dateFrom = `${endYear}-${firstMonthFormatted}-01`
        dateTo = `${endYear}-${secondMonthFormatted}-${daysInMonth}`
        timePeriod = undefined
      }
    }
    const payload = {
      cpi_name: cpi?.name,
      frequency: selectedFrequency,
      integration_ids: selectedDataSourceIds,
      section: thisSection?.name,
      timePeriod,
      dateFrom: dateFrom,
      dateTo: dateTo
    }

    setIsExportingReport(true)

    try {
      const res = await axios.post(`/api/v1/reports/cpi`, payload)

      if (res.status.toString().startsWith('2')) {
        const { code } = res.data
        setCode(code)
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
    } finally {
      setIsExportingReport(false)
    }
  }

  if (!initialLoad) {
    return (
      <LoadingWrapper>
        <CircularProgress size="40px" />
      </LoadingWrapper>
    )
  }
  return (
    <Box key={key}>
      {!manifestData ? (
        <Typography textAlign="center" mt={4}>
          CPI not found
        </Typography>
      ) : (
        <LibraryDetailsContainer>
          <LibraryDetailsHeader
            customColors={customColors}
            cpi={cpi}
            currentSeverityStatus={currentSeverityStatus}
            manifestData={manifestData}
            favorite={favorite}
            setInitialLoad={setInitialLoad}
            setRenderingChart={setRenderingChart}
            updateFavorite={updateFavorite}
            active={active}
            setDrawerOpen={setDrawerOpen}
            authUser={authUser}
            tabValue={tabValue}
            setTabValue={setTabValue}
            showBenchmarkingTab={showBenchmarkingTab}
            handleReportExport={handleReportExport}
            isExportingReport={isExportingReport || isFetching}
            showReport={Boolean(chartData && chartData.status === DataStatus.Ready)}
            accountSettings={accountSettings}
          />
          {active ? (
            <>
              <TabPanel value={tabValue} index={0}>
                <TrendsTab
                  cpi={cpi}
                  setPredictionData={setPredictionData}
                  fetchPredictionData={fetchPredictionData}
                  stripes={stripes}
                  setStripes={setStripes}
                  fetchingInterval={fetchingInterval}
                  isMediumHeight={isMediumHeight}
                  manifestData={manifestData}
                  chartData={chartData}
                  setChartData={setChartData}
                  setSelectedType={setSelectedType}
                  setSelectedSeverity={setSelectedSeverity}
                  setSelectedTimePeriod={setSelectedTimePeriod}
                  setSelectedFrequency={setSelectedFrequency}
                  setDataSourceValue={setDataSourceValue}
                  selectedType={selectedType}
                  selectedTimePeriod={selectedTimePeriod}
                  selectedSeverity={selectedSeverity}
                  selectedFrequency={selectedFrequency}
                  userConfiguredIntegrations={userConfiguredIntegrations}
                  cpiConfiguration={cpiConfiguration}
                  cpiSupportedAndUserConfiguredList={cpiSupportedAndUserConfiguredList}
                  timePeriodValues={timePeriodValues}
                  frequencyValues={frequencyValues}
                  dataSourceOptions={dataSourceOptions}
                  dataSourceValue={dataSourceValue}
                  setEmptyInfoTooltipOpen={setEmptyInfoTooltipOpen}
                  setCurrentSeverityStatus={setCurrentSeverityStatus}
                  emptyInfoTooltipOpen={emptyInfoTooltipOpen}
                  labelDrawerOpen={labelDrawerOpen}
                  setLabelDrawerOpen={setLabelDrawerOpen}
                  mode={mode}
                  setMode={setMode}
                  handleEmptyInfoClick={handleEmptyInfoClick}
                  currentSeverityStatus={currentSeverityStatus}
                  redirectToMode={redirectToMode}
                  setRedirectToMode={setRedirectToMode}
                  defaultValues={defaultValues}
                  setHealthDirection={setHealthDirection}
                  setCriticalSla={setCriticalSla}
                  setFetchingInterval={setFetchingInterval}
                  selectedDataSourceIds={selectedDataSourceIds}
                  setSelectedDataSourceIds={setSelectedDataSourceIds}
                  customTimePeriodValue={customTimePeriodValue}
                  setCustomTimePeriodValue={setCustomTimePeriodValue}
                  predictionModalOpen={predictionModalOpen}
                  handlePredictionModalOpen={handlePredictionModalOpen}
                  hasPredictionsEnabled={hasPredictionsEnabled}
                  predictionModelAvailable={predictionModelAvailable}
                  refetchPredictionData={refetchPredictionData}
                  daysUntilDataAvailable={daysUntilDataAvailable}
                  renderingChart={renderingChart}
                  setRenderingChart={setRenderingChart}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <AboutTab
                  manifest={manifestData}
                  cpi={cpi}
                  activeSources={activeSources}
                  notConfiguredInstancesApplicableToCpi={notConfiguredInstancesApplicableToCpi}
                  additionalOptions={cpi?.supported_integrations || []}
                  getIntegrationLogo={getIntegrationLogo}
                  active={active}
                  drawerOpen={drawerOpen}
                />
              </TabPanel>
              {showBenchmarkingTab && (
                <TabPanel value={tabValue} index={2}>
                  <BenchmarkingTab
                    active
                    sla={criticalSla}
                    unit={manifestData.ui.trend_chart.user_input.visualization_param.unit}
                    benchmarkingTabLoading={!initialLoad}
                    showMessage={showMessage}
                    healthDirection={healthDirection}
                    competitorAnalysisData={competitorAnalysisData}
                    detailedComparisonData={detailedComparisonData}
                    setCriticalSla={setCriticalSla}
                    selectedSeverity={selectedSeverity}
                    selectedDataSourceIds={selectedDataSourceIds}
                    selectedFrequency={selectedFrequency}
                    cpi={cpi}
                    selectedTimePeriod={selectedTimePeriod}
                    manifestData={manifestData}
                  />
                </TabPanel>
              )}
            </>
          ) : (
            <>
              <TabPanel value={tabValue} index={0}>
                <AboutTab
                  manifest={manifestData}
                  cpi={cpi}
                  activeSources={cpiSupportedAndUserConfiguredList}
                  notConfiguredInstancesApplicableToCpi={notConfiguredInstancesApplicableToCpi}
                  additionalOptions={cpi?.supported_integrations || []}
                  getIntegrationLogo={getIntegrationLogo}
                  active={active}
                  drawerOpen={drawerOpen}
                />
              </TabPanel>
              {showBenchmarkingTab && (
                <TabPanel value={tabValue} index={1}>
                  <BenchmarkingTab
                    sla={criticalSla}
                    cpi={cpi}
                    unit={manifestData.ui.trend_chart.user_input.visualization_param.unit}
                    showMessage={showMessage}
                    benchmarkingTabLoading={!initialLoad}
                    healthDirection={healthDirection}
                    competitorAnalysisData={competitorAnalysisData}
                    detailedComparisonData={detailedComparisonData}
                    setCriticalSla={setCriticalSla}
                    selectedSeverity={selectedSeverity}
                    selectedDataSourceIds={selectedDataSourceIds}
                    selectedFrequency={selectedFrequency}
                    selectedTimePeriod={selectedTimePeriod}
                    manifestData={manifestData}
                  />
                </TabPanel>
              )}
            </>
          )}

          <CPILibrarySettingsDrawer
            fw={fw}
            customFramework={customFramework}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            setShowMessage={setShowMessage}
            active={active}
            manifestData={manifestData}
            defaultValues={defaultValues}
            cpiSupportedAndUserConfiguredList={cpiSupportedAndUserConfiguredList}
            fetchAll={fetchAll}
            checked={checked}
            getDefaultChecked={getDefaultChecked}
            handleChangeInstance={handleChangeInstance}
            getIntegrationLogo={getIntegrationLogo}
            setEmptyInfoTooltipOpen={setEmptyInfoTooltipOpen}
            emptyInfoTooltipOpen={emptyInfoTooltipOpen}
            handleEmptyInfoClick={handleEmptyInfoClick}
            cpi={cpi}
            cpiConfiguration={cpiConfiguration}
            userConfiguredIntegrations={userConfiguredIntegrations}
            setChartData={setChartData}
            deactivateCpi={deactivateCpi}
            setTabValue={setTabValue}
            fetchCpiConfig={fetchCpiConfig}
            authUser={authUser}
            setCriticalSla={setCriticalSla}
            slaDescriptionTooltipOpen={slaDescriptionTooltipOpen}
            setSlaDescriptionTooltipOpen={setSlaDescriptionTooltipOpen}
            handleSlaDescriptionTooltipClick={handleSlaDescriptionTooltipClick}
          />
        </LibraryDetailsContainer>
      )}
      <PredictionModal
        open={predictionModalOpen}
        handleClose={handlePredictionModalClose}
        currentSeverityStatus={currentSeverityStatus}
        manifestData={manifestData as CPIManifest}
        isMediumHeight={isMediumHeight}
        setRedirectToMode={setRedirectToMode}
        setDataSourceValue={setDataSourceValue}
        selectedTimePeriod={selectedTimePeriod}
        selectedSeverity={selectedSeverity}
        selectedFrequency={selectedFrequency}
        setSelectedDataSourceIds={setSelectedDataSourceIds}
        cpi={cpi}
        userConfiguredIntegrations={userConfiguredIntegrations}
        cpiConfiguration={cpiConfiguration}
        cpiSupportedAndUserConfiguredList={cpiSupportedAndUserConfiguredList}
        dataSourceOptions={dataSourceOptions}
        dataSourceValue={dataSourceValue}
        setLabelDrawerOpen={setLabelDrawerOpen}
        setMode={setMode}
        setSelectedSeverity={setSelectedSeverity}
        defaultValues={defaultValues}
        predictionData={predictionData}
        refetchPredictionData={refetchPredictionData}
        activeSources={activeSources}
      />
    </Box>
  )
}

export default LibraryDetails
