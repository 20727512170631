import { FC, Dispatch, SetStateAction, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import theme from 'theme'

/* Utils */
import {
  CPITimePeriod,
  IntegrationInstance,
  LastSeenWithin,
  ManifestIntegration,
  OperatingSystem
} from '../../../models'

/* Components */
import { Container, Section, Separator } from './coverageAnalyzerInitial.styles'
import { ToolbarWrapper } from './components/ToolbarList.styles'
import ToolbarList from './components/ToolbarList'

interface Props {
  firstSource: string
  secondSource: string
  handleChangeSource: (source: 1 | 2, value: string) => void
  selectedSeenWithin: LastSeenWithin
  setSelectedSeenWithin: Dispatch<SetStateAction<LastSeenWithin>>
  handleAnalyzed: () => void
  fetchData: ({
    timePeriod,
    seenWithin,
    operatingSystem
  }: {
    timePeriod?: CPITimePeriod
    seenWithin?: LastSeenWithin
    operatingSystem?: OperatingSystem
  }) => Promise<void>
  instances: IntegrationInstance[]
  supportedIntegrations: ManifestIntegration[]
  optionsLoading: boolean
  fetchAnalysisData: ({
    integrationIds,
    operatingSystem,
    lastSeenWithin,
    searchTerm,
    filters
  }: {
    integrationIds: string[]
    operatingSystem?: OperatingSystem
    lastSeenWithin?: LastSeenWithin
    searchTerm?: string
    filters?: boolean[]
  }) => Promise<void>
  lastActiveSeenWithin: LastSeenWithin
  setLastActiveSeenWithin: Dispatch<SetStateAction<LastSeenWithin>>
  setActiveSeenWithin: Dispatch<SetStateAction<LastSeenWithin>>
  selectedTimePeriod: CPITimePeriod
  selectedOsTrends: OperatingSystem
}

const CoverageAnalyzerInitial: FC<Props> = ({
  firstSource,
  secondSource,
  handleChangeSource,
  selectedSeenWithin,
  setSelectedSeenWithin,
  instances,
  optionsLoading,
  handleAnalyzed,
  fetchData,
  fetchAnalysisData,
  supportedIntegrations,
  lastActiveSeenWithin,
  setLastActiveSeenWithin,
  setActiveSeenWithin,
  selectedTimePeriod,
  selectedOsTrends
}) => {
  const toolbarWrapperRef = useRef(null)

  return (
    <Container className="coverage-analyzer-initial">
      <Section className="section">
        <ToolbarWrapper className="controls-toolbar-wrapper" ref={toolbarWrapperRef}>
          <Typography className="controls-initial-header">
            To begin your analysis, first select two distinct data sources:
          </Typography>
          <ToolbarList
            firstSource={firstSource}
            setActiveSeenWithin={setActiveSeenWithin}
            secondSource={secondSource}
            handleChangeSource={handleChangeSource}
            selectedSeenWithin={selectedSeenWithin}
            setSelectedSeenWithin={setSelectedSeenWithin}
            fetchData={fetchData}
            instances={instances}
            optionsLoading={optionsLoading}
            fetchAnalysisData={fetchAnalysisData}
            handleAnalyzed={handleAnalyzed}
            supportedIntegrations={supportedIntegrations}
            loadingAnalysisData={false}
            fieldWidth="400"
            lastActiveSeenWithin={lastActiveSeenWithin}
            setLastActiveSeenWithin={setLastActiveSeenWithin}
            selectedTimePeriod={selectedTimePeriod}
            selectedOsTrends={selectedOsTrends}
          />
        </ToolbarWrapper>
      </Section>

      <Separator
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ height: (toolbarWrapperRef as any)?.current?.clientHeight || 337 }}
      />

      <Section className="section">
        <Box className="analysis-description-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="124" height="125" viewBox="0 0 124 125" fill="none">
            <circle cx="62" cy="62.5" r="61" stroke={theme.colors.primaryLight} strokeWidth="2" />
            <circle cx="62" cy="63" r="41" stroke={theme.colors.controlBorder} strokeWidth="2" />
            <circle cx="25" cy="25" r="25.5" transform="matrix(1 0 0 -1 37 88)" stroke={theme.colors.borderLow} />
            <line
              x1="97.4834"
              y1="29.4142"
              x2="63.4141"
              y2="63.2267"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <circle cx="62" cy="63" r="5" fill="#D9D9D9" />
          </svg>

          <Box className="content-container">
            <Typography className="pink-header-text">Analysis Example</Typography>
            <Typography className="header-text">EPP Coverage</Typography>
            <Box className="description-group">
              <Typography className="description-text">Compare a device management instance with</Typography>
              <Typography className="description-text">an endpoint instance to assess your EPP coverage</Typography>
            </Box>
          </Box>
        </Box>
      </Section>
    </Container>
  )
}

export default CoverageAnalyzerInitial
