import { useRef, forwardRef, Dispatch, SetStateAction, useEffect } from 'react'

/* Utils */
import { DataStatus, Label, SystemEventName, VisibleLabel } from '../../../../../../models'
import { CUSTOM_LABEL_ID } from '../../../utils'

/* Components */
import TrendsLabelCard from './components/TrendsLabelCard'

interface Props {
  healthValue?: string
  currentSeverityStatus: 'healthy' | 'problematic'
  handlePin: (labelId: string) => void
  handleEdit: (label: Label) => void
  getCardColors: (action: SystemEventName) => {
    background: string
    border: string
    titleColor: string
    icon: JSX.Element
  }
  visibleLabels: VisibleLabel[]
  width: number
  height: number
  margin: {
    top: number
    right: number
    bottom: number
    left: number
  }
  chartStatus?: DataStatus
  renderingChart: boolean
  setWidth: Dispatch<SetStateAction<number>>
  setHeight: Dispatch<SetStateAction<number>>
}

const TrendsChart = forwardRef<any, Props>(
  (
    {
      renderingChart,
      setWidth,
      setHeight,
      handlePin,
      handleEdit,
      getCardColors,
      visibleLabels,
      chartStatus,
      width,
      height,
      margin
    },
    ref
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { containerRef, svgRef } = ref
    const tooltipRef = useRef<HTMLDivElement | null>(null)
    const labelCardRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      if (!containerRef.current) return

      const observeTarget = containerRef.current
      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          setWidth(entry.contentRect.width)
          setHeight(entry.contentRect.height - 61)
        })
      })
      resizeObserver.observe(observeTarget)

      return () => {
        resizeObserver.unobserve(observeTarget)
      }
    }, [containerRef])

    return (
      <>
        <div ref={tooltipRef} className="data-point-tooltip" />
        {!renderingChart &&
          chartStatus &&
          chartStatus === DataStatus.Ready &&
          visibleLabels.length > 0 &&
          visibleLabels.map((thisLabel, index) => (
            <TrendsLabelCard
              ref={labelCardRef}
              key={index}
              width={width}
              isCustomLabel={thisLabel.action === CUSTOM_LABEL_ID}
              label={thisLabel}
              handlePin={handlePin}
              handleEdit={handleEdit}
              yPosition={index + 1}
              getCardColors={getCardColors}
            />
          ))}
        <svg
          ref={svgRef}
          id="svg-d3"
          className="svg-d3"
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        />
      </>
    )
  }
)

export default TrendsChart
