/* eslint camelcase: 0 */
import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 0

export const ChipsContainerTrendsDescription = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  position: relative;

  @media (max-width: 1440px) {
    margin-top: ${theme.spacing.small}px;
  }

  .chips-title {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.baseColors.greyscale[80]};
    margin-bottom: ${theme.spacing.xSmall}px;
    white-space: nowrap;
    text-transform: capitalize;
  }

  .MuiChip-root {
    background: transparent;
    border-radius: ${theme.attributes.borderRadius.secondary};
    border: 1px solid ${theme.colors.controlBorder};
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.5px;
    color: ${theme.colors.white};
    text-transform: capitalize;

    &.selected {
      color: ${theme.baseColors.primary[20]};
      border: none;
      background: ${theme.colors.textPrimary};
    }

    &:hover {
      background: ${theme.colors.textPrimary};!important;
      color: ${theme.baseColors.primary[20]} !important;
      opacity: 0.9 !important;
    }
  }

  .month-picker-container {
    position: absolute;
    top: 148px;
    left: 169px;
    z-index: 10000;
    width: 420px;
    display: none;
    border-radius: ${theme.attributes.borderRadius.small} ${theme.attributes.borderRadius.small} 0 0;
    background: ${theme.colors.primaryLight};
    transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.isopen {
      padding: 0;

      > div:first-of-type {
        padding: ${theme.spacing.small}px ${theme.spacing.md}px ${theme.spacing.half}px;
        justify-content: center;
        gap: 170px;

        & [class*='mx-4'] {
          &:before {
            width: 1px;
            height: 44px;
            background-color: #614964;
            position: absolute;
            left: 207.5px;
            top: 0;
            content: '';
            display: block;
          }
        }

        > div:first-of-type,
        > div:last-of-type {
          display: none;
        }
      }
    }

    p {
      color: ${theme.baseColors.greyscale[80]};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    & [class*='gap-4'] {
      border-bottom: 1px solid #614964;

      > div:first-of-type {
        border-right: 1px solid #614964;
      }
    }

    .month-picker-month {
      color: #fbd8f5;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;

      &[class*='variant-start'],
      &[class*='variant-end'] {
        background: ${theme.colors.textPrimary};
        color: ${theme.baseColors.primary[20]};
      }

      &[class*='variant-selected'] {
        background: #614964;
        color: #fbd8f5;
      }

      &[class*='variant-selected'] {
        color: #fbd8f5;
      }

      &[class*='variant-default'] {
        &:hover {
          background: transparent;
        }
      }
    }

    &.isopen {
      display: flex;
    }
  }
`

export const TrendsChartDescriptionFormControl = styled(FormControl)<{
  isselected?: string
  is_opened: string
  is_disabled: string
}>`
  border: 1px solid ${theme.colors.controlBorder};
  background: transparent;
  outline: none;
  min-width: 113px;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.large};

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
  }

  &:hover {
    border-radius: ${theme.attributes.borderRadius.large};
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: ${theme.colors.textPrimary};
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: ${theme.spacing.small}px;

    path {
      fill: ${theme.colors.textPrimary};
    }
  }

  ${({ is_disabled }) =>
    is_disabled &&
    is_disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      
      &:hover {
        border-radius: ${theme.attributes.borderRadius.large};
        background: transparent;
    
        .MuiOutlinedInput-root {
          color: ${theme.colors.textPrimary};
        }
      }
      
       .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};

  ${({ is_opened }) =>
    is_opened &&
    is_opened === 'true' &&
    `
      border: 1px solid ${theme.colors.textPrimary};
      background: rgba(208, 188, 255, 0.16);
      
      .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: rgba(234, 221, 255, 0.08);
    color: ${theme.colors.textPrimary} !important;
    border: 1px solid ${theme.colors.textPrimary} !important;
    
    .MuiOutlinedInput-root {
      border: 1px solid ${theme.colors.textPrimary} !important;
    }
  `};
`

export const TrendsChartDescriptionSelect = styled(Select)<{ isselected?: string; noborder?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};
  gap: ${theme.spacing.xSmall}px;

  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: ${theme.baseColors.greyscale[80]};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: capitalize;

  ${({ isselected }) =>
    isselected === 'true'
      ? `
      background: rgba(234, 221, 255, 0.08);
      color: ${theme.colors.textPrimary} !important;
    
      .MuiOutlinedInput-root {
        .MuiSelect-select {
          color: ${theme.colors.textPrimary} !important;
        }
      }    
  `
      : `     
 .MuiOutlinedInput-input {
    color: ${theme.colors.textPrimary} !important;
  }
  `};

  .MuiOutlinedInput-input {
    padding-right: ${theme.spacing.xLarge}px !important;
  }

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    color: ${theme.baseColors.greyscale[80]};
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.primaryLight,
      boxShadow: theme.attributes.boxShadow.chipCircle,
      borderRadius: theme.attributes.borderRadius.secondary,
      minWidth: '167px',
      width: '167px',

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: '167px',
        height: '48px',
        paddingLeft: `${theme.spacing.small}px`,
        paddingRight: `${theme.spacing.small}px`,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.08)'
        },

        '.MuiCheckbox-root': {
          marginRight: `${theme.spacing.xSmall}px`,

          '&.Mui-checked': {
            svg: {
              path: {
                fill: theme.colors.textPrimary
              }
            }
          },

          '&:hover': {
            borderRadius: theme.attributes.borderRadius.large,
            background: 'rgba(202, 196, 208, 0.12)'
          },

          svg: {
            width: '18px',
            height: '18px',

            path: {
              fill: theme.baseColors.greyscale[80]
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: '24px',
          letterSpacing: '0.5px',
          color: theme.baseColors.greyscale[80],
          fontStyle: 'normal',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      },

      '.Mui-selected': {
        backgroundColor: 'rgba(208, 188, 255, 0.16) !important'
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: theme.attributes.borderRadius.secondary
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
