import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const CardSkeletonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing.small}px;
  width: 100%;
`

export const CardSkeleton = styled(Box)`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.primaryLight};
    border-radius: ${theme.attributes.borderRadius.small};

    &:after {
      background: ${theme.colors.ssmCardSkeleton};
    }
  }
`
