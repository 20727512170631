import { Dispatch, FC, SetStateAction, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Box, ClickAwayListener, IconButton, InputAdornment, Skeleton, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAutocomplete } from '@mui/base/useAutocomplete'
import debounce from 'lodash.debounce'
import dayjs from 'dayjs'
import theme from 'theme'

/* Utils */
import { DrawerProps, SSM_EMPTY_BANNER_CLOSED } from '../SSM'
import {
  AddVendorPayload,
  SSMAssetType,
  SSMGroupedData,
  SSMNistPillar,
  SSMResponseProduct,
  SSMResponseProductExtended,
  Vendor
} from '../../../../models'
import { addVendorToRecentSearches, CostDistribution } from '../securityStackMap.utils'
import { notification } from '../../../../store/notifications/notificationSlice'
import { useAppDispatch } from '../../../../store/hooks'
import axios from '../../../../lib/axios'
import { dateFormat } from '../../../../lib/utils'

/* Components */
import { Content, Drawer, Wrapper } from './ssmSettingsDrawer.styles'
import { PlusIcon } from '../../../components/svg'
import { ThemeButton } from '../../../components/buttons'
import AssetAccordion from './AssetAccordion'
import {
  AutocompleteContainer,
  NoIntegrationLogo,
  NoResultsList,
  Popper,
  ResultListItem,
  ResultsList,
  SearchVendorsContainer,
  SearchVendorsInputWrapper,
  SearchVendorsResultsContainer
} from './searchVendors.styles'
import { MaxProductsTooltip } from './MaxProductsTooltip'
import AddCustomVendor from './add-custom-vendor/AddCustomVendor'
import { SSMCardSkeleton, SSMCardSkeletonWrapper } from './ssmCard.styles'
import { IconTooltip } from './IconTooltip'
import ListItemDisabled from './ListItemDisabled'

const emptyValue = {
  display_name: '',
  uuid: '',
  logo_path: '',
  name: '',
  url: '',
  created_at: '',
  created_by: null,
  description: '',
  is_product: false,
  tenant_id: null,
  vendor_uuid: '',
  verified: false
}

interface Props {
  drawerOpen: boolean
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  drawerProps: DrawerProps
  groupedData: SSMGroupedData
  fetchAndGroupData: any
  updateDrawerProps: (nist: SSMNistPillar, asset: SSMAssetType, assets: SSMResponseProduct[]) => void
  setConfirmRemoveProps: any
  setRemoving: Dispatch<SetStateAction<boolean>>
  setGroupedData: Dispatch<SetStateAction<SSMGroupedData>>
  expanded: string | false
  setExpanded: Dispatch<SetStateAction<string | false>>
  allProducts: SSMResponseProduct[]
  removing: boolean
  maxCellValue: number
  setBudgetBreakpoints: Dispatch<SetStateAction<number[]>>
  setEmptyStateBanner: Dispatch<SetStateAction<boolean>>
}

const SSMSettingsDrawer: FC<Props> = ({
  drawerOpen,
  updateDrawerProps,
  fetchAndGroupData,
  groupedData,
  setDrawerOpen,
  drawerProps,
  setConfirmRemoveProps,
  setRemoving,
  setGroupedData,
  expanded,
  setExpanded,
  allProducts,
  removing,
  maxCellValue,
  setBudgetBreakpoints,
  setEmptyStateBanner
}) => {
  const dispatch = useAppDispatch()
  const { nist, asset } = drawerProps
  const addButtonRef = useRef<HTMLButtonElement>(null)
  const [addProductOpen, setAddProductOpen] = useState(false)
  const [isAddingCustom, setIsAddingCustom] = useState(false)
  const [customVendorPopper, setCustomVendorPopper] = useState(false)
  const [vendors, setVendors] = useState<Vendor[]>([emptyValue])
  const [value, setValue] = useState<Vendor>(emptyValue)
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [customVendorOptions, setCustomVendorOptions] = useState<Vendor[]>([])
  const [newProductLoading, setNewProductLoading] = useState(false)
  const drawerPropsIds = useMemo(() => drawerProps.assets.map((n) => n.uuid), [drawerProps])
  const [customProductName, setCustomProductName] = useState('')
  const recentProductsStringified =
    typeof window !== 'undefined' ? localStorage.getItem('recently_added_vendors') : null
  const recentProducts = recentProductsStringified ? JSON.parse(recentProductsStringified) : null
  const thisCell = useMemo(
    () => groupedData.find((n) => n.asset === drawerProps.asset && n.nist === drawerProps.nist),
    [groupedData, drawerProps]
  )
  const [backspacingInput, setBackspacingInput] = useState(false)
  const [initialSearched, setInitialSearched] = useState(false)

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleCloseAdd = (event: Event | React.SyntheticEvent) => {
    if (addButtonRef.current && addButtonRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setInitialSearched(false)
    setCustomVendorPopper(false)
    setAddProductOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setAddProductOpen(false)
    } else if (event.key === 'Escape') {
      setAddProductOpen(false)
    }
  }

  const getIntegrationLogo = (urlPath: string) => {
    if (urlPath.includes('https://')) return urlPath

    const logoHost = process.env.REACT_APP_LOGO_HOST
    const url = `${logoHost}/${urlPath}`

    return urlPath ? url : 'default-vendor-logo.svg'
  }

  const handleSearchVendors = async (newValue: string | null, drawerProps: DrawerProps): Promise<void> => {
    setInputValue(newValue || '')

    if (!newValue) {
      setValue(emptyValue)
      setVendors([emptyValue])
      setLoading(false)

      return
    }

    if (newValue.length < 2) return

    try {
      const res = await axios.get(`/api/v3/vendors_products/search?name=${newValue}`)

      if (res.status === 200) {
        const thisCellProductIds = drawerProps.assets.map((n: any) => n.uuid)
        const d = res.data.data
        const products =
          d.length > 0
            ? d.map((n: any) => (thisCellProductIds.includes(n.uuid) ? { ...n, existsInCell: true } : n))
            : [emptyValue]

        setVendors(products.length > 0 ? products : [emptyValue])
      }
    } catch (e) {
      console.error('Failed searching vendors', e)
      setVendors([emptyValue])
    } finally {
      setLoading(false)
    }
  }

  const debouncedSearch = useMemo(() => debounce(handleSearchVendors, 200), [])

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, focused } = useAutocomplete({
    id: 'search-vendors-input',
    options: vendors,
    autoComplete: true,
    getOptionLabel: (option) => option.display_name,
    value,
    onChange: (e, newValue) => setValue(newValue || emptyValue),
    inputValue,
    onInputChange: (e, newInputValue) => setInputValue(newInputValue),
    blurOnSelect: true,
    isOptionEqualToValue: (option) => option.display_name === value.display_name
  })

  useEffect(() => {
    let active = true
    if (backspacingInput) return

    if (inputValue === '') {
      setVendors(value ? [value] : [emptyValue])
      setInputValue('')
    }

    if (active && inputValue.length >= 2) {
      setLoading(true)
      setInitialSearched(true)
    }
    debouncedSearch(inputValue, drawerProps)

    return () => {
      active = false
    }
  }, [value, inputValue])

  const handleToggleAdd = () => {
    setAddProductOpen((addProductOpen) => !addProductOpen)
    setTimeout(() => {
      setInitialSearched(false)
      setCustomVendorPopper(false)
      setCustomProductName('')
      setCustomVendorOptions([])

      const ref = getInputProps().ref
      if (ref && (ref as any).current) {
        ;(ref as any).current.focus()
      }
    }, 50)
  }

  const updateCostDistribution = (newValue: CostDistribution, asset: SSMResponseProduct) => {
    if (window && typeof window !== 'undefined') {
      const costDistribution = localStorage.getItem('cost_distribution')
      const costDistributionArr = costDistribution ? JSON.parse(costDistribution) : []
      const cdObj = {
        ...asset,
        costDistributionValue: newValue
      }
      const foundRes = costDistributionArr.find((n: any) => n.uuid === cdObj.uuid)
      const cdArr = foundRes
        ? [...costDistributionArr.filter((n: any) => n.uuid !== cdObj.uuid), cdObj]
        : [...costDistributionArr, cdObj]
      localStorage.setItem('cost_distribution', JSON.stringify(cdArr))
    }
  }

  const handleAddVendor = async (
    vendorId: string,
    nist: SSMNistPillar,
    asset: SSMAssetType,
    recentProductsAdd?: boolean
  ) => {
    setNewProductLoading(true)
    try {
      const vendorExists = allProducts.find((n) => n.uuid === vendorId)

      setEmptyStateBanner(false)
      localStorage.setItem(SSM_EMPTY_BANNER_CLOSED, 'false')
      if (vendorExists) {
        addVendorToRecentSearches(vendorExists)
        setNewProductLoading(false)
      }
      const cd = localStorage.getItem('cost_distribution')
      let vendorcd = vendorExists ? CostDistribution.Even : CostDistribution.Manual
      if (cd) {
        const parsed = JSON.parse(cd)
        if (Array.isArray(parsed) && parsed.length > 0) {
          const found = parsed.find((n) => n.uuid === vendorId)
          if (found) {
            vendorcd = found.costDistributionValue || CostDistribution.Even
          }
        }
      }
      if (vendorExists) {
        updateCostDistribution(vendorcd, vendorExists)
      }

      const filteringList = recentProductsAdd ? recentProducts : vendors

      const updatedGroupedData = groupedData.map((group) => {
        if (group.nist === nist && group.asset === asset) {
          const foundProduct = filteringList.find((n: any) => n.uuid === vendorId || n.vendor_uuid === vendorId)

          const startDate = (foundProduct as any)?.start_date || new Date().toISOString()
          const endDate = dayjs(startDate).add(1, 'year').format(dateFormat.customLabel)
          const endDateFormatted = new Date(endDate).toISOString()

          const product = {
            ...foundProduct,
            cost: 0,
            start_date: startDate,
            end_date: endDateFormatted,
            tilesTotal: (foundProduct as any)?.tilesTotal || 0
          }

          const updatedItems = [...group.items, product]
          updateDrawerProps(nist, asset, updatedItems as any)
          return {
            ...group,
            items: updatedItems
          }
        }
        return group
      })
      setGroupedData(updatedGroupedData as any)
      setCustomVendorPopper(false)
      setAddProductOpen(false)
      setInitialSearched(false)
      dispatch(
        notification({
          open: true,
          variant: 'success',
          message: 'Vendor added successfully.'
        })
      )

      const payload: AddVendorPayload = {
        itemId: vendorId,
        nist: nist.toLowerCase(),
        asset: asset.toLowerCase()
      }
      const res = await axios.post('/api/v3/ssm', payload)

      if (res.status === 201) {
        const products = await fetchAndGroupData()
        const newProduct = products.find((n: any) => n.uuid === vendorId)

        if (!vendorExists) {
          addVendorToRecentSearches(newProduct)
        }
      }
    } catch (e) {
      console.error('Failed to add vendor', e)
      let message = 'Failed to add vendor.'
      if ((e as any).response?.data?.message) {
        message = (e as any).response.data.message
      }
      localStorage.setItem(SSM_EMPTY_BANNER_CLOSED, 'true')
      setEmptyStateBanner(true)
      await fetchAndGroupData()
      dispatch(
        notification({
          open: true,
          variant: 'error',
          message
        })
      )
    } finally {
      setNewProductLoading(false)
    }
  }

  const handleAddCustomVendor = async (vendorId: string, nist: SSMNistPillar, asset: SSMAssetType) => {
    setIsAddingCustom(true)

    const vendorExists = allProducts.find((n) => n.uuid === vendorId)
    const selectedVendor = customVendorOptions && customVendorOptions.length > 0 ? customVendorOptions[0] : null
    if (vendorExists) {
      updateCostDistribution(CostDistribution.Even, vendorExists)
    }
    setEmptyStateBanner(false)
    localStorage.setItem(SSM_EMPTY_BANNER_CLOSED, 'false')

    try {
      const payload: AddVendorPayload = {
        itemId: vendorId,
        nist: nist.toLowerCase(),
        asset: asset.toLowerCase()
      }
      const vendorProductPayload = {
        vendorId,
        productName: customProductName
      }
      const updatedGroupedData = groupedData.map((group) => {
        if (group.nist === nist && group.asset === asset) {
          const foundProduct = customVendorOptions.find((n) => n.uuid === vendorId || n.vendor_uuid === vendorId)

          const startDate = (foundProduct as any)?.start_date || new Date().toISOString()
          const endDate = dayjs(startDate).add(1, 'year').format(dateFormat.customLabel)
          const endDateFormatted = new Date(endDate).toISOString()

          const product = {
            ...foundProduct,
            cost: 0,
            start_date: startDate,
            end_date: endDateFormatted,
            tilesTotal: 0
          }

          const updatedItems = [...group.items, product]
          updateDrawerProps(nist, asset, updatedItems as any)
          return {
            ...group,
            items: updatedItems
          }
        }
        return group
      })

      setGroupedData(updatedGroupedData as any)
      setCustomVendorPopper(false)
      setAddProductOpen(false)
      setInitialSearched(false)
      dispatch(
        notification({
          open: true,
          variant: 'success',
          message: 'Vendor added successfully.'
        })
      )

      if (selectedVendor && selectedVendor.created_at) {
        addVendorToRecentSearches(selectedVendor)
        const res2 = await axios.post('/api/v3/ssm', payload)

        if (res2.status.toString().startsWith('20')) {
          await fetchAndGroupData()
        }
      } else {
        const res = await axios.post('/api/v3/vendors_products/vendor', vendorProductPayload)

        if (res.status.toString().startsWith('20')) {
          if (vendorExists) {
            addVendorToRecentSearches(vendorExists)
          } else if (selectedVendor && selectedVendor.uuid && selectedVendor.display_name) {
            addVendorToRecentSearches(selectedVendor)
          }

          const res2 = await axios.post('/api/v3/ssm', payload)

          if (res2.status.toString().startsWith('20')) {
            await fetchAndGroupData()
          }
        }
      }
    } catch (e) {
      console.error('Failed to add vendor', e)
      let message = 'Failed to add vendor.'
      if ((e as any).response?.data?.message) {
        message = (e as any).response.data.message
      }
      localStorage.setItem(SSM_EMPTY_BANNER_CLOSED, 'true')
      setEmptyStateBanner(true)
      await fetchAndGroupData()
      dispatch(
        notification({
          open: true,
          variant: 'error',
          message
        })
      )
    } finally {
      setIsAddingCustom(false)
      setCustomVendorOptions([])
    }
  }

  const handleClearSearch = () => {
    if (!inputValue) {
      return
    }

    setInputValue('')
    setVendors([emptyValue])
    setValue(emptyValue)
    setLoading(false)
  }

  const closeDrawer = () => {
    setDrawerOpen(false)

    setTimeout(() => {
      setExpanded('')
      setAddProductOpen(false)
      setCustomVendorPopper(false)
    }, 300)
  }

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
      <Wrapper>
        <Box className="header">
          <Typography>
            {nist}/{asset}
          </Typography>
          <IconButton className="close-icon" onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="sub-header">
          <div style={{ position: 'relative' }}>
            {drawerProps.assets.length > 5 ? (
              <MaxProductsTooltip
                title={
                  <>
                    <Box className="tooltip-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                        className="arrow-up"
                      >
                        <path d="M7.49998 0L14.4282 12H0.571777L7.49998 0Z" fill={theme.colors.controlBorder} />
                      </svg>
                      <Typography className="tooltip-text">Cell limit reached: 6 products</Typography>
                    </Box>
                  </>
                }
              >
                <Box className="disabled-add-button">
                  <>
                    <PlusIcon />
                    Add Product
                  </>
                </Box>
              </MaxProductsTooltip>
            ) : (
              <ThemeButton
                ref={addButtonRef}
                themevariant="primary"
                className="add-button"
                onClick={handleToggleAdd}
                id="composition-button"
                aria-controls={addProductOpen ? 'composition-menu' : undefined}
                aria-expanded={addProductOpen ? 'true' : undefined}
                aria-haspopup="true"
                sx={
                  addProductOpen
                    ? {
                        background: `${theme.baseColors.primary[20]} !important`,
                        color: `${theme.colors.white} !important`,
                        border: 'none !important',

                        svg: {
                          path: {
                            fill: `${theme.colors.white} !important`
                          }
                        }
                      }
                    : null
                }
              >
                <>
                  <PlusIcon />
                  Add Product
                </>
              </ThemeButton>
            )}
            <Popper
              open={addProductOpen}
              anchorEl={addButtonRef.current}
              placement="bottom-start"
              transition
              disablePortal
            >
              <>
                {customVendorPopper ? (
                  <ClickAwayListener
                    onClickAway={() => {
                      setAddProductOpen(false)
                      setCustomVendorPopper(false)
                      setCustomProductName('')
                      setCustomVendorOptions([])
                      setInitialSearched(false)
                    }}
                  >
                    <Box>
                      <AddCustomVendor
                        handleAddCustomVendor={handleAddCustomVendor}
                        nist={nist as SSMNistPillar}
                        asset={asset as SSMAssetType}
                        isAddingCustom={isAddingCustom}
                        customVendorOptions={customVendorOptions}
                        setCustomVendorOptions={setCustomVendorOptions}
                        handleClose={() => {
                          setCustomVendorPopper(false)
                          setAddProductOpen(false)
                          setCustomProductName('')
                          setCustomVendorOptions([])
                        }}
                        customProductName={customProductName}
                        setCustomProductName={setCustomProductName}
                        drawerProps={drawerProps}
                      />
                    </Box>
                  </ClickAwayListener>
                ) : (
                  <ClickAwayListener onClickAway={handleCloseAdd}>
                    <SearchVendorsContainer
                      height={
                        (initialSearched && !loading && vendors.length === 1) ||
                        (initialSearched && !loading && inputValue === '' && vendors.length > 1)
                          ? '228px'
                          : '311px'
                      }
                    >
                      <AutocompleteContainer>
                        <SearchVendorsInputWrapper
                          {...getRootProps()}
                          className={focused ? 'focused' : ''}
                          hasinputvalue={inputValue.length > 0 ? 'true' : 'false'}
                        >
                          <TextField
                            variant="outlined"
                            color="primary"
                            size="medium"
                            label=""
                            inputProps={{ ...getInputProps() }}
                            InputProps={{
                              onKeyDown: (e: any) => {
                                if (e.keyCode === 8) {
                                  e.preventDefault()
                                  setBackspacingInput(true)
                                  if (inputValue.length <= 1) {
                                    setVendors([emptyValue])
                                  }
                                  setInputValue((pre) => pre.substring(0, pre.length - 1))
                                }
                              },
                              onKeyUp: (e: any) => {
                                if (e.keyCode === 8) {
                                  setBackspacingInput(false)
                                  if (inputValue.length >= 2) {
                                    setLoading(true)
                                    debouncedSearch(inputValue, drawerProps)
                                  }
                                }
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.2939 14.5786H15.3905L15.0703 14.2699C16.191 12.9663 16.8656 11.2739 16.8656 9.43282C16.8656 5.32762 13.538 2 9.43282 2C5.32762 2 2 5.32762 2 9.43282C2 13.538 5.32762 16.8656 9.43282 16.8656C11.2739 16.8656 12.9663 16.191 14.2699 15.0703L14.5786 15.3905V16.2939L20.2962 22L22 20.2962L16.2939 14.5786ZM9.43282 14.5786C6.58548 14.5786 4.28702 12.2802 4.28702 9.43282C4.28702 6.58548 6.58548 4.28702 9.43282 4.28702C12.2802 4.28702 14.5786 6.58548 14.5786 9.43282C14.5786 12.2802 12.2802 14.5786 9.43282 14.5786Z"
                                      fill={theme.colors.white}
                                    />
                                  </svg>
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end" onClick={handleClearSearch}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M20 5.61143L18.3886 4L12 10.3886L5.61143 4L4 5.61143L10.3886 12L4 18.3886L5.61143 20L12 13.6114L18.3886 20L20 18.3886L13.6114 12L20 5.61143Z"
                                      fill={theme.colors.textSecondaryLow}
                                    />
                                  </svg>
                                </InputAdornment>
                              )
                            }}
                          />
                        </SearchVendorsInputWrapper>
                        {/* Loading */}
                        {focused && loading && (
                          <SearchVendorsResultsContainer>
                            <SSMCardSkeletonWrapper sx={{ gap: `${theme.spacing.xSmall}px !important` }}>
                              {Array.from(Array(5).keys()).map((n) => (
                                <SSMCardSkeleton sx={{ height: '40px !important' }} key={n}>
                                  <Skeleton
                                    variant="rectangular"
                                    component="div"
                                    animation="wave"
                                    width="100%"
                                    height="100%"
                                    className="card-skeleton-inner"
                                  />
                                </SSMCardSkeleton>
                              ))}
                            </SSMCardSkeletonWrapper>
                          </SearchVendorsResultsContainer>
                        )}
                        {/* No results found */}
                        {((initialSearched && !loading && inputValue === '' && vendors.length > 1) ||
                          (initialSearched &&
                            !loading &&
                            inputValue.length > 1 &&
                            vendors.length === 1 &&
                            vendors[0].display_name === '')) && (
                          <SearchVendorsResultsContainer>
                            <Typography className="search-vendors-results-text">Results (0)</Typography>
                            <Box className="search-vendors-empty-wrapper">
                              <Typography className="search-vendors-empty-title">No results found :(</Typography>
                            </Box>
                            <NoResultsList {...getListboxProps()} hideborder="true" length={0}>
                              <Typography className="search-vendors-empty-text first">
                                1. Check your spelling or try a new domain search
                              </Typography>
                              <Typography className="search-vendors-empty-text">
                                2. Click{' '}
                                <span
                                  className="here-text"
                                  onClick={() => {
                                    setCustomVendorPopper(true)
                                  }}
                                >
                                  here
                                </span>{' '}
                                to add a new vendor/product
                              </Typography>
                            </NoResultsList>
                          </SearchVendorsResultsContainer>
                        )}
                        {/* Results */}
                        {(!loading &&
                          vendors.length === 1 &&
                          (vendors as Vendor[])[0].display_name === '' &&
                          (vendors as Vendor[])[0].name === '') ||
                          (vendors.length > 0 && !loading && (
                            <SearchVendorsResultsContainer>
                              <Typography className="search-vendors-results-text">
                                Results ({vendors.length})
                              </Typography>
                              <ResultsList {...getListboxProps()} items={vendors.length} onKeyDown={handleListKeyDown}>
                                {(vendors as Vendor[]).map((option, index) => {
                                  if (!option.display_name || !option.name) return null
                                  const vendor = option.is_product ? option.vendor : option
                                  const product = option.is_product ? option : null

                                  if (option.existsInCell) {
                                    const optionProps = {
                                      ...getOptionProps({ option, index })
                                    }

                                    return (
                                      <ListItemDisabled
                                        key={index}
                                        option={option}
                                        optionProps={optionProps}
                                        getIntegrationLogo={getIntegrationLogo}
                                        vendor={vendor}
                                        product={product}
                                      />
                                    )
                                  }
                                  return (
                                    <ResultListItem
                                      {...getOptionProps({ option, index })}
                                      key={index}
                                      onClick={() => {
                                        handleAddVendor(
                                          option.uuid,
                                          drawerProps.nist as SSMNistPillar,
                                          drawerProps.asset as SSMAssetType
                                        )
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <IconTooltip
                                          placement="left-start"
                                          PopperProps={{
                                            sx: {
                                              marginTop: `-${theme.spacing.xSmall}px !important`,
                                              marginRight: '7px !important'
                                            }
                                          }}
                                          title={
                                            <>
                                              <Box className="tooltip-wrapper">
                                                <svg
                                                  width="12"
                                                  height="14"
                                                  viewBox="0 0 12 14"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="arrow-right"
                                                >
                                                  <path
                                                    d="M12 7.0718L0 0.143593V14L12 7.0718Z"
                                                    fill={theme.colors.controlBorder}
                                                  />
                                                </svg>
                                                {option.is_product && option.vendor && (
                                                  <Typography className="tooltip-text">
                                                    {option.vendor.display_name}
                                                  </Typography>
                                                )}
                                                <Typography
                                                  className={
                                                    option.is_product && option.vendor
                                                      ? 'tooltip-subtext'
                                                      : 'tooltip-text'
                                                  }
                                                >
                                                  {option.display_name}
                                                </Typography>
                                              </Box>
                                            </>
                                          }
                                        >
                                          <Box className="list-item-wrapper">
                                            {option.logo_path &&
                                            option.logo_path.length > 0 &&
                                            option.logo_path !== 'N/A' ? (
                                              <img
                                                src={getIntegrationLogo(option.logo_path)}
                                                width="24px"
                                                height="24px"
                                                alt={option.display_name}
                                              />
                                            ) : (
                                              <NoIntegrationLogo>
                                                <Typography className="no-integration-logo-letter">
                                                  {option.vendor && option.vendor.display_name
                                                    ? option.vendor.display_name[0]
                                                    : option.display_name[0]}
                                                </Typography>
                                              </NoIntegrationLogo>
                                            )}

                                            <Typography className="option-text">
                                              {vendor?.display_name || ''}
                                              {product && ` ${product.display_name}`}
                                            </Typography>
                                          </Box>
                                        </IconTooltip>
                                      </div>
                                    </ResultListItem>
                                  )
                                })}
                              </ResultsList>
                            </SearchVendorsResultsContainer>
                          ))}

                        {/* Recent Products Results */}
                        {(!initialSearched &&
                          !loading &&
                          vendors.length === 1 &&
                          (vendors as Vendor[])[0].display_name === '' &&
                          (vendors as Vendor[])[0].name === '') ||
                        (initialSearched &&
                          inputValue.length < 2 &&
                          !loading &&
                          vendors.length === 1 &&
                          vendors[0].display_name === '') ? (
                          <>
                            {recentProducts && recentProducts.length > 0 ? (
                              <SearchVendorsResultsContainer>
                                <Typography className="search-vendors-results-text">
                                  Recents ({recentProducts.length})
                                </Typography>
                                <ResultsList items={recentProducts.length}>
                                  {(recentProducts as Vendor[]).map((option, index) => {
                                    if (!option.display_name && option.name) return null
                                    const vendor = option.is_product ? option.vendor : option
                                    const product = option.is_product ? option : null
                                    const productExistsInCell = drawerProps.assets.find((n) => n.uuid === option.uuid)

                                    if (productExistsInCell) {
                                      const optionProps = {
                                        ...getOptionProps({ option, index })
                                      }

                                      return (
                                        <ListItemDisabled
                                          key={index}
                                          option={option}
                                          optionProps={optionProps}
                                          getIntegrationLogo={getIntegrationLogo}
                                          vendor={vendor}
                                          product={product}
                                        />
                                      )
                                    }
                                    return (
                                      <ResultListItem
                                        {...getOptionProps({ option, index })}
                                        key={index}
                                        onClick={() => {
                                          if (!drawerPropsIds.includes(option.uuid)) {
                                            handleAddVendor(
                                              option.uuid,
                                              drawerProps.nist as SSMNistPillar,
                                              drawerProps.asset as SSMAssetType,
                                              true
                                            )
                                          }
                                        }}
                                      >
                                        <div style={{ position: 'relative' }}>
                                          <IconTooltip
                                            placement="left-start"
                                            PopperProps={{
                                              sx: {
                                                marginTop: '-8px !important',
                                                marginRight: '7px !important'
                                              }
                                            }}
                                            title={
                                              <>
                                                <Box className="tooltip-wrapper">
                                                  <svg
                                                    width="12"
                                                    height="14"
                                                    viewBox="0 0 12 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="arrow-right"
                                                  >
                                                    <path
                                                      d="M12 7.0718L0 0.143593V14L12 7.0718Z"
                                                      fill={theme.colors.controlBorder}
                                                    />
                                                  </svg>
                                                  {option.is_product && option.vendor && (
                                                    <Typography className="tooltip-text">
                                                      {option.vendor.display_name}
                                                    </Typography>
                                                  )}
                                                  <Typography
                                                    className={
                                                      option.is_product && option.vendor
                                                        ? 'tooltip-subtext'
                                                        : 'tooltip-text'
                                                    }
                                                  >
                                                    {option.display_name}
                                                  </Typography>
                                                </Box>
                                              </>
                                            }
                                          >
                                            <Box className="list-item-wrapper">
                                              {option.logo_path &&
                                              option.logo_path.length > 0 &&
                                              !option.logo_path.includes('N/A') ? (
                                                <img
                                                  src={getIntegrationLogo(option.logo_path)}
                                                  width="24px"
                                                  height="24px"
                                                  alt={option.display_name}
                                                />
                                              ) : (
                                                <NoIntegrationLogo>
                                                  <Typography className="no-integration-logo-letter">
                                                    {option.vendor && option.vendor.display_name
                                                      ? option.vendor.display_name[0]
                                                      : option.display_name
                                                      ? option.display_name[0]
                                                      : ''}
                                                  </Typography>
                                                </NoIntegrationLogo>
                                              )}
                                              <Typography className="option-text">
                                                {vendor?.display_name || ''}
                                                {product && ` ${product.display_name || ''}`}
                                              </Typography>
                                            </Box>
                                          </IconTooltip>
                                        </div>
                                      </ResultListItem>
                                    )
                                  })}
                                </ResultsList>
                              </SearchVendorsResultsContainer>
                            ) : (
                              <>
                                {allProducts.length > 0 && (
                                  <SearchVendorsResultsContainer>
                                    <Typography className="search-vendors-results-text">
                                      Recents ({allProducts.filter((n, i) => i < 5).length})
                                    </Typography>
                                    <ResultsList items={allProducts.filter((n, i) => i < 5).length}>
                                      {(allProducts as Vendor[])
                                        .filter((n, i) => i < 5)
                                        .map((option, index) => {
                                          if (!option.display_name && option.name) return null
                                          const vendor = option.is_product ? option.vendor : option
                                          const product = option.is_product ? option : null
                                          const productExistsInCell = drawerProps.assets.find(
                                            (n) => n.uuid === option.uuid
                                          )

                                          if (productExistsInCell) {
                                            const optionProps = {
                                              ...getOptionProps({ option, index })
                                            }

                                            return (
                                              <ListItemDisabled
                                                key={index}
                                                option={option}
                                                optionProps={optionProps}
                                                getIntegrationLogo={getIntegrationLogo}
                                                vendor={vendor}
                                                product={product}
                                              />
                                            )
                                          }
                                          return (
                                            <ResultListItem
                                              {...getOptionProps({ option, index })}
                                              key={index}
                                              onClick={() => {
                                                if (!drawerPropsIds.includes(option.uuid)) {
                                                  handleAddVendor(
                                                    option.uuid,
                                                    drawerProps.nist as SSMNistPillar,
                                                    drawerProps.asset as SSMAssetType,
                                                    true
                                                  )
                                                }
                                              }}
                                            >
                                              <>
                                                <IconTooltip
                                                  placement="left-start"
                                                  PopperProps={{
                                                    sx: {
                                                      marginTop: '-8px !important',
                                                      marginRight: '7px !important'
                                                    }
                                                  }}
                                                  title={
                                                    <>
                                                      <Box className="tooltip-wrapper">
                                                        <svg
                                                          width="12"
                                                          height="14"
                                                          viewBox="0 0 12 14"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          className="arrow-right"
                                                        >
                                                          <path
                                                            d="M12 7.0718L0 0.143593V14L12 7.0718Z"
                                                            fill={theme.colors.controlBorder}
                                                          />
                                                        </svg>
                                                        {option.is_product && option.vendor && (
                                                          <Typography className="tooltip-text">
                                                            {option.vendor.display_name}
                                                          </Typography>
                                                        )}
                                                        <Typography
                                                          className={
                                                            option.is_product && option.vendor
                                                              ? 'tooltip-subtext'
                                                              : 'tooltip-text'
                                                          }
                                                        >
                                                          {option.display_name}
                                                        </Typography>
                                                      </Box>
                                                    </>
                                                  }
                                                >
                                                  <Box className="list-item-wrapper">
                                                    {option.logo_path && option.logo_path.length > 0 ? (
                                                      <img
                                                        src={getIntegrationLogo(option.logo_path)}
                                                        width="24px"
                                                        height="24px"
                                                        alt={option.display_name}
                                                      />
                                                    ) : (
                                                      <NoIntegrationLogo>
                                                        <Typography className="no-integration-logo-letter">
                                                          {option.vendor && option.vendor.display_name
                                                            ? option.vendor.display_name[0]
                                                            : option.display_name[0]}
                                                        </Typography>
                                                      </NoIntegrationLogo>
                                                    )}
                                                    <Typography className="option-text">
                                                      {vendor?.display_name || ''}
                                                      {product && ` ${product.display_name}`}
                                                    </Typography>
                                                  </Box>
                                                </IconTooltip>
                                              </>
                                            </ResultListItem>
                                          )
                                        })}
                                    </ResultsList>
                                  </SearchVendorsResultsContainer>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </AutocompleteContainer>
                    </SearchVendorsContainer>
                  </ClickAwayListener>
                )}
              </>
            </Popper>
          </div>
          <Box className="items-wrapper">
            <Typography className="items-text">
              {drawerProps.assets.length} item{drawerProps.assets.length !== 1 && 's'}
            </Typography>
          </Box>
        </Box>
        <Content>
          {!thisCell ||
            (thisCell.items.length < 1 && (
              <Box className="empty-box-wrapper">
                <svg width="128" height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect
                    x="2"
                    y="2.5"
                    width="124"
                    height="124"
                    rx="62"
                    stroke={theme.colors.borderLow}
                    strokeWidth="4"
                    strokeDasharray="8 8"
                  />
                  <path
                    d="M80 48.5H48C45.8 48.5 44.02 50.3 44.02 52.5L44 76.5C44 78.7 45.8 80.5 48 80.5H80C82.2 80.5 84 78.7 84 76.5V52.5C84 50.3 82.2 48.5 80 48.5ZM48 58.5H69V65.5H48V58.5ZM48 69.5H69V76.5H48V69.5ZM80 76.5H73V58.5H80V76.5Z"
                    fill={theme.baseColors.secondary[80]}
                  />
                </svg>

                <Box className="text-content">
                  <Typography className="empty-box-header">No Products Found</Typography>
                  <Typography className="empty-box-text">
                    Click the <span onClick={handleToggleAdd}>'Add'</span> button to add your first product
                  </Typography>
                </Box>
              </Box>
            ))}
          {thisCell &&
            thisCell.items.map((assetItem: any, index) => {
              const product = allProducts.find((n) => n.uuid === assetItem.uuid)

              const productInstances = groupedData.map((group) => {
                return group.items.map((item) => {
                  if ((item as any).uuid === assetItem.uuid) {
                    return { ...item, nist: group.nist, asset: group.asset }
                  }
                  return null
                })
              })
              const flattenedInstances = productInstances
                .flat()
                .filter((n) => !!n)
                .sort((a: any, b: any) => (a.cost < b.cost ? -1 : 1)) as unknown as SSMResponseProductExtended[]

              return (
                <AssetAccordion
                  asset={assetItem}
                  key={index}
                  expanded={expanded}
                  handleChange={handleChange}
                  name={`panel${index}`}
                  thisNist={nist}
                  thisAsset={asset}
                  fetchAndGroupData={fetchAndGroupData}
                  updateDrawerProps={updateDrawerProps}
                  assets={thisCell.items as any}
                  setConfirmRemoveProps={setConfirmRemoveProps}
                  drawerProps={drawerProps}
                  setRemoving={setRemoving}
                  productInstances={flattenedInstances}
                  groupedData={groupedData}
                  setGroupedData={setGroupedData}
                  setExpanded={setExpanded}
                  product={product}
                  removing={removing}
                  maxCellValue={maxCellValue}
                  setBudgetBreakpoints={setBudgetBreakpoints}
                  newProductLoading={newProductLoading}
                  updateCostDistribution={updateCostDistribution}
                  setEmptyStateBanner={setEmptyStateBanner}
                />
              )
            })}
        </Content>
      </Wrapper>
    </Drawer>
  )
}

export default SSMSettingsDrawer
