import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xSmall}px;

  .label-text {
    overflow: hidden;
    color: ${theme.colors.white};
    text-overflow: ellipsis;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      marginTop: `${theme.spacing.half}px`,
      background: theme.colors.backgroundDark,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.small,
      minWidth: '250px !important',
      maxHeight: '486px !important',

      '.MuiList-root': {
        padding: `${theme.spacing.xSmall}px`,
        background: theme.colors.surfaceItemBackground,
        borderRadius: theme.attributes.borderRadius.small
      },

      '.MuiMenuItem-root': {
        borderRadius: theme.attributes.borderRadius.small,
        height: '40px',
        gap: `${theme.spacing.half}px`,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: theme.colors.primaryLight
        },

        '.MuiCheckbox-root': {
          padding: `${theme.spacing.xSmall}px`,

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.bold,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: theme.colors.white
        },

        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: theme.attributes.boxShadow.chipCircle,
            margin: '-4.5px',
            borderRadius: theme.attributes.borderRadius.circle,

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        },

        '&.Mui-disabled': {
          opacity: 1,

          '.MuiCheckbox-root': {
            opacity: 0.38
          },

          svg: {}
        }
      },

      '&::-webkit-scrollbar-track': {
        background: '#3e2e3d'
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#FBD8F5',
        height: '4px',
        width: '4px',
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}

export const LibraryFormControl = styled(FormControl)<{
  noborder?: string
  isselected?: string
  iscustom?: string
  isdisabled?: string
}>`
  border-radius: ${theme.attributes.borderRadius.small};
  min-width: 113px;
  background: transparent;
  border: 1px solid ${theme.colors.controlBorder};
  height: 40px;
  z-index: 10;

  &:hover {
    border: 1px solid ${theme.colors.textPrimary};
    background: rgba(208, 188, 255, 0.16);
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    justify-content: center;
  `};

  ${({ isdisabled }) =>
    isdisabled &&
    isdisabled === 'true' &&
    `
    border: 1px solid ${theme.baseColors.greyscale[50]};
    
    &:hover {
      border-color: ${theme.baseColors.greyscale[50]};
    }
  `};

  .MuiOutlinedInput-root {
    background: transparent;
    border-color: transparent;

    &.Mui-disabled {
      pointer-events: none;
      border: 1px solid transparent;
    }
  }

  .MuiOutlinedInput-input {
    &.Mui-disabled {
      -webkit-text-fill-color: ${theme.baseColors.greyscale[50]};
    }
  }
`

export const LibrarySelect = styled(Select)<{ isselected?: string; noborder?: string; iscustom?: string }>`
  min-width: 113px;
  height: 32px;
  background: transparent;
  gap: ${theme.spacing.xSmall}px;
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};

  ${({ noborder }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: ${theme.colors.white};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.xLarge};
  font-weight: ${theme.typography.fontWeight.normal};
  text-align: left;

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    height: 40px;
  `};

  .MuiOutlinedInput-root {
    ${({ iscustom }) =>
      iscustom &&
      iscustom === 'true' &&
      `
      height: 40px;
  `};
  }

  svg {
    top: calc(50% - 0.8em);
    right: ${theme.spacing.xSmall}px;
    width: 24px;
    height: 24px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: ${theme.colors.white};
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: ${theme.colors.white};
    }
  }

  &.Mui-disabled {
    svg {
      path {
        fill: ${theme.baseColors.greyscale[50]} !important;
      }
    }
  }
`

export const ListDivider = styled(Box)`
  background: ${theme.colors.borderLow};
  width: 100%;
  height: 1px;
  margin: ${theme.spacing.md}px 0;
`
