import { FC, useMemo, useState, useEffect } from 'react'
import { Box, Button, Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from 'ui/components/svg'
import { CategoryMenuProps, JiraFormControl, JiraSelect, Wrapper } from './jiraDropdownMultiple.styles'
import JiraDropdownSearch from './JiraDropdownSearch'
import theme from 'theme'

export interface LabelOption {
  name: string
  label: string
  icon?: JSX.Element
  action?: () => void
  id?: string
  integration_name?: string
  disabled?: boolean
  enabledForField?: string
}

interface Props extends SelectProps {
  options: LabelOption[]
  value: any
  handleChange: any
  minWidth?: string
  iscustom?: string
  hideSearch?: boolean
  label?: string
  field?: string
  maxWidth?: string
  wrapperSx?: Record<string, unknown>
  handleReset?: any
}

const JiraDropdownMultiple: FC<Props> = ({
  label,
  options,
  handleChange,
  iscustom,
  placeholder,
  minWidth,
  value,
  hideSearch = false,
  field,
  wrapperSx,
  maxWidth,
  handleReset,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [search, setSearch] = useState('')
  const [labelOptions, setLabelOptions] = useState(options)

  useEffect(() => {
    setLabelOptions(options)
  }, [options])

  useEffect(() => {
    setTimeout(() => {
      if (!search) setLabelOptions([...options])

      const filteredItems = options.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()))
      setLabelOptions([...filteredItems])
    }, 300)
  }, [search])

  useEffect(() => {
    if (!anchorEl) {
      setSearch('')
    }
  }, [anchorEl])

  const highlightMatch = (text: string) => {
    if (!search) return text

    const regex = new RegExp(`(${search})`, 'gi')
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ color: theme.colors.textPrimary }}>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const renderValue = (): string => {
    const pruned = value.filter((s: string) => s !== '')
    const option = options.find((n) => n.name === pruned[0])

    if (option) {
      if (pruned.length === 1) {
        return option.label
      }

      if (placeholder && pruned.length === 0) {
        return placeholder
      }

      return `${pruned.length} selected`
    }

    return placeholder || ''
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen svg-dropdown')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed svg-dropdown')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper className="jira-dropdown-multiple-wrapper" sx={wrapperSx}>
      {label && <Typography className="label-text">{label}</Typography>}

      <JiraFormControl
        sx={{ minWidth, maxWidth }}
        isselected={anchorEl || (placeholder !== 'Sort' && value.length) ? 'true' : 'false'}
        iscustom={iscustom}
        isopened={anchorEl ? 'true' : 'false'}
      >
        <JiraSelect
          isselected={placeholder !== 'Sort' && value.length > 1 ? 'true' : 'false'}
          iscustom={iscustom}
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                '.MuiList-root': {
                  ...CategoryMenuProps.PaperProps.sx['.MuiList-root'],
                  padding: hideSearch
                    ? `${theme.spacing.xSmall}px !important`
                    : `0 ${theme.spacing.xSmall}px {theme.spacing.xSmall}$px !important`
                },
                display: anchorEl ? 'block' : 'none'
              }
            },
            anchorEl,
            disableAutoFocusItem: true,
            autoFocus: false,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={() => renderValue()}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth }}
        >
          {!hideSearch && <JiraDropdownSearch search={search} setSearch={setSearch} placeholder="Search..." />}

          <Typography className="results-header-text">Results ({labelOptions.length})</Typography>

          <Box className="list-wrapper">
            {!labelOptions.length ? (
              <Box className="no-options-wrapper">
                <Typography className="no-options-text">No results found</Typography>
              </Box>
            ) : (
              labelOptions.map((option, index: number) => (
                <MenuItem
                  disableRipple
                  autoFocus={false}
                  key={index}
                  value={option.name}
                  disabled={typeof field !== 'undefined' && option.disabled && option.enabledForField !== field}
                  onClick={() => {
                    const newValue: string[] = [...value]
                    if (!newValue.includes(option.name)) {
                      newValue.push(option.name)
                    } else {
                      const index = newValue.indexOf(option.name)
                      newValue.splice(index, 1)
                    }
                    handleChange({ target: { value: newValue } })
                    if (!rest.multiple) {
                      setAnchorEl(null)
                    }
                  }}
                >
                  {rest.multiple && (
                    <Checkbox
                      checked={value.indexOf(option.name) > -1}
                      icon={<InstanceUncheckedIcon />}
                      checkedIcon={<InstanceCheckedIcon />}
                    />
                  )}
                  <ListItemText primary={highlightMatch(option.label)} />
                </MenuItem>
              ))
            )}
            {typeof handleReset !== 'undefined' && (
              <MenuItem className="reset-button-wrapper" disableRipple disabled={value.length === 1 && value[0] === ''}>
                <Button className="reset-button" onClick={handleReset}>
                  Reset
                </Button>
              </MenuItem>
            )}
          </Box>
        </JiraSelect>
      </JiraFormControl>
    </Wrapper>
  )
}

export default JiraDropdownMultiple
