import theme from 'theme'

export const GreenZoneIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="10" height="10" rx="2" fill="#27AE60" />
  </svg>
)

export const RedZoneIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="10" height="10" rx="2" fill="#E74C3C" />
  </svg>
)

export const SLAIcon = () => (
  <svg width="20" height="2" viewBox="0 0 20 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="-8.74228e-08"
      y1="1"
      x2="20"
      y2="0.999998"
      stroke={theme.colors.white}
      strokeWidth="2"
      strokeDasharray="4 4"
    />
  </svg>
)

export const TrendLineIcon = () => (
  <svg width="20" height="2" viewBox="0 0 20 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="2" rx="1" fill={theme.colors.white} />
  </svg>
)

export const PredictionLineIcon = () => (
  <svg width="20" height="2" viewBox="0 0 20 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="2" rx="1" fill={theme.baseColors.info[30]} />
  </svg>
)
