import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  height: 310px;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundDarker};
  padding: ${theme.spacing.small}px;
  width: 100%;

  @media (min-width: 1920px) {
    padding: 22px ${theme.spacing.large}px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: ${theme.spacing.xSmall}px;
    }
  }

  .circle {
    width: 220px;
    margin: 15px auto 0 auto;
  }
`
