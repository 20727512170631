import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'
import theme from 'theme'

export const LoadingWrapper = styled(Box)`
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  background: ${theme.colors.primaryDark};
  left: 0;
  top: 0;
  z-index: 10000;

  .content {
    position: absolute;
    top: 30%;
    left: 13%;

    .title {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.huge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 52px;
      margin-bottom: ${theme.spacing.large}px;
    }

    .desc {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 28px;
    }
  }

  .logo {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

export const FormWrapper = styled(Box)`
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
`
