import theme from 'theme'

const SecurityStackMapIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18.25 7.62783L12.75 4.44783C12.29 4.17783 11.71 4.17783 11.25 4.44783L5.75 7.62783C5.29 7.89783 5 8.38783 5 8.92783V15.2778C5 15.8178 5.29 16.3078 5.75 16.5778L11.25 19.7578C11.71 20.0278 12.29 20.0278 12.75 19.7578L18.25 16.5778C18.71 16.3078 19 15.8178 19 15.2778V8.92783C19 8.38783 18.71 7.89783 18.25 7.62783ZM7 14.9878V10.3678L11 12.6878V17.2978L7 14.9878ZM12 10.9578L8 8.63783L12 6.32783L16 8.63783L12 10.9578ZM13 17.2978V12.6878L17 10.3678V14.9878L13 17.2978ZM7 2.02783H3.5C2.67 2.02783 2 2.69783 2 3.52783V7.02783H4V4.02783H7V2.02783ZM17 2.02783H20.5C21.33 2.02783 22 2.69783 22 3.52783V7.02783H20V4.02783H17V2.02783ZM7 22.0278H3.5C2.67 22.0278 2 21.3578 2 20.5278V17.0278H4V20.0278H7V22.0278ZM17 22.0278H20.5C21.33 22.0278 22 21.3578 22 20.5278V17.0278H20V20.0278H17V22.0278Z"
      fill={theme.baseColors.greyscale[80]}
    />
  </svg>
)

export default SecurityStackMapIcon
