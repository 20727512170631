import styled from '@emotion/styled'
import { Box } from '@mui/material'
import theme from 'theme'

export const TableToolbarWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.medium}px;
  margin-bottom: ${theme.spacing.small}px;

  .left-side {
    .date {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.header};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;

    .count {
      color: ${theme.baseColors.secondary[80]};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }
  }

  .filter-icon-block {
    position: relative;
    display: flex;
    border-radius: ${theme.attributes.borderRadius.circle};
    background: transparent;
    border: 1px solid transparent;

    .active-circle {
      position: absolute;
      top: -3px;
      right: -5px;
      background: ${theme.colors.primaryDark};
      border-radius: ${theme.attributes.borderRadius.circle};
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;

      .circle {
        width: 8px;
        height: 8px;
        background: ${theme.baseColors.info[30]};
        border-radius: ${theme.attributes.borderRadius.circle};
      }
    }
  }
`
