import { Box, Table as MuiTable } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`
export const ContentWrapper = styled(Box)<{ showFilter: boolean }>`
  width: ${({ showFilter }) => (showFilter ? 'calc(100% - 444px)' : '100%')};
  transition: all 0.3s ease-in-out;
  height: 100%;
`

export const Table = styled(MuiTable)`
  background: ${theme.colors.backgroundDarker};
  border: 1px solid #342f32;
`

export const TableContainer = styled.div`
  display: block;
  overflow: auto;
  width: 100%;
  background: ${theme.colors.backgroundDarker};
  border: 1px solid #342f32;
`
