import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  padding: ${theme.spacing.small}px ${theme.spacing.small}px 34px ${theme.spacing.small}px;

  img {
    width: 100%;
    max-width: 100%;
    padding-left: ${theme.spacing.md}px;
    padding-right: ${theme.spacing.md}px;
  }
`

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.md}px;

  .title {
    padding-left: ${theme.spacing.md}px;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 28px;
    color: ${theme.colors.white};
  }
`
