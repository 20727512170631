import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  border-radius: ${theme.attributes.borderRadius.quaternary};
  background: ${theme.colors.backgroundDarker};
  box-shadow: ${theme.attributes.boxShadow.feedSection};
  padding: ${theme.spacing.small}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 310px;

  .title-wrapper {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: ${theme.spacing.xSmall}px;
    }
  }

  .inner {
    border-radius: ${theme.attributes.borderRadius.normal};
    padding: ${theme.spacing.md}px ${theme.spacing.lg}px ${theme.spacing.medium}px;
    display: flex;
    gap: 46px;
    justify-content: center;

    @media (min-width: 1920px) {
      gap: 103px;
      padding: 0 ${theme.spacing.lg}px ${theme.spacing.medium}px;
    }

    .chart {
      display: flex;
      flex-direction: column;
      gap: 17px;
      justify-content: center;
      align-items: center;

      .chart-description {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.medium}px;

        .chart-description-item {
          display: flex;
          align-items: center;

          .chart-description-icon {
            border-radius: ${theme.attributes.borderRadius.tertiary};
            background: ${theme.colors.borderLow};
            box-shadow: ${theme.attributes.boxShadow.chipCircle};
            width: 8px;
            height: 8px;
          }

          .MuiTypography-root {
            color: ${theme.colors.neutral};
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.medium};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: 16px;
            letter-spacing: 0.4px;
            margin-left: ${theme.spacing.half}px;
          }
        }
      }
    }
  }
`
