import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import theme from 'theme'

import { Wrapper } from './noFeedFound.styles'

const NoFeedFound: FC = () => (
  <Wrapper className="no-feed-found-wrapper">
    <Box className="image-wrapper">
      <svg width="128" height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="2"
          y="2.5"
          width="124"
          height="124"
          rx="62"
          stroke={theme.colors.borderLow}
          strokeWidth="4"
          strokeDasharray="8 8"
        />
        <path
          d="M64 57.2273C59.9818 57.2273 56.7273 60.4818 56.7273 64.5C56.7273 68.5182 59.9818 71.7727 64 71.7727C68.0182 71.7727 71.2727 68.5182 71.2727 64.5C71.2727 60.4818 68.0182 57.2273 64 57.2273ZM80.2545 62.6818C79.4182 55.1 73.4 49.0818 65.8182 48.2455V44.5H62.1818V48.2455C54.6 49.0818 48.5818 55.1 47.7455 62.6818H44V66.3182H47.7455C48.5818 73.9 54.6 79.9182 62.1818 80.7545V84.5H65.8182V80.7545C73.4 79.9182 79.4182 73.9 80.2545 66.3182H84V62.6818H80.2545ZM64 77.2273C56.9636 77.2273 51.2727 71.5364 51.2727 64.5C51.2727 57.4636 56.9636 51.7727 64 51.7727C71.0364 51.7727 76.7273 57.4636 76.7273 64.5C76.7273 71.5364 71.0364 77.2273 64 77.2273Z"
          fill={theme.baseColors.secondary[80]}
        />
      </svg>
    </Box>
    <Box className="content">
      <Typography className="content-title">No Data Found</Typography>
      <Typography className="content-description">Check your connection, or try to refresh the page</Typography>
    </Box>
  </Wrapper>
)

export default NoFeedFound
