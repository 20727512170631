import { Box, Drawer as MuiDrawer } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Drawer = styled(MuiDrawer)`
  > .MuiPaper-root {
    width: 45%;
    overflow-y: hidden;
    border-left: 1px solid ${theme.colors.borderLow};
    background: ${theme.colors.surfaceContainerBackground};
  }
`

export const Wrapper = styled(Box)`
  overflow-y: hidden;
  height: 100%;

  .header {
    height: 100px;
    padding: ${theme.spacing.medium}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiTypography-root {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }

    .close-icon {
      width: 40px;
      height: 40px;
      color: ${theme.colors.white};

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing.small}px;
    padding: ${theme.spacing.medium}px;
    border-top: 1px solid ${theme.colors.controlBorder};
  }
`

export const Content = styled(Box)<{ emptylist?: string }>`
  background: ${theme.colors.primaryDarker};
  height: 100%;
  overflow-y: auto;
  padding: ${({ emptylist }) => (emptylist && emptylist === 'true' ? 0 : `${theme.spacing.medium}px`)};

  .drawer-cpi-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.spacing.small}px;
  }

  .empty-cpi-list-container {
    position: relative;
    gap: ${theme.spacing.small}px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing.medium}px;

    .empty-cpi-list-header {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.header};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }

    .empty-cpi-list-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }

    .onyxia-logo-empty-drawer {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`

export const FooterContainer = styled(Box)`
  width: 100%;
  height: 88px;
  background: ${theme.colors.surfaceContainerBackground};
  padding: ${theme.spacing.medium}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .footer-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${theme.spacing.small}px;
  }
`
