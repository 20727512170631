import { forwardRef, Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react'
import { Box } from '@mui/material'
import * as d3 from 'd3'
import dayjs from 'dayjs'
import theme from 'theme'

/* Utils */
import {
  CategoryActivityItem,
  CPIFrequency,
  CPITimePeriod,
  HistoryPerformanceDataExtended,
  PerformanceStatus
} from '../../../../../../models'
import {
  filterHistoricalData,
  findHistoricalUniqueMonths,
  getCategoryPathAndPositions,
  groupHistoricalDataByWeeks
} from '../../../../library/library-category/sections/historical-data/performanceScoreUtils'
import { fillBlankData, findNearestXIndex, formatTooltipDate } from '../../../../library/utils'

/* Components */
import { ChartContainer } from './categoryChart.styles'

const chartWidthStart = 30
const chartHeightStart = 16

interface Props {
  category: CategoryActivityItem
  margin: { top: number; right: number; bottom: number; left: number }
  width: number
  height: number
  setWidth: Dispatch<SetStateAction<number>>
}

const CategoryChart = forwardRef<any, Props>(({ category, width, height, margin, setWidth }, ref) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { containerRef, svgRef } = ref
  const selectedTimePeriod = CPITimePeriod.Month
  const numberOfStripes = 4
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const barHeight = height + margin.top + margin.bottom

  const isOverPerform = useMemo(() => {
    const findItem = category.data.find((item) => item.yValue > 100)
    return !!findItem
  }, [category])

  const buildChart = (data: HistoryPerformanceDataExtended[], timeRange: number) => {
    const filteredData = filterHistoricalData(timeRange, data)
    const dataStartsAt = dayjs(data[0].xValue || '').format('YYYY-MM-DD')
    const tooltip = d3.select(tooltipRef.current)
    const svg = d3.select(svgRef.current)

    /*
     *  Populate each stripe with data points for that month
     * */
    const yAxisDomain = isOverPerform ? [0, 150] : [0, 100]
    const uniqueMonths = findHistoricalUniqueMonths(filteredData, selectedTimePeriod, data)
    const stripeWidth = width / uniqueMonths.length

    const [pathStr, positions, pathStartPoint] = getCategoryPathAndPositions({
      uniqueMonths,
      stripeWidth,
      height: barHeight,
      filteredData,
      yAxisDomain,
      chartHeightStart,
      chartWidthStart
    })

    const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([chartWidthStart, width]).padding(0)
    const yScale = d3
      .scaleLinear()
      .domain(yAxisDomain)
      .range([barHeight - chartHeightStart / 2, chartHeightStart / 2])
      .nice()

    /*
     * Cartesian Grid Bars
     * */
    const existingBars = svg.selectAll('.bar')
    if (!existingBars.empty()) existingBars.remove()
    svg
      .selectAll('.bar')
      .data(uniqueMonths)
      .enter()
      .append('rect')
      .attr('fill', 'theme.colors.primaryDark')
      .attr('class', 'bar')
      .attr('x', (d) => xScaleGroup(d) || 0)
      .attr('y', 0)
      .attr('width', xScaleGroup.bandwidth())
      .attr('height', barHeight)
      .on('mouseout', () => {
        tooltip.style('display', 'none')
        const circleInner = svg.select('.hover-circle-inner')
        circleInner.attr('display', 'none')
        const circleOuter = svg.select('.hover-circle-outer')
        circleOuter.attr('display', 'none')
      })

    /*
     * Mouse Move for data point tooltip
     * */
    svg.on('mousemove', (event: any) => {
      if (!data.length) return
      const [mouseX, mouseY] = d3.pointer(event)
      const dataPoint = findNearestXIndex(positions, mouseX)
      if (mouseX > width + chartWidthStart || mouseY > height + chartHeightStart) return null
      const matchingDataPoint = filteredData[dataPoint?.index || 0]
      if (dataPoint) mouseMove(event, dataPoint, matchingDataPoint)
    })

    /*
     * Hover Data Point Circle
     * */
    const appendHoverCircles = () => {
      const hoverCircleInner = svg.select('.hover-circle-inner')
      if (!hoverCircleInner.empty()) hoverCircleInner.remove()
      svg
        .append('circle')
        .attr('class', 'hover-circle-inner')
        .attr('cx', 100)
        .attr('cy', 200)
        .attr('r', 5.5)
        .attr('fill', theme.colors.white)
        .attr('stroke', '#000')
        .attr('stroke-width', 2)
        .attr('stroke-opacity', 1)
        .attr('display', 'none')
      const hoverCircleOuter = svg.select('.hover-circle-outer')
      if (!hoverCircleOuter.empty()) hoverCircleOuter.remove()
      svg
        .append('circle')
        .attr('class', 'hover-circle-outer')
        .attr('cx', 100)
        .attr('cy', 200)
        .attr('r', 5)
        .attr('fill', 'none')
        .attr('stroke', theme.colors.white)
        .attr('stroke-width', 15)
        .attr('stroke-opacity', 0.5)
        .attr('display', 'none')
    }
    appendHoverCircles()

    /*
     * Hover Tooltip
     * */
    const mouseMove = (event: any, dataPoint: any, d: any) => {
      tooltip.style('display', 'block')
      const circleInner = svg.select('.hover-circle-inner')
      const circleOuter = svg.select('.hover-circle-outer')
      circleInner.attr('cx', dataPoint.x)
      circleInner.attr('cy', dataPoint.y)
      circleInner.attr('display', 'block')
      circleOuter.attr('cx', dataPoint.x)
      circleOuter.attr('cy', dataPoint.y)
      circleOuter.attr('display', 'block')
      cursorLine.attr('x1', dataPoint.x).attr('x2', dataPoint.x).style('display', 'block')

      const formattedDate = formatTooltipDate(d.start, d.end, CPIFrequency.Weekly, selectedTimePeriod, dataStartsAt)
      const unitInfoText = d.dummy ? `There is no data for these dates` : `Performance score from ${formattedDate}`
      const value = d.dummy ? '' : `${d.yValue}%`

      tooltip
        .html(
          `
          <p class='tooltip-performance'>${value}</p>
          <p class='tooltip-units'>${unitInfoText}</p>
          `
        )
        .style('left', dataPoint.x + 5 + 'px')
        .style('top', dataPoint.y + 10 + 'px')
    }

    /*
     * Horizontal line middle
     * */
    if (isOverPerform) {
      const existingHorizontalLine = svg.selectAll('.horizontal-line-middle')
      if (!existingHorizontalLine.empty()) existingHorizontalLine.remove()
      svg
        .append('line')
        .attr('class', 'horizontal-line-middle')
        .attr('x1', chartWidthStart)
        .attr('x2', width - margin.left - margin.right)
        .attr('y1', (height + margin.top * 2 + 10) / 2)
        .attr('y2', (height + margin.top * 2 + 10) / 2)
        .attr('stroke', theme.baseColors.info[40])
        .attr('stroke-width', 1)
    } else {
      const existingHorizontalLine = svg.selectAll('.horizontal-line-middle')
      if (!existingHorizontalLine.empty()) existingHorizontalLine.remove()
      svg
        .append('line')
        .attr('class', 'horizontal-line-middle')
        .attr('x1', chartWidthStart)
        .attr('x2', width - margin.left - margin.right)
        .attr('y1', (height + margin.top + margin.bottom) / 2)
        .attr('y2', (height + margin.top + margin.bottom) / 2)
        .attr('stroke', theme.colors.borderLow)
        .attr('stroke-width', 1)
    }

    /*
     * Cursor Line
     * */
    const existingline = svg.selectAll('.cursor-line')
    if (!existingline.empty()) existingline.remove()
    const cursorLine = svg
      .append('line')
      .attr('class', 'cursor-line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', height + margin.top + margin.bottom)
      .attr('stroke', theme.colors.white)
      .attr('stroke-width', 1)
      .style('display', 'none')
    svg.on('mouseout', () => {
      cursorLine.style('display', 'none')
      tooltip.style('display', 'none')
      const circleInner = svg.select('.hover-circle-inner')
      circleInner.attr('display', 'none')
      const circleOuter = svg.select('.hover-circle-outer')
      circleOuter.attr('display', 'none')
    })

    const filledArea = svg.select('.filled-area')
    const filledAreaStroke = svg.select('.filled-area-stroke')

    /*
     *  Filled Area
     * */
    const appendFilledArea = () => {
      const existingFilledArea = svg.selectAll('.filled-area')
      if (!existingFilledArea.empty()) existingFilledArea.remove()
      svg
        .append('path')
        .datum(filteredData)
        .attr(
          'd',
          `${pathStr}` + `L${positions[positions.length - 1].x} ${barHeight} L ${pathStartPoint.x} ${barHeight} Z`
        )
        .attr('class', 'filled-area')
        .attr('fill', theme.colors.primaryLight)
        .attr('stroke', 'none')
        .on('mouseout', () => {
          tooltip.style('display', 'none')
          const circleInner = svg.select('.hover-circle-inner')
          circleInner.attr('display', 'none')
          const circleOuter = svg.select('.hover-circle-outer')
          circleOuter.attr('display', 'none')
        })
    }
    appendFilledArea()

    /*
     * Filled Area Stroke
     * */
    const appendFilledAreaStroke = () => {
      const existingFilledAreaStroke = svg.selectAll('.filled-area-stroke')
      if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', theme.colors.textPrimary)
        .attr('d', `${pathStr}`)
        .attr('class', 'filled-area-stroke')
        .attr('stroke-width', 2)
        .attr('stroke-linejoin', 'bevel')
    }
    appendFilledAreaStroke()

    if (!filledArea.empty()) filledArea.remove()
    if (!filledAreaStroke.empty()) filledAreaStroke.remove()

    /*
     *  Y-Axis
     * */
    const yAxis = isOverPerform
      ? d3
          .axisRight(yScale)
          .ticks(3)
          .tickFormat((d) => {
            return `${d}%`
          })
      : d3
          .axisRight(yScale)
          .ticks(3)
          .tickFormat((d) => `${d}%`)
    const existingYAxis = svg.selectAll('.y-axis')
    if (!existingYAxis.empty()) existingYAxis.remove()
    const yAxisGroup = svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('color', 'none')
      .attr('transform', 'translate(-8, 0)')
      .call(yAxis)
    yAxisGroup
      .selectAll('text')
      .attr('color', theme.colors.white)
      .attr('text-align', 'right')
      .attr('font-size', '11px')
      .attr('font-weight', theme.typography.fontWeight.normal)
      .attr('letter-spacing', '0.5px')
      .attr('line-height', '16px')
      .attr('font-family', theme.typography.fontFamily.primary)
    yAxisGroup.selectAll('path').attr('display', 'none')
    yAxisGroup.selectAll('line').attr('display', 'none')

    svg.selectAll('.filled-area').raise()
    svg.selectAll('.filled-area-stroke').raise()
    svg.selectAll('.hover-circle-outer').raise()
    svg.selectAll('.hover-circle-inner').raise()
    svg.selectAll('.cursor-line').raise()
    svg.selectAll('.horizontal-line-middle').raise()
  }

  useEffect(() => {
    if (!containerRef.current || category.status !== PerformanceStatus.active) return

    const { data } = category
    const groupedData = groupHistoricalDataByWeeks(data)
    const filledData = fillBlankData(groupedData, selectedTimePeriod) as HistoryPerformanceDataExtended[]

    buildChart(filledData, numberOfStripes)
  }, [category, width, tooltipRef])

  useEffect(() => {
    if (!containerRef.current) return

    const observeTarget = containerRef.current
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setWidth(entry.contentRect.width - 12)
      })
    })
    resizeObserver.observe(observeTarget)

    return () => {
      resizeObserver.unobserve(observeTarget)
    }
  }, [containerRef])

  return (
    <ChartContainer
      sx={{
        '.bordered-wrapper': {
          width: `${width + margin.left + margin.right + 12}px`
        }
      }}
      height={barHeight + 2}
    >
      <Box className="bordered-wrapper" />
      <div ref={tooltipRef} className="category-chart-tooltip" />
      <svg
        ref={svgRef}
        id={`category-chart-svg-${category.id}`}
        className="category-chart-svg"
        width={width + margin.left + margin.right}
        height={barHeight}
      />
    </ChartContainer>
  )
})

export default CategoryChart
