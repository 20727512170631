import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const DataSourceErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    marginTop: '0 !important',
    padding: `${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: theme.attributes.borderRadius.quaternary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: '#4E4E4E',
    minWidth: '253px'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: `${theme.spacing.xSmall}px`,
    width: '100%',

    '.instance-name-container': {
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing.xSmall}px`,

      img: {
        width: '24px',
        height: '24px',
        borderRadius: theme.attributes.borderRadius.circle
      },

      '.instance-name': {
        color: theme.colors.white,
        fontFamily: theme.typography.fontFamily.primary,
        fontSize: theme.typography.fontSize.large,
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeight.thick,
        lineHeight: 'normal'
      }
    },

    '.error-description-container': {
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing.xSmall}px`,

      '.error-icon': {
        width: '24px',
        height: '24px',
        borderRadius: theme.attributes.borderRadius.circle
      },

      '.error-text': {
        color: theme.baseColors.info[50],
        fontFamily: theme.typography.fontFamily.primary,
        fontSize: theme.typography.fontSize.large,
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeight.normal,
        lineHeight: 'normal'
      }
    }
  }
})

export const linkSx = {
  whiteSpace: 'nowrap',
  fontFamily: theme.typography.fontFamily.primary,
  fontSize: theme.typography.fontSize.large,
  fontWeight: theme.typography.fontWeight.normal,
  lineHeight: 'normal',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: theme.colors.white,
  alignSelf: 'flex-start',
  marginTop: `${theme.spacing.xSmall}px`
}
