import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Warning, TaskAlt } from '@mui/icons-material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const Wrapper = styled(Box)<{ variant: 'success' | 'error' }>`
  width: 100%;
  height: 40px;
  border: ${({ variant }) => `1px solid ${variant === 'success' ? '#3E8B56' : '#E85541'}`};
  background: ${({ variant }) => (variant === 'success' ? '#C6FCCB' : '#FDAEA3')};
  display: flex;
  align-items: center;
`

interface Props {
  variant: 'success' | 'error'
  message?: string
  sx?: Record<string, unknown>
}

const Toast: FC<Props> = ({ variant, message, sx }) => (
  <Wrapper variant={variant} sx={sx}>
    {variant === 'error' ? (
      <Warning sx={{ color: '#E85541', fontSize: theme.typography.fontSize.xLarge, mx: `${theme.spacing.half}px` }} />
    ) : (
      <TaskAlt sx={{ color: '#3E8B56', fontSize: theme.typography.fontSize.xLarge, mx: `${theme.spacing.half}px` }} />
    )}
    {variant === 'error' && (
      <Typography
        variant="body1"
        sx={{
          fontSize: theme.typography.fontSize.large,
          lineHeight: '20px',
          fontWeight: 300,
          color: theme.colors.primaryBorder
        }}
      >
        <strong>Test Failed</strong> {message ?? 'Something went wrong, please make sure inputs are accurate.'}
      </Typography>
    )}
    {variant === 'success' && (
      <Typography
        variant="body1"
        sx={{
          fontSize: theme.typography.fontSize.large,
          lineHeight: '20px',
          fontWeight: 300,
          color: theme.colors.primaryBorder
        }}
      >
        <strong>Success:</strong> {message ?? 'The test worked as expected.'}
      </Typography>
    )}
  </Wrapper>
)

export default Toast
