/* eslint camelcase: 0 */
import { FormControl as MuiFormControl, Select as MuiSelect } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 0

export const FormControl = styled(MuiFormControl)<{ isselected?: string; is_opened?: string; is_disabled: string }>`
  border: 1px solid ${theme.colors.controlBorder};
  background: transparent;
  outline: none;
  width: 100%;
  height: 56px;
  border-radius: ${theme.attributes.borderRadius.small};

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${theme.colors.neutral};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    width: 100%;
  }

  &:hover {
    border-radius: ${theme.attributes.borderRadius.small};
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: ${theme.colors.textPrimary};
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: ${theme.spacing.small}px;
  }

  ${({ is_disabled }) =>
    is_disabled &&
    is_disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      
      &:hover {
        border-radius: ${theme.attributes.borderRadius.small};
        background: transparent;
    
        .MuiOutlinedInput-root {
          color: ${theme.colors.textPrimary};
        }
      }
      
       .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};

  ${({ is_opened }) =>
    is_opened &&
    is_opened === 'true' &&
    `
      border: 1px solid ${theme.colors.textPrimary};
  `};
`

export const Select = styled(MuiSelect)<{ isselected?: string }>`
  width: 100%;
  height: 56px;
  background: transparent;
  border-radius: ${theme.attributes.borderRadius.small};
  border: 1px solid ${theme.colors.controlBorder};
  color: ${theme.colors.white};
  text-align: center;
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: capitalize;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
      background: ${theme.colors.backgroundLight};
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-input {
    text-align: left;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: theme.colors.primaryLight,
      boxShadow: theme.attributes.boxShadow.chipCircle,
      borderRadius: theme.attributes.borderRadius.secondary,
      width: '456px',
      marginTop: '3px',

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: '167px',
        height: '32px',
        paddingLeft: `${theme.spacing.small}px`,
        paddingRight: `${theme.spacing.small}px`,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.08)'
        },

        '.MuiCheckbox-root': {
          marginRight: `${theme.spacing.xSmall}px`,

          '&.Mui-checked': {
            svg: {
              path: {
                fill: theme.colors.textPrimary
              }
            }
          },

          '&:hover': {
            borderRadius: theme.attributes.borderRadius.large,
            background: 'rgba(202, 196, 208, 0.12)'
          },

          svg: {
            width: '18px',
            height: '18px',

            path: {
              fill: theme.baseColors.greyscale[80]
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontSize: theme.typography.fontSize.large,
          lineHeight: '24px',
          letterSpacing: '0.5px',
          color: theme.colors.neutral,
          fontStyle: 'normal',
          textTransform: 'capitalize'
        }
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: theme.attributes.borderRadius.secondary
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
