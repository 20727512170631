import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const DeleteInstanceWrapper = styled(Box)<{ step: number }>`
  background: ${theme.colors.backgroundMedium};
  border-radius: ${theme.attributes.borderRadius.secondary};
  padding: ${theme.spacing.medium}px 24px 18px;

  ${({ step }) =>
    step === 2 &&
    `
     width: 506px;
     margin: 0 auto;
  `};

  .input-wrapper {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.colors.neutral};
  }

  .cpi-wrapper {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;
    border-radius: ${theme.attributes.borderRadius.quaternary};
    background: ${theme.colors.backgroundMedium};
    padding: 12px 16px;
    margin-bottom: ${theme.spacing.medium}px;

    .cpi-name {
      border-radius: 18px;
      background: #debdd9;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #3f293e;
      padding: 2px ${theme.spacing.xSmall}px;
    }

    .cpi-title {
      max-width: 228px;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
      color: ${theme.colors.white};
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cpi-desc {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 24px;
      letter-spacing: 0.5px;
      color: ${theme.colors.white};
      margin-left: 22px;
    }
  }

  .instance-wrapper {
    border-radius: ${theme.attributes.borderRadius.quaternary};
    background: ${theme.colors.backgroundMedium};

    .instance-block {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.small}px;
      padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
      border-bottom: 1px solid ${theme.colors.borderLow};

      &:last-child {
        border-bottom: none;
      }

      .instance-name {
        width: 220px;
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 24px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        gap: ${theme.spacing.small}px;
      }

      .instance-status {
        width: 80px;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.medium};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 16px;
        letter-spacing: 0.4px;
        display: flex;
        align-items: center;
        gap: ${theme.spacing.xsm}px;
      }

      .instance-desc {
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
  }
`

export const ImgWrapper = styled(Box)<{ isActive: boolean }>`
  img {
    border-radius: 100%;
    border: ${({ isActive }) =>
      isActive ? `1px solid ${theme.baseColors.info[20]}` : `1px solid ${theme.colors.controlBorder}`};
  }
`

export const CircleStatus = styled(Box)<{ isActive: boolean }>`
  width: 8px;
  height: 8px;
  display: flex;
  box-shadow: ${theme.attributes.boxShadow.chipCircle};
  border-radius: 100%;
  background: ${({ isActive }) => (isActive ? theme.baseColors.info[20] : theme.colors.controlBorder)};
`

export const DeleteInstanceTitle = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.normal};
  font-size: ${theme.typography.fontSize.xxxLarge};
  line-height: 32px;
  color: ${theme.colors.neutral};
  mix-blend-mode: normal;
  margin-bottom: ${theme.spacing.md}px;
`

export const DeleteInstanceSubtitle = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.normal};
  ${theme.typography.fontSize.large};
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${theme.colors.neutral};
  mix-blend-mode: normal;
  margin-bottom: ${theme.spacing.small}px;
`

export const DeleteInstanceActionsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  margin-bottom: ${theme.spacing.xSmall}px;

  .MuiCircularProgress-root {
    color: #690005;
  }

  .MuiButtonBase-root:first-of-type {
    margin-right: ${theme.spacing.xSmall}px;
  }

  .MuiButtonBase-root:last-of-type {
    background: #ffb4ab;
    color: #690005;

    svg {
      path {
        fill: #690005;
      }
    }

    &.Mui-disabled {
      background: rgba(227, 227, 227, 0.12);
      color: ${theme.colors.neutral};

      svg {
        path {
          fill: ${theme.colors.neutral};
        }
      }
    }
  }
`
