import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const popperProps = {
  sx: {
    marginTop: `-${theme.spacing.sm}px !important`
  }
}

export const Container = styled(Box)`
  position: relative;
  display: inline-flex;
  margin: 0 auto;
  flex-grow: 1;

  .over-perform {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: flex-start;
    gap: 0;
    right: -151px;
    bottom: 0;

    .info {
      display: flex;
      align-items: center;
      gap: 7px;

      .over-perform-value {
        display: flex;
        align-items: baseline;
        gap: 1px;
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;

        .over-perform-value-unit {
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.mediumSmall};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.bold};
          line-height: 16px;
          letter-spacing: 0.5px;
        }
      }
    }

    .over-perform-desc {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`

export const CircleInner = styled(CircularProgress)`
  color: ${theme.baseColors.info[10]};
  animation-duration: 550ms;
  position: absolute;
  left: 0;
`

export const CircleOuter = styled(CircularProgress)`
  color: ${theme.colors.textPrimary};
`

export const ValueWrapper = styled(Box)`
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 200px;

  .MuiTypography-root {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 28px;
    font-size: ${theme.typography.fontSize.xxLarge};
  }
`
