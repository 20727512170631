import { FC } from 'react'
import theme from 'theme'

/* Utils */
import { DetailedComparisonData } from '../../../../../../../models'

/* Components */
import { Box, Typography } from '@mui/material'
import { BenchmarkCardTooltip } from '../../../../../dashboard/components/Benchmarking/components/BenchmarkCardTooltip'
import {
  DetailedComparisonGroupsWrapper,
  DetailedComparisonItemValues,
  DetailedComparisonItemWrapper,
  DetailedComparisonLegend,
  DetailedComparisonWrapper
} from './detailedComparison.styles'

const popperProps = {
  sx: {
    marginTop: `-${theme.spacing.sm}px !important`
  }
}

const minPercentageForShow = 11

interface Props {
  data: DetailedComparisonData
  showMessage: boolean
}

const DetailedComparison: FC<Props> = ({ data, showMessage }) => {
  const showPercentage = (percentage: number | null) => {
    if (!percentage) {
      return ''
    }
    if ((percentage.toString().length > 2 && percentage < minPercentageForShow) || percentage <= 6) {
      return ''
    }

    return `${percentage}%`
  }

  const industrySortFunc = (a: any, b: any) => {
    return a.industry_name < b.industry_name ? -1 : 0
  }

  return (
    <DetailedComparisonWrapper>
      <Typography className="detailed-comparison-title">Detailed Comparison</Typography>
      <Typography className="detailed-comparison-description">
        Gain detailed insight into how strictly other industries set their SLA compared to your company:
      </Typography>
      <DetailedComparisonLegend>
        <Box className="legend-item">
          <Box className="legend-color blue" />
          <Typography className="legend-text">Less Strict SLA</Typography>
        </Box>
        <Box className="legend-item">
          <Box className="legend-color orange" />
          <Typography className="legend-text">Same SLA</Typography>
        </Box>
        <Box className="legend-item">
          <Box className="legend-color purple" />
          <Typography className="legend-text">More Strict SLA</Typography>
        </Box>
      </DetailedComparisonLegend>

      {showMessage ? (
        <Box className="no-category">
          <img src="/info-panel.svg" alt="info" />
          This CPI is recalculating to reflect an updated SLA; your company data will display momentarily.
        </Box>
      ) : (
        ''
      )}

      <DetailedComparisonGroupsWrapper>
        {data.score.sort(industrySortFunc).map((n) => (
          <DetailedComparisonItemWrapper key={n.industry_id}>
            <Box className="title-wrapper">
              <Typography className="industry-title">{n.industry_name}</Typography>
            </Box>
            <DetailedComparisonItemValues
              one={n.summary.less as number}
              two={n.summary.equal as number}
              three={n.summary.more as number}
            >
              <BenchmarkCardTooltip
                PopperProps={popperProps}
                title={
                  <>
                    <Box className="tooltip-wrapper">
                      <Typography className="tooltip-title">{n.summary.less as number}%</Typography>
                      <Typography className="tooltip-text">
                        of {n.industry_name === 'All Industries' ? n.industry_name : `the ${n.industry_name} industry`}{' '}
                        have less strict SLAs
                      </Typography>
                    </Box>
                  </>
                }
              >
                <Box className="one">{showPercentage(n.summary.less as number)}</Box>
              </BenchmarkCardTooltip>
              <BenchmarkCardTooltip
                PopperProps={popperProps}
                title={
                  <>
                    <Box className="tooltip-wrapper">
                      <Typography className="tooltip-title">{n.summary.equal as number}%</Typography>
                      <Typography className="tooltip-text">
                        of {n.industry_name === 'All Industries' ? n.industry_name : `the ${n.industry_name} industry`}{' '}
                        industry have similar SLAs
                      </Typography>
                    </Box>
                  </>
                }
              >
                <Box className="two">{showPercentage(n.summary.equal as number)}</Box>
              </BenchmarkCardTooltip>
              <BenchmarkCardTooltip
                PopperProps={popperProps}
                title={
                  <>
                    <Box className="tooltip-wrapper">
                      <Typography className="tooltip-title">{n.summary.more as number}%</Typography>
                      <Typography className="tooltip-text">
                        of {n.industry_name === 'All Industries' ? n.industry_name : `the ${n.industry_name} industry`}{' '}
                        industry have more strict SLAs
                      </Typography>
                    </Box>
                  </>
                }
              >
                <Box className="three">{showPercentage(n.summary.more as number)}</Box>
              </BenchmarkCardTooltip>
            </DetailedComparisonItemValues>
          </DetailedComparisonItemWrapper>
        ))}
      </DetailedComparisonGroupsWrapper>
    </DetailedComparisonWrapper>
  )
}

export default DetailedComparison
