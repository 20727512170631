import { FC, SVGProps } from 'react'
import theme from 'theme'

type Props = SVGProps<SVGSVGElement>

const CheckboxCheckedIcon: FC<Props> = ({ ...props }) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="11" y="11.5" width="18" height="18" rx="2" fill={theme.colors.textPrimary} />
    <path d="M18 24.9L14 20.9L15.4 19.5L18 22.1L24.6 15.5L26 16.9L18 24.9Z" fill={theme.baseColors.primary[20]} />
  </svg>
)

export default CheckboxCheckedIcon
