import { FC, useMemo, useState } from 'react'
import { Box, Collapse, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { tooltipClasses } from '@mui/material/Tooltip'
import theme from 'theme'

/* Utils */
import {
  BenchmarkScoreCategory,
  BenchmarkScoreCategoryData,
  CategoryFramework,
  IFramework
} from '../../../../../../models'
import { categoryToUrl } from '../../../../library/utils'

/* Components */
import {
  BenchmarkCardContainer,
  BenchmarkTable,
  BenchmarkValueWrapper,
  CategoryBenchmarkWrapper,
  NoBenchmarkWrapper
} from './benchmarkCard.styles'
import { InfoIconFilled } from '../../../../../components/svg'
import { BenchmarkCardTooltip } from './BenchmarkCardTooltip'

const popperProps = {
  sx: {
    marginTop: `-${theme.spacing.sm}px !important`
  }
}

interface Props {
  category: BenchmarkScoreCategory
  i: number
  cpiTitles: {
    displayName: string
    name: string
    title: string
    category: string
  }[]
  hasBenchmark: boolean
  framework?: CategoryFramework
  customFramework: IFramework | null
}

const minPercentageForShow = 11

const BenchmarkCard: FC<Props> = ({ category, framework, customFramework, cpiTitles, hasBenchmark }) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(false)

  const handleClickExpand = () => {
    setExpanded(!expanded)
  }

  const showPercentage = (percentage: number | null) => {
    if (!percentage) {
      return ''
    }
    if ((percentage.toString().length > 2 && percentage < minPercentageForShow) || percentage <= 6) {
      return ''
    }

    return `${percentage}%`
  }

  const getTitle = (cpiName: string) => {
    const titleObj = cpiTitles.find((n) => n.name === cpiName)
    if (titleObj) {
      return titleObj.title
    }
  }

  const toLink = (name: string) => {
    if (name) {
      window.open(`${window.location.origin}/library/cpis/${name.toLowerCase()}`)
    }
  }

  const sortCategoryData: BenchmarkScoreCategoryData[] = useMemo(() => {
    const titlesInCategory = cpiTitles.filter((title) => title.category === category.category_name)
    const titleNames = titlesInCategory.map((n) => n.name)

    const cpisWithData = category?.data?.filter((data) => titleNames.includes(data.cpi_name)).map((n) => n.cpi_name)

    const realData: BenchmarkScoreCategoryData[] = []

    for (const cpi of titlesInCategory) {
      if (cpisWithData?.includes(cpi.name)) {
        const foundCpi = category.data.find((n) => n.cpi_name === cpi.name)
        if (foundCpi) {
          realData.push(foundCpi)
        }
      } else {
        const emptyCpi: BenchmarkScoreCategoryData = {
          cpi_display_name: cpi.displayName,
          cpi_name: cpi.name,
          cpi_title: cpi.title,
          summary: {
            less: null,
            equal: null,
            more: null
          }
        }
        realData.push(emptyCpi)
      }
    }

    return realData.sort((a, b) => ((a as any).cpi_name > (b as any).cpi_name ? 1 : -1))
  }, [category.data, cpiTitles])

  const removeLine = (str: string): string => {
    return str.replace('-', '')
  }

  const handleCardClick = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault()
    e.stopPropagation()
    let catId = category.category_id
    const fw = framework ? (framework === 'onyxia' ? 'onyxia' : framework === 'nist' ? 'nist' : 'custom') : ''
    if (fw === 'custom' && customFramework) {
      catId = categoryToUrl(category.category_name)
    }
    navigate(`/library/categories/${catId === 'iam' ? 'identity-and-access-management' : catId}`)
  }

  return (
    <BenchmarkCardContainer isexpanded={expanded ? 'true' : 'false'} onClick={handleCardClick}>
      <Box onClick={handleCardClick} className="category-name" display="inline-flex">
        {category.category_name}
      </Box>
      {category?.summary && (
        <BenchmarkValueWrapper
          one={category?.summary?.less ?? 0}
          two={category?.summary?.equal ?? 0}
          three={category?.summary?.more ?? 0}
          sx={{ marginTop: '7px', marginBottom: '10px' }}
          onClick={handleClickExpand}
        >
          <BenchmarkCardTooltip
            PopperProps={popperProps}
            title={
              <>
                <Box className="tooltip-wrapper">
                  <Typography className="tooltip-title">{category?.summary?.less}%</Typography>
                  <Typography className="tooltip-text">of companies have less strict SLAs</Typography>
                </Box>
              </>
            }
          >
            <Box className="one">{showPercentage(category?.summary?.less)}</Box>
          </BenchmarkCardTooltip>
          <BenchmarkCardTooltip
            PopperProps={popperProps}
            title={
              <>
                <Box className="tooltip-wrapper">
                  <Typography className="tooltip-title">{category?.summary?.equal}%</Typography>
                  <Typography className="tooltip-text">of companies have similar SLAs</Typography>
                </Box>
              </>
            }
          >
            <Box className="two">{showPercentage(category?.summary?.equal)}</Box>
          </BenchmarkCardTooltip>
          <BenchmarkCardTooltip
            PopperProps={popperProps}
            title={
              <>
                <Box className="tooltip-wrapper">
                  <Typography className="tooltip-title">{category?.summary?.more}%</Typography>
                  <Typography className="tooltip-text">of companies have more strict SLAs</Typography>
                </Box>
              </>
            }
          >
            <Box className="three">{showPercentage(category?.summary?.more)}</Box>
          </BenchmarkCardTooltip>
        </BenchmarkValueWrapper>
      )}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {category.data?.length > 0 && (
          <BenchmarkTable aria-label="benchmarking table">
            <TableHead>
              <TableRow>
                <TableCell>CPI ID</TableCell>
                <TableCell align="left">Less Strict</TableCell>
                <TableCell>Similar SLAs</TableCell>
                <TableCell>More Strict</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortCategoryData?.length &&
                sortCategoryData.map((data) => (
                  <TableRow key={data.cpi_name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <BenchmarkCardTooltip
                      PopperProps={popperProps}
                      sx={{
                        [`& .${tooltipClasses.tooltip}`]: {
                          width: '300px'
                        }
                      }}
                      title={
                        <>
                          <Box className="tooltip-wrapper">
                            <Typography className="tooltip-cpi-text">{getTitle(data.cpi_name)}</Typography>
                          </Box>
                        </>
                      }
                    >
                      <TableCell className="cpi-cell" onClick={() => toLink(data.cpi_display_name)}>
                        {removeLine(data.cpi_display_name)}
                      </TableCell>
                    </BenchmarkCardTooltip>
                    {!data.summary || (!data.summary.less && !data.summary.equal && !data.summary.more) ? (
                      <>
                        <TableCell colSpan={3}>
                          <NoBenchmarkWrapper>
                            <InfoIconFilled />
                            <Typography className="no-benchmark-text">
                              No benchmark data available for this CPI
                            </Typography>
                          </NoBenchmarkWrapper>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="left">{data.summary?.less}%</TableCell>
                        <TableCell align="center">{data.summary?.equal}%</TableCell>
                        <TableCell align="right">{data.summary?.more}%</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </BenchmarkTable>
        )}
        <CategoryBenchmarkWrapper>
          <BenchmarkTable aria-label="benchmarking category table">
            <TableHead>
              <TableRow>
                <TableCell className="category-title">Category Benchmark:</TableCell>
                {category?.summary &&
                  Object.values(category.summary).map((n, i) => (
                    <TableCell
                      key={n}
                      className="category-cell"
                      align={i === 0 ? 'left' : i === 1 ? 'center' : 'right'}
                      sx={i === 0 ? { paddingLeft: '0px' } : null}
                    >
                      {n ? n.toFixed(1) : 0}%
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
          </BenchmarkTable>
        </CategoryBenchmarkWrapper>
      </Collapse>

      {!hasBenchmark && (
        <Box className="no-category">
          <img src="/info-panel.svg" alt="info" />
          No benchmark data available for this CPI Category.
        </Box>
      )}
    </BenchmarkCardContainer>
  )
}

export default BenchmarkCard
