import { Box, Menu } from '@mui/material'
import styled from '@emotion/styled'
import theme from 'theme'

export const Wrapper = styled(Box)``

export const MenuWrapper = styled(Menu)`
  .MuiList-root {
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.borderLow};
    background: ${theme.colors.surfaceItemBackground};
    padding: ${theme.spacing.xSmall}px;

    .MuiFormGroup-root {
      .MuiFormControlLabel-root {
        border-radius: ${theme.attributes.borderRadius.small};
        height: 52px;
        margin: 0;
        padding: ${theme.spacing.xSmall}px;
        width: 100%;

        &:hover {
          background: rgb(87, 63, 86);
        }
      }
    }

    .MuiMenuItem-root {
      border-radius: ${theme.attributes.borderRadius.small};
      padding: ${theme.spacing.xSmall}px;

      &:hover {
        background: ${theme.colors.primaryLight};
      }

      .MuiTypography-root {
        padding-left: ${theme.spacing.xSmall}px;
      }
    }
  }

  .action {
    border-top: 1px solid ${theme.colors.borderLow};
    padding-bottom: ${theme.spacing.xSmall}px;
    padding-top: ${theme.spacing.xSmall}px;

    button {
      width: 100%;
    }
  }
`
