import { ToggleButton } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const StyledToggleButton = styled(ToggleButton)`
  border-radius: ${theme.attributes.borderRadius.large};
  border: 1px solid ${theme.colors.controlBorder};

  &:hover {
    background: ${theme.baseColors.secondary[30]};
    color: ${theme.colors.textPrimary};
    border: 1px solid ${theme.colors.controlBorder};
    box-shadow: ${theme.attributes.boxShadow.quaternary};
  }

  &.Mui-selected {
    background: ${theme.colors.textPrimary};
    border: 1px solid ${theme.colors.controlBorder};
    color: ${theme.baseColors.primary[20]};

    &:hover {
      background: ${theme.baseColors.primary[70]};
      color: ${theme.baseColors.primary[20]};
    }
  }

  &:focus {
    border: 1px solid ${theme.colors.white};
  }

  &.Mui-disabled {
    color: ${theme.baseColors.greyscale[50]};
    background: ${theme.baseColors.surface[50]};
  }
`
