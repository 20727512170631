import { FC, ChangeEvent, useState, useEffect } from 'react'
import { Typography, FormGroup, FormControlLabel, Checkbox, IconButton, DialogContent, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import theme from 'theme'

import { Dialog } from './addCpisToIntegration.styles'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { closeModal, modalPropsSelector } from 'store/modals/modalSlice'
import ThemeButton from 'ui/components/buttons/primary/ThemeButton'
import { ManifestIntegrationSupportedCPI } from '../../../../../../models'

const AddCpisToIntegration: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const [state, setState] = useState<Record<string, boolean>>({
    cpi: false
  })
  const [loading, setLoading] = useState(false)
  const [cpisList, setCpisList] = useState<any[]>([])
  const [initializing, setInitializing] = useState(true)

  useEffect(() => {
    if (initializing && modalProps && modalProps.props && modalProps.props.cpisList) {
      const state: Record<string, boolean> = {}
      modalProps.props.cpisList.forEach((cpi: ManifestIntegrationSupportedCPI) => {
        state[cpi.name] = true
      })
      setState(state)
      setCpisList(modalProps.props.cpisList as any)
      setInitializing(false)
    }
  }, [state, modalProps, initializing])

  const handleClose = () => {
    if (modalProps?.props?.cancelNavigation) {
      modalProps?.props?.cancelNavigation()
    }
    dispatch(closeModal())
  }

  const handleOk = async () => {
    setLoading(true)
    if (modalProps?.props?.confirmNavigation) {
      modalProps?.props?.confirmNavigation()
    }

    handleClose()

    if (modalProps?.props?.addCpisCallback) {
      const cpis = []
      for (const [key, value] of Object.entries(state)) {
        if (value) {
          cpis.push(key)
        }
      }
      await modalProps.props.addCpisCallback(cpis)
    }

    setLoading(false)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    })
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="enlarge-image-dialog" open={modalProps.open}>
      <Box className="header">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 2.6665C8.63996 2.6665 2.66663 8.63984 2.66663 15.9998C2.66663 23.3598 8.63996 29.3332 16 29.3332C23.36 29.3332 29.3333 23.3598 29.3333 15.9998C29.3333 8.63984 23.36 2.6665 16 2.6665ZM17.3333 22.6665H14.6666V14.6665H17.3333V22.6665ZM17.3333 11.9998H14.6666V9.33317H17.3333V11.9998Z"
            fill={theme.colors.textPrimary}
          />
        </svg>

        <Typography className="header-title">Add Integration as Data Source to CPIs</Typography>
      </Box>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: theme.colors.textPrimary,

          '&:hover': {
            background: theme.baseColors.primary[20],
            svg: {
              path: {
                fill: theme.colors.white
              }
            }
          }
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Typography className="content-header">
          {modalProps?.props?.integrationName} Can be added as a data source for the CPIs below. Inactive CPIs will be
          activated as a result.
        </Typography>
        <Typography className="content-description">
          SLA settings for each CPI can be customized from the CPI page.
        </Typography>
        <Typography className="content-description">
          To proceed, select the CPIs you want to enable, and click 'OK'.
        </Typography>

        {/*        {modalProps?.props?.cpisList && modalProps?.props?.cpisList.length > 0 && (
          <FormGroup className="checkboxes-form-group">
            {modalProps.props.cpisList.map((cpi, index) => ( */}
        {!initializing && cpisList.length > 0 && (
          <FormGroup className="checkboxes-form-group">
            {cpisList.map((cpi, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={state[cpi.name]}
                    onChange={handleChange}
                    icon={
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke={theme.colors.controlBorder} />
                      </svg>
                    }
                    checkedIcon={
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="2" fill={theme.colors.textPrimary} />
                        <path d="M8 14.4L4 10.4L5.4 9L8 11.6L14.6 5L16 6.4L8 14.4Z" fill={theme.baseColors.primary[20]} />
                      </svg>
                    }
                  />
                }
                name={cpi.name}
                label={`${cpi.display_name || ''} ${cpi.title}`}
              />
            ))}
          </FormGroup>
        )}
      </DialogContent>

      <Box className="footer">
        <ThemeButton themevariant="secondary" onClick={handleClose} sx={{ minWidth: '95px' }} disabled={loading}>
          Skip
        </ThemeButton>
        <ThemeButton themevariant="primary" onClick={handleOk} sx={{ minWidth: '95px' }} disabled={loading}>
          OK
        </ThemeButton>
      </Box>
    </Dialog>
  )
}

export default AddCpisToIntegration
