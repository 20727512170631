import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const SSMNistPillarWrapper = styled(Box)<{ index: number }>`
  border-radius: ${theme.attributes.borderRadius.quaternary} 0 0 ${theme.attributes.borderRadius.quaternary};
  border: 0.5px solid ${theme.colors.controlBorder};
  background: #8e9aff;
  display: flex;
  align-items: center;
  justify-self: end;
  align-self: center;
  width: 141px;
  height: 36px;
  padding: ${theme.spacing.xsm}px 0 ${theme.spacing.xsm}px ${theme.spacing.medium}px;
  margin-right: 1px;

  &:first-of-type {
    margin-top: 36px;
  }

  ${({ index }) =>
    index === 1 &&
    `
      background: #C77EDA;
  `};
  ${({ index }) =>
    index === 2 &&
    `
      background: #FFDE6A;
  `};
  ${({ index }) =>
    index === 3 &&
    `
      background: #FF7F6B;
  `};
  ${({ index }) =>
    index === 4 &&
    `
      background: #99FDC0;
  `};
  ${({ index }) =>
    index === 5 &&
    `
      background: #FF9E47;
  `};

  .MuiTypography-root {
    color: ${theme.baseColors.greyscale[0]};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-left: ${theme.spacing.xSmall}px;
  }

  &.vendor-details-nist {
    border-radius: ${theme.attributes.borderRadius.quaternary};
    min-width: 120px;
    height: 32px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

export const SSMGridHeader = styled(Box)`
  background: ${theme.baseColors.surface[50]};
  border-radius: ${theme.attributes.borderRadius.tertiary} ${theme.attributes.borderRadius.tertiary} 0 0;
  border: 0.365px solid ${theme.colors.controlBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.md}px 0;
  height: 44px;
  width: 205px;
  margin-bottom: 2px;

  @media (min-width: 1440px) {
    width: 230px;
  }

  @media (min-width: 1920px) {
    width: 310px;
    height: 61px;
  }

  .MuiTypography-root {
    color: ${theme.colors.neutral};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
  }
`

export const SSMCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 205px;
  cursor: pointer;

  @media (min-width: 1440px) {
    width: 230px;
  }

  @media (min-width: 1920px) {
    width: 310px;
  }

  .add-vendor-button {
    visibility: hidden;
    position: absolute;
    bottom: -10px;
    right: 0;

    .add-vendor-icon {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &:hover {
    .add-vendor-button {
      visibility: visible;
    }
  }
`

export const SSMCardInner = styled(Box)<{ itemslength?: number; isediting?: string; isCurrentEdit?: boolean }>`
  border-radius: 1.098px;
  border: 0.272151px solid ${theme.colors.controlBorder};
  background: ${theme.colors.surfaceItemBackgroundLow};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: ${theme.spacing.medium}px;
  grid-row-gap: ${theme.spacing.md}px;
  width: 205px;
  height: 86px;
  position: relative;
  padding: 7px 26px;
  cursor: pointer;

  @media (min-width: 1440px) {
    width: 230px;
    height: 96px;
    padding: ${theme.spacing.md}px 45px;
  }

  @media (min-width: 1920px) {
    width: 310px;
    height: 120px;
  }

  ${({ isCurrentEdit }) =>
    isCurrentEdit &&
    `
     background: ${theme.colors.primaryLight};
    `};

  ${({ isediting }) =>
    isediting &&
    isediting === 'true' &&
    `
      &:hover {
        border-radius: 2px;
        border: 1px solid #DEBDD9;
        background: ${theme.baseColors.surface[60]};
        box-shadow: ${theme.attributes.boxShadow.chipCircle};
        cursor: pointer;
      }
    `};

  ${({ itemslength }) =>
    itemslength &&
    itemslength > 3 &&
    `
    &:hover {
      cursor: default;
    }
  `};

  .text {
    color: ${theme.colors.neutral};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`

export const SSMCardIntegration = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IntegrationLogoWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .cancel-icon-button {
    position: absolute;
    top: -14px;
    right: -20px;
    color: ${theme.colors.info[50]};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3)) drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.15));

    &:hover {
      cursor: pointer;
    }
  }
`

export const IntegrationLogo = styled(Box)<{ logo: string }>`
  border: 1px solid #342f32;
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  width: 24px;
  height: 24px;
  border-radius: ${theme.attributes.borderRadius.normal};
  background: ${({ logo }) => `url("${logo}") no-repeat center`};
  background-size: cover;
  cursor: pointer;
  margin-right: ${theme.spacing.xSmall}px;
`

export const NoIntegrationLogo = styled(Box)`
  border: 1px solid #342f32;
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  width: 41px;
  height: 41px;
  border-radius: ${theme.attributes.borderRadius.circle};
  background: ${theme.baseColors.greyscale[80]};
  background-size: cover;
  margin-left: ${theme.spacing.half}px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1280px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1600px) and (max-height: 1079px) {
    width: 22px;
    height: 22px;
  }

  .no-integration-logo-letter {
    color: #342f32;
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.mediumSmall};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`
