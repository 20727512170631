import { FC, SVGProps } from 'react'
import theme from 'theme'

const ExitToLinkIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.375 12.75L16.125 9L12.375 5.25L11.3175 6.3075L13.2525 8.25H6.375V9.75H13.2525L11.3175 11.6925L12.375 12.75Z"
      fill={theme.colors.textPrimary}
    />
    <path
      d="M13.875 14.25H3.375V3.75H13.875V5.25H15.375V3.75C15.375 2.925 14.7075 2.25 13.875 2.25H3.375C2.55 2.25 1.875 2.925 1.875 3.75V14.25C1.875 15.075 2.55 15.75 3.375 15.75H13.875C14.7075 15.75 15.375 15.075 15.375 14.25V12.75H13.875V14.25Z"
      fill={theme.colors.textPrimary}
    />
  </svg>
)

export default ExitToLinkIcon
