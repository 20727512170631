import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const CategoryWrapper = styled(Box)<{ titlelength?: number; ishovered?: string }>`
  padding: ${theme.spacing.small}px ${theme.spacing.md}px ${theme.spacing.md}px ${theme.spacing.small}px;
  border-radius: ${theme.attributes.borderRadius.normal};
  background: ${theme.colors.backgroundMedium};
  box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
  height: 234px;
  width: calc(100% - 12px);

  ${({ ishovered }) =>
    ishovered &&
    ishovered === 'true' &&
    `
      background: ${theme.colors.primaryLight};
  `};

  .card-wrapper-loading {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.xSmall}px;

    .card-loading {
      border-radius: ${theme.attributes.borderRadius.normal};
      background: ${theme.colors.backgroundLight};
      flex-basis: 33.33%;
      width: 100%;
      height: 84px;
    }
  }

  .header-loading {
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.small}px;

    .category-title-loading {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};
      margin-left: ${theme.spacing.small}px;
    }
  }

  .header {
    display: flex;
    align-items: flex-start;
    margin-bottom: ${theme.spacing.xSmall}px;
    flex-direction: column;

    ${({ titlelength }) =>
      titlelength &&
      titlelength > 24 &&
      `
        margin-bottom: ${theme.spacing.xSmall}px;
      `};

    .category-title {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;

      &:hover {
        color: #fbd8f5;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .category-margin {
      margin-bottom: 26px;
    }

    .over-perform {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.half}px;
      margin-top: ${theme.spacing.xsm}px;

      b {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.large};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      .value {
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.medium};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }

    .action {
      display: inline-flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
      cursor: pointer;

      .category-all {
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.mediumSmall};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 16px;
        letter-spacing: 0.5px;
        color: ${theme.colors.textPrimary};
      }
    }
  }

  .data-sources-wrapper {
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.md}px;
    gap: 5px;

    img {
      border-radius: ${theme.attributes.borderRadius.circle};
    }

    ${({ titlelength }) =>
      titlelength &&
      titlelength > 24 &&
      `
          margin-top: ${theme.spacing.xsm}px;
      `};

    .data-sources-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    .error-wrapper {
      border-radius: ${theme.attributes.borderRadius.circle};
      position: relative;
      cursor: pointer;
      border: 1px solid ${theme.baseColors.info[50]};
      box-sizing: content-box;

      svg {
        position: absolute;
        bottom: -2px;
        left: 5px;

        &.error-content {
          left: 7px;
          bottom: 0;
        }
      }
    }

    .data-sources-count-text {
      color: ${theme.baseColors.greyscale[80]};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .available-status-sources-text {
    color: ${theme.baseColors.greyscale[80]};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.medium};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: ${theme.spacing.small}px;
    opacity: 0.38;

    ${({ titlelength }) =>
      titlelength &&
      titlelength > 24 &&
      `
          margin-top: ${theme.spacing.xsm}px;
      `};
  }
`

export const ExploreCategoryWrapper = styled(Box)`
  display: flex;
  width: calc(100% - 12px);

  .scale {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .scale-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
      opacity: 0.38;
    }
  }

  .chart-button-wrapper {
    background-color: rgba(30, 26, 29, 0.38);
    height: 116px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colors.borderLow};
    border-radius: ${theme.attributes.borderRadius.small};

    .MuiButtonBase-root {
      color: #debdd9;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      text-transform: none;
      opacity: 1 !important;

      svg {
        margin-right: ${theme.spacing.xSmall}px;
      }
    }

    @media (min-width: 1920px) {
      height: 124px;
    }
  }
`

export const EmptyDataCategoryWrapper = styled(Box)`
  display: flex;
  width: calc(100% - 12px);

  .scale {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .scale-text {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.mediumSmall};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .chart-button-wrapper {
    background-color: ${theme.colors.primaryDark};
    height: 116px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colors.borderLow};
    border-radius: ${theme.attributes.borderRadius.small};

    .no-data-text {
      color: ${theme.colors.controlBorder};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.medium};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    .MuiButtonBase-root {
      color: #debdd9;
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 20px;
      letter-spacing: 0.1px;
      text-transform: none;

      svg {
        margin-right: ${theme.spacing.xSmall}px;
      }
    }

    @media (min-width: 1920px) {
      height: 124px;
    }
  }
`
