import { FC, ReactNode } from 'react'
import { Box, Stepper as MuiStepper, Step, StepLabel, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { styled as muiStyled } from '@mui/material/styles'
import { StepIconProps } from '@mui/material/StepIcon'
import theme from 'theme'

const Stepper = styled(MuiStepper)`
  .MuiStepConnector-root {
    color: ${theme.colors.controlBorder};
  }

  .MuiStep-root {
    .MuiStepLabel-root {
      .MuiStepLabel-label {
        color: ${theme.colors.neutral};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      svg {
        circle {
          color: ${theme.baseColors.primary[40]};
        }
        path {
          fill: ${theme.baseColors.primary[40]};
        }
      }
    }
  }
`

const IconWrapper = muiStyled('div')<{
  ownerState: { completed?: boolean; active?: boolean; icon: number }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}>(({ theme: thisTheme, ownerState }) => ({
  zIndex: 1,
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: theme.attributes.borderRadius.circle,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  '.text': {
    color: theme.colors.neutral,
    fontFamily: theme.typography.fontFamily.primary,
    fontSize: theme.typography.fontSize.large,
    fontWeight: theme.typography.fontWeight.bold,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    position: 'absolute',
    top: '2px',
    left: '9px'
  },

  ...(!ownerState.active && {
    background: `url("/stepper/inactive-step.svg") no-repeat`,
    backgroundSize: 'cover',

    '.text': {
      color: '#342F32',
      fontFamily: theme.typography.fontFamily.primary,
      fontSize: theme.typography.fontSize.large,
      fontWeight: theme.typography.fontWeight.bold,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      position: 'absolute',
      top: '2px',
      left: `${theme.spacing.xSmall}px`
    }
  }),
  ...(ownerState.active && {
    background: `url("/stepper/active-step.svg") no-repeat`,
    backgroundSize: 'cover',

    ...(ownerState.icon === 2 && {
      '.text': {
        color: theme.colors.neutral,
        fontFamily: theme.typography.fontFamily.primary,
        fontSize: theme.typography.fontSize.large,
        fontWeight: theme.typography.fontWeight.bold,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        position: 'absolute',
        top: '2px',
        left: `${theme.spacing.xSmall}px`
      }
    })
  }),
  ...(ownerState.completed && {
    background: `url("/stepper/step-completed.svg") no-repeat`,
    backgroundSize: 'cover'
  })
}))

function CustomStepIconComponent(props: StepIconProps) {
  const { active, completed, icon, className } = props

  return (
    <IconWrapper ownerState={{ completed, active, icon: icon as number }} className={className}>
      {!props.completed && <Typography className="text">{icon}</Typography>}
    </IconWrapper>
  )
}

interface Props {
  steps: string[]
  activeStep: number
}

const CustomizeReportStepper: FC<Props> = ({ steps, activeStep }) => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Box sx={{ width: '516px' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: ReactNode
          } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} StepIconComponent={CustomStepIconComponent}>
                {label}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  </Box>
)

export default CustomizeReportStepper
