import styled from '@emotion/styled'
import { Box } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  border: 1px solid ${theme.colors.borderLow};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px ${theme.spacing.medium}px;

  @media (min-height: 801px) {
    min-height: 544px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      margin-top: ${theme.spacing.large}px;
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xxxLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;
      margin-bottom: ${theme.spacing.xSmall}px;
    }

    .description {
      color: ${theme.colors.white};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      text-align: center;

      .colored {
        color: ${theme.colors.textPrimary};
        display: inline-block;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
`
