import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'
import axios from '../../../../lib/axios'
import dayjs from 'dayjs'

/* Utils */
import {
  Benchmark,
  BenchmarkRowData,
  BenchmarkScore,
  BenchmarkScoreCategory,
  BenchmarkScoreCategoryData,
  CategoryDataPayload,
  CategoryFramework,
  CPITimePeriod,
  DataStatus,
  HistoryBenchmarkData,
  HistoryBenchmarkDataValues,
  HistoryPerformanceData,
  IChartDataCategory,
  IFramework,
  IntegrationStatusMessage,
  PerformanceScoreCpi,
  PerformanceScoreData,
  PerformanceScoreDataSource
} from '../../../../models'
import {
  categoryToUrl,
  createPerformanceScoreData,
  getPayloadTimePeriod,
  getSlaUnit,
  urlToCategory,
  urlToCategoryCapitalized
} from '../utils'
import { useAppSelector } from '../../../../store/hooks'
import { accountSettingsSelector } from '../../../../store/user/userSlice'
// import { useFramework } from '../../../../hooks/useFramework'
import { dateFormat } from '../../../../lib/utils'
import { DataSourceError } from '../../dashboard/sections/category-activity/components/DataSources'

/* Components */
import CategoryHeader from './components/CategoryHeader'
import PerformanceTab from './sections/current-findings'
import BenchmarkingTab from './sections/historical-data'
import { formatPerformanceDataCategory } from '../../dashboard/utils'
import TabPanel from '../library-details/components/LibraryDetailsTabPanel'
import ContentWrapper from '../../../components/header/ContentWrapper'

const IAM_OLD = 'identity-and-access-management'
const IAM_NEW = 'iam'

export interface Category {
  id: string
  title: string
}

const CategoryPage: FC = () => {
  const location = useLocation()
  const accountSettings = useAppSelector(accountSettingsSelector)
  const framework = accountSettings?.cyFramework
  const [categoryName, setCategoryName] = useState('')
  const [activeCategories, setActiveCategories] = useState<IChartDataCategory[]>([])
  const [tabValue, setTabValue] = useState(0)
  // const [prev, setPrev] = useState('Cloud Security')
  // const [next, setNext] = useState('Identity & Access Management')
  // const [categories, setCategories] = useState<string[]>([])
  // const [currentCategory, setCurrentCategory] = useState<any>(null)
  // const [categoriesData, setCategoriesData] = useState<PerformanceItem[]>([])
  const [performanceValue, setPerformanceValue] = useState(0)
  const [performanceRows, setPerformanceRows] = useState<PerformanceScoreCpi[]>([])
  const [performanceLoading, setPerformanceLoading] = useState(true)
  // const [inactiveCpis, setInactiveCpis] = useState<InactiveCPI[]>([])
  const [historyPerformanceData, setHistoryPerformanceData] = useState<HistoryPerformanceData[]>([])
  const [historyBenchmarkData, setHistoryBenchmarkData] = useState<HistoryBenchmarkData[]>([])
  const [historyBenchmarkValues, setHistoryBenchmarkValues] = useState<HistoryBenchmarkDataValues[]>([])
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<CPITimePeriod>(CPITimePeriod.Month)
  const [allCategories, setAllCategories] = useState<Category[]>([])
  const [benchmarkingLoading, setBenchmarkingLoading] = useState(true)
  const [benchmark, setBenchmark] = useState<Benchmark | null>(null)
  const [benchmarkForCategory, setBenchmarkForCategory] = useState<BenchmarkScoreCategory | null>(null)
  const [benchmarkingRows, setBenchmarkingRows] = useState<BenchmarkRowData[]>([])
  const [lastUpdatePerformance, setLastUpdatePerformance] = useState<string>('')
  const [customFramework, setCustomFramework] = useState<IFramework | null>(null)
  const [dataSourceErrors, setDataSourceErrors] = useState<DataSourceError[]>([])
  // const { framework } = useFramework()
  const fw = useMemo(() => (framework === 'onyxia' ? 'onyxia' : framework === 'nist' ? 'nist' : 'custom'), [framework])

  // const singleItemOnScreen = useMediaQuery('(max-width:1440px)')
  // const containersMaxWidth = singleItemOnScreen ? 624 : 552
  const containersMaxWidth = 624

  const defaultPerformancePayload = {
    timePeriod: getPayloadTimePeriod(selectedTimePeriod),
    category: urlToCategoryCapitalized(categoryName)
  }

  const fetchIntegrationsStatus = async (performanceRows: PerformanceScoreCpi[]) => {
    const allDataSources = performanceRows.map((c) => c.dataSources).flat()
    const allConfigs = allDataSources.reduce(
      (accumulator: PerformanceScoreDataSource[], current: PerformanceScoreDataSource) => {
        if (!accumulator.find((item) => item.integrationConfig.id === current.integrationConfig.id)) {
          accumulator.push(current as any)
        }
        return accumulator
      },
      []
    )
    const instanceNames = allConfigs.map((c) => c.integrationConfig.name)

    try {
      const res = await axios.get('/api/v3/integrations/configured?loadStatus=true')

      if (res.status === 200) {
        const { instances } = res.data
        const filteredInstances = instances.filter((c: any) => instanceNames.includes(c.name))

        const errors: DataSourceError[] = []
        filteredInstances.forEach((instance: any) => {
          const { id, syncStatus } = instance
          if (syncStatus && syncStatus.status && syncStatus.status === IntegrationStatusMessage.failed) {
            errors.push({
              id,
              status: syncStatus.status,
              errorMessage: syncStatus.errorMessage
            })
          }
        })
        setDataSourceErrors(errors)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getCustomFramework = async (): Promise<void> => {
    try {
      const res = await axios.get<{ framework: IFramework }>('/api/v3/frameworks/custom')

      if (res.status.toString().includes('20')) {
        const customFramework = res.data.framework

        if (customFramework) {
          setCustomFramework(customFramework)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      // setInitialLoad(true)
    }
  }

  // const fetchAllCpis = async () => {
  //   try {
  //     const res = await axios.get('/api/v3/cpis')
  //
  //     if (res.status === 200) {
  //       const {
  //         data: { cpis }
  //       } = res.data
  //       setAllCpis(cpis)
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  const fetchAllCategories = async () => {
    const res = await axios.get('/api/v1/frameworks/categories')

    if (res.status === 200) {
      setAllCategories(res.data.data.categories || [])
      setCategoryName(urlToCategory(location.pathname.replace('/library/categories/', '')))
      location.pathname.replace('/library/categories/', '')
    }
  }

  const fetchHistoryPerformance = async (payload: CategoryDataPayload = defaultPerformancePayload) => {
    const res = await axios.post(`/api/v3/cpis/performance_history`, payload)

    if (res.status === 201) {
      const { data } = res.data
      setHistoryPerformanceData(data)
    }
  }

  const fetchHistoryBenchmark = useCallback(async (payload: CategoryDataPayload = defaultPerformancePayload) => {
    const res = await axios.post(`/api/v3/benchmarks/history`, payload)
    if (res.status === 201) {
      const { data } = res.data
      setHistoryBenchmarkData(data)
    }
  }, [])

  const fetchHistoricalData = async () => {
    // const tenantRes = await axios.get('/api/v3/tenant')
    const tenantFramework = getTenantFramework(accountSettings)

    let categoryName = location.pathname.replace('/library/categories/', '')
    if (categoryName === 'identity-and-access-management') {
      categoryName = 'iam'
    }
    if (customFramework && tenantFramework === 'custom') {
      categoryName = categoryName.replaceAll('-', ' ')
    }

    try {
      const category = allCategories.find((n) =>
        customFramework && tenantFramework === 'custom' ? n.title.toLowerCase() === categoryName : n.id === categoryName
      )
      const payload = {
        timePeriod: getPayloadTimePeriod(selectedTimePeriod),
        category: category?.id === IAM_OLD ? IAM_NEW : category?.id || ''
      }
      const promises = [fetchHistoryPerformance(payload), fetchHistoryBenchmark(payload)]
      await Promise.all(promises)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    Promise.all([fetchAllCategories(), getCustomFramework()])
  }, [])

  useEffect(() => {
    if (allCategories.length > 0 && accountSettings) {
      fetchHistoricalData().catch((e) => e)
    }
  }, [allCategories, customFramework, accountSettings, location])

  const fetchInitialData = async () => {
    setBenchmarkingLoading(true)
    try {
      const performanceRows = await getPerformanceData().catch((e) => e)
      if (performanceRows) {
        await fetchIntegrationsStatus(performanceRows)
      }
      await setBenchmarkingData().catch((e) => e)
    } catch (err) {
      console.error(err)
    } finally {
      setBenchmarkingLoading(false)
    }
  }

  useEffect(() => {
    if (accountSettings) {
      fetchInitialData().catch((e) => e)
    }
  }, [location, accountSettings])

  useEffect(() => {
    if (location && location.pathname) {
      const category = location.pathname.replace('/library/categories/', '')
      const categoryName = urlToCategory(category)
      setCategoryName(categoryName)
    }
  }, [location])

  // useEffect(() => {
  //   fetchAllCpis().catch((e) => e)
  // }, [])

  // useEffect(() => {
  //   if (location.pathname && location.pathname.includes('library')) {
  //     const category = location.pathname.replace('/library/', '')
  //     const categoryName = urlToCategory(category)
  //     setCategoryName(categoryName)
  //
  // /*
  //  * Set previous and next categories based on categories from data
  //  * */
  // const currentIndex = categories.findIndex((n) => n.toLowerCase() === categoryName)
  // const previous = currentIndex === 0 ? categories[categories.length - 1] : categories[currentIndex - 1]
  // const next = currentIndex === categories.length - 1 ? categories[0] : categories[currentIndex + 1]
  // setPrev(previous)
  // setNext(next)
  // setCurrentCategory(categories[currentIndex])
  // }
  // }, [location.pathname, categories])

  const getTenantFramework = (accountSettings: any) => {
    if (accountSettings && accountSettings.cyFramework) {
      const { cyFramework } = accountSettings
      if (cyFramework === 'onyxia') {
        return 'onyxia'
      }
      if (cyFramework === 'nist') {
        return 'nist'
      }
      return 'custom'
    }

    return ''
  }

  const getPerformanceData = async () => {
    setPerformanceLoading(true)

    try {
      const tenantFramework = getTenantFramework(accountSettings)
      const res = await axios.get('/api/v3/cpis/performance_score')

      if (res.status === 200) {
        const {
          data: { data }
        } = res
        const d: PerformanceScoreData = data
        setLastUpdatePerformance(dayjs(d.date).format(dateFormat.lastUpdate))
        const formattedData = formatPerformanceDataCategory(d.categories)
        const activeItems = formattedData.filter((item) => item.score > 0 && item.score !== 100)
        setActiveCategories(activeItems)

        let categoryStr = location.pathname.replace('/library/categories/', '')
        if (categoryStr.includes('identity')) {
          categoryStr = 'iam'
        }
        if (tenantFramework === 'custom' && customFramework) {
          categoryStr = categoryStr.replaceAll('-', ' ').replaceAll('and', '&')
        }

        const category = d.categories.find((n) =>
          customFramework && tenantFramework === 'custom'
            ? categoryToUrl(n.name.toLowerCase()) === categoryToUrl(categoryStr)
            : n.id === categoryStr
        )

        if (category) {
          if (!category.details) {
            setPerformanceRows([])
            return []
          }
          setPerformanceValue(category.score ?? 0)
          const performanceRows: PerformanceScoreCpi[] = []
          const sortedCategories = category.details.sort((a: PerformanceScoreCpi, b: PerformanceScoreCpi) => {
            if (a.cpiName < b.cpiName) {
              return -1
            }
            if (a.cpiName > b.cpiName) {
              return 1
            }
            return 0
          })
          sortedCategories.forEach((cpi) => {
            const item = createPerformanceScoreData(
              cpi.cpiDisplayName,
              cpi.date,
              cpi.value,
              cpi.cpiDisplayName,
              cpi.cpiTitle,
              cpi.section,
              cpi.dataSources,
              cpi.weight,
              cpi.sla,
              `${cpi.value}${getSlaUnit(cpi.unit)}`,
              Math.round(cpi.score),
              cpi.unit,
              [DataStatus.Initializing].includes(cpi.status) ? DataStatus.Initializing : cpi.status
            )

            performanceRows.push(item)
          })

          setPerformanceRows(performanceRows)
          return performanceRows
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setPerformanceLoading(false)
    }

    return []
  }

  // useEffect(() => {
  //   if (allCpis.length > 0 && categoriesData.length > 0) {
  // const allCpisByCategory = allCpis.filter((cpi) => cpi.categories[0].toLowerCase() === categoryName)
  // const activeCPIsByCategory = categoriesData.filter((n) => n.category.toLowerCase() === categoryName)
  // if (!activeCPIsByCategory[0].details) return
  // const activeCpiNames = activeCPIsByCategory[0].details.map((n) => n.cpiDisplayName)
  // const inactiveCpis = allCpisByCategory.filter((n) => !activeCpiNames.includes(n.display_name))
  // const adjustedInactiveCpis: InactiveCPI[] = inactiveCpis.map((n) => ({
  //   name: n.display_name,
  //   title: n.title,
  //   potentialSources: n.supported_integrations.map((c) => c.name)
  // }))
  // setInactiveCpis(adjustedInactiveCpis)
  // }
  // }, [allCpis, categoriesData])

  // const handlePrev = () => {
  //   const categoryUrl = categoryToUrl(prev)
  //   navigate(`/library/${categoryUrl}`)
  // }

  // const handleNext = () => {
  //   const categoryUrl = categoryToUrl(next)
  //   navigate(`/library/${categoryUrl}`)
  // }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
    // navigate(newValue === 1 ? '#data-sources' : '#')
  }

  const fetchBenchmarkingData = async () => {
    setBenchmarkingLoading(true)

    try {
      const res = await axios.get('/api/v3/benchmarks/cpi_industry_scores')

      if (res.status === 200) {
        const { benchmark }: { benchmark: Benchmark } = res.data
        return benchmark ?? null
      }
    } catch (e) {
      console.error(e)
    }

    return null
  }

  const setBenchmarkingData = async () => {
    try {
      const bmark = await fetchBenchmarkingData()
      setBenchmark(bmark)

      if (bmark && bmark.score.length > 0) {
        const benchmarkScore: BenchmarkScore =
          bmark.score.find((item) => item.industry_name === 'All Industries') || bmark.score[0]
        let url = location.pathname.replace('/library/categories/', '')
        if (url === 'identity-and-access-management') {
          url = 'iam'
        }
        let found = benchmarkScore.categories.find((category) => category.category_id.toLowerCase() === url)
        if (fw === 'custom' && customFramework) {
          found = benchmarkScore.categories.find(
            (category) => categoryToUrl(category.category_name.toLowerCase()) === categoryToUrl(url)
          )
        }

        if (found && found.data) {
          setBenchmarkForCategory(found)
          const benchmarkingRows: any[] = []
          const sortedData = found.data.sort((a: BenchmarkScoreCategoryData, b: BenchmarkScoreCategoryData) => {
            if (a.cpi_display_name < b.cpi_display_name) {
              return -1
            }
            if (a.cpi_display_name > b.cpi_display_name) {
              return 1
            }
            return 0
          })

          sortedData.forEach((data) => {
            const item: BenchmarkRowData = {
              name: data.cpi_display_name,
              cpiTitle: data.cpi_title,
              weight: `${data.cpi_weight}%`,
              summary: data.summary
            }
            benchmarkingRows.push(item)
          })
          setBenchmarkingRows(benchmarkingRows)
        } else {
          setBenchmarkForCategory(null)
          setBenchmarkingRows([])
          // setTabValue(0)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setBenchmarkingLoading(false)
    }
  }

  const catId = location.pathname.replace('/library/categories/', '')
  const title = useMemo(() => urlToCategory(location.pathname.replace('/library/categories/', '')), [location.pathname])
  const category = {
    id: catId.includes('identity') ? 'iam' : catId,
    title,
    framework_id: accountSettings?.cyFramework || CategoryFramework.Onyxia
  }

  const categoryNavigationCallback = () => {
    setSelectedTimePeriod(CPITimePeriod.Month)
  }

  // if (performanceLoading || benchmarkingLoading) {
  // if (performanceLoading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center'
  //       }}
  //     >
  //       <CircularProgress size="46px" color="secondary" />
  //     </Box>
  //   )
  // }
  return (
    <>
      <CategoryHeader
        category={category}
        categoryName={categoryName}
        activeCategories={activeCategories}
        tabLabels={['Performance', 'Benchmarking']}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        loading={performanceLoading}
        categoryNavigationCallback={categoryNavigationCallback}
      />
      {/*      <CPITable
        allCpis={allCpis}
        title="Active Cyber Performance Indicators"
        active={true}
        categoryName={categoryName}
        categoryData={categoriesData.filter((data) => data.category.toLowerCase() === categoryName)}
      /> */}

      {performanceLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '48px'
          }}
        >
          <CircularProgress size="46px" color="secondary" />
        </Box>
      ) : (
        <ContentWrapper line={2} sx={{ marginRight: historyPerformanceData?.length > 0 ? 0 : '12px !important' }}>
          <>
            <TabPanel value={tabValue} index={0}>
              <PerformanceTab
                allCategories={allCategories}
                fw={fw}
                customFramework={customFramework}
                containersMaxWidth={containersMaxWidth}
                categoryName={categoryName}
                performanceRows={performanceRows}
                performanceValue={performanceValue}
                performanceLoading={performanceLoading}
                historyBenchmarkData={historyBenchmarkData}
                historyPerformanceData={historyPerformanceData}
                selectedTimePeriod={selectedTimePeriod}
                setSelectedTimePeriod={setSelectedTimePeriod}
                fetchHistoryPerformance={fetchHistoryPerformance}
                fetchHistoryBenchmark={fetchHistoryBenchmark}
                lastUpdatePerformance={lastUpdatePerformance}
                dataSourceErrors={dataSourceErrors}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <BenchmarkingTab
                benchmark={benchmark}
                fw={fw}
                customFramework={customFramework}
                benchmarkForCategory={benchmarkForCategory}
                benchmarkingRows={benchmarkingRows}
                benchmarkingLoading={benchmarkingLoading}
                allCategories={allCategories}
                containersMaxWidth={containersMaxWidth}
                categoryName={categoryName}
                historyPerformanceData={historyPerformanceData}
                historyBenchmarkData={historyBenchmarkData}
                selectedTimePeriod={selectedTimePeriod}
                setSelectedTimePeriod={setSelectedTimePeriod}
                fetchHistoryPerformance={fetchHistoryPerformance}
                setHistoryBenchmarkValues={setHistoryBenchmarkValues}
                setBenchmarkForCategory={setBenchmarkForCategory}
                setBenchmarkingRows={setBenchmarkingRows}
                historyBenchmarkValues={historyBenchmarkValues}
                fetchHistoryBenchmark={fetchHistoryBenchmark}
                categoryId={location.pathname.replace('/library/categories/', '')}
                lastUpdatePerformance={lastUpdatePerformance}
              />
            </TabPanel>
          </>
        </ContentWrapper>
      )}

      {/*     {inactiveCpis.length > 0 && (
        <>
          <CPITable
            allCpis={allCpis}
            title="Inactive Cyber Performance Indicators"
            subtitle="These CPIs aren’t configured in your environment yet, but are available to do so."
            active={false}
            categoryName={categoryName}
            categoryData={categoriesData.filter((data) => data.category.toLowerCase() === categoryName)}
            inactiveCpis={inactiveCpis}
          />
          <CategorySeparator mt={7} mb={5} />
        </>
      )} */}
      {/* <FooterNavigation prev={prev} handlePrev={handlePrev} next={next} handleNext={handleNext} /> */}
    </>
  )
}

export default CategoryPage
