import { Dispatch, forwardRef, SetStateAction, useRef } from 'react'
import TableToolbar from '../TableToolbar'
import LoadingSkeleton from '../LoadingSkeleton'
import DataTable from './DataTable'
import EmptyState from '../EmptyState'
import {
  AcaLastSeen,
  AnalysisDataTable,
  IntegrationInstance,
  LastSeenWithin,
  OperatingSystem,
  TableHeaders
} from '../../../../../models'
import { Pagination } from '../../useCoverageAnalyzerData'

type Ref = HTMLDivElement | null

interface Props {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  showFilter: boolean
  isFilterActive: boolean
  setShowFilter: Dispatch<SetStateAction<boolean>>
  columns: TableHeaders[]
  setColumns: Dispatch<SetStateAction<TableHeaders[]>>
  loadingAnalysisData: boolean
  firstInstanceName: string
  secondInstanceName: string
  initialLoad: boolean
  setOpenRowDrawer: Dispatch<SetStateAction<boolean>>
  setActiveRowItem: Dispatch<SetStateAction<AnalysisDataTable | null>>
  firstInstance: IntegrationInstance | undefined
  secondInstance: IntegrationInstance | undefined
  pagination: Pagination
  setPagination: Dispatch<SetStateAction<Pagination>>
  selectedSeenWithin: LastSeenWithin
  selectedOperatingSystem: OperatingSystem
  fetchAnalysisData: ({
    integrationIds,
    operatingSystem,
    lastSeenWithin,
    searchTerm,
    filters,
    sortBy,
    direction
  }: {
    integrationIds: string[]
    operatingSystem?: OperatingSystem
    lastSeenWithin?: LastSeenWithin
    searchTerm?: string
    filters?: boolean[]
    sortBy?: string
    direction?: string
  }) => void
  tableFilters: boolean[]
  fetchTotalAssets: ({
    integrationIds,
    lastSeenWithin
  }: {
    integrationIds: string[]
    lastSeenWithin: AcaLastSeen
  }) => Promise<void>
  setLoadingAnalysisData: Dispatch<SetStateAction<boolean>>
  rows: AnalysisDataTable[]
  setRows: Dispatch<SetStateAction<AnalysisDataTable[]>>
  firstSourceInField: string
  secondSourceInField: string
  setFirstSource: Dispatch<SetStateAction<string>>
  setSecondSource: Dispatch<SetStateAction<string>>
  selectedInstances: IntegrationInstance[]
  exporting: boolean
  handleExport: ({
    integrationIds,
    operatingSystem,
    lastSeenWithin,
    searchTerm,
    filters,
    sortBy,
    direction
  }: {
    integrationIds?: string[]
    operatingSystem?: OperatingSystem
    lastSeenWithin?: LastSeenWithin
    searchTerm?: string
    filters?: boolean[]
    sortBy?: string
    direction?: string
  }) => void
}

const TableContent = forwardRef<Ref, Props>(
  (
    {
      search,
      setSearch,
      showFilter,
      isFilterActive,
      setShowFilter,
      columns,
      setColumns,
      loadingAnalysisData,
      firstInstanceName,
      secondInstanceName,
      initialLoad,
      setOpenRowDrawer,
      setActiveRowItem,
      firstInstance,
      secondInstance,
      pagination,
      setPagination,
      exporting,
      selectedSeenWithin,
      selectedOperatingSystem,
      fetchAnalysisData,
      tableFilters,
      fetchTotalAssets,
      setLoadingAnalysisData,
      rows,
      setRows,
      firstSourceInField,
      secondSourceInField,
      setFirstSource,
      setSecondSource,
      selectedInstances,
      handleExport
    },
    contentWrapperRef
  ) => {
    const tableContainerVisibleRef = useRef<HTMLDivElement | null>(null)
    const tableContainerRef = useRef<HTMLDivElement | null>(null)

    const getLoadingSkeletonHeight = () => {
      if (!initialLoad) {
        if (tableContainerVisibleRef && tableContainerVisibleRef.current) {
          return `calc(100vh - 400px) !important`
        }
      }

      if (contentWrapperRef && (contentWrapperRef as any).current) {
        if (
          (contentWrapperRef as any).current.clientHeight < 425 &&
          tableContainerVisibleRef &&
          tableContainerVisibleRef.current
        ) {
          return `${(tableContainerVisibleRef as any).current.clientHeight}px !important`
        }
        return `${(contentWrapperRef as any).current.clientHeight - 57}px !important`
      }

      return 'calc(100vh - 400px) !important'
    }

    const handleSort = async ({
      column,
      direction,
      integrationIds
    }: {
      column: string
      direction: string
      integrationIds: string[]
    }) => {
      await fetchAnalysisData({
        integrationIds,
        operatingSystem: selectedOperatingSystem,
        filters: tableFilters,
        searchTerm: search,
        sortBy: column,
        direction
      })
    }

    const visibleColumns = columns.filter((c) => c.show)

    return (
      <>
        <TableToolbar
          search={search}
          setSearch={setSearch}
          count={rows.length}
          showFilter={showFilter}
          isFilterActive={isFilterActive}
          setShowFilter={setShowFilter}
          columns={columns}
          setColumns={setColumns}
          pagination={pagination}
          handleExport={handleExport}
          exporting={exporting}
        />
        {loadingAnalysisData && <LoadingSkeleton height={getLoadingSkeletonHeight()} columns={visibleColumns} />}
        <div ref={tableContainerVisibleRef} className="ref-wrapper-data-table">
          {!loadingAnalysisData && firstInstance && secondInstance && (
            <>
              <DataTable
                fetchTotalAssets={fetchTotalAssets}
                columns={columns}
                visibleColumns={visibleColumns}
                setColumns={setColumns}
                setOpenRowDrawer={setOpenRowDrawer}
                setActiveRowItem={setActiveRowItem}
                firstInstanceName={firstInstanceName}
                secondInstanceName={secondInstanceName}
                firstInstance={firstInstance}
                secondInstance={secondInstance}
                pagination={pagination}
                setPagination={setPagination}
                selectedSeenWithin={selectedSeenWithin}
                selectedOperatingSystem={selectedOperatingSystem}
                searchTerm={search}
                handleSort={handleSort}
                ref={tableContainerRef}
                rows={rows}
                setRows={setRows}
                setLoadingAnalysisData={setLoadingAnalysisData}
                firstSourceInField={firstSourceInField}
                secondSourceInField={secondSourceInField}
                setFirstSource={setFirstSource}
                setSecondSource={setSecondSource}
                selectedInstances={selectedInstances}
                search={search}
                tableFilters={tableFilters}
              />
            </>
          )}
        </div>
        {!loadingAnalysisData && !rows.length && <EmptyState />}
      </>
    )
  }
)

export default TableContent
