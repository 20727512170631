import { Box, Table as MuiTable } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  overflow-x: auto;
  width: 100%;
`

export const Table = styled(MuiTable)`
  background: ${theme.colors.backgroundDarker};
  border: 1px solid #342f32;
`

export const TableContainer = styled.div`
  display: block;
  overflow: auto;
  width: 100%;
  background: ${theme.colors.backgroundDarker};
  border: 1px solid #342f32;
`
