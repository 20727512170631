import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Wrapper = styled(Box)`
  width: 100%;
  border-radius: ${theme.attributes.borderRadius.normal};
  flex-basis: 33.33%;
  background: ${theme.colors.backgroundDarker};
  box-shadow: ${theme.attributes.boxShadow.feedSection};
  padding: ${theme.spacing.small}px ${theme.spacing.small}px ${theme.spacing.medium}px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.sm}px;
    width: 100%;

    .header-title {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
      }

      .title {
        color: ${theme.colors.neutral};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;
        margin-left: ${theme.spacing.xSmall}px;
      }
    }

    .select-wrapper {
      .MuiFormControl-root {
        margin-bottom: 0;

        .MuiOutlinedInput-root {
          outline: 0 !important;
          background: transparent !important;
          border-radius: ${theme.attributes.borderRadius.secondary} !important;
          border: 1px solid ${theme.colors.controlBorder} !important;
          color: ${theme.baseColors.greyscale[80]} !important;
        }
      }
    }
  }

  .card-container {
    gap: ${theme.spacing.small}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;

    .benchmark-card-wrapper {
      border-radius: ${theme.attributes.borderRadius.normal};
      background: ${theme.colors.backgroundMedium};
      box-shadow: ${theme.attributes.boxShadow.overallScoreWrapper};
      padding: 12.75px ${theme.spacing.small}px;
    }
  }
`
