import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const EmptyStateContainer = styled(Box)`
  padding-left: ${theme.spacing.medium}px;
  padding-right: ${theme.spacing.medium}px;

  h2 {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.huge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 52px;
  }

  .image-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`
