import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)<{ isactive?: string }>`
  display: flex;
  padding: ${theme.spacing.md}px ${theme.spacing.medium}px;
  justify-content: space-between;
  align-items: center;
  border-radius: ${theme.attributes.borderRadius.quaternary};
  min-height: 72px;
  background: ${({ isactive }) =>
    isactive && isactive === 'false' ? theme.colors.surfaceItemBackgroundLow : theme.colors.surfaceItemBackground};

  &:hover {
    background: ${theme.baseColors.surface[50]};
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;
    max-width: ${({ isactive }) => (isactive && isactive === 'true' ? 'calc(100% - 156px)' : 'unset')};
  }

  .title {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    max-width: 55%;
  }

  .MuiOutlinedInput-root {
    border-radius: ${theme.attributes.borderRadius.small};
    border: 1px solid ${theme.colors.controlBorder};
    letter-spacing: 0.5px;
    height: 48px;
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;

    input {
      padding-left: 22px;
    }

    &:hover {
      border-color: ${theme.colors.neutral};
    }

    &.Mui-focused {
      border: 1px solid ${theme.colors.textPrimary} !important;
    }

    fieldset {
      border: 0;
    }
  }

  .percentage {
    .MuiTypography-root {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }
  }

  .MuiFormControl-root {
    width: 81px;
  }

  .length3 input {
    padding-left: ${theme.spacing.small}px;
  }

  .length1 input {
    padding-left: ${theme.spacing.large}px;
  }

  .Mui-disabled {
    color: ${theme.baseColors.greyscale[80]};
    -webkit-text-fill-color: ${theme.baseColors.greyscale[80]};
    cursor: not-allowed;
  }

  .Mui-focused {
    border: 1px solid ${theme.colors.textPrimary};

    input {
      width: 100%;
    }
  }

  .close-button {
    color: ${theme.colors.textPrimary};

    &:hover {
      background: ${theme.colors.surfaceItemBackgroundLow};
    }
  }

  .no-role-weights-wrapper {
    padding-right: ${theme.spacing.medium}px;
  }
`
