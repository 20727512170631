/* eslint camelcase: 0 */
import { Box, FormControl as MuiFormControl, Select as MuiSelect } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8

export const FormControl = styled(MuiFormControl)<{
  isselected?: string
  isopen: string
  is_disabled: string
  field_width?: string
}>`
  border: 1px solid ${theme.colors.controlBorder};
  background: transparent;
  outline: none;
  width: ${({ field_width }) => (field_width && field_width.length > 0 ? `${field_width}px` : '300px')};
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.small};
  box-sizing: border-box;

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${theme.baseColors.secondary[80]};
    text-align: left;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
    text-transform: capitalize;
    flex-direction: row;
    justify-content: center;
  }

  &:hover {
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: ${theme.colors.textPrimary};
    }
  }

  svg:first-of-type {
    top: calc(50% - 0.6em);
    right: ${theme.spacing.small}px;

    path {
      fill: ${theme.colors.white};
    }
  }

  ${({ is_disabled }) =>
    is_disabled &&
    is_disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      
      &:hover {
        background: transparent;
    
        .MuiOutlinedInput-root {
          color: ${theme.colors.textPrimary};
        }
      }
      
       .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};

  ${({ isopen }) =>
    isopen === 'true' &&
    `
      border: 1px solid ${theme.colors.textPrimary};
      background: rgba(208, 188, 255, 0.16);
      
      .MuiOutlinedInput-root {
        color: ${theme.colors.textPrimary};
      }
  `};
`

export const Result = styled(Box)`
  color: ${theme.colors.textSecondaryLow};
  font-size: ${theme.typography.fontSize.small};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.thick};
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const Select = styled(MuiSelect)<{ isselected?: string; noborder?: string }>`
  min-width: 200px;
  height: 40px;
  background: transparent;
  border: 1px solid ${theme.colors.controlBorder};
  color: ${theme.colors.white};
  font-family: ${theme.typography.fontFamily.primary};
  ${theme.typography.fontSize.large};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: capitalize;

  .input-value-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing.xSmall}px;

    .input-value-integration {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 20px;
      letter-spacing: 0.25px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 250px;
    }

    .input-value-count {
      color: ${theme.colors.textSecondaryLow};
      text-align: right;
      text-overflow: ellipsis;
      font-size: ${theme.typography.fontSize.xLarge};
      font-weight: ${theme.typography.fontWeight.normal};
      font-style: normal;
      line-height: normal;
      margin-right: ${theme.spacing.small}px;
    }
  }

  ${({ isselected }) =>
    isselected === 'true' &&
    `
      background: ${theme.baseColors.secondary[40]};
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    color: ${theme.baseColors.greyscale[80]};
  }
`

export const OptionsWrapper = styled(Box)<{ length: number }>`
  height: ${({ length }) => (length > 5 ? '200px' : 'fit-content')};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.quaternary};
  }
`

export const MenuProps = {
  PaperProps: {
    sx: {
      border: `1px solid ${theme.colors.borderLow}`,
      background: theme.colors.surfaceItemBackground,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.secondary,
      minWidth: '350px',
      padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
      marginTop: `${theme.spacing.half}px`,

      '.MuiList-root': {
        minWidth: '316px'
      },

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        padding: `${theme.spacing.xSmall}px`,
        background: 'transparent !important',
        borderRadius: theme.attributes.borderRadius.small,

        img: {
          marginRight: `${theme.spacing.xSmall}px`
        },

        '&:hover': {
          background: `${theme.colors.primaryLight} !important`
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontSize: theme.typography.fontSize.xLarge,
          lineHeight: '24px',
          letterSpacing: '0.5px',
          color: theme.colors.white,
          fontStyle: 'normal',
          textTransform: 'capitalize'
        }
      },

      '.disabled-item': {
        cursor: 'default',

        '&:hover': {
          background: 'transparent !important'
        }
      },

      '.disabled-item .MuiTypography-root': {
        color: `${theme.baseColors.greyscale[50]} !important`
      },

      '.button-wrapper': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: `${theme.spacing.half}px`
      },

      '.Mui-selected': {
        backgroundColor: 'rgba(208, 188, 255, 0.16) !important'
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: theme.attributes.borderRadius.secondary
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}

export const getMenuProps = ({ width }: { width?: string }) => ({
  PaperProps: {
    sx: {
      border: `1px solid ${theme.colors.borderLow}`,
      background: theme.colors.surfaceItemBackground,
      boxShadow: theme.attributes.boxShadow.selectFieldPaper,
      borderRadius: theme.attributes.borderRadius.secondary,
      minWidth: width || '350px',
      width: width || 'unset',
      padding: `${theme.spacing.xSmall}px`,
      marginTop: `${theme.spacing.half}px`,

      '.MuiList-root': {
        minWidth: width ? 'unset' : '316px',
        padding: 0,
        display: 'flex',
        gap: '9px',
        flexDirection: 'column',
        justifyContent: 'center'
      },

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        padding: `${theme.spacing.xSmall}px`,
        background: 'transparent !important',
        borderRadius: theme.attributes.borderRadius.small,

        img: {
          marginRight: `${theme.spacing.small}px`
        },

        '&:hover': {
          background: `${theme.colors.primaryLight} !important`
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: theme.typography.fontFamily.primary,
          fontWeight: theme.typography.fontWeight.normal,
          fontSize: theme.typography.fontSize.xLarge,
          color: theme.colors.white,
          lineHeight: 'normal',
          fontStyle: 'normal',
          textTransform: 'capitalize',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      },

      '.disabled-item': {
        cursor: 'default',

        '&:hover': {
          background: 'transparent !important'
        }
      },

      '.disabled-item .MuiTypography-root': {
        color: `${theme.baseColors.greyscale[50]} !important`
      },

      '.button-wrapper': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: `${theme.spacing.half}px`
      },

      '.Mui-selected': {
        backgroundColor: 'rgba(208, 188, 255, 0.16) !important'
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: theme.attributes.borderRadius.secondary
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
})
