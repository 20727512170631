import theme from 'theme'

const MostRecentFindingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <ellipse opacity="0.5" cx="9.64459" cy="9.99905" rx="9.64459" ry="9.08108" fill={theme.baseColors.greyscale[80]} />
    <path
      d="M13.9649 9.99953C13.9649 12.2031 12.0585 14.0401 9.64261 14.0401C7.22668 14.0401 5.32031 12.2031 5.32031 9.99953C5.32031 7.79593 7.22668 5.95898 9.64261 5.95898C12.0585 5.95898 13.9649 7.79593 13.9649 9.99953Z"
      fill="white"
      stroke="black"
    />
  </svg>
)

export default MostRecentFindingIcon
