import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'
import theme from 'theme'

export const OnyxAITooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: `${theme.spacing.small}px`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: theme.attributes.borderRadius.secondary,
    border: `1px solid ${theme.colors.controlBorder}`,
    background: theme.colors.borderLow,
    boxShadow: theme.attributes.boxShadow.chipCircle,
    width: '209px'
  }
})
