import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Box, Chip, CircularProgress, SelectChangeEvent, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import {
  Benchmark,
  BenchmarkRowData,
  BenchmarkScoreCategory,
  BenchmarkScoreCategoryData,
  CategoryDataPayload,
  CPITimePeriod,
  HistoryBenchmarkData,
  HistoryBenchmarkDataValues,
  HistoryPerformanceData,
  IFramework
} from '../../../../../../models'
import { benchmarkingSelectOptions } from '../../../../dashboard/components/Benchmarking/benchmarking.constants'
import { defaultCategoriesTimePeriodValues } from '../../../library.constants'
import { categoryToUrl, getPayloadTimePeriod, urlToCategory } from '../../../utils'
import { Category } from '../../CategoryPage'
import { dateFormat } from '../../../../../../lib/utils'

/* Components */
import { CategoryTabWrapper } from '../../categoryPage.styles'
import { ChipsContainer } from '../../../components/library-components.styles'
import BenchmarkingScore from '../current-findings/components/BenchmarkingScore'
import BenchmarkingScoreHistory from './components/BenchmarkingScoreHistory'
import EmptyData from '../../components/EmptyData'
import NoData from '../../components/NoData'

interface Props {
  allCategories: Category[]
  containersMaxWidth: number
  categoryName: string
  historyPerformanceData: HistoryPerformanceData[]
  historyBenchmarkData: HistoryBenchmarkData[]
  selectedTimePeriod: CPITimePeriod
  setSelectedTimePeriod: Dispatch<SetStateAction<CPITimePeriod>>
  fetchHistoryPerformance: (payload: CategoryDataPayload) => void
  setHistoryBenchmarkValues: Dispatch<SetStateAction<HistoryBenchmarkDataValues[]>>
  historyBenchmarkValues: HistoryBenchmarkDataValues[]
  fetchHistoryBenchmark: (payload: CategoryDataPayload) => void
  benchmarkingLoading: boolean
  benchmark: Benchmark | null
  benchmarkForCategory: BenchmarkScoreCategory | null
  benchmarkingRows: BenchmarkRowData[]
  setBenchmarkForCategory: Dispatch<SetStateAction<BenchmarkScoreCategory | null>>
  categoryId: string
  setBenchmarkingRows: Dispatch<SetStateAction<BenchmarkRowData[]>>
  lastUpdatePerformance: string
  fw: string
  customFramework: IFramework | null
}

const BenchmarkingTab: FC<Props> = ({
  benchmark,
  fw,
  customFramework,
  benchmarkForCategory,
  benchmarkingRows,
  benchmarkingLoading,
  allCategories,
  containersMaxWidth,
  categoryName,
  selectedTimePeriod,
  fetchHistoryPerformance,
  setSelectedTimePeriod,
  historyPerformanceData,
  historyBenchmarkData,
  setHistoryBenchmarkValues,
  historyBenchmarkValues,
  fetchHistoryBenchmark,
  categoryId,
  setBenchmarkForCategory,
  setBenchmarkingRows,
  lastUpdatePerformance
}) => {
  const [selectedBenchmark, setSelectedBenchmark] = useState<string>(
    benchmarkingSelectOptions.find((item) => item === 'All Industries') || benchmarkingSelectOptions[0]
  )

  useEffect(() => {
    if (historyBenchmarkData && historyBenchmarkData.length > 0 && selectedBenchmark) {
      const selectedBenchmarkData = historyBenchmarkData.find((n) => n.industry_name === selectedBenchmark)
      if (selectedBenchmarkData) {
        setHistoryBenchmarkValues(selectedBenchmarkData.values)
      }
    }
  }, [historyBenchmarkData])

  const handleCompareToChange = (e: SelectChangeEvent<typeof selectedBenchmark>) => {
    const { value } = e.target
    setSelectedBenchmark(value)

    if (!benchmark) return

    const currentFindingsBenchmarkScore = benchmark.score
    const foundBenchmarkScore = currentFindingsBenchmarkScore.find((n) => n.industry_name === value)
    if (!foundBenchmarkScore) return
    const catId = categoryId.includes('identity') ? 'iam' : categoryId
    let foundBenchmarkCategory = foundBenchmarkScore.categories.find((n) => n.category_id === catId)
    if (fw === 'custom' && customFramework) {
      foundBenchmarkCategory = foundBenchmarkScore.categories.find(
        (n) => categoryToUrl(n.category_name.toLowerCase()) === catId
      )
    }

    if (!foundBenchmarkCategory) return
    setBenchmarkForCategory(foundBenchmarkCategory)

    const benchmarkingRows: any[] = []
    const sortedData = foundBenchmarkCategory.data.sort(
      (a: BenchmarkScoreCategoryData, b: BenchmarkScoreCategoryData) => {
        if (a.cpi_display_name < b.cpi_display_name) {
          return -1
        }
        if (a.cpi_display_name > b.cpi_display_name) {
          return 1
        }
        return 0
      }
    )

    sortedData.forEach((data) => {
      const item: BenchmarkRowData = {
        name: data.cpi_display_name,
        cpiTitle: data.cpi_title,
        weight: `${data.cpi_weight}%`,
        summary: data.summary
      }
      benchmarkingRows.push(item)
    })
    setBenchmarkingRows(benchmarkingRows)

    const selectedBenchmarkData = historyBenchmarkData.find((n) => n.industry_name === value)
    if (selectedBenchmarkData) {
      setHistoryBenchmarkValues(selectedBenchmarkData.values)
    }
  }

  const handleChangeTimePeriod = async (period: CPITimePeriod) => {
    setSelectedTimePeriod(period)
    const cat = categoryToUrl(categoryName)
    let category = allCategories.find((n) => {
      const id = cat === 'identity-and-access-management' ? 'iam' : cat

      return n.id === id
    })
    if (fw === 'custom' && customFramework) {
      category = allCategories.find((n) => {
        return urlToCategory(n.title.toLowerCase()) === urlToCategory(cat.toLowerCase())
      })
    }

    const timePeriod = getPayloadTimePeriod(period)
    const payload: CategoryDataPayload = {
      timePeriod,
      category: category?.id || cat
    }

    await fetchHistoryPerformance(payload)
    await fetchHistoryBenchmark(payload)
  }

  const lastUpdate = useMemo(() => {
    if (historyBenchmarkValues && historyBenchmarkValues.length > 0) {
      const lastValue = dayjs(historyBenchmarkValues[historyBenchmarkValues.length - 1].date || '').format(
        dateFormat.lastUpdate
      )
      const nextMonday = dayjs(lastValue).weekday(8)

      return dayjs(nextMonday).format(dateFormat.lastUpdate)
    }

    return dayjs().format(dateFormat.lastUpdate)
  }, [historyBenchmarkValues])

  return (
    <CategoryTabWrapper sx={{ gap: '0 !important', marginBottom: '56px !important' }}>
      <Box className="container">
        <Box className="container-inner">
          {(benchmarkForCategory || historyBenchmarkData.length > 0) && !benchmarkingLoading && (
            <Box className="title-wrapper">
              <Typography className="title">Current Findings</Typography>
            </Box>
          )}

          <Box className="container-inner-2">
            {benchmarkingLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <CircularProgress size="46px" color="secondary" />
              </Box>
            ) : !benchmarkForCategory && !historyBenchmarkData.length ? (
              <NoData />
            ) : (
              <>
                {/* Current Findings */}
                {benchmarkForCategory ? (
                  <BenchmarkingScore
                    containersMaxWidth={containersMaxWidth}
                    handleCompareToChange={handleCompareToChange}
                    benchmark={benchmark}
                    benchmarkForCategory={benchmarkForCategory}
                    selectedBenchmark={selectedBenchmark}
                    rows={benchmarkingRows}
                    lastUpdatePerformance={lastUpdatePerformance}
                  />
                ) : historyBenchmarkData.length > 0 ? (
                  <EmptyData />
                ) : (
                  ''
                )}

                {/* Historical Data */}
                {historyBenchmarkData.length > 0 && (
                  <>
                    <Box className="container-header">
                      <Box className="title-wrapper">
                        <Typography className="title">Historical Data</Typography>
                      </Box>
                      <ChipsContainer sx={{ marginBottom: '32px !important' }}>
                        <Typography className="chips-title">Time Period:</Typography>
                        <Stack direction="row" spacing={1}>
                          {defaultCategoriesTimePeriodValues.map((n: CPITimePeriod) => (
                            <Chip
                              key={n}
                              label={n}
                              variant="outlined"
                              onClick={() => handleChangeTimePeriod(n)}
                              className={selectedTimePeriod === n ? 'selected' : ''}
                            />
                          ))}
                        </Stack>
                      </ChipsContainer>
                    </Box>

                    <BenchmarkingScoreHistory
                      issolo={historyPerformanceData.length > 0 ? 'false' : 'true'}
                      historyBenchmarkData={historyBenchmarkData}
                      selectedBenchmark={selectedBenchmark}
                      handleCompareToChange={handleCompareToChange}
                      historyBenchmarkValues={historyBenchmarkValues}
                      selectedTimePeriod={selectedTimePeriod}
                      lastUpdate={lastUpdate}
                    />
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </CategoryTabWrapper>
  )
}

export default BenchmarkingTab
