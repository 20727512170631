import { useMediaQuery } from '@mui/material'
import theme from 'theme'

const StubBenchmarkCard = () => {
  const isSmall = useMediaQuery('(max-height:1440px)')

  return (
    <svg
      width={isSmall ? '300' : '364'}
      height="48"
      viewBox="0 0 364 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_712_3938"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="164"
        height="16"
      >
        <mask id="path-1-inside-1_712_3938" fill="white">
          <path d="M0 8C0 3.99594 3.24594 0.75 7.25 0.75H78.8964V15.25H7.24999C3.24592 15.25 0 12.0041 0 8Z" />
        </mask>
        <path
          d="M0 8C0 3.99594 3.24594 0.75 7.25 0.75H78.8964V15.25H7.24999C3.24592 15.25 0 12.0041 0 8Z"
          fill="url(#paint0_linear_712_3938)"
        />
        <path
          d="M0 8C0 3.99594 3.24594 0.75 7.25 0.75H78.8964V15.25H7.24999C3.24592 15.25 0 12.0041 0 8Z"
          fill={theme.colors.textPrimary}
          fillOpacity="0.08"
        />
        <path
          d="M-1 8C-1 3.44365 2.69365 -0.25 7.25 -0.25H78.8964V1.75H7.25C3.79822 1.75 1 4.54822 1 8H-1ZM78.8964 16.25H7.25C2.69365 16.25 -1 12.5563 -1 8H1C1 11.4518 3.79822 14.25 7.25 14.25H78.8964V16.25ZM7.25 16.25C2.69365 16.25 -1 12.5563 -1 8C-1 3.44365 2.69365 -0.25 7.25 -0.25V1.75C3.79822 1.75 1 4.54822 1 8C1 11.4518 3.79822 14.25 7.25 14.25V16.25ZM78.8964 0.75V15.25V0.75Z"
          fill={theme.colors.borderLow}
          mask="url(#path-1-inside-1_712_3938)"
        />
        <mask id="path-3-inside-2_712_3938" fill="white">
          <path d="M78.75 0.75H156.55C160.554 0.75 163.8 3.99594 163.8 8C163.8 12.0041 160.554 15.25 156.55 15.25H78.75V0.75Z" />
        </mask>
        <path
          d="M78.75 0.75H156.55C160.554 0.75 163.8 3.99594 163.8 8C163.8 12.0041 160.554 15.25 156.55 15.25H78.75V0.75Z"
          fill="url(#paint1_linear_712_3938)"
        />
        <path
          d="M78.75 0.75H156.55C160.554 0.75 163.8 3.99594 163.8 8C163.8 12.0041 160.554 15.25 156.55 15.25H78.75V0.75Z"
          fill={theme.colors.textPrimary}
          fillOpacity="0.08"
        />
        <path
          d="M78.75 -0.25H156.55C161.106 -0.25 164.8 3.44365 164.8 8H162.8C162.8 4.54822 160.002 1.75 156.55 1.75H78.75V-0.25ZM164.8 8C164.8 12.5563 161.106 16.25 156.55 16.25H78.75V14.25H156.55C160.002 14.25 162.8 11.4518 162.8 8H164.8ZM78.75 15.25V0.75V15.25ZM156.55 -0.25C161.106 -0.25 164.8 3.44365 164.8 8C164.8 12.5563 161.106 16.25 156.55 16.25V14.25C160.002 14.25 162.8 11.4518 162.8 8C162.8 4.54822 160.002 1.75 156.55 1.75V-0.25Z"
          fill={theme.colors.borderLow}
          mask="url(#path-3-inside-2_712_3938)"
        />
      </mask>
      <g mask="url(#mask0_712_3938)">
        <rect x="-288" y="-249.375" width="576" height="522" fill={theme.colors.primaryDark} />
        <rect x="-288" y="-249.375" width="576" height="522" fill="url(#paint2_linear_712_3938)" />
      </g>
      <mask
        id="mask1_712_3938"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="27"
        width="364"
        height="21"
      >
        <mask id="path-6-inside-3_712_3938" fill="white">
          <path d="M0 37.25C0 31.7272 4.47715 27.25 10 27.25H175.325V47.25H10C4.47716 47.25 0 42.7728 0 37.25Z" />
        </mask>
        <path
          d="M0 37.25C0 31.7272 4.47715 27.25 10 27.25H175.325V47.25H10C4.47716 47.25 0 42.7728 0 37.25Z"
          fill="url(#paint3_linear_712_3938)"
        />
        <path
          d="M0 37.25C0 31.7272 4.47715 27.25 10 27.25H175.325V47.25H10C4.47716 47.25 0 42.7728 0 37.25Z"
          fill={theme.colors.textPrimary}
          fillOpacity="0.08"
        />
        <path
          d="M-1 37.25C-1 31.1749 3.92487 26.25 10 26.25H175.325V28.25H10C5.02944 28.25 1 32.2794 1 37.25H-1ZM175.325 48.25H10C3.92487 48.25 -1 43.3251 -1 37.25H1C1 42.2206 5.02944 46.25 10 46.25H175.325V48.25ZM10 48.25C3.92487 48.25 -1 43.3251 -1 37.25C-1 31.1749 3.92487 26.25 10 26.25V28.25C5.02944 28.25 1 32.2794 1 37.25C1 42.2206 5.02944 46.25 10 46.25V48.25ZM175.325 27.25V47.25V27.25Z"
          fill={theme.colors.borderLow}
          mask="url(#path-6-inside-3_712_3938)"
        />
        <mask id="path-8-inside-4_712_3938" fill="white">
          <path d="M175 27.25H354C359.523 27.25 364 31.7272 364 37.25C364 42.7728 359.523 47.25 354 47.25H175V27.25Z" />
        </mask>
        <path
          d="M175 27.25H354C359.523 27.25 364 31.7272 364 37.25C364 42.7728 359.523 47.25 354 47.25H175V27.25Z"
          fill="url(#paint4_linear_712_3938)"
        />
        <path
          d="M175 27.25H354C359.523 27.25 364 31.7272 364 37.25C364 42.7728 359.523 47.25 354 47.25H175V27.25Z"
          fill={theme.colors.textPrimary}
          fillOpacity="0.08"
        />
        <path
          d="M175 26.25H354C360.075 26.25 365 31.1749 365 37.25H363C363 32.2794 358.971 28.25 354 28.25H175V26.25ZM365 37.25C365 43.3251 360.075 48.25 354 48.25H175V46.25H354C358.971 46.25 363 42.2206 363 37.25H365ZM175 47.25V27.25V47.25ZM354 26.25C360.075 26.25 365 31.1749 365 37.25C365 43.3251 360.075 48.25 354 48.25V46.25C358.971 46.25 363 42.2206 363 37.25C363 32.2794 358.971 28.25 354 28.25V26.25Z"
          fill={theme.colors.borderLow}
          mask="url(#path-8-inside-4_712_3938)"
        />
      </mask>
      <g mask="url(#mask1_712_3938)">
        <rect x="-640" y="-126.75" width="1280" height="720" fill={theme.colors.primaryDark} />
        <rect x="-640" y="-126.75" width="1280" height="720" fill="url(#paint5_linear_712_3938)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_712_3938"
          x1="39.4482"
          y1="0.75"
          x2="39.4482"
          y2="15.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primaryDark} />
          <stop offset="1" stopColor={theme.colors.primaryDark} stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_712_3938"
          x1="121.275"
          y1="0.75"
          x2="121.275"
          y2="15.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primaryDark} />
          <stop offset="1" stopColor={theme.colors.primaryDark} stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_712_3938"
          x1="-288"
          y1="-249.375"
          x2="279.623"
          y2="-221.081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primaryLight} stopOpacity="0.12" />
          <stop offset="0.375" stopColor={theme.colors.primaryLight} stopOpacity="0.71" />
          <stop offset="0.557292" stopColor={theme.colors.primaryLight} stopOpacity="0.9" />
          <stop offset="0.739583" stopColor={theme.colors.primaryLight} stopOpacity="0.71" />
          <stop offset="1" stopColor={theme.colors.primaryLight} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_712_3938"
          x1="87.6626"
          y1="27.25"
          x2="87.6626"
          y2="47.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primaryDark} />
          <stop offset="1" stopColor={theme.colors.primaryDark} stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_712_3938"
          x1="269.5"
          y1="27.25"
          x2="269.5"
          y2="47.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primaryDark} />
          <stop offset="1" stopColor={theme.colors.primaryDark} stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_712_3938"
          x1="-640"
          y1="-126.75"
          x2="616.416"
          y2="-25.8492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primaryLight} stopOpacity="0.12" />
          <stop offset="0.375" stopColor={theme.colors.primaryLight} stopOpacity="0.71" />
          <stop offset="0.557292" stopColor={theme.colors.primaryLight} stopOpacity="0.9" />
          <stop offset="0.739583" stopColor={theme.colors.primaryLight} stopOpacity="0.71" />
          <stop offset="1" stopColor={theme.colors.primaryLight} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default StubBenchmarkCard
