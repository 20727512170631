import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import { NistFunction } from '../../../models'
import theme from 'theme'

export const Wrapper = styled(Box)<{ fn: NistFunction | '' }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.attributes.borderRadius.secondary};
  border: 1px solid ${theme.colors.controlBorder};
  background: #8e9aff;
  padding: ${theme.spacing.half}px ${theme.spacing.xSmall}px;
  gap: ${theme.spacing.xSmall}px;
  width: fit-content;
  cursor: pointer;

  ${({ fn }) =>
    fn === NistFunction.Identify &&
    `
      background: #8E9AFF;
    `}
  ${({ fn }) =>
    fn === NistFunction.Protect &&
    `
      background: #C77EDA;
    `}
  ${({ fn }) =>
    fn === NistFunction.Detect &&
    `
      background: #FFDE6A;
    `}
  ${({ fn }) =>
    fn === NistFunction.Respond &&
    `
      background: #FF7F6B;
    `}
  ${({ fn }) =>
    fn === NistFunction.Recover &&
    `
      background: #99FDC0;
    `}
  ${({ fn }) =>
    fn === NistFunction.Govern &&
    `
      background: #FF9E47;
    `}
  img {
    border-radius: ${theme.attributes.borderRadius.circle};
  }

  .MuiTypography-root {
    color: #000;
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`
