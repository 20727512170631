import { FC, SVGProps } from 'react'
import theme from 'theme'

const RequestQuoteIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.0575 5.5575L11.435 1.935C11.1575 1.6575 10.775 1.5 10.3775 1.5H5C4.175 1.5 3.5075 2.175 3.5075 3L3.5 15C3.5 15.825 4.1675 16.5 4.9925 16.5H14C14.825 16.5 15.5 15.825 15.5 15V6.6225C15.5 6.225 15.3425 5.8425 15.0575 5.5575ZM11 9.75C11.4125 9.75 11.75 10.0875 11.75 10.5V12.75C11.75 13.1625 11.4125 13.5 11 13.5H10.25C10.25 13.9125 9.9125 14.25 9.5 14.25C9.0875 14.25 8.75 13.9125 8.75 13.5H8C7.5875 13.5 7.25 13.1625 7.25 12.75C7.25 12.3375 7.5875 12 8 12H10.25V11.25H8C7.5875 11.25 7.25 10.9125 7.25 10.5V8.25C7.25 7.8375 7.5875 7.5 8 7.5H8.75C8.75 7.0875 9.0875 6.75 9.5 6.75C9.9125 6.75 10.25 7.0875 10.25 7.5H11C11.4125 7.5 11.75 7.8375 11.75 8.25C11.75 8.6625 11.4125 9 11 9H8.75V9.75H11ZM11 6C10.5875 6 10.25 5.6625 10.25 5.25V2.625L13.625 6H11Z"
      fill={theme.baseColors.primary[20]}
    />
  </svg>
)

export default RequestQuoteIcon
