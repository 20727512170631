import {
  Box,
  Accordion,
  AccordionDetails,
  Button,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Link,
  Popper,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl
} from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const SentinelIntegrationStep2Wrapper = styled(Box)`
  display: flex;

  .MuiTypography-root:first-child {
    font-family: ${theme.typography.fontFamily.primary};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #fbd8f5;
    text-align: left;
  }
`

export const SentinelIntegrationStep2Button = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px ${theme.spacing.small}px;
  gap: ${theme.spacing.xSmall}px;
  width: 408px;
  height: 40px;
  background: ${theme.colors.textPrimary};
  border-radius: ${theme.attributes.borderRadius.large};
  font-family: ${theme.typography.fontFamily.primary};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.extraThick};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${theme.baseColors.primary[20]};
  text-transform: none;
  margin-bottom: ${theme.spacing.medium}px;

  &:hover {
    background: rgba(208, 188, 255, 0.08);
  }

  &:focus {
    background: rgba(208, 188, 255, 0.12);
  }

  &:active {
    background: rgba(208, 188, 255, 0.12);
  }

  ${({ disabled }) =>
    disabled &&
    `
      background: rgba(227, 227, 227, 0.12);
  `};
`

export const BackToStep1Button = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: ${theme.spacing.xSmall}px;
  width: 236px;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.large};
  text-transform: none;
  font-family: ${theme.typography.fontFamily.primary};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${theme.colors.textPrimary};

  svg {
    transform: rotate(180deg);

    path {
      fill: ${theme.colors.textPrimary};
    }
  }
`

export const ScriptButton = styled(Button)`
  gap: ${theme.spacing.xSmall}px;
  width: 105px;
  height: 40px;
  border: 1px solid ${theme.colors.controlBorder};
  border-radius: ${theme.attributes.borderRadius.large};
  background: transparent;
  color: ${theme.colors.textPrimary};
  letter-spacing: 0.1px;
  font-family: ${theme.typography.fontFamily.primary};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 19px;

  svg {
    path {
      fill: ${theme.colors.textPrimary};
    }
  }
`

export const SentinelInputWrapper = styled(Box)`
  margin-bottom: ${theme.spacing.medium}px;

  .MuiFormControl-root {
    width: 100%;
    background: ${theme.colors.primaryDark};

    label {
      font-family: ${theme.typography.fontFamily.primary};
      font-style: normal;
      color: ${theme.baseColors.greyscale[80]};
      font-weight: ${theme.typography.fontWeight.normal};
      font-size: ${theme.typography.fontSize.xLarge};
      line-height: 24px;
      letter-spacing: 0.4px;
    }

    .MuiInputLabel-root.MuiInputLabel-shrink {
      font-size: ${theme.typography.fontSize.medium};
      line-height: 16px;
      color: ${theme.colors.textPrimary};
    }
  }

  .MuiFormHelperText-root {
    margin-top: ${theme.spacing.half}px;
    font-family: ${theme.typography.fontFamily.primary};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.medium};
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${theme.baseColors.greyscale[80]};
  }

  .MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${theme.colors.textPrimary};
  }

  .MuiFilledInput-root {
    &.Mui-focused {
      &:after {
        border-color: ${theme.colors.textPrimary};
      }
    }

    &:hover:before {
      border-color: ${theme.colors.textPrimary};
    }

    &::before {
      border-color: ${theme.colors.neutral};
    }
  }

  input {
    letter-spacing: 0.5px;
    font-family: ${theme.typography.fontFamily.primary};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xLarge};
    line-height: 24px;
    color: ${theme.baseColors.greyscale[80]};
  }

  svg {
    color: #fbd8f5;
  }

  .MuiInputAdornment-root button {
    padding-right: ${theme.spacing.small}px;
  }
`

export const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 56px;
`

export const LabelWrapper = styled(Box)`
  display: flex;
  align-items: center;
`

export const InputLabel = styled(MuiInputLabel)`
  color: #dbdbdb;
  font-size: ${theme.typography.fontSize.medium};
  line-height: 16px;
  margin-bottom: 2px;
`

export const FormControl = styled(MuiFormControl)`
  width: 25ch;
`

export const outlinedInputSx = {
  width: '280px',
  height: '32px',
  backgroundColor: theme.colors.white
}

/* Tooltip */
export const TooltipContainer = styled(Box)`
  padding: ${theme.spacing.medium}px;
  max-width: 340px;
  box-shadow: ${theme.attributes.boxShadow.tertiary};
  background: ${theme.colors.primaryLight};
  border-radius: ${theme.attributes.borderRadius.secondary};
`

export const TooltipWrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;

  .tooltip-action-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .tooltip-action-button {
      gap: ${theme.spacing.xSmall}px;
      width: 131px;
      height: 40px;
      border-radius: ${theme.attributes.borderRadius.large};
      font-family: ${theme.typography.fontFamily.primary};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: ${theme.colors.textPrimary};
      margin-top: 34px;
      text-transform: capitalize;
    }
  }
`

export const TooltipText = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.normal};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #fbd8f5;
  mix-blend-mode: normal;
  text-align: left;

  &.tooltip-title {
    font-size: ${theme.typography.fontSize.xxxLarge};
    line-height: 32px;
    letter-spacing: normal;
    margin-bottom: ${theme.spacing.small}px;
  }
`

export const TooltipButton = styled(Button)`
  padding: 10px 16px 10px 12px;
  gap: ${theme.spacing.xSmall}px;
  width: 100%;
  min-width: 257px;
  height: 40px;
  border-radius: ${theme.attributes.borderRadius.large};
  background: transparent;
  font-family: ${theme.typography.fontFamily.primary};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${theme.colors.textPrimary};
  text-transform: none;
  margin-top: ${theme.spacing.medium}px;

  svg {
    path {
      fill: ${theme.colors.textPrimary};
    }
  }
`

export const TooltipLink = styled(Link)`
  color: #f5f5f5;
  text-decoration: underline;
  font-weight: bold;
`

export const TooltipButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 18px !important;
  width: 100%;
`

export const primaryButtonSx = {
  width: 'fit-content',
  margin: '0 !important',

  svg: {
    marginRight: `${theme.spacing.xSmall}px`
  }
}

export const TooltipPopper = styled(Popper)`
  z-index: 10000;
`

export const Tabs = styled(MuiTabs)`
  .MuiTabs-indicator {
    background-color: ${theme.colors.textPrimary};
  }
`

export const Tab = styled(Button)<{ active: boolean; completed?: boolean }>`
  width: 50%;
  max-width: unset;
  min-width: unset;
  height: 64px;
  box-sizing: border-box;
  text-align: left;
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0;

  .MuiTypography-root {
    padding-left: 40px;
    text-transform: none;
    font-family: ${theme.typography.fontFamily.primary};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.xxLarge};
    line-height: 28px;
    color: ${theme.colors.neutral};

    /* Second row of title */
    &:nth-of-type(2) {
      color: ${({ active }) => (active ? theme.baseColors.greyscale[80] : '#7C7579')};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.medium};
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    ${({ active }) =>
      !active &&
      `
      color: #7C7579;
    `}
  }

  svg {
    position: absolute;
    top: 10px;
    left: ${theme.spacing.sm}px;
  }

  &:first-of-type {
    border-bottom: 2px solid ${theme.colors.textPrimary};

    .MuiTypography-root {
      color: ${theme.colors.neutral};
    }

    svg {
      path {
        fill: ${theme.colors.textPrimary};
      }
    }
  }

  ${({ active }) =>
    !active &&
    `
    border-bottom: 2px solid ${theme.colors.borderLow};
    `}

  &:last-of-type {
    border-bottom: ${({ active }) =>
      active ? `2px solid ${theme.colors.textPrimary}` : `2px solid ${theme.colors.borderLow}`};

    .MuiTypography-root {
      color: ${({ active }) => (active ? theme.colors.neutral : '#7C7579')};
    }

    svg {
      path {
        fill: ${({ active }) => (active ? theme.colors.textPrimary : '#7C7579')};
      }
    }
  }

  ${({ completed }) =>
    completed &&
    `
    border-bottom: 2px solid ${theme.colors.textPrimary};
    
    .MuiTypography-root {
      color: ${theme.baseColors.greyscale[80]};
      
       &:nth-of-type(2) {
        color: ${theme.baseColors.greyscale[80]};
       }
    }
  `};
`

export const SentinelActionContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing.xLarge}px;
`

export const SentinelActionButton = styled(Button)<{ secondary?: string; tertiary?: string }>`
  color: ${({ secondary }) => (secondary && secondary === 'true' ? '#FFB4AB' : theme.baseColors.primary[20])};
  font-family: ${theme.typography.fontFamily.primary};
  font-size: ${theme.typography.fontSize.large};
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: ${theme.typography.fontWeight.bold};
  background: ${({ secondary }) => (secondary && secondary === 'true' ? 'transparent' : theme.colors.textPrimary)};
  border-radius: ${theme.attributes.borderRadius.large};
  text-transform: none;
  padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px ${theme.spacing.small}px;

  svg {
    margin-right: ${theme.spacing.xSmall}px;
  }

  &:hover {
    background: rgba(208, 188, 255, 0.08);
  }

  &:focus {
    background: rgba(208, 188, 255, 0.12);
  }

  &:active {
    background: rgba(208, 188, 255, 0.12);
  }

  &.Mui-disabled {
    background: ${theme.colors.backgroundDarker};
    color: ${theme.colors.textPrimary};
    gap: 0;
  }

  ${({ tertiary }) =>
    tertiary &&
    tertiary === 'true' &&
    `
    color: ${theme.colors.textPrimary};
    background: ${theme.colors.backgroundDarker};
    
    svg {
      path {
        fill: ${theme.colors.textPrimary};
      }
    }
  `};

  ${({ disabled }) =>
    disabled &&
    `
    background: rgba(227, 227, 227, 0.12);
    color: ${theme.colors.neutral};
    opacity: 0.38;
  `};
`

export const SentinelInstructionsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${theme.spacing.small}px ${theme.spacing.small}px ${theme.spacing.medium}px;
  width: 100%;
  max-width: 504px;
  height: 100%;
  background: ${theme.colors.backgroundDark};
  box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
  border-radius: ${theme.attributes.borderRadius.secondary};
`

export const SentinelInstructionsTitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.xSmall}px;

  &.wrapper-manual {
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: center;
  }

  .MuiIconButton-root:first-of-type {
    &:hover {
      background: rgba(202, 196, 208, 0.12);
    }
  }
`
export const SentinelInstructionsTitle = styled(Typography)`
  font-family: ${theme.typography.fontFamily.primary};
  font-style: normal;
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.xLarge};
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.1px;
  color: #fbd8f5;
  margin-right: ${theme.spacing.xSmall}px;
`

export const SentinelInstructionsContent = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  margin-top: ${theme.spacing.small}px;

  .external-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing.sm}px ${theme.spacing.medium}px ${theme.spacing.sm}px ${theme.spacing.small}px;
    gap: ${theme.spacing.xSmall}px;
    min-width: 210px;
    width: 100%;
    height: 40px;
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.textPrimary};
    border: 1px solid ${theme.colors.controlBorder};
    border-radius: ${theme.attributes.borderRadius.large};
    text-decoration: none;

    :hover {
      background: rgba(25, 118, 210, 0.04);
    }

    svg {
      margin-right: ${theme.spacing.xSmall}px;
    }
  }

  ol {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #fbd8f5;
    margin: 0;
    padding: 0 0 0 ${theme.spacing.small}px;

    li:not(:last-of-type) {
      margin-bottom: ${theme.spacing.small}px;
    }

    li .MuiLink-root {
      color: ${theme.colors.textPrimary};
      text-decoration: underline;
      margin: 0;
      padding: 0;
      font-weight: ${theme.typography.fontWeight.extraThick};
    }
  }

  .MuiTypography-root {
    font-family: ${theme.typography.fontFamily.primary};
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #fbd8f5;
    margin-left: ${theme.spacing.half}px;
  }

  .tooltip-icon-padded {
    margin-left: ${theme.spacing.xSmall}px;
  }

  .copy-text {
    font-family: 'IBM Plex Mono', monospace;
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    font-size: ${theme.typography.fontSize.large};
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${theme.colors.textPrimary};
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.xSmall}px;

    svg {
      margin-left: ${theme.spacing.half}px;
    }
  }
`

export const SentinelToastWrapper = styled(Box)<{ success: boolean }>`
  width: 100%;
  height: auto;
  background: #6dd58c;
  box-shadow: ${theme.attributes.boxShadow.toolbar};
  border-radius: ${theme.attributes.borderRadius.tertiary};
  background: ${({ success }) => (success ? '#6DD58C' : '#F2B8B5')};
  display: inline-flex;
  box-sizing: border-box;

  svg:first-child {
    margin: ${theme.spacing.medium}px ${theme.spacing.small}px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    box-sizing: border-box;
  }

  svg {
    path {
      fill: ${({ success }) => (success ? theme.baseColors.primary[10] : '#690005')};
    }
  }
`

export const SentinelToastTitle = styled(Typography)<{ success: boolean }>`
  font-family: 'Quicksand', sans-serif !important;
  font-style: normal !important;
  font-weight: ${theme.typography.fontWeight.bold} !important;
  font-size: ${theme.typography.fontSize.xLarge} !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
  color: ${({ success }) => (success ? theme.baseColors.primary[10] : '#690005')} !important;
  margin-bottom: ${theme.spacing.half}px !important;
`

export const SentinelToastMessage = styled(Typography)<{ success: boolean }>`
  font-family: 'Quicksand', sans-serif !important;
  font-style: normal !important;
  font-weight: ${theme.typography.fontWeight.normal} !important;
  font-size: ${theme.typography.fontSize.large} !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  color: ${({ success }) => (success ? theme.baseColors.primary[10] : '#690005')} !important;
  word-break: break-word !important;
  white-space: break-spaces !important;
`

export const SentinelAccordion = styled(Accordion)<{ success: boolean; isopen?: string | false }>`
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  background: ${({ success }) => (success ? '#6DD58C' : '#F2B8B5')};
  box-shadow: none;
  border: 0;
  outline: 0;

  .MuiAccordionSummary-root {
    padding: 0;
    margin: 0;
    min-height: unset;
    height: auto;

    .MuiAccordionSummary-content {
      padding: 0;
      margin: ${({ isopen }) => (isopen ? '-22px 0 0' : 0)};
      flex-flow: column wrap;
    }
  }
`

export const SentinelAccordionDetails = styled(AccordionDetails)`
  padding: 0 ${theme.spacing.small}px ${theme.spacing.small}px 0;
  margin: -35px 0 0;
`

export const SetupTypePickerTabs = styled(MuiTabs)`
  box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  border-radius: ${theme.attributes.borderRadius.large};

  .MuiTabs-indicator {
    background-color: transparent;
  }
`

export const SetupTypePickerTab = styled(MuiTab)<{ active: boolean }>`
  max-width: unset;
  min-width: unset;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  border-radius: 0;

  background: ${theme.colors.primaryLight};
  width: 492px;
  height: 40px;

  &:first-of-type {
    border-radius: ${theme.attributes.borderRadius.large} 0 0 ${theme.attributes.borderRadius.large};
  }

  &:last-of-type {
    border-radius: 0 ${theme.attributes.borderRadius.large} ${theme.attributes.borderRadius.large} 0;
  }

  ${({ active }) =>
    active &&
    `
      background: ${theme.colors.textPrimary};
  `}

  .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    .MuiTypography-root {
      font-family: ${theme.typography.fontFamily.primary};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fbd8f5;
      text-transform: none;

      ${({ active }) =>
        active &&
        `
          color: ${theme.baseColors.primary[20]};
      `}
    }

    svg {
      margin-right: ${theme.spacing.xSmall}px;

      path {
        fill: #fbd8f5;
      }

      ${({ active }) =>
        active &&
        `
          path {
            fill: ${theme.baseColors.primary[20]};
          }
      `}
    }
  }
`
