import theme from 'theme'

const FindingsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
    <mask id="path-1-inside-1_1_1812" fill="white">
      <path d="M0 0.728271H16V3.72827H0V0.728271Z" />
    </mask>
    <path
      d="M16 -0.771729H14V2.22827H16V-0.771729ZM10 -0.771729H6V2.22827H10V-0.771729ZM2 -0.771729H0V2.22827H2V-0.771729ZM16 -2.27173H14V3.72827H16V-2.27173ZM10 -2.27173H6V3.72827H10V-2.27173ZM2 -2.27173H0V3.72827H2V-2.27173Z"
      fill={theme.baseColors.info[20]}
      mask="url(#path-1-inside-1_1_1812)"
    />
  </svg>
)

export default FindingsIcon
