import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import theme from 'theme'
import DataSourceSelect from '../sections/trends-tab/components/controls-components/DataSourceSelect'
import {
  AnalysisData,
  CPITimePeriod,
  IntegrationConfigSyncStatus,
  IntegrationInstance,
  IntegrationStatusMessage,
  LastSeenWithin,
  ManifestIntegration,
  OperatingSystem
} from '../../../../models'
import { fetchSyncStatus } from '../../../../lib/utils'
import { Box, SelectChangeEvent, Typography } from '@mui/material'
import MiscControlSelectField from '../sections/trends-tab/components/controls-components/MiscControlSelectField'
import { ThemeButton } from '../../../components/buttons'
import { usePostHog } from 'posthog-js/react'

const getIntegrationLogo = (name: string, supportedIntegrations: ManifestIntegration[]) => {
  const manifest = supportedIntegrations.find((i) => i.name === name)
  const integrationLogo = manifest?.logo_path

  if (!name || !integrationLogo) {
    return ''
  }

  return `/icons/circle/${integrationLogo}`
}

interface Props {
  firstSource: string
  secondSource: string
  handleChangeSource: (source: 1 | 2, value: string) => void
  selectedSeenWithin: LastSeenWithin
  setSelectedSeenWithin: Dispatch<SetStateAction<LastSeenWithin>>
  fetchData: ({
    timePeriod,
    seenWithin,
    operatingSystem
  }: {
    timePeriod?: CPITimePeriod
    seenWithin?: LastSeenWithin
    operatingSystem?: OperatingSystem
  }) => void
  instances: IntegrationInstance[]
  handleAnalyzed: () => void
  optionsLoading: boolean
  fetchAnalysisData: ({
    integrationIds,
    operatingSystem,
    lastSeenWithin,
    filters
  }: {
    integrationIds: string[]
    operatingSystem?: OperatingSystem
    lastSeenWithin?: LastSeenWithin
    filters?: boolean[]
  }) => void
  mainToolbarList?: boolean
  setTableFilters?: Dispatch<SetStateAction<boolean[]>>
  firstInstanceAssets?: number
  secondInstanceAssets?: number
  supportedIntegrations: ManifestIntegration[]
  numOfActiveAssets?: number
  loadingAnalysisData: boolean
  sourceChanged?: boolean
  fieldWidth?: string
  lastActiveSeenWithin: LastSeenWithin
  setLastActiveSeenWithin: Dispatch<SetStateAction<LastSeenWithin>>
  setSourceChanged?: Dispatch<SetStateAction<boolean>>
  analysisData?: AnalysisData[]
  selectedInstances?: IntegrationInstance[]
  setIsFilterActive?: Dispatch<SetStateAction<boolean>>
  setSelectedOperatingSystem?: Dispatch<SetStateAction<OperatingSystem>>
  setIsTrendsFilterActive?: Dispatch<SetStateAction<boolean>>
  setSelectedTimePeriod?: Dispatch<SetStateAction<CPITimePeriod>>
  setSelectedOsTrends?: Dispatch<SetStateAction<OperatingSystem>>
  setChartLoading?: Dispatch<SetStateAction<boolean>>
  setActiveSeenWithin: Dispatch<SetStateAction<LastSeenWithin>>
  selectedTimePeriod: CPITimePeriod
  selectedOsTrends: OperatingSystem
  setSearch?: Dispatch<SetStateAction<string>>
}

interface SourceError {
  errorMessage?: string
}

const ToolbarList: FC<Props> = ({
  firstSource,
  secondSource,
  handleChangeSource,
  selectedSeenWithin,
  setSelectedSeenWithin,
  fetchData,
  instances,
  handleAnalyzed,
  optionsLoading,
  fetchAnalysisData,
  mainToolbarList,
  setTableFilters,
  firstInstanceAssets,
  secondInstanceAssets,
  supportedIntegrations,
  numOfActiveAssets,
  loadingAnalysisData,
  sourceChanged,
  fieldWidth,
  setIsTrendsFilterActive,
  lastActiveSeenWithin,
  setLastActiveSeenWithin,
  setSourceChanged,
  analysisData,
  selectedInstances,
  setIsFilterActive,
  setSelectedOperatingSystem,
  setSelectedOsTrends,
  setSelectedTimePeriod,
  setChartLoading,
  setActiveSeenWithin,
  // selectedTimePeriod,
  // selectedOsTrends,
  setSearch
}) => {
  const posthog = usePostHog()

  const sortArr = (arr: any) => {
    return arr.sort((a: { label: string }, b: { label: string }) => {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
  }

  const dataSourcesOptions = sortArr(
    instances.map((c) => ({
      label: c.name,
      value: c.id,
      src: getIntegrationLogo(c.integration_name, supportedIntegrations)
    }))
  )

  const [firstSourceError, setFirstSourceError] = useState<SourceError | null>(null)
  const [secondSourceError, setSecondSourceError] = useState<SourceError | null>(null)

  const firstInstance = instances.find((n) => n.id === firstSource)
  const secondInstance = instances.find((n) => n.id === secondSource)

  useEffect(() => {
    if (instances.length > 0) {
      const firstInstance = instances.find((n) => n.id === firstSource)
      const secondInstance = instances.find((n) => n.id === secondSource)

      if (firstInstance) {
        getStatus(firstInstance, 'first')
      }
      if (secondInstance) {
        getStatus(secondInstance, 'second')
      }
    }
  }, [instances, firstSource, secondSource])

  const getStatus = async (instance: IntegrationInstance, source: 'first' | 'second') => {
    const res: IntegrationConfigSyncStatus | null = await fetchSyncStatus(instance.id)

    if (!res) return

    if (res.status.toString().includes('20')) {
      if (source === 'first') {
        if (res && res.status && res.status === IntegrationStatusMessage.failed) {
          setFirstSourceError({
            errorMessage: res.errorMessage
          })
        }
      }
      if (source === 'second') {
        if (res && res.status && res.status === IntegrationStatusMessage.failed) {
          setSecondSourceError({
            errorMessage: res.errorMessage
          })
        }
      }
    }
  }

  const getDisabledOption = (order: 1 | 2, value: string) => {
    if (value === 'clear') {
      if (order === 1 && firstSource) return false
      return !(order === 2 && secondSource)
    }
    if (order === 1) {
      // const found =
      //   acaConfiguration && acaConfiguration.data.integrations.length > 0
      //     ? acaConfiguration.data.integrations.find((n) => n.id === value)
      //     : null
      // if (found && found.data_status !== 'ready') {
      //   return true
      // }
      return value === secondSource
    }
    if (order === 2) {
      // const found =
      //   acaConfiguration && acaConfiguration.data.integrations.length > 0
      //     ? acaConfiguration.data.integrations.find((n) => n.id === value)
      //     : null
      // if (found && found.data_status !== 'ready') {
      //   return true
      // }
      return value === firstSource
    }

    return false
  }

  const handleChangeSeenWithin = async (event: SelectChangeEvent) => {
    const lastSeenWithin = event.target.value as LastSeenWithin
    setSelectedSeenWithin(lastSeenWithin)

    if (
      typeof setSourceChanged !== 'undefined' &&
      typeof analysisData !== 'undefined' &&
      typeof selectedInstances !== 'undefined'
    ) {
      let sourceChanged = false
      const firstSourceSame = selectedInstances[0].id !== firstSource
      const secondSourceSame = selectedInstances[1].id !== secondSource

      if (firstSourceSame || secondSourceSame) {
        sourceChanged = true
      }
      if (lastSeenWithin !== lastActiveSeenWithin) {
        setSourceChanged(true)
      } else {
        if (sourceChanged) {
          setSourceChanged(true)
        } else {
          setSourceChanged(false)
        }
      }
    }
  }

  const analyzeHandler = async () => {
    if (setChartLoading) {
      setChartLoading(true)
    }
    if (setSearch) {
      setSearch('')
    }

    const defaultFilters = [false, false, false, false]

    if (setIsFilterActive) {
      setIsFilterActive(false)
    }
    if (setSelectedOperatingSystem) {
      setSelectedOperatingSystem(OperatingSystem.All)
    }
    if (setIsTrendsFilterActive) {
      setIsTrendsFilterActive(false)
    }

    setActiveSeenWithin(selectedSeenWithin)
    handleAnalyzed()
    await fetchAnalysisData({
      integrationIds: [firstSource, secondSource],
      lastSeenWithin: selectedSeenWithin,
      filters: defaultFilters,
      operatingSystem: OperatingSystem.All /* ONYXIA-4787 */
    })
    await fetchData({
      seenWithin: selectedSeenWithin,
      operatingSystem: OperatingSystem.All /* ONYXIA-4787 */,
      timePeriod: CPITimePeriod.ThreeMonths /* ONYXIA-4787 */
    })

    /* ONYXIA-4787 */
    if (setSelectedTimePeriod) {
      setSelectedTimePeriod(CPITimePeriod.ThreeMonths)
    }
    if (setSelectedOsTrends) {
      setSelectedOsTrends(OperatingSystem.All)
    }
    // if (setSelectedTimePeriod) {
    //   setSelectedTimePeriod(CPITimePeriod.Week)
    // }
    setLastActiveSeenWithin(selectedSeenWithin)

    if (setTableFilters) {
      setTableFilters(defaultFilters)
    }

    if (setChartLoading) {
      setChartLoading(false)
    }

    console.log('posthog event', posthog)
    posthog?.capture('clicked_analyze', { property: 'value analyze' })
  }

  return (
    <>
      <DataSourceSelect
        label="Assets Source A"
        order={1}
        options={dataSourcesOptions}
        instances={instances}
        value={firstSource}
        handleChange={handleChangeSource}
        disabled={optionsLoading}
        getDisabledOption={getDisabledOption}
        hasFailed={!!firstSourceError}
        errorMessage={firstSourceError?.errorMessage || ''}
        instance={firstInstance}
        numberOfAssets={firstInstanceAssets ?? undefined}
        placeholder="Select instance name..."
        wrapperSx={{
          '.MuiFormControl-root': !mainToolbarList && { minWidth: '100% !important' },
          '.header-wrapper': {
            '.source-selector-title': {
              marginBottom: `${theme.spacing.xSmall}px !important`
            }
          }
        }}
        fieldWidth={fieldWidth}
        acaMenuProps
      />
      <DataSourceSelect
        label="Assets Source B"
        order={2}
        numberOfAssets={secondInstanceAssets ?? undefined}
        options={dataSourcesOptions}
        instances={instances}
        value={secondSource}
        handleChange={handleChangeSource}
        disabled={optionsLoading}
        getDisabledOption={getDisabledOption}
        hasFailed={!!secondSourceError}
        errorMessage={secondSourceError?.errorMessage || ''}
        instance={secondInstance}
        placeholder="Select instance name..."
        wrapperSx={{
          '.MuiFormControl-root': !mainToolbarList && { minWidth: '100% !important' },
          '.header-wrapper': {
            '.source-selector-title': {
              marginBottom: `${theme.spacing.xSmall}px !important`
            }
          }
        }}
        fieldWidth={fieldWidth}
        acaMenuProps
      />
      <Box className="last-seen">
        <Typography className="misc-control-title">Active Assets</Typography>
        <MiscControlSelectField
          options={Object.values(LastSeenWithin)}
          label=""
          placeholder=""
          value={selectedSeenWithin}
          disabled={optionsLoading}
          handleChange={handleChangeSeenWithin}
          numberOfAssets={numOfActiveAssets ?? undefined}
        />
      </Box>

      <Box className="action-wrapper">
        <ThemeButton
          themevariant="primary"
          type="button"
          onClick={analyzeHandler}
          disabled={
            !(firstSource.length && secondSource.length) ||
            loadingAnalysisData ||
            (typeof selectedInstances === 'undefined' ? sourceChanged : !sourceChanged)
          }
          sx={{ height: '40px !important', padding: '10px 22px !important' }}
        >
          Analyze
        </ThemeButton>
      </Box>
    </>
  )
}

export default ToolbarList
