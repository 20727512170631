import styled from '@emotion/styled/macro'
import { Box, DialogActions } from '@mui/material'
import theme from 'theme'

export const Wrapper = styled(Box)`
  padding: ${theme.spacing.medium}px;

  .description {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    width: 350px;
  }

  .description-line {
    color: ${theme.colors.neutral};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 32px;
  }
`

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.small}px;

  .header-block {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.small}px;
  }

  .title {
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: 32px;
    color: ${theme.colors.neutral};
  }
`

export const Actions = styled(DialogActions)`
  margin-top: ${theme.spacing.medium}px;
  padding: 0;
`
