import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { useAuth } from '@frontegg/react'
import axios from '../../../../../lib/axios'
import theme from 'theme'

/* Utils */
import { hasPermission, Permission } from '../../../../../lib/utils'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeToast, openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'
import { modalPropsSelector, ModalType, openModal } from '../../../../../store/modals/modalSlice'

/* Components */
import { ThemeButton } from '../../../buttons'
import { Actions, Content } from './userProfile.styles'
import { CopyIcon, EmailIcon } from '../../../svg'

const UserProfile: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const user = modalProps?.props?.user
  const { user: authUser } = useAuth()

  const handleChangeName = () => {
    if (!hasPermission(Permission.UsersSelfProfileUpdate, authUser)) {
      return
    }
    dispatch(openModal({ type: ModalType.changeName, props: modalProps.props }))
  }

  const handleChangeEmail = () => {
    if (!hasPermission(Permission.UsersSelfProfileUpdate, authUser)) {
      return
    }
    dispatch(openModal({ type: ModalType.changeEmail, props: modalProps.props }))
  }

  const handleChangeRole = () => {
    if (!user?.role || !hasPermission(Permission.UsersSelfProfileUpdate, authUser)) {
      return
    }
    dispatch(openModal({ type: ModalType.changeRole, props: modalProps.props }))
  }

  const handleResendInvitationLink = async () => {
    if (!user) return

    try {
      const res = await axios.post(`/api/v1/identity/users/${user.id}/invite`)

      if (res.status === 200) {
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Reset Invitation Link Sent',
              description: 'Check your email for a link.'
            }
          })
        )
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed sending link',
            description:
              (e as any).response?.data.message ?? 'There was a problem while sending your password reset link.'
          }
        })
      )
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const handleEmailPasswordResetLink = async () => {
    if (!hasPermission(Permission.UsersSelfPasswordUpdate, authUser)) {
      return
    }
    try {
      if (user?.id === authUser?.id) {
        const res = await axios.post('/api/v1/identity/users/password/self')

        if (res.status === 200) {
          dispatch(
            openToast({
              variant: ToastVariant.success,
              props: {
                text: 'Reset Password Link Sent',
                description: 'Check your email for a link.'
              }
            })
          )
        }
      } else {
        const res = await axios.post(`api/v1/identity/users/${user?.id}/password`)

        if (res.status === 200) {
          dispatch(
            openToast({
              variant: ToastVariant.success,
              props: {
                text: 'Reset Password Link Sent',
                description: `Email sent to ${user?.email}`
              }
            })
          )
        }
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed sending link',
            description:
              (e as any).response?.data.message ?? 'There was a problem while sending your password reset link.'
          }
        })
      )
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const handleCopyPasswordResetLink = async () => {
    if (!hasPermission(Permission.UsersSelfProfileUpdate, authUser)) {
      return
    }
    if (!user) return

    try {
      const res = await axios.get(`/api/v1/identity/users/${user.id}/password`)

      if (res.status === 200) {
        const { link } = res.data
        window.navigator.clipboard.writeText(link)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Password Reset Link copied to clipboard.',
              description: ''
            }
          })
        )
      }
    } catch (e) {
      let message = ''
      if ((e as any).response.data.code === 'unauthorized') {
        message = 'You are not authorized for this action.'
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Failed copying link.',
              description: message
            }
          })
        )
      }
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const handleDeleteAccount = async () => {
    dispatch(openModal({ type: ModalType.deleteAccount, props: modalProps.props }))
  }

  const checkPermission = (
    isOwnProfile: boolean,
    ownProfilePermission: Permission,
    otherProfilePermission: Permission
  ) => {
    if (isOwnProfile) {
      return !hasPermission(ownProfilePermission, authUser)
    }

    if (!user?.verified) return true

    return !hasPermission(otherProfilePermission, authUser)
  }

  if (!user) {
    return <div>No User found.</div>
  }
  return (
    <Box>
      <Content userrole={user.role?.toLowerCase()}>
        <Box className="info-wrapper">
          <Box className="info-item">
            <Typography className="title">Name</Typography>
            <Box className="description-box">
              <Typography className="description">{user.name}</Typography>
              <ThemeButton
                themevariant="tertiary"
                onClick={handleChangeName}
                disabled={checkPermission(
                  user.id === authUser?.id,
                  Permission.UsersSelfProfileUpdate,
                  Permission.UsersProfileUpdate
                )}
              >
                Change Name
              </ThemeButton>
            </Box>
          </Box>
          <Box className="info-item" sx={{ marginTop: '0 !important' }}>
            <Typography className="title">Email</Typography>
            <Box className="description-box">
              <Typography className="description" data-private>
                {user.email}
              </Typography>
              <ThemeButton
                themevariant="tertiary"
                onClick={handleChangeEmail}
                disabled={checkPermission(
                  user.id === authUser?.id,
                  Permission.UsersSelfProfileUpdate,
                  Permission.UsersProfileUpdate
                )}
              >
                Change Email
              </ThemeButton>
            </Box>
          </Box>
          <Box className="info-item" sx={{ marginTop: '0 !important' }}>
            <Typography className="title">Password</Typography>
            <ThemeButton
              themevariant="secondary"
              onClick={handleEmailPasswordResetLink}
              disabled={checkPermission(
                user.id === authUser?.id,
                Permission.UsersSelfPasswordUpdate,
                Permission.UsersPasswordUpdate
              )}
            >
              <>
                <EmailIcon />
                Email Password Reset Link
              </>
            </ThemeButton>
            <ThemeButton
              themevariant="secondary"
              onClick={handleCopyPasswordResetLink}
              sx={{ marginTop: `${theme.spacing.half}px` }}
              disabled={checkPermission(
                user.id === authUser?.id,
                Permission.UsersSelfPasswordUpdate,
                Permission.UsersPasswordUpdate
              )}
            >
              <>
                <CopyIcon />
                Copy Password Reset Link
              </>
            </ThemeButton>
          </Box>
        </Box>
        <Box className="info-wrapper" sx={{ marginTop: `${theme.spacing.medium}px` }}>
          <Box className="info-item">
            <Typography className="title">Role</Typography>
            <Box className="description-box">
              <Box className="permissions-container">
                <Box className="permissions-title">
                  {['cpi_manager', 'CPI Manager'].includes(user.role) ? 'Manager' : user.role}
                </Box>
              </Box>
              {hasPermission(Permission.UsersRoleUpdate, authUser) && (
                <ThemeButton
                  themevariant="tertiary"
                  onClick={handleChangeRole}
                  disabled={
                    checkPermission(user.id === authUser?.id, Permission.UsersRoleUpdate, Permission.UsersRoleUpdate) ||
                    !user.role
                  }
                >
                  Change Role
                </ThemeButton>
              )}
            </Box>
          </Box>
        </Box>
        <Box className="info-wrapper" sx={{ marginTop: `${theme.spacing.medium}px` }}>
          <Box className="info-item">
            <Typography className="title">Status</Typography>
            <Box className="description-box">
              <Box className="status-container">
                <Box className="user-status">{user.verified ? 'Active' : 'Pending Approval'}</Box>
              </Box>
              {authUser?.id === user.id && (
                <>
                  {!user.verified ? (
                    <ThemeButton
                      themevariant="tertiary"
                      onClick={handleDeleteAccount}
                      sx={{
                        width: 'fit-content',
                        color: `${theme.baseColors.info[50]} !important`,
                        marginLeft: `${theme.spacing.xSmall}px`
                      }}
                      disabled={checkPermission(
                        user.id === authUser?.id,
                        Permission.UsersSelfProfileUpdate,
                        Permission.SubscriptionDelete
                      )}
                    >
                      Revoke Invitation & Delete Account
                    </ThemeButton>
                  ) : (
                    <ThemeButton
                      themevariant="tertiary"
                      onClick={handleDeleteAccount}
                      sx={{
                        width: 'fit-content',
                        color: `${theme.baseColors.info[50]} !important`,
                        marginLeft: `${theme.spacing.xSmall}px`
                      }}
                      disabled={checkPermission(
                        user.id === authUser?.id,
                        Permission.UsersSelfProfileUpdate,
                        Permission.SubscriptionDelete
                      )}
                    >
                      Delete Account
                    </ThemeButton>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Content>
      {!user.verified && (
        <Actions>
          <ThemeButton
            themevariant="primary"
            onClick={handleResendInvitationLink}
            disabled={!hasPermission(Permission.UsersInvite, authUser)}
          >
            <>
              <EmailIcon />
              Resend Invitation Link
            </>
          </ThemeButton>
        </Actions>
      )}
    </Box>
  )
}

export default UserProfile
