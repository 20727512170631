import { Dispatch, forwardRef, MouseEvent, SetStateAction, useState } from 'react'
import { Box, Button, Menu, MenuItem, SelectChangeEvent, Typography } from '@mui/material'
import dayjs from 'dayjs'
import theme from 'theme'

/* Utils */
import {
  ChartData,
  ConfiguredCPI,
  CPIFrequency,
  CPIManifest,
  CPISeverity,
  CPITimePeriod,
  CustomTrendsDataPayload,
  DataStatus,
  IntegrationInstance,
  PredictionChartData,
  PredictionData,
  PredictionDataExtended,
  TrendsDataPayload
} from '../../../../../../../models'
import {
  filterPredictionData,
  formatCustomTimePickerValue,
  formatPerformanceUnit,
  getPayloadTimePeriod,
  getPayloadTrendsChart,
  getUtcDateAndTime
} from '../../../../utils'
import { dateFormat } from '../../../../../../../lib/utils'

/* Components */
import {
  FindingsIcon,
  LegendFindingIcon,
  LegendPerformanceIcon,
  LegendSlaIcon,
  PinLabelIcon
} from '../../../../../../components/svg'
import { ChipsContainer } from '../../../../components/library-components.styles'
import LibraryDataSourceSelect from '../../../../components/LibraryDataSourceSelect'
import NoPerformanceData from '../components/NoPerformanceData'
import LegendPerformanceRedIcon from '../../../../../../components/svg/library/LegendPerformanceRedIcon'
import TrendsChartSelectField from '../components/TrendsChartSelectField'
import {
  LabelsMenuWrapper,
  DescriptionWrapper,
  OnyxAIWrapper,
  StatusValueText,
  OnyxAITooltipWrapper
} from '../trendsTab.styles'
import CustomTimePicker from '../../../../../../components/custom-time-picker'
import OnyxAIIcon from '../../../../../../components/svg/OnyxAIIcon'
import { OnyxAITooltip } from './OnyxAITooltip'
import { getDataSourceAllOption } from '../../../../library.constants'
import { ChipsContainerTrendsDescription } from '../components/trendsChartDescription.styles'

type Ref = HTMLDivElement | null

interface Props {
  fetchPredictionData: (cpiName: string, severity?: CPISeverity, dataSourceName?: string) => Promise<PredictionData[]>
  setRedirectToMode: Dispatch<SetStateAction<string>>
  isMediumHeight: boolean
  manifestData: CPIManifest
  chartData: ChartData | null
  setChartData: Dispatch<SetStateAction<ChartData | null>>
  setSelectedTimePeriod: Dispatch<SetStateAction<CPITimePeriod>>
  setSelectedFrequency: Dispatch<SetStateAction<CPIFrequency>>
  setSelectedDataSourceIds: Dispatch<SetStateAction<string[]>>
  setDataSourceValue: Dispatch<SetStateAction<string>>
  selectedTimePeriod: CPITimePeriod
  selectedSeverity: CPISeverity
  selectedDataSourceIds: string[]
  selectedFrequency: CPIFrequency
  fetchTrendsData: (payload: TrendsDataPayload, stripes?: number | null | undefined) => Promise<ChartData | undefined>
  userConfiguredIntegrations: IntegrationInstance[]
  cpiConfiguration: ConfiguredCPI | null
  cpiSupportedAndUserConfiguredList: IntegrationInstance[]
  timePeriodValues: Array<CPITimePeriod | string>
  frequencyValues: Array<CPIFrequency>
  dataSourceOptions: IntegrationInstance[]
  dataSourceValue: string
  setEmptyInfoTooltipOpen: Dispatch<SetStateAction<boolean>>
  emptyInfoTooltipOpen: boolean
  handleEmptyInfoClick: () => void
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  setMode: Dispatch<SetStateAction<string>>
  zone: string
  status?: DataStatus
  customTimePeriodValue: string
  setCustomTimePeriodValue: Dispatch<SetStateAction<string>>
  renderSeverities: () => JSX.Element | null
  predictionModalOpen: boolean
  handlePredictionModalOpen: () => void
  hasPredictionsEnabled: boolean
  predictionModelAvailable: boolean
  daysUntilDataAvailable: number
  setSelectedType: Dispatch<SetStateAction<string>>
  selectedType: string
  renderingChart?: boolean
  setRenderingChart: any
  cpiName: string
  setPredictionData: Dispatch<SetStateAction<PredictionChartData | null>>
}

const TrendsChartDescription = forwardRef<Ref, Props>(
  (
    {
      fetchPredictionData,
      setPredictionData,
      setRedirectToMode,
      isMediumHeight,
      manifestData,
      chartData,
      setChartData,
      selectedTimePeriod,
      setSelectedTimePeriod,
      setSelectedFrequency,
      selectedDataSourceIds,
      selectedFrequency,
      fetchTrendsData,
      selectedSeverity,
      setSelectedDataSourceIds,
      setDataSourceValue,
      timePeriodValues,
      frequencyValues,
      dataSourceOptions,
      dataSourceValue,
      selectedType,
      setEmptyInfoTooltipOpen,
      emptyInfoTooltipOpen,
      setSelectedType,
      handleEmptyInfoClick,
      setDrawerOpen,
      setMode,
      zone,
      status,
      customTimePeriodValue,
      setCustomTimePeriodValue,
      renderSeverities,
      handlePredictionModalOpen,
      hasPredictionsEnabled,
      predictionModelAvailable,
      daysUntilDataAvailable,
      renderingChart,
      setRenderingChart,
      cpiName
    },
    ref
  ) => {
    const options = [
      { id: 1, label: 'View All Labels', value: 'show-0', redirectToMode: 'show-0' },
      { id: 2, label: 'View Pinned Labels', value: 'show-1', redirectToMode: 'show-1' },
      { id: 3, label: 'Create Custom Label', value: 'create', redirectToMode: 'show-0' }
    ]
    const allOptionText = `All (${dataSourceOptions.length})`

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
      setAnchorEl(null)
    }
    const handleClose = (item: any) => {
      setMode(item.value)
      setRedirectToMode(item.redirectToMode)
      setDrawerOpen(true)
      setAnchorEl(null)
    }

    const handleChangeTimePeriod = async (event: SelectChangeEvent | null, customPayload?: any) => {
      setRenderingChart(true)
      const period = event ? (event.target.value as CPITimePeriod) : CPITimePeriod.Custom
      setSelectedTimePeriod(period)
      let thisFrequency = selectedFrequency

      if (period === CPITimePeriod.Week) {
        thisFrequency = CPIFrequency.Daily
        setSelectedFrequency(CPIFrequency.Daily)
      }
      if (period === CPITimePeriod.Month && selectedFrequency === CPIFrequency.Monthly) {
        thisFrequency = CPIFrequency.Weekly
        setSelectedFrequency(CPIFrequency.Weekly)
      }

      const payload: TrendsDataPayload = {
        section: selectedSeverity,
        integration_ids: selectedDataSourceIds,
        frequency: thisFrequency,
        type: selectedType
      }

      if (period === CPITimePeriod.Custom) {
        /**/
        const { dateFrom, dateTo } = customPayload

        const formattedValue = formatCustomTimePickerValue(dateFrom, dateTo)
        setCustomTimePeriodValue(formattedValue)
        payload.dateFrom = dateFrom
        payload.dateTo = dateTo
      } else {
        setCustomTimePeriodValue('')
        payload.timePeriod = getPayloadTimePeriod(period)
      }

      if (customPayload && customPayload.stripes) {
        const dataTrendsRes = await fetchTrendsData(payload, customPayload.stripes)
        setChartData(dataTrendsRes ?? null)
      } else {
        const dataTrendsRes = await fetchTrendsData(payload)
        setChartData(dataTrendsRes ?? null)
      }
    }

    const handleChangeFrequency = async (event: SelectChangeEvent) => {
      setRenderingChart(true)
      const frequency = event.target.value as CPIFrequency
      setSelectedFrequency(frequency)
      let thisTimePeriod = selectedTimePeriod

      if (frequency === CPIFrequency.Weekly && selectedTimePeriod === CPITimePeriod.Week) {
        thisTimePeriod = CPITimePeriod.Month
        setSelectedTimePeriod(CPITimePeriod.Month)
      } else if (
        frequency === CPIFrequency.Monthly &&
        (selectedTimePeriod === CPITimePeriod.Week || selectedTimePeriod === CPITimePeriod.Month)
      ) {
        thisTimePeriod = CPITimePeriod.ThreeMonths
        setSelectedTimePeriod(CPITimePeriod.ThreeMonths)
      }

      const payload: CustomTrendsDataPayload = getPayloadTrendsChart({
        severity: selectedSeverity,
        selectedDataSourceIds,
        selectedTimePeriod: thisTimePeriod,
        selectedFrequency: frequency,
        customTimePeriodValue,
        type: selectedType
      })
      const { stripes } = payload
      if (typeof stripes !== 'undefined') {
        delete payload.stripes
      }

      const dataTrendsRes = await fetchTrendsData(payload, stripes ?? undefined)
      setChartData(dataTrendsRes ?? null)
    }

    const handleChangeType = async (event: SelectChangeEvent) => {
      setRenderingChart(true)
      const type = event.target.value
      setSelectedType(type)

      const payload: CustomTrendsDataPayload = getPayloadTrendsChart({
        severity: selectedSeverity,
        selectedDataSourceIds,
        selectedTimePeriod,
        selectedFrequency,
        customTimePeriodValue,
        type
      })
      const { stripes } = payload
      if (typeof stripes !== 'undefined') {
        delete payload.stripes
      }

      const dataTrendsRes = await fetchTrendsData(payload, stripes ?? undefined)
      setChartData(dataTrendsRes ?? null)
    }

    const checkTimePeriodDisabled = (): boolean => {
      return chartData?.status !== DataStatus.Ready
    }

    const checkFrequencyDisabled = (): boolean => {
      return chartData?.status !== DataStatus.Ready
    }

    const handleChangeDataSource = async (event: SelectChangeEvent<string>) => {
      setRenderingChart(true)
      const {
        target: { value }
      } = event

      // eslint-disable-next-line array-callback-return
      const findItemsIds = dataSourceOptions.map((item) => {
        if (item.name === value) return item.id
      })

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const filtersArr: string[] = findItemsIds.filter(Boolean)
      const selectedSources = value === allOptionText ? [] : filtersArr
      setSelectedDataSourceIds(selectedSources)
      setDataSourceValue(value === allOptionText ? `All (${dataSourceOptions.length})` : value)

      const payload: CustomTrendsDataPayload = getPayloadTrendsChart({
        severity: selectedSeverity,
        selectedDataSourceIds: selectedSources,
        selectedTimePeriod,
        selectedFrequency,
        customTimePeriodValue,
        type: selectedType
      })
      const { stripes } = payload
      if (typeof stripes !== 'undefined') {
        delete payload.stripes
      }

      const dataTrendsRes = await fetchTrendsData(payload, stripes ?? undefined)
      setChartData(dataTrendsRes ?? null)

      if (hasPredictionsEnabled) {
        fetchPredictionData(
          cpiName || '',
          selectedSeverity,
          selectedSources.length === 1 ? selectedSources[0] : 'all'
        ).then((predictionValues: PredictionData[]) => {
          if (dataTrendsRes) {
            const allValues: PredictionDataExtended[] = [
              ...dataTrendsRes.values.map((n) => ({ xValue: n.xValue, yValue: n.yValue, prediction: false })),
              ...predictionValues.map((n) => ({ ...n, prediction: true }))
            ]

            const chartData: PredictionChartData = {
              ...dataTrendsRes,
              values: allValues
            }
            const filteredData: PredictionDataExtended[] = filterPredictionData(2, chartData)
            setPredictionData({
              ...chartData,
              values: [...filteredData]
            })
          }
        })
      }
    }

    const getLastValue = () => {
      if (!chartData) return ''
      const lastValue = chartData?.recentValue || 0

      const manifestUnit = manifestData?.ui.trend_chart.user_input.visualization_param.unit
      const unitText = formatPerformanceUnit(manifestUnit)

      return `${lastValue}` + `<span>${unitText}</span>`
    }

    const checkUnit = () => {
      const unit = manifestData?.ui.trend_chart.user_input.visualization_param.unit
      return ['assets', 'alerts', 'incidents', 'events'].includes(unit)
    }

    const getRecentValueDate = () => {
      if (!chartData) return dayjs().format(dateFormat.auditLogDefault)
      const date = getUtcDateAndTime(chartData.recentDate).labelFormatDate

      return dayjs(date).format(dateFormat.auditLogDefault)
    }

    const handleClickOnyxAI = () => {
      handlePredictionModalOpen()
    }

    const allOption = getDataSourceAllOption(dataSourceOptions.length)

    return (
      <DescriptionWrapper
        sx={{ gap: isMediumHeight ? `${theme.spacing.half}px` : `${theme.spacing.small}px` }}
        status={status}
        zone={zone}
        className="description-wrapper"
        ref={ref}
      >
        <Box className="box-wrapper wrapper-measurement">
          <Box className="measurement-wrapper">
            {chartData?.status !== DataStatus.Ready && (
              <Typography className="status">{chartData?.status.toLowerCase()}</Typography>
            )}

            {chartData && chartData.status === DataStatus.Ready && (
              <>
                {chartData.values && chartData.values.length > 0 ? (
                  <StatusValueText
                    className={checkUnit() ? 'column status' : 'status'}
                    status={status}
                    zone={zone}
                    dangerouslySetInnerHTML={{ __html: getLastValue() }}
                  />
                ) : (
                  <NoPerformanceData
                    setEmptyInfoTooltipOpen={setEmptyInfoTooltipOpen}
                    emptyInfoTooltipOpen={emptyInfoTooltipOpen}
                    handleEmptyInfoClick={handleEmptyInfoClick}
                  />
                )}
                <Box>
                  {renderSeverities() === null ? (
                    <Typography className="text">Most Recent Performance:</Typography>
                  ) : (
                    <Typography className="text">
                      Most Recent Performance of (<span className="severity">{selectedSeverity}</span> Severities):
                    </Typography>
                  )}
                  <Typography className="updated-at">{getRecentValueDate()}</Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box className="box-wrapper legend-wrapper">
          <Box className="legend">
            <Typography className="legend-key">Key:</Typography>
            <Box className="legend-container">
              <Box className="legend-item">
                {chartData?.zoneColor === 'green' ? <LegendPerformanceIcon /> : <LegendPerformanceRedIcon />}
                <Typography>Performance</Typography>
              </Box>
              <Box className="legend-item">
                <LegendSlaIcon />
                <Typography>SLA</Typography>
              </Box>
              <Box className="legend-item">
                <FindingsIcon />
                <Typography>Current Finding</Typography>
              </Box>
              <Box className="legend-item">
                <LegendFindingIcon />
                <Typography>Labels</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="box-wrapper wrapper-options">
          <Box className="box-wrapper-options">
            {renderSeverities() !== null && (
              <ChipsContainerTrendsDescription className="chips-container severity">
                {renderSeverities()}
              </ChipsContainerTrendsDescription>
            )}

            <CustomTimePicker
              timePeriodValues={timePeriodValues}
              selectedTimePeriod={selectedTimePeriod}
              handleChangeTimePeriod={handleChangeTimePeriod}
              customTimePeriodValue={customTimePeriodValue}
              checkTimePeriodDisabled={checkTimePeriodDisabled}
              hideSelected
              disabled={Boolean(renderingChart)}
            />

            <ChipsContainerTrendsDescription className="chips-container frequency">
              <Typography className="chips-title">Frequency:</Typography>
              <TrendsChartSelectField
                options={frequencyValues}
                label=""
                placeholder="Filter by Time Period"
                labeltext="Filter by: "
                value={selectedFrequency}
                handleChange={handleChangeFrequency}
                disabled={checkFrequencyDisabled() || Boolean(renderingChart)}
                minWidth="75px !important"
                hideSelected
              />
            </ChipsContainerTrendsDescription>

            {manifestData && manifestData.ui.trend_chart.type && (
              <ChipsContainer className="chips-container frequency">
                <Typography className="chips-title">{manifestData.ui.trend_chart.type.display_name}:</Typography>
                <TrendsChartSelectField
                  options={manifestData.ui.trend_chart.type.values.map((n) => n.display_name)}
                  label=""
                  placeholder="Filter by Type"
                  labeltext="Filter by: "
                  value={selectedType}
                  handleChange={handleChangeType}
                  minWidth="75px !important"
                  hideSelected
                  disabled={Boolean(renderingChart)}
                />
              </ChipsContainer>
            )}

            <Box className="data-source-wrapper">
              <Typography className="title">Data Source(s):</Typography>
              <Box className="data-source-select-wrapper">
                <LibraryDataSourceSelect
                  customClass="trends-source"
                  label=""
                  dataSourceOptions={[allOption, ...dataSourceOptions]}
                  allOptionText={allOptionText}
                  placeholder="Data Source"
                  value={dataSourceValue}
                  manifestData={manifestData}
                  handleChange={handleChangeDataSource}
                  disabled={chartData?.status !== DataStatus.Ready || Boolean(renderingChart)}
                  formControlMinWidth={[allOption, ...dataSourceOptions].length > 4 ? '200px !important' : ''}
                  disablePointerEvents={Boolean(renderingChart)}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <LabelsMenuWrapper className="box-wrapper px-7">
          <Box>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              className="label-wrapper"
              onClick={handleClick}
            >
              <Typography className="title">Labels:</Typography>
              <PinLabelIcon />
            </Button>

            <Menu
              className="menu-list"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={{
                '& .MuiMenu-paper': { backgroundColor: theme.colors.primaryLight }
              }}
            >
              {options.map((item) => {
                return (
                  <MenuItem
                    onClick={() => handleClose(item)}
                    key={item.id}
                    sx={{
                      '&.MuiMenuItem-root': {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        fontFamily: theme.typography.fontFamily.primary
                      },
                      '&.MuiMenuItem-root:hover': { backgroundColor: 'rgba(208, 188, 255, 0.16)' }
                    }}
                  >
                    {item.label}
                  </MenuItem>
                )
              })}
            </Menu>
          </Box>
        </LabelsMenuWrapper>
        {hasPredictionsEnabled && (
          <OnyxAIWrapper has_model={predictionModelAvailable ? 'true' : 'false'}>
            <Typography className="onyxai-text">OnyxAI</Typography>
            {predictionModelAvailable ? (
              <Button className="onyxai-btn" onClick={handleClickOnyxAI}>
                <OnyxAIIcon />
              </Button>
            ) : (
              <OnyxAITooltip
                placement="bottom-end"
                title={
                  <>
                    <OnyxAITooltipWrapper>
                      <Typography className="tooltip-title">Come back soon :)</Typography>
                      <Typography className="tooltip-text">
                        Please come back in {daysUntilDataAvailable} days. OnyxAI needs to collect data for 2 months
                        until it can predict a trend.
                      </Typography>
                    </OnyxAITooltipWrapper>
                  </>
                }
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="not-available-svg"
                >
                  <circle cx="20" cy="20" r="19.6429" stroke="white" strokeOpacity="0.4" strokeWidth="0.714286" />
                  <path
                    d="M26 17L27.25 14.25L30 13L27.25 11.75L26 9L24.75 11.75L22 13L24.75 14.25L26 17Z"
                    fill="white"
                    fillOpacity="0.4"
                  />
                  <path
                    d="M26 23L24.75 25.75L22 27L24.75 28.25L26 31L27.25 28.25L30 27L27.25 25.75L26 23Z"
                    fill="white"
                    fillOpacity="0.4"
                  />
                  <path
                    d="M18.5 17.5L16 12L13.5 17.5L8 20L13.5 22.5L16 28L18.5 22.5L24 20L18.5 17.5ZM16.99 20.99L16 23.17L15.01 20.99L12.83 20L15.01 19.01L16 16.83L16.99 19.01L19.17 20L16.99 20.99Z"
                    fill="white"
                    fillOpacity="0.4"
                  />
                </svg>
              </OnyxAITooltip>
            )}
          </OnyxAIWrapper>
        )}
      </DescriptionWrapper>
    )
  }
)

export default TrendsChartDescription
