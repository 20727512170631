import { FC, PropsWithChildren } from 'react'
import { Button as MuiButton, ButtonProps } from '@mui/material'
import theme from 'theme'
import styled from '@emotion/styled/macro'

const Button = styled(MuiButton)`
  background: transparent;
  margin: 0 auto;
  box-sizing: border-box;
  color: ${theme.colors.rawSienneCore};
  border-radius: ${theme.attributes.borderRadius.small};
  text-transform: capitalize;
  font-size: ${theme.typography.fontSize.xLarge};
  line-height: 24px;
  font-weight: 700;
  padding: ${theme.spacing.xsm}px ${theme.spacing.small}px;

  :hover {
    color: #dbdbdb;
  }
`

type Props = ButtonProps

const SecondaryButton: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>
}

export default SecondaryButton
