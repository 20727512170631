import styled from '@emotion/styled'
import { Box } from '@mui/material'
import theme from 'theme'

export const FilterWrapper = styled(Box)<{ showFilter: boolean }>`
  //border-left: ${({ showFilter }) => (showFilter ? `1px solid ${theme.colors.borderLow}` : 'none')};
  width: ${({ showFilter }) => (showFilter ? '396px' : '0px')};
  min-width: ${({ showFilter }) => (showFilter ? '396px' : '0px')};
  visibility: ${({ showFilter }) => (showFilter ? 'visible' : 'hidden')};
  opacity: ${({ showFilter }) => (showFilter ? '1' : '0')};
  padding-left: ${({ showFilter }) => (showFilter ? '24px' : '0')};
  margin-left: ${({ showFilter }) => (showFilter ? '24px' : '0')};
  transition: all 0.3s ease-in-out;
  height: 100%;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.medium}px;

    .title {
      color: ${theme.colors.white};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.header};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: normal;
      white-space: nowrap;
    }

    .reset {
      color: ${theme.colors.textPrimary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.thin};
      line-height: normal;
      cursor: pointer;
      white-space: nowrap;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.small}px;
    }
  }
`

export const ContentWrapper = styled(Box)`
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.quaternary};
  }

  > div {
    width: 371px;
    min-width: 371px;
  }
`
